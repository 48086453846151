import React from 'react';

import cn from '@/components/blocks/TinyGradientCategory/TinyGradientStory/style.module.sass';
import { useTabletMobileWidth } from '@/hooks/useMedia';

interface IMobileTap {
    swiperRef?: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}
const MobileTap = ({ swiperRef }: IMobileTap) => {
    const isMobile = useTabletMobileWidth();

    return (
        <div className={isMobile ? cn.tapContainer : cn.hidden}>
            <button
                aria-label="prev"
                type="button"
                className={cn.tapButton}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                onClick={() => swiperRef?.current?.swiper?.slidePrev()}
            />
            <button
                aria-label="next"
                type="button"
                className={cn.tapButton}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                onClick={() => swiperRef?.current?.swiper?.slideNext()}
            />
        </div>
    );
};

export default MobileTap;
