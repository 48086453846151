export const mockColoredBannerBlockData = {
    title: 'Стань клиентом банка без посещения офиса',
    text: 'для тебя доступно подключение услуг банка онлайн с помощью мобильного приложения почта банка онлайн',
    image: '/img-next/png/banners/coloredBannerImage2.png',
    name: 'coloredBanner',
    link: { link: '', type: 'anchor' },
};

export const mockColoredBannerBlockDataTina = {
    title: 'Заголовок',
    text: 'Текст',
    image: '',
    name: 'coloredBanner',
    link: { link: 'Название якоря без #', type: 'anchor' },
};
