import React from 'react';

import CustomImage from '@/components/CustomImage';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface TextImageRowProps {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'textImageRow';
    image: string;
    title: string;
    desc?: string;
}

const TextImageRow = ({ desc, image, title }: TextImageRowProps) => {
    const width = useResize();
    const isMobile = width <= MOBILE_WIDTH;

    return (
        <div className={cn.wrapper}>
            <div className={cn.left}>
                <div className={cn.title}>{title}</div>
                {desc && !isMobile && <div className={cn.desc} dangerouslySetInnerHTML={{ __html: desc }} />}
            </div>
            <div className={cn.right}>
                <CustomImage className={cn.img} src={image} fill alt={title} />
            </div>
            {desc && isMobile && <div className={cn.desc} dangerouslySetInnerHTML={{ __html: desc }} />}
        </div>
    );
};

export default TextImageRow;
