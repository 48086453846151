export const mockFaqBlockData = {
    title: 'Часто задаваемые вопросы',
    subtitle: `Если вы не найдете ответ на свой вопрос вы можете задать его через <a href="#">форму обратной связи</a>`,
    list: [
        {
            title: 'Погашение кредита',
            subtitle:
                'Не следует, однако, забывать, что внедрение современных методик в значительной степени обусловливает важность экономической целесообразности ' +
                'принимаемых решений. Сложно сказать, почему непосредственные участники технического прогресса и по сей день остаются уделом либерало.',
            link: '/support/faq/pogasheniye-kredita',
            image: '/img-next/svg/faq/1.svg',
            tabsList: [{ tabTitle: 'Первый тег' }, { tabTitle: 'Второй тег' }, { tabTitle: 'Третий тег' }],
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Третий тег' }],
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [
                        { elementTabTitle: 'Первый тег' },
                        { elementTabTitle: 'Второй тег' },
                        { elementTabTitle: 'Третий тег' },
                    ],
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Второй тег' }],
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }],
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Второй тег' }, { elementTabTitle: 'Третий тег' }],
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Третий тег' }],
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Второй тег' }],
                },
                {
                    title: 'Что такое частичное досрочное погашение кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Третий тег' }],
                },
                {
                    title: 'Могу ли я сразу внести несколько платежей?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Второй тег' }],
                },
            ],
        },
        {
            title: '«Лето-карта» / «Карта Почтовый Экспресс»',
            image: '/img-next/svg/faq/2.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Потребительские кредиты',
            subtitle:
                'Не следует, однако, забывать, что внедрение современных методик в значительной степени обусловливает важность экономической целесообразности принимаемых решений. \n' +
                'Сложно сказать, почему непосредственные участники технического прогресса и по сей день остаются уделом либерало.',
            link: '/support/faq/loans',
            image: '/img-next/svg/faq/3.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Покупки в кредит',
            image: '/img-next/svg/faq/4.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Что такое частичное досрочное погашение кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Общие',
            image: '/img-next/svg/faq/5.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Услуги',
            image: '/img-next/svg/faq/6.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: '«Моя кредитная история»',
            image: '/img-next/svg/faq/7.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Что такое частичное досрочное погашение кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Кредит на образование',
            image: '/img-next/svg/faq/8.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
        {
            title: 'Платежи и переводы',
            image: '/img-next/svg/faq/9.svg',
            info: [
                {
                    title: 'Другие предложения по кредиту наличными',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
            ],
        },
    ],
};

export const mockFaqBlockDataRight = {
    title: '',
    subtitle: `Если вы не найдете ответ на свой вопрос вы можете задать его через <a href="#">форму обратной связи</a>`,
    list: [
        {
            title: 'Погашение кредита',
            image: '/img-next/svg/faq/1.svg',
            info: [
                {
                    title: 'Как узнать сумму ежемесячного платежа?',
                    subtitle:
                        'Данная информация указана в графике платежей, который предоставлялся при оформлении кредита. Также размер платежа легко уточнить в Почта Банк Онлайн, ' +
                        'или позвонив по телефону +7 495 532 13 00 и правильно назвав код доступа или обратившись в клиентский центр «Почта Банка».',
                },
                {
                    title: 'Может ли кто-то другой внести за меня ежемесячный платеж?',
                    subtitle:
                        'Да, оплатить кредит может любой человек, которому вы доверяете и можете оставить реквизиты для внесения средств и сумму платежа.',
                },
                {
                    title: 'Я могу досрочно погасить кредит?',
                    subtitle:
                        'При частичном и при досрочном погашении Заявление на досрочное погашение подключается либо в Клиентской службе либо в Клиентском центре/стойке продаж. ' +
                        'При частичном досрочном погашении уменьшается ежемесячный платеж, срок сохраняется. При досрочном погашении подключение Заявления обязательно.',
                },
                {
                    title: 'Я внес сумму, превышающую ежемесячный платеж, что произойдет?',
                    subtitle:
                        'Без подключения Заявления на досрочное погашение со счета спишется сумма равная ежемесячному платежу, остаток остается на счете. При подключении ' +
                        'Заявления на досрочное погашение в дату платежа спишется вся сумма со счета, при этом последующие платежи уменьшатся, срок выплаты сохранится.',
                },
                {
                    title: 'Существует ли возможность автопогашения кредита?',
                    subtitle:
                        'Вы можете подключить погашение кредита в «Почта Банке» с банковской карты другого банка:\n' +
                        '\n' +
                        'Разовое погашение — сумму и дату вы определяете сами.\n' +
                        'Автопогашение — регулярное автоматическое погашение кредита.\n' +
                        'Для подключения услуг обратитесь в клиентский центр.',
                },
                {
                    title: 'Где можно внести платеж?',
                    subtitle:
                        'Существует несколько способов оплаты кредита, выбирайте наиболее удобный.\n' +
                        '\n' +
                        'Без комиссии платежи принимаются:\n' +
                        '\n' +
                        '    в банкоматах расположенных в клиентских центрах «Почта Банка»;\n' +
                        '\n' +
                        'Внести платежи с комиссией легко:\n' +
                        '\n' +
                        '    в банкоматах ВТБ 24\n' +
                        '    в кассах магазинов и салонов связи партнеров платежной системы «Золотая Корона» и «Рапида»;\n' +
                        '    в терминалах «QIWI», «Элекснет», «Киберплат».\n' +
                        '\n' +
                        'Подробная информация по каналам погашения есть в памятке по погашению',
                },
                {
                    title:
                        'У меня остались собственные средства на счете после оплаты кредита. ' +
                        'Как я могу их получить?',
                    subtitle:
                        'Для получения средств оставшихся на счете после погашения кредита достаточно обратиться в клиентский центр и написать заявление на перевод остатка ' +
                        'денежных средств на другой счет с указанием реквизитов. По кредитам «Лето-деньги» собственные средства также можно получить наличными в банкомате ' +
                        'с использованием бар-кода.',
                },
                {
                    title: 'Что такое частичное досрочное погашение кредита?',
                    subtitle:
                        'Это возможность сэкономить на процентах по кредиту, погашая кредит суммами, превышающими очередной платеж. Поступив на счет, деньги уменьшат ' +
                        'остаток основного долга в дату платежа по кредиту, на который начисляются проценты.',
                },
                {
                    title: 'Могу ли я сразу внести несколько платежей?',
                    subtitle:
                        'Да, внести несколько платежей можно. В дату платежа сумма списывается равная ежемесячному платежу, а остаток остается на счете.',
                },
            ],
        },
        {
            title: '«Лето-карта» / «Карта Почтовый Экспресс»',
            image: '/img-next/svg/faq/2.svg',
            info: [
                {
                    title: 'Установлена ли комиссия за выдачу наличных со счета карты «Почтовый экспресс»?',
                    subtitle:
                        'За выдачу собственных денежных средств в банкоматах ПАО «Почта Банка» и банкоматах Группы ВТБ взимание комиссии не предусмотрено. ' +
                        'Во всех остальных случаях комиссия за выдачу наличных со счета карты «Почтовый экспресс» составляет 300 рублей за каждую операцию,' +
                        ' вне зависимости от суммы.\n' +
                        '\n' +
                        'При снятии наличных денежных средств в устройствах других банков важно также учитывать комиссию банка, в котором производится получение ' +
                        'денежных средств.',
                },
                {
                    title: 'Есть ли комиссия за обслуживание карты «Почтовый экспресс»?',
                    subtitle:
                        'Размер комиссии за обслуживание карты составляет 300/500/600 рублей в зависимости от текущего кредитного лимита, взимается ежемесячно и ежегодно. ' +
                        'Ежемесячно (взимается в Дату платежа), при наличии на Дату Платежа Задолженности, образованной за предшествующие Расчетные периоды.\n' +
                        '\n' +
                        'Ежегодно (взимается в Дату совершения первой Операции по Счету по истечении указанных 12 (Двенадцати) Расчетных периодов), при совершении ' +
                        'за последние 12 (Двенадцать) Расчетных периодов хотя бы одной Операции по Счету, начиная с даты Активации Карты.',
                },
                {
                    title: 'Можно ли изменить действующий ПИН-код, если я его помню?',
                    subtitle: 'Да, изменить действующий ПИН-код возможно самостоятельно в любом банкомате Почта Банка.',
                },
                {
                    title: 'Я утерял карту «Почтовый экспресс». Что делать?',
                    subtitle:
                        'При обнаружении утраты карты «Почтовый экспресс», прежде всего, необходимо обратиться в Банк. Специалисты Банка проконсультируют по дальнейшим действиям, ' +
                        'в т.ч. помогут с блокировкой карты.\n' +
                        '\n' +
                        'Заблокировать карту «Почтовый экспресс» можно при обращении в клиентский центр, на стойку продаж или по звонку в клиентскую службу. ' +
                        'При обращении в клиентскую службу необходимо знать код доступа или правильно ответить на контрольные вопросы.\n' +
                        '\n' +
                        'При обращении в Клиентский центр или на Стойку продаж необходимо иметь при себе паспорт.',
                },
                {
                    title: 'Банкомат не вернул карту. Что делать?',
                    subtitle:
                        'Необходимо обратиться в банк и заблокировать карту «Почтовый экспресс». Заблокировать карту можно при обращении в клиентский центр, ' +
                        'на стойку продаж или по звонку в клиентскую службу по телефону +7 495 532 13 00. Далее необходимо произвести перевыпуск карты, ' +
                        'обратившись в клиентский центр или на стойку продаж. При обращении в клиентский центр или на стойку продаж необходимо иметь при себе паспорт.' +
                        ' За перевыпуск карты «Почтовый экспресс» взимается комиссия – 300/500/600 рублей в зависимости от текущего кредитного лимита.',
                },
                {
                    title:
                        'Возможен ли перевод собственных или кредитных средств с карты «Почтовый экспресс» на счет другого банка по банковским реквизитам ' +
                        'через банкоматы банка?',
                    subtitle:
                        'Да, такой перевод возможен. Для исполнения безналичного перевода Вам потребуются реквизиты счета, на который будет ' +
                        'производиться перевод, в т.ч.:\n' +
                        '\n' +
                        '    Номер счета в банке получателе;\n' +
                        '    Номер договора/номер карты в банке получателе;\n' +
                        '    ФИО получателя;\n' +
                        '    ФИО отправителя;\n' +
                        '    Номер телефона плательщика.\n' +
                        '\n' +
                        'В случае перевода по свободным реквизитам, обязательно указание БИК Банка получателя.\n' +
                        '\n' +
                        'Минимально возможная сумма для перевода с использованием карты «Почтовый экспресс» в наших банкоматах составляет 15 000 руб., ' +
                        'максимальная не может превышать 500 000 руб.',
                },
                {
                    title: 'Где я могу производить оплату по карте «Почтовый экспресс»?',
                    subtitle:
                        'Для кредитной карты работают те же каналы погашения, что и для всех кредитных продуктов банка. Актуальный перечень можно найти в ' +
                        'Памятке по погашению кредита',
                },
                {
                    title: 'Возможна ли оплата по карте «Почтовый экспресс» в Интернете?',
                    subtitle:
                        'Да, оплата по карте «Почтовый экспресс» в интернете возможна. Более того, карта «Почтовый экспресс» автоматически подключается ' +
                        'к услуге безопасной оплаты в Интернете 3-D Secure (Verified by Visa) в момент активации.',
                },
                {
                    title:
                        'Существуют ли ограничения (лимиты) на снятие наличных ' +
                        'со счета карты «Почтовый экспресс»?',
                    subtitle:
                        'Установлены следующие лимиты выдачи наличных по карте «Почтовый экспресс»:\n' +
                        '\n' +
                        '    на одну операцию снятия – 5 000 руб.;;\n' +
                        '    в месяц – 30 000 руб.;\n' +
                        '\n' +
                        'Эти ограничения распространяются как на выдачу собственных средств клиентов, размещённых на карте, так и на выдачу средств ' +
                        'за счет установленного по карте кредитного лимита.',
                },
                {
                    title: 'Что делать, если утерян/забыт ПИН-код?',
                    subtitle:
                        'Для восстановления забытого/утерянного ПИН-кода необходимо обратиться в клиентский центр, на стойку продаж или позвонить ' +
                        'в клиентскую службу по телефону +7 495 532 13 00. При обращении в клиентскую службу необходимо знать Код доступа.\n' +
                        '\n' +
                        'При обращении в клиентский центр или на стойку продаж необходимо иметь при себе паспорт.\n' +
                        '\n' +
                        'Комиссия за проведение данной операции не взимается.',
                },
                {
                    title: 'Что делать, если ПИН-код неверно введен 3 (три) раза и заблокирован?',
                    subtitle:
                        'Для снятия блокировки ПИН-кода необходимо обратиться в клиентский центр, на стойку продаж или позвонить в клиентскую службу ' +
                        'по телефону +7 495 532 13 00. При обращении в клиентскую службу необходимо знать код доступа. При обращении в клиентский ' +
                        'центр или на стойку продаж необходимо иметь при себе паспорт.',
                },
                {
                    title: 'Я блокировал карту «Почтовый экспресс». Как разблокировать?',
                    subtitle:
                        'Возможен только перевыпуск карты. Для перевыпуска карты «Почтовый экспресс» нужно обратиться в клиентский центр или на стойку продаж с паспортом. ' +
                        'За перевыпуск карты взимается комиссия – 300/500/600 рублей в зависимости от текущего кредитного лимита.',
                },
                {
                    title: 'Я потерял карту «Почтовый экспресс», но возможно найду ее. Что делать?',
                    subtitle:
                        'Необходимо заблокировать карту. Заблокировать карту «Почтовый экспресс» можно при обращении в клиентский центр, на стойку продаж или ' +
                        'по звонку в клиентскую службу по номеру +7 495 532 13 00.',
                },
                {
                    title:
                        'Какая комиссия взимается при переводе денежных средств в другой банк с карты ' +
                        '«Почтовый экспресс» через банкоматы «Почта Банка»?',
                    subtitle:
                        'Комиссия за перевод денежных средств в другой банк с карты «Почтовый экспресс» через банкоматы «Почта Банка» составляет 3,3% (минимум 300 рублей).\n' +
                        '\n' +
                        'Полный список услуг и размеры комиссий',
                },
                {
                    title:
                        'Когда будут доступны денежные средства для проведения операций по карте ' +
                        '«Почтовый экспресс» после пополнения счета?',
                    subtitle:
                        'Доступный остаток по карте «Почтовый экспресс» будет увеличен на следующий календарный день (как правило, до 17.00 московского времени) ' +
                        'после зачисления денежных средств на ваш счет. Вы получите соответствующее СМС-уведомление об увеличении доступного остатка.\n' +
                        '\n' +
                        'Ознакомиться со сроками отражения операций по счету в зависимости от способа внесения денежных средств можно в памятке по погашению кредита.',
                },
                {
                    title:
                        'По какому курсу происходит конвертация, если оплата по карте ' +
                        '«Почтовый экспресс» происходит за рубежом или в другой валюте?',
                    subtitle:
                        'По карте «Почтовый экспресс» курс конвертации валют устанавливается максимально просто и прозрачно.\n' +
                        '\n' +
                        'Курс конвертации Банка будет равен курсу Банка России + 2,5% (по операциям пополнения курс Банка России – 2,5%) на дату совершения операции ' +
                        'по ежедневно котируемым ЦБ РФ валютам (34 основные валюты). По остальным валютам – конвертация будет происходить по курсам международной' +
                        ' платежной системы через доллар США (далее конвертация доллар США–рубли будет также по курсу Банка России ± 2,5%).\n' +
                        '\n' +
                        'Курс устанавливается один раз в день и в течение дня не меняется. Если по операции была авторизация (а это подавляющее число операций), ' +
                        'то курс фиксируется на дату авторизации и дальше уже не пересматривается.',
                },
            ],
        },
        {
            title: 'Потребительские кредиты',
            image: '/img-next/svg/faq/3.svg',
            info: [
                {
                    title: 'Что делать, если пришло налоговое уведомление за неудержанный НДФЛ?',
                    subtitle:
                        'ПАО «Почта банк» (далее - Банк) информирует, что в соответствии с п.5 ст.226 Налогового Кодекса РФ в случае получения ' +
                        'налогоплательщиком-физическим лицом в 2020 г. дохода от Банка, с которого Банком не был удержан налог на доходы физических лиц (далее - НДФЛ) ' +
                        'по причине невозможности его удержания, Банк, как налоговый агент, обязан проинформировать налоговый орган о невозможности удержать налог, ' +
                        'о суммах дохода, с которого не удержан налог, и сумме неудержанного налога.\n' +
                        '\n' +
                        'Налогоплательщику-физическому лицу в соответствии с п.6 ст.228 НК РФ необходимо самостоятельно в срок не позднее 01 декабря 2021 года ' +
                        'уплатить неудержанный НДФЛ на основании направленного налоговым органом налогового уведомления об уплате НДФЛ.\n' +
                        '\n' +
                        'Информацию о начислении налогов и состоянии расчетов по ним можно уточнить в личном кабинете на портале госуслуг (www.gosuslugi.ru) , ' +
                        'на портале Федеральной налоговой службы (www.nalog.ru), либо в письменном уведомлении ФНС.\n' +
                        '\n' +
                        'Дополнительную информацию Вы можете получить:\n' +
                        '\n' +
                        '1. Позвонив по телефону горячей линии +7 495 532 13 00\n' +
                        '\n' +
                        '2. Обратившись по электронной почте welcome@pochtabank.ru',
                },
                {
                    title: 'Что делать, если график платежей потерялся?',
                    subtitle:
                        'Для получения нового графика платежей достаточно обратиться в клиентский центр банка с паспортом. Также уточнить сумму ' +
                        'и дату ежемесячного платежа можно по телефону клиентской службы банка +7 495 532 13 00, правильно назвав код доступа, или в Почта Банк Онлайн.',
                },
                {
                    title: 'На какие цели предоставляется кредит наличными?',
                    subtitle:
                        'Кредит наличными «Первый почтовый» предоставляется на любые желания. Оформив кредит, вы сможете использовать полученные деньги по своему усмотрению.',
                },
                {
                    title:
                        'Сколько действует кредитное решение? Мне обязательно сразу брать кредит? ' +
                        'Могу завтра получить кредит по одобренной заявке?',
                    subtitle:
                        'Одобренное решение действует 7 дней, в течение этого срока необходимо подписать договор. После этого получить кредит можно ' +
                        'в удобное время в течение 5 календарных дней.',
                },
                {
                    title: 'Взимается ли комиссия за снятие наличных в банкоматах?',
                    subtitle:
                        'Для тарифов «Лето-деньги»\n' +
                        '\n' +
                        'В банкоматах «Почта Банка» за снятие наличных комиссия не взимается. В банкоматах других банков комиссия составляет 3%, минимум 300 рублей.\n' +
                        '\n' +
                        'Для тарифов «Супер Лето»\n' +
                        '\n' +
                        'Комиссия за снятие наличных в банкоматах «Почта Банка» и банкоматах других банков составляет 3%, минимум 300 рублей.',
                },
                {
                    title: 'Как восстановить код доступа?',
                    subtitle:
                        'Чтобы восстановить код доступа, клиенту необходимо обратиться в клиентский центр банка с паспортом.',
                },
                {
                    title: 'Что такое код доступа?',
                    subtitle:
                        'Код доступа — это аналог подписи клиента, необходимый при обращении клиента в клиентскую службу. Он состоит из 6 цифр ' +
                        'и направляется клиенту в СМС на указанный в анкете номер мобильного телефона.',
                },
                {
                    title: 'Какие документы потребуется предоставить для оформления кредита наличными?',
                    subtitle: 'Для получения кредита понадобятся паспорт гражданина России, СНИЛС и ИНН работодателя.',
                },
                {
                    title: 'Можно ли подключить услугу «Гарантированная ставка» действующим клиентам банка?',
                    subtitle: 'Да,можно. Обратитесь к сотруднику банка по телефону или в клиентском центре.',
                },
            ],
        },
        {
            title: 'Покупки в кредит',
            image: '/img-next/svg/faq/4.svg',
            info: [
                {
                    title: 'Берет ли банк комиссию за выдачу кредита или рассмотрение заявки?',
                    subtitle: 'Нет, «Почта Банк» выдает кредит без комиссий.',
                },
                {
                    title: 'Сколько товаров можно купить в кредит?',
                    subtitle:
                        'По одному кредитному договору можно купить до 6-ти товаров: три вида товаров по две штуки каждого.',
                },
                {
                    title: 'Что делать, если стоимость выбранного товара превышает сумму кредита?',
                    subtitle:
                        'По всем нашим кредитным программам, кроме кредита «Я выбираю 0», есть возможность вносить первоначальный взнос. ' +
                        'Поэтому, если стоимость покупки превышает сумму кредита, просто внесите разницу в качестве первоначального взноса, ' +
                        'предварительно уточнив у представителя «Почта Банка» условия кредита.',
                },
                {
                    title: 'Что произойдет, если я обменяю покупку на товар меньшей или большей стоимости?',
                    subtitle:
                        'При обмене покупки на товар меньшей стоимости разница в стоимости распределяется пропорционально размеру первоначального взноса и суммы кредита. ' +
                        'Первую часть клиент получает наличными в кассе магазина, другая — перечисляется в счет погашения задолженности.\n' +
                        '\n' +
                        'Если при обмене выбран товар большей стоимости, то разницу нужно будет внести в кассу магазина.',
                },
                {
                    title: 'С какими магазинами сотрудничает банк?',
                    subtitle:
                        'Наш банк сотрудничает со множеством магазинов, среди которых магазины бытовой техники и одежды, мебельные магазины, салоны связи и пр. ' +
                        'Список наших партнеров и география сотрудничества постоянно расширяется.',
                },
                {
                    title: 'Какие документы требуется для оформления кредита на покупку?',
                    subtitle: 'При оформлении кредита на покупку в потребуется только российский паспорт. ',
                },
                {
                    title: 'Могу ли я вернуть товар, приобретенный в кредит?',
                    subtitle:
                        'Да, по российскому законодательству вы имеете право вернуть товар в торговую организацию в соответствии с условиями возврата и обмена товара.',
                },
            ],
        },
        {
            title: 'Общие',
            image: '/img-next/svg/faq/5.svg',
            info: [
                {
                    title: 'Что делать, если пришло налоговое уведомление за неудержанный НДФЛ?',
                    subtitle:
                        'ПАО «Почта банк» (далее - Банк) информирует, что в соответствии с п.5 ст.226 Налогового Кодекса РФ в случае получения ' +
                        'налогоплательщиком-физическим лицом в 2020 г. дохода от Банка, с которого Банком не был удержан налог на доходы ' +
                        'физических лиц (далее - НДФЛ) по причине невозможности его удержания, Банк, как налоговый агент, обязан проинформировать ' +
                        'налоговый орган о невозможности удержать налог, о суммах дохода, с которого не удержан налог, и сумме неудержанного налога.\n' +
                        '\n' +
                        'Налогоплательщику-физическому лицу в соответствии с п.6 ст.228 НК РФ необходимо самостоятельно в срок не позднее 01 декабря 2021 ' +
                        'года уплатить неудержанный НДФЛ на основании направленного налоговым органом налогового уведомления об уплате НДФЛ.\n' +
                        '\n' +
                        'Информацию о начислении налогов и состоянии расчетов по ним можно уточнить в личном кабинете на портале госуслуг (www.gosuslugi.ru) ,' +
                        ' на портале Федеральной налоговой службы (www.nalog.ru), либо в письменном уведомлении ФНС.\n' +
                        '\n' +
                        'Дополнительную информацию Вы можете получить:\n' +
                        '\n' +
                        '1. Позвонив по телефону горячей линии +7 495 532 13 00\n' +
                        '\n' +
                        '2. Обратившись по электронной почте welcome@pochtabank.ru',
                },
                {
                    title: 'Что делать, если график платежей потерялся?',
                    subtitle:
                        'Для получения нового графика платежей достаточно обратиться в клиентский центр банка с паспортом. Также уточнить сумму и дату ежемесячного платежа ' +
                        'можно по телефону клиентской службы банка +7 495 532 13 00, правильно назвав код доступа, или в Почта Банк Онлайн.',
                },
                {
                    title: 'Где можно получить выписку по кредиту?',
                    subtitle:
                        'Выписку по кредиту легко получить, обратившись в ближайший клиентский центр банка или позвонив в клиентскую службу по телефону +7 495 532 13 00. ' +
                        'Выписка предоставляется за любой период. Для получения информации необходимо назвать код доступа.',
                },
                {
                    title: 'Взимается ли комиссия за внесение платежей по кредиту?',
                    subtitle:
                        'Комиссия может взиматься в зависимости от способа внесения.\n' +
                        '\n' +
                        'Без комиссии платежи принимаются в банкоматах, расположенных в клиентских центрах «Почта Банка».\n' +
                        '\n' +
                        'При внесении платежей другими способами взимается комиссия.\n' +
                        '\n' +
                        'Подробная информация по каналам погашения и существующим комиссиям есть в разделе «Как оплатить кредит» и в памятке по погашению.',
                },
                {
                    title:
                        'Мой родственник не может прийти в банк по состоянию здоровья, ' +
                        'но нам нужна финансовая информация. Что делать?',
                    subtitle:
                        '1. Оформить нотариальную доверенность на себя или на кого-то из близких. С этим документом вы сможете представлять интересы родственника как в банке,' +
                        ' так и в любом другом учреждении. В доверенности прописываются все операции, которые может совершать представитель.\n' +
                        ' \n' +
                        '\n' +
                        '2. Установить клиенту мобильное приложение Почта Банк Онлайн. Там он сможет получить информацию по счёту самостоятельно, при этом не выходя из дома. ' +
                        'Для этого надо зайти в свой счёт, нажать «Действия», пролистать вниз и выбрать «Заказать выписку по договору». В открывшемся окне можно выбрать ' +
                        'период выгрузки информации, формат документа и e-mail, куда отправят информацию. Советуем перед этим уточнить у организации, куда планируется ' +
                        'предоставить информацию, подойдёт ли им такая форма.\n' +
                        ' \n' +
                        '\n' +
                        '3. Оформить доверенность для совершения операций именно в Почта Банке. Это можно сделать бесплатно в отделениях банка, как в присутствии представителя, ' +
                        'так и без его личного присутствия. Клиент выбирает конкретные полномочия, которыми наделяет доверенное лицо (например, только получение информации, ' +
                        'либо только внесение и получение денежных средств со счета). С формой доверенности можно ознакомиться в отделении банка.',
                },
                {
                    title: 'Как восстановить код доступа?',
                    subtitle:
                        'Чтобы восстановить код доступа, клиенту необходимо обратиться в клиентский центр банка с паспортом.',
                },
                {
                    title: 'Берет ли банк комиссию за выдачу кредита или рассмотрение заявки?',
                    subtitle: 'Нет, «Почта Банк» выдает кредит без комиссий.',
                },
                {
                    title: 'Что такое код доступа?',
                    subtitle:
                        'Код доступа — это аналог подписи клиента, необходимый при обращении клиента в клиентскую службу. Он состоит из 6 цифр и направляется клиенту ' +
                        'в СМС на указанный в анкете номер мобильного телефона.',
                },
                {
                    title: 'Можно ли оформить одновременно несколько кредитов?',
                    subtitle:
                        'Да, это возможно. У наших клиентов может быть оформлено несколько кредитов одновременно. Например, кредит на покупку в магазине-партнере ' +
                        'и кредит наличными.',
                },
            ],
        },
        {
            title: 'Услуги',
            image: '/img-next/svg/faq/6.svg',
            info: [
                {
                    title: 'Я уезжаю в отпуск и не успеваю внести ежемесячный платеж, что делать?',
                    subtitle:
                        'Вы можете подключить услугу «Пропускаю платеж», позвонив по телефону +7 495 532 13 00 (необходимо правильно назвать код доступа) не позднее, ' +
                        'чем за 7 дней до даты очередного платежа. Или внести платеж заранее, в любой день в течение календарного месяца до наступления даты платежа.',
                },
                {
                    title: 'Мне трудно оплачивать кредит, можно ли уменьшить сумму ежемесячного платежа?',
                    subtitle:
                        'Да, воспользуйтесь услугой «Уменьшаю платеж». При ее активации размер платежа становится меньше, а срок кредита увеличивается.',
                },
                {
                    title: 'Что делать, если наступил страховой случай?',
                    subtitle:
                        'При наступлении страхового события застрахованному или его родственникам следует сообщить об этом страховой компании. Сделать это нужно ' +
                        'в течение 30 календарных дней при потере жизни или здоровья застрахованного, и 10 дней в случае недобровольной потери работы.\n' +
                        '\n' +
                        'Телефоны страховых компаний:\n' +
                        '8  100 44 40 – ВТБ Страхование\n' +
                        '8 800 555 87 65 – Кардиф\n' +
                        '8 800 333 84 48 – АльфаСтрахование Жизнь\n' +
                        '8 800 100 05 45 – Альянс\n' +
                        '\n' +
                        'Необходимый комплект документов для различных видов страховых случаев\n' +
                        '\n' +
                        'Уточняйте номер телефона страховых компаний в вашей памятке застрахованному',
                },
                {
                    title:
                        'Я могу изменить дату платежа? Мне неудобно оплачивать в дату, ' +
                        'указанную в графике, я могу ее изменить?',
                    subtitle: 'Воспользовавшись услугой «Меняю дату платежа», легко перенести платеж на удобную дату.',
                },
                {
                    title:
                        'Я могу воспользоваться программой страхования, ' +
                        'если при оформлении кредита страховка не была подключена?',
                    subtitle: 'Услуга подключается только в Клиентском центре/стойке продаж.',
                },
                {
                    title: 'Я пропустил платеж, не подключая услугу. Что мне за это будет?',
                    subtitle:
                        'Денежные средства для погашения кредита необходимо вносить не позднее установленной даты платежа. Если вы не активировали услугу «Пропускаю платеж», ' +
                        'при пропуске платежа ежедневно будет начисляться неустойка в размере 20% годовых на сумму просроченного платежа. Узнать сумму неустойки можно ' +
                        'в клиентском центре, на стойке продаж или по телефону +7 495 532 13 00.',
                },
            ],
        },
        {
            title: '«Моя кредитная история»',
            image: '/img-next/svg/faq/7.svg',
            info: [
                {
                    title:
                        'У меня изменились персональные данные. Как мне сообщить их бюро, ' +
                        'в котором хранится моя кредитная история?',
                    subtitle:
                        'Если данные меняются во время действия кредитного договора, то Вы сообщаете их банку, а банк направляет их в бюро. Когда вы обращаетесь ' +
                        'за новым кредитом, Вы должны указать и свои прежние данные. В этом случае вся информация – старая и новая – попадет в единую кредитную историю.',
                },
                {
                    title: 'Насколько оперативно банк обязан отправлять данные в бюро кредитных историй?',
                    subtitle:
                        'В соответствии с частью 3, статьи 5, п. 5 ФЗ № 218 источники формирования кредитной истории представляют информацию в бюро кредитных историй в срок, ' +
                        'предусмотренный договором о предоставлении информации, но не позднее 10 дней со дня наступления этого события. На практике многие банки передают ' +
                        'в бюро информацию в автоматическом режиме, то есть сразу после наступления события.',
                },
                {
                    title:
                        'Я полагаю, что по ошибке попал в «черный список» банков и теперь мне нигде не дают кредит. ' +
                        'Что нужно сделать, чтобы меня из него исключили?',
                    subtitle:
                        'Такого понятия как общий для всех банков «черный список» не существует. Чтобы узнать, действительно ли Вы относитесь к категории недобросовестных ' +
                        'заемщиков, Вы можете проверить свою кредитную историю самостоятельно. Существует несколько способов это сделать. Если Вы попали в категорию ' +
                        'недобросовестных заемщиков ошибочно и в Вашей кредитной истории, по Вашему мнению, содержатся недостоверные данные, Вы можете оспорить эту ' +
                        'информацию по закону «О кредитных историях». Для этого необходимо подать заявление в бюро, где хранится Ваша кредитная история.',
                },
                {
                    title: 'Существуют ли способы улучшения кредитной истории?',
                    subtitle:
                        'Метод улучшения кредитной истории существует только один. Предположим, что в Вашей кредитной истории есть негативные записи и банки отказываются ' +
                        'выдавать Вам в кредит те суммы, на которые Вы рассчитываете. Тогда можно обращаться за гораздо меньшими кредитами. Если Вы будете выплачивать' +
                        ' их в срок, Ваша кредитная история станет более качественной',
                },
                {
                    title:
                        'При выдаче кредита я заключил договор страхования. Страховой случай наступил – обязанность по выплате кредита перешла к страховой компании. ' +
                        'Отражается ли в моей кредитной истории тот факт, что страховщик погасил мой кредит?',
                    subtitle:
                        'Все платежи по кредиту, включая поступления от страховой компании, отражаются в Вашей кредитной истории. Поэтому не волнуйтесь: на качестве' +
                        ' Вашей кредитной истории тот факт, что вы по объективной причине не смогли погасить кредит сами, не отразится.',
                },
                {
                    title: 'Как узнать, сколько я еще должен выплатить другому банку по кредитному договору?',
                    subtitle:
                        'Вы всегда можете получить свою кредитную историю, чтобы узнать, сколько Вы уже заплатили, и выяснить статус кредита. Для этого обратитесь ' +
                        'за кредитной историей. Из нее Вы узнаете не только информацию о статусе кредитной истории, но и контакты кредитора.',
                },
                {
                    title: 'Может ли банк запрашивать кредитную историю без моего согласия?',
                    subtitle:
                        'В соответствии с 218-ФЗ информационная часть кредитной истории может быть получена без согласия клиента; основная часть кредитной истории ' +
                        'раскрывается только при наличии письменного согласия клиента.',
                },
                {
                    title:
                        'Содержится ли в моей кредитной истории информация о моем доходе,' +
                        ' работодателе, недвижимости, которой я владею?',
                    subtitle:
                        'Согласно закону «О кредитных историях» эти сведения не должны включаться в кредитную историю.',
                },
                {
                    title: 'Зачем нужна кредитная история?',
                    subtitle:
                        'Многие банки выдают кредиты на основе оценки риска. Они запрашивают кредитную историю, чтобы узнать, насколько добросовестно заемщик ' +
                        'выплачивал кредиты прежде. В кредитной истории содержится информация обо всех кредитах, которые Вы получали после 2005 года. ' +
                        'Если Вы являетесь добросовестным заемщиком, вы можете рассчитывать на более выгодные условия кредитования.',
                },
                {
                    title:
                        'Я брал несколько кредитов и погасил их в срок. Потом по объективным причинам я не смог вовремя вносить платежи по следующему кредиту. ' +
                        'Почему этот случай настолько ухудшил мою кредитную историю, что займы в том же размере мне уже не предоставляют?',
                    subtitle:
                        'Дело в том, что кредитная история — это документ, фиксирующий обязательства и их выполнение. К сожалению, она не отражает обстоятельства, ' +
                        'по которым они не были выполнены. Ознакомьтесь со своей кредитной историей и убедитесь, что помимо просрочки, о которой Вы знаете, ' +
                        'там нет других негативных записей.',
                },
            ],
        },
        {
            title: 'Кредит на образование',
            image: '/img-next/svg/faq/8.svg',
            info: [
                {
                    title:
                        'Может ли Клиент оплачивать часть обучения самостоятельно и при этом сохранить возможность вернуться к оплате кредита на тех условиях, ' +
                        'на которых ему был предоставлен кредит?',
                    subtitle:
                        'Да, это возможно.\n' +
                        '\n' +
                        'Для этого Клиенту необходимо оформить транш на меньшую сумму. Рассмотрим пример: Клиент оформил кредит на 100 000 руб., каждый семестр ' +
                        'приносит счета для оплаты на сумму 50 000 рублей. В один из семестров у Клиента появилась возможность часть суммы оплатить самостоятельно, ' +
                        'предположим: 20 000 из 50 000 руб. В этом случае со стороны Банка необходимо будет провести платеж на сумму 30 000 руб. Остальную часть ' +
                        'суммы Клиент оплатит самостоятельно.',
                },
                {
                    title:
                        'В случае, если обязательства по оплате обучения указаны в Договоре с образовательным учреждением, Клиент должен предоставлять счета ' +
                        'для проведения оплаты обучения каждый период, который должен быть оплачен?',
                    subtitle:
                        'Необязательно. Клиент может предоставить Договор и справку из образовательного учреждения о том, что он продолжает обучение.',
                },
                {
                    title:
                        'Как Заемщик и Созаемщики узнают о том, что оплата ' +
                        'за обучение со стороны Банка проведена?',
                    subtitle:
                        'На электронные адреса Заемщика и Созаемщиков будет направлено платежное поручение о проведении оплаты.',
                },
                {
                    title: 'Нужно ли писать заявление, если Клиент оплачивает обучение самостоятельно?',
                    subtitle:
                        'Необходимо уведомить банк в самостоятельной оплате обучения для сохранения возможности использования кредита в дальнейшем ' +
                        'для оплаты следующих периодов обучения.',
                },
                {
                    title:
                        'В случае, если обязательства по оплате обучения указаны в Договоре с образовательным учреждением , его нужно повторно предоставлять, ' +
                        'если оплата осуществляется по Договору?',
                    subtitle:
                        'Да, а также необходимо предоставить справку о том, что Студент продолжает обучаться в образовательном учреждении.',
                },
            ],
        },
        {
            title: 'Платежи и переводы',
            image: '/img-next/svg/faq/9.svg',
            info: [
                {
                    title: 'Что такое «Фотоплатёж»?',
                    subtitle:
                        'Функция в Мобильном банке, которая способна автоматически распознавать текст, номера счетов и прочую информацию из бумажных квитанций.',
                },
                {
                    title: 'Как настроить услугу «Платежи по расписанию»?',
                    subtitle:
                        'В Мобильном банке, после совершения платежа/перевода, на финальном экране нажать «Переводить регулярно», либо активировать ' +
                        'в разделе редактирования шаблона.',
                },
                {
                    title: 'В чем отличие «Автоплатежа» от «Платежа по расписанию»?',
                    subtitle:
                        'Услуга «Автоплатёж» может быть подключена только для оплаты сотовой связи от операторов «большой тройки» — Мегафон, Билайн, МТС.' +
                        ' Для «Автоплатежа» задаются следующие настройки:\n' +
                        '\n' +
                        '    Баланс сотового телефона, при котором должно происходить автоматическое пополнение счета;\n' +
                        '    Сумма, на которую необходимо производить пополнение, при достижении установленного баланса;\n' +
                        '    Максимальная сумма пополнений в течение месяца, которая может быть произведена с использованием услуги «Автоплатёж».',
                },
                {
                    title: 'Как отправить денежный перевод Western Union?',
                    subtitle:
                        'Услуга доступна в Мобильном приложении Банка, раздел «Оплатить» → «Переводы Western Union». В открывшемся окне требуется вручную ' +
                        'заполнить необходимые поля и выбрать счет для перевода. Переводы Western Union доступны с рублевых счетов (кроме кредитных продуктов ' +
                        'и виртуальных карт) для клиентов Банка, прошедших полную идентификацию.',
                },
                {
                    title: 'Как осуществить оплату по QR-коду?',
                    subtitle:
                        'Услуга доступна в мобильном банке «Почта Банк Онлайн» в разделе «Оплатить» -> «Оплатить по QR-коду». Вам необходимо нажать ' +
                        'на кнопку «Сканировать QR-код», отсканировать QR-код на бумажной квитанции, проверить автоматически заполненные поля ' +
                        'на экране и нажать «Оплатить».',
                },
                {
                    title: 'Как воспользоваться услугой «Фотоплатёж»?',
                    subtitle:
                        'Услуга бесплатна и доступна в разделе «Перевести» → «Юридическому лицу в другой банк», «Налоговый платеж». «Таможенный платеж» ' +
                        'и «В бюджетную организацию», а также для платежей следующих групп: «Образование», «Коммунальные платежи», «Телефония» ' +
                        'и «Охранные системы». Вам необходимо нажать на пиктограмму фотоаппарата, сфотографировать квитанцию или выбрать снимок ' +
                        'из галереи. Далее нажать кнопку распознать, после чего произойдет автоматическое распознавание полей квитанции ' +
                        'и автоматическая подстановка распознанных значений в соответствующие поля платежной формы.',
                },
                {
                    title: 'Как часто можно осуществлять «Платежи по расписанию»?',
                    subtitle:
                        'Имеется два варианта настройки платежа по расписанию: ежемесячно и ежедневно. После того, как задали периодичность, ' +
                        'настраивается сумма платежа и период, в который будет действовать заданное расписания.',
                },
                {
                    title: 'Как подключить услугу «Автоплатёж»?',
                    subtitle:
                        'Подключить и отключить услугу можно по аналогии с услугой «Платеж по расписанию» при оплате мобильной связи или при редактировании шаблона.',
                },
                {
                    title: 'Где и как получатель может получить перевод Western Union?',
                    subtitle:
                        'Перевод будет доступен получателю в точке обслуживания Western Union в выбранной стране (для США и Мексики – штате и городе). ' +
                        'Для того, чтобы получатель смог получить перевод, отправитель самостоятельно сообщает ему уникальный номер MTCN, ' +
                        'который присваивается к каждому успешному переводу и указан в квитанции.',
                },
            ],
        },
    ],
};

export const mockFaqBlockDataTina = {
    title: 'Заголовок',
    subtitle: 'Описание',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            image: '/img-next/mock/46x46.png',
            tabsList: [{ tabTitle: 'Первый тег' }, { tabTitle: 'Второй тег' }, { tabTitle: 'Третий тег' }],
            info: [
                {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                    elementTabsList: [{ elementTabTitle: 'Первый тег' }, { elementTabTitle: 'Третий тег' }],
                },
            ],
        },
    ],
};
