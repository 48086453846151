import React from 'react';

import { TinaPhotoDateBlockBlock } from '@/components/blocks/PhotoDateBlock/index.tina';
import { mockPhotoDateBlockBlockData } from '@/data/blocks/photoDateBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const photoDateBlock = {
    Component: props => <TinaPhotoDateBlockBlock {...props} />,
    template: {
        label: 'Блок с фото по датам',
        defaultItem: () => mockPhotoDateBlockBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },

            {
                name: 'list',
                component: 'group-list',
                label: 'Список элементов',
                defaultItem: () => mockPhotoDateBlockBlockData.list[0],
                itemProps: (item: { date: string }, index: number) => ({
                    key: index,
                    label: item.date,
                }),
                fields: [
                    {
                        name: 'date',
                        component: 'text',
                        label: 'Дата',
                    },

                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },

                    {
                        name: 'img',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'href',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Название',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
