import React from 'react';

import { TinaSelectableWithValueBlock } from '@/components/blocks/SelectableWithValue/index.tina';
import { mockSelectableDataTina } from '@/data/blocks/selectableWithValue';

export const selectableWithValueBlock = {
    Component: props => <TinaSelectableWithValueBlock {...props} />,
    template: {
        label: 'Блок с селектом',
        defaultItem: () => mockSelectableDataTina,
        fields: [
            {
                name: 'firstFieldTitle',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'firstFieldDescription',
                component: 'text',
                label: 'Описание',
            },
            {
                name: 'firstFieldName',
                component: 'text',
                label: 'Название Поля № 1',
            },
            {
                name: 'secondFieldName',
                component: 'text',
                label: 'Название Поля № 2',
            },
            {
                name: 'options',
                label: 'Список элементов',
                component: 'group-list',
                itemProps: (item: { label: string }, index: number) => ({
                    key: index,
                    label: item.label,
                }),
                defaultItem: () => mockSelectableDataTina.options[0],
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'value',
                        component: 'text',
                        label: 'Значение',
                    },
                ],
            },
        ],
    },
};
