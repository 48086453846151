export const mockPartnersBlockData = {
    name: 'Тратьте бонусы у наших партнеров',
    limit: 8,
    type: 'col-4',
    list: [
        {
            title: 'AliExpress',
            link: '#',
            image: '/img-next/png/partners/1.png',
            imageDarkTheme: '/img-next/png/partners/dark/1.png',
        },
        {
            title: 'Перекресток',
            link: '#',
            image: '/img-next/png/partners/2.png',
            imageDarkTheme: '/img-next/png/partners/dark/2.png',
        },
        {
            title: 'Спортмастер',
            link: '#',
            image: '/img-next/png/partners/3.png',
            imageDarkTheme: '/img-next/png/partners/dark/3.png',
        },
        {
            title: 'Aviasales',
            link: '#',
            image: '/img-next/png/partners/4.png',
            imageDarkTheme: '/img-next/png/partners/dark/4.png',
        },
        {
            title: 'Booking.com',
            link: '#',
            image: '/img-next/png/partners/5.png',
            imageDarkTheme: '/img-next/png/partners/dark/5.png',
        },
        {
            title: 'Lamoda',
            link: '#',
            image: '/img-next/png/partners/6.png',
            imageDarkTheme: '/img-next/png/partners/dark/6.png',
        },
        {
            title: 'Читай-город',
            link: '#',
            image: '/img-next/png/partners/7.png',
            imageDarkTheme: '/img-next/png/partners/dark/7.png',
        },
        {
            title: 'M.видео',
            link: '#',
            image: '/img-next/png/partners/8.png',
            imageDarkTheme: '/img-next/png/partners/dark/8.png',
        },
        {
            title: 'O`STIN',
            link: '#',
            image: '/img-next/png/partners/ostin.png',
            imageDarkTheme: '/img-next/png/partners/dark/ostin.png',
        },
        {
            title: 'Л`Этуаль',
            link: '#',
            image: '/img-next/png/partners/letual.png',
            imageDarkTheme: '/img-next/png/partners/dark/letual.png',
        },
        {
            title: 'OBI',
            link: '#',
            image: '/img-next/png/partners/obi.png',
            imageDarkTheme: '/img-next/png/partners/dark/obi.png',
        },
        {
            title: 'Дочки сыночки',
            link: '#',
            image: '/img-next/png/partners/d-s.png',
            imageDarkTheme: '/img-next/png/partners/dark/d-s.png',
        },
    ],
};

export const mockPartnersBlockDataServiceGoods = {
    _template: 'partners',
    name: 'Магазины-партнёры',
    limit: 10,
    type: 'col-5',
    list: [
        {
            link: '#',
            image: '/img-next/png/partners/magazin/1.png',
            imageDarkTheme: '/img-next/png/partners/magazin/1.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/2.png',
            imageDarkTheme: '/img-next/png/partners/magazin/2.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/3.png',
            imageDarkTheme: '/img-next/png/partners/magazin/3.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/4.png',
            imageDarkTheme: '/img-next/png/partners/magazin/4.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/5.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/6.png',
            imageDarkTheme: '/img-next/png/partners/magazin/6.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/7.png',
            imageDarkTheme: '/img-next/png/partners/magazin/7.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/8.png',
            imageDarkTheme: '/img-next/png/partners/magazin/8.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/9.png',
            imageDarkTheme: '/img-next/png/partners/magazin/9.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/10.png',
            imageDarkTheme: '/img-next/png/partners/magazin/10.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/11.png',
            imageDarkTheme: '/img-next/png/partners/magazin/1.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/12.png',
            imageDarkTheme: '/img-next/png/partners/magazin/2.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/13.png',
            imageDarkTheme: '/img-next/png/partners/magazin/3.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/14.png',
            imageDarkTheme: '/img-next/png/partners/magazin/4.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/15.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/16.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/17.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/18.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/19.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/20.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/21.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/22.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/23.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/24.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/25.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/26.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/27.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/28.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/29.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/30.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/31.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/32.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/33.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/34.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/35.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/36.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/37.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
        {
            link: '#',
            image: '/img-next/png/partners/magazin/38.png',
            imageDarkTheme: '/img-next/png/partners/magazin/5.png',
        },
    ],
};

export const mockPartnersBlockDataTina = {
    section: null,
    limit: 8,
    list: [
        {
            title: '',
            name: 'Заголовок',
            link: '#',
            image: '',
            imageDarkTheme: '',
        },
    ],
};
