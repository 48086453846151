import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import cn from './style.module.sass';

export interface IMicroTileCard {
    listName: string;
    image: string;
    listLink: string;
    width: 'big' | 'medium';
}

const MicroTileCard: FC<IMicroTileCard> = ({ image, listLink, listName, width }) => (
    <div className={clsx(cn.microTitleCard, width === 'big' && cn.big, width === 'medium' && cn.medium)}>
        <div className={cn.textContent}>
            <div className={cn.text}>{listName}</div>
            <Link href={listLink} className={cn.link}>
                Подробнее
            </Link>
        </div>
        <div className={cn.imageContainer}>
            <Image width={46} height={46} className={cn.image} src={image} alt={listName} />
        </div>
    </div>
);

export default MicroTileCard;
