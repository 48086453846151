import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SwiperCore, { EffectFade } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import TariffCompareCharacteristics from '@/components/blocks/TariffCompare/info';
import TariffCompareItem from '@/components/blocks/TariffCompare/item';
import NextSliderArrow from '@/components/blocks/TariffCompare/nextArrow';
import PrevSliderArrow from '@/components/blocks/TariffCompare/prevArrow';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Feature from '@/domain/feature/Feature';
import { ITariffCompare, SwiperRefTariffCompare } from '@/types/tariffCompare/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

SwiperCore.use([EffectFade]);

function TariffCompare({ title, list, button }: ITariffCompare) {
    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);
    const { gtag } = button;
    const [fixId, setFixId] = useState(list[0].id);

    const swiperRef: SwiperRefTariffCompare = useRef(null);
    const settings = {
        slidesPerView: 1,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
        onSlideChange: (swp: { isEnd: boolean; isBeginning: boolean }) => {
            if (swp?.isEnd) {
                setIsEnd(true);
            } else {
                setIsEnd(false);
            }

            if (swp?.isBeginning) {
                setIsBeginning(true);
            } else {
                setIsBeginning(false);
            }
        },
    };

    const setCurrentFixItem = (id: number) => {
        setFixId(id);

        if (!isEnd) {
            swiperRef?.current?.swiper?.slideNext();
        } else {
            swiperRef?.current?.swiper?.slidePrev();
        }
    };

    const fixItem = useMemo(() => list?.find(item => item?.id === fixId), [list, fixId]);

    const currentList = useMemo(() => list?.filter(item => item?.id !== fixItem?.id), [fixItem, list]);

    const characteristics = useMemo(() => {
        if (list) {
            return list[0]?.characteristics?.map(item => item?.title);
        }

        return null;
    }, []);

    useEffect(() => {
        if (list?.length > 2) {
            swiperRef?.current?.swiper?.slideTo(1);
        }
    }, []);

    if (!Feature.isFeatureEnabled('enableTariffCompare') || !list || list?.length <= 0) {
        return null;
    }

    return (
        <div className={clsx('section', cn.current)}>
            <AnimatedComponent className={cn.wrap} classNameActive={cn.animationInit}>
                <div className={cn.wrapper}>
                    <div className={clsx(cn.titleWrap)}>
                        <TextField className={cn.title} text={title} name="title" />
                    </div>
                    <div className={cn.innerWrapper}>
                        <PrevSliderArrow
                            listLength={currentList?.length}
                            swiperRef={swiperRef}
                            gtag={gtag}
                            isDisabled={isBeginning}
                        />
                        <NextSliderArrow
                            listLength={currentList?.length}
                            swiperRef={swiperRef}
                            gtag={gtag}
                            isDisabled={isEnd}
                        />
                        <div className={cn.grid}>
                            <div className={cn.info}>
                                <TariffCompareCharacteristics list={characteristics} />
                            </div>
                            <div className={cn.content}>
                                <div className={cn.left}>
                                    <div className={clsx(cn.fixItemWrapper, 'active')}>
                                        <TariffCompareItem item={fixItem} />
                                    </div>
                                </div>
                                <div className={clsx(cn.right)}>
                                    {currentList?.length > 0 && (
                                        <div className={cn.currentGrid}>
                                            <AnimatedComponent
                                                className={clsx(
                                                    cn.currentSlider,
                                                    currentList?.length < 2 && cn.currentSliderSmall
                                                )}
                                                classNameActive={cn.animationInit}
                                            >
                                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                                {/* @ts-ignore */}
                                                <CustomSwiper {...settings} ref={swiperRef}>
                                                    {currentList?.map((item, index) => (
                                                        <SwiperSlide key={item.id}>
                                                            <TariffCompareItem
                                                                item={item}
                                                                pos={index}
                                                                handler={setCurrentFixItem}
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                                </CustomSwiper>
                                            </AnimatedComponent>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {button?.label && (
                            <div className={cn.button}>
                                <Button
                                    customClass={cn.center}
                                    variant="btnBlue"
                                    type="link"
                                    href={button?.link}
                                    label={button?.label}
                                    size="small"
                                    onClick={() => {
                                        if (gtag?.action) event(gtag);
                                    }}
                                    animation={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </AnimatedComponent>
        </div>
    );
}

export default withBlockLinks(TariffCompare);
