import { IInstructionInfoGraphic } from '@/components/blocks/InstructionInfoGrapfic';

export const mockInstructionInfoGraphicBlockData: IInstructionInfoGraphic = {
    _template: 'instructionInfoGraphic',
    title: 'Создайте веб-версию Почта Банк Онлайн',
    list: [
        {
            name: 'item1',
            image: '',
            positionImage: 'bottom',
            desc: '1. В браузере Safari на iPhone <a href="#">откройте сайт Почта Банка</a>',
        },
        {
            name: 'item2',
            image: '',
            positionImage: 'top',
            desc: '2. Нажмите «Поделиться» и выберите «На экран домой»',
        },
        {
            name: 'item3',
            image: '',
            positionImage: 'bottom',
            desc: '3. На экране смартфона появится иконка Почта Банка',
        },
        {
            name: 'item4',
            image: '',
            positionImage: 'bottom',
            desc: '4. Входите по Face ID',
        },
    ],
};

export const mockInstructionInfoGraphicBlockTinaData: IInstructionInfoGraphic & { section: number } = {
    _template: 'instructionInfoGraphic',
    section: null,
    title: 'Заголовок',
    list: [
        {
            name: 'item1',
            image: '',
            positionImage: 'bottom',
            desc: 'Описание',
        },
        {
            name: 'item2',
            image: '',
            positionImage: 'bottom',
            desc: 'Описание',
        },
        {
            name: 'item3',
            image: '',
            positionImage: 'bottom',
            desc: 'Описание',
        },
    ],
};
