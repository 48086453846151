export const mockLinkingListBlockData = {
    title: 'Другие предложения по кредиту',
    limit: 10,
    list: [
        {
            text: 'Кредит наличными с 18 лет',
            link: '#',
        },
        {
            text: 'Кредит на товары',
            link: '#',
        },
        {
            text: 'Кредит по низкой ставке',
            link: '#',
        },
        {
            text: 'Кредит по паспорту',
            link: '#',
        },
        {
            text: 'Кредит на карту',
            link: '#',
        },
        {
            text: 'Кредит наличными',
            link: '#',
        },
        {
            text: 'Кредит наличными с 19 лет',
            link: '#',
        },
        {
            text: 'Кредит без визита в банк',
            link: '#',
        },
        {
            text: 'Кредит на 1 год',
            link: '#',
        },
        {
            text: 'Срочный кредит наличными',
            link: '#',
        },
        {
            text: 'Кредит на обучение',
            link: '#',
        },
        {
            text: 'Кредит наличными на 900000',
            link: '#',
        },
        {
            text: 'Кредит на землю',
            link: '#',
        },
        {
            text: 'Кредит наличными на 950000',
            link: '#',
        },
        {
            text: 'Кредит на свадьбу',
            link: '#',
        },
        {
            text: 'Кредит наличными с 21 года',
            link: '#',
        },
        {
            text: 'Кредит пенсионерам',
            link: '#',
        },
        {
            text: 'Кредит наличными с 20 лет',
            link: '#',
        },
        {
            text: 'Кредит на ремонт',
            link: '#',
        },
        {
            text: 'Кредит на ремонт',
            link: '#',
        },
        {
            text: 'Кредит на карту без отказа',
            link: '#',
        },
        {
            text: 'Кредит наличными с 19 лет',
            link: '#',
        },
        {
            text: 'Кредит без визита в банк',
            link: '#',
        },
        {
            text: 'Кредит на 1 год',
            link: '#',
        },
        {
            text: 'Срочный кредит наличными',
            link: '#',
        },
        {
            text: 'Кредит на обучение',
            link: '#',
        },
        {
            text: 'Кредит наличными на 900000',
            link: '#',
        },
        {
            text: 'Кредит на землю',
            link: '#',
        },
        {
            text: 'Кредит наличными на 950000',
            link: '#',
        },
        {
            text: 'Кредит на свадьбу',
            link: '#',
        },
        {
            text: 'Кредит наличными с 21 года',
            link: '#',
        },
        {
            text: 'Кредит пенсионерам',
            link: '#',
        },
        {
            text: 'Кредит наличными с 20 лет',
            link: '#',
        },
        {
            text: 'Кредит на ремонт',
            link: '#',
        },
        {
            text: 'Кредит на ремонт',
            link: '#',
        },
        {
            text: 'Кредит на карту без отказа',
            link: '#',
        },
    ],
};

export const mockLinkingListBlockDataTina = {
    section: null,
    title: 'Заголовок',
    limit: 10,
    list: [
        {
            text: 'Заголовок',
            link: '#',
        },
    ],
};
