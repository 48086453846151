import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ILinkItem } from '@/types/links/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import Icon from './icon';
import cn from './style.module.sass';

export interface ILinks {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'links';
    /**
     * Список элементов. 3 элемента.
     */
    list: ILinkItem[];
    name?: string;
}

const Links: React.FC<ILinks> = ({ list, name }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.links, 'section')}>
            <div className={cn.linksWrap}>
                <TextField className={cn.title} text={name} name="name" isHTML htmlValue={name} />
                <div className={cn.linksGrid}>
                    {list?.map(({ title, subtitle, image, link, button, gtag }, index) => (
                        <AnimatedComponent
                            className={cn.linksItem}
                            classNameActive={cn.animationInit}
                            key={`key-${index}`}
                            onClick={() => {
                                if (gtag?.action) event(gtag);
                            }}
                            role="presentation"
                        >
                            <a className={cn.linksItemWrap} href={link}>
                                <div className={cn.linksIcon}>
                                    <Icon />
                                </div>
                                <div className={cn.linksTop}>
                                    <TextField
                                        className={cn.linksTitle}
                                        text={title}
                                        name={`list.[${index}].title`}
                                        isHTML
                                        htmlValue={title}
                                    />
                                    <TextField
                                        className={cn.linksSubtitle}
                                        text={subtitle}
                                        name={`list.[${index}].subtitle`}
                                        isHTML
                                        htmlValue={subtitle}
                                    />
                                </div>
                                <div className={cn.linksBot}>
                                    <div className={cn.linksBotWrapper}>
                                        {button?.title && (
                                            <div className={cn.buttonWrapper}>
                                                <Button
                                                    variant="btnBlue"
                                                    type="link"
                                                    label={button.title}
                                                    href={button?.link}
                                                    onClick={() => {
                                                        if (button?.gtag?.action) event(button?.gtag);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className={cn.linksImage}>
                                            <CustomImage
                                                mockWidth="200"
                                                mockHeight="185"
                                                src={image}
                                                alt={title}
                                                fill
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </AnimatedComponent>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(Links);
