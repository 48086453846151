import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ISafely {
    _template: 'safely';
    subhead: string;
    text: string;
    image?: string;
    imageAlt?: string;
    imgMinHigh: string;
    imgMinLow: string;
}
interface IImgItem {
    src: string;
    alt: string;
}

const Safely: React.FC<ISafely> = ({ subhead, text, image, imageAlt, imgMinHigh, imgMinLow }) => (
    <div className={clsx(cn.safely, 'section')}>
        <div className={cn.safelyWrap}>
            <div className={cn.safelyBlock}>
                <div className={cn.safelyGrid}>
                    <div className={clsx(cn.safelyItem, cn.safelyItemContent)}>
                        <div className={cn.safelyContent}>
                            <TextField className={cn.safelyTitle} text={subhead} name="subhead" />
                            <TextField text={text} name="text" className={cn.safelyDesc} htmlValue={text} isHTML />
                        </div>
                    </div>
                    <div className={clsx(cn.safelyItem, cn.safelyItemImage)}>
                        <div className={cn.img}>
                            <div className={cn.imgGrid}>
                                {(imgMinHigh || imgMinLow) && (
                                    <div className={clsx(cn.imgItem, cn.imgItemMin)}>
                                        {imgMinHigh && (
                                            <div className={cn.imgMin}>
                                                <CustomImage src={imgMinHigh} alt={imageAlt} fill />
                                            </div>
                                        )}
                                        {imgMinLow && (
                                            <div className={cn.imgMin}>
                                                <CustomImage src={imgMinLow} alt={imageAlt} fill />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {image && (
                                    <div className={clsx(cn.imgItem, cn.imgItemBig)}>
                                        <div className={cn.imgBig}>
                                            <CustomImage src={image} alt={imageAlt} fill />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default Safely;
