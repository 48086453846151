import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import CustomTooltip from '@/components/Tooltip';
import Button from '@/components/UI/Button';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import { ICalculatorContent, ICreditCalculatorStore } from '@/types/calculator/creditCalculator';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const CreditCalculatorContent: React.FC<ICalculatorContent> = observer(({ title, gtag, button }) => {
    const { resultConfig, paymentSum, percent } = useContext<ICreditCalculatorStore>(CalculatorStoreContextNew);

    const [isEarningShow, setisEarningShow] = useState(false);

    useEffect(() => {
        if (resultConfig.range1.value >= 300000) {
            setisEarningShow(true);
        } else {
            setisEarningShow(false);
        }
    }, [resultConfig.range1.value]);

    /* eslint-disable */
    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={clsx(cn.wrapper)}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            {title && (
                                <TextField
                                    text={title}
                                    customTag="h2"
                                    name="title"
                                    className={cn.title}
                                    isHTML
                                    htmlValue={title}
                                />
                            )}
                            <div className={cn.rangeWrap}>
                                <RangeInput config={resultConfig.range1} noMaxWidth />
                            </div>
                            <div className={cn.about}>
                                <span className={cn.aboutText}>Для получения запрашиваемой суммы необходимо:</span>
                                <div className={cn.list}>
                                    <div className={clsx(cn.aboutIcon, cn['aboutIcon--pasport'])}>Паспорт</div>
                                    <div className={clsx(cn.aboutIcon, cn['aboutIcon--snils'])}>СНИЛС</div>

                                    {isEarningShow && (
                                        <div className={cn.item}>
                                            <div className={cn.itemInner}>
                                                <div className={cn.img}>
                                                    <CustomImage
                                                        src="/img-next/svg/calculators/uslugi.svg"
                                                        alt="Гос. услуги"
                                                        title="Гос. услуги"
                                                        width={36}
                                                        height={36}
                                                    />
                                                </div>
                                                <div className={cn.text}>
                                                    Подтвердить доход
                                                    <CustomTooltip
                                                        html={
                                                            <div className={cn.tooltip}>
                                                                Подтвердить доход онлайн можно не выходя из дома, с
                                                                помощью СМС или учетной записи на сайте Госуслуги
                                                            </div>
                                                        }
                                                    >
                                                        <span className={cn.tooltipText}>онлайн</span>
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Ежемесячный платёж</span>
                                            <span className={cn.resultNumber}>{`${percent} %`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Сумма платежа</span>
                                            <span className={cn.resultNumber}>{paymentSum}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.helpText}>
                                    Предварительный расчет с учетом подключения дополнительных услуг. Не является
                                    офертой.
                                </div>
                                <div className={cn.button}>
                                    <Button
                                        variant={'btnBlue'}
                                        type="link"
                                        href={button?.link || '#'}
                                        label={button?.label || 'Заполнить анкету'}
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => {
                                            if (gtag?.gtagButton) event(gtag.gtagButton);
                                        }}
                                        animation={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CreditCalculatorContent;
