import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import GuaranteeCalculatorContent from '@/components/blocks/calculators/layouts/GuaranteeCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import GuaranteeCalculatorStore from '@/domain/calculator/GuaranteeCalculatorStore';
import { TGuaranteeCalculator } from '@/types/calculator/guarantee';

function GuaranteeCalculator({
    title,
    index,
    _template,
    filterElements,
    taxDeductionPercentage,
    cashback,
}: TGuaranteeCalculator) {
    const tempFilterElements = filterElements?.filter(
        item =>
            item.value >= 0 &&
            item.value.toString().length > 0 &&
            item.percent >= 0 &&
            item.percent.toString().length > 0
    );
    const filterElementsNew = tempFilterElements?.reduce((acc: ICalculatorFilterElement[], curr) => {
        if (!acc.find(item => item.id === curr.id)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    useEffect(() => {
        if (!filterElementsNew) {
            GuaranteeCalculatorStore.initialized = false;
        } else {
            GuaranteeCalculatorStore.init(filterElementsNew, cashback, _template);
        }
    }, [filterElements, cashback, taxDeductionPercentage]);

    return (
        <CalculatorStoreContext.Provider value={GuaranteeCalculatorStore}>
            {GuaranteeCalculatorStore.initialized && (
                <GuaranteeCalculatorContent
                    title={title}
                    index={index}
                    cashback={cashback}
                    taxDeductionPercentage={taxDeductionPercentage}
                />
            )}
        </CalculatorStoreContext.Provider>
    );
}

export default observer(GuaranteeCalculator);
