export const mockPossibilitiesBlockData = {
    title: 'Полезные услуги',
    list: [
        {
            image: '/img-next/png/service/1.png',
            imageAlt: 'Автопогашение',
            name: 'Автопогашение',
            desc: '<ul><li>Регулярное погашение с карты другого банка</li><li>Комиссия за каждую разовую операцию погашения кредита с карты составляет 1,9% от суммы перевода (но не менее 49 рублей). Комиссия за каждую регулярную операцию погашения кредита с карты составляет 29 ₽</li></ul>',
        },
        {
            image: '/img-next/png/service/2.png',
            imageAlt: 'Меняю дату платежа',
            name: 'Меняю дату платежа',
            desc: '<ul><li>Возможность изменить дату платежа в диапазоне от 4 по 28 число</li><li>Услуга доступна при отсутствии неоплаченных в срок платежей.</li><li>Комиссия за подключение — 300 ₽</li></ul>',
        },
    ],
};

export const mockPossibilitiesBlockTinaData = {
    title: 'Заголовок',
    list: [
        {
            image: '',
            imageAlt: 'Описание картинки',
            name: 'Заголовок',
            desc: 'Описание',
        },
    ],
};
