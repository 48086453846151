import React from 'react';

import { TinaPartnersMap } from '@/components/blocks/PartnersMap/index.tina';
import { mockPartnersMapDataTina } from '@/data/blocks/partnersMap';

export const partnersMap = {
    Component: props => <TinaPartnersMap {...props} />,
    template: {
        label: 'Карта партнеров',
        defaultItem: () => mockPartnersMapDataTina,
        fields: [],
    },
};
