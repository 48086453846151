export const mockNewsBlockData = {
    name: 'Новости банка',
    socialsTitle: 'Пресс-служба',
    socials: [
        {
            _template: 'social',
            section: null,
            title: 'Пресс-служба',
            list: [
                {
                    title: 'pr@pochtabank.ru',
                    link: 'mailto:pr@pochtabank.ru',
                    image: '/img-next/svg/social/news/mail.svg',
                },
                {
                    title: '8 (800) 550 0770',
                    link: 'tel:88005500770',
                    image: '/img-next/svg/social/news/phone.svg',
                    gtag: {
                        action: 'test',
                        category: 'test',
                        label: 'test',
                    },
                },
                {
                    title: 'Фейсбук',
                    image: '/img-next/svg/social/news/fb.svg',
                    link: '#',
                },
                {
                    title: 'Яндекс.Дзен',
                    image: '/img-next/svg/social/news/zen.svg',
                    link: '#',
                    gtag: {
                        action: 'test',
                        category: 'test',
                        label: 'test',
                    },
                },
            ],
        },
    ],
    list: [
        {
            title: 'Не забывать про рваную футболку и «подпрыгивать» в Zoom!',
            link: '#',
            date: '2021-08-06T00:00:00Z',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            title: 'Это будет «суперхит»: ставка по кредиту от 5,9% годовых и беспроцентный период на полгода',
            link: '#',
            year: '2020',
            date: '2021-08-11T00:00:00Z',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            title: 'Клиенты Почта Банка за полгода вложили в золото более 1 млрд рублей',
            link: '#',
            date: '2021-08-12T00:00:00Z',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};

export const mockNewsBlockDataTina = {
    section: null,
    name: 'Новости банка',
    socials: [
        {
            _template: 'social',
            title: 'Пресс-служба',
            section: null,
            list: [{ title: 'Заголовок', link: '#', image: '' }],
        },
    ],
    list: [
        {
            title: 'Заголовок',
            link: '#',
            date: '2021-01-01T00:00:00Z',
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};
