import React from 'react';

import { TinaPromoSecondary } from '@/components/blocks/PromoSecondary/index.tina';
import { mockPromoBlockDataTina } from '@/data/blocks/promo';
import { mockPromoSecondaryBlockData } from '@/data/blocks/promoSecondary';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const promoSecondaryBlock = {
    Component: props => <TinaPromoSecondary {...props} />,
    template: {
        label: 'Главный промоблок внутренних страниц версия 3',
        defaultItem: () => mockPromoSecondaryBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'hideBreadcrumbs',
                component: 'toggle',
                label: 'Отключить хлебные крошки',
            },
            {
                label: 'Главная кнопка',
                name: 'entry',
                description: 'Изменить текст. Сделан для примера.',
                component: 'group',
                fields: [
                    {
                        name: 'text',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'color',
                        component: 'select',
                        label: 'Цвет элемента',
                        options: [
                            {
                                value: 'default',
                                label: 'Обычный',
                            },
                            { value: 'white', label: 'Белый' },
                        ],
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Баннер',
                name: 'banner',
                description: 'Изменить текст. Сделан для примера.',
                component: 'group',
                fields: [
                    {
                        label: 'Баннер. Красно-синяя полоса. Заголовок.',
                        name: 'line',
                        description: 'Изменить текст. Сделан для примера.',
                        component: 'group',
                        fields: [
                            {
                                name: 'hideBackground',
                                component: 'toggle',
                                label: 'Сделать фон прозрачным.',
                            },
                            {
                                component: 'select',
                                name: 'type',
                                label: 'Тип',
                                options: [
                                    {
                                        value: 'main',
                                        label: 'Основной',
                                    },
                                    {
                                        value: 'secondary',
                                        label: 'Второстепенный',
                                    },
                                ],
                            },
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Баннер. Красно-синяя полоса. Заголовок.',
                            },
                            {
                                name: 'subtitle',
                                component: 'textarea',
                                label: 'Баннер. Красно-синяя полоса. Описание.',
                            },
                        ],
                    },
                    {
                        name: 'description',
                        component: 'text',
                        label: 'Баннер. Текстовое описание.',
                    },
                    {
                        component: 'select',
                        name: 'color',
                        label: 'Цвет',
                        options: [
                            {
                                value: 'black',
                                label: 'Черный',
                            },
                            {
                                value: 'white',
                                label: 'Белый',
                            },
                        ],
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageLink',
                        component: 'text',
                        label: 'Название продуктового параметра или ссылка на изображение',
                    },
                    {
                        name: 'mobileImage',
                        component: 'image',
                        label: 'Изображение для мобильной версии.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageLinkMobile',
                        component: 'text',
                        label: 'Название продуктового параметра или ссылка на изображение для мобильной версии',
                    },
                    {
                        name: 'imageTopMv',
                        component: 'toggle',
                        label: 'Прижать изображение к верхнему краю',
                    },
                    {
                        name: 'imageBottomMv',
                        component: 'toggle',
                        label: 'Прижать изображение к нижнему краю',
                    },
                    {
                        name: 'imageBig',
                        component: 'toggle',
                        label: 'Увеличить контейнер для изображения',
                    },
                    {
                        name: 'withAnimationImage',
                        component: 'toggle',
                        label: 'Выключить/включить видео.',
                    },
                    {
                        name: 'animationImageWebm',
                        component: 'webm',
                        label: 'Видео.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'animationImage',
                        component: 'image',
                        label: 'Анимированное изображение(gif).',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'backgroundImage',
                        component: 'image',
                        label: 'Фоновое Изображение.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'activeImage',
                        component: 'toggle',
                        label: 'Включить/выключить фоновое изображение',
                    },
                    {
                        name: 'activeGradient',
                        component: 'toggle',
                        label: 'Включить/выключить градиент',
                    },
                    {
                        label: 'Баннер. Кнопки.',
                        name: 'buttons',
                        component: 'group-list',
                        description: 'Список кнопок на баннере',
                        itemProps: (item: { text: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.text,
                        }),
                        defaultItem: () => ({
                            text: 'Текст',
                            link: '/',
                            type: 'button',
                        }),
                        fields: [
                            {
                                name: 'text',
                                component: 'text',
                                label: 'Текст.',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка.',
                            },
                            {
                                name: 'type',
                                component: 'select',
                                label: 'Тип элемента',
                                options: [
                                    { value: 'button', label: 'Кнопка-ссылка' },
                                    { value: 'link', label: 'Ссылка' },
                                    { value: 'anchor', label: 'Якорь-кнопка' },
                                    { value: 'anchor-link', label: 'Якорь-ссылка' },
                                ],
                            },
                            {
                                name: 'color',
                                component: 'select',
                                label: 'Цвет элемента',
                                options: [
                                    {
                                        value: 'default',
                                        label: 'Темно-синий',
                                    },
                                    {
                                        value: 'blue',
                                        label: 'Ярко-синий',
                                    },
                                    {
                                        value: 'white',
                                        label: 'Белый',
                                    },
                                    {
                                        value: 'gray',
                                        label: 'Серый',
                                    },
                                    {
                                        value: 'supreme',
                                        label: 'Кнопка для MirSupreme',
                                    },
                                ],
                            },
                            {
                                name: 'isSetMobileAnimate',
                                component: 'toggle',
                                label: 'Включить анимацию в моб версии',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                ],
            },
            {
                label: 'Список элементов',
                name: 'promo',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockPromoBlockDataTina.promo[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Элемент. Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Элемент. Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: 'textarea',
                        label: 'Элемент. Текстовое описание',
                    },
                    {
                        name: 'tooltipText',
                        component: 'textarea',
                        label: 'Элемент. Подсказка',
                    },
                ],
            },
        ],
    },
};
