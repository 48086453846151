import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IUnsubscribeNews {
    _template: 'unsubscribeNews';
    title: string;
}

const UnsubscribeNewsContent = dynamic(async () => import('./UnsubscribeNewsContent'), {
    ssr: false,
});

const UnsubscribeNews: React.FC<IUnsubscribeNews & { index: number }> = ({ index, title }) => (
    <div className="section">
        <UnsubscribeNewsContent title={title} />
    </div>
);

export default withBlockLinks(UnsubscribeNews);
