import React from 'react';

import SalesHRAdvantagesCard from '@/components/blocks/SalesHRLanding/core/SalesHRAdvantages/Card';
import { Advantages } from '@/components/blocks/SalesHRLanding/types/advantages';

import cn from './style.module.sass';

type SalesHRAdvantagesListProps = {
    list: Advantages['list'];
};

function SalesHRAdvantagesList({ list }: SalesHRAdvantagesListProps) {
    if (list.length === 0) return null;

    return (
        <div className={cn.advantagesList}>
            {list.map(({ icon, title }) => (
                <div className={cn.advantagesList__item} key={title}>
                    <SalesHRAdvantagesCard icon={icon} title={title} />
                </div>
            ))}
        </div>
    );
}

export default SalesHRAdvantagesList;
