import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

export interface IMoneyBoxCalculatorStore {
    range: IRangeInput;
    termsElements: IRangeInput;
    paymentValue: number;
    creditValue: number;
    initialized: boolean;
    enableCheckbox?: boolean;
    isChecked?: boolean;
    toggleChecked?: () => void;
    getRate?: () => number;
}

class MoneyBoxCalculatorStore implements IMoneyBoxCalculatorStore {
    public range: IRangeInput;

    public termsElements: IRangeInput;

    // public isPreferential = false;

    public initialized = false;

    public isChecked = true;

    public enableCheckbox = false;

    public rate = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    get termValue(): number {
        return this.termsElements.value;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        const dayPercent = this.rate / 100 / 12;
        const totalPercent = this.termValue * dayPercent;

        return Math.trunc(totalPercent * this.creditValue);
    }

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    getRate = () => this.rate;

    public init = (
        filterElements: ICalculatorFilterElement[],
        termsElements: ICalculatorFilterElement[],
        enableCheckbox: boolean,
        rate: number
    ) => {
        this.range = new RangeInput({
            name: 'creditValue',
            label: 'Сумма вклада',
            elements: filterElements,
            initValue: 100000,
        });

        const termsInitValue = 1;

        this.termsElements = new RangeInput({
            name: 'termValue',
            label: 'Срок',
            elements: termsElements,
            initValue: termsInitValue,
            customFormatValueHandle: (value, _) => `${value} мес.`,
        });

        this.enableCheckbox = enableCheckbox;

        this.rate = rate;

        this.initialized = true;
    };
}

export default new MoneyBoxCalculatorStore();
