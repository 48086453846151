import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import SliderDesktop from '@/components/blocks/Persons/SliderDesktop';
import SliderMobile from '@/components/blocks/Persons/SliderMobile';
import { IPersons, IPersonsItem } from '@/components/blocks/Persons/types';
import { NextArrow, PrevArrow } from '@/components/blocks/TinyGradientCategory/Arrows';
import CustomSwiper from '@/components/CustomSwiper';
import { useTabletSmallWidth } from '@/hooks/useMedia';

import cn from './style.module.sass';

function Persons({ _template, title, list = [] }: IPersons) {
    const isSmallTabletWidth = useTabletSmallWidth();
    const swiperRef = useRef(null);

    const [activeCardIndex, setActiveCardIndex] = useState(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [desktopSliderArray, setDesktopSliderArray] = useState([]);

    useEffect(() => {
        const currentDesktopSliderArray: IPersonsItem[][] = list.reduce<IPersonsItem[][]>((acc, _, index) => {
            if (index % 4 === 0) {
                acc.push(list.slice(index, index + 4));
            }
            return acc;
        }, []);
        setDesktopSliderArray(currentDesktopSliderArray);
    }, [list]);

    const showArrows = list?.length > 4 && !isSmallTabletWidth;

    const settings = {
        className: cn.slider,
        slidesPerView: 1,
        centeredSlides: true,
        effect: 'fade' as 'fade' | 'slide' | 'cube' | 'coverflow' | 'flip',
        fadeEffect: { crossFade: true },
        noSwiping: true,
        loop: false,
        onActiveIndexChange: (swiper: { activeIndex: number; realIndex: number }) => {
            setCurrentSlideIndex(swiper.activeIndex);
        },
    };

    if (isSmallTabletWidth) {
        return <SliderMobile list={list} />;
    }

    return (
        <div className={clsx(cn.wrapper, isSmallTabletWidth && cn.hidden, 'section')}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <CustomSwiper {...settings} ref={swiperRef} allowTouchMove={false}>
                {desktopSliderArray?.map((items, index) => (
                    <SwiperSlide key={index + Math.random()} className={clsx(cn.slide)}>
                        <SliderDesktop
                            setActiveCardIndex={setActiveCardIndex}
                            activeCardIndex={activeCardIndex}
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            currentSlideContent={items}
                        />
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            {showArrows && <PrevArrow swiperRef={swiperRef} disabled={currentSlideIndex === 0} />}
            {showArrows && (
                <NextArrow swiperRef={swiperRef} disabled={currentSlideIndex === desktopSliderArray.length - 1} />
            )}
        </div>
    );
}

export default Persons;
