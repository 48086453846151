import React from 'react';

import { TinaPayPlatforms } from '@/components/blocks/PayPlatforms/index.tina';
import { mockPayPlatformsDataTina } from '@/data/blocks/payPlatforms';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const payPlatformsBlock = {
    Component: props => <TinaPayPlatforms {...props} />,
    template: {
        label: 'Платежные агрегаты',
        defaultItem: () => mockPayPlatformsDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { alt: string }, index: number) => ({
                    key: index,
                    label: item.alt,
                }),
                defaultItem: () => mockPayPlatformsDataTina.list[0],
                fields: [
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'alt',
                        component: 'text',
                        label: 'Альтернативный текст изображения',
                    },
                ],
            },
        ],
    },
};
