import React from 'react';

import { TinaCashCreditBlock } from '@/components/blocks/CashCredit/index.tina';
import { mockCashCreditBlockTinaData } from '@/data/blocks/cashCredit';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const cashCreditBlock = {
    Component: props => <TinaCashCreditBlock {...props} />,
    template: {
        label: 'Кредит наличными на карту',
        defaultItem: () => mockCashCreditBlockTinaData,
        fields: [
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },

            {
                name: 'imageAlt',
                component: 'text',
                label: 'Описание к изображению',
            },
            {
                name: 'subhead',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'backgroundColor',
                component: 'select',
                label: 'Цвет фона',
                options: [
                    {
                        value: 'blue',
                        label: 'Голубой',
                    },
                    {
                        value: 'white',
                        label: 'Белый',
                    },
                ],
            },
        ],
    },
};
