export const mockConfirmEmailFormBlockData = {
    title: 'Форма для подтверждения электронной почты',
    successTitle: 'Спасибо за обращение! Мы скоро свяжемся с Вами.',
    description: '',
};

export const mockConfirmEmailFormBlockDataTina = {
    title: 'Заголовок',
    successTitle: 'Успешный заголовок.',
    description: 'Описание',
};
