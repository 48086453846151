import React from 'react';

import { TinaSavings } from '@/components/blocks/frames/PollSavings/index.tina';

export const savingsBlock = {
    Component: props => <TinaSavings {...props} />,
    template: {
        label: 'Накопления',
        fields: [],
    },
};
