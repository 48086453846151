import React from 'react';

import { TinaFeedbackFormMortgage } from '@/components/blocks/FeedbackFormMortgage/index.tina';
import { mockFeedbackFormBlockDataTina } from '@/data/blocks/feedbackForm';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const feedbackFormMortgageBlock = {
    Component: props => <TinaFeedbackFormMortgage {...props} />,
    template: {
        label: 'Форма обратной связи - Ипотека',
        defaultItem: () => mockFeedbackFormBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'successTitle',
                component: 'text',
                label: 'Успешный заголовок',
            },
            {
                name: 'description',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
        ],
    },
};
