import clsx from 'clsx';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useMedia } from 'react-use';

import FullScreenTooltip from '@/components/blocks/FullScreenTolltip';
import ErrorFallback from '@/components/UI/ErrorFallback';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useTooltip } from '@/hooks/useTooltip';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

type CalculatorOfferProps = {
    sum: string;
    percent: number;
    percentTooltip?: string;
    title: string;
    withMaxWidth?: boolean;
    hideRate?: boolean;
};

const CalculatorOffer: FC<CalculatorOfferProps> = ({
    sum,
    percent,
    title,
    withMaxWidth,
    percentTooltip,
    hideRate = false,
}) => {
    const isMaxMobile = useMedia(`(max-width: ${MOBILE_WIDTH}px)`);

    const { show, hide, isVisible } = useTooltip();

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={clsx(cn.label, !isMaxMobile && cn.hidden)}>
                <div className={cn.labelContent}>{title}</div>
            </div>

            <div className={clsx(cn.wrapper, withMaxWidth && cn.withMaxWidth)}>
                <div className={cn.content}>
                    <div className={clsx(cn.label, isMaxMobile && cn.hidden)}>
                        <div className={cn.labelContent}>{title}</div>
                    </div>
                    <div className={cn.group}>
                        <div className={cn.groupWrapper}>
                            <div className={cn.month}>
                                <div className={cn.title}>{sum}</div>
                                <div className={cn.subtitle}>Платёж в месяц</div>
                            </div>
                            {!hideRate && (
                                <div className={cn.rate}>
                                    <div className={cn.title}>
                                        <span>от {percent}%</span>

                                        {percentTooltip?.length > 0 && (
                                            <span role="presentation" className={cn.tooltip} onClick={show}>
                                                <TooltipIcon />
                                            </span>
                                        )}
                                    </div>

                                    <div className={cn.subtitle}>Ставка</div>

                                    {percentTooltip?.length > 0 && (
                                        <FullScreenTooltip
                                            tooltipText={percentTooltip}
                                            handleClose={hide}
                                            isOpen={isVisible}
                                            customClass={cn.tooltipText}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default CalculatorOffer;
