import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { withComponentView } from '@/components/HOC/withComponentView';
import { withExternalFrameView } from '@/components/HOC/withExternalFrameView';

export interface INewYearQuiz {
    _template: 'newYearQuiz';
    title: string;
}

const NewYearQuizContent = dynamic(async () => import('./NewYearQuizContent'), { ssr: false });

const NewYearQuiz: React.FC<INewYearQuiz & { index: number }> = ({ title }) => (
    <div className="section">
        <NewYearQuizContent title={title} />
    </div>
);

export default withBlockLinks(withComponentView(withExternalFrameView(NewYearQuiz)));
