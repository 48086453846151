import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IInfoBlockCredit {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'infoBlockCredit';
    /**
     * Список категорий
     */
    backgroundImg: string;
    image: string;
    title: string;
    desc: string;
}

const InfoBlockCredit: React.FC<IInfoBlockCredit> = ({ backgroundImg, image, title, desc }) => (
    <AnimatedComponent className={clsx(cn.infoBlockCredit, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.infoBlockCreditWrap}>
            <div
                className={clsx(cn.infoBlockCreditGrid)}
                style={{
                    backgroundImage: `url(${backgroundImg})`,
                }}
            >
                <div className={clsx(cn.infoBlockCreditItem, cn.infoBlockCreditItemImg)}>
                    <div className={clsx(cn.infoBlockCreditImage)}>
                        <div className={clsx(cn.infoBlockCreditImageContainer)}>
                            <CustomImage
                                src={image}
                                alt={title}
                                width={257}
                                height={280}
                                className={cn.infoBlockCreditImageContainerItem}
                            />
                        </div>
                    </div>
                </div>
                <div className={clsx(cn.infoBlockCreditItem, cn.infoBlockCreditItemContent)}>
                    <div className={clsx(cn.infoBlockCreditContent)}>
                        <div className={cn.infoBlockCreditTop}>
                            {title && (
                                <TextField
                                    text={title}
                                    className={cn.infoBlockCreditTitle}
                                    name="title"
                                    htmlValue={title}
                                    isHTML
                                />
                            )}
                            {desc && (
                                <TextField
                                    text={desc}
                                    isHTML
                                    htmlValue={desc}
                                    name="desc"
                                    className={cn.infoBlockCreditDesc}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default withBlockLinks(InfoBlockCredit);
