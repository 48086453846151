export const mockInfoBlockCreditBlockData = {
    backgroundImg: '/img-next/jpg/infoBlockCredit/banner.jpg',
    image: '/img-next/png/infoBlockCredit/title-image.png',
    title: 'Для вас «Гарантированная ставка»',
    desc: 'Хотим сделать условия по кредиту максимально комфортными для вас. С услугой «Гарантированная ставка» мы уменьшим процент по кредиту до 4%.',
};

export const mockInfoBlockCreditBlockDataTinaDB = {
    backgroundImg: '',
    image: '',
    title: 'Заголовок',
    desc: 'Описание',
};
