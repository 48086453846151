import React from 'react';

import { Offer } from '@/components/blocks/SalesHRLanding/types/offers';

import cn from './style.module.sass';

type SalesHRInnerListProps = {
    list: Offer['list'];
};

function SalesHRInnerList({ list }: SalesHRInnerListProps) {
    const { title, items } = list;

    return (
        <div className={cn.offersInnerList}>
            <div className={cn.offersInnerList__title} dangerouslySetInnerHTML={{ __html: title }} />
            {items.map(({ icon, name }) => (
                <div className={cn.offersInnerList__item} key={name}>
                    <div className={cn.offersInnerList__icon}>{icon}</div>
                    <div className={cn.offersInnerList__name} dangerouslySetInnerHTML={{ __html: name }} />
                </div>
            ))}
        </div>
    );
}

export default SalesHRInnerList;
