import React from 'react';

import PboFaqDetailMobileNavigation from '@/components/blocks/PboFaqDetail/PboFaqDetailMobileNavigation';
import { TPboFaqItem } from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebarLink';
import PboFaqDetailSidebarNavigation from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebarNavigation';
import PromotionGradientBanner from '@/components/blocks/PromotionGradientBanner';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IPromotion {
    text: string;
    mobileText: string;
    button?: string;
    link?: string;
    image: string;
    isMobileInitial?: boolean;
}

export interface IPboFaqDetailSidebar {
    list: TPboFaqItem[];
    promotion: IPromotion;
}

const PboFaqDetailSidebar = ({ list, promotion }: IPboFaqDetailSidebar) => {
    const width = useResize();
    const isMobile = width <= MOBILE_WIDTH;

    const showMobileNavigation = isMobile && !!list.length;
    const showDesktopNavigation = !isMobile && !!list.length;

    return (
        <div className={cn.wrapper}>
            <div className={cn.promotion}>
                <PromotionGradientBanner promotion={promotion} _template="promotionGradientBanner" />
            </div>
            {showDesktopNavigation && <PboFaqDetailSidebarNavigation list={list} />}
            {showMobileNavigation && <PboFaqDetailMobileNavigation list={list} />}
        </div>
    );
};

export default PboFaqDetailSidebar;
