import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import Collapsible from 'react-collapsible';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import Button from '@/components/UI/Button';
import Title from '@/components/UI/Title';
import { ICardsItemWithActive } from '@/types/cards/item';
import { ITag } from '@/types/tags';

import AnimatedComponent from '../../Animation';
import CardsItem from '../../CardsItem';
import Arrows from './arrows';
import cn from './style.module.sass';

export interface ICards {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'creditCardsList';
    /**
     * Список категорий
     */
    title: string;
    list: ICardsItemWithActive[];
    tagList?: ITag[];
    cardLimit?: number;
}

const Cards: React.FC<ICards> = ({ list, cardLimit = 1, title, tagList }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);
    const [hidden, setHidden] = useState(true);
    const tagsEnabled = tagList?.length > 0;

    const activeList = useMemo(() => {
        const filteredList = list?.reduce((acc: ICardsItemWithActive[], current, index) => {
            if (!current.archive) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);

        if (!tagsEnabled) return filteredList;
        if (tagsEnabled && !selectedTag) {
            return filteredList.filter(current => current.tags?.includes(tagList[0]?.xmlId));
        }
        return filteredList.filter(current => current.tags?.includes(selectedTag));
    }, [list, cardLimit, tagList, selectedTag]);

    const inActiveList = useMemo(() => {
        const filteredList = list?.reduce((acc: ICardsItemWithActive[], current, index) => {
            if (current.archive) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);

        if (!tagsEnabled) return filteredList;
        return filteredList.filter(current => current.tags?.includes(selectedTag));
    }, [list, cardLimit, tagList, selectedTag]);

    const getTrigger = (trigger: string) => (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger}>{trigger}</div>
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </div>
    );

    return (
        <div className={clsx(cn.cards, 'section')}>
            <div className={cn.cardsWrap}>
                {title && (
                    <div className={cn.titleWrapper}>
                        <Title title={title} level={3} name="title" isHTML htmlValue={title} />
                    </div>
                )}
                {tagsEnabled && (
                    <div className={cn.tags}>
                        <TagList
                            tagList={tagList}
                            onClick={setSelectedTag}
                            activeTag={!selectedTag ? tagList[0]?.xmlId : selectedTag}
                        />
                    </div>
                )}
                <AnimatedComponent className={cn.cardsActive} classNameActive={cn.animationInit} key={selectedTag}>
                    {activeList
                        ?.filter((current, index) => !hidden || index + 1 <= cardLimit)
                        ?.map((item, index) => (
                            <CardsItem key={index} {...item} />
                        ))}
                    {hidden && activeList?.length > cardLimit && (
                        <Button
                            variant="btnBlueBorder"
                            type="button"
                            label="Показать ещё"
                            customClass={clsx('center', 'full', cn.btnAll)}
                            onClick={() => setHidden(false)}
                            darkWhite
                        />
                    )}
                </AnimatedComponent>
                {inActiveList?.length > 0 && (
                    <AnimatedComponent
                        className={clsx(cn.cardsArchive, 'cardsArchive')}
                        classNameActive={cn.animationInit}
                    >
                        <Collapsible
                            trigger={getTrigger('Архивные карты')}
                            triggerOpenedClassName={cn.open}
                            triggerTagName="div"
                            transitionTime={300}
                            easing="ease-in-out"
                            openedClassName={clsx(cn.cardsCollapsibleOpen)}
                            className={clsx(cn.cardsCollapsibleClose)}
                            overflowWhenOpen="visible"
                        >
                            {inActiveList?.map((item, index) => (
                                <CardsItem key={index} {...item} />
                            ))}
                        </Collapsible>
                    </AnimatedComponent>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(Cards);
