import clsx from 'clsx';
import React, { FC, useMemo, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import ReviewsItem from '@/components/blocks/Reviews/item';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IArrowMainArticlesSlider } from '@/types/mainArticlesSlider';
import { IReviews, SwiperRefReviews } from '@/types/reviews';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const PrevArrow: FC<IArrowMainArticlesSlider> = ({ gtag, listLength, swiperRefLink }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRefLink?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrowMainArticlesSlider> = ({ gtag, listLength, swiperRefLink }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRefLink?.current?.swiper?.slideNext();

        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const Review: React.FC<IReviews> = ({ section, list, title, gtag, disableSlider }) => {
    const width = useResize();

    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    const swiperRef: SwiperRefReviews = useRef(null);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.16;
        if (width < MOBILE_WIDTH) return 1.32;
        if (width < TABLET_SMALL_WIDTH) return 2.2;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
        onSlideChange: (swp: { isEnd: boolean; isBeginning: boolean }) => {
            if (swp?.isEnd) {
                setIsEnd(true);
            } else {
                setIsEnd(false);
            }

            if (swp?.isBeginning) {
                setIsBeginning(true);
            } else {
                setIsBeginning(false);
            }
        },
    };

    if (disableSlider) {
        return (
            <div className={clsx(cn.current, 'section')}>
                <div className={cn.currentWrap}>
                    <div className={cn.topWrap}>
                        <div className={cn.topWrapTitle}>
                            {title && (
                                <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} />
                            )}
                        </div>
                    </div>

                    <div className={cn.currentGrid}>
                        <AnimatedComponent
                            className={clsx(cn.currentSlider, cn.currentNoSlider)}
                            classNameActive={cn.animationInit}
                        >
                            {list?.length > 0 && list?.map((item, index) => <ReviewsItem key={index} {...item} />)}
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.current, 'section')}>
            <div className={cn.currentWrap}>
                <div className={cn.topWrapTitle}>
                    {title && <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} />}
                </div>

                {list?.length > 0 && (
                    <div className={cn.currentGrid}>
                        <AnimatedComponent className={cn.currentSlider} classNameActive={cn.animationInit}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <CustomSwiper {...settings} ref={swiperRef}>
                                {list?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <ReviewsItem {...item} />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>

                            {!isBeginning && (
                                <PrevArrow listLength={list?.length} swiperRefLink={swiperRef} gtag={gtag} />
                            )}

                            {!isEnd && <NextArrow listLength={list?.length} swiperRefLink={swiperRef} gtag={gtag} />}
                        </AnimatedComponent>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(Review);
