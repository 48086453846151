import clsx from 'clsx';
import React from 'react';

import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ISidebarInfo {
    _template?: 'sidebarInfo';
    title?: string;
    desc?: string;
    mail?: string;
    phone?: string;
    phoneLink?: string;
    annotation?: string;
    namePrefix?: string;
}

const SidebarInfo: React.FC<ISidebarInfo> = ({ title, desc, phone, phoneLink, mail, annotation, namePrefix }) => (
    <div className={clsx(cn.info, 'sidebarSection')}>
        {title && (
            <TextField
                className={cn.infoTitle}
                text={title}
                isHTML
                htmlValue={title}
                name={`${namePrefix || ''}title`}
            />
        )}
        {desc && (
            <TextField className={cn.infoDesc} text={desc} isHTML htmlValue={desc} name={`${namePrefix || ''}desc`} />
        )}
        {mail && (
            <div className={cn.infoItem}>
                <TextField
                    className={cn.infoMailLink}
                    customTag="a"
                    href={`mailto:${mail}`}
                    text={mail}
                    name={`${namePrefix || ''}mail`}
                />
            </div>
        )}
        {phone && (
            <div className={cn.infoItem}>
                <TextField
                    className={cn.infoPhoneLink}
                    customTag="a"
                    href={`tel:${phoneLink}`}
                    text={phone}
                    name={`${namePrefix || ''}phone`}
                />
            </div>
        )}
        {annotation && (
            <div className={cn.infoItem}>
                <TextField
                    className={cn.annotation}
                    text={annotation}
                    isHTML
                    htmlValue={annotation}
                    name={`${namePrefix || ''}annotation`}
                />
            </div>
        )}
    </div>
);

export default SidebarInfo;
