import { makeAutoObservable, observe } from 'mobx';

import RangeInput, { IElement, IRangeInput } from '@/domain/calculator/blocksStore/Range';
import TermField, { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';

export interface IStudyCalculatorStore {
    term: ITermField;
    range: IRangeInput;
    values: TValues;
    paymentSum: string;
    creditTerm: string;
    initialized: boolean;
    getValue: (key: ValuesKeys) => string;
    chartHandler: (e: CustomEvent<number>) => void;
    calculate: (changedValuePropertyName: ValuesKeys, value: number) => void;
    getRate?: () => number;
}

export type StudyInitPayload = {
    filterElements: IElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
    valueRate?: number;
};

type TValues = {
    tuitionFeePerYear: number;
    numberOfYearsOfEducation: number;
    chartMonths: number;
    insuranceScheme: {
        interestRate: number;
        insuranceCommission: number;
    };
    loanAmount: number;
    loanTerm: number;
    interestRate: number;
    payment: number;
    repaymentOfPrincipal: number;
    repaymentOfAccruedInterest: number;
    balanceOfLoanDebt: number;
};

type ValuesKeys = keyof Omit<TValues, 'insuranceScheme'>;

class StudyCalculatorStore implements IStudyCalculatorStore {
    term: ITermField;

    range: IRangeInput;

    initialized = false;

    values: TValues = {
        tuitionFeePerYear: 100000,
        numberOfYearsOfEducation: 0,
        chartMonths: 6,
        insuranceScheme: {
            interestRate: 9.9,
            insuranceCommission: 0.29,
        },
        loanAmount: 0,
        loanTerm: 0,
        interestRate: 0,
        payment: 1696.4,
        repaymentOfPrincipal: 0,
        repaymentOfAccruedInterest: 1696.4,
        balanceOfLoanDebt: 50000,
    };

    settings = {
        maximumLoanAmount: 2000000,
    };

    rangeDisposer;

    termDisposer;

    valueRate: number;

    constructor() {
        makeAutoObservable(this);
    }

    get creditTerm() {
        const value = this.term.activeTerm.value * 12 + 51;
        return `${value} мес.`;
    }

    get paymentSum() {
        const value = this.range.value * this.term.activeTerm.value;
        const result = value > 2000000 ? 2000000 : value;
        return this.formatStringWithThousandSeparator(`${result}`);
    }

    get rangeValue() {
        return this.range.value;
    }

    get termValue() {
        return this.term.value;
    }

    public getValue = (key: ValuesKeys): string => this.formatStringWithThousandSeparator(this.values[key].toFixed(1));

    public chartHandler = (e: CustomEvent<number>): void => {
        this.values.chartMonths = e.detail;
        this.calculate();
    };

    public init = ({ filterElements, terms, valueRate }: StudyInitPayload) => {
        this.range = new RangeInput({
            name: 'creditSum',
            initValue: 100000,
            label: 'Стоимость обучения в год',
            elements: filterElements,
        });

        this.valueRate = valueRate;

        this.term = new TermField(terms, 3);

        this.initialized = true;

        this.rangeDisposer = observe(this.range, change => {
            if (change.type !== 'update' || change.name !== 'value') return;
            this.calculate();
        });
        this.termDisposer = observe(this.term, change => {
            if (change.type !== 'update' || change.name !== 'activeTerm') return;
            this.values.chartMonths = 6;
            this.calculate();
        });
    };

    public calculate = () => {
        this.values.tuitionFeePerYear = this.rangeValue;

        const loanReceivingDate = new Date(); // Дата получения кредита

        loanReceivingDate.setHours(0, 0, 0, 0);

        let paymentDate = new Date(loanReceivingDate); // Дата платежа
        let nextPaymentDate: Date; // Дата следующего платежа
        const M = 6; // Количество месяцев в семестре
        const T = (this.termValue * 12) / M; // Количество семестров, на которые берется кредит
        let Cr1 = (this.rangeValue * M) / 12; // Кредит за семестр
        const P1 = this.values.insuranceScheme.interestRate / 100; // Годовая ставка кредита
        let C1 = T * Cr1; // Сумма кредита

        C1 = C1 > this.settings.maximumLoanAmount ? this.settings.maximumLoanAmount : C1;
        Cr1 = Math.floor(C1 / T);

        const L = T * 6 + 51; // Срок кредита, мес.
        let S1 = 0; // Платёж по процентам в месяц
        let S2 = 0; // Платёж по основному долгу в месяц
        let S5 = Cr1; // Сумма основного долга
        let M1 = 1; // Текущий месяц

        // Ежемесячный платёж по кредиту
        const MP = (((C1 * P1) / 12) * (1 + P1 / 12) ** (L - M * T - 3)) / ((1 + P1 / 12) ** (L - M * T - 3) - 1);

        // Платёж по страховке в месяц
        const I2 = Cr1 * T * 1.1 * (this.values.insuranceScheme.insuranceCommission / 100);

        // Ежемесячный платеж после окончания учебного заведения
        const MA = Math.ceil((MP + I2) / 100) * 100;

        while (M1 < this.values.chartMonths) {
            nextPaymentDate = new Date(paymentDate);
            this.addMonthsToDate(nextPaymentDate, 1);
            this.correctNextPaymentDateDay(nextPaymentDate);

            S1 =
                ((S5 * P1) / this.getNumberOfDaysInYear(nextPaymentDate.getFullYear())) *
                this.getNumberOfDaysBetweenDates(paymentDate, nextPaymentDate);

            if (M1 >= T * M + 3) {
                S2 = MA - I2 - S1;
            }

            if (S5 <= S2) {
                S2 = S5; // Самый последний платеж
            }

            S5 -= S2;

            if ((M1 === 0 || M1 % M === 0) && M1 < T * M) {
                S5 += Cr1;
            }

            M1 += 1;

            if (S5 <= 0) {
                break;
            }

            paymentDate = new Date(nextPaymentDate);
        }

        this.values.loanAmount = C1;
        this.values.loanTerm = L;
        this.values.interestRate = this.values.insuranceScheme.interestRate;
        this.values.payment = S1 + I2 + S2;
        this.values.repaymentOfPrincipal = S2;
        this.values.repaymentOfAccruedInterest = S1 + I2;
        this.values.balanceOfLoanDebt = S5;
    };

    private formatStringWithThousandSeparator = (value: string) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;

    private correctNextPaymentDateDay = (paymentDate: Date) => {
        let currentDay = paymentDate.getDate();

        if (currentDay === 1 || currentDay === 2 || currentDay === 3) {
            currentDay += 3;
        } else if (currentDay === 29 || currentDay === 30 || currentDay === 31) {
            currentDay -= 3;
        }

        paymentDate.setDate(currentDay);
    };

    private getNumberOfDaysInMonth = (year: number, month: number) => {
        const isLeap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

        return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };

    private getNumberOfDaysInYear = (year: number) => {
        let totalDays = 0;

        for (let i = 0; i < 12; i += 1) {
            totalDays += this.getNumberOfDaysInMonth(year, i);
        }

        return totalDays;
    };

    private addMonthsToDate = (date: Date, monthsNumber: number) => {
        const currentMonthDate = date.getDate();

        date.setDate(1);
        date.setMonth(date.getMonth() + monthsNumber);
        date.setDate(Math.min(currentMonthDate, this.getNumberOfDaysInMonth(date.getFullYear(), date.getMonth())));
    };

    private getNumberOfDaysBetweenDates = (date1: Date, date2: Date) =>
        Math.floor((date2.valueOf() - date1.valueOf()) / 86400000);
}

export default new StudyCalculatorStore();
