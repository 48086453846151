import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import Arrows from '@/components/blocks/Questions/arrows';
import { ScrollLink } from '@/components/UI/ScrollLink';

import { ISidebarAnchorLinksProps } from './iSidebarAnchorLinks';
import cn from './style.module.sass';

const RollableSideBarAnchorLinks: React.FC<ISidebarAnchorLinksProps> = ({ list, scrollOffset }) => {
    const generateUniqueKey = () => `${Math.random().toString(36)}`;

    const getTrigger = (trigger: string) => (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger}>{trigger}</div>
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </div>
    );

    return (
        <div className={clsx(cn.wrapper, cn.wrapperRollable, 'sidebarSection')}>
            <Collapsible
                trigger={getTrigger('В этой статье:')}
                triggerOpenedClassName={cn.open}
                triggerTagName="div"
                transitionTime={300}
                easing="ease-in-out"
                className={clsx(cn.innerWrapper)}
                checked={false}
            >
                {list &&
                    list?.length > 0 &&
                    list?.map(item => (
                        <div key={generateUniqueKey() + item.linkName} className={clsx(cn.item, cn.accordionItem)}>
                            <ScrollLink
                                to={item.linkHref}
                                offset={-scrollOffset}
                                activeClass="menuLinkActive"
                                className={cn.link}
                            >
                                {item.linkName}
                            </ScrollLink>
                        </div>
                    ))}
            </Collapsible>
        </div>
    );
};

export default RollableSideBarAnchorLinks;
