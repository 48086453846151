import { IMicroTiles } from '@/components/blocks/MicroTiles';

export const microTilesMockData: IMicroTiles = {
    _template: 'microTiles',
    titleBlock: 'Заголовок',
    list: [
        {
            listName: 'Название',
            image: '/img-next/Group1.png',
            listLink: '',
            width: 'big',
        },
        {
            listName: 'Название',
            image: '/img-next/Group1.png',
            listLink: '',
            width: 'big',
        },
        {
            listName: 'Название',
            image: '/img-next/Group1.png',
            listLink: '',
            width: 'medium',
        },
        {
            listName: 'Название',
            image: '/img-next/Group1.png',
            listLink: '',
            width: 'medium',
        },
        {
            listName: 'Название',
            image: '/img-next/Group1.png',
            listLink: '',
            width: 'medium',
        },
    ],
};
