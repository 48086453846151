export const mockPayPlatformsData = {
    title: 'Получайте деньги любым удобным способом',
    list: [
        {
            img: '/img-next/png/payPlatforms/Google-Pay.png',
            link: '#',
            alt: 'Google Pay',
        },
        {
            img: '/img-next/png/payPlatforms/Apple-Pay.png',
            link: '#',
            alt: 'Apple Pay',
        },
        {
            img: '/img-next/png/payPlatforms/visa.png',
            link: '#',
            alt: 'Visa',
        },
        {
            img: '/img-next/png/payPlatforms/Mastercard.png',
            link: '#',
            alt: 'Mastercard',
        },
        {
            img: '/img-next/png/payPlatforms/mir.png',
            link: '#',
            alt: 'МИР',
        },
        {
            img: '/img-next/png/payPlatforms/SamsungPay.png',
            link: '#',
            alt: 'Samsung Pay',
        },
    ],
};

export const mockPayPlatformsDataTina = {
    title: 'Заголовок',
    list: [
        {
            img: '',
            alt: 'Изображение',
        },
    ],
};
