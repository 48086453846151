import React from 'react';

import { TinaIndication } from '@/components/blocks/Indication/index.tina';
import { mockIndicationBlockDataTina } from '@/data/blocks/indication';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const indicationBlock = {
    Component: props => <TinaIndication {...props} />,
    template: {
        label: 'Блок с цифровыми данными',
        defaultItem: () => mockIndicationBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'rows',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockIndicationBlockDataTina.rows[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { year: string }, index: number) => ({
                            key: index,
                            label: item.year,
                        }),
                        defaultItem: () => mockIndicationBlockDataTina.rows[0].list[0],
                        fields: [
                            {
                                name: 'year',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'numbers',
                                component: 'text',
                                label: 'Описание',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
