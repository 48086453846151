import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import SpecialAdvantageItem from '@/components/blocks/advantages/SpecialAdvantages/item';
import SpecialAdvantagesMobileList from '@/components/blocks/advantages/SpecialAdvantages/mobileList';
import TextField from '@/components/TextField';
import { useTabletMobileWidth } from '@/hooks/useMedia';
import { ISpecialAdvantagesList } from '@/types/specialAdvantages';

import cn from './style.module.sass';

function SpecialAdvantagesList({ list, name, description }: ISpecialAdvantagesList) {
    const isTablet = useTabletMobileWidth(false);

    const topRef = useRef<HTMLDivElement>(null);

    return (
        <div className={clsx(cn.advantagesGrid)}>
            <div className={cn.top} ref={topRef}>
                <div className={clsx(cn.topItem, cn.topItemTitle)}>
                    {name && <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />}
                    {description && (
                        <TextField
                            text={description}
                            name="description"
                            className={cn.description}
                            isHTML
                            htmlValue={description}
                        />
                    )}
                </div>
            </div>

            {isTablet ? (
                <SpecialAdvantagesMobileList
                    list={list}
                    name={name}
                    offset={topRef.current?.offsetHeight ? topRef.current.offsetHeight + 26 : 0}
                />
            ) : (
                <div className={cn.advantagesList}>
                    {list.map((item, index) => (
                        <SpecialAdvantageItem {...item} key={`main-${index}`} pos={index} />
                    ))}
                </div>
            )}
        </div>
    );
}

export default observer(SpecialAdvantagesList);
