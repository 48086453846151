/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import ItemTagMapper from '@/components/blocks/advantages/Advantages-v2/item.tag.mapper';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useFixHtml } from '@/hooks/useFixHtml';
import { useResize } from '@/hooks/useResize';
import { IAdvantagesItem } from '@/types/advantages/item';
import { TABLET_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

import FullScreenTolltip from '../../FullScreenTolltip';
import cn from './style.module.sass';

const AdvantageItemV2: React.FC<IAdvantagesItem & { pos: number }> = observer(
    ({
        link,
        title,
        subtitle,
        subtitleMobile,
        image,
        size = 'small',
        tooltipText,
        pos,
        button,
        isHoverNeeded = false,
        bgColor = '',
        isClickable = false,
        mobileBgColor,
    }) => {
        const width = useResize();
        const isDesktop = width > TABLET_WIDTH && typeof window !== 'undefined';

        const [showToolTip, setShowTooltip] = useState<boolean>(false);

        const { fix, unfix } = useFixHtml();
        const show = () => {
            fix();
            setShowTooltip(true);
        };

        const hide = () => {
            unfix();
            setShowTooltip(false);
        };

        const sizeToNameMapping = {
            small: 4,
            middle: 6,
            big: 8,
            full: 8,
        };

        return (
            <ItemTagMapper
                className={clsx(
                    cn.advantagesItem,
                    cn[`advantagesItem${sizeToNameMapping[size]}`],
                    bgColor && cn[`bgColor--${bgColor}`],
                    isClickable && cn.clickable
                )}
                isClickable={isClickable}
                key={pos}
                link={link}
            >
                <div
                    className={clsx(cn.advantagesItemWrap, isHoverNeeded && cn.advantagesItemHover)}
                    role="presentation"
                    style={isDesktop ? {} : { background: mobileBgColor }}
                >
                    <div className={cn.advantagesItemGrid}>
                        <div className={cn.tooltip}>
                            {tooltipText?.length > 0 && (
                                <div onClick={show}>
                                    <TooltipIcon />
                                </div>
                            )}
                        </div>
                        <div className={cn.advantagesContent}>
                            {link && !isClickable && (
                                <TextField
                                    customTag="a"
                                    href={link}
                                    text={title}
                                    name={`list.[${pos}].title`}
                                    isHTML
                                    htmlValue={title}
                                    className={cn.advantagesTitle}
                                />
                            )}

                            {isClickable && title && (
                                <TextField
                                    text={title}
                                    name={`list.[${pos}].title`}
                                    isHTML
                                    htmlValue={title}
                                    className={cn.advantagesTitle}
                                />
                            )}

                            {!link && title && (
                                <TextField
                                    text={title}
                                    name={`list.[${pos}].title`}
                                    isHTML
                                    htmlValue={title}
                                    className={cn.advantagesTitle}
                                />
                            )}
                            {isDesktop && subtitle && (
                                <TextField
                                    text={subtitle}
                                    name={`list.[${pos}].subtitle`}
                                    isHTML
                                    htmlValue={subtitle}
                                    className={cn.advantagesSubtitle}
                                />
                            )}
                            {!isDesktop && subtitleMobile && (
                                <TextField
                                    text={subtitleMobile}
                                    name={`list.[${pos}].subtitleMobile`}
                                    isHTML
                                    htmlValue={subtitleMobile}
                                    className={cn.advantagesSubtitle}
                                />
                            )}
                            {!isDesktop && !subtitleMobile && subtitle && (
                                <TextField
                                    text={subtitle}
                                    name={`list.[${pos}].subtitle`}
                                    isHTML
                                    htmlValue={subtitle}
                                    className={cn.advantagesSubtitle}
                                />
                            )}
                            {button?.label && (
                                <div className={cn.advantagesBtn}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={button.link}
                                        label={button.label}
                                        animation={false}
                                        onClick={() => {
                                            if (button?.gtag?.action) event(button?.gtag);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {image && (
                            <div className={cn.advantagesImageWrap}>
                                <div className={cn.advantagesImage}>
                                    <CustomImage src={image} alt={title} mockWidth="200" mockHeight="200" fill />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={cn.advantagesBotDecor} />
                </div>

                <FullScreenTolltip tooltipText={tooltipText} handleClose={hide} isOpen={showToolTip} />
            </ItemTagMapper>
        );
    }
);

export default AdvantageItemV2;
