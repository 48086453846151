import React from 'react';

import { TinaQuoteHr } from '@/components/blocks/QuoteHr/index.tina';
import { mockQuoteHrBlockDataTina } from '@/data/blocks/quoteHr';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const quoteHrBlock = {
    Component: props => <TinaQuoteHr {...props} />,
    template: {
        label: 'HR: Цитата',
        defaultItem: () => mockQuoteHrBlockDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'items',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockQuoteHrBlockDataTina.items,
                fields: [
                    {
                        name: 'photo',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'position',
                        component: HtmlEditorFeatured,
                        label: 'Позиция',
                    },
                    {
                        name: 'quote',
                        component: HtmlEditorFeatured,
                        label: 'Текст',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Модальное окно: Текст',
                    },
                ],
            },
        ],
    },
};
