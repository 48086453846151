import React from 'react';

import { TinaHypothecReadyHousingCalculator } from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/index.tina';
import { hypothecReadyHousingCalculatorData } from '@/data/blocks/calculators/hypothecReadyHousingCalc';
import HtmlEditor from '@/tina/plugins/htmlEditor';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const hypothecReadyHousingCalculatorBlock = {
    Component: props => <TinaHypothecReadyHousingCalculator {...props} />,
    template: {
        label: 'Ипотечный калькулятор на готовое жилье',
        defaultItem: hypothecReadyHousingCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditor,
                label: 'Заголовок',
            },
            {
                name: 'subtitle',
                component: HtmlEditor,
                label: 'Подзаголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
