import clsx from 'clsx';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useMedia } from 'react-use';

import ErrorFallback from '@/components/UI/ErrorFallback';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

interface CalculatorOfferProps {
    sum: string;
    title: string;
    withMaxWidth?: boolean;
}
const CalculatorOffer: FC<CalculatorOfferProps> = ({ sum, title, withMaxWidth }) => {
    const isMaxMobile = useMedia(`(max-width: ${MOBILE_WIDTH}px)`);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={clsx(cn.label, !isMaxMobile && cn.hidden)}>
                <div className={cn.labelContent}>{title}</div>
            </div>

            <div className={clsx(cn.wrapper, withMaxWidth && cn.withMaxWidth)}>
                <div className={cn.content}>
                    <div className={clsx(cn.label, isMaxMobile && cn.hidden)}>
                        <div className={cn.labelContent}>{title}</div>
                    </div>
                    <div className={cn.group}>
                        <div className={cn.groupWrapper}>
                            <div className={cn.month}>
                                <div className={cn.title}>{sum}</div>
                                <div className={cn.subtitle}>Платёж в месяц</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default CalculatorOffer;
