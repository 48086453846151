import React from 'react';

import Button from '@/components/UI/Button';

import cn from './style.module.sass';

interface IProps {
    text?: string;
    type?: string;
    link?: string;
    vacancyLimit?: number;
    onClick?: () => void;
    isShowMore?: boolean;
}

const VacancyButton: React.FC<IProps> = ({ text, type, link, onClick, isShowMore }) => {
    if (type === 'button') {
        return (
            <div className={cn.btnWrap}>
                <div className={cn.btnLimit}>
                    {isShowMore && (
                        <Button
                            variant="btnBlueBorder"
                            type="button"
                            label={text || 'Показать еще'}
                            customClass={cn.btn}
                            darkWhite
                            onClick={onClick}
                            animation={false}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={cn.btnWrap}>
            <Button
                variant="btnBlue"
                type="link"
                href={link}
                label={text || 'Все вакансии'}
                customClass={cn.btn}
                animation={false}
            />
        </div>
    );
};

export default VacancyButton;
