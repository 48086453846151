import React from 'react';

import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

interface IProps {
    href: string;
    button: string;
    buttonType: string;
    gtag: GTagEvent;
}
const CreditCardButton: React.FC<IProps> = ({ href, button, buttonType, gtag }) => {
    const handleClick = () => {
        if (gtag?.action) event(gtag);
    };

    if (buttonType === 'white') {
        return (
            <Button
                variant="btnWhite"
                animation={false}
                type="link"
                label={button}
                href={href}
                size="small"
                onClick={handleClick}
            />
        );
    }
    return (
        <Button
            variant="btnBlueBorder"
            type="link"
            href={href}
            label={button}
            size="small"
            white
            animation={false}
            onClick={handleClick}
        />
    );
};

export default CreditCardButton;
