export const mockPhotoSliderData = {
    _template: 'photoSlider',
    list: [
        {
            image: '/img-next/png/photoSlider/1.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/2.jpg',
            desc: 'Место посадки: Национальный Парк «Мещера». Владимирская область.',
        },
        {
            image: '/img-next/png/photoSlider/3.jpg',
            desc: 'Место посадки: Национальный Парк «Куршская коса».  Калининградская область.',
        },
        {
            image: '/img-next/png/photoSlider/4.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/5.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/6.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/1.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/2.jpg',
            desc: 'Место посадки: Национальный Парк «Мещера». Владимирская область.',
        },
        {
            image: '/img-next/png/photoSlider/3.jpg',
            desc: 'Место посадки: Национальный Парк «Куршская коса».  Калининградская область.',
        },
        {
            image: '/img-next/png/photoSlider/4.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/5.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
        {
            image: '/img-next/png/photoSlider/6.jpg',
            desc: 'Место посадки: Национальный Парк «Бузулукский Бор». Оренбургская и Самарская области.',
        },
    ],
};

export const mockPhotoSliderDataTina = {
    list: [
        {
            image: '',
            desc: 'Описание',
        },
    ],
};
