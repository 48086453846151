import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ReactCardFlip from 'react-card-flip';

import cn from '@/components/blocks/Persons/SliderDesktop/style.module.sass';
import { IPersonsItem } from '@/components/blocks/Persons/types';
import CustomImage from '@/components/CustomImage';
import Button from '@/components/UI/Button';

interface ISlide {
    slideContent: IPersonsItem;
    index: number;
    setActiveCardIndex: (index: number) => void;
    activeCardIndex: number;
}

function Slide({ slideContent, index, activeCardIndex, setActiveCardIndex }: ISlide) {
    const { name, status, img, backSideTitle, backSideDescription, description } = slideContent;
    const [isFlipped, setIsFlipped] = useState(false);
    const [lastHovered, setLastHovered] = useState<number | null>(null);
    useEffect(() => {
        setLastHovered(0);
    }, []);
    const onMouseEnterHandler = (currentIndex: number) => {
        setActiveCardIndex(currentIndex);
        if (index !== 0) setLastHovered(currentIndex);
    };

    return (
        <div
            role="presentation"
            className={clsx(
                index === activeCardIndex ? cn.cardActive : cn.card,
                lastHovered === index && cn.lastHovered
            )}
            key={index}
            onMouseEnter={() => onMouseEnterHandler(index)}
        >
            <ReactCardFlip
                isFlipped={isFlipped}
                flipDirection="horizontal"
                containerClassName={cn.flipWrapper}
                flipSpeedFrontToBack={1}
                flipSpeedBackToFront={1}
            >
                <div className={clsx(cn.cardContent, cn.front)}>
                    <div className={cn.textBlock}>
                        <div className={cn.name}>{name}</div>
                        <div className={cn.status}>{status}</div>
                        <div className={cn.description} dangerouslySetInnerHTML={{ __html: description }} />
                        <Button
                            variant={index === activeCardIndex ? 'btnBlue' : 'btnBlueBorder'}
                            onClick={() => setIsFlipped(!isFlipped)}
                            type="button"
                            label="Подробнее"
                            customClass={cn.button}
                        />
                    </div>
                    <div className={cn.image}>
                        <CustomImage src={img} alt={name} width={280} height={280} />
                    </div>
                </div>
                <div className={clsx(cn.backCardContent, cn.back)}>
                    <div className={cn.textBlock}>
                        <div className={cn.backSideTitle}>{backSideTitle}</div>
                        <div className={cn.description} dangerouslySetInnerHTML={{ __html: backSideDescription }} />
                        <Button
                            variant="btnBlue"
                            onClick={() => setIsFlipped(!isFlipped)}
                            type="button"
                            label="Закрыть"
                            customClass={cn.button}
                        />
                    </div>
                </div>
            </ReactCardFlip>
        </div>
    );
}

export default Slide;
