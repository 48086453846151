import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IMirSupremeCard {
    _template: 'mirSupremeCard';
    title: string;
}

const MirSupremeCardContent = dynamic(async () => import('./MirSupremeCardContent'), {
    ssr: false,
});

const MirSupremeCard: React.FC<IMirSupremeCard & { index: number }> = ({ title }) => (
    <div className="section">
        <MirSupremeCardContent title={title} />
    </div>
);

export default withBlockLinks(MirSupremeCard);
