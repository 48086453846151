import clsx from 'clsx';
import React, { FC, useMemo, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';

import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IServiceItem } from '@/types/service';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';

import ServiceItem from './item';
import cn from './style.module.sass';

export interface IService {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'possibilities';
    title: string;
    list: IServiceItem[];
    disableSlider?: boolean;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ listLength, swiperRef }) => {
    if (listLength < 3) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ listLength, swiperRef }) => {
    if (listLength < 3) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const Possibilities: React.FC<IService> = ({ title, list, disableSlider }) => {
    const width = useResize();

    const swiperRef = useRef(null);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return list.length < 2 ? list.length : 2;
        if (width < MOBILE_SM_WIDTH) return 1.16;
        if (width < MOBILE_WIDTH) return 1.32;
        if (width < TABLET_SMALL_WIDTH) return 2;
        return list.length < 2 ? list.length : 2;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
    };

    if (!list || list?.length <= 0) return null;

    if (disableSlider) {
        return (
            <div className={clsx(cn.service, 'section')}>
                <div className={cn.serviceWrap}>
                    {title && (
                        <TextField text={title} name="title" isTextArea className={cn.serviceTitle} customTag="h3" />
                    )}
                    <div className={cn.serviceGrid}>
                        <div className={clsx(cn.serviceSlider, cn.serviceNoSlider)}>
                            {list?.map(({ name, desc, image, imageAlt }, index) => (
                                <ServiceItem
                                    key={index}
                                    name={name}
                                    desc={desc}
                                    image={image}
                                    imageAlt={imageAlt}
                                    pos={index}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.service, 'section')}>
            <div className={cn.serviceWrap}>
                {title && <TextField text={title} name="title" isTextArea className={cn.serviceTitle} customTag="h3" />}
                <div className={cn.serviceGrid}>
                    <div className={cn.serviceSlider}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <CustomSwiper {...settings} ref={swiperRef}>
                            {list?.map(({ name, desc, image, imageAlt }, index) => (
                                <SwiperSlide key={index}>
                                    <ServiceItem
                                        key={index}
                                        name={name}
                                        desc={desc}
                                        image={image}
                                        imageAlt={imageAlt}
                                        pos={index}
                                    />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                        <PrevArrow listLength={list?.length} swiperRef={swiperRef} />
                        <NextArrow listLength={list?.length} swiperRef={swiperRef} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(Possibilities);
