export const mockOurTargetsDefaultData = {
    targets: [
        {
            title: 'Стратегия развития',
            desc: 'На состоявшемся 11 августа заседании наблюдательного совета «Почта Банка» единогласно утверждена стратегия развития банка на 7 лет. Она развивает основные направления стратегической модели, утвержденной на совещании в Правительстве РФ 24 июля 2015 года.',
            image: '/img-next/png/ourTargets/item1.png',
            size: 'half',
        },
        {
            title: 'О стратегии',
            desc: 'Стратегия предусматривает снижение операционных издержек за счет масштабирования лоукост-модели розничного обслуживания и широкого использования цифровых каналов. При этом инфраструктурный платеж банка Почте России превысит показатель, утвержденный ранее Правительством РФ.',
            image: '/img-next/png/ourTargets/item2.png',
            size: 'half',
        },
        {
            title: 'Наши цели',
            desc: '«Почта Банк» ориентирован на мультиканальную работу с физическими лицами. Целевыми клиентскими сегментами для «Почта Банка» являются интернет-покупатели, зарплатные клиенты, пенсионеры, а также представители малого и микробизнеса.',
            image: '/img-next/png/ourTargets/item3.png',
            size: 'full',
        },
    ],
};

export const mockOurTargetsMockData = {
    heading: 'Заголовок, если нужен',
    targets: [
        {
            title: 'Название 1',
            desc: 'Описание 1',
            image: '',
        },
        {
            title: 'Название 2',
            desc: 'Описание 2',
            image: '',
        },
        {
            title: 'Название 3',
            desc: 'Описание 3',
            image: '',
            size: 'full',
        },
        {
            title: 'Название 4',
            desc: 'Описание 4',
            image: '',
            size: 'full',
        },
        {
            title: 'Название 5',
            desc: 'Описание 5',
            image: '',
        },
        {
            title: 'Название 6',
            desc: 'Описание 6',
            image: '',
        },
    ],
};
