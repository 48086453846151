import clsx from 'clsx';
import React from 'react';

import { protocol } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    href: string;
    type?: 'vk' | 'ok';
    children?: React.ReactNode;
}

const ShareItem: React.FC<IProps> = ({ href, type, children }) => {
    let link: string;

    if (type === 'vk') {
        link = `https://vk.com/share.php?url=${protocol}${process.env.FRONT_ROOT_URL}/${href}`;
    }

    if (type === 'ok') {
        link = `https://connect.ok.ru/offer?url=${protocol}${process.env.FRONT_ROOT_URL}/${href}`;
    }

    return (
        <a
            href={link}
            target="_blank"
            className={clsx(cn.button, type === 'vk' && cn.vk, type === 'ok' && cn.ok)}
            rel="noreferrer"
        >
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                children && <div className={cn.icon}>{children}</div>
            }
            <div className={cn.text}> Поделиться</div>
        </a>
    );
};

export default ShareItem;
