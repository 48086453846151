import clsx from 'clsx';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Feature from '@/domain/feature/Feature';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IDarkBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'darkBanner';
    title?: string;
    text?: string;
    desktopImage?: string;
    tabletImage?: string;
    mobileImage?: string;
}

const DarkBanner: React.FC<IDarkBanner> = ({ title, text, desktopImage, mobileImage, tabletImage }) => {
    const width = useResize();

    const getCurrentImage = useMemo(() => {
        if (width < MOBILE_WIDTH) return mobileImage ?? desktopImage;
        if (width < TABLET_SMALL_WIDTH) return tabletImage ?? desktopImage;
        return desktopImage;
    }, [width, desktopImage, mobileImage, tabletImage]);

    if (!Feature.isFeatureEnabled('enableDarkBanner')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    <div className={cn.textWrapper}>
                        {title && <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />}
                        {text && <TextField className={cn.text} text={text} name="text" isHTML htmlValue={text} />}
                    </div>
                    {getCurrentImage && (
                        <div className={clsx(cn.imageWrap)}>
                            <CustomImage src={getCurrentImage} alt="Почта Банк" mockWidth="300" mockHeight="300" fill />
                        </div>
                    )}
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default withBlockLinks(DarkBanner);
