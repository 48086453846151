import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';

import AnimatedComponent from '@/components/Animation';
import AwardsList, { IAwardsItem } from '@/components/blocks/headManagement/AwardsList';
import SocialColor from '@/components/blocks/headManagement/SocialColor';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useTheme } from '@/context/ThemeProvider';
import { IManagementBanner } from '@/types/headManagement/item';

import cn from './style.module.sass';

export interface IHeadManagement {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'headManagement';

    banner: IManagementBanner;

    awardsList: IAwardsItem[];
}

const HeadManagement: React.FC<IHeadManagement> = observer(({ banner, awardsList }) => {
    const headManagementRef = useRef<HTMLDivElement>(null);
    const { setPromoHeight } = useLayoutStore();

    useEffect(() => {
        if (!headManagementRef.current?.offsetHeight) return;

        setPromoHeight(headManagementRef.current?.offsetHeight);
    }, [headManagementRef, setPromoHeight]);

    const { theme } = useTheme();

    return (
        <AnimatedComponent>
            <div className={clsx(cn.headManagement)}>
                <div className={cn.headManagementWrap}>
                    <div className={cn.headManagementGrid} ref={headManagementRef}>
                        <div
                            className={clsx(cn.headManagementItem, banner?.backgroundImage && cn.headManagementItemBg)}
                            style={{
                                background: banner?.background,
                            }}
                        >
                            <div className={cn.headManagementItemGrid}>
                                <div className={cn.headManagementItemContent}>
                                    <div className={cn.headManagementItemTop}>
                                        <div className={cn.headManagementItemTopLines}>
                                            <div className={cn.headManagementItemTitleWrap}>
                                                <TextField
                                                    text={banner?.line?.title}
                                                    name="banner.line.title"
                                                    customTag="h1"
                                                    className={cn.headManagementItemTitle}
                                                    isHTML
                                                    htmlValue={banner.line.title}
                                                />
                                            </div>
                                            {banner?.line?.subtitle && (
                                                <div className={cn.headManagementItemSubtitle}>
                                                    <TextField
                                                        text={banner?.line?.subtitle}
                                                        name="banner.line.subtitle"
                                                        customTag="span"
                                                        isHTML
                                                        htmlValue={banner.line.subtitle}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {banner?.description && (
                                            <TextField
                                                text={banner.description}
                                                name="banner.description"
                                                className={cn.headManagementItemDesc}
                                                isHTML
                                                htmlValue={banner.description}
                                            />
                                        )}
                                    </div>
                                    {banner?.socialList?.length > 0 && (
                                        <div className={cn.headManagementItemBot}>
                                            <div className={cn.headManagementSocial}>
                                                <SocialColor list={banner.socialList} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={clsx(cn.headManagementItemImage)}>
                                    <div className={cn.headManagementImage}>
                                        <div className={cn.headManagementImageWrapper}>
                                            {banner?.image && (
                                                <CustomImage
                                                    src={banner?.image}
                                                    alt={banner?.line?.title}
                                                    fill
                                                    priority
                                                    loading="eager"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {awardsList?.length > 0 && (
                        <div className={cn.headManagementAwards}>
                            <AwardsList awardsList={awardsList} />
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
});

export default withBlockLinks(HeadManagement);
