import React from 'react';

import { TinaIntroPromo } from '@/components/blocks/IntroPromo/index.tina';
import { mockIntroPromoDataTina } from '@/data/blocks/introPromo';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const introPromoBlock = {
    Component: props => <TinaIntroPromo {...props} />,
    template: {
        label: 'Заглавный промо-баннер',
        defaultItem: () => mockIntroPromoDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'heading',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'hideBreadcrumbs',
                component: 'toggle',
                label: 'Отключить хлебные крошки',
            },
            {
                name: 'subheading',
                component: HtmlEditorFeatured,
                label: 'Подзаголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Текст',
            },
            {
                name: 'descBold',
                component: 'toggle',
                label: 'Сделать жирный текст',
            },
            {
                component: 'select',
                name: 'descSize',
                label: 'Размер шрифта для текста',
                options: [
                    {
                        value: 'normal',
                        label: 'Обычный размер шрифта',
                    },
                    {
                        value: 'big',
                        label: 'Увеличенный размер шрифта (28px)',
                    },
                ],
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                component: 'select',
                name: 'imagePosition',
                label: 'Позиция изображения',
                options: [
                    {
                        value: 'center',
                        label: 'Центр',
                    },
                    {
                        value: 'bottom',
                        label: 'Низ',
                    },
                ],
            },
            {
                name: 'isImageToBottomMobile',
                component: 'toggle',
                label: 'Прижать изображение к низу в моб версии',
            },
            {
                name: 'enableBackgroundImage',
                component: 'toggle',
                label: 'Включить фоновое изображение',
            },
            {
                name: 'backgroundImage',
                component: 'image',
                label: 'Фоновое изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'enableLogo',
                component: 'toggle',
                label: 'Включить логотипы',
            },
            {
                label: 'Логотипы',
                name: 'logo',
                component: 'group-list',
                itemProps: (item: { text: string }, index: number) => ({
                    key: index,
                    label: item.text,
                }),
                defaultItem: () => mockIntroPromoDataTina.logo[0],
                fields: [
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Логотип',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'alt',
                        component: 'text',
                        label: 'Альтернативный текст',
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'enableIsNewDesign',
                        component: 'toggle',
                        label: 'Включить цвета нового дизайна',
                    },
                    {
                        component: 'select',
                        name: 'size',
                        label: 'Размер кнопки',
                        options: [
                            {
                                value: 'default',
                                label: 'Обычный',
                            },
                            {
                                value: 'big',
                                label: 'Большой',
                            },
                        ],
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип всего баннера',
                options: [
                    {
                        value: 'min',
                        label: 'Маленький',
                    },
                    {
                        value: 'max',
                        label: 'Большой',
                    },
                ],
            },
        ],
    },
};
