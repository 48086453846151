import clsx from 'clsx';
import React from 'react';

import PboFaqItem, { TPboFaqItem } from '@/components/blocks/PboFaq/PboFaqItem';
import PromotionGradientBanner from '@/components/blocks/PromotionGradientBanner';

import cn from './style.module.sass';

interface IPromotion {
    text: string;
    mobileText: string;
    button: string;
    link: string;
    image: string;
    isMobileInitial?: boolean;
}

export interface IPboFaq {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'pboFaq';
    list: TPboFaqItem[];
    promotion: IPromotion;
    title: string;
    hideItemsButton?: boolean;
}

const PboFaq = ({ title, list = [], promotion, hideItemsButton }: IPboFaq) => (
    <div className={clsx(cn.wrapper, 'section')}>
        <div className={cn.title}>{title}</div>
        <div className={cn.installPromotion}>
            <PromotionGradientBanner _template="promotionGradientBanner" promotion={promotion} />
        </div>
        <div className={cn.list}>
            {list.map(item => (
                <div className={cn.item} key={item.title}>
                    <PboFaqItem
                        title={item.title}
                        hideItemsButton={hideItemsButton}
                        subSectionLinks={item.subSectionLinks}
                        icon={item.icon}
                    />
                </div>
            ))}
        </div>
    </div>
);

export default PboFaq;
