import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import cn from './style.module.sass';

export interface IShowcaseCard {
    listName: string;
    image: string;
    listLink: string;
}

export const ShowcaseCard: FC<IShowcaseCard> = ({ listName, image, listLink }) => (
    <Link href={listLink || ''} className={cn.showcaseCardLink}>
        <div className={cn.showcaseCard}>
            {image ? (
                <Image className={cn.image} alt={listName} src={image} width={90} height={90} />
            ) : (
                <div className={cn.image} />
            )}
            <div
                className={clsx(cn.title, !listName && cn.titleFiller)}
                dangerouslySetInnerHTML={{ __html: listName }}
            />
        </div>
    </Link>
);
