import clsx from 'clsx';
import React, { useState } from 'react';

import PboFaqDetailSidebarLink, { TPboFaqItem } from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebarLink';
import CustomImage from '@/components/CustomImage';
import { useFixHtml } from '@/hooks/useFixHtml';

import cn from './style.module.sass';

interface IPboFaqDetailMobileNavigation {
    list: TPboFaqItem[];
}

const Trigger = ({ onOpen }: { onOpen: () => void }) => (
    <div className={cn.trigger} onClick={onOpen} role="presentation">
        <div className={cn.left}>
            <div className={cn.icon}>
                <CustomImage src="/img-next/svg/pbo-faq/menu.svg" alt="К выбору раздела" width={42} height={42} />
            </div>
            <div className={cn.title}>Назад к выбору раздела</div>
        </div>
        <div className={cn.right}>
            <CustomImage
                className={cn.arrow}
                src="/img-next/svg/pbo-faq/arrow.svg"
                alt="К выбору раздела"
                width={14}
                height={14}
            />
        </div>
    </div>
);

const PboFaqDetailMobileNavigation = ({ list }: IPboFaqDetailMobileNavigation) => {
    const [isOpen, setIsOpen] = useState(false);
    const { fix, unfix } = useFixHtml();

    const onOpen = () => {
        setIsOpen(true);
        fix();
    };

    const onClose = () => {
        setIsOpen(false);
        unfix();
    };

    return (
        <div className={cn.wrapper}>
            <Trigger onOpen={onOpen} />
            {isOpen && <div className={clsx(cn.overlay)} onClick={onClose} role="presentation" />}
            <div className={clsx(cn.drawer, isOpen && cn.active)}>
                <div className={cn.top}>
                    <CustomImage
                        onClick={onClose}
                        className={cn.close}
                        src="/img-next/svg/pbo-faq/close.svg"
                        alt="Закрыть"
                        width={24}
                        height={24}
                    />
                </div>
                <div className={cn.navigation}>
                    {list.map(item => (
                        <PboFaqDetailSidebarLink
                            title={item.title}
                            icon={item.icon}
                            subSectionLinks={item.subSectionLinks}
                            key={item.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PboFaqDetailMobileNavigation;
