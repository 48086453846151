import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IDepositApplication {
    _template: 'depositApplication';
    title: string;
    typeProduct: string;
    type: string;
    leadDescription?: string;
    withoutRedirect: boolean;
    gtagClick?: GTagEvent;
}

const DepositApplicationContent = dynamic(async () => import('./DepositApplicationContent'), {
    ssr: false,
});

const DepositApplication: React.FC<IDepositApplication & { index: number }> = ({
    title,
    typeProduct = 'Deposit',
    type = 'Nakopitelny',
    leadDescription,
    withoutRedirect = false,
    gtagClick,
}) => (
    <div className="section">
        <DepositApplicationContent
            title={title}
            type={type}
            typeProduct={typeProduct}
            leadDescription={leadDescription}
            withoutRedirect={withoutRedirect}
            gtagClick={gtagClick}
        />
    </div>
);

export default withBlockLinks(DepositApplication);
