import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import IconPdf from '@/components/UI/IconPdf';
import { useResize } from '@/hooks/useResize';
import { IInsuranceItem } from '@/types/insurance/item';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

const InsuranceItem: React.FC<IInsuranceItem> = ({ image, name, link, desc, info, pos, bot }) => {
    const width = useResize();
    const isMobile = width <= TABLET_SMALL_WIDTH && typeof window !== 'undefined';
    const isDesktop = width > TABLET_SMALL_WIDTH || typeof window === 'undefined';
    return (
        <AnimatedComponent className={clsx(cn.item)} classNameActive={cn.animationInit}>
            <div className={cn.itemGrid}>
                <div className={clsx(cn.itemCol, cn.itemColImg)}>
                    <div className={cn.itemMobail}>
                        <TextField
                            text={name}
                            name={`list.${pos}.name`}
                            href={link}
                            isHTML
                            htmlValue={name}
                            customTag="a"
                            className={cn.itemTitle}
                        />

                        <TextField
                            text={desc}
                            name={`list.${pos}.desc`}
                            customTag="span"
                            isHTML
                            htmlValue={desc}
                            className={cn.itemDesc}
                        />
                    </div>
                    <div className={cn.itemImg}>
                        <CustomImage src={image} alt={name} height="250" width="250" />
                    </div>
                </div>
                <div className={clsx(cn.itemCol, cn.itemColContent)}>
                    <div className={cn.itemContent}>
                        <div className={cn.itemContentTop}>
                            <div className={cn.itemDesktop}>
                                <TextField
                                    text={name}
                                    name={`list.${pos}.name`}
                                    href={link}
                                    isHTML
                                    htmlValue={name}
                                    customTag="a"
                                    className={cn.itemTitle}
                                />

                                <TextField
                                    text={desc}
                                    name={`list.${pos}.desc`}
                                    customTag="span"
                                    isHTML
                                    htmlValue={desc}
                                    className={cn.itemDesc}
                                />
                            </div>
                            {info?.length > 0 && (
                                <div className={cn.info}>
                                    {info?.slice(0, 3)?.map(({ title, subtitle }, infoPos) => (
                                        <div className={cn.infoItem} key={infoPos}>
                                            <TextField
                                                text={title}
                                                name={`list.${pos}.info.${infoPos}.title`}
                                                className={cn.infoName}
                                                isHTML
                                                htmlValue={title}
                                            />
                                            <TextField
                                                text={subtitle}
                                                name={`list.${pos}.info.${infoPos}.subtitle`}
                                                className={cn.infoDesc}
                                                isHTML
                                                htmlValue={subtitle}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {bot && (
                            <div className={cn.itemContentBot}>
                                <div className={clsx(cn.bot)}>
                                    <div className={clsx(cn.botGrid)}>
                                        <div className={clsx(cn.botItem, cn.botItemInfo)}>
                                            <div className={cn.botRow}>
                                                <div className={cn.botItem}>
                                                    {bot.documents ? (
                                                        <a href={bot.documents.link} className={cn.documents}>
                                                            <div className={cn.documentsIcon}>
                                                                <IconPdf customClass={cn.pdf} />
                                                            </div>
                                                            <div className={cn.documentsName}>{bot.documents.name}</div>
                                                        </a>
                                                    ) : (
                                                        <TextField
                                                            text={bot.logoName}
                                                            name={bot.logoName}
                                                            className={cn.botName}
                                                            isTextArea
                                                        />
                                                    )}
                                                    {isMobile && bot.link && (
                                                        <TextField
                                                            text={bot.linkName}
                                                            name={bot.linkName}
                                                            className={cn.botDesc}
                                                            isTextArea
                                                            customTag="a"
                                                            href={bot.link}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={clsx(cn.botItem, cn.botItemLogo)}>
                                            <div className={cn.botRow}>
                                                {isDesktop && bot.link && (
                                                    <div className={cn.botItem}>
                                                        <TextField
                                                            text={bot.linkName}
                                                            name={bot.linkName}
                                                            className={cn.botDesc}
                                                            isTextArea
                                                            customTag="a"
                                                            href={bot.link}
                                                        />
                                                    </div>
                                                )}
                                                {bot.logo && (
                                                    <div className={cn.botItem}>
                                                        <a href={bot.logoLink} className={cn.botLogo}>
                                                            <CustomImage
                                                                src={bot.logo}
                                                                alt={bot.logoName}
                                                                height="56"
                                                                width="118"
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};
export default InsuranceItem;
