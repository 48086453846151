import { makeAutoObservable } from 'mobx';

import RangeInput, { IRangeInput, TRangeConfig } from '@/domain/calculator/blocksStore/Range';

export interface IRkoCalculator {
    resultConfig: Record<string, IRangeInput>;
    rangeFormatValue: () => void;
    balance: string;
    setValueBalanceMonth: (value: string) => void;
    purpose: boolean;
    setCheckedIsPurposePayment: (value: boolean) => void;
    computingTariffValue: (config: ITariffConfig[]) => void;
    tariff: string;
}

export type IFieldsConfig = {
    rangeSlider: TRangeConfig[];
};

export type ITariffConfig = {
    role: string;
    coords: number[][];
    balance: number;
};

class RkoCalculatorStore implements IRkoCalculator {
    public resultConfig: Record<string, IRangeInput> = {};

    public initialized = false;

    public balance = '1';

    public purpose = false;

    public tariff = 'Бизнес';

    constructor() {
        makeAutoObservable(this);
    }

    public init = (fields: IFieldsConfig) => {
        fields.rangeSlider?.forEach(rangeConfig => {
            this.resultConfig[rangeConfig.name] = new RangeInput(rangeConfig);
        });
        this.rangeFormatValue();
        this.initialized = true;
    };

    public rangeFormatValue = () => {
        this.resultConfig.range2.formatValue = `${this.resultConfig.range2.value}`;
    };

    public setValueBalanceMonth = (value: string) => {
        this.balance = value;
    };

    public setCheckedIsPurposePayment = (value: boolean) => {
        this.purpose = value;
    };

    public computingTariffValue = (config: ITariffConfig[]) => {
        const filteredConfig = config.filter(i => i.balance === +this.balance);

        const resultTariff = filteredConfig.find(item => {
            const tariffCoords = item.coords.find(
                i => i[0] >= this.resultConfig.range2.value && i[1] >= this.resultConfig.range1.value
            );
            return item.coords.includes(tariffCoords);
        });

        if (!resultTariff || this.purpose) {
            this.tariff = filteredConfig.find(i => i.role === 'Бизнес').role;
        } else {
            this.tariff = resultTariff.role;
        }
    };
}

export default new RkoCalculatorStore();
