import { useScroll } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import SpecialAdvantageItemMobileWrapper from '@/components/blocks/advantages/SpecialAdvantages/itemMobileWrapper';
import { ISpecialAdvantagesList } from '@/types/specialAdvantages';

import cn from './style.module.sass';

function SpecialAdvantagesMobileList({ list, offset }: ISpecialAdvantagesList) {
    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ['start start', 'end end'],
    });

    return (
        <div ref={container} className={cn.main} style={{ marginBottom: `${(list.length - 1) * 10 - 10}px` }}>
            {list.map((project, i) => {
                const targetScale = 1 - (list.length - i) * 0.05;

                return (
                    <SpecialAdvantageItemMobileWrapper
                        key={`p_${i}`}
                        {...project}
                        pos={i}
                        i={i}
                        progress={scrollYProgress}
                        range={[i * 0.1, 1]}
                        targetScale={targetScale}
                        tooltipText={project.tooltipText}
                        offset={offset}
                    />
                );
            })}
        </div>
    );
}

export default observer(SpecialAdvantagesMobileList);
