import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { ITermField } from '@/domain/calculator/blocksStore/Term';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';
import TermsList from './TermsList';

export type TermsProps = {
    title: string;
    titleFlag?: boolean;
    field: ITermField;
    gtag?: GTagEvent;
    className?: string;
    noFixedWidth?: boolean;
};

const Terms: FC<TermsProps> = observer(({ title, titleFlag, field, gtag, className, noFixedWidth }) => (
    <div className={cn.wrapper}>
        {titleFlag && <div className={cn.label}>{title}</div>}
        <TermsList {...field} gtag={gtag} className={className} noFixedWidth={noFixedWidth} />
    </div>
));

export default Terms;
