import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useResize } from '@/hooks/useResize';
import { IBanner, IPromoEntry } from '@/types/promo/item';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';

interface IProps extends Pick<IBanner, 'line' | 'advantages' | 'description'> {
    hideBreadcrumbs: boolean;
    entry: IPromoEntry;
    isLiteHeader: boolean;
}

const PromoTop: FC<IProps> = ({ hideBreadcrumbs, line, advantages, description, entry }) => {
    const { isLiteHeaderDesktop, isLiteHeaderMobile } = useLayoutStore();
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const scrollOffset = isMobile ? 60 : 90;

    const addBreadcrumbsStyle = isLiteHeaderMobile || hideBreadcrumbs || isLiteHeaderDesktop;

    const breadCrumbsConditions = !isLiteHeaderDesktop && !isLiteHeaderMobile;

    let searchParams = '';

    try {
        const { location } = document;
        searchParams = location.search;
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Tabs error: `, error.message);
        }
    }

    const [titleUtm, setTitleUtm] = useState(line?.title);
    const [subtitleUtm, setSubtitleUtm] = useState(line?.subtitle);
    const [isLineUtm, setIsLineUtm] = useState(false);

    useEffect(() => {
        const utmHeader = line?.headersList?.find(header => searchParams.includes(header.utm));

        if (utmHeader && utmHeader?.title?.length > 0) {
            setTitleUtm(utmHeader.title);
        } else {
            setTitleUtm(line?.title);
        }
    }, [line?.title, searchParams, line?.headersList]);

    useEffect(() => {
        const utmSubheader = line?.subheadersList?.find(subheader => searchParams.includes(subheader.utm));

        if (utmSubheader && utmSubheader?.title?.length > 0) {
            setSubtitleUtm(utmSubheader.title);
        } else {
            setSubtitleUtm(line?.subtitle);
        }
    }, [line?.subtitle, searchParams, line?.subheadersList]);

    return (
        <div className={clsx('promoItemTop', isLineUtm && 'promoItemTopUtm')}>
            {breadCrumbsConditions && (
                <Breadcrumbs
                    hideDisable={!hideBreadcrumbs}
                    customClassName="breadcrumbsPromo"
                    customClassItem="breadcrumbsItemPromo"
                />
            )}
            <div
                className={clsx(
                    'promoItemTopLines',
                    addBreadcrumbsStyle && 'promoItemTopLinesLite',
                    line?.type === 'secondary' && 'promoItemTopLinesSecondary'
                )}
            >
                <div className={clsx('promoItemTitleWrap', line?.hideBackground && 'promoItemNoBg')}>
                    <ScrollLink
                        to={line?.titleAnchor}
                        offset={-scrollOffset}
                        activeClass="active"
                        className={line?.titleAnchor && 'promoItemAnchor'}
                    >
                        <h1 className={clsx('promoItemTitle')} dangerouslySetInnerHTML={{ __html: titleUtm }} />
                    </ScrollLink>
                </div>

                {subtitleUtm && (
                    <div className={clsx('promoItemSubtitle', line?.hideBackground && 'promoItemNoBg')}>
                        <ScrollLink
                            to={line?.subtitleAnchor}
                            offset={-scrollOffset}
                            activeClass="active"
                            className={line?.subtitleAnchor && 'promoItemAnchor'}
                        >
                            <span dangerouslySetInnerHTML={{ __html: subtitleUtm }} />
                        </ScrollLink>
                    </div>
                )}
            </div>
            {description && (
                <TextField text={description} name="banner.description" className="promoItemDesc" isTextArea />
            )}
            {advantages?.length > 0 && (
                <div className="promoAdvantages">
                    <div className="promoAdvantagesGrid">
                        {advantages?.slice(0, 3)?.map(({ title, subtitle }, index) => (
                            <div className="promoAdvantagesItem" key={index}>
                                <div
                                    className="promoAdvantagesTitle"
                                    dangerouslySetInnerHTML={{
                                        __html: title,
                                    }}
                                />
                                <div
                                    className="promoAdvantagesSubtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: subtitle,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {entry?.text &&
                (entry.color === 'white' ? (
                    <div className={clsx('promoEntry', 'promoEntryNoDesktop')}>
                        <Button
                            variant="btnWhiteBorder"
                            type="link"
                            href={entry?.link}
                            label={entry?.text}
                            size="small"
                            customClass={clsx('full')}
                            animation={false}
                        />
                    </div>
                ) : (
                    <div className={clsx('promoEntry', 'promoEntryNoDesktop')}>
                        <Button
                            variant="btnBlueBorder"
                            type="link"
                            href={entry?.link}
                            label={entry?.text}
                            size="small"
                            customClass={clsx('full')}
                            animation={false}
                        />
                    </div>
                ))}
        </div>
    );
};

export default observer(PromoTop);
