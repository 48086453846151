import React from 'react';

import { PrizesList } from './list';
import cn from './style.module.sass';

export type MainPrizesProps = {
    _template: 'mainPrizes';
    title: string;
    items: { title: string; label: string; backgroundSrc: string; partnerLogoSrc: string }[];
};

export default function MainPrizes({ title, items }: MainPrizesProps) {
    return (
        <div className="section">
            <div className={cn.wrapper}>
                <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />
                <PrizesList items={items} />
            </div>
        </div>
    );
}
