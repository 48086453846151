import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IServiceItem } from '@/types/service';

import cn from './style.module.sass';

const ServiceItem: React.FC<IServiceItem> = ({ name, desc, image, imageAlt, pos }) => (
    <AnimatedComponent className={cn.serviceItem} classNameActive={cn.animationInit}>
        <div className={cn.serviceItemWrap}>
            <div className={cn.serviceContent}>
                {name && (
                    <div className={cn.serviceNameWrap}>
                        <TextField text={name} name={`list.[${pos}].name`} className={cn.serviceName} />
                    </div>
                )}
                {desc && (
                    <div className={cn.serviceDesc}>
                        <TextField
                            text={desc}
                            name={`list.[${pos}].desc`}
                            isHTML
                            htmlValue={desc}
                            className={cn.serviceDesc}
                        />
                    </div>
                )}
            </div>
            <div className={cn.serviceImage}>
                <CustomImage
                    src={image}
                    alt={imageAlt}
                    width={100}
                    height={100}
                    objectFit="contain"
                    objectPosition="center center"
                />
            </div>
        </div>
    </AnimatedComponent>
);

export default ServiceItem;
