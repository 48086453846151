import clsx from 'clsx';
import React, { FC } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IQuote {
    img?: string;
    personName: string;
    personPosition?: string;
    text: string;
}

const Quote: FC<IQuote> = ({ img, personName, personPosition, text }) => (
    <AnimatedComponent className={clsx(cn.quote)} classNameActive={cn.animationInit}>
        <div className={cn.wrapper}>
            <div className={cn.top}>
                <div className={cn.imageWrapper}>
                    <div className={cn.image}>
                        {img && <CustomImage src={img} alt={personName} fill mockWidth={500} mockHeight={500} />}
                    </div>
                </div>
                <div className={cn.info}>
                    <TextField
                        text={personName}
                        className={cn.name}
                        name="quote.personName"
                        isHTML
                        htmlValue={personName}
                    />
                    <TextField text={personPosition} className={cn.position} name="quote.personPosition" isTextArea />
                </div>
            </div>
            <div className={cn.bottom}>
                <TextField text={text} className={cn.text} name="quote.text" isHTML htmlValue={text} />
            </div>
        </div>
    </AnimatedComponent>
);

export default Quote;
