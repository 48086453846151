import React from 'react';

import { TinaStepSelection } from '@/components/blocks/StepSelection/index.tina';
import { mockStepSelectionBlockDataTina } from '@/data/blocks/stepSelection';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const stepSelectionBlock = {
    Component: props => <TinaStepSelection {...props} />,
    template: {
        label: 'Блок с выбором шага',
        defaultItem: mockStepSelectionBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Текст',
            },
            {
                component: 'select',
                name: 'step',
                label: 'Номер шага',
                options: [
                    {
                        value: '1',
                        label: '1',
                    },
                    {
                        value: '2',
                        label: '2',
                    },
                    {
                        value: '3',
                        label: '3',
                    },
                ],
            },
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
