export const vddohodCalculatorData = {
    title: 'Калькулятор кешбэка',
    tabTitle: 'Калькулятор кешбэка',
    maxCache: 5000,
    fields: {
        rangeSlider: [
            {
                name: 'range1',
                elements: [
                    {
                        value: 0,
                        step: 1000,
                        percent: 0,
                        label: '0',
                        id: 1,
                    },
                    {
                        value: 15000,
                        step: 1000,
                        percent: 25,
                        label: '15 тыс.',
                        id: 2,
                    },
                    {
                        value: 30000,
                        step: 7000,
                        percent: 50,
                        label: '30 тыс.',
                        id: 3,
                    },
                    {
                        value: 750000,
                        step: 7000,
                        percent: 75,
                        label: '750 тыс.',
                        id: 5,
                    },
                    {
                        value: 1500000,
                        step: 7000,
                        percent: 100,
                        label: '1.5 млн.',
                        id: 6,
                    },
                ],
                title: 'Ваши расходы по карте за месяц',
                label: 'Выберите сумму',
                initValue: 30000,
            },
            {
                name: 'range2',
                elements: [
                    {
                        value: 0,
                        step: 1000,
                        percent: 0,
                        label: '0',
                        id: 1,
                    },
                    {
                        value: 30000,
                        step: 1000,
                        percent: 100,
                        label: '30 тыс.',
                        id: 3,
                    },
                ],
                title: 'Из них траты в категории одежды, обуви, в ресторанах и кафе (включая фастфуд)',
                label: 'Выберите сумму',
                initValue: 10000,
            },
        ],
    },
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
