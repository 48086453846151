import React, { FC, useEffect, useMemo, useState } from 'react';

import Document from '@/components/Document';
import TagList from '@/components/TagList';
import Button from '@/components/UI/Button';
import { useAppStore } from '@/context/AppStoreContext';
import { ITag } from '@/types/tags';
import {
    ITariffsDocumentsArchive,
    ITariffsDocumentsArchiveElement,
    ITariffsDocumentsArchiveElementChunked,
    ITariffsDocumentsArchiveSection,
} from '@/types/tariffsDocumentsArchive/index';

import cn from './style.module.sass';

const TariffsDocumentsArchive: FC<ITariffsDocumentsArchive> = ({ filter, list }) => {
    const chunkList = (listToDivide: ITariffsDocumentsArchiveElement[]): ITariffsDocumentsArchiveElementChunked[] =>
        listToDivide?.map(item => {
            const chunkedSections = item?.sections?.reduce((acc: ITag[][], section, index) => {
                if (!(index % 10)) {
                    return [...acc, item?.sections?.slice(index, index + 10)];
                }
                return acc;
            }, []) as ITariffsDocumentsArchiveSection[][];

            return {
                ...item,
                sectionsToShow: 1,
                sections: chunkedSections,
            };
        });

    const [activeTab, setActiveTab] = useState<string>(filter?.[0]?.xmlId);
    const [activeList, setActiveList] = useState<ITariffsDocumentsArchiveElementChunked[]>(chunkList(list));
    const [isTinaView] = useAppStore(store => store.isTinaView);

    const chunkedArray = filter?.reduce((acc: ITag[][], item, index) => {
        if (!(index % 10)) {
            return [...acc, filter?.slice(index, index + 10)];
        }
        return acc;
    }, []);

    const showedList: ITariffsDocumentsArchiveElementChunked = useMemo(
        () => activeList?.filter(item => activeTab === item?.xmlId)[0],
        [activeTab, activeList, list]
    );

    const onTagClick = (code: string) => {
        setActiveTab(code);
    };

    const onShowMoreClick = () => {
        const activeSectionsIndex = activeList?.indexOf(showedList);

        const newList = [...activeList];
        newList[activeSectionsIndex] = {
            ...activeList[activeSectionsIndex],
            sectionsToShow: activeList[activeSectionsIndex].sectionsToShow + 1,
        };

        setActiveList(newList);
    };

    useEffect(() => {
        if (isTinaView) setActiveList(chunkList(list));
    }, [list]);

    return (
        <div className="section">
            <section className={cn.wrapper}>
                <div className={cn.tabsContainer}>
                    {chunkedArray?.map((array, index) => (
                        <nav className={cn.tabs} key={index}>
                            <TagList
                                tagList={array}
                                onClick={onTagClick}
                                activeTag={activeTab}
                                className={cn.slider}
                                tagClassName={cn.tab}
                            />
                        </nav>
                    ))}
                </div>
                <div className={cn.body}>
                    {showedList?.sections?.map((chunk, index) => {
                        if (index + 1 <= showedList.sectionsToShow) {
                            return chunk?.map(({ date, documents }, chunkIndex) => (
                                <div className={cn.section} key={chunkIndex}>
                                    <div className={cn.sectionDate}>{date}</div>
                                    <div className={cn.items}>
                                        {documents?.map((document, documentIndex) => (
                                            <Document {...document} key={documentIndex} />
                                        ))}
                                    </div>
                                </div>
                            ));
                        }
                        return null;
                    })}
                </div>
                {showedList?.sectionsToShow < showedList?.sections?.length && (
                    <Button
                        variant="btnBlueBorder"
                        type="button"
                        label="Показать ещё"
                        customClass={cn.showMore}
                        darkWhite
                        onClick={onShowMoreClick}
                    />
                )}
            </section>
        </div>
    );
};

export default TariffsDocumentsArchive;
