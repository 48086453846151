import clsx from 'clsx';
import React from 'react';

import OkIconItem from '@/components/UI/ShareIconBlock/okIcon';
import TwIconItem from '@/components/UI/ShareIconBlock/twIcon';
import VkIconItem from '@/components/UI/ShareIconBlock/vkIcon';
import { protocol } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    href: string;
    type?: 'vk' | 'ok' | 'tw';
}

const ShareIconItem: React.FC<IProps> = ({ href, type }) => {
    let link: string;
    let children: React.ReactNode;

    if (type === 'vk') {
        link = `https://vk.com/share.php?url=${protocol}${process.env.FRONT_ROOT_URL}/${href}`;
        children = <VkIconItem />;
    }

    if (type === 'ok') {
        link = `https://connect.ok.ru/offer?url=${protocol}${process.env.FRONT_ROOT_URL}/${href}`;
        children = <OkIconItem />;
    }

    if (type === 'tw') {
        link = `http://twitter.com/share?url=${protocol}${process.env.FRONT_ROOT_URL}/${href}`;
        children = <TwIconItem />;
    }

    return (
        <a
            href={link}
            target="_blank"
            className={clsx(cn.button, type === 'vk' && cn.vk, type === 'ok' && cn.ok, type === 'tw' && cn.tw)}
            rel="noreferrer"
        >
            {children && <div className={cn.icon}>{children}</div>}
        </a>
    );
};

export default ShareIconItem;
