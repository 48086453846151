import React from 'react';

import { TinaActionDetail } from '@/components/blocks/ActionDetail/index.tina';
import { mockActionDetailBlockData } from '@/data/blocks/actionDetail';

export const actionDetailBlock = {
    Component: props => <TinaActionDetail {...props} />,
    template: {
        label: 'Детальная страница внешней акции',
        defaultItem: () => mockActionDetailBlockData,
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
        ],
    },
};
