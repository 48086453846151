import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useMemo } from 'react';
import { v4 } from 'uuid';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { useCity } from '@/context/CityProvider';
import { IBuildingCalculatorStore } from '@/domain/calculator/BuildingCalculatorStore';
import { TBuildingCalculatorContent } from '@/types/calculator/building';
import { getTTCodeforMacro } from '@/utils/getTTCodeforMacro';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const BuildingCalculatorContent: FC<TBuildingCalculatorContent> = observer(({ title, index, gtag }) => {
    const { range, percentRange, termField, paymentValue, rate, toggleChecked, creditSum } =
        useContext<IBuildingCalculatorStore>(CalculatorStoreContext);
    const {
        currentCity: { region },
    } = useCity();

    const ttCode = useMemo(() => getTTCodeforMacro(region), [region]);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const formattedRate = `${rate}%`;

    const uuidUser = useMemo(() => v4(), []);

    const firstPayment = (+range.value * +percentRange.value) / 100;

    const specialLink = `https://my.pochtabank.ru/pos-credit-v2?operId=${uuidUser}&productCode=EXP_DOM_PP&toCode=9999999998&ttCode=${ttCode}&ttName=&amountCredit=${range.value}&termCredit=${termField.activeTerm.value}&firstPayment=${firstPayment}&fullName=&phone=&brokerAgentId=NON_BROKER&returnUrl=&order%5B0%5D%5Bcategory%5D=274&order%5B0%5D%5Bmark%5D=%D0%94%D0%BE%D0%BC&order%5B0%5D%5Bmodel%5D=%D0%9A%D0%B8%D1%80%D0%BF%D0%B8%D1%87%D0%BD%D1%8B%D0%B9&order%5B0%5D%5Bquantity%5D=1&order%5B0%5D%5Bprice%5D=${range.value}&_ga=2.48246177.646290470.1639584009-169232854.1638538784`;

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.wrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <TextField
                                text={title}
                                customTag="h2"
                                name="title"
                                className={cn.title}
                                isHTML
                                htmlValue={title}
                            />
                            <RangeInput config={range} noMaxWidth />
                            <RangeInput config={percentRange} noMaxWidth />
                            <Terms
                                title="Срок"
                                field={termField}
                                gtag={gtag?.gtagTerm}
                                className={cn.termsNoMargin}
                                noFixedWidth
                            />
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Процентная ставка</span>
                                            <span className={cn.resultNumber}>{formattedRate}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                            <span className={cn.progressRed} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Сумма ежемесячного платежа</span>
                                            <span className={cn.resultNumber}>{`${formatValue(paymentValue)} ₽`}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                            <span className={cn.progressRed} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Сумма кредита</span>
                                            <span className={cn.resultNumber}>{`${formatValue(creditSum)} ₽`}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                            <span className={cn.progressRed} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.checkbox}>
                                    <Checkbox label="Страховка" onChangeHandler={toggleChecked} />
                                </div>
                                <div className={cn.button}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={specialLink}
                                        target="_blank"
                                        label="Заполнить анкету"
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => {
                                            if (gtag?.gtagButton) event(gtag.gtagButton);
                                        }}
                                        animation={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BuildingCalculatorContent;
