import React from 'react';

// eslint-disable-next-line import/no-cycle
import { IPushHRVacancyListItemInner } from '@/components/blocks/PushHRLanding/HRVacancy/HRVacancyItem';
import VacancyButton from '@/components/blocks/PushHRLanding/HRVacancy/VacancyButton';

import cn from './style.module.sass';

interface IPushHRVacancyModalItem extends IPushHRVacancyListItemInner {
    handleClose: () => void;
}

function HRVacancyModal({
    title,
    subtitle,
    handler,
    link,
    linkShare,
    description,
    handleClose,
}: IPushHRVacancyModalItem) {
    const handleClick = theme => {
        handler(theme);
        handleClose();
    };
    return (
        <div className={cn.hrVacancyModal}>
            <div className={cn.hrVacancyModalTitle} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={cn.hrVacancyModalSubtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
            <div className={cn.hrVacancyModalControls}>
                <div className={cn.hrVacancyModalButtons}>
                    <VacancyButton
                        customClass={cn.hrVacancyModalBtn}
                        type="button"
                        label="Откликнуться"
                        isBg
                        onClick={() => handleClick(link)}
                    />
                    {/* <VacancyButton */}
                    {/*    customClass={cn.hrVacancyModalBtn} */}
                    {/*    type="link" */}
                    {/*    label="Открыть на hh" */}
                    {/*    isDark */}
                    {/*    target="_blank" */}
                    {/*    href={link} */}
                    {/* /> */}
                </div>
                <div className={cn.hrVacancyModalShare}>
                    {/* <a */}
                    {/*    href={`https://vk.com/share.php?url=${protocol}${process.env.FRONT_ROOT_URL}/${linkShare}`} */}
                    {/*    target="_blank" */}
                    {/*    className={cn.hrVacancyModalShareLink} */}
                    {/*    rel="noreferrer" */}
                    {/* > */}
                    {/*    Поделиться вакансией */}
                    {/* </a> */}
                </div>
            </div>

            <div className={cn.hrVacancyModalDesc} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    );
}

export default HRVacancyModal;
