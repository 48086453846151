export const mockSidebarInfoBlockData = {
    title: 'Уважаемые журналисты!',
    desc: 'Вы можете задать вопросы пресс-службе Почта Банка:',
    phone: '+7 495 532 13 00',
    phoneLink: '74955321300',
    mail: 'pr@pochtabank.ru',
};

export const mockSidebarInfoBlockDataContacts1 = {
    title: 'Чат с оператором <br> в <a href="https://my.pochtabank.ru/">Почта Банк Онлайн</a>',
    desc: '',
    phone: '+7 (495) 532-13-00',
    phoneLink: '74955321300',
    mail: '',
    annotation: 'Стоимость звонка согласно тарифам вашего оператора связи.',
};

export const mockSidebarInfoBlockDataContacts2 = {
    title: 'Головной офис',
    desc: '107061, г. Москва, <br> Преображенская пл., д. 8',
    phone: '',
    phoneLink: '',
    mail: 'welcome@pochtabank.ru',
    annotation: '',
};

export const mockSidebarInfoBlockDataContacts3 = {
    title: 'Пресс-служба',
    desc: '',
    phone: '+7 (495) 532-13-00',
    phoneLink: '74955321300',
    mail: 'pr@pochtabank.ru',
    annotation:
        'В теме письма укажите, пожалуйста, что это запрос СМИ. Также ждем обращений в официальные аккаунты соцсетей.',
};

export const mockSidebarInfoBlockDataContacts4 = {
    title: 'Служба подбора персонала',
    desc: '',
    phone: '+7 (495) 228-38-88',
    phoneLink: '74952283888',
    mail: 'rabota@pochtabank.ru',
    annotation: '',
};

export const mockSidebarInfoBlockDataTina = {
    title: 'Заголовок',
    desc: 'Описание',
    phone: '+7 999 999 99 99',
    phoneLink: '79999999999',
    mail: 'pr@pochtabank.ru',
};
