import clsx from 'clsx';
import React from 'react';

import ShareItem from '@/components/UI/ShareBlock/item';
import OkIcon from '@/components/UI/ShareBlock/okIcon';
import VkIcon from '@/components/UI/ShareBlock/vkIcon';

import cn from './style.module.sass';

interface IProps {
    link: string;
}

const ShareBlock: React.FC<IProps> = ({ link }) => (
    <div className={clsx(cn.wrapper)} role="presentation">
        <div className={cn.item}>
            <ShareItem href={link} type="ok">
                <OkIcon />
            </ShareItem>
        </div>
        <div className={cn.item}>
            <ShareItem href={link} type="vk">
                <VkIcon />
            </ShareItem>
        </div>
    </div>
);

export default ShareBlock;
