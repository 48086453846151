import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import { ICalculatorDisclaimer } from '@/components/blocks/calculators/InfoTitle';
import CashCalculatorContent, {
    ICalculatorPopup,
} from '@/components/blocks/calculators/layouts/CashCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useAppStore } from '@/context/AppStoreContext';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import CashCalculatorStore from '@/domain/calculator/CashCalculatorStore';
import Feature from '@/domain/feature/Feature';
import { ITermItem } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

interface ICalculatorParams {
    filterElements: ICalculatorFilterElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
}

interface ISecondaryCalculatorBannerRanges {
    title: string;
    subtitle: string;
    min: number;
    max: number;
    image?: string;
    shouldHide?: boolean;
}

interface ICalculatorBanner {
    ranges?: ISecondaryCalculatorBannerRanges[];
    isActive: boolean;
}

export interface ICashCalculator {
    _template?: 'cashCalculator';
    title?: string;
    tabTitle?: string;
    params: ICalculatorParams;
    banner?: ICalculatorBanner;
    popup?: ICalculatorPopup;
    infoTitle?: ICalculatorDisclaimer;
    button?: {
        label: string;
        link: string;
    };
    buttonShared?: {
        isActive: boolean;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
        gtagButtonShare: GTagEvent;
    };
}

const CashCalculator: React.FC<ICashCalculator> = observer(
    ({ title, params, index, gtag, banner, button, buttonShared, infoTitle, popup }) => {
        const [path] = useAppStore(store => store.path);

        if (!params) return null;

        const CashCalculatorInstance = useMemo(() => new CashCalculatorStore(params), [path]);

        if (!Feature.isFeatureEnabled('enableMainCashCreditCalculator')) {
            return null;
        }

        return (
            <CalculatorStoreContext.Provider value={CashCalculatorInstance}>
                <AnimatedComponent>
                    <CashCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        banner={banner}
                        infoTitle={infoTitle}
                        button={button}
                        buttonShared={buttonShared}
                        popup={popup}
                    />
                </AnimatedComponent>
            </CalculatorStoreContext.Provider>
        );
    }
);

export default withBlockLinks(CashCalculator);
