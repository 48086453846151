import { makeAutoObservable } from 'mobx';

import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import { IFieldsConfig } from '@/types/calculator/config';

export interface ISberCalculatorStore {
    resultConfig: IConfigCalculator;
    paymentSum: string;
    formatValueHandle: (value: string) => string;
    valueRate: number;
}

export interface IConfigCalculator {
    range1: IRangeInput;
}

class SberschetCalculatorStore implements ISberCalculatorStore {
    valueRate: number;

    constructor({ fields }: { fields: IFieldsConfig }, valueRate: number) {
        makeAutoObservable(this, {});
        this.initField(fields);
        this.valueRate = valueRate;
    }

    resultConfig = {
        range1: undefined,
    };

    completeConfig = false;

    formatValueHandle = (value: string) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}₽`;

    get paymentSum() {
        if (this.completeConfig) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { value }: { value: number } = this.resultConfig.range1;
            const sum = (value * Math.round((this.valueRate * 100) / 100)) / 100;
            return this.formatValueHandle(`${sum}`);
        }
        return '0 ₽'; // добавлено чисто для ts
    }

    initField = (fields: IFieldsConfig) => {
        const { rangeSlider } = fields;
        // инизиализация полей range слайдера
        rangeSlider.forEach(rangeConfig => {
            this.resultConfig[rangeConfig.name] = new RangeInput(rangeConfig);
        });

        this.completeConfig = true;
    };
}

export default SberschetCalculatorStore;
