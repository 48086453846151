import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Feature from '@/domain/feature/Feature';
import { useResize } from '@/hooks/useResize';
import { IDiagram } from '@/types/diagram/item';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

const Diagram: React.FC<IDiagram> = observer(({ title, button, firstDiagram, secondDiagram, _template }) => {
    if (!Feature.isFeatureEnabled('enableDiagram')) {
        return null;
    }

    const width = useResize();

    const getPersentHeight = useMemo(() => {
        if (typeof window === 'undefined') return '5px';
        if (width < MOBILE_WIDTH) return '3px';
        return '5px';
    }, [width]);

    const firstDiagramBlueColumnAdditionalHeight = Number(firstDiagram?.redColumn?.value) ? '15px' : '0px';

    const secondDiagramBlueColumnAdditionalHeight = Number(secondDiagram?.redColumn?.value) ? '15px' : '0px';

    return (
        <div className={clsx('section', cn.diagram)}>
            <div className={clsx(cn.fullWrapper)}>
                <TextField text={title} customTag="h2" name="title" className={cn.title} isHTML htmlValue={title} />
                <div className={clsx(cn.wrapper)}>
                    <div className={clsx(cn.innerWrapper)}>
                        <div className={clsx(cn.diagram)}>
                            <div className={clsx(cn.left)}>
                                <div className={clsx(cn.column)}>
                                    {(!!Number(firstDiagram?.redColumn?.value) ||
                                        !!Number(firstDiagram?.blueColumn?.value)) && (
                                        <TextField
                                            className={cn.columnTitle}
                                            text={firstDiagram.title}
                                            name="firstDiagram.title"
                                            isHTML
                                            htmlValue={firstDiagram.title}
                                        />
                                    )}
                                    {!!Number(firstDiagram?.redColumn?.value) && (
                                        <div
                                            className={clsx(cn.columnRed)}
                                            style={{
                                                height: `calc(${firstDiagram.redColumn.value}*${getPersentHeight})`,
                                            }}
                                            data-value={firstDiagram.redColumn.value}
                                        >
                                            <div className={cn.columnTextWrapper}>
                                                <TextField
                                                    className={cn.columnText}
                                                    text={firstDiagram.redColumn.text}
                                                    name="firstDiagram.redColumn.text"
                                                    isHTML
                                                    htmlValue={firstDiagram.redColumn.text}
                                                />
                                            </div>

                                            <div className={clsx(cn.columnDiagram)}>
                                                <div className={clsx(cn.item, cn.redItem)}>
                                                    <div className={clsx(cn.itemTop)} />
                                                    <div className={clsx(cn.itemBottom)} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!!Number(firstDiagram?.blueColumn?.value) && (
                                        <div
                                            className={clsx(cn.columnBlue)}
                                            style={{
                                                height: `calc(${firstDiagram.blueColumn.value}*${getPersentHeight} + ${firstDiagramBlueColumnAdditionalHeight})`,
                                            }}
                                            data-value={firstDiagram.blueColumn.value}
                                        >
                                            <div className={cn.columnTextWrapper}>
                                                <TextField
                                                    className={cn.columnText}
                                                    text={firstDiagram.blueColumn.text}
                                                    name="firstDiagram.blueColumn.text"
                                                    isHTML
                                                    htmlValue={firstDiagram.blueColumn.text}
                                                />
                                            </div>
                                            <div className={clsx(cn.columnDiagram)}>
                                                <div className={clsx(cn.item, cn.blueItem)}>
                                                    <div className={clsx(cn.itemTop)} />
                                                    <div className={clsx(cn.itemBottom)} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={clsx(cn.right)}>
                                <div className={clsx(cn.column)}>
                                    {(!!Number(secondDiagram?.redColumn?.value) ||
                                        !!Number(secondDiagram?.blueColumn?.value)) && (
                                        <TextField
                                            className={cn.columnTitle}
                                            text={secondDiagram.title}
                                            name="secondDiagram.title"
                                            isHTML
                                            htmlValue={secondDiagram.title}
                                        />
                                    )}
                                    {!!Number(secondDiagram?.redColumn?.value) && (
                                        <div
                                            className={clsx(cn.columnRed)}
                                            style={{
                                                height: `calc(${secondDiagram.redColumn.value}*${getPersentHeight})`,
                                            }}
                                            data-value={secondDiagram.redColumn.value}
                                        >
                                            <div className={clsx(cn.columnDiagram)}>
                                                <div className={clsx(cn.item, cn.redItem)}>
                                                    <div className={clsx(cn.itemTop)} />
                                                    <div className={clsx(cn.itemBottom)} />
                                                </div>
                                            </div>
                                            <div className={cn.columnTextWrapper}>
                                                <TextField
                                                    className={cn.columnText}
                                                    text={secondDiagram.redColumn.text}
                                                    name="secondDiagram.redColumn.text"
                                                    isHTML
                                                    htmlValue={secondDiagram.redColumn.text}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!!Number(secondDiagram?.blueColumn?.value) && (
                                        <div
                                            className={clsx(cn.columnBlue)}
                                            style={{
                                                height: `calc(${secondDiagram.blueColumn.value}*${getPersentHeight} + ${secondDiagramBlueColumnAdditionalHeight})`,
                                            }}
                                            data-value={secondDiagram.blueColumn.value}
                                        >
                                            <div className={clsx(cn.columnDiagram)}>
                                                <div className={clsx(cn.item, cn.blueItem)}>
                                                    <div className={clsx(cn.itemTop)} />
                                                    <div className={clsx(cn.itemBottom)} />
                                                </div>
                                            </div>
                                            <div className={cn.columnTextWrapper}>
                                                <TextField
                                                    className={cn.columnText}
                                                    text={secondDiagram.blueColumn.text}
                                                    name="secondDiagram.blueColumn.text"
                                                    isHTML
                                                    htmlValue={secondDiagram.blueColumn.text}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={clsx(cn.legend)}>
                            <div className={clsx(cn.left)}>
                                {!!Number(firstDiagram?.redColumn?.value) && (
                                    <div className={clsx(cn.legendItemWrapper)}>
                                        <div className={clsx(cn.redLegendItem)}>
                                            <div className={clsx(cn.legendItemTop)} />
                                            <div className={clsx(cn.legendItemBottom)} />
                                        </div>
                                        <TextField
                                            className={cn.legendText}
                                            text={firstDiagram.redColumn.legendText}
                                            name="firstDiagram.redColumn.legendText"
                                        />
                                    </div>
                                )}

                                {!!Number(firstDiagram?.blueColumn?.value) && (
                                    <div className={clsx(cn.legendItemWrapper)}>
                                        <div className={clsx(cn.blueLegendItem)}>
                                            <div className={clsx(cn.legendItemTop)} />
                                            <div className={clsx(cn.legendItemBottom)} />
                                        </div>
                                        <TextField
                                            className={cn.legendText}
                                            text={firstDiagram.blueColumn.legendText}
                                            name="firstDiagram.blueColumn.legendText"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={clsx(cn.right)}>
                                {!!Number(secondDiagram?.redColumn?.value) && (
                                    <div className={clsx(cn.legendItemWrapper)}>
                                        <div className={clsx(cn.redLegendItem)}>
                                            <div className={clsx(cn.legendItemTop)} />
                                            <div className={clsx(cn.legendItemBottom)} />
                                        </div>
                                        <TextField
                                            className={cn.legendText}
                                            text={secondDiagram.redColumn.legendText}
                                            name="secondDiagram.redColumn.legendText"
                                        />
                                    </div>
                                )}
                                {!!Number(secondDiagram?.blueColumn?.value) && (
                                    <div className={clsx(cn.legendItemWrapper)}>
                                        <div className={clsx(cn.blueLegendItem)}>
                                            <div className={clsx(cn.legendItemTop)} />
                                            <div className={clsx(cn.legendItemBottom)} />
                                        </div>
                                        <TextField
                                            className={cn.legendText}
                                            text={secondDiagram.blueColumn.legendText}
                                            name="secondDiagram.blueColumn.legendText"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {button?.title && (
                        <div className={clsx(cn.button)}>
                            <Button
                                variant="btnBlue"
                                type="button"
                                label={button?.title}
                                disable
                                customClass="diagramButton"
                                animation={false}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default withBlockLinks(Diagram);
