import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import { ISberCalculatorStore } from '@/domain/calculator/SberschetCalculatorStore';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface ICalculator {
    title?: string;
    button?: {
        label: string;
        link: string;
    };
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
}
const SberCalculatorContent: React.FC<ICalculator> = observer(({ title, gtag, button }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { resultConfig, paymentSum, valueRate } = useContext<ISberCalculatorStore>(CalculatorStoreContextNew);

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={clsx(cn.wrapper)}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            {title && (
                                <TextField
                                    text={title}
                                    customTag="h2"
                                    name="title"
                                    isHTML
                                    htmlValue={title}
                                    className={cn.title}
                                />
                            )}
                            <div className={cn.rangeWrap}>
                                <RangeInput config={resultConfig.range1} noMaxWidth />
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Процентная ставка</span>
                                            <span className={cn.resultNumber}>{`${valueRate}%`}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                            <span className={cn.progressRed} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Сумма вашего дохода</span>
                                            <span className={cn.resultNumber}>{paymentSum}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                            <span className={cn.progressRed} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.helpText}>
                                    Расчет предварительный. Точная сумма доходов будет рассчитана по фактическим данным.
                                </div>
                                <div className={cn.button}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={button?.link || '#'}
                                        label={button?.label || 'Открыть счёт'}
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => {
                                            if (gtag?.gtagButton) event(gtag.gtagButton);
                                        }}
                                        animation={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SberCalculatorContent;
