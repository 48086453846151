import React from 'react';

import { TinaThreeInARow } from '@/components/blocks/ThreeInARow/index.tina';
import { mockThreeInARowBlockDataTina } from '@/data/blocks/threeInARow';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const threeInARowBlock = {
    Component: props => <TinaThreeInARow {...props} />,
    template: {
        label: 'Три плитки в ряд',
        defaultItem: () => mockThreeInARowBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },

            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список элементов',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                    title: item.title,
                }),
                defaultItem: () => mockThreeInARowBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        required: true,
                        validate: (value: string) => {
                            if (!value) {
                                return 'Вы не заполнили наименование элемента';
                            }
                            return null;
                        },
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'icon',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
