import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface IProps {
    isVideo?: boolean;
    video?: string;
    image?: string;
    title?: string;
}

const MobileAppSource: React.FC<IProps> = ({ image, isVideo, video, title }) => {
    if (isVideo)
        return (
            <video key={video} muted loop autoPlay controls={false} className={cn.video}>
                <source src={video} />
            </video>
        );

    return <CustomImage src={image} alt={title} fill mockWidth={242} mockHeight={332} />;
};

export default MobileAppSource;
