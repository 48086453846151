import clsx from 'clsx';
import React from 'react';

import FilterElement from '@/components/blocks/universalList/FilterElement';
import CustomSlider from '@/components/CustomSlider';
import { IUniversalListFilter } from '@/types/universalList';

import cn from './style.module.sass';

interface IProps {
    filter: IUniversalListFilter[];
    onFilterClick: () => void;
}

const Filter: React.FC<IProps> = ({ filter, onFilterClick }) => (
    <div className={clsx(cn.filter, 'newsFilter')}>
        <CustomSlider className={cn.filterGrid}>
            {filter?.map((item, index) => (
                <FilterElement key={`${item.link}-${item.text}-${index}`} filter={item} onFilterClick={onFilterClick} />
            ))}
        </CustomSlider>
    </div>
);

export default Filter;
