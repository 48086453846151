import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import ActionPensionsList from '@/components/blocks/ActionPensions/list';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { IActionPensions } from '@/types/actionPensions/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function ActionPensions({ title, list, btn }: IActionPensions) {
    return (
        <AnimatedComponent>
            <div className={clsx('section')}>
                <div className={cn.wrap}>
                    {title && (
                        <div className={cn.top}>
                            <div className={clsx(cn.topItem, cn.topItemTitle)}>
                                <TextField text={title} name="name" className={cn.title} isHTML htmlValue={title} />
                            </div>
                        </div>
                    )}

                    {list && list?.length > 0 && <ActionPensionsList list={list} />}

                    {btn?.link && (
                        <div className={cn.bottom}>
                            <a
                                href={btn?.link}
                                className={cn.link}
                                onClick={() => {
                                    if (btn?.gtag?.action) event(btn?.gtag);
                                }}
                            >
                                {btn?.label}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(ActionPensions);
