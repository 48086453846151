import React from 'react';

import { TinaSafelyBlock } from '@/components/blocks/Safely/index.tina';
import { mockSafelyBlockTinaData } from '@/data/blocks/safely';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const safelyBlock = {
    Component: props => <TinaSafelyBlock {...props} />,
    template: {
        label: 'Безопасно',
        defaultItem: () => mockSafelyBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Большое изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imgMinHigh',
                component: 'image',
                label: 'Маленькое изображение. Верх',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imgMinLow',
                component: 'image',
                label: 'Маленькое изображение. Низ',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imageAlt',
                component: 'text',
                label: 'Описание к основной картинке',
            },
            {
                name: 'subhead',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
        ],
    },
};
