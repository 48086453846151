import React from 'react';

import { TinaOurTargets } from '@/components/blocks/OurTargets/index.tina';
import { mockOurTargetsMockData } from '@/data/blocks/ourTargets';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const ourTargetsBlock = {
    Component: props => <TinaOurTargets {...props} />,
    template: {
        label: 'Наши цели',
        defaultItem: () => mockOurTargetsMockData,
        fields: [
            {
                name: 'heading',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'targets',
                label: 'Список элементов',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockOurTargetsMockData.targets[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок блока',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Текст блока',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер',
                        options: [
                            { value: 'half', label: 'На половину блока' },
                            {
                                value: 'full',
                                label: 'На всю ширину',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
