import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import RangeInput from '@/components/blocks/calculators/range/deprecated/RangeInputDeprecated';
import Term from '@/components/blocks/calculators/Term';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { finFormattingValue } from '@/utils/finFormattingValue';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import CalculatorInfo from '../Info';
import CalculatorOffer from '../Offer';
import cn from './style.module.sass';

export interface ICalculator {
    title?: string;
    tabTitle?: string;
    infoTitle?: {
        isActive?: boolean;
        text?: string;
        tooltipText?: string;
    };
    button?: {
        label: string;
        link: string;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
    };
}

const CashCreditCalculatorContent: React.FC<ICalculator> = observer(({ title, gtag, button, infoTitle }) => {
    const { monthlyPayment } = useContext(CalculatorStoreContext);

    return (
        <div className="section">
            <div className={cn.container}>
                <div className={cn.wrapper}>
                    {title && <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} />}

                    <div className={cn.content}>
                        <div className={cn.left}>
                            <RangeInput gtag={gtag?.gtagSlider} className={cn.rangeInput} />

                            <div className={cn.termWrapper}>
                                <Term
                                    gtag={gtag?.gtagTerm}
                                    itemClassName={cn.customTerm}
                                    activeClassName={cn.customTermActive}
                                />
                            </div>
                        </div>

                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <CalculatorOffer
                                    title="Наше предложение"
                                    sum={finFormattingValue(monthlyPayment.total)}
                                />

                                <div className={cn.buttonWrapper}>
                                    <ScrollLink to={button?.link || '#'} className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="button"
                                            label={button?.label || 'Заполнить заявку'}
                                            customClass={cn.button}
                                            animation={false}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                        />
                                    </ScrollLink>
                                </div>
                            </div>
                        </div>

                        <div className={cn.bottom}>
                            <div className={cn.info}>
                                {infoTitle?.isActive && (
                                    <CalculatorInfo text={infoTitle.text} tooltipText={infoTitle.tooltipText} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CashCreditCalculatorContent;
