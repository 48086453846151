import Image from 'next/image';
import React, { FC } from 'react';

import cn from './style.module.sass';

const imgItems = {
    type1: { img: '/img-next/png/buildingCalculator/imgType1.png' },
    type2: { img: '/img-next/png/buildingCalculator/imgType2.png' },
    type3: { img: '/img-next/png/buildingCalculator/imgType3.png' },
};

const ImageBlock: FC<{ type: 'type1' | 'type2' | 'type3' }> = ({ type }) => (
    <div className={cn.wrapper}>
        <Image
            src={imgItems[type].img}
            alt="Кредит на строительство"
            width={439}
            height={244}
            priority
            placeholder="blur"
            blurDataURL="/img-next/svg/tooltip/dark.svg"
        />
    </div>
);

export default ImageBlock;
