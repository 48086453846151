import React from 'react';

import { TinaArticleList } from '@/components/blocks/articleList/ArticleList/index.tina';

export const articleListBlock = {
    Component: props => <TinaArticleList {...props} />,
    template: {
        label: 'Список статей',
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
            {
                name: 'section',
                component: 'section',
                withInfoBlock: true,
                label: 'Привязка к секции',
            },
        ],
    },
};
