import React from 'react';

import { TinaVacancy } from '@/components/blocks/vacancy/Vacancy/index.tina';
import { mockVacancyBlockDataTina } from '@/data/blocks/vacancy';

export const vacancyBlock = {
    Component: props => <TinaVacancy {...props} />,
    template: {
        label: 'HR: Вакансии',
        defaultItem: () => mockVacancyBlockDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: 'textarea',
                label: 'Описание',
            },
            {
                name: 'vacancyToShow',
                component: 'number',
                label: 'Количество выводимых вакансий',
            },
        ],
    },
};
