export const mockInfoBlockData = {
    name: 'Уведомления',
    type: 'primary',
    list: [
        {
            title: 'Будьте бдительны!',
            subtitle: 'Новые виды мошенничества',
            image: '/img-next/svg/info/1.svg',
            link: 'https://www.pochtabank.ru/service/financial_security',
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
        },
        {
            title: 'Поддержка клиентов',
            subtitle: 'В период пандемии',
            image: '/img-next/svg/info/2.svg',
            link: 'https://www.pochtabank.ru/pandemic',
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
        },
        {
            title: 'Банк на диване',
            subtitle: 'Оформление кредита или пенсии на дому',
            image: '/img-next/svg/info/3.svg',
            link: 'https://www.pochtabank.ru/service/home-delivery',
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
        },
    ],
};

export const mockInfoBlockDataOnlineCredit = {
    type: 'secondary',
    name: 'Как это работает?',
    list: [
        {
            title: 'WEB-анкета на кредит размещается на вашем сайте.',
            image: '/img-next/png/onlinecredits/how1.png',
        },
        {
            title: 'Клиент вашего интернет-магазина подает заявку на кредит онлайн',
            image: '/img-next/png/onlinecredits/how2.png',
        },
        {
            title: 'Подписание договора',
            subtitle:
                'После одобрения заявки клиент подписывает кредитный договор в одном из 4 500 отделений Почта Банка и Почты России.',
            image: '/img-next/png/onlinecredits/how3.png',
        },
    ],
};

export const mockInfoBlockData5kaMir = {
    type: 'secondary',
    name: 'Выберите ваш тариф',
    tagList: [
        { value: 'Получайте баллы по карте', xmlId: '1' },
        { value: 'Возможности', xmlId: '2' },
        { value: 'Онлайн-контроль', xmlId: '3' },
        { value: 'Полезно знать', xmlId: '4' },
    ],
    list: [
        {
            tags: ['1'],
            title: '555 приветственных баллов',
            subtitle:
                'за каждые 555 руб. в чеке в магазинах сети «Пятёрочка» в течение первых 6 месяцев, но не более 5 550 баллов.',
            image: '/img-next/png/info/CardMir/1.png',
        },
        {
            tags: ['1'],
            title: 'За каждые 20 ₽ в магазинах «Пятёрочка»',
            subtitle:
                '<p></p>4 балла </br>если стоимость покупки ≥ 555₽</p><p>3 балла если стоимость покупки менее 555₽.</br> Предъявляйте карту кассиру до оплаты покупки,в том числе при оплате смартфоном</p>',
            image: '/img-next/png/info/CardMir/2.png',
        },
        {
            tags: ['1'],
            title: 'За каждые 20 ₽ в других магазинах',
            subtitle: '2 балла </br>при любой стоимости покупки',
            image: '/img-next/png/info/CardMir/3.png',
        },
        {
            tags: ['2'],
            title: '555 приветственных баллов',
            subtitle:
                'за каждые 555 руб. в чеке в магазинах сети «Пятёрочка» в течение первых 6 месяцев, но не более 5 550 баллов.',
            image: '/img-next/png/info/CardMir/1.png',
        },
        {
            tags: ['3'],
            title: 'За каждые 20 ₽ в магазинах «Пятёрочка»',
            subtitle:
                '<p></p>4 балла </br>если стоимость покупки ≥ 555₽</p><p>3 балла если стоимость покупки менее 555₽.</br> Предъявляйте карту кассиру до оплаты покупки,в том числе при оплате смартфоном</p>',
            image: '/img-next/png/info/CardMir/2.png',
        },
        {
            tags: ['4'],
            title: 'За каждые 20 ₽ в других магазинах',
            subtitle: '2 балла </br>при любой стоимости покупки',
            image: '/img-next/png/info/CardMir/3.png',
        },
    ],
};

export const mockInfoBlockTinaData = {
    section: null,
    type: 'primary',
    name: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            bg: '',
            image: '',
            link: '#',
            tags: [],
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
            size: 'small',
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            link: '#',
            tags: [],
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
            size: 'big',
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            link: '#',
            tags: [],
            gtag: {
                action: 'click',
                category: 'test',
                label: 'test',
            },
            size: 'small',
        },
    ],
};
