import React from 'react';

import { TinaTariffsDocumentsArchive } from '@/components/blocks/TariffsDocumentsArchive/index.tina';
import { mockTariffDocumentsArchiveTina } from '@/data/blocks/tariffDocumentsArchive';

export const tariffsDocumentsArchiveBlock = {
    Component: props => <TinaTariffsDocumentsArchive {...props} />,
    template: {
        label: 'Архив тарифов',
        defaultItem: () => mockTariffDocumentsArchiveTina,
        fields: [
            {
                label: 'Список фильтров',
                name: 'filter',
                component: 'group-list',
                itemProps: (item: { value: string }, index: number) => ({
                    key: index,
                    label: item.value,
                }),
                defaultItem: () => mockTariffDocumentsArchiveTina.filter[0],
                fields: [
                    {
                        name: 'value',
                        component: 'text',
                        label: 'Фильтр',
                    },
                    {
                        name: 'xmlId',
                        component: 'text',
                        label: 'Код привязки',
                    },
                ],
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { xmlId: string }, index: number) => ({
                    key: index,
                    label: item.xmlId,
                }),
                defaultItem: () => mockTariffDocumentsArchiveTina.list[0],
                fields: [
                    {
                        name: 'xmlId',
                        component: 'text',
                        label: 'Код привязки',
                    },
                    {
                        label: 'Список секций',
                        name: 'sections',
                        component: 'group-list',
                        itemProps: (item: { date: string }, index: number) => ({
                            key: index,
                            label: item.date,
                        }),
                        defaultItem: () => mockTariffDocumentsArchiveTina.list[0].sections[0],
                        fields: [
                            {
                                name: 'date',
                                component: 'text',
                                label: 'Дата',
                            },
                            {
                                label: 'Список документов',
                                name: 'documents',
                                component: 'group-list',
                                itemProps: (item: { documentName: string }, index: number) => ({
                                    key: index,
                                    label: item.documentName,
                                }),
                                defaultItem: () => mockTariffDocumentsArchiveTina.list[0].sections[0].documents[0],
                                fields: [
                                    {
                                        component: 'text',
                                        name: 'documentName',
                                        label: 'Заголовок',
                                    },
                                    {
                                        component: 'text',
                                        name: 'document',
                                        label: 'Ссылка',
                                    },
                                    {
                                        component: 'text',
                                        name: 'permanentDocument',
                                        label: 'Ссылка для редиректа',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
