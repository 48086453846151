import clsx from 'clsx';
import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IOurTarget {
    title: string;
    desc: string;
    image: string;
    size?: 'full' | 'half';
}

export interface IOurTargets {
    _template: 'ourTargets';
    heading?: string;
    targets: IOurTarget[];
}

const OurTargets: FC<IOurTargets> = ({ heading, targets }) => {
    if (!targets || targets?.length <= 0) return null;

    return (
        <div className="section">
            <div className={cn.wrapper}>
                <TextField
                    text={heading}
                    name="heading"
                    isHTML
                    htmlValue={heading}
                    className={cn.heading}
                    customTag="h2"
                />
                <div className={cn.grid}>
                    {targets?.map(({ title, desc, image, size }: IOurTarget, index) => (
                        <div className={clsx(cn.item, size ? cn[size] : cn.half)} key={index}>
                            <div className={cn.info}>
                                <TextField
                                    text={title}
                                    name={`targets.${index}.title`}
                                    isHTML
                                    htmlValue={title}
                                    className={cn.title}
                                />
                                <TextField
                                    text={desc}
                                    name={`targets.${index}.desc`}
                                    isHTML
                                    htmlValue={desc}
                                    className={cn.desc}
                                />
                            </div>
                            <div className={cn.imgWrap}>
                                <div className={cn.img}>
                                    <CustomImage src={image} alt={title} mockWidth="256" mockHeight="256" fill />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurTargets;
