import clsx from 'clsx';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import React from 'react';
import { registerLocale } from 'react-datepicker';

import CustomImage from '@/components/CustomImage';
import ShareIconBlock from '@/components/UI/ShareIconBlock';
import Title from '@/components/UI/Title';
import { IActionsSliderItem } from '@/types/actionsSlider';

import cn from './style.module.sass';

registerLocale('ru', ru);

function ActionDetailContent({
    id,
    code,
    startedAt,
    endedAt,
    conditions,
    conditionsSrc,
    mechanicsDescription,
    mechanicsDescriptionSrc,
    name,
    detailListUrl,
    portalLink,
    rules,
    linkTo,
}: IActionsSliderItem) {
    return (
        <div className={cn.detailWrap}>
            <div className={cn.detailTop}>
                <div className={cn.detailTopLeft}>
                    Акция действует&nbsp;до:{' '}
                    {endedAt && <span>{format(new Date(endedAt), 'PPP', { locale: ru })}</span>}
                </div>
                <div className={cn.detailTopRight}>
                    <div className={clsx(cn.detailTopRightItem, cn.logo)}>
                        <CustomImage
                            src="/img-next/svg/logo/logo-privet.svg"
                            alt="Лого акции"
                            mockWidth="82"
                            mockHeight="22"
                            width={130}
                            height={40}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                            }}
                        />
                    </div>
                    <div className={clsx(cn.detailTopRightItem, cn.cardInfo)}>
                        <div className={cn.cardInfoTop}>
                            Карта <span>&laquo;Мир&raquo;</span>
                        </div>
                        <div className={cn.cardInfoBottom}>Платежная система</div>
                    </div>
                </div>
            </div>
            <div className={cn.detailContent}>
                <Title title="Подробнее об акции" level={1} />

                <div dangerouslySetInnerHTML={{ __html: conditions }} />

                <div dangerouslySetInnerHTML={{ __html: rules }} />

                <div className={cn.detailContentMarc}>
                    {startedAt && endedAt ? (
                        <>
                            Акция действует&nbsp;с {format(new Date(startedAt), 'PPP', { locale: ru })} по{' '}
                            {format(new Date(endedAt), 'PPP', { locale: ru })}
                        </>
                    ) : (
                        'Дата не установлена'
                    )}
                </div>

                <a href={portalLink} target="_blank" rel="noreferrer">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: 'Подробное описание на&nbsp;сайте партнера акции',
                        }}
                    />
                </a>
            </div>
            <div className={cn.detailSocial}>
                <ShareIconBlock link={linkTo} list={['vk', 'ok']} />
            </div>
        </div>
    );
}

export default ActionDetailContent;
