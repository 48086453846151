import React from 'react';

import { TinaComHRLanding } from '@/components/blocks/ComHRLanding/index.tina';

export const comHrLandingBlock = {
    Component: props => <TinaComHRLanding {...props} />,
    template: {
        label: 'Com HR лендинг',
        fields: [],
    },
};
