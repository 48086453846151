import { MainPrizesProps } from '@/components/blocks/draw/main-prizes';

export const mockMainPrizes: MainPrizesProps = {
    _template: 'mainPrizes',
    title: 'Главные призы',
    items: [
        {
            title: '5 сертификатов на&nbsp;бытовую технику по&nbsp;100&nbsp;000&nbsp;₽',
            label: '50 шансов',
            backgroundSrc: '/img-next/svg/draw/prize-1.png',
            partnerLogoSrc: '/img-next/svg/draw/partner.svg',
        },
        {
            title: '5 украшений \n по&nbsp;100&nbsp;000&nbsp;₽',
            label: '1 шанс',
            backgroundSrc: '/img-next/svg/draw/prize-2.png',
            partnerLogoSrc: '/img-next/svg/draw/partner.svg',
        },
        {
            title: '5 украшений \n по&nbsp;100&nbsp;000&nbsp;₽',
            label: '125 шансов',
            backgroundSrc: '/img-next/svg/draw/prize-2.png',
            partnerLogoSrc: '/img-next/svg/draw/partner.svg',
        },
    ],
};
