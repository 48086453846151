import clsx from 'clsx';
import React from 'react';

import cn from '@/components/blocks/TinyGradientCategory/style.module.sass';
import CustomImage from '@/components/CustomImage';

interface ISlide {
    img: string;
    previewTitle: string;
    backgroundImage?: string;
}

const Slide = ({ img, previewTitle, backgroundImage }: ISlide): JSX.Element => (
    <div className={clsx(cn.item, backgroundImage && cn.activeImage)}>
        {backgroundImage && <CustomImage src={backgroundImage || ''} fill alt="" />}

        <div className={cn.imageWrapper}>
            <CustomImage src={img} className={cn.image} alt={previewTitle} fill quality={100} />
        </div>
        <div className={cn.text} dangerouslySetInnerHTML={{ __html: previewTitle }} />
    </div>
);
export default Slide;
