import React from 'react';

import { TinaDistributionAskQuestion } from '@/components/blocks/DistributionAskQuestion/index.tina';
import { mockDistributionAskQuestionBlockTinaData } from '@/data/blocks/distributionAskQuestion';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const distributionAskQuestionBlock = {
    Component: props => <TinaDistributionAskQuestion {...props} />,
    template: {
        label: 'Задать вопрос',
        defaultItem: () => mockDistributionAskQuestionBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'description',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Список элементов',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockDistributionAskQuestionBlockTinaData.list[0],
                fields: [
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер элемента',
                        options: [
                            { value: 'big', label: 'Большой' },
                            { value: 'small', label: 'Маленький' },
                        ],
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        label: 'Текст в тултипе',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'isTel',
                        component: 'toggle',
                        label: 'Ссылка является номером телефона',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
