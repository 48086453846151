import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Title from '@/components/UI/Title';

import cn from './style.module.sass';

export interface ITextBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'textBlock';
    title?: string;
    source: 'SEO_TEXT_FIRST' | 'SEO_TEXT_SECOND' | 'SEO_TEXT_THIRD';
    desc: {
        SEO_TEXT_FIRST: string;
        SEO_TEXT_SECOND: string;
        SEO_TEXT_THIRD: string;
    };
}

// Тут будет Inline Редактирование html

const TextBlock: React.FC<ITextBlock> = ({ title, desc, source }) => (
    <div className={clsx(cn.textBlock, 'section')}>
        <div className={cn.textBlockWrap}>
            {title && <Title level={3} title={title} />}
            <AnimatedComponent className={cn.textBlockContent} classNameActive={cn.animationInit}>
                <TextField
                    className={cn.textBlockDesc}
                    text={desc?.[source]}
                    name={`desc.${source}`}
                    isHTML
                    htmlValue={desc?.[source]}
                />
            </AnimatedComponent>
        </div>
    </div>
);

export default withBlockLinks(TextBlock);
