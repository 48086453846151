import React from 'react';

import { TinaPossibilities } from '@/components/blocks/Possibilities/index.tina';
import { mockPossibilitiesBlockTinaData } from '@/data/blocks/possibilities';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const possibilitiesBlock = {
    Component: props => <TinaPossibilities {...props} />,
    template: {
        label: 'Полезные услуги',
        defaultItem: () => mockPossibilitiesBlockTinaData,
        fields: [
            {
                name: 'title',
                label: 'Заголовок',
                component: 'text',
            },
            {
                name: 'list',
                label: 'Список',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockPossibilitiesBlockTinaData.list[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка в углу',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageAlt',
                        label: 'Описание картинки',
                        component: 'text',
                    },
                    {
                        name: 'name',
                        label: 'Заголовок',
                        component: 'text',
                    },
                    {
                        name: 'desc',
                        label: 'Описание',
                        component: HtmlEditorFeatured,
                    },
                ],
            },
        ],
    },
};
