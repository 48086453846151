import React from 'react';

import cn from './style.module.sass';

const IconCalendar: React.FC = () => (
    <div className={cn.icon}>
        <svg
            className={cn.svg}
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={cn.svgStroke}
                d="M3.83404 19.9999H13.501C15.6149 19.9999 17.3351 18.2797 17.3351 16.1658V6.49886C17.3351 4.86585 16.3068 3.47179 14.8655 2.9202V4.01431C14.8655 4.10622 14.8586 4.19631 14.8486 4.28541C14.8219 4.51847 14.7614 4.74163 14.6715 4.94916C14.3087 5.78733 13.4744 6.37548 12.5044 6.37548C11.3879 6.37548 10.4527 5.59612 10.2076 4.55353C10.1868 4.46575 10.1702 4.37664 10.1597 4.28523C10.1495 4.19596 10.1432 4.10571 10.1432 4.01413V2.66455H7.19173V4.01413C7.19173 4.10604 7.1853 4.19613 7.17526 4.28523C7.16455 4.37664 7.14808 4.46558 7.12733 4.55353C6.88242 5.59595 5.94674 6.37548 4.83054 6.37548C3.86077 6.37548 3.02588 5.78733 2.66306 4.94916C2.57363 4.74163 2.51318 4.51879 2.4865 4.28541C2.4758 4.19614 2.46954 4.10589 2.46954 4.01431V2.9202C1.02821 3.47179 0 4.86585 0 6.49886V16.1658C0 18.2797 1.72016 20.0001 3.83413 20.0001L3.83404 19.9999ZM1.8889 9.92737H15.4463V16.1655C15.4463 17.2382 14.5733 18.1107 13.5011 18.1107L3.83421 18.1108C2.76152 18.1108 1.88907 17.2384 1.88907 16.1657L1.8889 9.92737Z"
                fill="#67798E"
            />
            <path
                className={cn.svgStroke}
                d="M4.05739 4.55353C4.22802 4.79795 4.51082 4.9587 4.83118 4.9587C5.15202 4.9587 5.4345 4.79795 5.60497 4.55353C5.66113 4.47282 5.70231 4.38158 5.7313 4.28523C5.75748 4.19876 5.77576 4.10916 5.77576 4.01412V0.944386C5.77576 0.422777 5.35282 0 4.83138 0C4.30977 0 3.88699 0.422946 3.88699 0.944386V4.01412C3.88699 4.10899 3.90544 4.19876 3.93146 4.28523C3.96012 4.38174 4.00097 4.47266 4.05746 4.55353H4.05739Z"
                fill="#67798E"
            />
            <path
                className={cn.svgStroke}
                d="M11.7313 4.55353C11.9019 4.79795 12.1842 4.9587 12.5051 4.9587C12.8254 4.9587 13.1079 4.79795 13.2789 4.55353C13.335 4.47282 13.3762 4.38158 13.4052 4.28523C13.4309 4.19876 13.4497 4.10916 13.4497 4.01412V0.944386C13.4497 0.422777 13.0267 0 12.5053 0C11.9837 0 11.5609 0.422946 11.5609 0.944386V4.01412C11.5609 4.10899 11.5788 4.19876 11.6054 4.28523C11.634 4.38174 11.6745 4.47266 11.7314 4.55353H11.7313Z"
                fill="#67798E"
            />
        </svg>
    </div>
);

export default IconCalendar;
