import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IQuotePersonItem } from '@/types/quotePerson/item';

import cn from './style.module.sass';

const ModalItem: React.FC<IQuotePersonItem & { pos: number }> = ({ img, personName, personPosition, text, pos }) => (
    <div className={cn.item}>
        <div className={cn.itemGrid}>
            <div className={cn.itemTop}>
                {img && (
                    <div className={cn.imageWrapper}>
                        <div className={cn.image}>
                            <CustomImage src={img} alt={personName} mockWidth="96" mockHeight="96" fill />
                        </div>
                    </div>
                )}
                <div className={cn.content}>
                    <TextField text={personName} name={`list.[${pos}].personName`} className={cn.name} isTextArea />
                    {personPosition && (
                        <TextField
                            text={personPosition}
                            name={`list.[${pos}].personPosition`}
                            className={cn.position}
                            isTextArea
                        />
                    )}
                </div>
            </div>
            <div className={cn.itemBottom}>
                <TextField text={text} name={`list.[${pos}].text`} className={cn.text} isHTML htmlValue={text} />
            </div>
        </div>
    </div>
);

export default ModalItem;
