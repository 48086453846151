import clsx from 'clsx';
import { useParams, usePathname } from 'next/navigation';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import CustomAccordion from '@/components/UI/CustomAccordion';
import { IOffersCreditsItem } from '@/types/offersCredits/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import Arrows from './arrows';
import cn from './style.module.sass';

export interface IOffersCredits {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'offersCredits';
    /**
     * Список категорий
     */
    list: IOffersCreditsItem[];
}

const OffersCredits: React.FC<IOffersCredits> = ({ list }) => {
    const pathname = usePathname();
    const params = useParams<{ slug: string[] }>();
    const path = params?.slug ? pathname?.split('#')[0]?.split('?')[0] : '';

    const getTrigger = (trigger: string, index: number) => (
        <div className={clsx(cn.triggerWrap)}>
            <TextField
                text={trigger}
                name={`list.[${index}].title`}
                isHTML
                htmlValue={trigger}
                className={cn.trigger}
            />
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </div>
    );

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.offersCredits, 'section')}>
                <div className={cn.offersCreditsWrap}>
                    <div className={cn.offersCreditsGrid}>
                        <div className={cn.offersCreditsRow}>
                            {list?.map(({ title, categories, gtag }, index) => (
                                <AnimatedComponent
                                    className={cn.offersCreditsCollapsibleWrap}
                                    classNameActive={cn.animationInit}
                                    key={index}
                                >
                                    <CustomAccordion
                                        trigger={getTrigger(title, index)}
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                    >
                                        {categories?.map(({ name, categoriesList }, categoriesIndex) => (
                                            <AnimatedComponent
                                                className={clsx(cn.offersRow, cn.open)}
                                                classNameActive={cn.animationInit}
                                                key={categoriesIndex}
                                            >
                                                <div className={cn.offersTitleWrap}>
                                                    {name && (
                                                        <TextField
                                                            text={name}
                                                            name={`list.[${index}].categories.[${categoriesIndex}].name`}
                                                            className={cn.offersTitle}
                                                            isHTML
                                                            htmlValue={name}
                                                        />
                                                    )}
                                                </div>

                                                <div className={cn.offersList}>
                                                    {categoriesList?.map(({ text, link }, categoriesListIndex) => {
                                                        const currentLink = link?.split('.ru')[1];

                                                        if (path === currentLink) {
                                                            return null;
                                                        }

                                                        return (
                                                            <AnimatedComponent
                                                                className={clsx(cn.offersItem, cn.open)}
                                                                key={categoriesListIndex}
                                                                classNameActive={cn.animationInit}
                                                            >
                                                                <TextField
                                                                    text={text}
                                                                    name={`list.[${index}].categories.[${categoriesIndex}].categoriesList.[${categoriesListIndex}].text`}
                                                                    className={cn.offersLink}
                                                                    isHTML
                                                                    htmlValue={text}
                                                                    customTag="a"
                                                                    href={link}
                                                                />
                                                            </AnimatedComponent>
                                                        );
                                                    })}
                                                </div>
                                            </AnimatedComponent>
                                        ))}
                                    </CustomAccordion>
                                </AnimatedComponent>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(OffersCredits);
