export const mockMobileAppBlockData = {
    name: 'Связаться с нами',
    mobile: {
        title: 'Мобильное приложение',
        previewText:
            'Получайте информацию о&nbsp;продуктах банка не&nbsp;выходя из&nbsp;дома: узнайте баланс, сумму платежа по&nbsp;кредиту или карте, оформляйте банковские продукты и&nbsp;услуги, совершайте платежи, в&nbsp;том числе коммунальные. Так&nbsp;же можно связаться с&nbsp;сотрудником банка в&nbsp;онлайн-чате.',
        link: {
            address: 'https://my.pochtabank.ru/',
            title: 'Все возможности Почта Банк Онлайн',
        },
        store: [
            {
                image: '/img-next/svg/services/AppStore.svg',
                name: 'App Store',
                link: 'https://itunes.apple.com/ru/app/leto-bank/id979116495?mt=8',
            },
            {
                image: '/img-next/svg/services/GooglePlay.svg',
                name: 'Google Play',
                link: 'https://play.google.com/store/apps/details?id=ru.letobank.Prometheus',
            },
            {
                image: '/img-next/svg/services/AppGallery.svg',
                name: 'AppGallery',
                link: 'https://appgallery8.huawei.com/#/app/C101191061',
            },
        ],
        image: '/img-next/png/mobileBank2.png',
    },
};

export const mockMobileAppBlockTinaData = {
    section: null,
    name: 'Заголовок',
    mobile: {
        title: 'Заголовок',
        previewText: 'Описание',
        link: {
            address: '#',
            title: 'Заголовок',
        },
        store: [
            {
                image: '',
                name: 'Название',
                link: '#',
            },
            {
                image: '',
                name: 'Название',
                link: '#',
            },
            {
                image: '',
                name: 'Название',
                link: '#',
            },
        ],
        image: '',
    },
};
