export const mockCitiesLoansBlockData = {
    title: 'Города',
    limit: 18,
    list: [
        {
            text: 'в Абакане',
            link: '#',
        },
        {
            text: 'в Анадыре',
            link: '#',
        },
        {
            text: 'в Архангельске',
            link: '#',
        },
        {
            text: 'в Астрахани',
            link: '#',
        },
        {
            text: 'в Барнауле',
            link: '#',
        },
        {
            text: 'в Белгороде',
            link: '#',
        },
        {
            text: 'в Биробиджане',
            link: '#',
        },
        {
            text: 'в Благовещенске',
            link: '#',
        },
        {
            text: 'в Брянске',
            link: '#',
        },
        {
            text: 'в Великом Новгороде',
            link: '#',
        },
        {
            text: 'во Владикавказе',
            link: '#',
        },
        {
            text: 'во Владимире',
            link: '#',
        },
        {
            text: 'во Владивостоке',
            link: '#',
        },
        {
            text: 'в Волгограде',
            link: '#',
        },
        {
            text: 'в Вологде',
            link: '#',
        },
        {
            text: 'в Воронеже',
            link: '#',
        },
        {
            text: 'в Горно-Алтайске',
            link: '#',
        },
        {
            text: 'в Грозном',
            link: '#',
        },
        {
            text: 'в Волгограде',
            link: '#',
        },
        {
            text: 'в Вологде',
            link: '#',
        },
        {
            text: 'в Воронеже',
            link: '#',
        },
        {
            text: 'в Горно-Алтайске',
            link: '#',
        },
        {
            text: 'в Грозном',
            link: '#',
        },
    ],
};

export const mockCitiesLoansBlockDataTina = {
    section: null,
    title: 'Заголовок',
    limit: 18,
    list: [
        {
            text: 'Заголовок',
            link: '#',
        },
    ],
};
