import clsx from 'clsx';
import React from 'react';

import Button from '@/components/UI/Button';
import { useTheme } from '@/context/ThemeProvider';

import cn from './style.module.sass';

interface IPboFaqDetailContent {
    title: string;
    content: string;
    button?: {
        href: string;
        label: string;
    };
}

const PboFaqDetailContent = ({ title, content, button }: IPboFaqDetailContent) => {
    const { theme } = useTheme();
    return (
        <div className={cn.wrapper}>
            <div className={clsx(cn.title, cn?.[theme])}>{title}</div>
            <div className={cn.content} dangerouslySetInnerHTML={{ __html: content }} />
            {button && button?.label && (
                <Button
                    variant="btnBlue"
                    label={button.label}
                    type="link"
                    href={button?.href}
                    customClass={cn.button}
                />
            )}
        </div>
    );
};

export default PboFaqDetailContent;
