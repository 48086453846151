import React from 'react';

import { TinaDiagram } from '@/components/blocks/Diagram/index.tina';
import { mockDiagramBlockTinaData } from '@/data/blocks/diagram';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const diagramBlock = {
    Component: props => <TinaDiagram {...props} />,
    template: {
        label: 'Диаграмма',
        defaultItem: mockDiagramBlockTinaData,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Название',
            },
            {
                label: 'Диаграмма 1',
                name: 'firstDiagram',
                component: 'group',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockDiagramBlockTinaData.firstDiagram,
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Название столбца',
                    },

                    {
                        label: 'Красный столбец',
                        name: 'redColumn',
                        component: 'group',
                        itemProps: (item: { text: string }, index: number) => ({
                            key: index,
                            label: item.text,
                        }),
                        defaultItem: () => mockDiagramBlockTinaData.firstDiagram.redColumn,
                        fields: [
                            {
                                component: 'select',
                                name: 'value',
                                label: 'Значение',
                                options: [
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 10,
                                        label: '10',
                                    },
                                    {
                                        value: 20,
                                        label: '20',
                                    },
                                    {
                                        value: 30,
                                        label: '30',
                                    },
                                    {
                                        value: 40,
                                        label: '40',
                                    },
                                    {
                                        value: 50,
                                        label: '50',
                                    },
                                    {
                                        value: 60,
                                        label: '60',
                                    },
                                    {
                                        value: 70,
                                        label: '70',
                                    },
                                    {
                                        value: 80,
                                        label: '80',
                                    },
                                    {
                                        value: 90,
                                        label: '90',
                                    },
                                    {
                                        value: 100,
                                        label: '100',
                                    },
                                ],
                            },
                            {
                                name: 'text',
                                label: 'Текст',
                                component: HtmlEditorFeatured,
                            },
                            {
                                name: 'legendText',
                                label: 'Текст легенды',
                                component: 'text',
                            },
                        ],
                    },

                    {
                        label: 'Синий столбец',
                        name: 'blueColumn',
                        component: 'group',
                        itemProps: (item: { text: string }, index: number) => ({
                            key: index,
                            label: item.text,
                        }),
                        defaultItem: () => mockDiagramBlockTinaData.firstDiagram.blueColumn,
                        fields: [
                            {
                                component: 'select',
                                name: 'value',
                                label: 'Значение',
                                options: [
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 10,
                                        label: '10',
                                    },
                                    {
                                        value: 20,
                                        label: '20',
                                    },
                                    {
                                        value: 30,
                                        label: '30',
                                    },
                                    {
                                        value: 40,
                                        label: '40',
                                    },
                                    {
                                        value: 50,
                                        label: '50',
                                    },
                                    {
                                        value: 60,
                                        label: '60',
                                    },
                                    {
                                        value: 70,
                                        label: '70',
                                    },
                                    {
                                        value: 80,
                                        label: '80',
                                    },
                                    {
                                        value: 90,
                                        label: '90',
                                    },
                                    {
                                        value: 100,
                                        label: '100',
                                    },
                                ],
                            },
                            {
                                name: 'text',
                                label: 'Текст',
                                component: HtmlEditorFeatured,
                            },
                            {
                                name: 'legendText',
                                label: 'Текст легенды',
                                component: 'text',
                            },
                        ],
                    },
                ],
            },

            {
                label: 'Диаграмма 2',
                name: 'secondDiagram',
                component: 'group',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockDiagramBlockTinaData.secondDiagram,
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Название столбца',
                    },

                    {
                        label: 'Красный столбец',
                        name: 'redColumn',
                        component: 'group',
                        itemProps: (item: { text: string }, index: number) => ({
                            key: index,
                            label: item.text,
                        }),
                        defaultItem: () => mockDiagramBlockTinaData.secondDiagram.redColumn,
                        fields: [
                            {
                                component: 'select',
                                name: 'value',
                                label: 'Значение',
                                options: [
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 10,
                                        label: '10',
                                    },
                                    {
                                        value: 20,
                                        label: '20',
                                    },
                                    {
                                        value: 30,
                                        label: '30',
                                    },
                                    {
                                        value: 40,
                                        label: '40',
                                    },
                                    {
                                        value: 50,
                                        label: '50',
                                    },
                                    {
                                        value: 60,
                                        label: '60',
                                    },
                                    {
                                        value: 70,
                                        label: '70',
                                    },
                                    {
                                        value: 80,
                                        label: '80',
                                    },
                                    {
                                        value: 90,
                                        label: '90',
                                    },
                                    {
                                        value: 100,
                                        label: '100',
                                    },
                                ],
                            },
                            {
                                name: 'text',
                                label: 'Текст',
                                component: HtmlEditorFeatured,
                            },
                            {
                                name: 'legendText',
                                label: 'Текст легенды',
                                component: 'text',
                            },
                        ],
                    },

                    {
                        label: 'Синий столбец',
                        name: 'blueColumn',
                        component: 'group',
                        itemProps: (item: { text: string }, index: number) => ({
                            key: index,
                            label: item.text,
                        }),
                        defaultItem: () => mockDiagramBlockTinaData.secondDiagram.blueColumn,
                        fields: [
                            {
                                component: 'select',
                                name: 'value',
                                label: 'Значение',
                                options: [
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 10,
                                        label: '10',
                                    },
                                    {
                                        value: 20,
                                        label: '20',
                                    },
                                    {
                                        value: 30,
                                        label: '30',
                                    },
                                    {
                                        value: 40,
                                        label: '40',
                                    },
                                    {
                                        value: 50,
                                        label: '50',
                                    },
                                    {
                                        value: 60,
                                        label: '60',
                                    },
                                    {
                                        value: 70,
                                        label: '70',
                                    },
                                    {
                                        value: 80,
                                        label: '80',
                                    },
                                    {
                                        value: 90,
                                        label: '90',
                                    },
                                    {
                                        value: 100,
                                        label: '100',
                                    },
                                ],
                            },
                            {
                                name: 'text',
                                label: 'Текст',
                                component: HtmlEditorFeatured,
                            },
                            {
                                name: 'legendText',
                                label: 'Текст легенды',
                                component: 'text',
                            },
                        ],
                    },
                ],
            },

            {
                name: 'button',
                component: 'group',
                label: 'Нижняя плашка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Название',
                    },
                ],
            },
        ],
    },
};
