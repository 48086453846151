import React from 'react';

import cn from './style.module.sass';

function Decor() {
    return (
        <svg className={cn.svg} viewBox="0 0 192 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.8 0H12L7.2 4H0L4.8 0Z" className={cn.svgFill} />
            <path d="M100.8 0H108L103.2 4H96L100.8 0Z" className={cn.svgFill} />
            <path d="M52.8 0H60L55.2 4H48L52.8 0Z" className={cn.svgFill} />
            <path d="M148.8 0H156L151.2 4H144L148.8 0Z" className={cn.svgFill} />
            <path d="M28.8 0H36L31.2 4H24L28.8 0Z" className={cn.svgFill} />
            <path d="M124.8 0H132L127.2 4H120L124.8 0Z" className={cn.svgFill} />
            <path d="M76.8 0H84L79.2 4H72L76.8 0Z" className={cn.svgFill} />
            <path d="M172.8 0H180L175.2 4H168L172.8 0Z" className={cn.svgFill} />
            <path d="M16.8 0H24L19.2 4H12L16.8 0Z" className={cn.svgFill} />
            <path d="M112.8 0H120L115.2 4H108L112.8 0Z" className={cn.svgFill} />
            <path d="M64.8 0H72L67.2 4H60L64.8 0Z" className={cn.svgFill} />
            <path d="M160.8 0H168L163.2 4H156L160.8 0Z" className={cn.svgFill} />
            <path d="M40.8 0H48L43.2 4H36L40.8 0Z" className={cn.svgFill} />
            <path d="M136.8 0H144L139.2 4H132L136.8 0Z" className={cn.svgFill} />
            <path d="M88.8 0H96L91.2 4H84L88.8 0Z" className={cn.svgFill} />
            <path d="M184.8 0H192L187.2 4H180L184.8 0Z" className={cn.svgFill} />
        </svg>
    );
}

export default Decor;
