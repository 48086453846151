import React from 'react';

import AnimatedComponent from '@/components/Animation';
import YouTubeVideosElement from '@/components/blocks/youTubeVideos/YouTubeVideosElement';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IYouTube {
    _template?: 'youtube';
    src?: string;
    title?: string;
    description?: string;
    buttonColor?: string;
}

const YouTube: React.FC<IYouTube> = ({ title, description, src, buttonColor = 'dark' }) => (
    <AnimatedComponent>
        <div className="section">
            <div className={cn.fullWrapper}>
                <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                <TextField
                    className={cn.description}
                    isHTML
                    htmlValue={description}
                    text={description}
                    name="description"
                />
                <div className={cn.iframe}>
                    <YouTubeVideosElement link={src} title={title} fullBlock buttonColor={buttonColor} />
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default withBlockLinks(YouTube);
