import React from 'react';

import cn from './style.module.sass';

interface IProps {
    list: string[];
}

const TariffCompareCharacteristics: React.FC<IProps> = ({ list }) => {
    if (list?.length <= 0) return null;

    return (
        <div className={cn.infoInner}>
            <div className={cn.head} />
            {list?.map((item, index) => (
                <div className={cn.row} key={index}>
                    {item}
                </div>
            ))}
        </div>
    );
};

export default TariffCompareCharacteristics;
