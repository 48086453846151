import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import AdvantagesList from '@/components/blocks/advantages/Advantages/list';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import { IAdvantagesFilteredItems, IAdvantagesItem } from '@/types/advantages/item';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

export interface IAdvantages {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'advantages';
    /**
     * Карточки.
     */
    section?: number;
    list: IAdvantagesItem[];
    tagList?: ITag[];
    name?: string;
    text?: string;
}

const Advantages: React.FC<IAdvantages> = ({ list, name, text, tagList }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const items = useMemo(
        () =>
            list?.reduce(
                (acc: IAdvantagesFilteredItems, item, index) => {
                    const mobileSizes = ['small', 'middle'];

                    const itemInTags = item?.tags?.includes(selectedTag) || !selectedTag;

                    if (!itemInTags) return acc;

                    if (mobileSizes.includes(item.size)) {
                        return {
                            desktop: [...acc.desktop, { ...item, pos: index }],
                            mobile: {
                                main: acc.mobile.main,
                                slider: [...acc.mobile.slider, { ...item, pos: index }],
                            },
                        };
                    }

                    return {
                        desktop: [...acc.desktop, { ...item, pos: index }],
                        mobile: {
                            main: [...acc.mobile.main, { ...item, pos: index }],
                            slider: acc.mobile.slider,
                        },
                    };
                },
                {
                    desktop: [],
                    mobile: {
                        main: [],
                        slider: [],
                    },
                }
            ),
        [selectedTag, list, tagList]
    );

    const showHead = name || tagList;

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.advantages, 'section')}>
                <div className={cn.advantagesWrap}>
                    {showHead && (
                        <div className={cn.top}>
                            <div className={clsx(cn.topItem, cn.topItemTitle)}>
                                {name && (
                                    <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />
                                )}
                                {text && (
                                    <TextField
                                        text={text}
                                        name="text"
                                        isHTML
                                        htmlValue={text}
                                        className={cn.advantagesText}
                                    />
                                )}
                            </div>

                            {tagList?.length > 0 && (
                                <div className={clsx(cn.topItem, cn.topItemFilter)}>
                                    <AnimatedComponent className={clsx(cn.filter)} classNameActive={cn.animationInit}>
                                        <TagList tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                                    </AnimatedComponent>
                                </div>
                            )}
                        </div>
                    )}
                    <AdvantagesList list={items} />
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Advantages);
