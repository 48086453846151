import React from 'react';

import CreditCardButton from '@/components/SpecialOffer/CreditCardButton';
import CreditCardContent from '@/components/SpecialOffer/CreditCardContent';
import { IOfferCard } from '@/types/menu/menu';

import cn from './style.module.sass';

const CreditCard: React.FC<IOfferCard> = ({
    title,
    desc,
    href,
    subDesc,
    img,
    button,
    buttonType,
    isPWA = false,
    gtag,
}) => (
    <div className={cn.card}>
        <CreditCardContent title={title} desc={desc} subDesc={subDesc} img={img} isPWA={isPWA}>
            <CreditCardButton href={href} button={button} buttonType={buttonType} gtag={gtag} />
        </CreditCardContent>
    </div>
);

export default CreditCard;
