import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IFeedbackFormFrame {
    _template: 'feedbackFormFrame';
    title: string;
}

const FeedbackFormFrameContent = dynamic(async () => import('./FeedbackFormFrameContent'), {
    ssr: false,
});

const FeedbackFormFrame: React.FC<IFeedbackFormFrame & { index: number }> = ({ title }) => (
    <div className="section">
        <FeedbackFormFrameContent title={title} />
    </div>
);

export default withBlockLinks(FeedbackFormFrame);
