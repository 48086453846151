import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IInstructionInfoGraphicItem {
    name: string;
    image: string;
    positionImage: 'top' | 'bottom';
    desc?: string;
}

function InstructionInfoGraphicItem({
    image,
    positionImage,
    desc,
    pos,
}: IInstructionInfoGraphicItem & { pos: number }) {
    return (
        <AnimatedComponent classNameActive={cn.animationInit} className={cn.item}>
            <div className={cn.itemWrap}>
                <div className={clsx(cn.itemInnerWrap, positionImage === 'top' && cn.itemInnerWrapTop)}>
                    <div className={cn.itemImageWrap}>
                        <CustomImage src={image} alt={desc} width={260} height={214} />
                    </div>
                </div>
                {desc && (
                    <TextField text={desc} name={`list.[${pos}].desc`} className={cn.desc} isHTML htmlValue={desc} />
                )}
            </div>
        </AnimatedComponent>
    );
}

export default InstructionInfoGraphicItem;
