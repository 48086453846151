import React from 'react';

import Container from '@/components/blocks/SalesHRLanding/core/Container';
import Footer from '@/components/blocks/SalesHRLanding/core/Footer';
import Form from '@/components/blocks/SalesHRLanding/core/Form';
import Header from '@/components/blocks/SalesHRLanding/core/Header';
import SalesHRAdvantages from '@/components/blocks/SalesHRLanding/core/SalesHRAdvantages';
import SalesHROffers from '@/components/blocks/SalesHRLanding/core/SalesHROffers';
import SalesHRPromo from '@/components/blocks/SalesHRLanding/core/SalesHRPromo';
import { advantages } from '@/components/blocks/SalesHRLanding/data/advantages';
import { hrGtag } from '@/components/blocks/SalesHRLanding/data/hrGtag';
import { menu } from '@/components/blocks/SalesHRLanding/data/menu';
import { offers } from '@/components/blocks/SalesHRLanding/data/offers';
import { promo } from '@/components/blocks/SalesHRLanding/data/promo';
import { APPLY_FOR_JOB_KEY, DO_WE_OFFER_KEY } from '@/components/blocks/SalesHRLanding/helpers/constants/menu';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export interface ISalesHRLanding {
    _template?: 'SalesHRLanding';
}

function SalesHRLanding({ _template }: ISalesHRLanding) {
    if (!Feature.isFeatureEnabled('enableSalesHRLanding')) return null;

    return (
        <div className={cn.wrapper}>
            <Container>
                <Header menu={menu} />
            </Container>
            <Container>
                <SalesHRPromo
                    title={promo.title}
                    description={promo.description}
                    buttonValue={promo.buttonValue}
                    slides={promo.slides}
                />
            </Container>
            <Container id={DO_WE_OFFER_KEY}>
                <SalesHRAdvantages title={advantages.title} list={advantages.list} />
            </Container>
            <Container>
                <SalesHROffers offers={offers} />
            </Container>
            <Container id={APPLY_FOR_JOB_KEY}>
                <Form
                    title="Откликнуться на вакансию"
                    buttonSubmitValue="Отправить отклик"
                    _template="salesHRLandingForm"
                    hrGtag={hrGtag}
                />
            </Container>
            <Footer menu={menu} />
        </div>
    );
}

export default withBlockLinks(SalesHRLanding);
