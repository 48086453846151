import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import MediaPublicationsPerson, {
    IMediaPublicationsPerson,
} from '@/components/blocks/mediaPublications/MediaPublicationsPerson';
import MediaPublicationsSlider from '@/components/blocks/mediaPublications/mediaPublicationsSlider';
import { IMediaPublicationsSliderItem } from '@/components/blocks/mediaPublications/mediaPublicationsSlider/item';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IMediaPublications {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'mediaPublications';
    name?: string;
    title?: string;
    person: IMediaPublicationsPerson;
    list?: IMediaPublicationsSliderItem[];
}

const MediaPublications: React.FC<IMediaPublications> = ({ list, title, person }) => (
    <AnimatedComponent
        className={clsx(cn.mediaPublications, 'section')}
        classNameActive={cn.animationInit}
        role="presentation"
    >
        <div className={cn.mediaPublicationsWrap}>
            {title && (
                <TextField text={title} className={cn.mainTitle} name="title" customTag="h3" isHTML htmlValue={title} />
            )}
            <div className={cn.mediaPublicationsGrid}>
                {person && (
                    <div className={cn.mediaPublicationsPerson}>
                        <MediaPublicationsPerson {...person} />
                    </div>
                )}
                {list && list?.length > 0 && (
                    <div className={cn.mediaPublicationsSlider}>
                        <MediaPublicationsSlider list={list} />
                    </div>
                )}
            </div>
        </div>
    </AnimatedComponent>
);

export default MediaPublications;
