import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IBankText {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'bankText';
    title: string;
    desc: string;
}

const BankText: React.FC<IBankText> = ({ title, desc }) => (
    <div className={clsx(cn.bankText, 'section')}>
        <AnimatedComponent className={cn.wrap} classNameActive={cn.animationInit}>
            <div className={cn.grid}>
                <div className={clsx(cn.item, cn.itemTitle)}>
                    <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                </div>
                <div className={clsx(cn.item, cn.itemDesc)}>
                    <TextField className={cn.desc} text={desc} name="desc" isHTML htmlValue={desc} />
                </div>
            </div>
        </AnimatedComponent>
    </div>
);

export default withBlockLinks(BankText);
