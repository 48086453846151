import React from 'react';

import { TinaAllExchangeRates } from '@/components/blocks/AllExchangeRates/AllExchangeRates/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const allExchangeRatesBlock = {
    Component: props => <TinaAllExchangeRates {...props} />,
    template: {
        label: 'Все курсы валют',
        defaultItem: () => ({}),
        fields: [
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
