import React from 'react';

import { TinaPersons } from '@/components/blocks/Persons/index.tina';
import { mockPersonsBlockData } from '@/data/blocks/persons';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const personsBlock = {
    Component: props => <TinaPersons {...props} />,
    template: {
        label: 'Персоны',
        defaultItem: () => mockPersonsBlockData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },

            {
                name: 'list',
                component: 'group-list',
                label: 'Список элементов',
                defaultItem: () => mockPersonsBlockData.list[0],
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Имя',
                    },
                    {
                        name: 'status',
                        component: 'text',
                        label: 'Статус',
                    },
                    {
                        name: 'description',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'backSideTitle',
                        component: 'Заголовок на обратной стороне',
                        label: 'Имя',
                    },
                    {
                        name: 'backSideDescription',
                        component: HtmlEditorFeatured,
                        label: 'Описание на обратной стороне',
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Кнопка',
                        fields: [
                            {
                                name: 'href',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Название',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
