import Image from 'next/image';
import React, { FC } from 'react';

import TextField from '@/components/TextField';

import { IRefinanceAdvantagesCard } from '../../iRefinanceAdvantagesCard';
import cn from './style.module.sass';

const ExternalCreditsCard: FC<IRefinanceAdvantagesCard> = ({
    image,
    title,
    firstText,
    secondText,
    thirdText,
    fourthText,
}) => (
    <div className={cn.externalcreditscard}>
        <TextField text={title} className={cn.title} name="list[0].title" htmlValue={title} isHTML />
        <div className={cn.credits}>
            <div className={cn.bankPricesList}>
                <TextField
                    text={firstText}
                    className={cn.bankPriceElement}
                    name="list[0].firstText"
                    htmlValue={firstText}
                    isHTML
                />
                <TextField
                    text={secondText}
                    className={cn.bankPriceElement}
                    name="list[0].secondText"
                    htmlValue={secondText}
                    isHTML
                />
            </div>
            <div className={cn.equals} />
            <TextField
                text={thirdText}
                className={cn.sumOfCredits}
                name="list[0].thirdText"
                htmlValue={thirdText}
                isHTML
            />
        </div>
        <TextField
            text={fourthText}
            className={cn.monthlyPayment}
            name="list[0].fourthText"
            htmlValue={fourthText}
            isHTML
        />
        {image && <Image className={cn.image} src={image} alt={image} width={217} height={190} />}
    </div>
);

export default ExternalCreditsCard;
