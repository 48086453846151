import React from 'react';

import SalesHRAdvantagesList from '@/components/blocks/SalesHRLanding/core/SalesHRAdvantages/List';
import { Advantages } from '@/components/blocks/SalesHRLanding/types/advantages';

import cn from './style.module.sass';

type SalesHRAdvantagesProps = Advantages;

function SalesHRAdvantages({ title, list }: SalesHRAdvantagesProps) {
    return (
        <div className={cn.advantages}>
            <div className={cn.advantages__title}>{title}</div>
            <SalesHRAdvantagesList list={list} />
        </div>
    );
}

export default SalesHRAdvantages;
