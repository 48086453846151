import clsx from 'clsx';
import React from 'react';

import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    link: string;
    type: 'link' | 'anchor';
    linkName: string;
    gtag?: GTagEvent;
    hideOnMobile?: boolean;
}

function InfoBlockPensiyaMapButton({ link, linkName, type, gtag, hideOnMobile }: IProps) {
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const scrollOffset = isMobile ? 60 : 90;

    if (type === 'link')
        return (
            <div
                className={clsx(
                    hideOnMobile && cn.infoBlockBot,
                    hideOnMobile && cn.hideOnMobile,
                    !hideOnMobile && cn.infoBlockPensiyaItem,
                    !hideOnMobile && cn.infoBlockItemBtn,
                    !hideOnMobile && cn.showOnMobile
                )}
            >
                <Button
                    variant="btnBlue"
                    type="link"
                    href={link}
                    label={linkName}
                    isRedesign
                    size="small"
                    customClass={clsx(cn.btn, !hideOnMobile && 'full')}
                    onClick={() => {
                        if (gtag?.action) event(gtag);
                    }}
                />
            </div>
        );

    return (
        <div
            className={clsx(
                hideOnMobile && cn.infoBlockBot,
                hideOnMobile && cn.hideOnMobile,
                !hideOnMobile && cn.infoBlockPensiyaItem,
                !hideOnMobile && cn.infoBlockItemBtn,
                !hideOnMobile && cn.showOnMobile
            )}
        >
            <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className={cn.scrollWrap}>
                <Button
                    variant="btnBlue"
                    type="button"
                    href={link}
                    label={linkName}
                    isRedesign
                    size="small"
                    customClass={clsx(cn.btn, !hideOnMobile && 'full')}
                    onClick={() => {
                        if (gtag?.action) event(gtag);
                    }}
                />
            </ScrollLink>
        </div>
    );
}

export default InfoBlockPensiyaMapButton;
