export const mockIntroPromoAboutData = {
    name: 'Промо - "О банке"',
    heading: 'О банке',
    desc: 'Вы хотите реализовать свои возможности? Присоединяйтесь к нашей команде!',
    descBold: true,
    image: '/img-next/png/about/main.png',
};

export const mockIntroPromoForestData = {
    heading: 'Подари лес другу',
    subheading: 'Первый в мире проект по посадке деревьев через интернет',
    desc: 'Для восстановления леса не обязательно  быть клиентом Почта Банка, посадить деревья и получить именной сертификат может каждый!',
    image: '/img-next/png/forest/intro.png',
    isImageToBottomMobile: false,
    button: {
        name: 'Подарить лес другу',
        link: '/about',
    },
};

export const mockIntroPromoDataTina = {
    name: 'IntroPromo',
    heading: 'Заголовок',
    subheading: 'Подзаголовок',
    desc: 'Описание, какой-то пояснительный текст',
    image: '',
    isImageToBottomMobile: false,
    button: {
        name: 'Кнопка с ссылкой на другую страницу',
        link: '#',
    },
    enableLogo: true,
    logo: [
        {
            img: '',
            alt: 'Альтернативный текст',
        },
    ],
};
