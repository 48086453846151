import React from 'react';

import { TinaHrFaq } from '@/components/blocks/HrFaq/index.tina';
import { mockHrFaqBlockDataTina } from '@/data/blocks/hrFaq';
import { mockQuestionsBlockDataTina } from '@/data/blocks/questions';
import { questionsBlock } from '@/tina/blocks/questions';
import { quoteHrBlock } from '@/tina/blocks/quoteHr';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const hrFaqBlock = {
    Component: props => <TinaHrFaq {...props} />,
    template: {
        label: 'HR: Faq',
        defaultItem: () => mockHrFaqBlockDataTina,
        fields: [
            {
                component: 'group',
                label: 'Цитата',
                name: 'quoteHrBlock',
                fields: [
                    {
                        name: 'section',
                        component: 'section',
                        label: 'Привязка к разделу',
                    },
                    ...quoteHrBlock.template.fields,
                ],
            },
            {
                component: 'group',
                label: 'Вопросы ',
                name: 'questionsBlock',
                fields: [
                    ...questionsBlock.template.fields.filter(field => field.name !== 'list'),
                    {
                        name: 'tagList',
                        component: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        label: 'Список вопросов',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { name: string }, index: number) => ({
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => mockQuestionsBlockDataTina.list[0],
                        fields: [
                            {
                                name: 'name',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                            {
                                name: 'desc',
                                component: HtmlEditorFeatured,
                                label: 'Описание',
                            },
                            {
                                name: 'tags',
                                component: 'tagElement',
                                parentName: 'tagList',
                                label: 'Привязка по тэгам',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Название',
                    },
                ],
            },
        ],
    },
};
