import React from 'react';

import { TinaInfoBgBlock } from '@/components/blocks/InfoBgBlock/index.tina';
import { mockInfoBgBlockBlockDataTina } from '@/data/blocks/infoBgBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoBgBlockBlock = {
    Component: props => <TinaInfoBgBlock {...props} />,
    template: {
        label: 'Информационный блок с серым фоном',
        defaultItem: () => mockInfoBgBlockBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'titleBlock',
                component: HtmlEditorFeatured,
                label: 'Главный заголовок',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                component: 'select',
                name: 'color',
                label: 'Тип',
                options: [
                    {
                        value: 'default',
                        label: 'Стандартный',
                    },
                    {
                        value: 'white',
                        label: 'Белый',
                    },
                ],
            },
            {
                name: 'bg',
                component: 'text',
                label: 'Цвет фона',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'lineToPopup',
                component: HtmlEditorFeatured,
                label: 'Текст, который будет открывать модальное окно',
            },
            {
                label: 'Модальное окно',
                name: 'modal',
                component: 'group',
                fields: [
                    {
                        name: 'modalTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок в модальном окне',
                    },
                    {
                        name: 'modalDescription',
                        component: HtmlEditorFeatured,
                        label: 'Описание в модальном окне',
                    },
                ],
            },
            {
                name: 'smallDesc',
                component: HtmlEditorFeatured,
                label: 'Описание мелким шрифтом',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imageLink',
                component: 'text',
                label: 'Название продуктового параметра или ссылка на изображение',
            },

            {
                component: 'select',
                name: 'sideImg',
                label: 'Положение изображения',
                options: [
                    {
                        value: 'left',
                        label: 'Изображение слева',
                    },
                    {
                        value: 'right',
                        label: 'Изображение справа',
                    },
                ],
            },
            {
                name: 'imageTop',
                component: 'toggle',
                label: 'Разместить изображение сверху в моб. версии',
            },

            {
                name: 'linkName',
                component: 'text',
                label: 'Текст на кнопке',
            },
            {
                name: 'link',
                component: 'text',
                label: 'Ссылка',
            },
            {
                label: 'Элементы',
                name: 'store',
                component: 'group',
                fields: [
                    {
                        label: 'Список элементов',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { alt: string }, index: number) => ({
                            key: index,
                            label: item.alt,
                        }),
                        defaultItem: () => mockInfoBgBlockBlockDataTina.store.list[0],
                        fields: [
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Изображение',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'alt',
                                component: 'textarea',
                                label: 'Альтернативный текст',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                        ],
                    },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
