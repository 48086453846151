import React from 'react';

import { TinaAboutPB } from '@/components/blocks/aboutPB/aboutPB/index.tina';
import { mockAboutPBBlockTinaData } from '@/data/blocks/aboutPB';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const aboutPBBlock = {
    Component: props => <TinaAboutPB {...props} />,
    template: {
        label: 'О создании',
        defaultItem: () => mockAboutPBBlockTinaData,
        fields: [
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tabletName',
                component: HtmlEditorFeatured,
                label: 'Заголовок: Моб. версия',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'sidebarInfo',
                label: 'Информация',
                component: 'group',
                fields: [
                    {
                        name: 'title',
                        component: 'textarea',
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: 'textarea',
                        label: 'Описание',
                    },
                    {
                        name: 'phone',
                        component: 'text',
                        label: 'Телефон',
                    },
                    {
                        name: 'phoneLink',
                        component: 'text',
                        label: 'Телефонная ссылка',
                    },
                    {
                        name: 'mail',
                        component: 'text',
                        label: 'Эл. почта',
                    },
                    {
                        name: 'annotation',
                        component: 'textarea',
                        label: 'Дополнительное описание',
                    },
                ],
            },
            {
                name: 'quote',
                label: 'Цитата',
                component: 'group',
                fields: [
                    {
                        name: 'personName',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'personPosition',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { personName: string }, index: number) => ({
                    key: index,
                    label: item.personName,
                }),
                defaultItem: () => mockAboutPBBlockTinaData.list[0],
                fields: [
                    {
                        name: 'personName',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'personPosition',
                        component: 'text',
                        label: 'Должность',
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
