import React from 'react';

import { TinaSpecialAdvantages } from '@/components/blocks/advantages/SpecialAdvantages/index.tina';
import { mockSpecialAdvantagesBlockData, mockSpecialAdvantagesBlockDataTina } from '@/data/blocks/advantages';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const specialAdvantagesBlock = {
    Component: props => <TinaSpecialAdvantages {...props} />,
    template: {
        label: 'Особые преимущества',
        defaultItem: () => mockSpecialAdvantagesBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'description',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                    title: item.title,
                }),
                defaultItem: () => mockSpecialAdvantagesBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок (максимум 255 символов)',
                        required: true,
                        validate: (value: string) => {
                            if (!value) {
                                return 'Вы не заполнили наименование элемента';
                            }
                            return null;
                        },
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        label: 'Текст подсказки',
                    },
                ],
            },
        ],
    },
};
