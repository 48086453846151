import { IFaqQuestions } from '@/components/blocks/FaqQuestions';

export const mockFaqQuestionsBlockData: IFaqQuestions = {
    _template: 'faqQuestions',
    title: 'Часто задаваемые вопросы',
    description:
        'Не&nbsp;следует, однако, забывать, что внедрение современных методик в&nbsp;значительной степени обусловливает важность экономической целесообразности принимаемых решений. \n' +
        'Сложно сказать, почему непосредственные участники технического прогресса и&nbsp;по&nbsp;сей день остаются уделом либерало.',
    list: [
        {
            title: 'Погашение кредита',
            link: '#',
            image: '/img-next/svg/faq-questions/1.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: '&laquo;Деньги и&nbsp;всё!&raquo;',
            link: '#',
            image: '/img-next/svg/faq-questions/2.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Потребительские кредиты',
            link: '#',
            image: '/img-next/svg/faq-questions/3.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Покупки в&nbsp;кредит',
            link: '#',
            image: '/img-next/svg/faq-questions/4.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Общие',
            link: '#',
            image: '/img-next/svg/faq-questions/5.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Услуги',
            link: '#',
            image: '/img-next/svg/faq-questions/6.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: '&laquo;Моя кредитная история&raquo;',
            link: '#',
            image: '/img-next/svg/faq-questions/7.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Вклады',
            link: '#',
            image: '/img-next/png/faq-questions/8.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Кредит на&nbsp;образование',
            link: '#',
            image: '/img-next/svg/faq-questions/9.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Платежи и&nbsp;переводы',
            link: '#',
            image: '/img-next/svg/faq-questions/10.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Кредитные карты',
            link: '#',
            image: '/img-next/svg/faq-questions/11.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Дебетовые карты',
            link: '#',
            image: '/img-next/svg/faq-questions/12.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Почта Банк Онлайн',
            link: '#',
            image: '/img-next/svg/faq-questions/13.svg',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};

export const mockFaqQuestionsBlockTinaData: IFaqQuestions = {
    _template: 'faqQuestions',
    title: 'Заголовок',
    description: 'Описание',
    list: [
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};
