import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

interface IAdvantage {
    title: string;
    subtitle: string;
}

export interface IInfoBlockGradient {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'infoBlockGradient';
    /**
     * Список категорий
     */
    titleBlock?: string;
    image: string;
    title: string;
    accentText: string;
    botLink?: string;
    botLinkHref?: string;
    desc?: string;
    subtitle?: string;
    advantages?: IAdvantage[];
    link: string;
    linkName: string;
    gtag?: GTagEvent;
    size?: string;
    /**
     * Позиции изображений
     */
    sideImg: 'left' | 'right';
    positionImg: 'top' | 'center' | 'bottom';
    positionMobileImg?: 'left' | 'center' | 'right';
    color?: 'default' | 'white';
    bg?: 'default' | string;
}

const InfoBlockGradient: React.FC<IInfoBlockGradient> = ({
    image,
    title,
    desc,
    advantages,
    subtitle,
    link,
    linkName,
    gtag,
    sideImg = 'left',
    positionImg = 'center',
    positionMobileImg = 'left',
    size,
    titleBlock,
    accentText,
    botLink,
    botLinkHref,
    color = 'default',
    bg = '#F4F4FF',
}) => (
    <AnimatedComponent className={clsx(cn.infoBlockGradient, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.infoBlockGradientWrap}>
            {titleBlock && (
                <TextField text={titleBlock} className={cn.mainTitle} name="titleBlock" isHTML htmlValue={titleBlock} />
            )}

            <div
                className={clsx(
                    cn.infoBlockGradientGrid,
                    sideImg === 'right' && cn.right,
                    positionImg === 'bottom' && cn.bottom,
                    positionImg === 'top' && cn.top,
                    size === 'small' && cn.small,
                    size === 'smallHeight' && cn.smallHeight,
                    color === 'white' && cn.colorWhite
                )}
                style={{
                    background: bg,
                }}
            >
                <div className={clsx(cn.infoBlockGradientItem, cn.infoBlockGradientItemImg)}>
                    <div className={clsx(cn.infoBlockGradientImage)}>
                        <div
                            className={clsx(
                                cn.infoBlockGradientImageContainer,
                                positionMobileImg === 'center' && cn.positionMobileImg
                            )}
                        >
                            {image && size === 'small' && (
                                <CustomImage src={image} alt={title} width={257} height={280} />
                            )}
                            {image && size !== 'small' && <CustomImage src={image} alt={title} fill />}
                        </div>
                    </div>
                </div>
                <div className={clsx(cn.infoBlockGradientItem, cn.infoBlockGradientItemContent)}>
                    <div className={clsx(cn.infoBlockGradientContent, link && cn.infoBlockGradientContentSpaceBetween)}>
                        <div className={cn.infoBlockGradientTop}>
                            {title && (
                                <TextField
                                    text={title}
                                    className={cn.infoBlockGradientTitle}
                                    name="title"
                                    htmlValue={title}
                                    isHTML
                                />
                            )}
                            {accentText && (
                                <TextField
                                    text={accentText}
                                    className={cn.infoBlockGradientAccent}
                                    name="accentText"
                                    htmlValue={accentText}
                                    isHTML
                                />
                            )}
                            {desc && (
                                <TextField
                                    text={desc}
                                    isHTML
                                    htmlValue={desc}
                                    name="desc"
                                    className={cn.infoBlockGradientDesc}
                                />
                            )}
                            {!advantages?.length ? (
                                <TextField
                                    className={cn.stretchSubtitle}
                                    text={subtitle}
                                    isHTML
                                    htmlValue={subtitle}
                                    name="subtitle"
                                />
                            ) : (
                                <div className={cn.advantages}>
                                    {advantages?.map((advantage, index) => (
                                        <div className={cn.advantagesItem} key={index}>
                                            <div className={cn.advantagesItemTitle}>{advantage?.title}</div>
                                            <div
                                                className={cn.advantagesItemSubtitle}
                                                dangerouslySetInnerHTML={{
                                                    __html: advantage?.subtitle,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {botLink && (
                                <TextField
                                    text={botLink}
                                    className={cn.infoBlockGradientLink}
                                    customTag="a"
                                    href={botLinkHref}
                                    name="botLink"
                                    htmlValue={botLink}
                                    isHTML
                                />
                            )}
                        </div>
                        {link && linkName && (
                            <div className={clsx(cn.infoBlockGradientBot, cn.hideOnMobile)}>
                                <Button
                                    variant="btnBlue"
                                    type="link"
                                    href={link}
                                    customClass={cn.infoBlockGradientBtn}
                                    label={linkName}
                                    size="full"
                                    onClick={() => {
                                        if (gtag?.action) event(gtag);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {link && (
                    <div className={clsx(cn.infoBlockGradientItem, cn.infoBlockGradientItemBtn, cn.showOnMobile)}>
                        <Button
                            variant="btnBlue"
                            type="link"
                            href={link}
                            label={linkName}
                            customClass={cn.infoBlockGradientBtn}
                            size="full"
                            onClick={() => {
                                if (gtag?.action) event(gtag);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    </AnimatedComponent>
);

export default withBlockLinks(InfoBlockGradient);
