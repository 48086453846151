import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

interface IQueryParam {
    value: string;
    term: string;
}

export interface ICreditRealEstateCalculatorStore {
    sumElements: IRangeInput;
    termsElements: IRangeInput;
    creditValue: number;
    termValue: number;
    rate: number;
    balancePercent: number;
    initialized: boolean;
    getMonthlyPayment: () => number;
}

class CreditRealEstateCalculatorStore implements ICreditRealEstateCalculatorStore {
    public sumElements: IRangeInput;

    public termsElements: IRangeInput;

    public rate = 0;

    public balancePercent = 0;

    public initialized = false;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.sumElements.value;
    }

    get termValue(): number {
        return this.termsElements.value;
    }

    getMonthlyPayment = () => {
        const MONTHS_IN_YEAR = 12;

        if (+this.creditValue < 100000) {
            const percentRate = 3 / 100;

            // Размер платежа

            return Math.ceil((+this.creditValue * percentRate) / 100) * 100;
        }

        const percentRate = this.rate / 100;

        const year = this.termValue;
        const formattedYear = year * MONTHS_IN_YEAR; // Год в месяцах

        const percentPart = (1 + percentRate / MONTHS_IN_YEAR) ** (0 - formattedYear); // 1 + ставка/12 в степени срока
        const creditSumPercent = (+this.creditValue * percentRate) / MONTHS_IN_YEAR; // Cумма кредита * ставку / 12

        const total = Math.round(creditSumPercent / (1 - percentPart));
        // Размер платежа

        const balance = +this.creditValue * (this.balancePercent / 100);

        const balanceSumPercent = (balance * percentRate) / MONTHS_IN_YEAR; // Остаток кредита * ставку / 12

        const balancePercentPart = (1 + percentRate / MONTHS_IN_YEAR) ** 12; // 1 + ставка/12 в степени 12

        const lastYearTotal = Math.round((balanceSumPercent * balancePercentPart) / (balancePercentPart - 1)); // Размер платежа в последний год

        return total;
    };

    public init = (
        sumElements: ICalculatorFilterElement[],
        termsElements: ICalculatorFilterElement[],
        rate: number,
        balancePercent: number,
        currentQueryValue?: number,
        currentQueryTerm?: number
    ) => {
        this.sumElements = new RangeInput({
            name: 'creditValue',
            title: 'Сумма кредита',
            label: 'Укажите сумму',
            elements: sumElements,
            initValue: currentQueryValue || 1500000,
        });

        this.termsElements = new RangeInput({
            name: 'termValue',
            title: 'Срок кредита',
            label: 'Укажите срок',
            elements: termsElements,
            initValue: currentQueryTerm || 5,
            customFormatValueHandle: (value, _) => `${value} лет`,
        });

        this.rate = rate;

        this.balancePercent = balancePercent;

        this.initialized = true;
    };
}

export default new CreditRealEstateCalculatorStore();
