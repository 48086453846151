import { IPromoPension } from '@/components/blocks/PromoPension';

export const mockPromoPensionBlockData: IPromoPension = {
    _template: 'promoPension',
    banner: {
        background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background1.png',
        activeGradient: false,
        activeImage: true,
        color: 'white',
        line: {
            title: 'Пенсионная карта',
            titleAnchor: '',
            subtitle: 'Получайте пенсию и преимущества',
            subtitleAnchor: '',
            headersList: [
                { title: 'Вклад для пенсионеров с дополнительным % годовых', utm: 'v-brand-dliapens' },
                { title: 'Пенсионный вклад с дополнительным % годовых', utm: 'v-brand-pensionni' },
                { title: 'Дополнительный процент по вкладу для пенсионеров', utm: 'v-brand-procenti' },
            ],
            subheadersList: [
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-dliapens' },
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-pensionni' },
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-procenti' },
            ],
        },
        buttons: [
            {
                text: 'Заявка на перевод пенсии',
                link: '#id__credit-issue',
                type: 'button',
                color: 'blue',
                isSetMobileAnimate: true,
            },
        ],
        image: '/img-next/png/promov2.png',
        imageLink: '',
        imageBottomMv: true,
        imageBig: true,
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'Кешбэк 3%',
            subtitle: 'в аптеках, за ж/д билеты, на автозаправках',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'Вклад',
            subtitle: 'с повышенной ставкой',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'Кредит',
            subtitle: 'по сниженной ставке',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'Бесплатное снятие',
            subtitle: 'в банкоматах всех банков',
        },
    ],
};
