import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import CommonLink from '@/components/UI/CommonLink';
import Feature from '@/domain/feature/Feature';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IColoredBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'coloredBanner';
    title?: string;
    text?: string;
    image?: string;
    link?: { link: string; type: string };
}

const ColoredBanner: React.FC<IColoredBanner> = ({ title, text, image, link }) => {
    const width = useResize();
    const isTabletLg = width <= TABLET_WIDTH && typeof window !== 'undefined';
    const isTablet = width < TABLET_SMALL_WIDTH && typeof window !== 'undefined';
    const isMobile = width <= MOBILE_WIDTH && typeof window !== 'undefined';
    const [correctWidthImage, setCorrectWidthImage] = useState<number>(230);
    const [correctHeightImage, setCorrectHeightImage] = useState<number>(210);

    useEffect(() => {
        if (isMobile) {
            setCorrectHeightImage(150);
        } else if (isTabletLg) {
            setCorrectHeightImage(180);
        } else {
            setCorrectHeightImage(210);
        }
    }, [isTabletLg, isMobile]);

    useEffect(() => {
        if (isMobile) {
            setCorrectWidthImage(90);
        } else {
            setCorrectWidthImage(230);
        }
    }, [isTabletLg, isMobile]);

    const scrollOffset = isTablet ? 60 : 90;

    if (!Feature.isFeatureEnabled('enableColoredBanner')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    <CommonLink
                        href={link}
                        offset={-scrollOffset}
                        activeClass="active"
                        className={clsx(cn.innerWrap, link.link.length > 0 && cn.innerWrapLinkActive)}
                    >
                        <div className={cn.textWrapper}>
                            {title && (
                                <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                            )}
                            {text && <TextField className={cn.text} text={text} name="text" isHTML htmlValue={text} />}
                        </div>
                        {image && (
                            <div className={clsx(cn.imageWrapper)}>
                                <div className={clsx(cn.imageWrap)}>
                                    <CustomImage
                                        src={image}
                                        alt="Почта Банк"
                                        width={correctWidthImage}
                                        height={correctHeightImage}
                                    />
                                </div>
                            </div>
                        )}
                    </CommonLink>
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default withBlockLinks(ColoredBanner);
