import { chunk } from 'lodash';
import React, { FC } from 'react';

import { IShowcaseCard, ShowcaseCard } from '../ShowcaseCard';
import cn from './style.module.sass';

export interface IOneSpaceList {
    list: IShowcaseCard[];
}

const OneSpaceList: FC<IOneSpaceList> = ({ list }) => (
    <div className={cn.wrapper}>
        <div className={cn.oneSpace}>
            {chunk(list?.slice(0, 9), 3).map((showcaseCardRow, rawIdx) => {
                const filler = Array(3 - showcaseCardRow.length).fill(null) as null[];
                const showcaseCardRowProcessed = [...showcaseCardRow, ...filler];

                return (
                    <div key={rawIdx} className={cn.raw}>
                        {showcaseCardRowProcessed.map((showcaseCard, idx) => (
                            <ShowcaseCard
                                key={idx}
                                image={showcaseCard?.image || ''}
                                listLink={showcaseCard?.listLink || ''}
                                listName={showcaseCard?.listName || ''}
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    </div>
);

export default OneSpaceList;
