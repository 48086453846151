export const mockAnimatedPromoBlockData = {
    name: 'Слайдер',
    list: [
        {
            image: '/img-next/png/animatedPromo/dots3.png',
            title: 'Карта «ВездеДоход» 1',
            titleMobile: 'Карта',
            subtitle: '120 дней без процентов',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '/img-next/png/animatedPromo/background1.png',
                withDecor: false,
                withAnimationImage: true,
                animationImageWebm: 'https://simpl.info/videoalpha/video/soccer1.webm',
                withAnimate: true,
                animationType: 'red',
                imageBottomMv: false,
                imageBig: true,
                line: {
                    title: 'Карта «ВездеДоход»',
                    subtitle: '120 дней без процентов',
                },
                description: '2000 бонусов в подарок',
                advantages: [],
                buttons: [
                    {
                        text: 'Оформить заявку',
                        link: 'https://www.pochtabank.ru/service/cards/vddohod_2801?_ga=2.130331279.1090276843.1626076706-137358549.1625727021#credit-issue-form-container',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Подробнее',
                        link: 'https://www.pochtabank.ru/service/cards/vddohod_2801?_ga=2.164084159.1090276843.1626076706-137358549.1625727021',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '/img-next/png/animatedPromo/3.png',
            },
        },
        {
            image: '/img-next/png/animatedPromo/dots1.png',
            title: 'Переведите пенсию',
            titleMobile: 'Пенсия',
            subtitle: 'в Почта Банк',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '/img-next/png/animatedPromo/background1.png',
                withDecor: false,
                withAnimate: true,
                animationType: 'red',
                line: {
                    title: 'Переведите пенсию',
                    subtitle: 'в Почта Банк',
                },
                description: 'Прибавка к пенсии до 5% годовых',
                advantages: [],
                buttons: [
                    {
                        text: 'Заполнить заявку',
                        link: 'https://www.pochtabank.ru/service/pensioneram?_ga=2.130331279.1090276843.1626076706-137358549.1625727021',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Подробнее',
                        link: 'https://www.pochtabank.ru/service/pensioneram?_ga=2.130331279.1090276843.1626076706-137358549.1625727021',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '/img-next/png/animatedPromo/1.png',
                imageTopMv: true,
                dotGtag: {
                    action: 'click',
                    category: 'credit_cash_button_zapolnit',
                    label: 'main_slider_credit_cash_button_oformit',
                },
            },
        },
        {
            image: '/img-next/png/animatedPromo/dots2.png',
            title: 'Кредит наличными',
            titleMobile: 'Кредит',
            subtitle: 'по сниженной ставке',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '/img-next/png/animatedPromo/background1.png',
                withDecor: false,
                withAnimate: true,
                animationType: 'red',
                line: {
                    title: 'Кредит наличными',
                    subtitle: 'по сниженной ставке',
                },
                description: '0% годовых на 6 месяцев',
                advantages: [],
                buttons: [
                    {
                        text: 'Заполнить заявку',
                        link: '/service/cashcredit#credit-issue-form-container',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '/img-next/png/animatedPromo/2.png',
            },
        },
        {
            image: '/img-next/png/animatedPromo/dots4.png',
            title: 'Кредит на строительство',
            titleMobile: 'Кредит',
            subtitle: 'Оформите заявку на кредит ',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '/img-next/png/animatedPromo/background2.png',
                withDecor: false,
                withAnimate: true,
                animationType: 'blue',
                line: {
                    title: 'Кредит на строительство',
                    subtitle: 'Оформите заявку на кредит ',
                },
                description: 'Первоначальный взнос от 10%',
                withAnimationImage: true,
                animationImage: '/img-next/png/animatedPromo/smoke.gif',
                buttons: [
                    {
                        text: 'Заполнить заявку',
                        link: '#',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Подробнее',
                        link: '#',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],

                image: '/img-next/png/animatedPromo/4.png',
            },
        },
    ],
};

export const mockAnimatedPromoBlockTinaData = {
    section: null,
    name: 'Слайдер',
    list: [
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            titleMobile: 'Заголовок',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '',
                withDecor: true,
                withAnimate: true,
                animationType: 'red',
                line: {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                advantages: [
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                ],
                buttons: [
                    {
                        text: 'Название',
                        link: '#',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Название',
                        link: '#',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '',
                imageTopMv: false,
                dotGtag: {
                    action: 'click',
                    category: 'credit_cash_button_zapolnit',
                    label: 'main_slider_credit_cash_button_oformit',
                },
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            titleMobile: 'Заголовок',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '',
                withDecor: true,
                withAnimate: true,
                animationType: 'red',
                line: {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                advantages: [
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                ],
                buttons: [
                    {
                        text: 'Название',
                        link: '#',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Название',
                        link: '#',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '',
                imageTopMv: false,
                dotGtag: {
                    action: 'click',
                    category: 'credit_cash_button_zapolnit',
                    label: 'main_slider_credit_cash_button_oformit',
                },
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            titleMobile: 'Заголовок',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '',
                withDecor: true,
                withAnimate: true,
                animationType: 'red',
                line: {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                advantages: [
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                ],
                buttons: [
                    {
                        text: 'Название',
                        link: '#',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Название',
                        link: '#',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '',
                imageTopMv: false,
                dotGtag: {
                    action: 'click',
                    category: 'credit_cash_button_zapolnit',
                    label: 'main_slider_credit_cash_button_oformit',
                },
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            titleMobile: 'Заголовок',
            banner: {
                background: 'linear-gradient(67.26deg, #FEEDEA 21.04%, #D0D2FF 90.51%)',
                backgroundImage: '',
                withDecor: true,
                withAnimate: false,
                animationType: '',
                line: {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                advantages: [
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                    { title: 'Заголовок', subtitle: 'Описание' },
                ],
                buttons: [
                    {
                        text: 'Название',
                        link: '#',
                        type: 'button',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                    {
                        text: 'Название',
                        link: '#',
                        type: 'link',
                        gtag: {
                            action: 'click',
                            category: 'credit_cash_button_zapolnit',
                            label: 'main_slider_credit_cash_button_oformit',
                        },
                    },
                ],
                image: '',
                imageTopMv: false,
                dotGtag: {
                    action: 'click',
                    category: 'credit_cash_button_zapolnit',
                    label: 'main_slider_credit_cash_button_oformit',
                },
            },
        },
    ],
};
