import clsx from 'clsx';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { ButtonBlue } from '@/components/UI/button/ButtonBlue';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

interface IBtn {
    label: string;
    link: string;
    gtag?: GTagEvent;
}

export interface IRightButtonText {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'rightButtonText';
    titleBlock?: string;
    title: string;
    desc?: string;
    btn?: IBtn;
}

function RightButtonText({ title, desc, btn, titleBlock }: IRightButtonText) {
    const { label, link, gtag } = btn;
    return (
        <AnimatedComponent className={clsx(cn.rightButtonBlock, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.rightButtonBlockWrap}>
                {titleBlock && (
                    <TextField
                        text={titleBlock}
                        className={cn.mainTitle}
                        name="titleBlock"
                        isHTML
                        htmlValue={titleBlock}
                    />
                )}

                <div className={cn.rightButtonBlockContentWrap}>
                    <div className={cn.rightButtonBlockTextContent}>
                        <TextField
                            text={title}
                            className={cn.rightButtonBlockTitle}
                            name="title"
                            isHTML
                            htmlValue={title}
                        />
                        {desc && (
                            <TextField
                                text={desc}
                                className={cn.rightButtonBlockDesc}
                                name="desc"
                                isHTML
                                htmlValue={desc}
                            />
                        )}
                    </div>
                    {link && (
                        <div className={cn.rightButtonBlockLinkContent}>
                            <ButtonBlue
                                type="link"
                                href={link}
                                label={label}
                                size="large"
                                customClass={cn.rightButtonBlockLinkBtn}
                                onClick={() => {
                                    if (gtag?.action) event(gtag);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}
export default withBlockLinks(RightButtonText);
