import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { options } from '@/components/blocks/PushHRLanding/data/vacancyFormData';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import { FileUpload } from '@/components/UI/FileUpload';
import Input from '@/components/UI/Input';
import Title from '@/components/UI/Title';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { GTagEvent, universalEvent } from '@/components/shared/utilities/analytics/metrics';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IHRLandingForm {
    _template?: 'pushHrLandingForm';
    title?: string;
    defaultValue?: string;
    hrGtag?: {
        [key: string]: GTagEvent;
    };
}

function PushHRLandingForm({ title, defaultValue, _template = 'pushHrLandingForm', hrGtag }: IHRLandingForm) {
    const [isFail, setIsFail] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [currentVacancy, setCurrentVacancy] = useState(
        () => options.find(item => item.value === defaultValue) || options[0]
    );

    const fileTypes = ['.doc', '.pdf'];

    const formResolver = useValidationSchema([
        { name: 'accept', type: 'checkbox', required: true },
        { name: 'fio', type: 'fio', required: true },
        { name: 'phone', type: 'phone', required: true },
        { name: 'email', type: 'email', required: true },
        { name: 'file', type: 'arrayOfStringsOptional', required: false },
    ]);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        setValue,
    } = useForm<InnerFormValues>({ resolver: formResolver });

    const methods = useForm<InnerFormValues>({
        resolver: formResolver,
    });

    // Регистрация полей для файла, обязательно, так как ref занят useDropzone
    useEffect(() => {
        register('file');
    }, [register]);

    const searchParams = useSearchParams();

    const onSubmit = async finalData => {
        const utmSource = searchParams.get('utm_source') || undefined;
        const utmMedium = searchParams.get('utm_medium') || undefined;
        const utmCampaing = searchParams.get('utm_campaing') || undefined;
        const utmTerm = searchParams.get('utm_term') || undefined;
        const pagePath = typeof window !== undefined ? window.location.href : undefined;

        const filteredFinalData = removePropertyFromObject({ vacancy: currentVacancy.value, ...finalData }, 'accept');
        const formData = getFormDataFromHookForm({
            ...filteredFinalData,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaing: utmCampaing,
            utm_term: utmTerm,
            pagePath,
        });

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${_template}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setIsFail(true);
                throw new Error(response?.data?.errors[0].message);
            } else {
                setIsSuccess(true);

                universalEvent(hrGtag.gtagForm);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const [isErrorAccept, errorAcceptText] = getFormErrors(errors, 'accept');
    const [isErrorFio, errorFioText] = getFormErrors(errors, 'fio');
    const [isErrorPhone, errorPhoneText] = getFormErrors(errors, 'phone');
    const [isErrorEmail, errorEmailText] = getFormErrors(errors, 'email');

    return (
        <FormProvider {...methods}>
            <div>
                {!isFail && !isSuccess && (
                    <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Title title={title} level={2} className={cn.title} />
                        <div className={cn.input}>
                            <label htmlFor="vacancy">Вакансия *</label>
                            <div className={cn.right}>
                                <CustomSelect
                                    name="vacancy"
                                    value={options.find(item => item.value === currentVacancy.value)}
                                    onChange={(e: ISelectElement<string>) => {
                                        setCurrentVacancy(options.find(item => item.value === e.value));
                                    }}
                                    customClass={cn.selectVacancy}
                                    options={options}
                                    placeholder="Выберите"
                                />
                            </div>
                        </div>
                        <div className={cn.input}>
                            <label htmlFor="text">ФИО *</label>
                            <div className={cn.right}>
                                <Input
                                    type="text"
                                    customClass={cn.isFull}
                                    isError={isErrorFio}
                                    placeholder="Введите ФИО"
                                    errorText={errorFioText}
                                    {...register('fio')}
                                />
                            </div>
                        </div>
                        <div className={clsx(cn.input, cn.inputMask)}>
                            <label htmlFor="text">Телефон *</label>
                            <div className={cn.right}>
                                <Input
                                    type="tel"
                                    placeholder="Введите номер"
                                    mask="+7 (999) 999-99-99"
                                    customClass={cn.isFull}
                                    isError={isErrorPhone}
                                    errorText={errorPhoneText}
                                    {...register('phone')}
                                />
                            </div>
                        </div>
                        <div className={cn.input}>
                            <label htmlFor="text">E-mail *</label>
                            <div className={cn.right}>
                                <Input
                                    isError={isErrorEmail}
                                    errorText={errorEmailText}
                                    customClass={cn.isFull}
                                    type="text"
                                    placeholder="Введите e-mail"
                                    {...register('email')}
                                />
                            </div>
                        </div>

                        <div className={cn.input}>
                            <FileUpload
                                name="file"
                                onFileChange={(files: File[]) => setValue('file', files)}
                                label="Добавить резюме"
                                fileTypes={fileTypes}
                            />
                        </div>

                        <div className={cn.input}>
                            <Checkbox
                                customClass={cn.accept}
                                size="small"
                                isError={isErrorAccept}
                                errorText={errorAcceptText}
                                label='Даю согласие на <a href="https://www.pochtabank.ru/personal-data?_ga=2.169039207.773343870.1681377168-720124909.1675855140">обработку персональных данных</a>'
                                {...register('accept')}
                            />
                        </div>

                        <Button
                            variant="btnBlue"
                            buttonType="submit"
                            type="button"
                            label="Отправить заявку"
                            disable={isSubmitting}
                            customClass={cn.button}
                            animation={false}
                        />
                    </form>
                )}

                {isFail && (
                    <Title
                        title="К сожалению, произошла ошибка при отправке формы. Попробуйте позднее."
                        level={2}
                        className={cn.title}
                    />
                )}

                {isSuccess && <Title title="Форма успешно отправлена!" level={2} className={cn.title} />}
            </div>
        </FormProvider>
    );
}

export default PushHRLandingForm;
