import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import LikeIcon from '@/components/UI/LikeButton/likeIcon';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IResponse {
    data: {
        data: number;
        errors: string[];
        status: string;
    };
}

const LikeButton: React.FC = () => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [countLikes, setCountLikes] = useState<number>(0);

    const pathname = usePathname();

    const handleClick = async () => {
        try {
            const response: IResponse = await apiRequest(`articles/newlikes`);

            if (response.data.errors?.length > 0) throw new Error(JSON.stringify(response.data.errors[0]));

            setCountLikes(response.data.data);
            setIsActive(true);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const likesList = JSON.parse(window?.localStorage?.getItem('likes')) || [];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            likesList.push(pathname);
            window?.localStorage?.setItem('likes', JSON.stringify(likesList));
        } catch (e) {
            console.error(e);
        }
    };

    const fetchData = async () => {
        try {
            const response: IResponse = await apiRequest(`articles/likes`);

            if (response.data.errors?.length > 0) throw new Error(JSON.stringify(response.data.errors[0]));

            setCountLikes(response.data.data || 0);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData().catch(console.error);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const likes = JSON.parse(window?.localStorage?.getItem('likes')) || [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (likes.includes(pathname)) setIsActive(true);
    }, []);

    return (
        <div className={clsx(cn.button, isActive && cn.active)} onClick={handleClick} role="presentation">
            <LikeIcon />
            <div className={cn.text}>{countLikes}</div>
        </div>
    );
};

export default LikeButton;
