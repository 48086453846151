import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import cn from '@/components/blocks/calculators/layouts/StudyCalculator/Content/style.module.sass';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IStudyCalculatorStore } from '@/domain/calculator/StudyCalculatorStore';

const TopResults = observer(() => {
    const { creditTerm, paymentSum } = useContext<IStudyCalculatorStore>(CalculatorStoreContext);
    return (
        <>
            <div className={cn.wrapResult}>
                <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                    <div className={cn.wrapResultRow}>
                        <span className={cn.resultText}>Сумма кредита</span>
                        <span className={cn.resultNumber}>{paymentSum}</span>
                    </div>
                    <div className={cn.progress}>
                        <span className={cn.progressSilver} />
                        <span className={cn.progressRed} />
                    </div>
                </div>
            </div>
            <div className={cn.wrapResult}>
                <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                    <div className={cn.wrapResultRow}>
                        <span className={cn.resultText}>Срок кредита</span>
                        <span className={cn.resultNumber}>{creditTerm}</span>
                    </div>
                    <div className={cn.progress}>
                        <span className={cn.progressSilver} />
                        <span className={cn.progressRed} />
                    </div>
                </div>
            </div>
            <div className={cn.wrapResult}>
                <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                    <div className={cn.wrapResultRow}>
                        <span className={cn.resultText}>Процентная ставка</span>
                        <span className={cn.resultNumber}>9.90%</span>
                    </div>
                    <div className={cn.progress}>
                        <span className={cn.progressSilver} />
                        <span className={cn.progressRed} />
                    </div>
                </div>
            </div>
        </>
    );
});

export default TopResults;
