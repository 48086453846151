import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import { IVacancyItem } from '@/types/vacancy/item';

import Modal from './Modal';
import cn from './style.module.sass';

const VacancyItem: React.FC<IVacancyItem> = ({ title, pay, city, content, pos, citiesList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const cityString = useMemo(
        () =>
            citiesList?.reduce((acc, item) => {
                if (city?.includes(item.xmlId)) {
                    return acc.length ? `${acc}, ${item?.value}` : item?.value;
                }
                return acc;
            }, ''),
        [city, citiesList]
    );

    return (
        <div className={cn.row}>
            <div className={cn.wrap}>
                <div className={clsx(cn.item, cn.itemName)}>
                    <TextField
                        text={title}
                        name={`list.${pos}.title`}
                        isTextArea
                        className={cn.name}
                        onClick={handleOpen}
                        role="presentation"
                    />
                    <ModalWrapper
                        isOpen={isOpen}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        className={cn.otherView}
                        bgClassName={cn.otherViewBg}
                    >
                        {isOpen && <Modal content={content} vacancyName={title} cityName={cityString} />}
                    </ModalWrapper>
                </div>
                <div className={clsx(cn.item, cn.itemPay)}>
                    <TextField text={pay} name={`list.${pos}.pay`} isTextArea className={cn.pay} />
                </div>
                <div className={clsx(cn.item, cn.itemCity)}>
                    <div className={cn.city}>{cityString}</div>
                </div>
            </div>
        </div>
    );
};

export default VacancyItem;
