import React from 'react';

import CustomImage from '@/components/CustomImage';
import { protocol } from '@/utils/constants';

import cn from './style.module.sass';

function PreloaderSnowFlake() {
    return (
        <CustomImage
            src={`${protocol}${process.env.FRONT_ROOT_URL}/img-next/gif/snowflake.png`}
            alt="Почта Банк"
            title="Почта Банк"
            width={64}
            height={64}
            className={cn.image}
        />
    );
}

export default PreloaderSnowFlake;
