import React from 'react';

import { TinaPartners } from '@/components/blocks/partners/Partners/index.tina';
import { mockPartnersBlockDataTina } from '@/data/blocks/partners';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const partnersBlock = {
    Component: props => <TinaPartners {...props} />,
    template: {
        label: 'Партнеры',
        defaultItem: () => mockPartnersBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                component: 'select',
                name: 'size',
                label: 'Размер элементов',
                options: [
                    {
                        value: 'big',
                        label: 'Большие',
                    },
                    {
                        value: 'small',
                        label: 'Маленькие',
                    },
                ],
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип (количество колонок)',
                options: [
                    {
                        value: 'col-3',
                        label: '3 колонки',
                    },
                    {
                        value: 'col-4',
                        label: '4 колонки',
                    },
                    {
                        value: 'col-5',
                        label: '5 колонок',
                    },
                ],
            },
            {
                name: 'limit',
                component: 'number',
                label: 'Лимит',
            },
            {
                name: 'showAll',
                component: 'toggle',
                label: 'Показать все',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockPartnersBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Логотип.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageDarkTheme',
                        component: 'image',
                        label: 'Логотип. Темная тема',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
