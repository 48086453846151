import React from 'react';

interface IProps {
    customClass?: string;
}

const IconFire: React.FC<IProps> = ({ customClass }) => (
    <svg className={customClass} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 14.9371C20 19.1642 17.3819 21.0192 15.3588 21.6447C14.9274 21.7781 14.6438 21.2784 14.9019 20.9099C15.7823 19.6528 16.8 17.7349 16.8 15.9307C16.8 13.9926 15.1559 11.7045 13.8721 10.2933C13.5786 9.97065 13.0667 10.1841 13.0507 10.6187C12.9976 12.0586 12.7689 13.9851 11.7828 15.4949C11.6241 15.7379 11.2872 15.758 11.1063 15.5308C10.7982 15.1436 10.4901 14.6653 10.182 14.2875C10.016 14.084 9.71604 14.0812 9.52461 14.2613C8.77825 14.9635 7.73333 16.0584 7.73333 17.421C7.73333 18.3449 8.0936 19.3135 8.50007 20.0929C8.72368 20.5216 8.32607 21.0376 7.89573 20.8133C6.11307 19.8838 4 18.0009 4 14.9371C4 11.8109 8.31029 7.48022 9.95605 3.3888C10.2157 2.74319 11.0161 2.43982 11.5727 2.86113C14.9439 5.41267 20 10.345 20 14.9371Z" />
    </svg>
);

export default IconFire;
