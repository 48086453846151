import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import DistributionAskQuestionItem, {
    IDistributionAskQuestionItem,
} from '@/components/blocks/DistributionAskQuestion/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IDistributionAskQuestion {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'distributionAskQuestion';
    name?: string;
    title?: string;
    description?: string;
    list: Array<IDistributionAskQuestionItem>;
}

function DistributionAskQuestion({ title, description, list }: IDistributionAskQuestion) {
    return (
        <AnimatedComponent>
            <div className={clsx(cn.wrapper, 'section')}>
                <div className={cn.wrap}>
                    <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                    <TextField
                        className={cn.description}
                        text={description}
                        name="description"
                        isHTML
                        htmlValue={description}
                    />
                    {list?.length > 0 && (
                        <div className={cn.grid}>
                            {list?.map((item, index) => (
                                <div className={item.size === 'big' ? cn.bigItem : cn.smallItem} key={`key-${index}`}>
                                    <DistributionAskQuestionItem {...item} index={index} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(DistributionAskQuestion);
