import clsx from 'clsx';
import { Scrollbars } from 'rc-scrollbars';
import React from 'react';

import TextField from '@/components/TextField';
import IconFixButton from '@/components/UI/IconFixButton';
import { ITariffCompareItem } from '@/types/tariffCompare/item';

import cn from './style.module.sass';

interface IProps {
    item: ITariffCompareItem;
    pos?: number;
    handler?: (id: number) => void;
}

const TariffCompareItem: React.FC<IProps> = ({ item, pos, handler }) => {
    if (item?.characteristics?.length <= 0) return null;

    const fixHandler = () => {
        if (handler) handler(item?.id);
    };

    const trim = (val: string, count: number) => {
        if (val?.length > count) {
            return `${val.slice(0, count)}...`;
        }
        return val;
    };

    return (
        <div className={cn.currentItem}>
            <div className={clsx(cn.itemTitleWrap, cn.head)}>
                {/* 2 варианта отображения для просмотра и редактирования элемента */}
                {pos ? (
                    <TextField
                        text={item?.title}
                        className={cn.itemTitle}
                        name={`list.[${pos}].title`}
                        isHTML
                        htmlValue={item?.title}
                    />
                ) : (
                    <div className={cn.itemTitle} dangerouslySetInnerHTML={{ __html: trim(item?.title, 55) }} />
                )}

                <div className={cn.fixButton} role="presentation" onClick={fixHandler}>
                    {' '}
                    <IconFixButton />
                </div>
            </div>
            {item?.characteristics?.map((elem, index) => (
                <div className={cn.row} key={index}>
                    {pos ? (
                        <>
                            <TextField
                                text={elem.title}
                                className={cn.rowTitle}
                                name={`elem.characteristics[${index}].title`}
                                isHTML
                                htmlValue={elem.title}
                            />
                            <div className={cn.rowValue}>
                                <Scrollbars autoHeight autoHeightMax={80} universal hideTracksWhenNotNeeded>
                                    <TextField
                                        text={elem.value}
                                        className={cn.rowValueInner}
                                        name={`list.[${pos}].characteristics[${index}].value`}
                                        isHTML
                                        htmlValue={elem.value}
                                    />
                                </Scrollbars>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={cn.rowTitle}>{elem.title}</div>
                            <div className={cn.rowValue}>
                                <Scrollbars autoHeight autoHeightMax={80} universal hideTracksWhenNotNeeded>
                                    <div
                                        className={cn.rowValueInner}
                                        dangerouslySetInnerHTML={{ __html: elem?.value }}
                                    />
                                </Scrollbars>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TariffCompareItem;
