import { IHRMain } from '@/components/blocks/HRLanding/HRMain';

export const hrMain: IHRMain = {
    title: 'IT в Почта Банке — попади в команду профессионалов',
    description: '{&nbsp;c&nbsp;3&nbsp;июля по&nbsp;30&nbsp;сентября&nbsp;}',
    images: [
        '/img-next/png/hr-landing/main/1.png',
        '/img-next/png/hr-landing/main/2.png',
        '/img-next/png/hr-landing/main/3.png',
    ],
};
