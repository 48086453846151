import clsx from 'clsx';
import React from 'react';

import CollapsibleItem from '@/components/blocks/faq/CollapsibleItem';
import { IFaqInfo } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    info: IFaqInfo[];
    activeItem: number;
    isHidden?: boolean;
    tagsEnabled?: boolean;
    selectedTag?: string;
}

const CollapsibleList: React.FC<IProps> = ({ info, activeItem, isHidden, tagsEnabled, selectedTag }) => {
    if (!info || info?.length <= 0) return null;

    return (
        <div className={clsx(cn.wrapper, isHidden && cn.hidden)}>
            <div className={cn.list}>
                {info?.map((item, index) => (
                    <CollapsibleItem
                        key={index}
                        pos={index}
                        activeItem={activeItem}
                        item={item}
                        tagsEnabled={tagsEnabled}
                        selectedTag={selectedTag}
                    />
                ))}
            </div>
        </div>
    );
};

export default CollapsibleList;
