export const mockTextBannerBlockData = {
    startText: 'Ваш номер:',
    text:
        'Поздравляем, вам положен приз! <br/>' +
        'Пожалуйста, сделайте скриншот и для получения приза покажите его сотруднику Почта Банка.',
    victoryText:
        'Поздравляем, вам положен суперприз! <br/>' +
        'Пожалуйста, сделайте скриншот и для получения приза покажите его сотруднику Почта Банка.',
    name: 'textBanner',
    step: '10',
};

export const mockTextBannerBlockDataTina = {
    startText: 'Ваш номер:',
    text: 'Текст',
    name: 'textBanner',
    step: '10',
};
