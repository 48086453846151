export const rkoCalculatorData = {
    title: 'Подберите пакет услуг',
    tabTitle: 'Постоянный остаток на счете',
    maxCache: 0,
    fields: {
        rangeSlider: [
            {
                name: 'range1',
                elements: [
                    {
                        value: 0,
                        step: 5000,
                        percent: 0,
                        label: '0',
                        id: 1,
                    },
                    {
                        value: 100000,
                        step: 5000,
                        percent: 25,
                        label: '100 тыс.',
                        id: 2,
                    },
                    {
                        value: 500000,
                        step: 5000,
                        percent: 50,
                        label: '500 тыс.',
                        id: 3,
                    },
                    {
                        value: 1000000,
                        step: 5000,
                        percent: 75,
                        label: '1 млн.',
                        id: 5,
                    },
                    {
                        value: 3000000,
                        step: 5000,
                        percent: 100,
                        label: '3 млн.',
                        id: 6,
                    },
                ],
                label: 'Сумма снятия наличных в месяц',
                initValue: 150000,
            },
            {
                name: 'range2',
                elements: [
                    {
                        value: 0,
                        step: 1,
                        percent: 0,
                        label: '0',
                        id: 1,
                    },
                    {
                        value: 2,
                        step: 1,
                        percent: 10,
                        label: '2',
                        id: 2,
                    },
                    {
                        value: 4,
                        step: 1,
                        percent: 20,
                        label: '4',
                        id: 3,
                    },
                    {
                        value: 6,
                        step: 1,
                        percent: 30,
                        label: '6',
                        id: 4,
                    },
                    {
                        value: 8,
                        step: 1,
                        percent: 40,
                        label: '8',
                        id: 5,
                    },
                    {
                        value: 10,
                        step: 1,
                        percent: 50,
                        label: '10',
                        id: 6,
                    },
                    {
                        value: 12,
                        step: 1,
                        percent: 60,
                        label: '12',
                        id: 7,
                    },
                    {
                        value: 14,
                        step: 1,
                        percent: 70,
                        label: '14',
                        id: 8,
                    },
                    {
                        value: 16,
                        step: 1,
                        percent: 80,
                        label: '16',
                        id: 9,
                    },
                    {
                        value: 18,
                        step: 1,
                        percent: 90,
                        label: '18',
                        id: 10,
                    },
                    {
                        value: 19,
                        step: 1,
                        percent: 100,
                        label: '19',
                        id: 11,
                    },
                ],
                label: 'Количество платежей в месяц',
                initValue: 2,
            },
        ],
    },
    items: [
        {
            title: 'Пакет Стартап',
            price: '900',
            tariff: 'Стартап',
            listFree:
                '<ul><li>Первый месяц обслуживания</li><li>5 переводов в месяц <span>(далее 50 ₽ за перевод)</span></li><li>Внесение наличных в банкоматах Почта Банка</li></ul>',
            listDesc:
                '<ul><li>Лимит снятия наличных в месяц — 500 тыс. ₽</li><li>Комиссия за снятие — 2,5% от суммы</li></ul>',
            itemUrl: '#!',
        },
        {
            title: 'Пакет Гуру',
            price: '1 900',
            tariff: 'Гуру',
            listFree:
                '<ul><li>Первый месяц обслуживания</li><li>15 переводов в месяц <span>(далее 40 ₽ за перевод)</span></li><li>Внесение наличных в банкоматах Почта Банка</li><li>Снятие наличных до 50 тыс. ₽ в мес</li></ul>',
            listDesc:
                '<ul><li>Лимит снятия наличных в месяц — 1 млн ₽</li><li>Комиссия за снятие от 50 тыс ₽ — 2% от суммы</li><li>Комиссия за снятие от 500 тыс ₽ — 3,5% от суммы</li></ul>',
            listBottom: '<ul><li>+3% годовых на остаток от 50 000 ₽</li></ul>',
            itemUrl: '#!',
        },
        {
            title: 'Пакет Бизнес',
            price: '2 900',
            tariff: 'Бизнес',
            listFree:
                '<ul><li>Первый месяц обслуживания</li><li>35 переводов в месяц <span>(далее 30 ₽ за перевод)</span></li><li>Внесение наличных в банкоматах Почта Банка</li><li>Снятие наличных до 100 тыс. ₽ в мес</li></ul>',
            listDesc:
                '<ul><li>Лимит снятия наличных в месяц — 3 млн ₽</li><li>Комиссия за снятие от 100 тыс ₽ — 1,5% от суммы</li><li>Комиссия за снятие от 500 тыс ₽ — 3,5% от суммы</li><li>Комиссия за снятие от 1 млн ₽ — 11% от суммы</li></ul>',
            listBottom: '<ul><li>+3% годовых на остаток от 50 000 ₽</li></ul>',
            itemUrl: '#!',
        },
    ],
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
