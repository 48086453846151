export const profitabilityCalculatorHotSeasons = {
    title: 'Калькулятор доходности',
    filterElements: [
        {
            value: 10000,
            step: 5000,
            percent: 0,
            label: '10 тыс.',
            id: 1,
        },
        {
            value: 1000000,
            step: 5000,
            percent: 50,
            label: '1 млн.',
            id: 2,
        },
        {
            value: 1000000000,
            step: 5000,
            percent: 100,
            label: '1 млрд.',
            id: 3,
        },
    ],
    terms: [
        {
            id: 1,
            label: '3 месяца',
            value: 3,
            ranges: [{ id: 4, min: 0, max: 1000000, percent: 20 }],
        },
        {
            id: 2,
            label: '6 месяцев',
            value: 6,
            ranges: [{ id: 4, min: 0, max: 1000000, percent: 18 }],
        },
        {
            id: 3,
            label: '12 месяцев',
            value: 12,
            ranges: [{ id: 4, min: 0, max: 1000000, percent: 9.5 }],
        },
        {
            id: 4,
            label: '18 месяцев',
            value: 18,
            ranges: [{ id: 4, min: 0, max: 1000000, percent: 9.25 }],
        },
    ],
    minValue: 50000,
    maxvalue: 10000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};

export const profitabilityCalculatorHotSeasonsNewYear = {
    title: 'Калькулятор доходности: Горячие сезоны',
    filterElements: [
        {
            value: 100000,
            step: 5000,
            percent: 0,
            label: '100 тыс.',
            id: 1,
        },
        {
            value: 1000000,
            step: 5000,
            percent: 50,
            label: '1 млн.',
            id: 2,
        },
        {
            value: 2000000,
            step: 5000,
            percent: 100,
            label: '2 млн.',
            id: 3,
        },
    ],
    terms: [
        {
            id: 1,
            label: '6 месяцев',
            value: 181,
            calculatedValue: 181,
            ranges: [{ id: 4, min: 0, max: 2000000, percent: 14.5 }],
        },
    ],
    minValue: 100000,
    maxvalue: 2000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};

export const profitabilityCalculatorCapital = {
    title: 'Калькулятор доходности',
    filterElements: [
        {
            value: 50000,
            step: 5000,
            percent: 0,
            label: '50 тыс.',
            id: 1,
        },
        {
            value: 140000,
            step: 10000,
            percent: 25,
            label: '',
            id: 2,
        },
        {
            value: 500000,
            step: 100000,
            percent: 50,
            label: '',
            id: 3,
        },
        {
            value: 2500000,
            step: 1,
            percent: 75,
            label: '',
            id: 4,
        },
        {
            value: 100000000,
            step: 1,
            percent: 100,
            label: '100 млн.',
            id: 5,
        },
    ],
    terms: [
        {
            id: 1,
            label: '181 день',
            value: 181,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 99999,
                    percent: 4.05,
                },
                {
                    id: 2,
                    min: 100000,
                    max: 100000000,
                    percent: 4.25,
                },
            ],
        },
        {
            id: 2,
            label: '367 дней',
            value: 367,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 99999,
                    percent: 4.55,
                },
                {
                    id: 2,
                    min: 100000,
                    max: 100000000,
                    percent: 4.75,
                },
            ],
        },
        {
            id: 3,
            label: '546 дней',
            value: 546,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 99999,
                    percent: 4.8,
                },
                {
                    id: 2,
                    min: 100000,
                    max: 100000000,
                    percent: 5,
                },
            ],
        },
    ],
    minValue: 50000,
    maxvalue: 100000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};

export const profitabilityCalculatorProfit = {
    title: 'Калькулятор доходности',
    filterElements: [
        {
            value: 100000,
            step: 20000,
            percent: 0,
            label: '100 тыс.',
            id: 1,
        },
        {
            value: 2800000,
            step: 250000,
            percent: 25,
            label: '',
            id: 2,
        },
        {
            value: 25000000,
            step: 500000,
            percent: 50,
            label: '',
            id: 3,
        },
        {
            value: 75000000,
            step: 1000000,
            percent: 75,
            label: '',
            id: 4,
        },
        {
            value: 100000000,
            step: 1,
            percent: 100,
            label: '100 млн.',
            id: 5,
        },
    ],
    terms: [
        {
            id: 1,
            label: '367 дней',
            value: 367,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 499999,
                    percent: 4.25,
                },
                {
                    id: 2,
                    min: 500000,
                    max: 100000000,
                    percent: 4.45,
                },
            ],
        },
    ],
    minValue: 100000,
    maxvalue: 100000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};

export const profitabilityCalculatorPension = {
    title: 'Калькулятор доходности',
    filterElements: [
        { value: 10000, step: 20000, percent: 0, label: '10 тыс.', id: 1 },
        { value: 2800000, step: 250000, percent: 25, label: '', id: 2 },
        { value: 25000000, step: 500000, percent: 50, label: '', id: 3 },
        { value: 75000000, step: 1000000, percent: 75, label: '', id: 4 },
        { value: 100000000, step: 1, percent: 100, label: '100 млн.', id: 5 },
    ],
    termsTitleFlag: false,
    terms: [
        {
            id: 1,
            label: 'Базовые условия',
            topLabel: '6 месяцев',
            value: 275,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 100000000,
                    percent: 8.25,
                },
            ],
        },
        {
            id: 2,
            label: 'Я получаю пенсию в Почта Банке',
            topLabel: '6 месяцев',
            value: 275,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 100000000,
                    percent: 9.25,
                },
            ],
        },
        {
            id: 3,
            label: 'Базовые условия',
            topLabel: '12 месяцев',
            value: 275,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 100000000,
                    percent: 8.5,
                },
            ],
        },
        {
            id: 4,
            label: 'Я получаю пенсию в Почта Банке',
            topLabel: '12 месяцев',
            value: 275,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 100000000,
                    percent: 9.5,
                },
            ],
        },
    ],
    minValue: 50000,
    maxvalue: 100000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};

export const profitabilityCalculatorCumulative = {
    title: 'Калькулятор доходности',
    enableCheckbox: true,
    filterElements: [
        {
            value: 5000,
            step: 5000,
            percent: 0,
            label: '5 тыс.',
            id: 1,
        },
        {
            value: 150000,
            step: 10000,
            percent: 25,
            label: '',
            id: 2,
        },
        {
            value: 500000,
            step: 100000,
            percent: 50,
            label: '',
            id: 3,
        },
        {
            value: 2500000,
            step: 100000,
            percent: 75,
            label: '',
            id: 4,
        },
        {
            value: 100000000,
            step: 1,
            percent: 100,
            label: '100 млн.',
            id: 5,
        },
    ],
    terms: [
        {
            id: 1,
            label: '367 дней',
            value: 275,
            ranges: [
                {
                    id: 1,
                    min: 0,
                    max: 100000000,
                    percent: 4.35,
                },
            ],
        },
    ],
    minValue: 5000,
    maxvalue: 100000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
