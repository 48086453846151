export const mockPaperWorkBlockData = {
    image: '/img-next/png/askQuestions.png',
    title: 'Остались вопросы?',
    buttonText: 'Название кнопки',
    content: 'Подробнее о кредитных картах можно узнать в разделе «Часто задаваемые вопросы».',
};

export const mockPaperWorkBlockDataTina = {
    image: '',
    title: 'Заголовок',
    buttonText: 'Название кнопки',
    content: 'Описание',
};
