'use client';

import React from 'react';
import { SwiperSlide } from 'swiper/react';

import type { MainPrizesProps } from '@/components/blocks/draw/main-prizes';
import { PrizeItem } from '@/components/blocks/draw/main-prizes/item';
import CustomSwiper from '@/components/CustomSwiper';
import { useMobileWidth } from '@/hooks/useMedia';

import cn from './style.module.sass';

export function PrizesList({ items }: Pick<MainPrizesProps, 'items'>) {
    const isMobile = useMobileWidth(true);

    return (
        <CustomSwiper
            className={cn.list}
            spaceBetween={isMobile ? 10 : 32}
            slidesPerView={isMobile ? 1 : 3}
            loop={false}
        >
            {items?.map(({ title, label, backgroundSrc, partnerLogoSrc }, index) => (
                <SwiperSlide key={`${index}-${title}`}>
                    <PrizeItem
                        key={title}
                        title={title}
                        label={label}
                        backgroundSrc={backgroundSrc}
                        partnerLogoSrc={partnerLogoSrc}
                    />
                </SwiperSlide>
            ))}
        </CustomSwiper>
    );
}
