import React from 'react';

import { TinaQuotesCarousel } from '@/components/blocks/QuotesCarousel/index.tina';
import { mockPhotoSliderDataTina } from '@/data/blocks/photoSlider';
import { mockQuotesCarouselMockData } from '@/data/blocks/quotesCarousel';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const quotesCarouselBlock = {
    Component: props => <TinaQuotesCarousel {...props} />,
    template: {
        label: 'Карусель цитат руководителей',
        defaultItem: () => mockQuotesCarouselMockData,
        fields: [
            {
                label: 'Список элементов',
                name: 'items',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockPhotoSliderDataTina.list[0],
                fields: [
                    {
                        name: 'photo',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Описание',
                    },
                    {
                        name: 'position',
                        component: 'text',
                        label: 'Описание',
                    },
                    {
                        name: 'quote',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
            {
                name: 'bottomText',
                component: 'textarea',
                label: 'Доп. описание',
            },
            { ...gtagSingleItem },
        ],
    },
};
