export const mockHolidayBannerBlockData = {
    title: 'День святого Валентина',
    text: 'Купите билеты на концерт, выставку или спектакль. Проведите время интересно и весело вместе с <a href="#">близкими</a>',
    desktopImage: '/img-next/png/banners/holidayBannerDesktopImage.png',
    tabletImage: '/img-next/png/banners/holidayBannerTabletImage.png',
    mobileImage: '/img-next/png/banners/holidayBannerMobileImage.png',
    name: 'holidayBanner',
    typeBanner: 'love',
    button: {
        title: 'Купить',
        link: '#',
    },
};

export const mockHolidayBannerBlockDataTina = {
    title: 'Заголовок',
    text: 'Текст',
    desktopImage: '',
    tabletImage: '',
    mobileImage: '',
    name: 'holidayBanner',
    typeBanner: 'love',
    button: {
        title: 'Текст кнопки',
        link: '#',
    },
};
