import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';

import TextField from '../../TextField';
import cn from './style.module.sass';

export interface ICitiesLoans {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'citiesLoans';
    title: string;
    /**
     * Список категорий
     */
    list: ICitiesLoansItem[];
    limit?: number;
}

interface ICitiesLoansItem {
    text: string;
    link: string;
}

function CitiesLoans({ list, title, limit = 18 }: ICitiesLoans) {
    const [showMore, setShowMore] = useState(true);

    const defaultElements = list?.slice(0, limit);

    const [showedElements, setShowedElements] = useState(defaultElements);

    const handleClick = () => {
        if (showMore) {
            const index = showedElements.length + 18;
            setShowedElements(list?.slice(0, index));

            if (index >= list?.length) {
                setShowMore(false);
            } else {
                setShowMore(true);
            }
        } else {
            setShowedElements(defaultElements);

            setShowMore(true);
        }
    };

    const buttonLabel = showMore ? 'Показать ещё' : 'Скрыть';

    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.citiesList, 'section')}>
            <div className={cn.citiesListWrap}>
                <div className={cn.citiesListGrid}>
                    <div className={cn.citiesListRow}>
                        <AnimatedComponent className={cn.citiesListCollapsibleWrap} classNameActive={cn.animationInit}>
                            <TextField
                                text={title}
                                customTag="h3"
                                name="title"
                                className={cn.title}
                                isHTML
                                htmlValue={title}
                            />

                            <div className={cn.citiesRow}>
                                {showedElements?.map(({ text, link }, index) => (
                                    <div className={cn.citiesItem} key={index}>
                                        <div className={cn.citiesItemContent}>
                                            <div className={cn.citiesItemMark}>Кредит наличными</div>
                                            <TextField
                                                text={text}
                                                name={`list.[${index}].text`}
                                                className={cn.citiesItemTitle}
                                                isHTML
                                                htmlValue={text}
                                            />
                                            <div className={cn.citiesBtn}>
                                                <Button
                                                    variant="btnBlue"
                                                    type="link"
                                                    href={link}
                                                    label="Получить кредит"
                                                    size="small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {list?.length > limit && (
                                <div className={cn.showMoreBtn}>
                                    <Button
                                        variant="btnBlueBorder"
                                        type="button"
                                        label={buttonLabel}
                                        animation={false}
                                        onClick={handleClick}
                                    />
                                </div>
                            )}
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withBlockLinks(CitiesLoans);
