import React from 'react';

import cn from './style.module.sass';

function Decor() {
    return (
        <svg width="37" height="29" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.64844 29L0.625 24.6387C4.02344 19.6921 5.7793 14.4622 5.89258 8.94922V0H17.957V7.81641C17.957 11.7057 16.9564 15.6328 14.9551 19.5977C12.9915 23.5247 10.556 26.6589 7.64844 29ZM26.5664 29L19.543 24.6387C22.9414 19.6921 24.6973 14.4622 24.8105 8.94922V0H36.875V7.81641C36.875 11.7057 35.8743 15.6328 33.873 19.5977C31.9095 23.5247 29.474 26.6589 26.5664 29Z"
                className={cn.svgFill}
            />
        </svg>
    );
}

export default Decor;
