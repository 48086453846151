export const mockYoutubeBlockData = {
    title: 'Новая технология «Фотоплатеж»',
    description:
        'Используйте новую технологию распознавания квитанций – фотоплатеж. Cфотографируйте квитанцию в мобильном банке, а мы автоматически заполним все необходимые платёжные реквизиты.',
    src: 'https://www.youtube.com/embed/bx9wTyzthX8',
    buttonColor: 'dark',
};

export const mockYoutubeBlockDataTina = {
    title: 'Заголовок',
    description: 'Описание',
    buttonColor: 'dark',
    src: '',
};
