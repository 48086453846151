import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import TermsList from '@/components/blocks/calculators/TermsList';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
    itemClassName?: string;
    activeClassName?: string;
    isRedesign?: boolean;
}

const Term: React.FC<IProps> = observer(({ gtag, itemClassName, activeClassName, isRedesign }) => {
    const { terms } = useContext(CalculatorStoreContext);

    return (
        <div className={cn.wrapper}>
            <div className={cn.label}>Срок кредита</div>
            <TermsList
                terms={terms}
                gtag={gtag}
                itemClassName={itemClassName}
                activeClassName={activeClassName}
                isRedesign={isRedesign}
            />
        </div>
    );
});

export default Term;
