import clsx from 'clsx';
import React from 'react';

import InfoBlockPensiyaMapButton from '@/components/blocks/InfoBlockPensiya/buttonMap';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IInfoBlockPensiya {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'infoBlockPensiya';
    /**
     * Список категорий
     */
    image: string;
    title: string;
    desc?: string;
    button?: { link: string; type: 'link' | 'anchor'; linkName: string; gtag?: GTagEvent };
    alignment?: 'top' | 'middle' | 'bottom';
}

function InfoBlockPensiya({ image, title, desc, button, alignment = 'top' }: IInfoBlockPensiya) {
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const { link = undefined, type = 'anchor', linkName = undefined, gtag = undefined } = button || {};

    return (
        <AnimatedComponent className={clsx(cn.infoBlockPensiya, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.infoBlockPensiyaWrap}>
                <div className={clsx(cn.infoBlockPensiyaGrid, cn?.[alignment])}>
                    <div className={cn.infoBlockPensiyaItem}>
                        <div className={clsx(cn.infoBlockPensiyaContent, link && cn.infoBlockContentSpaceBetween)}>
                            <div>
                                {title && (
                                    <TextField
                                        text={title}
                                        className={cn.infoBlockPensiyaTitle}
                                        name="title"
                                        htmlValue={title}
                                        isHTML
                                    />
                                )}

                                {desc && (
                                    <TextField
                                        text={desc}
                                        isHTML
                                        htmlValue={desc}
                                        name="desc"
                                        className={cn.infoBlockPensiyaDesc}
                                    />
                                )}
                            </div>
                            {link && (
                                <InfoBlockPensiyaMapButton
                                    link={link}
                                    type={type}
                                    linkName={linkName}
                                    gtag={gtag}
                                    hideOnMobile
                                />
                            )}
                        </div>
                    </div>
                    <div className={clsx(cn.infoBlockPensiyaItem, cn.infoBlockPensiyaImageContainer)}>
                        <div className={clsx(cn.infoBlockPensiyaImage)}>
                            {image && <CustomImage src={image} alt={title} width={544} height={380} />}
                        </div>
                    </div>
                    {link && (
                        <InfoBlockPensiyaMapButton
                            link={link}
                            type={type}
                            linkName={linkName}
                            gtag={gtag}
                            hideOnMobile={false}
                        />
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(InfoBlockPensiya);
