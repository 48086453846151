import Image from 'next/image';
import React from 'react';

import cn from './style.module.sass';

export interface IAtmList {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'atmList';
    map: IAtm[];
}

interface IAtm {
    address: string;
    city: string;
    region: string;
    workingTime: string;
}

function ATMList({ map }: IAtmList) {
    return (
        <div className={cn.wrapper}>
            {map?.map(item => {
                const { address, workingTime, city } = item;

                return (
                    <div className={cn.card} key={address + city}>
                        <Image
                            className={cn.logo}
                            src="/img-next/svg/map/pb_logo.svg"
                            alt="логотип Почтабанк"
                            width={135}
                            height={32}
                        />
                        <div className={cn.description}>
                            <div className={cn.address}>{address}</div>
                            <div className={cn.schedule}>{workingTime}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default ATMList;
