import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsSalaryProject } from '@/components/blocks/FeedbackFormSalaryProject/data';

interface IFeedbackFormSalaryProject {
    _template: 'feedbackFormSalaryCard';
    title?: string;
    description?: string;
    successTitle?: string;
    buttonText?: string;
}

const FeedbackFormSalaryProject: React.FC<IFeedbackFormSalaryProject> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormSalaryProject',
    buttonText,
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsSalaryProject}
        title={title}
        description={description}
        successTitle={successTitle}
        descFlag={false}
        buttonText={buttonText}
    />
);

export default FeedbackFormSalaryProject;
