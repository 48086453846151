import clsx from 'clsx';
import React, { FC } from 'react';

import cn from './style.module.sass';

export type IIconArrow = {
    color?: 'white' | 'blue' | 'grey' | string;
    back?: boolean;
    isHovering?: boolean;
    customClass?: string;
};

const IconArrow: FC<IIconArrow> = ({ color, back, isHovering, customClass }) => (
    <svg
        className={clsx(cn.iconArrow, cn[color], {
            [cn.iconArrowBack]: back,
            [cn.iconArrowHover]: isHovering,
        })}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6 1L12 7M12 7L6 13M12 7H0" strokeWidth="2" className={clsx(cn.iconArrowPath, customClass)} />
    </svg>
);
export default IconArrow;
