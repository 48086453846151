import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import Questions, { IQuestions } from '@/components/blocks/Questions';
import QuoteHr, { IQuoteHr } from '@/components/blocks/QuoteHr';
import Button from '@/components/UI/Button';
import CustomSelect from '@/components/UI/CustomSelect';
import { IQuestionsItem } from '@/types/questions/item';
import { ISelectElement } from '@/types/select';

import cn from './style.module.sass';

interface IThemesList {
    id?: number;
    xmlId: string;
    value: string;
}

interface IExtendedQuestionsItem extends IQuestionsItem {
    tags?: string[];
}

interface IExtendedQuestions extends IQuestions {
    tagList: IThemesList[];
    list: IExtendedQuestionsItem[];
}

interface IButton {
    title: string;
    link: string;
}

export interface IHrFaq {
    _template?: 'hrFaq';
    quoteHrBlock: IQuoteHr;
    questionsBlock: IExtendedQuestions;
    button?: IButton;
}

const HrFaq: React.FC<IHrFaq> = ({ questionsBlock, quoteHrBlock, button }) => {
    const [selectedTag, setSelectedTag] = useState<string>(questionsBlock?.tagList?.[0]?.xmlId);

    const questions = useMemo(() => {
        if (!questionsBlock?.tagList?.length) return questionsBlock?.list;
        return questionsBlock?.list?.filter(current => current?.tags?.includes(selectedTag));
    }, [questionsBlock, selectedTag]);

    const selectItems: ISelectElement<string>[] = useMemo(
        () => questionsBlock?.tagList?.map(item => ({ value: item.xmlId, label: item.value })),
        [questionsBlock?.tagList]
    );

    const onSelectChange = (item: ISelectElement<string>) => {
        setSelectedTag(item.value);
    };

    return (
        <div className="section">
            <div className={clsx(cn.fullWrapper, 'nestedBlocks')}>
                <div className={cn.select}>
                    <div className={clsx(cn.selectItem, cn.itemSelect)}>
                        <CustomSelect options={selectItems} onChangeHandler={onSelectChange} placeholder="Выбрать..." />
                    </div>
                    {button?.title && (
                        <div className={clsx(cn.selectItem, cn.itemBtn)}>
                            <Button
                                variant="btnBlue"
                                type="button"
                                label={button?.title}
                                href={button?.link}
                                customClass="full"
                                size="large"
                            />
                        </div>
                    )}
                </div>
                <QuoteHr {...quoteHrBlock} tinaPrefix="quoteHrBlock." />
                <Questions {...questionsBlock} list={questions} tinaPrefix="questionsBlock." />
            </div>
        </div>
    );
};

export default HrFaq;
