import React from 'react';

import { TinaCards } from '@/components/blocks/Cards/index.tina';
import { mockCardsBlockDataTina } from '@/data/blocks/cards';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const cardsBlock = {
    Component: props => <TinaCards {...props} />,
    template: {
        label: 'Карты',
        defaultItem: () => mockCardsBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },
            {
                name: 'cardLimit',
                component: 'number',
                label: 'Лимит для показа',
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Список карт',
                itemProps: (item: { name: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockCardsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Название (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                        required: true,
                    },
                    {
                        name: 'advantages',
                        component: 'group-list',
                        label: 'Список информационных блоков',
                        itemProps: (item: { title: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => ({
                            title: '120 дней',
                            subtitle: 'беспроцентный период',
                        }),
                        fields: [
                            {
                                name: 'title',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                            {
                                name: 'subtitle',
                                component: HtmlEditorFeatured,
                                label: 'Подзаголовок',
                            },
                            {
                                name: 'tooltipText',
                                component: HtmlEditorFeatured,
                                label: 'Тултип',
                            },
                        ],
                    },
                    {
                        name: 'promo',
                        component: HtmlEditorFeatured,
                        label: 'Промо описание',
                    },
                    {
                        name: 'main',
                        component: 'toggle',
                        label: 'Лучшая карта',
                    },
                    {
                        name: 'archive',
                        component: 'toggle',
                        label: 'Активная/Архивная',
                    },
                    {
                        name: 'buttonBg',
                        component: 'group',
                        label: 'Основная кнопка',
                        fields: [
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Название',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                    {
                        name: 'buttonDetail',
                        component: 'group',
                        label: 'Дополнительная кнопка',
                        fields: [
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Название',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                    {
                        name: 'buttonArchive',
                        component: 'group',
                        label: 'Архивная кнопка',
                        fields: [
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Название',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                    {
                        name: 'cardLayers',
                        component: 'group',
                        label: 'Слои картинок карты',
                        fields: [
                            {
                                name: 'bgLayer',
                                component: 'image',
                                label: 'Основной слой',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'imageLink',
                                component: 'text',
                                label: 'Название продуктового параметра или ссылка на изображение',
                            },
                            {
                                name: 'infoLayer',
                                component: 'image',
                                label: 'Информационный слой',
                                parse: (media: { src: string }) => media.src,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
