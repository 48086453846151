import { IPromoSecondary } from '@/components/blocks/PromoSecondary';

export const mockPromoSecondaryBlockData: IPromoSecondary = {
    _template: 'promoSecondary',
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage:
            'https://files.pochtabank.ru/medialibrary/e9f/e9ff68c74337a0a70fea9038cd842b3a/Hero%20image.png',
        color: 'white',
        line: {
            title: 'Кредит наличными',
            subtitle: 'от 4% годовых',
        },
        buttons: [
            {
                text: 'Получить кредит',
                link: '#id__credit-issue',
                type: 'button',
                color: 'blue',
                isSetMobileAnimate: true,
            },
        ],
        image: '/img-next/png/promov2.png',
        imageLink: '',
        imageBottomMv: true,
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'Онлайн',
            subtitle: 'Подтверждение дохода',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'До 5 000 000 ₽',
            subtitle: 'Сумма кредита',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'До 7 лет',
            subtitle: 'Срок кредита',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'За 1 минуту',
            subtitle: 'Решение по кредиту',
        },
    ],
};
