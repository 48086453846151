import React from 'react';

import { TinaTariffBlocks } from '@/components/blocks/tariffTabs/TariffBlocks/index.tina';
import { mockTabTariffBlockDataTina } from '@/data/blocks/tariffTabs/tariff';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const tariffBlocksBlock = {
    Component: (props: IBlockProps) => <TinaTariffBlocks {...props} />,
    template: {
        label: 'Блоки тарифов',
        defaultItem: () => mockTabTariffBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                name: 'type',
                component: 'select',
                label: 'Тип',
                options: [
                    {
                        value: 'main',
                        label: 'Главный',
                    },
                    {
                        value: 'secondary',
                        label: 'Дополнительный',
                    },
                ],
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockTabTariffBlockDataTina.list[0],
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        name: 'title',
                        label: 'Подзаголовок',
                    },
                    {
                        component: 'toggle',
                        name: 'isDropdown',
                        label: 'Включить dropdown',
                    },
                    {
                        component: 'toggle',
                        name: 'isDropdownDefaultHide',
                        label: 'Скрыть dropdown по умолчанию',
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { name: string }, index: number) => ({
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => mockTabTariffBlockDataTina.list[0].info[0],
                        fields: [
                            {
                                component: 'text',
                                name: 'name',
                                label: 'Название',
                            },
                            {
                                component: HtmlEditorFeatured,
                                name: 'desc',
                                label: 'Описание',
                            },
                            {
                                component: 'toggle',
                                name: 'isMain',
                                label: 'Главный/побочный',
                            },
                            {
                                component: 'text',
                                name: 'tooltipText',
                                label: 'Подсказка',
                            },
                        ],
                    },
                    {
                        name: 'isConditionsActive',
                        component: 'toggle',
                        label: 'Выключить/включить условия',
                    },
                    {
                        component: 'group',
                        name: 'conditions',
                        label: 'Условия',
                        fields: [
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Изображение',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                component: 'text',
                                name: 'subhead',
                                label: 'Текст',
                            },
                            {
                                component: 'textarea',
                                name: 'text',
                                label: 'Описание',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
