import clsx from 'clsx';
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

import cn from '@/components/blocks/Persons/SliderMobile/style.module.sass';
import { IPersonsItem } from '@/components/blocks/Persons/types';
import CustomImage from '@/components/CustomImage';
import Button from '@/components/UI/Button';

interface ISlideMobile {
    mobileSlide: IPersonsItem;
}

function SlideMobile({ mobileSlide }: ISlideMobile) {
    const [isFlipped, setIsFlipped] = useState(false);
    const { name, status, description, img, backSideTitle, backSideDescription } = mobileSlide;

    return (
        <div role="presentation" className={cn.cardMobile}>
            <ReactCardFlip
                isFlipped={isFlipped}
                flipDirection="horizontal"
                containerClassName={cn.flipWrapper}
                flipSpeedFrontToBack={1}
                flipSpeedBackToFront={1}
            >
                <div className={clsx(cn.cardMobileContent)}>
                    <div className={cn.textBlock}>
                        <div className={cn.name}>{name}</div>
                        <div className={cn.status}>{status}</div>
                        <div className={cn.description} dangerouslySetInnerHTML={{ __html: description }} />
                        <Button
                            variant="btnBlue"
                            onClick={() => setIsFlipped(!isFlipped)}
                            type="button"
                            label="Подробнее"
                            customClass={cn.button}
                        />
                    </div>

                    <div className={cn.image}>
                        <CustomImage src={img} alt={name} width={160} height={160} />
                    </div>
                </div>
                <div className={clsx(cn.backCardMobileContent, !isFlipped && cn.hidden)}>
                    <div className={cn.textBlock}>
                        <div className={cn.backSideMobileTitle}>{backSideTitle}</div>
                        <div className={cn.description} dangerouslySetInnerHTML={{ __html: backSideDescription }} />
                    </div>
                    <Button
                        variant="btnBlue"
                        onClick={() => setIsFlipped(!isFlipped)}
                        type="button"
                        label="Закрыть"
                        customClass={cn.button}
                    />
                </div>
            </ReactCardFlip>
        </div>
    );
}

export default SlideMobile;
