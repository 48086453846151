import { useState } from 'react';

import { useFixHtml } from '@/hooks/useFixHtml';

export const useTooltip = () => {
    const [showToolTip, setShowTooltip] = useState<boolean>(false);

    const { fix, unfix } = useFixHtml();

    const show = () => {
        fix();
        setShowTooltip(true);
    };

    const hide = () => {
        unfix();
        setShowTooltip(false);
    };

    return {
        isVisible: showToolTip,
        show,
        hide,
    };
};
