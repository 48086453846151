import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ITitle {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'title';
    /**
     * Заголовок
     */
    title: string;

    // eslint-disable-next-line no-magic-numbers
    level: 1 | 2 | 3 | 4 | 5 | 6;
    text?: string;
}

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Title: React.FC<ITitle> = ({ title, level, text }) => {
    const Tag = `h${level}` as HeadingTag;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.titleWrap)}>
                <div className={cn.wrapper}>
                    <TextField text={title} name="title" customTag={Tag} className={clsx(cn.title, cn[`h${level}`])} />
                    {text && <div className={cn.text}>{text}</div>}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Title);
