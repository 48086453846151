import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import cn from '@/components/blocks/MainSlider/style.module.sass';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { LinkIcon } from '@/components/UI/LinkIcon';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IMainSliderItem {
    image: string;
    title: string;
    subtitle: string;
    textBtn: string;
    link: string;
    tags?: string[];
    isLink: boolean;
    gtag?: GTagEvent;
    pos?: number;
}

const MainSliderItem: React.FC<IMainSliderItem> = observer(({ link, title, image, gtag, pos, subtitle, textBtn }) => {
    const onClick = () => {
        if (gtag?.action) event(gtag);
    };

    return (
        <div className={clsx(cn.currentItem, 'currentItem')}>
            <div className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}>
                {link ? (
                    <a href={link} className={cn.currentImage} onClick={onClick}>
                        <CustomImage src={image} alt={title} mockWidth="350" mockHeight="200" fill />
                    </a>
                ) : (
                    <div className={cn.currentImage}>
                        <CustomImage src={image} alt={title} mockWidth="350" mockHeight="200" fill />
                    </div>
                )}
                <div className={cn.currentContent}>
                    <div className={cn.currentTop}>
                        {link ? (
                            <a href={link} className={cn.currentTitle}>
                                <TextField
                                    text={title}
                                    name={`list.[${pos}].title`}
                                    customTag="span"
                                    isHTML
                                    htmlValue={title}
                                />
                            </a>
                        ) : (
                            <div className={cn.currentTitle}>
                                <TextField
                                    text={title}
                                    name={`list.[${pos}].title`}
                                    customTag="span"
                                    isHTML
                                    htmlValue={title}
                                />
                            </div>
                        )}
                        <TextField
                            text={subtitle}
                            name={`list.[${pos}].subtitle`}
                            isHTML
                            htmlValue={subtitle}
                            className={cn.currentSubtitle}
                        />
                    </div>
                    <div className={cn.currentBot}>
                        {link && (
                            <LinkIcon
                                href={link}
                                noPadding
                                typeIcon="right"
                                label={textBtn || 'Узнать больше'}
                                gtag={gtag}
                            />
                        )}
                    </div>
                    <div className={cn.currentBotDecor} />
                </div>
            </div>
        </div>
    );
});

export default MainSliderItem;
