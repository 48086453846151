import React from 'react';

import { TinaInfoBlockGradient } from '@/components/blocks/InfoBlockGradient/index.tina';
import { mockInfoBlockGradientDataTina } from '@/data/blocks/infoBlockGradient';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoBlockGradientBlock = {
    Component: props => <TinaInfoBlockGradient {...props} />,
    template: {
        label: 'Растяжка - градиент',
        defaultItem: () => mockInfoBlockGradientDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'titleBlock',
                component: HtmlEditorFeatured,
                label: 'Главный заголовок',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                component: 'select',
                name: 'color',
                label: 'Тип',
                options: [
                    {
                        value: 'default',
                        label: 'Стандартный',
                    },
                    {
                        value: 'white',
                        label: 'Белый',
                    },
                ],
            },
            {
                name: 'bg',
                component: 'text',
                label: 'Цвет фона',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Преимущества',
                name: 'advantages',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockInfoBlockGradientDataTina.advantages[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: 'textarea',
                        label: 'Описание',
                    },
                ],
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'linkName',
                component: 'text',
                label: 'Текст на кнопке',
            },
            {
                name: 'link',
                component: 'text',
                label: 'Ссылка',
            },
            { ...gtagSingleItem },
        ],
    },
};
