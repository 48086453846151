import clsx from 'clsx';
import React, { FC } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import TooltipColorIcon from '@/components/UI/ColorToolTipIcon';

import cn from './style.module.sass';

interface IProps {
    tooltipText: string;
    handleClose: () => void;
    isOpen: boolean;
    customClass?: string;
}

const FullScreenTooltip: FC<IProps> = ({ tooltipText, handleClose, isOpen, customClass }) => (
    <ModalWrapper handleClose={handleClose} isOpen={isOpen} isStandartModalWidth={false}>
        <div className={cn.tooltipWrapper}>
            <TooltipColorIcon />
            <div className={clsx(cn.tooltipText, customClass)} dangerouslySetInnerHTML={{ __html: tooltipText }} />
        </div>
    </ModalWrapper>
);

export default FullScreenTooltip;
