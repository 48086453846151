export const mockTableBlockData = {
    html:
        '<table><tbody><tr><td><div>Преимущества пакета</div></td><td><div>Серебряный</div></td><td><div>Золотой</div></td><td><div>Платиновый</div></td></tr>' +
        '<tr><td><div>Выпуск карты с индивидуальным дизайном</div></td><td><div>без взимания комиссии</div></td><td><div>без взимания комиссии</div></td>' +
        '<td><div>без взимания комиссии</div></td></tr><tr><td><div>СМС-информирование</div></td><td><div>без взимания комиссии</div></td>' +
        '<td><div>без взимания комиссии</div></td><td><div>без взимания комиссии</div></td></tr><tr><td><div>Повышенные лимиты на снятие наличных в любом банкомате</div></td>' +
        '<td><div>до 30 000 ₽/мес.</div></td><td><div>до 50 000 ₽/мес.</div></td><td><div>до 100 000 ₽/мес.</div></td></tr>' +
        '<tr><td><div>Бех комиссии переводы через систему быстрых платежей</div></td><td><div>до 200 000 ₽/мес.</div></td><td>' +
        '<div>до 250 000 ₽/мес.</div></td><td><div>до 300 000 ₽/мес.</div></td></tr><tr><td><div>Специальные условия по Сберегательному счету, %годовых</div></td>' +
        '<td><div>до 6,5% годовых</div></td><td><div>до 6,5% годовых</div></td><td><div>до 6,5% годовых</div></td></tr></tbody></table>',
    table: [
        {
            listCell: [
                {
                    label: '',
                    text: 'Преимущества пакета',
                },
                {
                    label: '',
                    text: 'Серебряный',
                },
                {
                    label: '',
                    text: 'Золотой',
                },
                {
                    label: '',
                    text: 'Платиновый',
                },
            ],
        },
        {
            listCell: [
                {
                    label: '',
                    text: 'Выпуск карты с индивидуальным дизайном',
                },
                {
                    label: 'Выпуск карты с индивидуальным дизайном',
                    text: 'без взимания комиссии',
                },
                {
                    label: 'Выпуск карты с индивидуальным дизайном',
                    text: 'без взимания комиссии',
                },
                {
                    label: 'Выпуск карты с индивидуальным дизайном',
                    text: 'без взимания комиссии',
                },
            ],
        },
        {
            listCell: [
                {
                    label: '',
                    text: 'СМС-информирование',
                },
                {
                    label: 'СМС-информирование',
                    text: 'без взимания комиссии',
                },
                {
                    label: 'СМС-информирование',
                    text: 'без взимания комиссии',
                },
                {
                    label: 'СМС-информирование',
                    text: 'без взимания комиссии',
                },
            ],
        },
        {
            listCell: [
                {
                    label: '',
                    text: 'Повышенные лимиты на снятие наличных в любом банкомате',
                },
                {
                    label: 'СМС-информирование',
                    text: 'до 30 000 ₽/мес.',
                },
                {
                    label: 'СМС-информирование',
                    text: 'до 50 000 ₽/мес.',
                },
                {
                    label: 'СМС-информирование',
                    text: 'до 100 000 ₽/мес.',
                },
            ],
        },
        {
            listCell: [
                {
                    label: '',
                    text: 'Бех комиссии переводы через систему быстрых платежей',
                },
                {
                    label: 'Бех комиссии переводы через систему быстрых платежей',
                    text: 'до 200 000 ₽/мес.',
                },
                {
                    label: 'Бех комиссии переводы через систему быстрых платежей',
                    text: 'до 250 000 ₽/мес.',
                },
                {
                    label: 'Бех комиссии переводы через систему быстрых платежей',
                    text: 'до 300 000 ₽/мес.',
                },
            ],
        },
        {
            listCell: [
                {
                    label: '',
                    text: 'Специальные условия по Сберегательному счету, %годовых',
                },
                {
                    label: 'Специальные условия по Сберегательному счету, %годовых',
                    text: 'до 6,5% годовых',
                },
                {
                    label: 'Специальные условия по Сберегательному счету, %годовых',
                    text: 'до 6,5% годовых',
                },
                {
                    label: 'Специальные условия по Сберегательному счету, %годовых',
                    text: 'до 6,5% годовых',
                },
            ],
        },
    ],
    title: 'Тариф',
    isActive: true,
};
export const mockTableBlockDataCapital = {
    html:
        '<table><tbody><tr><td>Сумма вклада (₽)</td><td>181 день</td><td>367 дней</td><td>546 дней</td></tr>' +
        '<tr><td>50 000 – 99 999,99 руб.</td><td>4,05%</td><td>4,55%</td><td>4,8%</td></tr>' +
        '<tr><td>100 000 – 499 999,99 руб.</td><td>4,25%</td><td>4,75%</td><td>5%</td></tr>' +
        '<tr><td>500 000 – 2 999 999,99 руб.</td><td>4,25%</td><td>4,75%</td><td>5%</td></tr>' +
        '<tr><td>от 3 000 000 руб.</td><td>4,25%</td><td>4,75%</td><td>5%</td></tr></tbody></table>',
    title: 'Ставки по вкладу «Капитальный»',
};

export const mockTableBlockDataForPagePclient = {
    html:
        '<table><tbody><tr><td><div>Преимущества пакета</div></td><td><div>Серебряный</div></td><td>' +
        '<div>Золотой</div></td><td><div>Платиновый</div></td></tr><tr><td><div>Выпуск карты с индивидуальным дизайном</div></td>' +
        '<td><div>без взимания комиссии</div></td><td><div>без взимания комиссии</div></td><td><div>без взимания комиссии</div></td></tr>' +
        '<tr><td><div>СМС-информирование</div></td><td><div>без взимания комиссии</div></td><td><div>без взимания комиссии</div></td><td>' +
        '<div>без взимания комиссии</div></td></tr><tr><td><div>Повышенные лимиты на снятие наличных в любом банкомате</div></td>' +
        '<td><div>до 30 000 ₽/мес.</div></td><td><div>до 50 000 ₽/мес.</div></td><td><div>до 100 000 ₽/мес.</div></td></tr>' +
        '<tr><td><div>Бех комиссии переводы через систему быстрых платежей</div></td><td><div>до 200 000 ₽/мес.</div></td>' +
        '<td><div>до 250 000 ₽/мес.</div></td><td><div>до 300 000 ₽/мес.</div></td></tr>' +
        '<tr><td><div>Специальные условия по Сберегательному счету, %годовых</div></td>' +
        '<td><div>до 6,5% годовых</div></td><td><div>до 6,5% годовых</div></td>' +
        '<td><div>до 6,5% годовых</div></td></tr><tr><td><div>Специальные условия\n' +
        'по вкладам «Капитальный», «Накопительный», «Доходный»</div></td>' +
        '<td><div>+0,25% годовых дополнительно к ставке</div></td>' +
        '<td><div>+0,25% годовых дополнительно к ставке</div></td>' +
        '<td><div>+0,25% годовых дополнительно к ставке</div></td></tr>' +
        '<tr><td><div>Повышенные бонусы программы лояльности «Мультибонус»</div></td>' +
        '<td><div>1% на все покупки 3% в категориях «Товары для дома, ремонта» (суммируется с текущими предложениями).</div></td>' +
        '<td><div>1% на все покупки 3% в категориях «Товары для дома, ремонта» (суммируется с текущими предложениями).</div></td>' +
        '<td><div>1% на все покупки 3% в категориях «Товары для дома, ремонта» (суммируется с текущими предложениями).</div></td></tr>' +
        '<tr><td><div>MAX вознаграждение/мес.</div></td><td><div>10 000 бонусов</div></td><td><div>20 000 бонусов</div></td>' +
        '<td><div>30 000 бонусов</div></td></tr><tr><td><div>Стоимость пакета после 6 подряд оплаченных периодов, i/мес.</div></td>' +
        '<td><div>0</div></td><td><div>0</div></td><td><div>0</div></td></tr></tbody></table>',
    title: 'Тариф',
};

export const mockTableBlockTina = {
    html:
        '<table><tbody><tr><th>Заголовок</th><th>Заголовок</th><th>Заголовок</th></tr><tr>' +
        '<td>Заголовок</td><td>Заголовок</td><td>Заголовок</td></tr></tbody></table>',
    title: 'Заголовок',
};

export const mockTableBlockDataForPageLawyer = {
    html:
        '<table>' +
        '<tbody>' +
        '<tr><td><div>Тарифный план/Услуга</div></td><td><div>Стандарт</div></td><td><div>Семейный</div></td><td><div style="white-space: nowrap;">Предприниматель</div></td></tr>' +
        '<tr><td><div>Где можно оформить</div></td><td><div>Оформление в&nbsp;клиентских центрах и&nbsp;стойках продаж</div></td><td><div>Оформление в&nbsp;клиентских центрах и&nbsp;стойках продаж</div></td><td><div>Оформление в&nbsp;клиентских центрах и&nbsp;стойках продаж</div></td></tr>' +
        '<tr><td><div>Устная правовая консультация</div></td><td><div>Без ограничений</div></td><td><div>Без ограничений</div></td><td><div>Без ограничений</div></td></tr>' +
        '<tr><td><div>Устная правовая консультация по&nbsp;вопросам предпринимательской деятельности</div></td><td><div>Нет</div></td><td><div>Нет</div></td><td><div>Без ограничений</div></td></tr>' +
        '<tr><td><div>Доверь переговоры юристу</div></td><td><div>4&nbsp;раза за&nbsp;период использования</div></td><td><div>Без ограничений</div></td><td><div>Без ограничений</div></td></tr>' +
        '<tr><td><div>Предоставление типовых договоров и&nbsp;инструкций по&nbsp;их&nbsp;составлению</div></td><td><div>Без ограничений</div></td><td><div>Без ограничений</div></td><td><div>Без ограничений</div></td></tr>' +
        '<tr><td><div>Письменная консультация</div></td><td><div>1&nbsp;консультация за&nbsp;весь период использования</div></td><td><div>4&nbsp;консультации в&nbsp;год (не&nbsp;более одной в&nbsp;квартал)</div></td><td><div>4&nbsp;консультации в&nbsp;год</div></td></tr>' +
        '<tr><td><div>Письменное заключение по&nbsp;запуску бизнес-проекта</div></td><td><div>Нет</div></td><td><div>Нет</div></td><td><div>1&nbsp;заключение за&nbsp;весь период использования</div></td></tr>' +
        '<tr><td><div>Обзор изменений в&nbsp;законодательстве</div></td><td><div>Нет</div></td><td><div>Нет</div></td><td><div>До&nbsp;2-х отраслей права в&nbsp;месяц</div></td></tr>' +
        '<tr><td><div>Количество пользователей</div></td><td><div>1 человек</div></td><td><div>До 3-х человек</div></td><td><div>До 3-х человек</div></td></tr>' +
        '<tr><td><div>Скидка на&nbsp;доп. услуги Компании, не&nbsp;входящие в&nbsp;Тарифный план</div></td><td><div>10%</div></td><td><div>15%</div></td><td><div>10%</div></td></tr>' +
        '<tr><td><div>Стоимость услуг (стоимость актуальна при годовом контракте)</div></td><td><div>3 000 рублей</div></td><td><div>6 000 рублей</div></td><td><div>7 500 рублей</div></td></tr>' +
        '</tbody></table>',
    title: 'Тарифы',
};
