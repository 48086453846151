import React, { FC } from 'react';
import Tilt from 'react-tilt';

import CustomImage from '@/components/CustomImage';
import { useAppStore } from '@/context/AppStoreContext';

import cn from './style.module.sass';

interface IProps {
    bgLayer: string;
    imageLink?: string;
    infoLayer: string;
    alt: string;
}
const AnimatedCreditCard: FC<IProps> = ({ bgLayer, infoLayer, alt, imageLink }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    return (
        <div className={cn.wrapper}>
            <Tilt className={cn.card} options={{ max: 20, perspective: 1000 }}>
                <div className={cn.bg}>
                    {bgLayer && !imageLink && <CustomImage src={bgLayer} alt={alt} width={288} height={184} />}
                    {imageLink && !isTinaView && <CustomImage src={imageLink} alt={alt} width={288} height={184} />}
                    {imageLink && isTinaView && (
                        <div className="promoServiceDesc">
                            предпросмотр картинки с продуктовым параметром невозможен
                        </div>
                    )}
                </div>
                <div className={cn.info}>
                    {infoLayer && <CustomImage src={infoLayer} alt={alt} width={288} height={184} />}
                </div>
            </Tilt>
        </div>
    );
};

export default AnimatedCreditCard;
