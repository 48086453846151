import React from 'react';

import { TinaTitle } from '@/components/UI/Title/index.tina';

export const titleBlock = {
    Component: props => <TinaTitle {...props} />,
    template: {
        label: 'Заголовок',
        defaultItem: () => ({
            title: 'Заголовок',
            level: 1,
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
                required: true,
            },
            {
                name: 'level',
                component: 'select',
                label: 'Тип блока',
                required: true,
                options: [
                    { value: 1, label: 'H1' },
                    { value: 2, label: 'H2' },
                    { value: 3, label: 'H3' },
                    { value: 4, label: 'H4' },
                    { value: 5, label: 'H5' },
                    { value: 6, label: 'H6' },
                ],
            },
        ],
    },
};
