import React from 'react';

import { TinaGuitarBanner } from '@/components/blocks/banners/GuitarBanner/index.tina';
import { mockGuitarBannerBlockData } from '@/data/blocks/guitarBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const guitarBannerBlock = {
    Component: props => <TinaGuitarBanner {...props} />,
    template: {
        label: 'Баннер-аннонс',
        defaultItem: () => mockGuitarBannerBlockData,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'type',
                component: 'select',
                label: 'Тип ссылки',
                options: [
                    {
                        value: 'anchor',
                        label: 'Якорь',
                    },
                    {
                        value: 'link',
                        label: 'Обычная ссылка',
                    },
                ],
            },
            {
                name: 'link',
                component: 'text',
                label: 'Ссылка',
            },
            {
                name: 'desktopImage',
                component: 'image',
                label: 'Картинка для десктопа',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'mobileImage',
                component: 'image',
                label: 'Картинка для мобильных',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
