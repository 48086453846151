import React, { ReactNode } from 'react';

interface ITabs {
    className: string;
    onClick: (index: number) => void;
    index: number;
    children: ReactNode;
}
function Tabs({ onClick, className, index, children }: ITabs) {
    return (
        <div className={className} onClick={() => onClick(index)} role="presentation">
            {children}
        </div>
    );
}

export default Tabs;
