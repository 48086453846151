import React from 'react';

import { TinaArticlesListForDetail } from '@/components/blocks/ArticlesListForDetail/index.tina';
import { mockArticlesListForDetailTinaData } from '@/data/blocks/articlesListForDetail';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const articlesListForDetailBlock = {
    Component: props => <TinaArticlesListForDetail {...props} />,
    template: {
        label: 'Подборка статей для детальной страницы статьи',
        defaultItem: () => mockArticlesListForDetailTinaData,
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
            {
                name: 'section',
                component: 'section',
                withInfoBlock: true,
                label: 'Привязка к секции',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
