import React from 'react';

import { TinaTextBlock } from '@/components/blocks/TextBlock/index.tina';
import { mockTextBlockDataTina } from '@/data/blocks/textBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const textBlockBlock = {
    Component: props => <TinaTextBlock {...props} />,
    template: {
        label: 'SEO: Первый текст.',
        defaultItem: () => mockTextBlockDataTina,
        fields: [
            {
                name: 'source',
                component: 'select',
                label: 'Источник контента',
                description: 'При изменении параметров на странице шаблона, изменятся все зависимые страницы.',
                options: [
                    {
                        value: 'SEO_TEXT_FIRST',
                        label: '1-й Seo-Block',
                    },
                    {
                        value: 'SEO_TEXT_SECOND',
                        label: '2-й Seo-Block',
                    },
                    {
                        value: 'SEO_TEXT_THIRD',
                        label: '3-й Seo-Block',
                    },
                ],
            },
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                component: 'group',
                name: 'desc',
                label: 'Описание',
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        name: 'SEO_TEXT_FIRST',
                        label: '1-й Seo-Block',
                    },
                    {
                        component: 'textarea',
                        name: 'SEO_TEXT_SECOND',
                        label: '2-й Seo-Block',
                    },
                    {
                        component: 'textarea',
                        name: 'SEO_TEXT_THIRD',
                        label: '3-й Seo-Block',
                    },
                ],
            },
        ],
    },
};
