export const mockMobileAppBannerBlockData = {
    title: 'В магазине приложений RuStore',
    text: 'Наведите камеру на QR-код или скачайте по ссылке',
    img: '',
    buttons: [
        {
            link: '#',
            label: 'Скачать',
        },
    ],
};
