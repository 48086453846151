import React from 'react';

import cn from '@/components/UI/ShareIconBlock/style.module.sass';

function TwIconItem() {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8272 2.44165C17.1925 2.72005 16.5162 2.90458 15.8112 2.99418C16.5365 2.56112 17.0901 1.88058 17.3504 1.06032C16.6741 1.46352 15.9274 1.74832 15.1317 1.90725C14.4896 1.22352 13.5744 0.800049 12.576 0.800049C10.6389 0.800049 9.07943 2.37232 9.07943 4.29978C9.07943 4.57712 9.1029 4.84378 9.1605 5.09765C6.2517 4.95578 3.67783 3.56165 1.94876 1.43792C1.6469 1.96165 1.46983 2.56112 1.46983 3.20645C1.46983 4.41818 2.09383 5.49232 3.02396 6.11418C2.46183 6.10352 1.91036 5.94032 1.44316 5.68325C1.44316 5.69392 1.44316 5.70778 1.44316 5.72165C1.44316 7.42192 2.65596 8.83418 4.24636 9.15951C3.96156 9.23738 3.65116 9.27471 3.32903 9.27471C3.10503 9.27471 2.8789 9.26192 2.66663 9.21498C3.11996 10.6006 4.40636 11.6192 5.93596 11.6523C4.74556 12.5835 3.2341 13.1446 1.59783 13.1446C1.3109 13.1446 1.0357 13.1318 0.760498 13.0966C2.31036 14.096 4.14716 14.6667 6.12796 14.6667C12.5664 14.6667 16.0864 9.33338 16.0864 4.71045C16.0864 4.55578 16.081 4.40645 16.0736 4.25818C16.768 3.76538 17.3514 3.14992 17.8272 2.44165Z"
                className={cn.svgFillInner}
            />
        </svg>
    );
}

export default TwIconItem;
