export const termsElements = {
    label: 'Список элементов для Срока вклада',
    name: 'terms',
    component: 'group-list',
    itemProps: (item: { label: string; topLabel: string; id: number }, index: number) => ({
        key: index,
        label: item.label ? `${item.id}) ${item.label}` : 'Элемент',
    }),
    defaultItem: () => ({
        id: 115,
        label: '3 месяца',
        value: 3,
        ranges: [{ id: 4, min: 0, max: 1000000, percent: 20 }],
    }),
    fields: [
        {
            name: 'id',
            component: 'number',
            label: 'Номер - ID - обязательно уникальное цифровое значение для элемента в массиве (элементы с совпадающими ID автоматически не выводятся)',
        },
        {
            name: 'topLabel',
            component: 'text',
            label: 'Подпись над полем',
        },
        {
            name: 'label',
            component: 'text',
            label: 'Название',
        },
        {
            name: 'value',
            component: 'number',
            label: 'Значение',
        },
        {
            name: 'ranges',
            component: 'group-list',
            label: 'Диапазон значений процентной ставки',
            itemProps: (item: { id: number; percent: number }, innerIndex: number) => ({
                key: innerIndex,
                label: item.percent ? `${item.id}) ${item.percent}%` : 'Элемент',
            }),
            defaultItem: () => ({ id: 435, min: 0, max: 1000000, percent: 20 }),
            fields: [
                {
                    name: 'id',
                    component: 'number',
                    label: 'Номер - ID - обязательно уникальное цифровое значение для элемента в массиве (элементы с совпадающими ID автоматически не выводятся)',
                },
                {
                    name: 'min',
                    component: 'number',
                    label: 'Минимальный порог активации процента',
                },
                {
                    name: 'max',
                    component: 'number',
                    label: 'Максимальный порог активации процента',
                },
                {
                    name: 'percent',
                    component: 'number',
                    label: 'Процент',
                },
            ],
        },
    ],
};
