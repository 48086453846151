import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IIndication {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'indication';
    title?: string;
    rows: IIndicationRow[];
}
interface IIndicationRow {
    name?: string;
    list: IIndicationItem[];
}
interface IIndicationItem {
    year: string;
    numbers: string;
}

const Indication: React.FC<IIndication> = ({ title, rows }) => (
    <AnimatedComponent className={clsx(cn.indication, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.indicationWrap}>
            <TextField
                text={title}
                name="title"
                customTag="h3"
                className={cn.indicationTitle}
                isHTML
                htmlValue={title}
            />
            {rows?.length > 0 &&
                rows?.map(({ name, list }, rowIndex) => (
                    <div className={cn.rows} key={rowIndex}>
                        <TextField
                            text={name}
                            name={`rows.${rowIndex}.name`}
                            className={cn.name}
                            isHTML
                            htmlValue={name}
                        />
                        {list?.length > 0 && (
                            <div className={cn.wrap}>
                                <div className={cn.grid}>
                                    {list?.map(({ year, numbers }, listIndex) => (
                                        <div className={cn.item} key={listIndex} data-length={list.length}>
                                            <div className={cn.decor}>
                                                <i />
                                            </div>
                                            <TextField
                                                text={year}
                                                name={`rows.${rowIndex}.list.${listIndex}.year`}
                                                className={cn.year}
                                            />
                                            <TextField
                                                text={numbers}
                                                name={`rows.${rowIndex}.list.${listIndex}.numbers`}
                                                className={cn.numbers}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    </AnimatedComponent>
);

export default withBlockLinks(Indication);
