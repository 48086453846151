export const educationCalculatorData = {
    title: 'Предварительный расчет',
    valueRate: 8.99,
    filterElements: [
        {
            value: 50000,
            step: 5000,
            percent: 0,
            label: '50 тыс',
            id: 1,
        },
        {
            value: 250000,
            step: 5000,
            percent: 16.6,
            label: '250 тыс',
            id: 2,
        },
        {
            value: 500000,
            step: 5000,
            percent: 33.2,
            label: '500 тыс',
            id: 3,
        },
        {
            value: 1000000,
            step: 5000,
            percent: 50,
            label: '1 млн.',
            id: 4,
        },
        {
            value: 1500000,
            step: 5000,
            percent: 75,
            label: '1,5 млн.',
            id: 5,
        },
        {
            value: 2000000,
            step: 5000,
            percent: 100,
            label: '2 млн.',
            id: 6,
        },
    ],
    terms: [
        {
            id: 1,
            label: '1 год',
            value: 1,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 38.1853177079737,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 38.185317708,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 37.5048604181,
                },
            ],
        },
        {
            id: 2,
            label: '2 года',
            value: 2,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 29.9469781389227,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 29.9469781389,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 29.1608666805,
                },
            ],
        },
        {
            id: 3,
            label: '3 года',
            value: 3,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 24.6773644878684,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 24.6773644879,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 23.9416492058,
                },
            ],
        },
        {
            id: 4,
            label: '4 года',
            value: 4,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 24.6773644878684,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 24.6773644879,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 23.9416492058,
                },
            ],
        },
        {
            id: 5,
            label: '5 лет',
            value: 5,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 24.6773644878684,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 24.6773644879,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 23.9416492058,
                },
            ],
        },
        {
            id: 6,
            label: '6 лет',
            value: 6,
            ranges: [
                {
                    id: 1,
                    percent: 9.9,
                    min: 20000,
                    max: 499999,
                    balancePercent: 24.6773644878684,
                },
                {
                    id: 2,
                    percent: 9.9,
                    min: 500000,
                    max: 999999,
                    balancePercent: 21.0729371986,
                },
                {
                    id: 3,
                    percent: 9.9,
                    min: 1000000,
                    max: 6000000,
                    balancePercent: 20.4700559128,
                },
            ],
        },
    ],
};
