export const mockInstructionSliderBlockData = {
    name: 'InstructionSlider',
    items: [
        {
            image: '/img-next/png/instructionSlider/1.png',
            text: '<p>Зайдите на сайт <a href="https://www.banki.ru/" target="_blank"> banki.ru</a></p>',
        },
        {
            image: '/img-next/png/instructionSlider/2.png',
            text: '<p>Нажмите на иконку личного кабинета</p>',
        },
        {
            image: '/img-next/png/instructionSlider/3.png',
            text: '<p>Заполните форму регистрации</p>',
        },
        {
            image: '/img-next/png/instructionSlider/4.png',
            text: '<p>Получите письмо для подтверждения регистрации и перейдите по ссылке для установления пароля</p>',
        },
    ],
    button: { link: 'http://www.banki.ru/auth/?register=yes', label: 'Перейти на banki.ru' },
};

export const mockInstructionSliderBlockDataTina = {
    name: 'InstructionSlider',
    items: [
        {
            image: '',
            text: 'Текст',
        },
    ],
    button: { link: 'Ссылка', label: 'Заголовок' },
};
