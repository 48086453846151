import React from 'react';

import { TinaWidgetPoll } from '@/components/blocks/WidgetPoll/index.tina';
import { mockWidgetPollBlockDataTina } from '@/data/blocks/widgetPoll';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const widgetPollBlock = {
    Component: props => <TinaWidgetPoll {...props} />,
    template: {
        label: 'Блок Виджет для опросов на сайте',
        defaultItem: () => mockWidgetPollBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Название элемента/Заголовок',
                required: true,
            },

            {
                label: 'Список вопросов',
                component: 'group-list',
                name: 'list',
                itemProps: (item: { questionName: string }, index: number) => ({
                    key: index,
                    label: item?.questionName || 'Заголовок',
                    title: item?.questionName || 'Заголовок',
                }),
                defaultItem: () => mockWidgetPollBlockDataTina.list[0],
                fields: [
                    {
                        name: 'questionName',
                        component: HtmlEditorFeatured,
                        label: 'Введите вопрос',
                    },
                    {
                        name: 'typeAnswer',
                        component: 'select',
                        label: 'Тип ответа на вопрос',
                        options: [
                            { value: 'emoji', label: 'Оценка (да/нет)' },
                            { value: 'rating', label: 'Оценка по шкале (1-10)' },
                            { value: 'comment', label: 'Текстовый ответ' },
                            { value: 'one', label: 'Варианты ответа с выбором одного' },
                            { value: 'many', label: 'Варианты ответа с выбором нескольких' },
                        ],
                    },
                    {
                        name: 'isAddTextArea',
                        component: 'toggle',
                        label: 'Добавить дополнительно текстовое поле',
                    },
                    {
                        label: 'Если вы выбрали Оценка (да/нет)',
                        name: 'emojiList',
                        component: 'group',
                        fields: [
                            {
                                name: 'emojiYes',
                                component: HtmlEditorFeatured,
                                label: 'Описание для положительного варианта',
                            },
                            {
                                name: 'emojiNo',
                                component: HtmlEditorFeatured,
                                label: 'Описание для отрицательного варианта',
                            },
                        ],
                    },
                    {
                        label: 'Если вы выбрали Варианты ответа с выбором одного или нескольких - добавьте варианты',
                        component: 'group-list',
                        name: 'answerList',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                        }),
                        fields: [
                            {
                                label: 'Название варианта ответа',
                                component: HtmlEditorFeatured,
                                name: 'title',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
