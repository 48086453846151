export const mockWidgetBlockData = {
    titleBlock: 'widget',
    top: {
        title: 'Кредит наличными 4%',
        shortTitle: 'Кредит от 4%',
        desc: 'в ближайшем отделении Почты',
    },
    image: '/img-next/png/widgetHead.png',
    isSetImage: true,
    mark: '<p>*До 30 млн. рублей</p><p>*Онлайн-заявка за 2 минуты</p>',
    filterElements: [
        {
            value: 10000,
            step: 1000,
            percent: 0,
            label: '10 тыс.',
            id: 1,
        },
        {
            value: 30000,
            step: 10000,
            percent: 16,
            label: '',
            id: 2,
        },
        {
            value: 300000,
            step: 10000,
            percent: 32,
            label: '',
            id: 3,
        },
        {
            value: 500000,
            step: 50000,
            percent: 48,
            label: '',
            id: 4,
        },
        {
            value: 1000000,
            step: 100000,
            percent: 64,
            label: '',
            id: 5,
        },
        {
            value: 2000000,
            step: 100000,
            percent: 80,
            label: '',
            id: 6,
        },
        {
            value: 5000000,
            step: 100000,
            percent: 100,
            label: '5 млн.',
            id: 7,
        },
    ],
    termsInfo: {
        firstValue: 1,
        firstLabel: '1 год',
        lastValue: 7,
        lastLabel: '7 лет',
        step: 1,
    },
};

export const mockWidgetBlockDataTina = {
    titleBlock: 'widget',
    top: {
        title: 'Длинный заголовок',
        shortTitle: 'Короткий заголовок',
        desc: 'Описание',
    },
    image: '/img-next/png/widgetHead.png',
    isSetImage: true,
    mark: 'Описание',
    sumInfo: {
        firstValue: 10000,
        firstLabel: '10 тыс.',
        lastValue: 5000000,
        lastLabel: '5 млн.',
        step: 500000,
    },
    termsInfo: {
        firstValue: 1,
        firstLabel: '1 год',
        lastValue: 7,
        lastLabel: '7 лет',
        step: 1,
    },
};
