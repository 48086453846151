import clsx from 'clsx';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import CustomImage from '@/components/CustomImage';
import { LinkIcon } from '@/components/UI/LinkIcon';
import { IActionsSliderItem } from '@/types/actionsSlider';

import cn from './style.module.sass';

const ActionsSliderItem: React.FC<IActionsSliderItem> = observer(
    ({ name, mechanicsDescription, mechanicsDescriptionSrc, linkTo, endedAt, bgColor = 'default', conditionsSrc }) => {
        const [subtitle, setSubtitle] = useState('');

        useEffect(() => {
            if (mechanicsDescription?.length <= 0) return;
            if (mechanicsDescription?.length > 200) {
                const desc = mechanicsDescription?.slice(0, 200).concat('...');
                setSubtitle(desc);
            } else {
                setSubtitle(mechanicsDescription);
            }
        }, [mechanicsDescription]);

        return (
            <div className={clsx(cn.currentItem, 'currentItem')}>
                <div className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}>
                    <div
                        className={clsx(cn.header)}
                        style={{
                            background: mechanicsDescriptionSrc
                                ? `url(${conditionsSrc}) center right 35%/cover no-repeat`
                                : null,
                        }}
                    >
                        <div className={cn.headerWrapper}>
                            <div className={cn.headerLeft}>
                                <div className={cn.headerTitle}>{name}</div>
                            </div>
                            <div className={cn.headerRight} />
                        </div>
                        <div className={clsx(cn.headerWrapper, cn.headerWrapperBottom)}>
                            <div className={clsx(cn.headerLeft, cn.headerInnerWrapper)}>
                                <div className={cn.headerItem}>
                                    <CustomImage
                                        src="/img-next/svg/logo/logo-privet.svg"
                                        alt={name}
                                        width="60"
                                        height="20"
                                    />
                                </div>
                                <div className={cn.headerItem}>
                                    <CustomImage src="/img-next/svg/logo/logo.svg" alt={name} width="42" height="20" />
                                </div>
                            </div>
                            <div className={cn.headerRight}>
                                <div className={cn.infoDesc}>
                                    Действует до {format(new Date(endedAt), 'PPP', { locale: ru })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cn.currentContent}>
                        <div className={cn.currentTop}>
                            <a href={linkTo} className={cn.currentTitle}>
                                <span>{name}</span>
                            </a>
                            <div className={cn.currentSubtitle}>{subtitle}</div>
                        </div>
                        <div className={cn.currentBot}>
                            <LinkIcon href={linkTo} noPadding label="Читать" />
                        </div>
                        <div className={cn.currentBotDecor} />
                    </div>
                </div>
            </div>
        );
    }
);

export default ActionsSliderItem;
