import React from 'react';

import cn from './style.module.sass';

const IconTime: React.FC = () => (
    <div className={cn.icon}>
        <svg
            className={cn.svg}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={cn.svgStroke}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.035 20C4.49107 20 0 15.5089 0 10.035C0 4.49107 4.49107 0 10.035 0C15.5086 0 20 4.49107 20 10.035C20 15.5086 15.5089 20 10.035 20ZM13.8946 9.54393H10.6665V4.63179C10.6665 3.22832 8.63139 3.22832 8.63139 4.63179V10.5264C8.63139 11.158 9.05243 11.579 9.61382 11.579H13.8945C15.2278 11.579 15.2278 9.54389 13.8945 9.54389L13.8946 9.54393Z"
                fill="#67798E"
            />
        </svg>
    </div>
);

export default IconTime;
