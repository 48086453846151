export const mockTariffsRestrictionsBlockData = {
    title: 'Тарифы и&nbsp;ограничения',
    list: [
        {
            itemValue: 'Приобрести ОФЗ-н могут только совершеннолетние граждане Российской Федерации (физические лица)',
        },
        {
            itemValue:
                'Нельзя перевести для депозитарного учета ценные бумаги в&nbsp;иной уполномоченный Банк, иной депозитарий',
        },
        {
            itemValue:
                'Нельзя продавать ОФЗ-н другому физическому лицу или юридическому лицу, кроме банка-агента, через который ОФЗ-н покупались',
        },
        {
            itemValue:
                'ОФЗ-н не&nbsp;должны использоваться в&nbsp;качестве обеспечения маржинального кредитования или быть предметом залога',
        },
    ],
};

export const mockTariffsRestrictionsBlockTinaData = {
    section: null,
    _template: 'tariffsRestrictions',
    title: 'Заголовок',
    list: [
        {
            itemValue: 'Описание',
        },
        {
            itemValue: 'Описание',
        },
        {
            itemValue: 'Описание',
        },
        {
            itemValue: 'Описание',
        },
    ],
};
