import React from 'react';

import { TinaInfoBlockCredit } from '@/components/blocks/InfoBlockCredit/index.tina';
import { mockInfoBlockCreditBlockData } from '@/data/blocks/infoBlockCredit';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const infoBlockCreditBlock = {
    Component: props => <TinaInfoBlockCredit {...props} />,
    template: {
        label: 'Информационный блок КН 1',
        defaultItem: () => mockInfoBlockCreditBlockData,
        fields: [
            {
                name: 'backgroundImg',
                component: 'image',
                label: 'Фон',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
        ],
    },
};
