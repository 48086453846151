import React from 'react';

import { TinaInfoCard } from '@/components/blocks/infoCard/InfoCard/index.tina';
import { mockInfoCardBlockTina } from '@/data/blocks/infoCard';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoCardBlock = {
    Component: props => <TinaInfoCard {...props} />,
    template: {
        label: 'Карточки с информацией',
        defaultItem: () => mockInfoCardBlockTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockInfoCardBlockTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageLink',
                        component: 'text',
                        label: 'Название продуктового параметра или ссылка на изображение',
                    },
                    {
                        name: 'isActual',
                        component: 'toggle',
                        label: 'Актуально',
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockInfoCardBlockTina.list[0].info[0],
                        fields: [
                            {
                                name: 'title',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                            {
                                name: 'subtitle',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'buttonBg',
                        component: 'group',
                        label: 'Кнопка. Главная',
                        fields: [
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Название',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'rel',
                                component: 'text',
                                label: 'Атрибут rel',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                    {
                        label: 'Кнопка. Побочная',
                        name: 'buttonDetail',
                        component: 'group',
                        fields: [
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Название',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                ],
            },
        ],
    },
};
