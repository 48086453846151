import React from 'react';

import { TinaWorkRemotelyLanding } from '@/components/blocks/WorkRemotely/index.tina';

export const workRemotelyLandingBlock = {
    Component: props => <TinaWorkRemotelyLanding {...props} />,
    template: {
        label: 'Работа Удаленно лендинг',
        fields: [],
    },
};
