import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IForestMap {
    _template?: 'forestMap';
}

const ForestMapContent = dynamic(async () => import('./ForestMapContent'), { ssr: false });

const ForestMap: React.FC<IForestMap> = () => (
    <div className="section">
        <ForestMapContent />
    </div>
);

export default withBlockLinks(ForestMap);
