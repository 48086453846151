export const hrAchievementsList = [
    {
        img: '/img-next/png/hr-landing/push/phone.png',
        title: 'Мобильное приложение Почта Банка',
        description:
            '<ul><li><span>входит в топ-5 лучших банковских сервисов</span> по версии Go Mobile 2022</li><li>7-е место в рейтинге Markswebb</li></ul>',
    },
    {
        img: '/img-next/png/hr-landing/push/p-card.png',
        title: '«Пушкинская карта»',
        description:
            'В 2022 Пушкинская карта победила в номинации <span>«Лучший финансовый продукт»</span> по версии FINAWARD22',
    },
];
