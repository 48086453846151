import React from 'react';

import { TinaBankText } from '@/components/blocks/BankText/index.tina';
import { mockBankTextBlockData } from '@/data/blocks/bankText';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const bankTextBlock = {
    Component: props => <TinaBankText {...props} />,
    template: {
        label: 'HR: Блок с текстом',
        defaultItem: () => mockBankTextBlockData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
        ],
    },
};
