import dynamic from 'next/dynamic';
import { useParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { withComponentView } from '@/components/HOC/withComponentView';
import { withExternalFrameView } from '@/components/HOC/withExternalFrameView';
import { IStaticCityResponse } from '@/types/city/city';
import { apiRequest } from '@/utils/request';

export interface IMapContainer {
    _template?: 'map';
}

const MapContainerContent = dynamic(async () => import('./MapContainerContent'), { ssr: false });

const MapContainer: React.FC<IMapContainer> = () => {
    const [showScript, setShowScript] = useState(false);
    const [isFetch, setIsFetch] = useState(false);

    const params = useParams<{ slug: string[] }>();
    const [currentCoordinates, setCurrentCoordinates] = useState<number[]>([]);

    const fetchInfoCity = async () => {
        if (!params.slug) return;

        const staticCity = params.slug[params.slug.length - 1];

        try {
            const {
                data: { data },
            } = await apiRequest.get<IStaticCityResponse>(`/city/coordinates?city=${staticCity}`);

            if (data.city) {
                setCurrentCoordinates([data.latitude, data.longitude]);
            }
        } catch (e) {
            console.log('Error:::', e);
        }

        setIsFetch(true);
    };

    useEffect(() => {
        fetchInfoCity().catch(e => console.log(e));
    }, []);

    useEffect(() => {
        if (showScript) return;

        if (isFetch) {
            setShowScript(true);
        }
    }, [isFetch]);

    return <div>{showScript && <MapContainerContent currentCoordinates={currentCoordinates} />}</div>;
};

export default withBlockLinks(withComponentView(withExternalFrameView(MapContainer)));
