import React from 'react';

import { TinaTopChart } from '@/components/blocks/TopChart/index.tina';
import { mockTopChartBlockDataTina } from '@/data/blocks/topChart';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const topChartBlock = {
    Component: props => <TinaTopChart {...props} />,
    template: {
        label: 'ТОП - 5/10',
        defaultItem: () => mockTopChartBlockDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item?.name || 'Элемент списка',
                }),
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'verticalImagePosition',
                        component: 'select',
                        label: 'Позиция изображения по вертикали',
                        options: [
                            { value: 'center', label: 'Центр' },
                            { value: 'top', label: 'Верх' },
                            { value: 'bottom', label: 'Низ' },
                        ],
                    },
                    {
                        name: 'topText',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'source',
                        component: HtmlEditorFeatured,
                        label: 'Источник',
                    },
                ],
            },
        ],
    },
};
