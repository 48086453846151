import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IOnlineStorePartners {
    _template: 'onlineStorePartners';
    title: string;
}

const OnlineStorePartnersContent = dynamic(async () => import('./OnlineStorePartnersContent'), {
    ssr: false,
});

const OnlineStorePartners: React.FC<IOnlineStorePartners & { index: number }> = ({ title }) => (
    <div className="section">
        <OnlineStorePartnersContent title={title} />
    </div>
);

export default withBlockLinks(OnlineStorePartners);
