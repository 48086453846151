export const mockLinksBlockData = {
    name: 'Важно знать',
    list: [
        {
            title: 'Задайте вопрос',
            subtitle: 'или оставьте отзыв',
            link: '#',
            image: '/img-next/png/links/1.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: '+7 495 532 1300',
            subtitle: 'Обратный звонок',
            link: 'tel:+74955321300',
            image: '/img-next/png/links/2.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заказать продукт',
            subtitle: 'Карта, кредит или вклад',
            link: '#',
            image: '/img-next/png/links/3.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};

export const mockLinksBlockDataContacts = {
    name: '',
    list: [
        {
            title: 'Помощь клиентам в период пандемии',
            subtitle: '',
            link: 'https://www.pochtabank.ru/pandemic',
            image: '/img-next/png/links/2.png',
            button: {
                title: 'Подробнее',
                link: 'https://www.pochtabank.ru/pandemic',
            },
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Офисы и банкоматы',
            subtitle: '',
            link: 'https://www.pochtabank.ru/map',
            button: {
                title: '',
                link: '',
            },
            image: '/img-next/png/links/3.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};

export const mockLinksBlockTinaData = {
    section: null,
    name: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};
