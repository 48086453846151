export const mockTopChartBlockData = {
    list: [
        {
            name: 'topChart10',
            image: '/img-next/png/topChart.png',
            verticalImagePosition: 'bottom',
            topText: 'ТОП-10',
            desc: 'мобильных приложений для ежедневного банкинга',
            source: 'Mobile Banking Rank 2022',
        },
        {
            name: 'topChart5',
            image: '/img-next/png/services/bank.png',
            topText: 'ТОП-5',
            desc: 'банковских веб-приложений',
            source: 'Mobile Banking Rank 2023',
        },
    ],
};

export const mockTopChartBlockDataTina = {
    list: [
        {
            id: null,
            name: 'topChart5',
            image: '',
            verticalImagePosition: 'bottom',
            topText: 'Заголовок',
            desc: 'Текст',
            source: 'Источник',
        },
        {
            id: null,
            name: 'topChart10',
            image: '',
            topText: 'Заголовок',
            desc: 'Текст',
            source: 'Источник',
        },
    ],
};
