import React from 'react';

import { TinaColoredBanner } from '@/components/blocks/banners/ColoredBanner/index.tina';
import { mockColoredBannerBlockDataTina } from '@/data/blocks/coloredBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const coloredBannerBlock = {
    Component: props => <TinaColoredBanner {...props} />,
    template: {
        label: 'Цветной баннер',
        defaultItem: () => mockColoredBannerBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
                validate: (value: string) => {
                    if (!value) {
                        return 'Это поле обязательно для заполнения';
                    }
                    return null;
                },
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
                required: true,
                validate: (value: string) => {
                    if (!value) {
                        return 'Это поле обязательно для заполнения';
                    }
                    return null;
                },
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
                required: true,
                validate: (value: string) => {
                    if (!value) {
                        return 'Это поле обязательно для заполнения, добавьте хотя бы пробел :)';
                    }
                    return null;
                },
            },
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },

            {
                name: 'link',
                component: 'group',
                label: 'Ссылка',
                fields: [
                    {
                        name: 'type',
                        component: 'select',
                        label: 'Тип элемента',
                        options: [
                            { value: 'anchor', label: 'Якорь' },
                            { value: 'link', label: 'Ссылка' },
                        ],
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
