import React from 'react';

import { TinaOffersCredits } from '@/components/blocks/OffersCredits/index.tina';
import { mockOffersCreditsBlockDataTina } from '@/data/blocks/offersCredits';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const offersCreditsBlock = {
    Component: props => <TinaOffersCredits {...props} />,
    template: {
        label: 'Перелинковка на другие предложения',
        defaultItem: () => mockOffersCreditsBlockDataTina,
        fields: [
            {
                name: 'list',
                component: 'group-list',
                label: 'Список предложений',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockOffersCreditsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'categories',
                        component: 'group-list',
                        label: 'Список категорий',
                        itemProps: (item: { name: string }, index: number) => ({
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => mockOffersCreditsBlockDataTina.list[0].categories[0],
                        fields: [
                            {
                                name: 'name',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                            {
                                name: 'categoriesList',
                                component: 'group-list',
                                label: 'Список субкатегорий',
                                itemProps: (item: { text: string }, index: number) => ({
                                    key: index,
                                    label: item.text,
                                }),
                                defaultItem: () =>
                                    mockOffersCreditsBlockDataTina.list[0].categories[0].categoriesList[0],
                                fields: [
                                    {
                                        name: 'text',
                                        component: HtmlEditorFeatured,
                                        label: 'Заголовок',
                                    },
                                    {
                                        name: 'link',
                                        component: 'text',
                                        label: 'Ссылка',
                                    },
                                ],
                            },
                        ],
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
