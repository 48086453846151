export const mockRefinanceAdvantagesBlockData = {
    title: 'Ваша выгода при рефинансировании в Почта Банке',
    list: [
        {
            image: '/img-next/png/pens5.png',
            title: 'Ваши кредиты в других банках',
            firstText: 'первый текст',
            secondText: 'второй текст',
            thirdText: 'третий текст',
            fourthText: 'четвертый текст',
        },
        {
            image: '/img-next/png/pens5.png',
            title: 'Ваши кредиты в других банках',
            firstText: 'первый текст',
            secondText: 'второй текст',
            thirdText: 'третий текст',
            fourthText: 'четвертый текст',
        },
    ],
};
