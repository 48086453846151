import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IStudyCalculatorStore } from '@/domain/calculator/StudyCalculatorStore';

const BottomResults = observer(() => {
    const { getValue } = useContext<IStudyCalculatorStore>(CalculatorStoreContext);
    return (
        <div className="education-right">
            <div className="chart-count">
                <div className="count-case">
                    <div className="count-value">{getValue('payment')}</div>
                    <div className="count-note">платеж</div>
                </div>
                <div className="count-case">
                    <div className="count-value">{getValue('repaymentOfPrincipal')}</div>
                    <div className="count-note">погашение основного долга</div>
                </div>
                <div className="count-case">
                    <div className="count-value">{getValue('repaymentOfAccruedInterest')}</div>
                    <div className="count-note">погашение начисленных процентов</div>
                </div>
                <div className="count-case">
                    <div className="count-value">{getValue('balanceOfLoanDebt')}</div>
                    <div className="count-note">остаток долга по кредиту</div>
                </div>
            </div>
        </div>
    );
});

export default BottomResults;
