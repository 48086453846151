import React from 'react';

import { TinaBuildingCalculatorWithPicture } from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/index.tina';
import { buildingCalculatorWithPicture } from '@/data/blocks/calculators/buildingCalculatorWithPicture';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const buildingCalculatorWithPictureBlock = {
    Component: props => <TinaBuildingCalculatorWithPicture {...props} />,
    template: {
        label: 'Калькулятор кредита на строительство с изображением',
        defaultItem: buildingCalculatorWithPicture,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
