import Image from 'next/image';
import React from 'react';

import cn from './style.module.sass';

const partnerItems = [
    { img: '/img-next/png/buildingCalculator/1.png', link: '', desc: '' },
    { img: '/img-next/png/buildingCalculator/2.png', link: '', desc: '' },
    { img: '/img-next/png/buildingCalculator/3.png', link: '', desc: '' },
    { img: '/img-next/png/buildingCalculator/4.png', link: '', desc: '' },
    { img: '/img-next/png/buildingCalculator/5.png', link: '', desc: '' },
];

function Partners() {
    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>Партнеры, участвующие в акции</div>
            <div className={cn.box}>
                {partnerItems?.map((item, idx) => (
                    <div key={idx.toString()} className={cn.imgWrap}>
                        {item.link ? (
                            <a href={item.link}>
                                <Image src={item.img} width={80} height={80} alt="Партнеры" />
                            </a>
                        ) : (
                            <Image src={item.img} width={80} height={80} alt="Партнеры" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Partners;
