import React from 'react';

import { TinaSberschetOpen } from '@/components/blocks/frames/SberschetOpen/index.tina';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

export const sberschetOpenBlock = {
    Component: (props: IBlockProps) => <TinaSberschetOpen {...props} />,
    template: {
        label: 'Онлайн заявка на открытие сберегательного счета',
        defaultItem: () => ({
            title: 'Заголовок',
            gtagClick: {
                action: 'form_send',
                category: 'conversions',
                label: 'vklad_sber',
                event: 'universalEvent',
                orderid2: 'weedfrerrgt45437',
            },
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            { ...gtagSingleItemCustom('gtagClick', 'Цель на успешную отправку') },
        ],
    },
};
