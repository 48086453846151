import clsx from 'clsx';
import React, { ReactNode } from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface IProps {
    title: string;
    desc: string;
    subDesc?: string;
    img: string;
    isPWA: boolean;
    children: ReactNode;
}
const CreditCardContent: React.FC<IProps> = ({ title, desc, subDesc, img, isPWA = false, children }) => (
    <>
        <div className={cn.content}>
            <div className={clsx(cn.title, subDesc && cn.smMargin, isPWA && cn.pwa)}>{title}</div>
            <div
                className={clsx(cn.desc, subDesc && cn.smMargin, isPWA && cn.pwa)}
                dangerouslySetInnerHTML={{ __html: desc }}
            />
        </div>
        <div className={clsx(cn.decor, isPWA && cn.pwa)}>
            <CustomImage src={img} alt={title} fill sizes="100vw" />
        </div>
        {subDesc && <div className={cn.subDesc} dangerouslySetInnerHTML={{ __html: subDesc }} />}
        {children}
    </>
);

export default CreditCardContent;
