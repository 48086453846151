/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useFixHtml } from '@/hooks/useFixHtml';
import { IInfoItem } from '@/types/info/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import FullScreenTolltip from '../../FullScreenTolltip';
import cn from './style.module.sass';

type WrapperTag = 'div' | 'a';

const InfoInnerItem: React.FC<IInfoItem> = ({
    pos,
    length,
    title,
    link,
    subtitle,
    image,
    gtag,
    linkHref,
    linkName,
    markContent,
    bigIcon,
    topText,
    size = 'small',
    bg,
}) => {
    const [showToolTip, setShowTooltip] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();

    let WrapElement = 'div' as WrapperTag;
    let attributes = {};

    if (link) {
        WrapElement = 'a';
        attributes = { href: link };
    }

    const show = () => {
        fix();
        setShowTooltip(true);
    };

    const hide = () => {
        unfix();
        setShowTooltip(false);
    };

    return (
        <AnimatedComponent
            className={clsx(
                cn.infoItem,
                (size === 'small' || !size) && cn.infoItem4,
                size === 'middle' && cn.infoItem6,
                size === 'big' && cn.infoItem8,
                size === 'full' && cn.infoItem12
            )}
            classNameActive={cn.animationInit}
            data-length={length}
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
            role="presentation"
        >
            <div
                className={clsx(cn.infoItemWrap, link && cn.link)}
                style={{
                    background: bg || null,
                }}
            >
                <div className={cn.tooltip}>
                    {markContent?.length > 0 && (
                        <div onClick={show}>
                            <TooltipIcon />
                        </div>
                    )}
                </div>
                <div className={clsx(cn.infoTop, bigIcon && cn.bigIcon)}>
                    <div className={cn.infoImage}>
                        <CustomImage src={image} alt={title} mockWidth="60" mockHeight="60" fill />
                    </div>
                </div>
                <div className={cn.infoBot}>
                    <div className={cn.infoContent}>
                        {topText && (
                            <TextField
                                className={cn.infoTopText}
                                text={topText}
                                name={`list.[${pos}].topText`}
                                isHTML
                                htmlValue={topText}
                            />
                        )}

                        <WrapElement {...attributes}>
                            <TextField
                                className={cn.infoTitle}
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                            />
                        </WrapElement>
                        <TextField
                            className={cn.infoSubtitle}
                            text={subtitle}
                            name={`list.[${pos}].subtitle`}
                            isHTML
                            htmlValue={subtitle}
                        />
                        <div className={cn.infoLinks}>
                            {linkName && (
                                <a href={linkHref} className={cn.infoLink}>
                                    {linkName}
                                </a>
                            )}
                        </div>
                    </div>
                    <FullScreenTolltip handleClose={hide} isOpen={showToolTip} tooltipText={markContent} />
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(InfoInnerItem);
