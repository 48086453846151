import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { useTheme } from '@/context/ThemeProvider';
import { IPartnersItem } from '@/types/partners/item';

import cn from './style.module.sass';

const PartnersItem: React.FC<IPartnersItem & { type: 'col-3' | 'col-4' | 'col-5' }> = ({
    title,
    image,
    imageDarkTheme,
    link,
    pos,
    customClass,
    type = 'col-3',
    size,
}) => {
    // theme нужен для опредения цвета логотипа
    const { theme } = useTheme();
    return (
        <AnimatedComponent
            className={clsx(
                cn.item,
                customClass,
                type === 'col-3' && cn.col3,
                type === 'col-4' && cn.col4,
                type === 'col-5' && cn.col5,
                size === 'small' && cn.itemSmall
            )}
            classNameActive={cn.animationInit}
        >
            <a href={link} className={cn.itemLink} rel="nofollow">
                <div className={clsx(cn.itemImg, title && cn.itemImgDecor)}>
                    <div className={cn.itemImgWrap}>
                        <CustomImage
                            src={theme === 'dark' ? imageDarkTheme : image}
                            alt={title}
                            fill
                            mockWidth="190"
                            mockHeight="120"
                        />
                    </div>
                </div>
                {title && (
                    <TextField
                        className={cn.itemName}
                        text={title}
                        name={`list.[${pos}].title`}
                        isHTML
                        htmlValue={title}
                    />
                )}
            </a>
        </AnimatedComponent>
    );
};

export default PartnersItem;
