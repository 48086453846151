import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IPensionTransfer {
    _template: 'pensionTransfer';
    title: string;
}

const PensionTransferContent = dynamic(async () => import('./PensionTransferContent'), {
    ssr: false,
});

const PensionTransfer: React.FC<IPensionTransfer & { index: number }> = ({ index, title }) => (
    <div className="section">
        <PensionTransferContent title={title} index={index} />
    </div>
);

export default withBlockLinks(PensionTransfer);
