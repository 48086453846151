import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';

import CreditCalculatorContent from '@/components/blocks/calculators/layouts/CreditCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import CreditCalculatorStore from '@/domain/calculator/CreditCalculatorStore';
import { ICreditCalculator } from '@/types/calculator/creditCalculator';

const CreditCalculator: React.FC<ICreditCalculator> = observer(({ title, fields, gtag, button, rate }) => {
    const CreditCalculatorStoreInstance = useMemo(() => new CreditCalculatorStore(), []);
    if (!fields || !rate) return null;

    useEffect(() => {
        CreditCalculatorStoreInstance.init(fields, rate);
    }, []);

    return (
        <CalculatorStoreContextNew.Provider value={CreditCalculatorStoreInstance}>
            {CreditCalculatorStoreInstance.initialized && (
                <CreditCalculatorContent title={title} gtag={gtag} button={button} />
            )}
        </CalculatorStoreContextNew.Provider>
    );
});

export default withBlockLinks(CreditCalculator);
