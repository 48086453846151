import React from 'react';

import { TinaCashback } from '@/components/blocks/Cashback/index.tina';
import { mockCashbackBlockTinaData } from '@/data/blocks/cashback';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const cashbackBlock = {
    Component: props => <TinaCashback {...props} />,
    template: {
        label: 'Кешбэк',
        defaultItem: () => mockCashbackBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                label: 'Заголовок',
                component: HtmlEditorFeatured,
            },
            {
                name: 'text',
                label: 'Подзаголовок',
                component: HtmlEditorFeatured,
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },
            {
                name: 'list',
                label: 'Элементы переключателей',
                component: 'group-list',
                itemProps: (
                    item: { tags: string[]; title: string; subtitle: string; image: string },
                    index: number
                ) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockCashbackBlockTinaData.list[0],
                fields: [
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'title',
                        label: 'Заголовок',
                        component: HtmlEditorFeatured,
                    },
                    {
                        name: 'subtitle',
                        label: 'Подзаголовок',
                        component: HtmlEditorFeatured,
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
