export const mockManagementInfoBlockData = {
    title: 'Руководство',
    tagList: [
        {
            value: 'Правление',
            xmlId: '1',
        },
        {
            value: 'Главный бухгалтер',
            xmlId: '2',
        },
        {
            value: 'Наблюдательный совет',
            xmlId: '3',
        },
    ],
    list: [
        {
            big: true,
            name: 'Руденко Дмитрий Васильевич',
            subtitle: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo;',
            desc: '1 &quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/1.png',
            tags: ['1', '3'],
        },
        {
            name: 'Емельянов Святослав Сергеевич',
            subtitle: 'Заместитель Президента-Председателя Правления, Член Правления АО «Почта Банк»',
            desc: '2 &quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/2.png',
            tags: ['1', '2'],
        },
        {
            name: 'Тулубьев Павел Андреевич',
            subtitle: 'Заместитель Президента-Председателя Правления, Член Правления АО «Почта Банк»',
            desc: '3 &quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/3.png',
            tags: ['1', '2', '3'],
        },
        {
            name: 'Бабаджанян Григорий Хачатурович',
            subtitle: 'Член Правления, Директор по продуктам и технологиям АО «Почта Банк»',
            desc: '&quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/4.png',
            tags: ['1', '3'],
        },
        {
            name: 'Масленникова Анастасия Алексеевна',
            subtitle: 'Член Правления, Директор по развитию электронного бизнеса АО «Почта Банк»',
            desc: '&quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/5.png',
            tags: ['1', '2', '3'],
        },
        {
            name: 'Мохначева Елена Владимировна',
            subtitle: 'Член Правления, Операционный директор АО «Почта Банк»',
            desc: '&quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/6.png',
            tags: ['1', '2', '3'],
        },
        {
            name: 'Строкун Наталия Николаевна',
            subtitle: 'Член Правления, Финансовый директор АО «Почта Банк»',
            desc: '&quot;Проект не&nbsp;предусматривает никаких бюджетных средств. Более того, в&nbsp;течение восьми лет мы&nbsp;должны заплатить почте 47&nbsp;миллиардов рублей за&nbsp;пользование инфраструктурой. Эти деньги пойдут на&nbsp;развитие самой почты. Мы&nbsp;планируем, что за&nbsp;первые три года запустим 15&nbsp;тысяч офисов, а&nbsp;в&nbsp;дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
            image: '/img-next/management/7.png',
            tags: ['1', '2', '3'],
        },
    ],
};

export const mockManagementInfoBlockTinaData = {
    section: null,
    title: 'Заголовок',
    tagList: [],
    list: [
        {
            big: true,
            name: 'Имя',
            subtitle: 'Должность',
            desc: 'Описание',
            image: '',
            tags: [],
        },
        {
            big: false,
            name: 'Имя',
            subtitle: 'Должность',
            desc: 'Описание',
            image: '',
            tags: [],
        },
        {
            big: false,
            name: 'Имя',
            subtitle: 'Должность',
            desc: 'Описание',
            image: '',
            tags: [],
        },
    ],
};
