import { ITiledShowcase } from '@/components/blocks/TiledShowcase';

export const mockTiledShowcaseBlock: ITiledShowcase = {
    _template: 'tiledShowcase',
    titleBlock: 'Заголовок',
    list: [
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
        {
            image: '/img-next/Group1.png',
            listName: 'data',
            listLink: '/',
        },
    ],
};
