import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useMobileWidth } from '@/hooks/useMedia';

import cn from './style.module.sass';

export interface ICorgis {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'corgis';
    title: string;
    image: string;
    imageMobile?: string;
}

function Corgis({ title, image, imageMobile }: ICorgis) {
    const isMobileWidth = useMobileWidth();

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    <div className={cn.content}>
                        <div className={cn.textWrapper}>
                            {title && (
                                <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                            )}
                            <div className={cn.flocktoryWrapper}>
                                <div className={cn.subtitle}>Осталось</div>
                                <div className="corgis_flocktory" />
                            </div>
                        </div>
                        {image && (
                            <div className={clsx(cn.imageWrap, isMobileWidth && cn.hidden)}>
                                <CustomImage src={image} alt="корги" width={195} height={121} />
                            </div>
                        )}
                        {imageMobile && isMobileWidth && (
                            <div className={clsx(cn.imageWrap, cn.imageWrapMobile)}>
                                <CustomImage src={imageMobile} alt="корги мобильная версия" width={107} height={95} />
                            </div>
                        )}
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
}

export default withBlockLinks(Corgis);
