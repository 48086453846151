import React from 'react';

import { TinaExample } from '@/components/blocks/Example/index.tina';
import { mockExampleBlockDataTina } from '@/data/blocks/example';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const exampleBlock = {
    Component: props => <TinaExample {...props} />,
    template: {
        label: 'Пример экономии на покупках',
        defaultItem: () => mockExampleBlockDataTina,
        fields: [
            {
                name: 'name',
                label: 'Заголовок',
                component: 'text',
            },
            {
                name: 'text',
                label: 'Описание',
                component: 'textarea',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockExampleBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        label: 'Заголовок',
                        component: 'text',
                    },
                    {
                        label: 'Список элементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { subtitle: string }, index: number) => ({
                            key: index,
                            label: item.subtitle,
                        }),
                        defaultItem: () => mockExampleBlockDataTina.list[0].info[0],
                        fields: [
                            {
                                name: 'subtitle',
                                label: 'Заголовок',
                                component: 'text',
                            },
                            {
                                name: 'desc',
                                label: 'Описание',
                                component: 'text',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'bottomText',
                label: 'Нижний текст',
                component: HtmlEditorFeatured,
            },
            {
                name: 'bottomInfo',
                label: 'Дополнительная информация',
                component: 'group',
                fields: [
                    {
                        name: 'infoTitle',
                        label: 'Заголовок',
                        component: 'text',
                    },
                    {
                        label: 'Список элементов',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { infoItemTitle: string }, index: number) => ({
                            key: index,
                            label: item.infoItemTitle,
                        }),
                        defaultItem: () => mockExampleBlockDataTina.bottomInfo.list[0],
                        fields: [
                            {
                                name: 'infoItemTitle',
                                label: 'Заголовок',
                                component: 'textarea',
                            },
                            {
                                name: 'infoItemText',
                                label: 'Описание',
                                component: 'textarea',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'banner',
                label: 'Баннер',
                component: 'group',
                fields: [
                    {
                        name: 'text',
                        label: 'Заголовок',
                        component: 'text',
                    },
                    {
                        name: 'bigText',
                        label: 'Большой заголовок',
                        component: 'text',
                    },
                    {
                        name: 'logo',
                        component: 'image',
                        label: 'Логотип',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
