import React from 'react';

import { TinaFAQTheme } from '@/components/blocks/faq/FAQTheme/index.tina';
import { mockFaqBlockDataTina } from '@/data/blocks/faq';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const faqThemeBlock = {
    Component: props => <TinaFAQTheme {...props} />,
    template: {
        label: 'FAQ - помощь - темы',
        defaultItem: () => mockFaqBlockDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockFaqBlockDataTina.list[0],
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                        name: 'title',
                    },
                    {
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                        name: 'subtitle',
                    },
                    {
                        component: 'text',
                        label: 'Ссылка',
                        name: 'link',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockFaqBlockDataTina.list[0].info[0],
                        fields: [
                            {
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                                name: 'title',
                            },
                            {
                                component: HtmlEditorFeatured,
                                label: 'Описание',
                                name: 'subtitle',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
