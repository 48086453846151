import React from 'react';

import { TinaInsurance } from '@/components/blocks/insurance/InsuranceList/index.tina';
import { mockInsuranceBlockDataTina } from '@/data/blocks/insurance';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const insuranceBlock = {
    Component: props => <TinaInsurance {...props} />,
    template: {
        label: 'Страхование жизни и здоровья',
        defaultItem: () => mockInsuranceBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockInsuranceBlockDataTina.list[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockInsuranceBlockDataTina.list[0].info[0],
                        fields: [
                            {
                                name: 'title',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                            {
                                name: 'subtitle',
                                component: HtmlEditorFeatured,
                                label: 'Подзаголовок',
                            },
                        ],
                    },
                    {
                        label: 'Футер',
                        name: 'bot',
                        component: 'group',
                        fields: [
                            {
                                name: 'logo',
                                component: 'image',
                                label: 'Изображение',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'logoName',
                                component: 'text',
                                label: 'Название',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'linkName',
                                component: 'text',
                                label: 'Название ссылки',
                            },
                            {
                                label: 'Документы',
                                name: 'documents',
                                component: 'group',
                                fields: [
                                    {
                                        name: 'name',
                                        component: 'text',
                                        label: 'Название',
                                    },
                                    {
                                        name: 'link',
                                        component: 'text',
                                        label: 'Ссылка',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
