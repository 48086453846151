import clsx from 'clsx';
import React, { useMemo } from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IUniversalItemProperty } from '@/types/universalList';
import { getDateObjectFromISO } from '@/utils/date/getDateObjectFromISO';

import cn from './style.module.sass';

interface IProps {
    name: string;
    properties: IUniversalItemProperty[];
    detailText: string;
    image?: string;
    imagePreview?: string;
}

const Content: React.FC<IProps> = ({ name, properties, detailText, image, imagePreview }) => {
    const date = useMemo(() => {
        const filteredDate = properties?.filter(property => property?.code === 'NEWS_DATE')?.[0]?.value;
        return getDateObjectFromISO(filteredDate);
    }, [properties]);

    const dateString = `${date.day} ${date.month} ${date.year}`;

    return (
        <div className={clsx(cn.item)}>
            <div className={cn.itemData}>{dateString}</div>
            <TextField customTag="h1" text={name} name="element.name" className={cn.itemTitle} />
            {image && (
                <div className={cn.image}>
                    <CustomImage src={image} fill mockWidth={736} mockHeight={400} alt="" />
                </div>
            )}
            <TextField
                text={detailText}
                name="element.detailText"
                className={cn.itemContent}
                isHTML
                htmlValue={detailText}
            />
        </div>
    );
};

export default Content;
