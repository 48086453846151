import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import React, { FC, useEffect, useState } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { IBanner, IPromoEntry } from '@/types/promo/item';

interface IProps extends Pick<IBanner, 'line' | 'description'> {
    hideBreadcrumbs: boolean;
    entry: IPromoEntry;
}

const PromoTopWithoutLines: FC<IProps> = ({ hideBreadcrumbs, line, description, entry }) => {
    const { isLiteHeaderDesktop, isLiteHeaderMobile } = useLayoutStore();

    const addBreadcrumbsStyle = isLiteHeaderMobile || isLiteHeaderDesktop || hideBreadcrumbs;
    const breadCrumbsConditions = !isLiteHeaderMobile && !isLiteHeaderDesktop && !hideBreadcrumbs;

    const currentUrl = usePathname();

    const [titleUtm, setTitleUtm] = useState(line?.title);
    const [subtitleUtm, setSubtitleUtm] = useState(line?.subtitle);

    useEffect(() => {
        const utmHeader = line?.headersList?.find(header => currentUrl.includes(header.utm));

        if (utmHeader && utmHeader?.title?.length > 0) {
            setTitleUtm(utmHeader.title);
        } else {
            setTitleUtm(line?.title);
        }
    }, [line?.title, currentUrl, line?.headersList]);

    useEffect(() => {
        const utmSubheader = line?.subheadersList?.find(subheader => currentUrl.includes(subheader.utm));

        if (utmSubheader && utmSubheader?.title?.length > 0) {
            setSubtitleUtm(utmSubheader.title);
        } else {
            setSubtitleUtm(line?.subtitle);
        }
    }, [line?.subtitle, currentUrl, line?.subheadersList]);

    return (
        <div className="promoItemTop promoSuperTop headers">
            {breadCrumbsConditions && (
                <Breadcrumbs
                    hideDisable={!hideBreadcrumbs}
                    customClassName="breadcrumbsPromo"
                    customClassItem="breadcrumbsItemPromo"
                />
            )}
            <div
                className={clsx('promoTopLines', 'promoSuperTopLines', addBreadcrumbsStyle && 'promoItemTopLinesLite')}
            >
                <h1 className={clsx('promoLineTitle')} dangerouslySetInnerHTML={{ __html: titleUtm }} />

                {subtitleUtm && (
                    <div className={clsx('promoLineSubtitle')} dangerouslySetInnerHTML={{ __html: subtitleUtm }} />
                )}
            </div>
            {description && (
                <TextField text={description} name="banner.description" className="promoLineDesc" isTextArea />
            )}
            {entry?.text &&
                (entry.color === 'white' ? (
                    <div className={clsx('promoEntry', 'promoEntryNoDesktop')}>
                        <Button
                            variant="btnWhiteBorder"
                            type="link"
                            href={entry?.link}
                            label={entry?.text}
                            size="small"
                            customClass={clsx('full')}
                            animation={false}
                        />
                    </div>
                ) : (
                    <div className={clsx('promoEntry', 'promoEntryNoDesktop')}>
                        <Button
                            variant="btnBlueBorder"
                            type="link"
                            href={entry?.link}
                            label={entry?.text}
                            size="small"
                            customClass={clsx('full')}
                            animation={false}
                        />
                    </div>
                ))}
        </div>
    );
};

export default observer(PromoTopWithoutLines);
