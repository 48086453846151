import clsx from 'clsx';
import React, { useEffect } from 'react';

import SpecialAdvantagesList from '@/components/blocks/advantages/SpecialAdvantages/list';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { ISpecialAdvantages } from '@/types/specialAdvantages';

import cn from './style.module.sass';

const SpecialAdvantages: React.FC<ISpecialAdvantages> = ({ list, name, description, button }) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            const element = document.querySelector('.layoutMiddle');
            if (element) {
                element.classList.add('disable-overflow');
            }
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.advantages, 'section')}>
            <div className={cn.advantagesWrap}>
                <SpecialAdvantagesList list={list} name={name} description={description} />
                {button?.title && (
                    <div className={clsx(cn.advantagesWrapBtn, cn.maxWidth)}>
                        <ScrollLink
                            to={button?.link}
                            activeClass="menuLinkActive"
                            className={clsx('btnBlue', cn.buttonLinkWrap, cn.maxWidth)}
                        >
                            <Button
                                variant="btnBlue"
                                type="button"
                                customClass={clsx(cn.button, 'productPanelButton')}
                                label={button?.title}
                                animation={false}
                            />
                        </ScrollLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(SpecialAdvantages);
