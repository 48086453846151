import React from 'react';

import { TinaAdvantages } from '@/components/blocks/advantages/Advantages/index.tina';
import { mockAdvantagesBlockTinaData } from '@/data/blocks/advantages';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const advantagesBlock = {
    Component: props => <TinaAdvantages {...props} />,
    template: {
        label: 'Преимущества',
        defaultItem: () => mockAdvantagesBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockAdvantagesBlockTinaData.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                        required: true,
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'isHoverNeeded',
                        component: 'toggle',
                        label: 'Ховер Эффект',
                    },
                    {
                        name: 'isClickable',
                        component: 'toggle',
                        label: 'Кликабелен ли блок',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageLink',
                        component: 'text',
                        label: 'Название продуктового параметра или ссылка на изображение',
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        label: 'Текст подсказки',
                    },
                    {
                        name: 'bgColor',
                        component: 'select',
                        label: 'Цвет фона',
                        options: [
                            {
                                value: '',
                                label: 'Обычный',
                            },
                            {
                                value: 'violet',
                                label: 'Фиолетовый',
                            },
                        ],
                    },
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер',
                        options: [
                            {
                                value: 'small',
                                label: 'Маленький',
                            },
                            {
                                value: 'middle',
                                label: 'Средний',
                            },
                            {
                                value: 'big',
                                label: 'Большой',
                            },
                            {
                                value: 'full',
                                label: 'Огромный',
                            },
                        ],
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Кнопка',
                        fields: [
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                ],
            },
        ],
    },
};
