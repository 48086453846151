import React from 'react';

import { TinaPaperWork } from '@/components/blocks/PaperWork/index.tina';
import { mockPaperWorkBlockDataTina } from '@/data/blocks/paperWork';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const paperWorkBlock = {
    Component: props => <TinaPaperWork {...props} />,
    template: {
        label: 'Оформление',
        defaultItem: () => mockPaperWorkBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Текст',
            },
            {
                name: 'buttonText',
                component: 'text',
                label: 'Текст для кнопки',
            },
            {
                name: 'content',
                component: HtmlEditorFeatured,
                label: 'Контент',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            { ...gtagSingleItem },
        ],
    },
};
