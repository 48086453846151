export const moneyBoxCalculatorData = {
    title: 'Калькулятор "Копилка"',
    filterElements: [
        { value: 1000, step: 100000, percent: 0, label: '1 тыс.', id: 1 },
        { value: 3000000, step: 100000, percent: 50, label: '', id: 2 },
        { value: 10000000, step: 100000, percent: 100, label: 'Сумма счета не ограничена', id: 3 },
    ],
    termsElements: [
        { value: 1, step: 1, percent: 0, label: '1 мес.', id: 1 },
        { value: 60, step: 1, percent: 100, label: 'Срок счета не ограничен', id: 2 },
    ],
    minValue: 50000,
    maxvalue: 10000000,
    rate: 13,
    gtag: {
        gtagButton: { action: 'click', category: 'test', label: 'test' },
        gtagSlider: { action: 'click', category: 'test', label: 'test' },
        gtagTerm: { action: 'click', category: 'test', label: 'test' },
    },
};
