import React from 'react';

import { TinaTinyResponsiveCategory } from '@/components/blocks/TinyResponsiveCategory/index.tina';
import { tinaTinyResponsiveCategoryData } from '@/data/blocks/tinyResponsiveCategory';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const tinyResponsiveCategoryBlock = {
    Component: props => <TinaTinyResponsiveCategory {...props} />,
    template: {
        label: 'Маленький блок расширяемых категорий',
        defaultItem: () => tinaTinyResponsiveCategoryData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'bottomText',
                component: HtmlEditorFeatured,
                label: 'Текст под списокм',
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Список элементов',
                defaultItem: () => tinaTinyResponsiveCategoryData.list[0],
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Текст',
                    },
                    {
                        name: 'subTitle',
                        component: HtmlEditorFeatured,
                        label: 'Текст',
                    },
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
