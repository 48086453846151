export const mockCreditRealEstateCalculatorData = {
    title: 'Рассчитайте условия по кредиту',
    tabTitle: 'Наличными',
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagButtonShare: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
    params: {
        terms: [
            {
                id: 1,
                label: '5 лет',
                value: 5,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 2,
                label: '6 лет',
                value: 6,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 3,
                label: '7 лет',
                value: 7,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },

            {
                id: 4,
                label: '8 лет',
                value: 8,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 5,
                label: '9 лет',
                value: 9,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 6,
                label: '10 лет',
                value: 10,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 7,
                label: '11 лет',
                value: 11,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 8,
                label: '12 лет',
                value: 12,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 9,
                label: '13 лет',
                value: 13,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 10,
                label: '14 лет',
                value: 14,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
            {
                id: 11,
                label: '15 лет',
                value: 15,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 0,
                        max: 10000000,
                        balancePercent: 24.6773644878684,
                    },
                ],
            },
        ],
        filterElements: [
            {
                value: 0,
                step: 5000,
                percent: 0,
                label: '0',
                id: 1,
            },
            {
                value: 300000,
                step: 10000,
                percent: 20,
                label: '300 тыс.',
                id: 2,
            },
            {
                value: 500000,
                step: 50000,
                percent: 40,
                label: '500 тыс.',
                id: 3,
            },
            {
                value: 1000000,
                step: 100000,
                percent: 60,
                label: '1 млн.',
                id: 4,
            },
            {
                value: 3000000,
                step: 100000,
                percent: 80,
                label: '3 млн.',
                id: 5,
            },
            {
                value: 10000000,
                step: 100000,
                percent: 100,
                label: '10 млн.',
                id: 6,
            },
        ],
        minValue: 0,
        maxValue: 10000000,
    },
    banner: {
        ranges: [
            {
                title: '',
                subtitle: 'Без обязательных страховок',
                min: 0,
                max: 270000,
            },
            {
                title: '',
                subtitle: '',
                min: 270001,
                max: 499999,
                shouldHide: true,
            },
            {
                title: '3%',
                subtitle: 'ежемесячный платеж',
                min: 500000,
                max: 10000000,
            },
        ],
        isActive: true,
    },
    infoTitle: {
        ranges: [
            {
                text: 'Предварительный расчёт по кредитной карте «Деньги и Всё!». Не является публичной офертой.',
                min: 0,
                max: 99999,
            },
            {
                text: 'Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке &nbsp;5.9% годовых с&nbsp;услугой «Гарантированная ставка». Не&nbsp;является публичной офертой.',
                tooltipText:
                    'Погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке 5.9% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита',
                min: 100000,
                max: 10000000,
            },
        ],
        isActive: true,
    },
    buttonShared: {
        isActive: true,
    },
};

export const mockCreditRealEstateCalculatorDataNew = {
    title: '<h2>Рассчитайте условия по кредиту</h2>',
    tabTitle: 'Наличными',
    gtag: {
        gtagButton: { action: 'click', category: 'test', label: 'test' },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagButtonShare: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
    button: { link: '#howtoget' },
    params: {
        rate: 10.9,
        balancePercent: 24.6773644878684,
        terms: [
            { value: 5, step: 1, percent: 0, label: '5 лет', id: 1 },
            { value: 6, step: 1, percent: 12, label: '6 лет', id: 2 },
            { value: 7, step: 1, percent: 20, label: '7 лет', id: 3 },
            { value: 8, step: 1, percent: 28, label: '8 лет', id: 4 },
            { value: 9, step: 1, percent: 38, label: '9 лет', id: 5 },
            { value: 10, step: 1, percent: 48, label: '10 лет', id: 6 },
            { value: 11, step: 1, percent: 58, label: '11 лет', id: 7 },
            { value: 12, step: 1, percent: 68, label: '12 лет', id: 8 },
            { value: 13, step: 1, percent: 78, label: '13 лет', id: 9 },
            { value: 14, step: 1, percent: 87, label: '14 лет', id: 10 },
            { value: 15, step: 1, percent: 100, label: '15 лет', id: 11 },
        ],
        sumElements: [
            { value: 1000000, step: 100000, percent: 0, label: '1 млн.', id: 1 },
            { value: 3000000, step: 100000, percent: 25, label: '3 млн.', id: 2 },
            { value: 5000000, step: 100000, percent: 50, label: '5 млн.', id: 3 },
            { value: 7000000, step: 100000, percent: 75, label: '7 млн.', id: 4 },
            { value: 10000000, step: 100000, percent: 100, label: '10 млн.', id: 5 },
        ],
        minValue: 1000000,
        maxValue: 10000000,
    },
    banner: {
        ranges: [
            {
                title: '',
                subtitle: 'Услуга «Гарантированная ставка»',
                min: 0,
                max: 10000000,
                shouldHide: false,
            },
        ],
        isActive: false,
    },
    infoTitle: {
        ranges: [
            {
                text: 'Предварительный расчёт по кредитной карте «Деньги и Всё!». Не является публичной офертой.',
                min: 0,
                max: 99999,
            },
            {
                text: 'Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке &nbsp;5.9% годовых с&nbsp;услугой «Гарантированная ставка». Не&nbsp;является публичной офертой.',
                tooltipText:
                    'Погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке 5.9% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита',
                min: 100000,
                max: 10000000,
            },
        ],
        isActive: false,
    },
    buttonShared: {
        isActive: true,
    },
};
