import React from 'react';

import { TinaProductsAndServices } from '@/components/blocks/ProductsAndServices/index.tina';
import { productAndServicesMock } from '@/data/blocks/productAndServices';

export const productsAndServicesBlock = {
    Component: props => <TinaProductsAndServices {...props} />,
    template: {
        label: 'Продукты и услуги',
        defaultItem: () => productAndServicesMock,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок блока',
            },
            {
                name: 'amountOfElements',
                component: 'number',
                label: 'Количество отображаемых на мобильном',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                description: 'Список карточек',
                itemProps: (item: { cardTitle: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.cardTitle,
                }),
                fields: [
                    {
                        name: 'cardTitle',
                        component: 'text',
                        label: 'Заголовок карточки',
                    },
                    {
                        name: 'cardDescription',
                        component: 'textarea',
                        label: 'Описание карточки',
                    },
                    {
                        name: 'isLineNeeded',
                        component: 'toggle',
                        label: 'Полоса с градиентом снизу',
                    },
                    {
                        name: 'linkText',
                        component: 'text',
                        label: 'Текст ссылки',
                    },
                    {
                        name: 'linkHref',
                        component: 'text',
                        label: 'Путь ссылки',
                    },
                    {
                        name: 'linkStyle',
                        component: 'select',
                        label: 'Вид ссылки',
                        options: [
                            {
                                label: 'Нет',
                                value: 'none',
                            },
                            {
                                label: 'кнопка',
                                value: 'button',
                            },
                            {
                                label: 'ссылка',
                                value: 'link',
                            },
                        ],
                    },
                    {
                        name: 'buttonStyle',
                        component: 'select',
                        label: 'Стиль кнопки',
                        options: [
                            {
                                value: 'lightBlueWithWhite',
                                label: 'Светло-синяя с белым текстом',
                            },
                            {
                                value: 'whiteWithBlack',
                                label: 'Белая текстом',
                            },
                        ],
                    },
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер кнопки',
                        options: [
                            { value: 'large', label: 'Большая' },
                            { value: 'small', label: 'Маленькая' },
                            { value: 'extra-small', label: 'Экстра маленькая' },
                            { value: 'full', label: 'Полная ширина' },
                        ],
                    },
                    {
                        name: 'isActual',
                        component: 'toggle',
                        label: 'Актуально',
                    },
                    {
                        name: 'actualColor',
                        component: 'select',
                        label: 'Цвет огонька',
                        options: [
                            {
                                label: 'Белый',
                                value: 'white',
                            },
                            {
                                label: 'Красный',
                                value: 'red',
                            },
                        ],
                    },
                    {
                        name: 'desktopImage',
                        component: 'image',
                        label: 'Изображение для пк',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'mobileImage',
                        component: 'image',
                        label: 'Изображение для мобильного',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imagePosition',
                        component: 'select',
                        label: 'Позиционирование изображения',
                        options: [
                            {
                                label: 'Справа',
                                value: 'right',
                            },
                            {
                                label: 'По центру',
                                value: 'middle',
                            },
                            {
                                label: 'Слева',
                                value: 'left',
                            },
                        ],
                    },
                    {
                        name: 'width',
                        component: 'select',
                        label: 'Ширина блока',
                        options: [
                            {
                                label: 'Маленький',
                                value: 'small',
                            },
                            {
                                label: 'Средний',
                                value: 'medium',
                            },
                            {
                                label: 'Большой',
                                value: 'large',
                            },
                        ],
                    },
                    {
                        name: 'color',
                        component: 'select',
                        label: 'Цвет блока',
                        options: [
                            {
                                label: 'Серый',
                                value: 'gray',
                            },
                            {
                                label: 'Голубой',
                                value: 'blue',
                            },
                            {
                                label: 'Ярко-Фиолетовый',
                                value: 'bright-purple',
                            },
                            {
                                label: 'Зеленый',
                                value: 'green',
                            },
                            {
                                label: 'Оранжевый',
                                value: 'orange',
                            },
                            {
                                label: 'Персиковый',
                                value: 'peach',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
