import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import Counter from '@/components/blocks/calculators/CalculatorConclusions/Counter';

import cn from './style.module.sass';

interface ICalculatorConclusions {
    sum: string;
    percent: string;
    title: string;
    withMaxWidth?: boolean;
    hideRate?: boolean;
    isRedesign?: boolean;
}

const CalculatorConclusions: FC<ICalculatorConclusions> = observer(
    ({ sum, percent, title, withMaxWidth, hideRate = false, isRedesign }) => (
        <div className={clsx(cn.wrapper, withMaxWidth && cn.withMaxWidth, isRedesign && cn.redesign)}>
            {isRedesign ? (
                <div className={cn.content}>
                    <div>
                        <div className={cn.sumWrap}>
                            <div className={cn.label}>{title}</div>
                            <Counter sum={sum} />
                        </div>
                    </div>
                    {!hideRate && (
                        <div className={cn.rateWrap}>
                            <div className={cn.rightLabel}>Процентная ставка</div>
                            <div className={cn.percentRate}>{percent}</div>
                        </div>
                    )}
                </div>
            ) : (
                <div className={cn.content}>
                    <div className={cn.label}>
                        <div className={cn.labelContent}>{title}</div>
                    </div>
                    <div className={cn.group}>
                        <div className={cn.groupWrapper}>
                            <div className={cn.month}>
                                <div className={cn.title}>{sum}</div>
                                <div className={cn.subtitle}>Платёж в месяц</div>
                            </div>
                            {!hideRate && (
                                <div className={cn.rate}>
                                    <div className={cn.title}>{percent}</div>
                                    <div className={cn.subtitle}>Ставка</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
);

export default CalculatorConclusions;
