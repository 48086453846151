import React from 'react';

import { TinaNewYearQuiz } from '@/components/blocks/frames/NewYearQuiz/index.tina';

export const newYearQuizBlock = {
    Component: props => <TinaNewYearQuiz {...props} />,
    template: {
        label: 'Новогодний квиз',
        fields: [],
    },
};
