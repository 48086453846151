import React from 'react';

import { TinaGuaranteeCalculator } from '@/components/blocks/calculators/layouts/GuaranteeCalculator/index.tina';
import { guaranteeCalculator } from '@/data/blocks/calculators/guarantee';
import { filterElements } from '@/tina/groups/calculators/filterElements';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const guaranteeCalculatorBlock = {
    Component: props => <TinaGuaranteeCalculator {...props} />,
    template: {
        label: 'Стратегия на 5. Гарант',
        defaultItem: guaranteeCalculator,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            { ...filterElements },
            {
                name: 'cashback',
                component: 'number',
                label: 'Кешбэк',
            },
            {
                name: 'taxDeductionPercentage',
                component: 'number',
                label: 'Процент налогового вычета',
            },
        ],
    },
};
