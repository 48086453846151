import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { IUniversalItem } from '@/types/universalList';
import { getDateObjectFromISO } from '@/utils/date/getDateObjectFromISO';

import cn from './style.module.sass';

interface IProps {
    item: IUniversalItem;
}

const UniversalItem: React.FC<IProps> = ({ item }) => {
    const date = useMemo(() => {
        const filteredDate = item?.properties.filter(property => property.code === 'NEWS_DATE')?.[0]?.value;
        return getDateObjectFromISO(filteredDate);
    }, [item?.properties]);

    return (
        <AnimatedComponent className={cn.item} classNameActive={cn.animationInit}>
            <a href={item?.detailListUrl} className={cn.itemLink}>
                <div className={cn.itemWrap}>
                    <div className={cn.itemTop}>
                        {item?.previewPictureSrc && (
                            <div className={cn.itemImage}>
                                <div className={cn.itemImageInner}>
                                    <CustomImage
                                        src={item?.previewPictureSrc}
                                        fill
                                        mockWidth={60}
                                        mockHeight={60}
                                        alt=""
                                    />
                                </div>
                            </div>
                        )}
                        <div className={cn.itemTitle} dangerouslySetInnerHTML={{ __html: item?.name }} />
                    </div>
                    <div className={cn.itemDesc} dangerouslySetInnerHTML={{ __html: item?.previewText }} />
                    {date && (
                        <div
                            className={cn.itemData}
                            dangerouslySetInnerHTML={{ __html: `${date.day} ${date.month}` }}
                        />
                    )}
                </div>
            </a>
        </AnimatedComponent>
    );
};

export default UniversalItem;
