import React from 'react';

import MainPrizes from '@/components/blocks/draw/main-prizes';
import { mockMainPrizes } from '@/components/blocks/draw/main-prizes/mock';
import withBlocksControl from '@/components/HOC/withBlocksControl';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const TinaMainPrizes = withBlocksControl(MainPrizes);

export const mainPrizesBlock = {
    Component: props => <TinaMainPrizes {...props} />,
    template: {
        label: 'Блок "Главные призы"',
        defaultItem: () => mockMainPrizes,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'items',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockMainPrizes.items[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'label',
                        component: HtmlEditorFeatured,
                        label: 'Шансы',
                    },
                    {
                        name: 'backgroundSrc',
                        component: 'image',
                        label: 'Фон',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'partnerLogoSrc',
                        component: 'image',
                        label: 'Логотип партнера',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
