import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface ICardApplication {
    _template: 'cardApplication';
    title: string;
    type: string;
}

const CardApplicationContent = dynamic(async () => import('./CardApplicationContent'), {
    ssr: false,
});

const CardApplication: React.FC<ICardApplication & { index: number }> = ({ title, type = 'MasterAccount' }) => (
    <div className="section">
        <CardApplicationContent title={title} type={type} />
    </div>
);

export default withBlockLinks(CardApplication);
