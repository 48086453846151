import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';

import RangeInput from '@/components/blocks/calculators/range/deprecated/RangeInputDeprecated';
import Term from '@/components/blocks/calculators/Term';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { useResize } from '@/hooks/useResize';
import { TABLET_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import CalculatorInfo from '../Info';
import CalculatorOffer from '../Offer';
import cn from './style.module.sass';

export interface ICalculator {
    title?: string;
    tabTitle?: string;
    percentTooltip?: string;
    infoTitle?: {
        isActive?: boolean;
        text?: string;
        tooltipText?: string;
    };
    button?: {
        label: string;
        link: string;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
    };
}

const CashCalculatorContent: React.FC<ICalculator> = observer(({ title, gtag, button, infoTitle, percentTooltip }) => {
    const { getMonthlyPayment, monthlyPayment } = useContext(CalculatorStoreContext);

    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;

    const formattedMonthlyPayment = `${monthlyPayment.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;

    useEffect(() => {
        getMonthlyPayment();
    }, []);

    return (
        <div className="section">
            <div className={cn.container}>
                <div className={cn.wrapper}>
                    {title && <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} />}

                    <div className={cn.content}>
                        <div className={cn.left}>
                            <RangeInput gtag={gtag?.gtagSlider} className={cn.rangeInput} />

                            <div className="flctry-calc" />

                            <div className={cn.termWrapper}>
                                <Term
                                    gtag={gtag?.gtagTerm}
                                    itemClassName={cn.customTerm}
                                    activeClassName={cn.customTermActive}
                                />
                            </div>

                            {!isMobile && (
                                <div className={cn.info}>
                                    {infoTitle?.isActive && (
                                        <CalculatorInfo text={infoTitle.text} tooltipText={infoTitle.tooltipText} />
                                    )}
                                </div>
                            )}
                        </div>

                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <CalculatorOffer
                                    title="Наше предложение"
                                    sum={formattedMonthlyPayment}
                                    percent={monthlyPayment.rate}
                                    percentTooltip={percentTooltip}
                                />

                                <div className={cn.buttonWrapper}>
                                    <ScrollLink to={button?.link || '#'} className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="button"
                                            label={button?.label || 'Заполнить заявку'}
                                            customClass={cn.button}
                                            animation={false}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                        />
                                    </ScrollLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CashCalculatorContent;
