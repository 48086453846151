import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

export interface ISafeAccountCalculatorStore {
    range: IRangeInput;
    termsElements: IRangeInput;
    promoDurationMonths: number;
    paymentValue: number;
    creditValue: number;
    initialized: boolean;
    promoBonusRate: number;
    turnoverBonusRate: number;
    enableCheckbox?: boolean;
    isChecked?: boolean;
    toggleChecked?: () => void;
    isCheckedFirst?: boolean;
    toggleCheckedFirst?: () => void;
    isCheckedCard?: boolean;
    toggleCheckedCard?: () => void;
    getRate?: () => number;
}

class SafeAccountCalculatorStore implements ISafeAccountCalculatorStore {
    public range: IRangeInput;

    public termsElements: IRangeInput;

    // public isPreferential = false;

    public initialized = false;

    public isChecked = true;

    public enableCheckbox = false;

    public rateMin = 0;

    public rateMax = 0;

    public promoDurationMonths = 3;

    public promoBonusRate = 2;

    public turnoverBonusRate = 10.5;

    public accrualThreshold = 3000000;

    public isCheckedFirst = true;

    public isCheckedCard = true;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    get termValue(): number {
        return this.termsElements.value;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        const monthPercent = this.getRate() / 100 / 12;
        const totalPercent = (1 + monthPercent) ** this.termValue;

        const promoIncome = this.getPromoIncome();
        const turnoverIncome = this.getTurnoverIncome();

        return Math.trunc(this.creditValue * totalPercent);
    }

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    public toggleCheckedFirst = () => {
        this.isCheckedFirst = !this.isCheckedFirst;
    };

    public toggleCheckedCard = () => {
        this.isCheckedCard = !this.isCheckedCard;
    };

    getRate = () => {
        let currentRate = 0;

        if (this.termValue <= 3) {
            currentRate = this.rateMin;
        } else {
            currentRate = this.rateMax;
        }

        if (this.creditValue <= this.accrualThreshold && this.termValue <= 3 && this.isCheckedFirst) {
            currentRate += this.turnoverBonusRate;
        }

        if (
            this.creditValue <= this.accrualThreshold &&
            this.termValue <= 3 &&
            !this.isCheckedFirst &&
            this.isCheckedCard
        ) {
            currentRate += this.promoBonusRate;
        }

        if (this.creditValue <= this.accrualThreshold && this.termValue > 3 && this.isCheckedCard) {
            currentRate += this.promoBonusRate;
        }

        return currentRate;
    };

    // Получить доход за промо
    getPromoIncome = () => {
        if (!this.isCheckedFirst) return 0;

        return (
            ((Math.min(this.creditValue, this.accrualThreshold) * (this.turnoverBonusRate / 100)) / 12) *
            Math.min(this.promoDurationMonths, this.termValue)
        );
    };

    // Получить доход за pos-активность
    getTurnoverIncome = () => {
        if (!this.isCheckedCard) return 0;

        return (this.promoBonusRate / 100 / 12) * Math.min(this.creditValue, this.accrualThreshold) * this.termValue;
    };

    public init = (
        filterElements: ICalculatorFilterElement[],
        termsElements: ICalculatorFilterElement[],
        enableCheckbox: boolean,
        rateMin: number,
        rateMax: number,
        promoDurationMonths: number
    ) => {
        this.range = new RangeInput({
            name: 'creditValue',
            label: 'Сумма',
            elements: filterElements,
            initValue: 100000,
        });

        const termsInitValue = 1;

        this.termsElements = new RangeInput({
            name: 'termValue',
            label: 'Срок',
            elements: termsElements,
            initValue: termsInitValue,
            customFormatValueHandle: (value, _) => `${value} мес.`,
        });

        this.enableCheckbox = enableCheckbox;

        this.rateMin = rateMin;

        this.rateMax = rateMax;

        this.initialized = true;

        this.promoDurationMonths = promoDurationMonths || 3;
    };
}

export default new SafeAccountCalculatorStore();
