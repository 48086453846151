import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface ILoanRepayment {
    _template: 'loanRepayment';
    title: string;
}

const LoanRepaymentContent = dynamic(async () => import('./LoanRepaymentContent'), { ssr: false });

const LoanRepayment: React.FC<ILoanRepayment> = ({ title }) => (
    <div>
        <LoanRepaymentContent title={title} />
    </div>
);

export default withBlockLinks(LoanRepayment);
