import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import ActionDetailContent from '@/components/blocks/ActionDetail/content';
import ActionDetailTopContent from '@/components/blocks/ActionDetail/topContent';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useAppStore } from '@/context/AppStoreContext';
import Feature from '@/domain/feature/Feature';
import { IActionsResponse, IActionsSliderItem } from '@/types/actionsSlider';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IActionDetail {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'actionDetail';
    apiCode: string;
    element: IActionsSliderItem;
}

const ActionDetail: React.FC<IActionDetail> = observer(({ apiCode, element }) => {
    const [path] = useAppStore(store => store.path);
    const [isTinaView] = useAppStore(store => store.isTinaView);

    const [currentContent, setCurrentContent] = useState<IActionsSliderItem>(element);

    const requestDataWithParams = async (...args) => {
        try {
            const response: IActionsResponse<IActionDetail> = await apiRequest(
                `/page/block?path=${path}&block=actionDetail&apiCode=${apiCode}&section=0`
            );

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const {
                data: { data: fetchedData },
            } = response;

            setCurrentContent(fetchedData?.element);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return fetchedData;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    useEffect(() => {
        if (!apiCode || !isTinaView) return;

        requestDataWithParams().catch(console.error);
    }, []);

    useEffect(() => {
        if (!apiCode || !isTinaView) return;

        requestDataWithParams().catch(console.error);
    }, [apiCode]);

    if (!Feature.isFeatureEnabled('enableActions')) {
        return null;
    }

    return (
        <AnimatedComponent className={clsx(cn.detail)} classNameActive={cn.animationInit}>
            <ActionDetailTopContent {...currentContent} />

            <ActionDetailContent {...currentContent} />
        </AnimatedComponent>
    );
});

export default withBlockLinks(ActionDetail);
