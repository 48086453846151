import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { useResize } from '@/hooks/useResize';
import { IActionsSliderItem } from '@/types/actionsSlider';
import { MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

const ActionDetailTopContent: React.FC<IActionsSliderItem> = observer(
    ({
        id,
        code,
        endedAt,
        conditions,
        conditionsSrc,
        tabletImage,
        mobileImage,
        mechanicsDescription,
        mechanicsDescriptionSrc,
        name,
        rules,
        linkTo,
        cashbackAmount,
    }) => {
        const width = useResize();

        const isMobile = width < TABLET_SMALL_WIDTH;

        const getCurrentImage = useMemo(() => {
            if (width < MOBILE_WIDTH) return mobileImage ?? conditionsSrc;
            if (width < TABLET_SMALL_WIDTH) return tabletImage ?? conditionsSrc;
            return conditionsSrc;
        }, [width, conditionsSrc, mobileImage, tabletImage]);

        return (
            <div
                className={clsx(cn.wrapper)}
                style={{
                    background: !isMobile
                        ? `url(${getCurrentImage}) center center/cover no-repeat`
                        : `url(${getCurrentImage}) center bottom/cover no-repeat`,
                }}
            >
                <div className={clsx(cn.grid)}>
                    <div className={clsx(cn.cellInfo, cn.cell)}>
                        {name && (
                            <div className={clsx(cn.headingWrap)}>
                                <div className={cn.heading} dangerouslySetInnerHTML={{ __html: name }} />
                            </div>
                        )}
                        {cashbackAmount && (
                            <div>
                                <div className={cn.subheadingWrap}>
                                    <div
                                        className={cn.subheading}
                                        dangerouslySetInnerHTML={{
                                            __html: cashbackAmount,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {mechanicsDescription && (
                            <div className={clsx(cn.desc)} dangerouslySetInnerHTML={{ __html: mechanicsDescription }} />
                        )}
                    </div>
                    <div className={clsx(cn.cell, cn.cellImg)}>
                        <div className={cn.imgWrap} />
                    </div>
                </div>
            </div>
        );
    }
);

export default ActionDetailTopContent;
