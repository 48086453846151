import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';

import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { ITermItem } from '@/types/calculator/config';

import cn from './style.module.sass';

interface IProps {
    term: ITermItem;
    handleClick: () => void;
    activeClassName?: string;
    itemClassName?: string;
    isRedesign?: boolean;
}

const TermItem: React.FC<IProps> = observer(({ term, handleClick, itemClassName, activeClassName, isRedesign }) => {
    const { activeTerm, setActiveTerm, isTermDisabled, value } = useContext(CalculatorStoreContext);
    const { label, value: termValue } = term;
    const [disabled, setDisabled] = useState(false);

    const isActive = activeTerm.id === term.id;

    const handleTermClick = () => {
        if (disabled) return;
        setActiveTerm(term);
        handleClick();
    };

    useEffect(() => {
        setDisabled(isTermDisabled(termValue));
    }, [value]);

    return (
        <div className={cn.wrapper}>
            <div
                role="presentation"
                onClick={handleTermClick}
                className={clsx(
                    cn.item,
                    isActive && cn.active,
                    isActive && activeClassName,
                    disabled && cn.disable,
                    isRedesign && cn.redesign,
                    itemClassName
                )}
            >
                {label}
            </div>
        </div>
    );
});

export default TermItem;
