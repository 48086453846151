import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { useTheme } from '@/context/ThemeProvider';
import { ICashbackPercentageItem } from '@/types/CashbackPercentage/item';

import cn from './style.module.sass';

const CashbackPercentageItem: React.FC<
    ICashbackPercentageItem & {
        type: 'col-3' | 'col-4' | 'col-5';
        background: 'blue' | 'gradient' | 'white';
    }
> = ({ title, image, imageDarkTheme, link, pos, customClass, type = 'col-3', size, cashbackSize, background }) => {
    // theme нужен для опредения цвета логотипа
    const { theme } = useTheme();
    return (
        <AnimatedComponent
            className={clsx(
                cn.item,
                customClass,
                type === 'col-3' && cn.col3,
                type === 'col-4' && cn.col4,
                type === 'col-5' && cn.col5,
                size === 'small' && cn.itemSmall
            )}
            classNameActive={cn.animationInit}
        >
            <a href={link} className={cn.itemLink} rel="nofollow">
                <div
                    className={clsx(
                        cn.itemImg,
                        background === 'gradient' && cn.itemGradient,
                        background === 'blue' && cn.itemBlue
                    )}
                >
                    <div className={cn.titleAndImage}>
                        <TextField
                            className={clsx(cn.itemName, background === 'gradient' && cn.white)}
                            text={title}
                            name={`list.[${pos}].title`}
                            isHTML
                            htmlValue={title}
                        />
                        <div className={cn.itemImgWrap}>
                            <CustomImage
                                src={theme === 'dark' ? imageDarkTheme : image}
                                alt={title}
                                fill
                                mockWidth="190"
                                mockHeight="120"
                            />
                        </div>
                    </div>
                    <div className={cn.cashbackSizeWrapper}>
                        <TextField
                            className={clsx(cn.cashbackSize, background === 'gradient' && cn.white)}
                            text={`${cashbackSize}%`}
                            name={`list.[${pos}].cashbackSize`}
                            isHTML
                            htmlValue={`${cashbackSize}%`}
                        />
                        <span className={clsx(cn.cashbackText, background === 'gradient' && cn.white)}>кешбэк</span>
                    </div>
                </div>
            </a>
        </AnimatedComponent>
    );
};

export default CashbackPercentageItem;
