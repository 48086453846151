export const mockLightBannerBlockData = {
    title: 'Выбирайте, куда сходить и как провести свободное время, в <a href="#">Почта Банк. Билеты</a>',
    text: '5 000 Пушкинских рублей для оплаты билетов в театры для молодых людей от 14 до 22 лет',
    leftImage: '/img-next/png/banners/lightBannerLeftImage.png',
    rightImage: '/img-next/png/banners/lightBannerRightImage.png',
    name: 'lightBanner',
};

export const mockLightBannerBlockWithoutImageData = {
    title: '',
    text: '<a href="#">Почта Банк. Билеты</a> – надежный и современный помощник в организации продаж билетов на ваши мероприятия',
    leftImage: '',
    rightImage: '',
    name: 'lightBanner',
};

export const mockLightBannerBlockDataTina = {
    title: 'Заголовок',
    text: 'Текст',
    leftImage: '',
    rightImage: '/img-next/png/banners/lightBannerRightImage.png',
    name: 'lightBanner',
};
