import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import BiographyItem, { IBiographyItem } from '@/components/blocks/biography/BiographyItem';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IBiography {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'biography';
    name?: string;
    title?: string;
    list: IBiographyItem[];
}

const Biography: React.FC<IBiography> = ({ list, title }) => {
    if (list?.length <= 0) return null;

    return (
        <AnimatedComponent
            className={clsx(cn.biography, 'section')}
            classNameActive={cn.animationInit}
            role="presentation"
        >
            <div className={cn.biographyWrap}>
                {title && <TextField text={title} className={cn.mainTitle} name="title" customTag="h3" />}
                <div className={cn.biographyGrid}>
                    {list?.map((item, index) => (
                        <div className={cn.biographyItemWrapper} key={index}>
                            <BiographyItem {...item} pos={index} />
                        </div>
                    ))}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default Biography;
