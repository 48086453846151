import React from 'react';

import cn from '@/components/UI/ShareIconBlock/style.module.sass';

function VkIconItem() {
    return (
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0327 7.04968C15.7222 6.67634 15.811 6.51025 16.0327 6.17653C16.0367 6.17273 18.5991 2.80053 18.8631 1.65691L18.8647 1.65615C18.996 1.23939 18.8647 0.933105 18.2303 0.933105H16.1311C15.5966 0.933105 15.3502 1.19596 15.2182 1.49006C15.2182 1.49006 14.1494 3.92663 12.6373 5.50606C12.1493 5.96244 11.9237 6.10873 11.6573 6.10873C11.5261 6.10873 11.3221 5.96244 11.3221 5.54568V1.65615C11.3221 1.15634 11.1725 0.933105 10.7301 0.933105H7.42915C7.09394 0.933105 6.89473 1.16625 6.89473 1.38339C6.89473 1.8573 7.65076 1.96625 7.72916 3.29958V6.19253C7.72916 6.82644 7.60836 6.94301 7.34035 6.94301C6.62672 6.94301 4.89466 4.49653 3.86823 1.69653C3.66102 1.1533 3.45861 0.933867 2.9202 0.933867H0.820123C0.220902 0.933867 0.100098 1.19672 0.100098 1.49082C0.100098 2.01044 0.813722 4.59406 3.41861 8.00739C5.15467 10.3373 7.59956 11.5998 9.82364 11.5998C11.1605 11.5998 11.3237 11.3194 11.3237 10.8371C11.3237 8.61082 11.2029 8.40054 11.8725 8.40054C12.1829 8.40054 12.7173 8.54682 13.9654 9.67063C15.3918 11.0032 15.6262 11.5998 16.4247 11.5998H18.5239C19.1224 11.5998 19.4256 11.3194 19.2512 10.7663C18.8519 9.60282 16.1543 7.20968 16.0327 7.04968Z"
                className={cn.svgFillInner}
            />
        </svg>
    );
}

export default VkIconItem;
