import { mockQuestionsBlockDataHrTags, mockQuestionsBlockDataTina } from '@/data/blocks/questions';
import { mockQuoteHrBlockData, mockQuoteHrBlockDataTina } from '@/data/blocks/quoteHr';

export const mockHrFaqBlockData = {
    quoteHrBlock: mockQuoteHrBlockData,
    questionsBlock: mockQuestionsBlockDataHrTags,
    button: {
        title: 'Кнопка',
        link: '#',
    },
};

export const mockHrFaqBlockDataTina = {
    quoteHrBlock: mockQuoteHrBlockDataTina,
    questionsBlock: mockQuestionsBlockDataTina,
    button: {
        title: 'Кнопка',
        link: '#',
    },
};
