import clsx from 'clsx';
import Image from 'next/image';
import React, { useRef } from 'react';

import { ScrollLink } from '@/components/UI/ScrollLink';
import Feature from '@/domain/feature/Feature';
import useBeingInViewport from '@/hooks/useBeingInViewport';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { smoothScroll } from '@/utils/smoothScroll';

import cn from './style.module.sass';

export interface IButtonBlueProps {
    /**
     * Тип: кнопка или ссылка
     */
    type: 'button' | 'link' | 'anchor';
    /**
     * Ссылка
     */
    href?: string;
    /**
     * Target атрибут
     */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /**
     * Размеры кнопки
     */
    size?: 'large' | 'small' | 'extra-small' | 'full';
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Кнопка с иконкой
     */
    img?: string;
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Нужна ли анимация на кнопке
     */
    animation?: boolean;
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    /**
     * Тип, в случае выбора кнопки
     */
    buttonType?: 'button' | 'submit' | 'reset';
    smooth?: boolean;
    isRedesign?: boolean;
    rel?: string;
}

export const ButtonBlue: React.FC<IButtonBlueProps> = ({
    size = 'large',
    label,
    img,
    disable = false,
    customClass,
    type = 'button',
    href,
    target,
    animation = true,
    onClick: onClickHandler,
    buttonType = 'button',
    smooth = true,
    isRedesign,
    rel,
}) => {
    const ref = useRef(null);

    const wasInViewport = useBeingInViewport(ref, 0);

    const isAnimationEnabled = Feature.isFeatureEnabled('showAnimationWrap');

    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const scrollOffset = isMobile ? 60 : 90;

    const className = clsx(
        cn.btnBlue,
        disable === true && cn.disable,
        size === 'small' && cn.small,
        size === 'extra-small' && cn.xsmall,
        size === 'full' && cn.full,
        customClass,
        animation && isAnimationEnabled && cn.animation,
        animation && isAnimationEnabled && wasInViewport && cn.animationInit,
        isRedesign && cn.btnNewStyle
    );

    const children = (
        <>
            {img && <Image src={img} width={22} height={22} alt={label} />}
            <span>{label}</span>
        </>
    );

    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (onClickHandler) onClickHandler();
        if (smooth && type === 'link') smoothScroll(e, href);
    };

    /* eslint-disable react/button-has-type */
    if (type === 'button') {
        return (
            <button ref={ref} disabled={disable} type={buttonType} className={className} onClick={onClick}>
                {children}
            </button>
        );
    }

    if (type === 'anchor') {
        return (
            <ScrollLink to={href} offset={-scrollOffset} activeClass="active" className="">
                <button ref={ref} disabled={disable} type="button" className={className}>
                    {children}
                </button>
            </ScrollLink>
        );
    }

    return (
        <a href={href} ref={ref} target={target} className={className} onClick={onClick} rel={rel}>
            {children}
        </a>
    );
};
