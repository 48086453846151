import { action, computed, makeObservable, observable } from 'mobx';
import { ChangeEvent, KeyboardEvent } from 'react';

import { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import { ITermItem } from '@/types/calculator/config';
import { Cookie } from '@/utils/cookie';

export interface ICalculatorFilterElement {
    value: number;
    step: number;
    percent: number;
    label: string;
    id: number;
}

export interface ICalculatorRange {
    [name: string]: number[];
}

export interface IAbstractCalculatorStore {
    value: string;
    setInitValue: () => void;
    formattedValue: string;
    minValue: number;
    setMinValue: (minValue: number) => void;
    maxValue: number;
    setMaxValue: (maxValue: number) => void;
    setValue: (newValue: string) => void;
    onTrackSlide: (newArrayOfValues: string[]) => void;
    onTrackChange: (newArrayOfValues: string[]) => void;
    monthlyPayment: { total: number; rate: number };
    inputValue: string;
    termsInputValue?: string;
    isInputFocused: boolean;
    onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onInputFocus: () => void;
    onInputBlur: () => void;
    onInputEnterPress: (e: KeyboardEvent<HTMLInputElement>) => void;
    filterElements: Array<ICalculatorFilterElement>;
    setFilterElements: (filterElements: ICalculatorFilterElement[]) => void;
    sliderTimeout: ReturnType<typeof setTimeout>;
    terms?: Array<ITermItem>;
    setTerms?: (terms: ITermItem[]) => void;
    termsRange?: ICalculatorFilterElement[];
    activeTermRange?: number;
    setActiveTermRange?: (termRange: number) => void;
    getMonthsString?: (months: number) => string;
    activeTerm?: ITermItem;
    setActiveTerm?: (term: ITermItem) => void;
    setValueToTerm?: () => void;
    getRate?: () => number;
    getMonthlyPayment: () => void;
    insuranceTotal?: number;
    isTermDisabled?: (termValue: number) => boolean;
    hydrate?: (
        filterElements: ICalculatorFilterElement[],
        terms: ITermItem[],
        minValue: number,
        maxValue: number
    ) => void;
    resultConfig?: Record<string, IRangeInput>;
    maxCreditLimit?: () => void;
    setInitialValueFromQueryParams?: (value: string, term: string) => void;
}

class AbstractCalculatorStore implements IAbstractCalculatorStore {
    value = '500000';

    formattedValue = '500 000 ₽';

    minValue = 20000;

    setMinValue = (minValue: number) => {
        this.minValue = minValue;
    };

    maxValue = 5000000;

    setMaxValue = (maxValue: number) => {
        this.maxValue = maxValue;
    };

    monthlyPayment = { total: 0, rate: 0 };

    isInputFocused = false;

    sliderTimeout = null;

    // Запрашивать с бэка.
    filterElements = null;

    setFilterElements = (filterElements: ICalculatorFilterElement[]) => {
        this.filterElements = filterElements;
    };

    get inputValue() {
        return this.isInputFocused ? this.value : this.formattedValue;
    }

    terms: ITermItem[] = [];

    activeTerm: ITermItem = null;

    setActiveTerm = (term: ITermItem) => {
        this.activeTerm = term;
        if (typeof window !== 'undefined') window.localStorage?.setItem('activeTermId', term.id.toString());
        this.getMonthlyPayment();
    };

    isTermDisabled = (termValue: number) => false;

    setTerms = (terms: ITermItem[]) => {
        this.terms = terms;
    };

    hydrate = (filterElements: ICalculatorFilterElement[], terms: ITermItem[], minValue: number, maxValue: number) => {
        this.setFilterElements(filterElements);
        this.setTerms(terms);
        this.setActiveTerm(terms?.[2] || terms[0]);
        this.setMinValue(minValue);
        this.setMaxValue(maxValue);
        this.setInitValue();
    };

    setValue = (newValue: string) => {
        const numberRegex = /^\d*$/;

        if (!numberRegex.test(newValue) || newValue.slice(0, 1) === '0') return;

        this.value = newValue;
        this.formatValue();
    };

    onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setValue(e.target.value);
    };

    onInputFocus = () => {
        this.isInputFocused = true;
    };

    onInputEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!this.isInputFocused) return;

        if (e.key === 'Enter') {
            this.validateValue();
            this.getMonthlyPayment();
            (e.target as HTMLInputElement).blur();
        }
    };

    onInputBlur = () => {
        this.isInputFocused = false;
        this.validateValue();
        this.getMonthlyPayment();
    };

    validateValue = () => {
        if (+this.value > this.maxValue) this.value = this.maxValue.toString();
        if (+this.value < this.minValue) this.value = this.minValue.toString();
        this.formatValue();
        if (typeof window !== 'undefined') window.localStorage.setItem('calculatorValue', this.value);
    };

    getCorrectStorageValue = (value: string) => {
        if (+value > this.maxValue) return this.maxValue.toString();
        if (+value < this.minValue) return this.minValue.toString();
        return value;
    };

    setInitValue = () => {
        if (typeof window === 'undefined') return;

        const cookieValue = Cookie.getCookie('creditAmount');
        if (!cookieValue) {
            this.value = '500000';
            document.cookie = `creditAmount=500000; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;
            this.formatValue();
            return;
        }

        this.value = this.getCorrectStorageValue(cookieValue);
        document.cookie = `creditAmount=${this.value}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;
        this.formatValue();
    };

    onTrackSlide = (newArrayOfValues: string[]) => {
        const parsedValue = +newArrayOfValues[0];
        const newValue = parsedValue.toString();
        this.setValue(newValue);
        this.getMonthlyPayment();
    };

    onTrackChange = (newArrayOfValues: string[]) => {
        const parsedValue = +newArrayOfValues[0];
        const newValue = parsedValue.toString();
        this.setValue(newValue);
        this.getMonthlyPayment();
        document.cookie = `creditAmount=${newValue}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;
        if (typeof window !== 'undefined') window.localStorage.setItem('calculatorValue', newValue);
        this.setValueToFrame(newValue);
    };

    setValueToFrame = (value: string) => {
        if (!window?.PBSDK || !window?.PBSDK?.creditIssue) return null;
        window.PBSDK.creditIssue.setCreditAmount(value);
        return null;
    };

    setValueToTerm = () => {
        if (typeof window === 'undefined') return;
        if (window?.PBSDK && window?.PBSDK?.creditIssue)
            window.PBSDK.creditIssue.setCreditTerm((this.activeTerm.value * 12).toString());
    };

    getMonthlyPayment = (): void => {
        throw new Error('Переопределите этот метод для расчета в наследуемом классе.');
    };

    setInitialValueFromQueryParams = (value: string, term: string) => {
        const correctValue = this.getCorrectStorageValue(value);
        this.setValue(correctValue);
        const correctTerm: ITermItem = this.terms.find(item => item.value === +term);
        const isCorrectTermDisabled = this.isTermDisabled(correctTerm.value);
        if (!isCorrectTermDisabled) this.setActiveTerm(correctTerm);
        this.getMonthlyPayment();
    };

    formatValue = () => {
        if (this.value.length) {
            this.formattedValue = `${this.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;
            return;
        }
        this.formattedValue = '';
    };

    constructor() {
        makeObservable(this, {
            minValue: observable,
            maxValue: observable,
            value: observable,
            formattedValue: observable,
            monthlyPayment: observable,
            isInputFocused: observable,
            inputValue: computed,
            setValue: action,
            onInputChange: action,
            onInputFocus: action,
            onInputEnterPress: action,
            onInputBlur: action,
            validateValue: action,
            onTrackSlide: action,
            onTrackChange: action,
            formatValue: action,
            setFilterElements: action,
            activeTerm: observable,
            setActiveTerm: action,
            setTerms: action,
            filterElements: observable,
            hydrate: action,
            setInitValue: action,
        });

        if (this.constructor === AbstractCalculatorStore) {
            throw new Error('Не используте абстрактный класс калькулятора как инстанс.');
        }
    }
}

export default AbstractCalculatorStore;
