import { makeAutoObservable } from 'mobx';

import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import { IFieldsConfig } from '@/types/calculator/config';

export interface ICashbackCalculator {
    resultConfig: Record<string, IRangeInput>;
    maxCashback: number;
    maxPercent: number;
    maxCashbackResult: number;
    currentCashback: number;
    createConfigRangeBuy: () => void;
    progressMaxCashback: number;
    progressCashback: number;
}

class CashbackCalculatorStore implements ICashbackCalculator {
    public resultConfig: Record<string, IRangeInput> = {};

    public initialized = false;

    public maxCashback = 0;

    public maxPercent = 0;

    public purchaseSumPoint = 30000;

    constructor() {
        makeAutoObservable(this);
    }

    public get progressMaxCashback() {
        return (this.maxCashbackResult / this.maxCashback) * 100;
    }

    public get progressCashback() {
        return (this.currentCashback / this.maxCashback) * 100;
    }

    public get maxCashbackResult() {
        const configRangeFirstValue = this.resultConfig.range1.value;

        if (configRangeFirstValue <= this.purchaseSumPoint) {
            const value = this.multiplyPercent(configRangeFirstValue, 3);
            return this.getValidatedValue(value);
        }

        const value =
            this.multiplyPercent(configRangeFirstValue - this.purchaseSumPoint, this.maxPercent) +
            this.multiplyPercent(this.purchaseSumPoint, 3);

        return this.getValidatedValue(value);
    }

    public get currentCashback() {
        const purchaseSum = this.resultConfig.range1.value;
        const categorySum = this.resultConfig.range2.value;
        const sumWithoutPoint = purchaseSum - this.purchaseSumPoint;

        // сумма покупок менее 30 000
        if (purchaseSum <= this.purchaseSumPoint) {
            const value = this.multiplyPercent(purchaseSum - categorySum, 1) + this.multiplyPercent(categorySum, 3);

            return this.getValidatedValue(value);
        }

        // сумма покупок более 30 000

        // сумма в категории больше, чем (сумма покупок – 30 000)
        if (categorySum > sumWithoutPoint) {
            const value =
                this.multiplyPercent(sumWithoutPoint, this.maxPercent) +
                this.multiplyPercent(categorySum - sumWithoutPoint, 3) +
                this.multiplyPercent(this.purchaseSumPoint - categorySum, 1);

            return this.getValidatedValue(value);
        }

        // сумма в категории меньше или равно, чем (сумма покупок – 30 000)
        const value =
            this.multiplyPercent(categorySum, this.maxPercent) +
            this.multiplyPercent(sumWithoutPoint - categorySum, 2) +
            this.multiplyPercent(this.purchaseSumPoint, 1);

        return this.getValidatedValue(value);
    }

    public init = (fields: IFieldsConfig, maxCashback: number, maxPercent: number) => {
        this.maxCashback = maxCashback || 6;
        fields.rangeSlider?.forEach(rangeConfig => {
            this.resultConfig[rangeConfig.name] = new RangeInput(rangeConfig);
        });
        this.initialized = true;
        this.maxPercent = maxPercent;
    };

    public createConfigRangeBuy = () => {
        this.resultConfig.range2.handleChangeConfig({
            elements: [
                {
                    value: 0,
                    step: 1000,
                    percent: 0,
                    label: '0',
                    id: 1,
                },
                {
                    value: +this.resultConfig.range1.value,
                    step: 1000,
                    percent: 100,
                    label: `${this.resultConfig.range1.value}`,
                    id: 2,
                },
            ],
        });
    };

    private multiplyPercent = (value: number, percent: number) => (value / 100) * percent;

    private getValidatedValue = (value: number): number => {
        if (value > this.maxCashback) {
            return this.maxCashback;
        }
        return Math.round(value);
    };
}

export default new CashbackCalculatorStore();
