import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

interface ICategory {
    title: string;
    subTitle: string;
    img: string;
}

export interface ITinyResponsiveCategory {
    _template: 'tinyResponsiveCategory';
    title: string;
    bottomText: string;
    list: ICategory[];
}

const TinyResponsiveCategory: FC<ITinyResponsiveCategory> = ({ _template, title, bottomText, list }) => {
    const width = useResize();
    const isMobile = width <= MOBILE_WIDTH;

    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>{title}</div>
            <div className={cn.list}>
                {list.map(item => (
                    <div className={cn.item} key={item.title}>
                        <div className={cn.left}>
                            <div className={cn.text}>
                                {item.title}{' '}
                                {item?.subTitle && isMobile && (
                                    <span
                                        className={cn.subTitleMobile}
                                        dangerouslySetInnerHTML={{ __html: item.subTitle }}
                                    />
                                )}
                            </div>
                            {item?.subTitle && (
                                <div className={cn.subTitle} dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                            )}
                        </div>
                        <div className={cn.right}>
                            <CustomImage
                                src={item.img}
                                alt={item.title}
                                width={isMobile ? 28 : 40}
                                height={isMobile ? 28 : 40}
                                quality={100}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className={cn.bottomText} dangerouslySetInnerHTML={{ __html: bottomText }} />
        </div>
    );
};

export default TinyResponsiveCategory;
