export const mockBiographyBlockData = {
    title: 'Биография',
    list: [
        {
            image: '/img-next/management/rudenko/biography/1.png',
            year: '1969',
            desc: 'Родился 23&nbsp;ноября 1969 года в&nbsp;пос. Болшево, г. Калининград Московской области',
        },
        {
            image: '/img-next/management/rudenko/biography/2.png',
            year: '1993 — 1998',
            desc: 'Работал в&nbsp;Промрадтехбанке, где прошел путь от&nbsp;специалиста отдела организации расчетов до&nbsp;начальника управления по&nbsp;работе с&nbsp;частными лицами.',
        },
        {
            image: '/img-next/management/rudenko/biography/3.png',
            year: '1999 — 2007',
            title: 'Курируя розничный бизнес банка &laquo;Русский Стандарт&raquo;, он&nbsp;одним из&nbsp;первых предложил рынку такой продукт, как кредитные карты',
            desc: 'Входил в&nbsp;рабочую группу по&nbsp;реорганизации банка &laquo;Русский Стандарт&raquo; и&nbsp;впоследствии стал одним из&nbsp;ключевых топ-менеджеров банка. В&nbsp;мае 1999 года назначен Вице-президентом, Руководителем Департамента розничного бизнеса, а&nbsp;в&nbsp;2005 году&nbsp;&mdash; Первым заместителем Председателя банка &laquo;Русский Стандарт&raquo;',
        },
        {
            image: '/img-next/management/rudenko/biography/4.png',
            year: '2007 — 2012',
            title: 'Дмитрий Руденко внедрил сегментированный подход к&nbsp;работе с&nbsp;клиентской базой, предложил пакет &laquo;Привилегия&raquo;, который и&nbsp;сегодня популярен у&nbsp;клиентов.',
            desc: 'В&nbsp;этот период Дмитрий Руденко работал в&nbsp;должности первого заместителя президента-председателя правления банка ВТБ24. Координировал работу сети, обслуживание VIP-клиентов, розничный бизнес в&nbsp;России и&nbsp;странах СНГ, ипотечное кредитование, руководил деятельностью департамента маркетинга и&nbsp;клиентской службы.',
        },
        {
            image: '/img-next/management/rudenko/biography/5.png',
            year: '2012 — 2016',
            title: 'Под руководством Дмитрия Руденко было внедрено несколько инновационных для российского банковского рынка решений.',
            desc: 'Президент-председатель правления Лето Банка. Несмотря на&nbsp;спад потребительского кредитования в&nbsp;2015&nbsp;году, банк вышел на&nbsp;устойчивую прибыль, реализовав уникальную модель &laquo;легкого&raquo; банка, работающего без кассовых узлов. Под руководством Дмитрия Руденко было внедрено несколько инновационных для российского банковского рынка решений: замкнутый оборот наличных во&nbsp;всех банкоматах сети, биометрическое распознавание лиц для противодействия мошенничеству, технология real-time marketing, проект по&nbsp;дистанционному восстановлению лесов в&nbsp;национальных парках России, реализуемый с&nbsp;помощью банкоматов и&nbsp;сайта банка.',
        },
        {
            image: '/img-next/management/rudenko/biography/6.png',
            year: '2016',
            desc: 'С&nbsp;марта 2016 года по&nbsp;настоящее время&nbsp;&mdash; президент-председатель правления Почта Банка. Первый российский почтовый банк в&nbsp;течение трех лет открыл более 18&nbsp;000 точек облуживания по&nbsp;всей стране, в&nbsp;том числе на&nbsp;базе почтовых отделений.',
        },
    ],
};

export const mockBiographyBlockDataTina = {
    title: 'Заголовок',
    list: [
        {
            image: '',
            year: '',
            desc: 'Описание',
        },
    ],
};
