import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import ManagementInfoItem from '@/components/blocks/managementInfo/ManagementInfoItem';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import { IManagementItem } from '@/types/managementInfo/item';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

export interface IManagementInfo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'managementInfo';
    title: string;
    tagList?: ITag[];
    list: IManagementItem[];
}

const ManagementInfo: React.FC<IManagementInfo> = ({ list, title, tagList }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const items = useMemo(() => {
        if (!tagList?.length) return list?.map((item, index) => ({ ...item, pos: index }));
        return list?.reduce((acc: IManagementItem[], current, index) => {
            if (current?.tags?.includes(selectedTag)) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);
    }, [tagList, list, selectedTag]);

    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.managementInfo, 'section')}>
            <div className={cn.managementInfoWrap}>
                {title && (
                    <TextField
                        text={title}
                        customTag="h1"
                        name="title"
                        className={clsx(cn.title, cn.h1)}
                        isHTML
                        htmlValue={title}
                    />
                )}
                {tagList?.length > 0 && (
                    <div className={cn.filter}>
                        <TagList tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                    </div>
                )}
                <AnimatedComponent className={cn.panelWrap} classNameActive={cn.animationInit} key={selectedTag}>
                    <div className={cn.panel}>
                        <div className={cn.managementInfoGrid}>
                            {items?.length > 0 &&
                                items?.map(innerItem => (
                                    <div
                                        key={`key-${innerItem.pos}`}
                                        className={clsx(cn.item, innerItem.big && cn.itemBig)}
                                    >
                                        <ManagementInfoItem {...innerItem} pos={innerItem.pos} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default ManagementInfo;
