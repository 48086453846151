import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import Quote, { IQuote } from '@/components/blocks/aboutPB/Quote';
import QuotePerson from '@/components/blocks/aboutPB/QuotePerson';
import Arrows from '@/components/blocks/Questions/arrows';
import SidebarInfo, { ISidebarInfo } from '@/components/blocks/sidebar/SidebarInfo';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IQuotePersonItem } from '@/types/quotePerson/item';
import { TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IAboutPB {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'aboutPB';
    /**
     * Заголовок
     */
    name: string;
    /**
     * Заголовок
     */
    tabletName: string;
    /**
     * Текст
     */
    desc: string;
    sidebarInfo: ISidebarInfo;
    quote: IQuote;
    list?: IQuotePersonItem[];
}

const AboutPB: React.FC<IAboutPB> = ({ name, tabletName, desc, sidebarInfo, quote, list }) => {
    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;
    const isTablet = width < TABLET_WIDTH;

    const getTrigger = (trigger: string) => (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger} dangerouslySetInnerHTML={{ __html: trigger }} />
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </div>
    );

    return (
        <div className={clsx(cn.aboutPB, 'section')}>
            <div className={cn.wrap}>
                <div className={cn.grid}>
                    <div className={cn.innerWrapper}>
                        <div className={cn.content}>
                            {name && (
                                <TextField
                                    text={name}
                                    className={cn.mainTitle}
                                    name="name"
                                    customTag="h3"
                                    isHTML
                                    htmlValue={name}
                                />
                            )}
                            {isTablet ? (
                                <Collapsible
                                    className={cn.descCollapsible}
                                    openedClassName={clsx(cn.descCollapsible, cn.open)}
                                    trigger={getTrigger(tabletName)}
                                    triggerOpenedClassName={cn.open}
                                    triggerTagName="div"
                                    transitionTime={300}
                                    easing="ease-in-out"
                                    open
                                >
                                    <TextField text={desc} className={cn.desc} name="desc" isHTML htmlValue={desc} />
                                </Collapsible>
                            ) : (
                                <TextField text={desc} className={cn.desc} name="desc" isHTML htmlValue={desc} />
                            )}
                            {isDesktop && list?.length > 0 && <QuotePerson list={list} />}
                        </div>
                        <div className={cn.info}>
                            <div className={cn.quote}>
                                <Quote {...quote} />
                                {isMobile && list?.length > 0 && <QuotePerson list={list} />}
                            </div>
                            {isDesktop && (
                                <div className={cn.announcement}>
                                    <SidebarInfo namePrefix="sidebarInfo." {...sidebarInfo} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPB;
