import { IPurchases } from '@/components/blocks/purchases/Purchases';

export const mockPurchasesBlockData: IPurchases = {
    list: [
        {
            date: '13.12.2021',
            link: '#',
            title: 'Оказание услуг на осуществление строительного контроля и экспертизы выполняемых работ Исполнителем для размещения Дополнительной площадки центра поддержки Банка ВТБ (ПАО) по адресу г. Новосибирск, ул. Советская, д. 5',
            status: 'active',
        },
        {
            date: '13.12.2021',
            link: '#',
            title: 'Предквалификационный отбор (в электронной форме) по выбору контрагентов для участия в конкурентной процедуре закупи на право заключения договора на оказание услуг по хозяйственному обслуживанию помещений Банка ВТБ (ПАО) по адресу: г. Москва, Пресненская набережная, д.10, стр. 1, Башня «Евразия».',
            status: 'running',
        },
        {
            date: '13.12.2021',
            endDate: '13.12.2021',
            link: '#',
            title: 'Запрос о предоставлении ценовой информации на поставку оборудования и выполнение работ по заявкам для объектов Банка ВТБ (ПАО) в Северо-Западном федеральном округе',
            status: 'running',
        },
        {
            date: '13.12.2021',
            endDate: '13.12.2021',
            link: '#',
            title: 'Оказание услуг на осуществление строительного контроля и экспертизы выполняемых работ Исполнителем для размещения Дополнительной площадки центра поддержки Банка ВТБ (ПАО) по адресу г. Новосибирск, ул. Советская, д. 5',
            status: 'end',
        },
        {
            date: '13.12.2021',
            endDate: '13.12.2021',
            link: '#',
            title: 'Оказание услуг на осуществление строительного контроля и экспертизы выполняемых работ Исполнителем для размещения Дополнительной площадки центра поддержки Банка ВТБ (ПАО) по адресу г. Новосибирск, ул. Советская, д. 5',
            status: 'end',
        },
    ],
};

export const mockPurchasesBlockDataTina: IPurchases = {
    list: [
        {
            date: '',
            link: '',
            title: 'Заголовок',
            status: 'active',
        },
    ],
};
