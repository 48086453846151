export const mockSingleDocumentsBlockData = {
    title: 'Документы',
    documents: [
        {
            documentName:
                'Информация о квалификации и опыте работы лица, занимающего должность единоличного исполнительного органа, его заместителя, члена коллегиального исполнительного органа, главного бухгалтера, заместителя главного бухгалтера АО «Почта Банк»',
            document: '#',
            documentType: 'pdf',
        },
    ],
};

export const mockSingleDocumentsBlockDataTina = {
    title: 'Заголовок',
    documents: [
        {
            documentName: 'Документ',
            document: '#',
            documentType: 'pdf',
        },
    ],
};
