import Link from 'next/link';
import React, { FC, useState } from 'react';

import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';

interface IProps {
    link: string;
    children: React.ReactNode;
    isClickable: boolean;
    className: string;
}

const ItemTagMapper: FC<IProps> = ({ link, children, isClickable, className }) => {
    const [isInternalLink, setIsInternalLink] = useState<boolean>(false);

    useIsomorphicEffect(() => {
        const frontUrl = window.location.origin;
        const grepUrl = new RegExp(`${frontUrl}[\\W\\w]*`);
        setIsInternalLink(grepUrl.test(link));
    }, []);

    if (isClickable && isInternalLink) {
        return (
            <Link href={link} passHref className={className}>
                {children}
            </Link>
        );
    }

    if (isClickable) {
        return (
            <a className={className} href={link}>
                {children}
            </a>
        );
    }

    return <div className={className}>{children}</div>;
};

export default ItemTagMapper;
