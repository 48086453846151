import React from 'react';

import { TinaUniversalList } from '@/components/blocks/universalList/UniversalList/index.tina';

export const universalListBlock = {
    Component: props => <TinaUniversalList {...props} />,
    template: {
        label: 'Универсальный список',
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
            {
                name: 'section',
                component: 'section',
                withInfoBlock: true,
                label: 'Привязка к секции',
            },
        ],
    },
};
