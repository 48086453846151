import React from 'react';

import CustomImage from '@/components/CustomImage';
import Arrow from '@/components/svg/Arrow';
import { IFaqItem } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    activeItem: IFaqItem;
    handleOpen: () => void;
}

const Reason: React.FC<IProps> = ({ activeItem, handleOpen }) => (
    <div className={cn.reason} role="presentation" onClick={handleOpen}>
        <div className={cn.reasonInner}>
            <div className={cn.item}>
                {activeItem ? (
                    <div className={cn.content}>
                        <div className={cn.image}>
                            <CustomImage src={activeItem.image} width="46" height="46" alt="" />
                        </div>
                        <div className={cn.title} dangerouslySetInnerHTML={{ __html: activeItem.title }} />
                    </div>
                ) : (
                    'Выберите тему обращения'
                )}
            </div>
            <div className={cn.arrow}>
                <Arrow />
            </div>
        </div>
    </div>
);
export default Reason;
