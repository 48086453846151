import React, { useMemo, useState } from 'react';

import FilterItem from '@/components/blocks/dictionary/FilterItem';
import Search from '@/components/blocks/dictionary/Search';
import SidebarItem from '@/components/blocks/dictionary/SidebarItem';
import { IDictionary, IDictionaryItem, IDictionarySection } from '@/types/dictionary';
import { ISelectElement } from '@/types/select';

import cn from './style.module.sass';

const Dictionary: React.FC<IDictionary> = ({ sections }) => {
    const sortSections: IDictionarySection[] = useMemo(() => {
        const sortArray = [...sections];
        return sortArray.sort((a, b) => a.label.localeCompare(b.label));
    }, [sections]);

    const [initialActiveSection, initialActiveList] = useMemo((): [IDictionarySection, IDictionaryItem] => {
        const activeSection = sortSections?.find(item => !!item?.list?.length);
        return [activeSection, activeSection?.list[0]];
    }, [sortSections]);

    const [activeSection, setActiveSection] = useState(initialActiveSection);
    const [activeItem, setActiveItem] = useState(initialActiveList);
    const [searchValue, setSearchValue] = useState('');

    const sectionChangeHandler = (section: IDictionarySection) => {
        setActiveSection(section);
        setActiveItem(section?.list[0]);
    };

    const changeActiveItemHandler = (item: IDictionaryItem) => {
        setActiveItem(item);
    };

    const onSearchValueChange = (value: string) => {
        setSearchValue(value);
    };

    const onChoose = (item: ISelectElement<string>) => {
        const [sectionPosition, listPosition] = item.value.split('.');
        setActiveSection(sortSections?.[+sectionPosition]);
        setActiveItem(sortSections?.[+sectionPosition].list[+listPosition]);
    };

    const activeSectionList: IDictionarySection['list'] = useMemo(
        () => sortSections?.filter(item => item?.label === activeSection?.label)?.[0]?.list,
        [activeSection, sortSections]
    );

    if (sections?.length <= 0) return null;

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.wrapper}>
                    <div className={cn.search}>
                        <Search
                            value={searchValue}
                            onChange={onSearchValueChange}
                            sections={sortSections}
                            onChoose={onChoose}
                        />
                    </div>
                    <div className={cn.filter}>
                        <div className={cn.filterContainer}>
                            {sortSections?.map((item, index) => (
                                <FilterItem
                                    item={item}
                                    activeSection={activeSection}
                                    key={index}
                                    sectionChangeHandler={sectionChangeHandler}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={cn.list}>
                        <div className={cn.listSidebar}>
                            <div className={cn.listSidebarInner}>
                                {activeSectionList?.map((item, index) => (
                                    <SidebarItem
                                        key={index}
                                        item={item}
                                        activeItem={activeItem}
                                        changeActiveItemHandler={changeActiveItemHandler}
                                    />
                                ))}
                            </div>
                        </div>
                        <div
                            className={cn.listActiveElement}
                            dangerouslySetInnerHTML={{ __html: activeItem?.description }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dictionary;
