import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import ProfitabilityCalculatorContent from '@/components/blocks/calculators/layouts/ProfitabilityCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import ProfitabilityCalculatorStore from '@/domain/calculator/ProfitabilityCalculatorStore';
import Feature from '@/domain/feature/Feature';
import { ITermItem } from '@/types/calculator/config';
import { IProfitabilityCalculatorDataItem, TProfitabilityCalculator } from '@/types/calculator/profitability';

function ProfitabilityCalculatorWrapper({
    title,
    isActiveRefill,
    data,
    termsTitleFlag,
    index,
    gtag,
    button,
    enableCheckbox,
    _template,
    filterElements,
    terms,
    termsActiveIndex,
}: TProfitabilityCalculator) {
    let filterElementsNew: ICalculatorFilterElement[];
    let termsNew: ITermItem[];

    if (Feature.isFeatureEnabled('enableProductParameterCalc')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsData: IProfitabilityCalculatorDataItem[] = JSON.parse(data);

        filterElementsNew = [
            {
                value: Number(parsData[0]?.amountFrom),
                step: 5000,
                percent: 0,
                label: parsData[0]?.amountFromLabel,
                id: 1,
            },
            {
                value: Number(parsData[0]?.amountTo),
                step: 5000,
                percent: 100,
                label: parsData[0]?.amountToLabel,
                id: 2,
            },
        ];

        termsNew = parsData?.map((item, itemIndex) => ({
            id: itemIndex,
            label: item?.label,
            value: item?.periodFromDay,
            calculatedValue: item?.periodFromDay,
            ranges: [
                {
                    id: 1,
                    min: Number(item?.amountFrom) || 0,
                    max: Number(item?.amountTo) || 0,
                    percent: item?.rate,
                },
            ],
        }));
    } else {
        const tempFilterElements = filterElements?.filter(
            item =>
                item.value >= 0 &&
                item.value.toString().length > 0 &&
                item.percent >= 0 &&
                item.percent.toString().length > 0
        );
        filterElementsNew = tempFilterElements?.reduce((acc: ICalculatorFilterElement[], curr) => {
            if (!acc.find(item => item.id === curr.id)) {
                acc.push(curr);
            }
            return acc;
        }, []);

        termsNew = terms?.reduce((acc: ITermItem[], curr) => {
            if (!acc.find(item => item.id === curr.id)) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }

    useEffect(() => {
        ProfitabilityCalculatorStore.init(filterElementsNew, termsNew, enableCheckbox, _template, termsActiveIndex);
    }, [filterElements, terms, enableCheckbox, termsActiveIndex]);

    useEffect(() => {
        if (!termsNew) {
            ProfitabilityCalculatorStore.initialized = false;
        } else {
            ProfitabilityCalculatorStore.init(filterElementsNew, termsNew, enableCheckbox, _template, termsActiveIndex);
        }
    }, [filterElements, terms, enableCheckbox, termsActiveIndex]);

    if (!termsNew) return null;

    return (
        <CalculatorStoreContext.Provider value={ProfitabilityCalculatorStore}>
            {ProfitabilityCalculatorStore.initialized && (
                <ProfitabilityCalculatorContent
                    title={title}
                    index={index}
                    gtag={gtag}
                    button={button}
                    enableCheckbox={enableCheckbox}
                    termsTitleFlag={termsTitleFlag}
                    isActiveRefill={isActiveRefill}
                />
            )}
        </CalculatorStoreContext.Provider>
    );
}
export default observer(ProfitabilityCalculatorWrapper);
