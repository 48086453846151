import React from 'react';

import { TinaOnlineStorePartners } from '@/components/blocks/frames/OnlineStorePartners/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const onlineStorePartnersBlock = {
    Component: props => <TinaOnlineStorePartners {...props} />,
    template: {
        label: 'Онлайн магазины: Заявка на партнерство',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
