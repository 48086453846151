import React from 'react';

import { TinaLinks } from '@/components/blocks/Links/index.tina';
import { mockLinksBlockTinaData } from '@/data/blocks/links';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const linksBlock = {
    Component: props => <TinaLinks {...props} />,
    template: {
        label: 'Блок перелинковки',
        defaultItem: () => mockLinksBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Список ссылок',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockLinksBlockTinaData.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Кнопка',
                        fields: [
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Название',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
