import React from 'react';

import { TinaInstructionSlider } from '@/components/blocks/InstructionSlider/index.tina';
import { mockInstructionSliderBlockDataTina } from '@/data/instructionSlider';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const instructionSliderBlock = {
    Component: props => <TinaInstructionSlider {...props} />,
    template: {
        label: 'Слайдер: Инструкции',
        defaultItem: () => mockInstructionSliderBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                label: 'Список элементов',
                name: 'items',
                component: 'group-list',
                itemProps: (item: { text: string }, index: number) => ({
                    key: index,
                    label: item.text,
                }),
                defaultItem: () => mockInstructionSliderBlockDataTina.items[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
