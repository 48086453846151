export const productAndServicesMock = {
    _template: 'productsAndServices',
    title: 'Заголовок блока',
    list: [
        {
            cardTitle: 'Заголовок 1',
            cardDescription: 'Описание',
            width: 'large',
            isLineNeeded: true,
            isActual: true,
            actualColor: 'white',
            color: 'bright-purple',
            desktopImage:
                'https://files.pochtabank.ru/medialibrary/0e1/0e1715f7993721208e6179492d06fa3a/37eb15cd67bed3cbb66c3642b13928a8.png?id=27516',
            mobileImage:
                'https://files.pochtabank.ru/medialibrary/674/6749dcf7b837822634379e51e5207c45/2679ad477806d813e54d335471ce29b4.png?id=26714',
            imagePosition: 'right',
            linkText: 'Ссылка Кнопка',
            linkHref: '#',
            linkStyle: 'button',
            size: 'full',
            buttonStyle: 'lightBlueWithWhite',
        },
        {
            cardTitle: 'Заголовок 2',
            cardDescription: 'Описание',
            width: 'medium',
            isLineNeeded: true,
            isActual: true,
            actualColor: 'white',
            color: 'bright-purple',
            desktopImage:
                'https://files.pochtabank.ru/medialibrary/0e1/0e1715f7993721208e6179492d06fa3a/37eb15cd67bed3cbb66c3642b13928a8.png?id=27516',
            mobileImage:
                'https://files.pochtabank.ru/medialibrary/674/6749dcf7b837822634379e51e5207c45/2679ad477806d813e54d335471ce29b4.png?id=26714',
            imagePosition: 'right',
            linkText: 'Ссылка Кнопка',
            linkHref: '#',
            linkStyle: 'button',
            size: 'large',
            buttonStyle: 'whiteWithBlack',
        },
        {
            cardTitle: 'Заголовок 3',
            cardDescription: 'Описание',
            width: 'small',
            isLineNeeded: true,
            isActual: true,
            actualColor: 'white',
            color: 'bright-purple',
            desktopImage:
                'https://files.pochtabank.ru/medialibrary/0e1/0e1715f7993721208e6179492d06fa3a/37eb15cd67bed3cbb66c3642b13928a8.png?id=27516',
            mobileImage:
                'https://files.pochtabank.ru/medialibrary/674/6749dcf7b837822634379e51e5207c45/2679ad477806d813e54d335471ce29b4.png?id=26714',
            imagePosition: 'right',
            linkText: 'Ссылка Кнопка',
            linkHref: '#',
            linkStyle: 'button',
            size: 'extra-small',
            buttonStyle: 'whiteWithBlack',
        },
    ],
};
