import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { options } from '@/data/blocks/feedbackFormData';

export interface IFeedbackForm {
    _template: 'feedbackForm';
    title?: string;
    description?: string;
    successTitle?: string;
}

const FeedbackForm: React.FC<IFeedbackForm> = ({ title, description, successTitle, _template = 'feedbackForm' }) => (
    <FeedbackFormContent
        templateName={_template}
        options={options}
        title={title}
        description={description}
        successTitle={successTitle}
    />
);

export default FeedbackForm;
