import { useRouter } from 'next/navigation';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { FRONT_URL } from '@/utils/constants';

import cn from './style.module.sass';

export interface IThreeInARowItem {
    icon: string;
    title: string;
    subtitle?: string;
    link?: string;
}

function ThreeInARowItem({ title, subtitle, icon, link }: IThreeInARowItem) {
    const router = useRouter();

    const goTo = () => {
        if (link) {
            const isInnerNavigation = link.includes(FRONT_URL);
            if (isInnerNavigation) {
                router.push(link);
                return;
            }
            window.location.assign(link);
        }
    };

    return (
        <AnimatedComponent className={cn.threeInARowItemWrap} classNameActive={cn.animationInit} role="presentation">
            <div className={cn.threeInARowItem} onClick={goTo} role="presentation">
                <div className={cn.iconWrap}>
                    <CustomImage src={icon} width={32} height={32} alt={title} />
                </div>
                <div className={cn.textWrap}>
                    <TextField text={title} name="name" className={cn.threeInARowItemTitle} isHTML htmlValue={title} />
                    {subtitle && (
                        <TextField
                            text={subtitle}
                            name="subtitle"
                            className={cn.threeInARowItemSubtitle}
                            isHTML
                            htmlValue={subtitle}
                        />
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default ThreeInARowItem;
