export const guaranteeCalculator = {
    title: 'Рассчитайте свой доход',
    filterElements: [
        {
            value: 30000,
            step: 5000,
            percent: 0,
            label: '30 тыс.',
            id: 1,
        },
        {
            value: 1000000,
            step: 5000,
            percent: 50,
            label: '1 млн.',
            id: 2,
        },
        {
            value: 3000000,
            step: 5000,
            percent: 100,
            label: '3 млн.',
            id: 3,
        },
    ],

    minValue: 30000,
    maxvalue: 3000000,
    cashback: 12,
    taxDeductionPercentage: 13,
};
