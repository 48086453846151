import React from 'react';

import { TinaMoneyBoxAccountCalculator } from '@/components/blocks/calculators/layouts/MoneyBoxAccountCalculator/index.tina';
import { moneyBoxAccountCalculatorData } from '@/data/blocks/calculators/moneyBoxAccount';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const moneyBoxAccountCalculatorBlock = {
    Component: props => <TinaMoneyBoxAccountCalculator {...props} />,
    template: {
        label: 'Калькулятор | Счет "Копилка"',
        defaultItem: moneyBoxAccountCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
