import React from 'react';

import cn from '@/components/UI/ShareIconBlock/style.module.sass';

function OkIconItem() {
    return (
        <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4419 6.01694C12.4419 9.04556 9.9586 11.5007 6.89535 11.5007C3.83366 11.497 1.35259 9.04402 1.34883 6.01698C1.34883 2.98836 3.83206 0.533203 6.89535 0.533203C9.95864 0.533203 12.4419 2.98832 12.4419 6.01694ZM9.88196 6.01698C9.88196 4.38617 8.54479 3.06417 6.89535 3.06417C5.2459 3.06417 3.90873 4.38617 3.90873 6.01698C3.90873 7.6478 5.24586 8.96979 6.89535 8.96979C8.54483 8.96979 9.88196 7.6478 9.88196 6.01698ZM13.295 12.3274C13.2941 13.041 12.9309 13.7066 12.3281 14.0991C11.5561 14.6066 10.7151 15.0035 9.83056 15.2777C9.67924 15.3232 9.52365 15.3663 9.36381 15.4067L11.9383 17.8972C11.9525 17.9107 11.9664 17.9245 11.9802 17.9385C12.6359 18.6078 12.6187 19.6761 11.9418 20.3244C11.2648 20.9728 10.1843 20.9558 9.52853 20.2865L6.89518 17.5918L4.26696 20.2814C4.25336 20.2954 4.23952 20.3091 4.22544 20.3226C3.54893 20.9716 2.46852 20.9554 1.81217 20.2866C1.15582 19.6177 1.17218 18.5495 1.84865 17.9006L4.42735 15.4076C4.26748 15.3682 4.11248 15.3255 3.96228 15.2794C3.07674 15.0041 2.235 14.6064 1.46206 14.0982C0.859591 13.7054 0.496519 13.0401 0.495239 12.3266C0.487759 11.7345 0.825952 11.1913 1.3639 10.9312C1.91725 10.6601 2.57799 10.7215 3.07054 11.0898C5.34981 12.7327 8.44123 12.7327 10.7205 11.0898C11.2123 10.7196 11.8742 10.6581 12.4271 10.9312C12.9653 11.1913 13.3034 11.7352 13.295 12.3274Z"
                className={cn.svgFillInner}
            />
        </svg>
    );
}

export default OkIconItem;
