import React from 'react';

import cn from './style.module.sass';

function OkIcon() {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="31" height="31" rx="8" className={cn.svgFill} />
            <path
                d="M15.5334 7.23303C13.4957 7.23303 11.816 8.8852 11.816 10.9504C11.816 12.9881 13.4682 14.6678 15.5334 14.6678C17.5986 14.6678 19.2508 13.0156 19.2508 10.9504C19.2508 8.8852 17.5711 7.23303 15.5334 7.23303ZM15.5334 12.1895C14.845 12.1895 14.2943 11.6388 14.2943 10.9504C14.2943 10.262 14.845 9.71129 15.5334 9.71129C16.2218 9.71129 16.7725 10.262 16.7725 10.9504C16.7725 11.6388 16.2218 12.1895 15.5334 12.1895Z"
                className={cn.svgFillInner}
            />
            <path
                d="M20.1039 17.174C19.1677 17.6971 18.1488 18.0551 17.13 18.2203L20.0213 21.7725C20.3517 22.1855 20.4343 22.7638 20.1314 23.2044C19.6633 23.9203 18.672 23.9479 18.1764 23.3145L15.5054 20.0377L12.8619 23.3145C12.6141 23.6174 12.2836 23.7551 11.9257 23.7551C11.5677 23.7551 11.2097 23.5899 10.9619 23.2594C10.659 22.8464 10.6865 22.158 11.017 21.745L13.8807 18.1653C12.8619 17.9725 11.8431 17.6421 10.9344 17.1189C10.3561 16.7885 10.1634 16.0725 10.4938 15.4667C10.8242 14.8885 11.5677 14.6957 12.146 15.0261C14.2387 16.2102 16.8546 16.2102 18.9749 15.0537C19.5532 14.7232 20.2967 14.9435 20.5996 15.5218C20.9025 16.1276 20.6822 16.8435 20.1039 17.174Z"
                className={cn.svgFillInner}
            />
        </svg>
    );
}

export default OkIcon;
