import React from 'react';

import { TinaRkoCalculator } from '@/components/blocks/calculators/layouts/RkoCalculator/index.tina';
import { rkoCalculatorData } from '@/data/blocks/calculators/rko';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const rkoCalculatorBlock = {
    Component: props => <TinaRkoCalculator {...props} />,
    template: {
        label: 'Калькулятор РКО',
        defaultItem: () => rkoCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список',
                name: 'items',
                component: 'group-list',
                description: 'Список информационных блоков',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => rkoCalculatorData.items[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'price',
                        component: 'textarea',
                        label: 'Цена',
                    },
                    {
                        name: 'tariff',
                        component: 'textarea',
                        label: 'Тариф',
                    },
                    {
                        name: 'listFree',
                        component: 'textarea',
                        label: 'Бесплатно',
                    },
                    {
                        name: 'listDesc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'listBottom',
                        component: 'textarea',
                        label: 'Нижний текст',
                    },
                    {
                        name: 'itemUrl',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
