import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo } from 'react';

import Banner from '@/components/blocks/calculators/Banner';
import CalculatorConclusions from '@/components/blocks/calculators/CalculatorConclusions';
import Conditions from '@/components/blocks/calculators/Conditions';
import InfoTitle, { ICalculatorDisclaimer } from '@/components/blocks/calculators/InfoTitle';
import SharingButton from '@/components/blocks/calculators/layouts/SecondaryCashCalculator/Content/sharingButton';
import RangeInputSecondary from '@/components/blocks/calculators/range/deprecated/RangeInputSecondaryDeprecated';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import SecondaryCalculatorStoreContext from '@/context/SecondaryCalculatorStoreContext';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface ISecondaryCalculatorBannerRanges {
    title: string;
    subtitle: string;
    min: number;
    max: number;
    image?: string;
}

interface ISecondaryCalculatorBanner {
    ranges?: ISecondaryCalculatorBannerRanges[];
    isActive: boolean;
}

export interface ISecondaryCalculator {
    title?: string;
    tabTitle?: string;
    banner?: ISecondaryCalculatorBanner;
    button?: {
        label: string;
        link: string;
    };
    infoTitle?: ICalculatorDisclaimer;
    buttonShared?: {
        isActive: boolean;
    };
    index: number;
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
}

// @Todo ИСПОЛЬЗОВАТЬ НОВЫЕ СТАНДАРТ КАЛЬКУЛЯТОРОВ. ОТКАЗАЛИСЬ ОТ ABSTRACTSTORE. УБРАТЬ ДУБЛЯЖИ

const SecondaryCashCalculatorContent: React.FC<ISecondaryCalculator> = observer(
    ({ title, index, gtag, banner, button, buttonShared, infoTitle }) => {
        const { getMonthlyPayment, monthlyPayment, value } = useContext(SecondaryCalculatorStoreContext);
        const width = useResize();

        const formattedMonthlyPayment = `${monthlyPayment.total
            .toString()
            // eslint-disable-next-line no-irregular-whitespace
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;

        const isMobile = width <= MOBILE_WIDTH;

        const formattedRate = `${monthlyPayment.rate}%`;

        useEffect(() => {
            getMonthlyPayment();
        }, []);

        const currentBanner = useMemo(() => {
            const currentRanges = banner?.ranges?.find(range => range.min - 1 <= +value && range.max - 1 >= +value);
            return { ...currentRanges, title: currentRanges.title ? formattedRate : '' };
        }, [banner, value]);

        const currentInfoTitle = useMemo(
            () => infoTitle?.ranges?.find(range => range.min - 1 <= +value && range.max - 1 >= +value),
            [infoTitle, value]
        );

        return (
            <div className="section">
                <div className={cn.container}>
                    <div className={cn.wrapper}>
                        {title && !banner?.isActive && (
                            <TextField
                                text={title}
                                customTag="h2"
                                name="title"
                                className={cn.title}
                                isHTML
                                htmlValue={title}
                            />
                        )}
                        <div className={cn.content}>
                            <div className={cn.left}>
                                {title && banner?.isActive && (
                                    <TextField
                                        text={title}
                                        customTag="h2"
                                        name="title"
                                        isHTML
                                        htmlValue={title}
                                        className={cn.title}
                                    />
                                )}
                                <RangeInputSecondary gtag={gtag?.gtagSlider} />
                                {!isMobile && (
                                    <div className={cn.info}>
                                        {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                    </div>
                                )}
                            </div>
                            <div className={cn.right}>
                                {banner?.isActive && <Banner isActive={banner.isActive} banner={currentBanner} />}
                                <div className={cn.rightWrapper}>
                                    <CalculatorConclusions
                                        title="Наше предложение"
                                        sum={formattedMonthlyPayment}
                                        percent={formattedRate}
                                        hideRate={+value < 500000}
                                    />
                                    <Conditions isEarningShow={false} />
                                    <div className={cn.buttonWrapper}>
                                        {+value < 500000 && (
                                            <Button
                                                variant="btnBlue"
                                                type="link"
                                                href={button?.link || '/service/cashcredit#calculation'}
                                                label={button?.label || 'Заполнить заявку'}
                                                customClass={cn.button}
                                                onClick={() => {
                                                    if (gtag?.gtagButton) event(gtag.gtagButton);
                                                }}
                                                animation={false}
                                            />
                                        )}
                                        {+value >= 500000 && (
                                            <Button
                                                variant="btnBlue"
                                                type="link"
                                                href={button?.link || '/service/cashcredit#calculation'}
                                                label={button?.label || 'Подробнее'}
                                                customClass={cn.button}
                                                onClick={() => {
                                                    if (gtag?.gtagButton) event(gtag.gtagButton);
                                                }}
                                                animation={false}
                                            />
                                        )}
                                    </div>
                                    {buttonShared?.isActive && <SharingButton />}
                                </div>
                                {isMobile && (
                                    <div className={cn.info}>
                                        {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default SecondaryCashCalculatorContent;
