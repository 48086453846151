import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { IUniversalListFilter } from '@/types/universalList';

import cn from './style.module.sass';

interface IProps {
    filter: IUniversalListFilter;
    onFilterClick: () => void;
}

const FilterElement: React.FC<IProps> = ({ filter, onFilterClick }) => {
    if (!filter?.link) return null;

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!filter?.isActive) {
            onFilterClick();
            return;
        }
        e.preventDefault();
    };

    return (
        <Link
            href={filter?.link}
            prefetch={false}
            type="button"
            onClick={onClick}
            className={clsx(cn.filterItem, filter?.isActive && cn.active)}
        >
            <div className={cn.filterLink}>{filter?.text}</div>
        </Link>
    );
};

export default FilterElement;
