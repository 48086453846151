export const mockCreditGiftBlockBlockDataTina = {
    image: '',
    title: 'Заголовок',
    desc: 'Описание',
    promoData: {
        promoPeriodTitle: 'Заголовок',
        promoPeriod: 'Контент',
        promoDateTitle: 'Заголовок',
        promoDate: 'Контент',
        promoQuantityTitle: 'Заголовок',
        promoQuantity: 'Контент',
    },
    button: {
        link: '#',
        linkName: 'Подать заявку',
        gtag: {
            action: 'click',
            category: 'Interactions',
            label: ' label',
        },
    },
    link: {
        linkName: 'Подробнее об акции',
        gtag: {
            action: 'click',
            category: 'Interactions',
            label: ' label',
        },
    },
    modal: {
        modalTitle: 'Как получить 2 000 ₽\n',
        modalDescription:
            'Шаг 1. Обратитесь в отделение Социального фонда (ранее Пенсионный фонд), флагманский клиентский центр банка или отделение банка на Почте России.\n' +
            'Шаг 2. После того как пенсия или пособие поступит на Сберегательный счёт — начислим 2 000 ₽ в следующем месяце',
    },
};

export const mockCreditGiftBlockBlockData = {
    image: '/img-next/png/creditGift.png',
    title: 'Кредит в подарок',
    desc: 'Хотите получить кредит без возврата средств? Тогда подайте заявку на нашем сайте на сумму от 30 тыс. до 6 млн рублей и получите шанс стать 1 из 10 счастливчиков.',
    promoData: {
        promoPeriod: '20.02.24 — 28.02.24',
        promoPeriodTitle: 'Сроки акции',
        promoDate: '01.03.2024',
        promoDateTitle: 'Дата розыгрыша',
        promoQuantity: '10 победителей',
        promoQuantityTitle: 'Количество победителей',
    },
    button: {
        link: '#',
        linkName: 'Подать заявку',
        gtag: {
            action: 'click',
            category: 'Interactions',
            label: ' label',
        },
    },
    link: {
        linkName: 'Подробнее об акции',
        gtag: {
            action: 'click',
            category: 'Interactions',
            label: ' label',
        },
    },
    modal: {
        modalTitle: 'Как получить 2 000 ₽\n',
        modalDescription:
            'Шаг 1. Обратитесь в отделение Социального фонда (ранее Пенсионный фонд), флагманский клиентский центр банка или отделение банка на Почте России.\n' +
            'Шаг 2. После того как пенсия или пособие поступит на Сберегательный счёт — начислим 2 000 ₽ в следующем месяце',
    },
};
