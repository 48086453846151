export const mockTariffDocumentsArchive = {
    filter: [
        {
            value: 'Сборники тарифов',
            xmlId: 'code1',
        },
        {
            value: 'Потребительский кредит',
            xmlId: 'code2',
        },
        {
            value: 'Кредиты для пенсионеров',
            xmlId: 'code3',
        },
        {
            value: 'Кредит на образование',
            xmlId: 'code4',
        },
        {
            value: 'Покупки в кредит',
            xmlId: 'code5',
        },
        {
            value: 'Лето-карта',
            xmlId: 'code6',
        },
        {
            value: 'Кредитные карты',
            xmlId: 'code7',
        },
        {
            value: 'Дебетовые карты',
            xmlId: 'code8',
        },
        {
            value: 'Вклады',
            xmlId: 'code9',
        },
        {
            value: 'Сберегательный счёт',
            xmlId: 'code10',
        },
        {
            value: 'Корпоративные тарифы',
            xmlId: 'code11',
        },
        {
            value: 'Почта Банк Онлайн',
            xmlId: 'code12',
        },
        {
            value: 'РКО для бизнеса',
            xmlId: 'code13',
        },
        {
            value: 'Анкеты и приложения',
            xmlId: 'code14',
        },
        {
            value: 'Страховые продукты',
            xmlId: 'code15',
        },
    ],
    list: [
        {
            xmlId: 'code1',
            sections: [
                {
                    date: '01 июля 2021',
                    documents: [
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 11.06.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName:
                                'Правила проведения промо-акции «Великолепная семёрка» по Сберегательному счёту',
                            documentSize: '999MB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },

                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '30 июня 2021',
                    documents: [
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитна карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки» по карте к Сберегательному счёту для пенсионных клиентов в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 30.03.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки в категории Супермаркеты для пенсионных клиентов» в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '28 июня 2021',
                    documents: [
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 11.06.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName:
                                'Правила проведения промо-акции «Великолепная семёрка» по Сберегательному счёту',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '25 июня 2021',
                    documents: [
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки» по карте к Сберегательному счёту для пенсионных клиентов в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 30.03.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки в категории Супермаркеты для пенсионных клиентов» в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '01 июля 2021',
                    documents: [
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 11.06.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName:
                                'Правила проведения промо-акции «Великолепная семёрка» по Сберегательному счёту',
                            documentSize: '999MB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },

                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '30 июня 2021',
                    documents: [
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитна карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки» по карте к Сберегательному счёту для пенсионных клиентов в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 30.03.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки в категории Супермаркеты для пенсионных клиентов» в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '28 июня 2021',
                    documents: [
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 11.06.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName:
                                'Правила проведения промо-акции «Великолепная семёрка» по Сберегательному счёту',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
                {
                    date: '25 июня 2021',
                    documents: [
                        {
                            documentName: 'Тариф «Запасной кошелёк 10000» по программе «Кредитная карта»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки» по карте к Сберегательному счёту для пенсионных клиентов в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName: 'Сборник тарифов для физических лиц (действует с 30.03.2021)',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                        {
                            documentName:
                                'Условия Акции «Бонусы за покупки в категории Супермаркеты для пенсионных клиентов» в рамках Программы лояльности «Мультибонус»',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.xls',
                            documentType: 'xls',
                        },
                    ],
                },
            ],
        },
    ],
};

export const mockTariffDocumentsArchiveTina = {
    filter: [
        {
            value: 'Фильтр',
            xmlId: 'code1',
        },
    ],
    list: [
        {
            xmlId: 'code1',
            sections: [
                {
                    date: '01 июля 2021',
                    documents: [
                        {
                            documentName: 'Название',
                            documentSize: '64KB',
                            document: '/sourseToFile/ourFile.pdf',
                            documentType: 'pdf',
                        },
                    ],
                },
            ],
        },
    ],
};
