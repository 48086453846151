import React from 'react';

import { TinaParametersBonds } from '@/components/blocks/bonds/ParametersBonds/index.tina';
import { mockParametersBondsBlockTinaData } from '@/data/blocks/parametersBonds';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const parametersBondsBlock = {
    Component: props => <TinaParametersBonds {...props} />,
    template: {
        label: 'Блок с параметрами облигаций',
        defaultItem: () => mockParametersBondsBlockTinaData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { itemTitle: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.itemTitle,
                }),
                defaultItem: () => mockParametersBondsBlockTinaData.list[0],
                fields: [
                    {
                        name: 'itemTitle',
                        component: HtmlEditorFeatured,
                        label: 'Название',
                    },
                    {
                        name: 'itemValue',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
