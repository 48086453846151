import React from 'react';

import { TinaMainArticlesSlider } from '@/components/blocks/MainArticlesSlider/index.tina';
import { mockMainArticlesSliderBlockData } from '@/data/blocks/mainArticlesSlider';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const mainArticlesSliderBlock = {
    Component: props => <TinaMainArticlesSlider {...props} />,
    template: {
        label: 'Статьи для главной страницы',
        defaultItem: () => mockMainArticlesSliderBlockData,
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
            {
                name: 'section',
                component: 'section',
                withInfoBlock: true,
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'disableSlider',
                component: 'toggle',
                label: 'Отключить слайдер',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
