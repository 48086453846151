import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

import PaginationIconArrow from '@/components/UI/PaginationIconArrow';

import cn from './style.module.sass';

interface IPaginationProps {
    pageCount: number;
    initialPage?: number;
    tag: string;
    handleClick: (page: ISelected) => void;
}

export interface ISelected {
    selected: number;
}

const Pagination: FC<IPaginationProps> = ({ pageCount, initialPage, tag, handleClick }) => {
    const pageRangeDisplayed = 3;

    if (pageCount < 2) {
        return null;
    }

    return (
        <div className={cn.paginationContainer}>
            <ReactPaginate
                pageCount={pageCount}
                onPageChange={handleClick}
                breakLabel="..."
                forcePage={initialPage}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={1}
                disableInitialCallback
                hrefAllControls
                hrefBuilder={t =>
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    t >= 1 && t <= pageCount ? `/articles?page=${t}&activeTag=${tag}` : '#'
                }
                nextLabel={<PaginationIconArrow color="blue" back={false} isHovering={false} />}
                previousLabel={<PaginationIconArrow color="blue" back isHovering={false} />}
                containerClassName={cn.pagination}
                pageClassName={cn.paginationItem}
                previousClassName={cn.paginationPrevious}
                nextClassName={cn.paginationNext}
                pageLinkClassName={cn.paginationLink}
                disabledLinkClassName={cn.paginationDisabledLink}
                activeLinkClassName={cn.paginationActiveLink}
                previousLinkClassName={cn.paginationLink}
                nextLinkClassName={cn.paginationLink}
                breakClassName={cn.paginationBreak}
            />
        </div>
    );
};

export default Pagination;
