import React from 'react';

import Ellipse from '@/components/blocks/SalesHRLanding/core/Icons/Ellipse';
import Polygon from '@/components/blocks/SalesHRLanding/core/Icons/Polygon';
import Star from '@/components/blocks/SalesHRLanding/core/Icons/Star';
import { SALES_OFFICES_KEY } from '@/components/blocks/SalesHRLanding/helpers/constants/menu';

export const offers = [
    {
        id: SALES_OFFICES_KEY,
        icon: '/img-next/jpg/hr-landing/offers/offer-1.jpg',
        title: 'Работа в&nbsp;офисах продаж',
        buttonValue: 'Хочу в команду',
        list: {
            title: 'Вы будете:',
            items: [
                {
                    icon: <Ellipse fill="#504fd7" />,
                    name: 'Работать с&nbsp;клиентами в&nbsp;офисах продаж или на&nbsp;территории магазина-партнера',
                },
                {
                    icon: <Polygon fill="#504fd7" />,
                    name: 'Консультировать клиентов и&nbsp;подбирать продукты банка',
                },
                {
                    icon: <Star fill="#504fd7" />,
                    name: 'Оформлять необходимые документы',
                },
            ],
        },
    },
];
