import clsx from 'clsx';
import React, { VFC } from 'react';

import cn from '@/components/blocks/PartnersMap/style.module.sass';

export type BalloonCustomProps = {
    logoPartner: string;
    namePartner: string;
    partnerLink: string;
    city: string;
    mp?: string;
    regionalCenter: string;
    address: string;
    openingHours?: string;
    phones: string;
    specialization: string;
    statementLink?: string;
    webCode?: string;
};

const BalloonCustom: VFC<BalloonCustomProps> = ({
    logoPartner,
    namePartner,
    partnerLink,
    address,
    openingHours,
    phones,
    specialization,
    statementLink,
}) => (
    <div className={cn.balloon}>
        <div className={cn.balloonHead}>
            {logoPartner && (
                <div className={cn.balloonLink}>
                    <img src={logoPartner} alt="logo" className={cn.balloonLogo} />
                </div>
            )}
            {namePartner && <div className={cn.balloonTitle}>{namePartner}</div>}
        </div>
        <div className="globalArrow">
            <div className={cn.balloonDesc}>Контакты</div>
            <div className="balloonArrow" />
        </div>
        <div className="balloonBody">
            <div className={cn.balloonFieldTitle}>адрес</div>
            {address && <span className={cn.balloonField}>{address}</span>}
            <div className={cn.balloonFieldTitle}>часы работы</div>
            {openingHours && <span className={cn.balloonField}>{openingHours}</span>}
            <div className={cn.balloonFieldTitle}>телефон</div>
            {phones && <span className={cn.balloonField}>{phones}</span>}

            {specialization && (
                <>
                    <div className={cn.balloonFieldTitle}>Специализация</div>
                    <div className={cn.balloonField} dangerouslySetInnerHTML={{ __html: specialization }} />
                </>
            )}
        </div>

        <div className={cn.links}>
            {statementLink && (
                <a href={statementLink} className={cn.balloonButton}>
                    Заполнить заявку
                </a>
            )}
            {partnerLink && (
                <a
                    href={partnerLink}
                    target="_blank"
                    rel="noreferrer"
                    className={clsx(cn.balloonButton, cn.balloonButtonPartners)}
                >
                    Сайт партнера
                </a>
            )}
        </div>
    </div>
);

export default BalloonCustom;
