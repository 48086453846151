export const mockGuitarBannerBlockData = {
    title: 'Ищите концерты спектакли и выставки на Витрине Билетов',
    desktopImage: '/img-next/png/banners/guitar.png',
    mobileImage: '/img-next/png/banners/guitarMobile.png',
    name: 'guitarBanner',
    link: 'Ссылка для якоря заполняется без решетки',
    type: 'anchor',
};

export const mockGuitarBannerBlockDataTina = {
    title: 'Заголовок',
    desktopImage: '',
    mobileImage: '',
    name: 'guitarBanner',
    link: 'Ссылка для якоря заполняется без решетки',
    type: 'anchor',
};
