export const mockQuotesCarouselDefaultData = {
    items: [
        {
            photo: '/img-next/jpg/management/item1.jpg',
            name: 'Дмитрий Руденко',
            position: 'Президент-Председатель Правления АО «Почта Банк»',
            quote: 'Высокие темпы восстановления лесов в масштабах страны стали возможны благодаря активности наших клиентов и партнеров. Проект «Подари лес другу!» вышел на новый уровень. К программе постоянно подключаются новые особо охраняемые природные территории. На сегодняшний день их более 50, ведутся переговоры еще с рядом регионов. С каждым годом эко-проект «Подари лес другу!» набирает обороты, о чем красноречиво свидетельствует количество восстановленных деревьев.',
        },
        {
            photo: '/img-next/jpg/management/item2.jpg',
            name: 'Бородай Юлия Анатольевна',
            position: 'Вице-президент, директор по персоналу',
            quote: 'Проект «Подари лес другу!» демонстрирует, как можно воплотить идею, которая несколько лет назад показалась бы почти фантастической, в серьезную экологическую инициативу.',
        },
        {
            photo: '/img-next/jpg/management/item1.jpg',
            name: 'Дмитрий Руденко',
            position: 'Президент-Председатель Правления АО «Почта Банк»',
            quote: 'Высокие темпы восстановления лесов в масштабах страны стали возможны благодаря активности наших клиентов и партнеров. Проект «Подари лес другу!» вышел на новый уровень. К программе постоянно подключаются новые особо охраняемые природные территории. На сегодняшний день их более 50, ведутся переговоры еще с рядом регионов. С каждым годом эко-проект «Подари лес другу!» набирает обороты, о чем красноречиво свидетельствует количество восстановленных деревьев.',
        },
        {
            photo: '/img-next/jpg/management/item2.jpg',
            name: 'Бородай Юлия Анатольевна',
            position: 'Вице-президент, директор по персоналу',
            quote: 'Проект «Подари лес другу!» демонстрирует, как можно воплотить идею, которая несколько лет назад показалась бы почти фантастической, в серьезную экологическую инициативу.',
        },
    ],
    bottomText:
        'Партнером проекта «Подари лес другу!» является веб-платформа Treeography.com. Что значит слово Treeography? Это деревья, которые мы вместе с вами вписываем в историю планеты, это карты, которые мы открываем, это уникальный российский онлайн-сервис, который помогает нам с вами дистанционно посадить деревья и следить, как растет ваш лес. Кроме того, с апреля 2020 года компания Treeography стала резидентом «Сколково».',
};

export const mockQuotesCarouselMockData = {
    items: [
        {
            photo: '',
            name: 'Имя руководителя',
            position: 'Должность руководителя',
            quote: 'Цитата руководителя',
        },
        {
            photo: '',
            name: 'Имя руководителя',
            position: 'Должность руководителя',
            quote: 'Цитата руководителя',
        },
        {
            photo: '',
            name: 'Имя руководителя',
            position: 'Должность руководителя',
            quote: 'Цитата руководителя',
        },
        {
            photo: '',
            name: 'Имя руководителя',
            position: 'Должность руководителя',
            quote: 'Цитата руководителя',
        },
    ],
    bottomText: 'Доп. описание',
};
