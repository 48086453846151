import React from 'react';

// eslint-disable-next-line import/no-cycle
import { TinaNews } from '@/components/blocks/News/index.tina';
import { mockNewsBlockDataTina } from '@/data/blocks/news';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const newsBlock = {
    Component: props => <TinaNews {...props} />,
    template: {
        label: 'Новости',
        defaultItem: () => mockNewsBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Список новостей',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockNewsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'date',
                        label: 'Дата',
                        component: 'date',
                        dateFormat: 'DD.MM.YYYY',
                        timeFormat: false,
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                name: 'socialsTitle',
                component: 'text',
                label: 'Заголовок соц-сетей',
            },
            {
                label: 'Социальные сети',
                name: 'socials',
                component: 'blocks',
                limit: 1,
                max: 1,
                maxLimit: 1,
                count: 1,
                templates: {
                    social: {
                        label: 'Социальные сети',
                        defaultItem: () => mockNewsBlockDataTina.socials[0],
                        fields: [
                            {
                                name: 'section',
                                component: 'section',
                                label: 'Привязка к разделу',
                            },
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'list',
                                component: 'group-list',
                                label: 'Список соц. сетей',
                                itemProps: (item: { title: string }, index: number) => ({
                                    key: index,
                                    label: item.title,
                                }),
                                defaultItem: () => mockNewsBlockDataTina.socials[0].list[0],
                                fields: [
                                    {
                                        name: 'image',
                                        component: 'image',
                                        label: 'Картинка',
                                        parse: (media: { src: string }) => media.src,
                                    },
                                    {
                                        name: 'title',
                                        component: 'text',
                                        label: 'Заголовок',
                                    },
                                    {
                                        name: 'link',
                                        component: 'text',
                                        label: 'Ссылка',
                                    },
                                    { ...gtagSingleItem },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    },
};
