import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import SafeAccountCalculatorContent from '@/components/blocks/calculators/layouts/SafeAccountCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import SafeAccountCalculatorStore from '@/domain/calculator/SafeAccountCalculatorStore';
import { TSafeAccountCalculator } from '@/types/calculator/safeAccount';

const SafeAccountCalculator: React.FC<TSafeAccountCalculator> = observer(
    ({
        title,
        rateMin,
        rateMax,
        filterElements,
        termsElements,
        promoDurationMonths,
        index,
        gtag,
        button,
        enableCheckbox,
    }) => {
        if (!filterElements || !termsElements || !rateMin || !rateMax) return null;

        useEffect(() => {
            SafeAccountCalculatorStore.init(
                filterElements,
                termsElements,
                enableCheckbox,
                rateMin,
                rateMax,
                promoDurationMonths
            );
        }, []);

        return (
            <CalculatorStoreContext.Provider value={SafeAccountCalculatorStore}>
                {SafeAccountCalculatorStore.initialized && (
                    <SafeAccountCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        button={button}
                        enableCheckbox={enableCheckbox}
                        rateMin={rateMin}
                        rateMax={rateMax}
                    />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default SafeAccountCalculator;
