import React from 'react';

import { TinaTextBanner } from '@/components/blocks/banners/TextBanner/index.tina';
import { mockTextBannerBlockData } from '@/data/blocks/textBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const textBannerBlock = {
    Component: props => <TinaTextBanner {...props} />,
    template: {
        label: 'Текстовый баннер',
        defaultItem: () => mockTextBannerBlockData,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'startText',
                component: HtmlEditorFeatured,
                label: 'Текст, который выводится перед номером',
            },
            {
                name: 'step',
                component: 'text',
                label: 'Шаг для призового текста',
            },
            {
                name: 'victoryText',
                component: HtmlEditorFeatured,
                label: 'Описание, которое выводится при победе',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание, которое выводится при проигрыше',
            },
        ],
    },
};
