import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import IconFire from '@/components/UI/IconFire';
import { useAppStore } from '@/context/AppStoreContext';
import { IInfoCardItem } from '@/types/infoCard/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function InfoCardItem({ image, name, desc, info, buttonDetail, buttonBg, pos, isActual, imageLink }: IInfoCardItem) {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    return (
        <AnimatedComponent className={clsx(cn.item)} classNameActive={cn.animationInit}>
            {isActual && (
                <div className={clsx(cn.fire, isActual && cn.fireVisible)}>
                    <IconFire customClass={cn.cardActualRed} />
                </div>
            )}
            <div className={cn.itemGrid}>
                <div className={clsx(cn.itemCol, cn.itemColImg, cn.isImage)}>
                    <div className={cn.itemMobail}>
                        {name && (
                            <div className={cn.itemTitle}>
                                <a
                                    href={buttonDetail?.link || buttonBg?.link}
                                    className={clsx(cn.itemLink, cn.itemLinkWrapper)}
                                    onClick={() => {
                                        if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                    }}
                                >
                                    <TextField
                                        text={name}
                                        name={`list.${pos}.name`}
                                        customTag="span"
                                        isHTML
                                        htmlValue={name}
                                    />
                                </a>
                            </div>
                        )}
                        {desc && (
                            <div className={cn.itemDesc}>
                                <TextField
                                    text={desc}
                                    name={`list.${pos}.desc`}
                                    customTag="span"
                                    isHTML
                                    htmlValue={desc}
                                />
                            </div>
                        )}
                    </div>
                    {(image || imageLink) && (
                        <a
                            href={buttonDetail?.link || buttonBg?.link}
                            className={clsx(cn.itemLink, cn.itemLinkWrapper)}
                            onClick={() => {
                                if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                            }}
                        >
                            <div className={cn.itemImg}>
                                {imageLink && !isTinaView && <CustomImage src={imageLink} alt={name} fill />}
                                {imageLink && isTinaView && (
                                    <div className="promoServiceDesc">
                                        предпросмотр картинки с продуктовым параметром невозможен
                                    </div>
                                )}
                                {image && !imageLink && <CustomImage src={image} alt={name} fill />}
                            </div>
                        </a>
                    )}
                </div>
                <div className={clsx(cn.itemCol, cn.itemColContent, cn.isImage)}>
                    <div className={cn.itemContent}>
                        <div className={cn.itemContentTop}>
                            <div className={cn.itemDesktop}>
                                {name && (
                                    <div className={cn.itemTitle}>
                                        <a
                                            href={buttonDetail?.link || buttonBg?.link}
                                            className={clsx(cn.itemLink, cn.itemLinkWrapper)}
                                            onClick={() => {
                                                if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                            }}
                                        >
                                            <TextField
                                                text={name}
                                                name={`list.${pos}.name`}
                                                customTag="span"
                                                isHTML
                                                htmlValue={name}
                                            />
                                        </a>
                                    </div>
                                )}
                                {desc && (
                                    <div className={cn.itemDesc}>
                                        <TextField
                                            text={desc}
                                            name={`list.${pos}.desc`}
                                            customTag="span"
                                            isHTML
                                            htmlValue={desc}
                                        />
                                    </div>
                                )}
                            </div>
                            {info?.length > 0 && (
                                <div className={cn.info}>
                                    {info?.slice(0, 3)?.map(({ title, subtitle }, infoPos) => (
                                        <div className={cn.infoItem} key={infoPos}>
                                            <TextField
                                                text={title}
                                                name={`list.${pos}.info.${infoPos}.title`}
                                                className={cn.infoName}
                                                isHTML
                                                htmlValue={title}
                                            />
                                            <TextField
                                                text={subtitle}
                                                name={`list.${pos}.info.${infoPos}.subtitle`}
                                                className={cn.infoDesc}
                                                isHTML
                                                htmlValue={subtitle}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={cn.itemContentBot}>
                            <div className={clsx(cn.itemBtn)}>
                                {buttonDetail?.name && (
                                    <AnimatedComponent
                                        className={clsx(cn.itemBtnItem, cn.itemBtnItemLink)}
                                        classNameActive={cn.animationInit}
                                    >
                                        <a
                                            href={buttonDetail?.link}
                                            className={cn.itemLink}
                                            onClick={() => {
                                                if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                            }}
                                        >
                                            {buttonDetail?.name}
                                        </a>
                                    </AnimatedComponent>
                                )}
                                {buttonBg?.name && (
                                    <div className={clsx(cn.itemBtnItem, cn.itemBtnItemBtn)}>
                                        <Button
                                            variant="btnBlue"
                                            rel={buttonBg?.rel}
                                            type="link"
                                            href={buttonBg?.link}
                                            label={buttonBg?.name}
                                            size="small"
                                            customClass="full"
                                            onClick={() => {
                                                if (buttonBg?.gtag?.action) event(buttonBg?.gtag);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
}
export default InfoCardItem;
