import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import Content from '@/components/blocks/universalDetail/Content';
import Button from '@/components/UI/Button';
import { IUniversalDetailElement } from '@/types/universalList';

import cn from './style.module.sass';

export interface IDetailPage {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'universalDetail';
    title: string;
    element: IUniversalDetailElement;
    button: {
        text: string;
        link: string;
    };
}

const UniversalDetail: React.FC<IDetailPage> = ({ title, element, button }) => (
    <AnimatedComponent className={clsx(cn.detail, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.detailWrap}>
            <div className={cn.detailGrid}>
                <div className={clsx(cn.detailItem, cn.detailItemList)}>
                    <Content
                        name={element?.name}
                        properties={element?.properties}
                        detailText={element?.detailText}
                        imagePreview={element?.previewPictureSrc}
                        image={element?.pictureSrc}
                    />
                    <div className={cn.detailBtn}>
                        {button?.text && (
                            <Button
                                variant="btnBlueBorder"
                                type="link"
                                label={button?.text}
                                href={button?.link}
                                darkWhite
                            />
                        )}

                        <Button
                            variant="btnBlueBorder"
                            type="link"
                            label="Перейти в Telegram"
                            href="https://t.me/pochtabank"
                            target="_blank"
                            darkWhite
                        />
                    </div>
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default UniversalDetail;
