export const mockCashCreditCalculatorV2Data = {
    title: 'Рассчитать условия по кредиту',
    tabTitle: 'Наличными',
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
    params: {
        terms: [
            {
                id: 1,
                label: '3 года',
                value: 3,
                ranges: [
                    {
                        id: 1,
                        percent: 4,
                        min: 20000,
                        max: 270000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 4,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 2,
                label: '4 года',
                value: 4,
                ranges: [
                    {
                        id: 1,
                        percent: 4,
                        min: 20000,
                        max: 270000,
                        balancePercent: 29.9469781389227,
                    },
                    {
                        id: 2,
                        percent: 4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 29.9469781389,
                    },
                    {
                        id: 3,
                        percent: 4,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 29.1608666805,
                    },
                ],
            },
            {
                id: 3,
                label: '5 лет',
                value: 5,
                ranges: [
                    {
                        id: 1,
                        percent: 4,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 24.6773644879,
                    },
                    {
                        id: 3,
                        percent: 4,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 23.9416492058,
                    },
                ],
            },
            {
                id: 4,
                label: '6 лет',
                value: 6,
                ranges: [
                    {
                        id: 1,
                        percent: 4,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 21.0729371986,
                    },
                    {
                        id: 3,
                        percent: 4,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 20.4700559128,
                    },
                ],
            },
            {
                id: 5,
                label: '7 лет',
                value: 7,
                ranges: [
                    {
                        id: 1,
                        percent: 4,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 18.5045230588,
                    },
                    {
                        id: 3,
                        percent: 4,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 17.9971158943,
                    },
                ],
            },
        ],
        filterElements: [
            {
                value: 30000,
                step: 5000,
                percent: 0,
                label: '30 тыс.',
                id: 1,
            },
            {
                value: 300000,
                step: 10000,
                percent: 20,
                label: '',
                id: 2,
            },
            {
                value: 500000,
                step: 50000,
                percent: 40,
                label: '',
                id: 3,
            },
            {
                value: 1000000,
                step: 100000,
                percent: 60,
                label: '',
                id: 4,
            },
            {
                value: 3000000,
                step: 100000,
                percent: 80,
                label: '',
                id: 5,
            },
            {
                value: 5000000,
                step: 100000,
                percent: 100,
                label: '5 млн.',
                id: 6,
            },
        ],
        minValue: 30000,
        maxValue: 5000000,
    },
    infoTitle: {
        text: `Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке&nbsp;4% годовых
с&nbsp;услугой &laquo;Гарантированная ставка&raquo;. Не&nbsp;является публичной офертой.`,
        tooltipText: `Вы&nbsp;отдаете&nbsp;&mdash; мы&nbsp;возвращаем! \n
        Оформите кредит с&nbsp;услугой &laquo;Гарантированная ставка&raquo; и&nbsp;финансовой защитой, погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке&nbsp;4% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита!`,
        isActive: true,
    },
};
