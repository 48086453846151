export const mockInsuranceBlockData = {
    title: 'Страхование жизни и здоровья',
    list: [
        {
            name: 'Альфаантивирус',
            link: '',
            image: '/img-next/png/insurance/1.png',
            desc: 'Выплата при госпитализации или смерти в случае заболевания COVID-19',
            info: [
                {
                    title: 'До 1 000 000 ₽',
                    subtitle: 'страховая сумма',
                },
                {
                    title: 'От 1 500 ₽',
                    subtitle: 'срок страхования',
                },
                {
                    title: '1 год',
                    subtitle: 'срок действия полиса',
                },
            ],
            bot: {
                logo: '/img-next/png/insurance/bot/1.png',
                logoName: 'ООО «АльфаСтрахование-Жизнь»',
            },
        },
        {
            name: 'Здоровье и уверенность',
            link: '',
            image: '/img-next/png/insurance/2.png',
            desc: 'Помощь при онкологических заболеваниях',
            info: [
                {
                    subtitle: 'Организация лечения в медицинских центрах',
                },
                {
                    subtitle: 'Обеспечение лекарственными средствами',
                },
                {
                    subtitle: 'Медицинский пульт 24Х7',
                },
            ],
            bot: {
                logo: '/img-next/png/insurance/bot/2.png',
                logoName: 'АО «Согаз»',
                documents: {
                    name: 'Условия страхования',
                    link: '#',
                },
            },
        },
        {
            name: 'Под защитой',
            link: '',
            image: '/img-next/png/insurance/3.png',
            desc: 'Получение компенсации при наступлении несчастного случая',
            info: [
                {
                    title: 'До 250 000 ₽',
                    subtitle: 'травмы',
                },
                {
                    title: 'До 500 000 ₽',
                    subtitle: 'инвалидность',
                },
                {
                    title: '500 000 ₽',
                    subtitle: 'уход из жизни',
                },
            ],
            bot: {
                logo: '/img-next/png/insurance/bot/2.png',
                logoName: 'АО «Согаз»',
                documents: {
                    name: 'Условия страхования',
                    link: '#',
                },
            },
        },
        {
            name: 'Спроси врача',
            link: '',
            image: '/img-next/png/insurance/4.png',
            desc: 'Консультации врачей без посещения клиник в режиме 24×7',
            info: [
                {
                    title: 'За 50% стоимости',
                    subtitle: 'комплекс лабораторных исследований',
                },
                {
                    title: '1 год',
                    subtitle: 'срок страхования',
                },
                {
                    title: 'от 3 000 ₽',
                    subtitle: 'стоимость в год',
                },
            ],
        },
        {
            name: 'Семейный оберег',
            link: '',
            image: '/img-next/png/insurance/5.png',
            desc: 'Защита от несчастных случаев для всей семьи',
            info: [
                {
                    subtitle: 'Выплаты при получении инвалидности 1 и 2 группы',
                },
                {
                    subtitle: 'Выплаты при получении травм',
                },
                {
                    subtitle: 'Компенсация длительных больничных листов свыше 30 дней',
                },
            ],
            bot: {
                logo: '/img-next/png/insurance/bot/3.png',
                logoName: 'BNP PARIBAS CARDIF',
            },
        },
        {
            name: 'С заботой о Вас',
            link: '',
            image: '/img-next/png/insurance/6.png',
            desc: 'Защита жизни и здоровья',
            info: [
                {
                    subtitle: 'Страхование финансовых рисков травме, инвалидности или смерти',
                },
                {
                    subtitle: 'Компенсация расходов на лекарства',
                },
                {
                    subtitle: 'Телемедицинские консультации',
                },
            ],
            bot: {
                logo: '/img-next/png/insurance/bot/3.png',
                logoName: 'BNP PARIBAS CARDIF',
            },
        },
        {
            name: 'Теледоктор',
            link: '',
            image: '/img-next/png/insurance/7.png',
            desc: 'Онлайн-консультации врачей по вопросам здоровья в режиме 24/7',
            info: [
                {
                    subtitle: '24 часа консультации специалистов, второе мнение по поставленному диагнозу',
                },
                {
                    subtitle: 'Подбор врача в Вашем регионе, рекомендации по лекарственным средствама',
                },
                {
                    subtitle: 'Юридические консультации по оказанию медицинских услуг и помощь в экстренных ситуациях',
                },
            ],
            bot: {
                logoName: 'ООО «Теледоктор 24»',
            },
        },
    ],
};

export const mockInsuranceBlockDataTina = {
    title: 'Страхование жизни и здоровья',
    list: [
        {
            name: 'Заголовок',
            link: '',
            image: '',
            desc: 'Описание',
            info: [
                {
                    title: 'Заголовок',
                    subtitle: 'Подзаголовок',
                },
            ],
            bot: {
                logo: '',
                logoName: 'Название',
                link: '#',
                linkName: 'Заголовок',
                documents: {
                    name: 'Заголовок',
                    link: '#',
                },
            },
        },
    ],
};
