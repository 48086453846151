import clsx from 'clsx';
import React from 'react';

import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

interface IProps {
    type: string;
    children: React.ReactNode;
    link: string;
    customClass?: string;
    scrollOffset?: number;
}

function HelpWrapperLinkBlock({ type, children, link, customClass, scrollOffset }: IProps) {
    if (type === 'link') {
        return (
            <a href={link} className={clsx(cn.innerWrap, customClass)}>
                {children}
            </a>
        );
    }

    return (
        <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className={clsx(cn.innerWrap, customClass)}>
            {children}
        </ScrollLink>
    );
}

export default HelpWrapperLinkBlock;
