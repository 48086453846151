import { ITinyResponsiveCategory } from '@/components/blocks/TinyResponsiveCategory';

export const tinaTinyResponsiveCategoryData: ITinyResponsiveCategory = {
    _template: 'tinyResponsiveCategory',
    title: 'Популярные категории',
    bottomText: 'Другие категории в приложении <a href = "#">Почта Банк Онлайн</a>',
    list: [
        {
            title: 'Продукты питания',
            subTitle: 'до 5%',
            img: '',
        },
        {
            title: 'Аптеки',
            subTitle: 'до 5%',
            img: '',
        },
        {
            title: 'Ремонт и строительство',
            subTitle: 'до 5%',
            img: '',
        },
        {
            title: 'Магазины-партнеры',
            subTitle: 'до 5%',
            img: '',
        },
        {
            title: 'Все покупки',
            subTitle: 'до 5%',
            img: '',
        },
    ],
};
