import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import cn from '@/components/blocks/calculators/CalculatorConclusions/style.module.sass';
import { DecCashSvg } from '@/components/blocks/calculators/layouts/CashCalculator/svg/DecCashSvg';
import { IncCashSvg } from '@/components/blocks/calculators/layouts/CashCalculator/svg/IncCashSvg';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';

interface ICounter {
    sum: string;
}
const Counter = ({ sum }: ICounter) => {
    const { terms, activeTerm, setActiveTerm, isTermDisabled, value } = useContext(CalculatorStoreContext);
    const [isDisabledInc, setIsDisabledInc] = useState(false);
    const [isDisabledDec, setIsDisabledDec] = useState(false);
    const currentTerm = terms.find(term => term.label === activeTerm.label);

    const currentTermIndex = terms.findIndex(term => term.label === activeTerm.label);

    const handleIncrement = () => {
        const incCondition = currentTermIndex < terms.length - 1 ? currentTermIndex + 1 : currentTermIndex;
        setActiveTerm(terms[incCondition]);
    };

    useEffect(() => {
        if (isTermDisabled(currentTerm.value + 1) || currentTermIndex === terms.length - 1) setIsDisabledInc(true);
        else setIsDisabledInc(false);
    }, [currentTerm.value, value]);

    useEffect(() => {
        if (isTermDisabled(currentTerm.value - 1) || currentTermIndex === 0) setIsDisabledDec(true);
        else setIsDisabledDec(false);
    }, [currentTerm.value, value]);

    const handleDecrement = () => {
        const decCondition = currentTermIndex > 0 ? currentTermIndex - 1 : currentTermIndex;
        setActiveTerm(terms[decCondition]);
    };

    return (
        <div className={cn.sum}>
            <div>
                <div className={cn.counterWrap}>
                    <button
                        type="button"
                        className={clsx(cn.counterBtn, isDisabledInc && cn.disabled)}
                        onClick={handleIncrement}
                    >
                        <DecCashSvg color="#3A499D" customClass={cn.counterBtnImg} />
                    </button>
                    <div className={cn.resultNumber}>{sum}</div>

                    <button
                        type="button"
                        className={clsx(cn.counterBtn, isDisabledDec && cn.disabled)}
                        onClick={handleDecrement}
                    >
                        <IncCashSvg color="#3A499D" customClass={cn.counterBtnImg} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Counter;
