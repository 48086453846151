import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ICashbackPercentageItem } from '@/types/CashbackPercentage/item';
import { IDefaultBlock } from '@/types/tina/block';

import CashbackPercentageItem from './CashbackPercentageItem';
import CashbackPercentageItemLoader from './CashbackPercentageItemLoader';
import cn from './style.module.sass';

export interface ICashbackPercentage extends IDefaultBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'сashbackPercentage';
    title?: string;
    /**
     * Список блоков.
     */
    list: ICashbackPercentageItem[];
    // Количество видимых элементов
    limit: number;
    // Количество элементов в строке
    type: 'col-3' | 'col-4' | 'col-5';
    // Размер элементов
    size?: 'big' | 'small';
    background: 'blue' | 'gradient' | 'white';
}

const CashbackPercentage: React.FC<ICashbackPercentage> = ({
    list,
    limit,
    type = 'col-3',
    title,
    isDataFetching,
    size = 'big',
    background,
}) => {
    const [hidden, setHidden] = useState(true);

    const mockItems = useMemo(() => {
        if (type === 'col-5') return new Array<string>(5).fill('');
        if (type === 'col-4') return new Array<string>(4).fill('');
        if (type === 'col-3') return new Array<string>(3).fill('');
        return new Array<string>(3).fill('');
    }, [type]);

    if (isDataFetching)
        return (
            <div className={clsx(cn.CashbackPercentage, 'section')}>
                <div className={cn.CashbackPercentageWrap}>
                    {title && <div className={cn.titleLoader} />}
                    <div className={clsx(cn.CashbackPercentageGrid)}>
                        {mockItems?.map((_, index) => (
                            <CashbackPercentageItemLoader key={index} type={type} />
                        ))}
                    </div>
                </div>
            </div>
        );

    return (
        <AnimatedComponent>
            <div className={clsx(cn.CashbackPercentage, 'section')}>
                <div className={cn.CashbackPercentageWrap}>
                    {title && (
                        <TextField
                            text={title}
                            className={cn.mainTitle}
                            name="title"
                            customTag="h3"
                            isHTML
                            htmlValue={title}
                        />
                    )}
                    <div className={clsx(cn.CashbackPercentageGrid)}>
                        {list
                            ?.filter((current, index) => !hidden || index + 1 <= limit)
                            ?.map((item, index) => (
                                <CashbackPercentageItem
                                    key={index}
                                    pos={index}
                                    title={item.title}
                                    image={item.image}
                                    imageDarkTheme={item.imageDarkTheme}
                                    link={item.link}
                                    type={type}
                                    size={size}
                                    cashbackSize={item?.cashbackSize}
                                    background={background}
                                />
                            ))}
                    </div>
                    {hidden && list.length > limit && (
                        <AnimatedComponent className={cn.CashbackPercentageBtn} classNameActive={cn.animationInit}>
                            <Button
                                variant="btnBlueBorder"
                                type="button"
                                label="Показать ещё"
                                customClass={clsx(cn.btnAll)}
                                darkWhite
                                onClick={() => setHidden(false)}
                            />
                        </AnimatedComponent>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(CashbackPercentage);
