import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import TermField, { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';
import { DAY_IN_YEAR } from '@/utils/constants';

export interface IProfitabilityCalculatorStore {
    range: IRangeInput;
    termField: ITermField;
    paymentValue: number;
    creditValue: number;
    initialized: boolean;
    rate: number;
    enableCheckbox?: boolean;
    isChecked?: boolean;
    toggleChecked?: () => void;
}

const CALCULATOR_INDEX_MAP: Record<string, number> = {
    profitabilityCalculatorHotSeasons: 1,
    profitabilityCalculatorCapital: 0,
    profitabilityCalculatorProfit: 0,
    profitabilityCalculatorPension: 1,
    profitabilityCalculatorCumulative: 0,
};

class ProfitabilityCalculatorStore implements IProfitabilityCalculatorStore {
    public range: IRangeInput = null;

    public termField: ITermField = null;

    public isPreferential = false;

    public initialized = false;

    public isChecked = true;

    public enableCheckbox = false;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    get rate() {
        const activeItemRangeInArray = this.termField?.activeTerm?.ranges.filter(
            range => range.min <= +this.creditValue && range.max >= +this.creditValue
        );

        return this.isChecked && this.enableCheckbox && activeItemRangeInArray[0]
            ? activeItemRangeInArray[0].percent + 0.25
            : activeItemRangeInArray?.[0]?.percent;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        const dayPercent = this.rate / 100 / DAY_IN_YEAR;
        const totalPercent = (this.termField?.calculatedValue || this.termField.value) * dayPercent;

        return Math.round(totalPercent * this.creditValue);
    }

    public init = (
        filterElements: ICalculatorFilterElement[],
        terms: ITermItem[],
        enableCheckbox: boolean,
        template: string,
        termsActiveIndex?: number
    ) => {
        const rangeInitValue = filterElements && filterElements.length > 0 && filterElements[0].value;

        this.range = new RangeInput({
            name: 'creditValue',
            label: 'Сумма вклада',
            elements: filterElements,
            initValue: rangeInitValue,
        });

        const index =
            termsActiveIndex - 1 < 0 || termsActiveIndex === undefined
                ? CALCULATOR_INDEX_MAP[template]
                : termsActiveIndex - 1;
        this.termField = new TermField(terms, index);

        this.enableCheckbox = enableCheckbox;

        this.initialized = true;
    };
}

export default new ProfitabilityCalculatorStore();
