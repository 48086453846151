export const mockCorgisBlockData = {
    title: `Корги скучает, порадуйте <br/>eго скорее!`,
    name: 'Корги',
    image: '',
    imageMobile: '',
};

export const mockCorgisBlockDataTina = {
    title: 'Заголовок',
    name: 'corgis',
    image: '',
    imageMobile: '',
};
