import React from 'react';

import { TinaCardToCard } from '@/components/blocks/frames/CardToCard/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const cardToCardBlock = {
    Component: (props: IBlockProps) => <TinaCardToCard {...props} />,
    template: {
        label: 'Онлайн переводы',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
