import clsx from 'clsx';
import React, { FC } from 'react';

import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ISummaryNumber {
    title: string;
    number: string;
    desc: string;
}

export interface ISummaryNumbers {
    _template: 'summaryNumbers';
    heading?: string;
    columns: 1 | 2 | 3;
    items: ISummaryNumber[];
}

const SummaryNumbers: FC<ISummaryNumbers> = ({ heading, columns = 2, items }) => {
    if (!items || items?.length <= 0) return null;

    return (
        <div className="section">
            <div className={cn.wrapper}>
                <TextField text={heading} name="heading" isTextArea className={cn.heading} customTag="h2" />
                <div className={clsx(cn.grid, cn[`columns${columns}`])}>
                    {items?.map(({ title, number, desc }: ISummaryNumber, index) => (
                        <div className={cn.item} key={index}>
                            <TextField text={title} name={`items.[${index}].title`} isTextArea className={cn.title} />
                            <TextField
                                text={number}
                                name={`items.[${index}].number`}
                                isTextArea
                                className={cn.number}
                            />
                            <TextField text={desc} name={`items.[${index}].desc`} isTextArea className={cn.desc} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SummaryNumbers;
