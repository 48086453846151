export const mockInfoBlockGradientData = {
    image: '/img-next/png/askQuestions.png',
    title: 'Остались вопросы?',
    desc: 'Подробнее о кредитных картах можно узнать в разделе «Часто задаваемые вопросы».',
    link: '#',
    linkName: 'Узнать подробнее',
    color: 'default',
};

export const mockInfoBlockGradientDataTina = {
    titleBlock: 'Заголовок',
    image: '',
    title: 'Заголовок',
    desc: 'Описание',
    advantages: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
        },
    ],
    link: '#',
    linkName: 'Название',
    color: 'default',
};
