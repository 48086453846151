export const mockMainArticlesSliderBlockData = {
    name: 'Статьи',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    title: 'Статьи',
    button: {
        title: 'Все статьи',
        link: '#',
    },
    list: [
        {
            image: '/img-next/png/main-slider/1.png',
            isImageDisabled: false,
            date: '06.07.2022',
            background: '#F6F7FF',
            title: 'Процентная ставка по кредиту ',
            text: 'Что это такое, откуда она берется и можно ли от нее отказаться?',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/2.png',
            title: 'Как получить кредит с вероятностью 99%?',
            text: 'Делимся простой, но рабочей инструкцией',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/3.png',
            title: 'Хотите себе идеальную кредитную историю?',
            text: 'Тогда не повторяйте эти 5 ошибок!',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/1.png',
            title: 'Платежи без комиссии',
            text: 'Мы отменили комиссию за ЖКУ и иных услуг в Почта Банк Онлайн и банкоматах для всех клиентов',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/2.png',
            title: 'Текущие акции',
            text: 'Все актуальные акции Почта Банка собранные в одном месте',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/3.png',
            title: 'Подари лес другу',
            text: 'Первый в мире проект по посадке деревьев через интернет',
            textBtn: 'Узнать больше',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};

export const mockMainArticlesSliderBlockTinaData = {
    section: null,
    name: 'Статьи',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    title: 'Заголовок',
    button: {
        title: 'Заголовок',
        link: '#',
    },
    list: [
        {
            image: '',
            title: 'Заголовок',
            text: 'Описание',
            textBtn: 'Название',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            text: 'Описание',
            textBtn: 'Название',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            text: 'Описание',
            textBtn: 'Название',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            text: 'Описание',
            textBtn: 'Название',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            text: 'Описание',
            textBtn: 'Название',
            linkTo: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};
