import React from 'react';

import AnimatedComponent from '@/components/Animation';
import YouTubeVideosElement, { IYouTubeVideosElement } from '@/components/blocks/youTubeVideos/YouTubeVideosElement';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Title from '@/components/UI/Title';

import cn from './style.module.sass';

export interface IYouTubeVideos {
    _template?: 'youTubeVideos';
    title: string;
    list: IYouTubeVideosElement[];
}

const YouTubeVideos: React.FC<IYouTubeVideos> = ({ title, list }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className="section">
                <div className={cn.fullWrapper}>
                    <div className={cn.title}>{title && <Title title={title} level={2} name="title" />}</div>
                    <div className={cn.wrapper}>
                        {list?.map((item, index) => (
                            <YouTubeVideosElement {...item} pos={index} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(YouTubeVideos);
