import React from 'react';

import Pip from '@/components/blocks/calculators/range/deprecated/PipDeprecated';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';

import cn from './style.module.sass';

interface IProps {
    filterElements: ICalculatorFilterElement[];
    isRedesign?: boolean;
}

const Pips: React.FC<IProps> = ({ filterElements, isRedesign }) => (
    <div className={cn.pips}>
        <div className={cn.pipsContainer}>
            {filterElements?.map((filterElement: ICalculatorFilterElement) => (
                <Pip key={filterElement.id} filterElement={filterElement} isRedesign={isRedesign} />
            ))}
        </div>
    </div>
);

export default Pips;
