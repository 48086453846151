import React, { useEffect, useMemo, useState } from 'react';

import CustomSelect from '@/components/UI/CustomSelect';
import { IDictionarySection } from '@/types/dictionary';
import { ISelectElement } from '@/types/select';

interface IProps {
    value: string;
    onChange: (value: string) => void;
    sections: IDictionarySection[];
    onChoose: (value: ISelectElement<string>) => void;
}

const Search: React.FC<IProps> = ({ value, onChange, sections, onChoose }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const options = useMemo(() => {
        if (value.length > 2) {
            return sections.flatMap((item, index) =>
                item?.list.reduce((acc: ISelectElement<string>[], listItem, listItemIndex) => {
                    if (listItem?.name.toLowerCase().includes(value.toLowerCase())) {
                        return [...acc, { value: `${index}.${listItemIndex}`, label: listItem?.name }];
                    }
                    return acc;
                }, [])
            );
        }
        return [];
    }, [value, sections]);

    const onBlur = () => {
        setIsMenuOpen(false);
    };

    const onFocus = () => {
        if (value.length > 2) setIsMenuOpen(true);
    };

    const getNoOptionsMessage = () => 'Поиск не дал результатов';

    useEffect(() => {
        if (value.length > 2) {
            setIsMenuOpen(true);
            return;
        }
        setIsMenuOpen(false);
    }, [value]);

    return (
        <CustomSelect
            options={options}
            onInputChange={onChange}
            placeholder="Поиск..."
            menuIsOpen={isMenuOpen}
            noOptionsMessage={getNoOptionsMessage}
            onBlur={onBlur}
            onFocus={onFocus}
            showArrow={false}
            onChangeHandler={onChoose}
        />
    );
};

export default Search;
