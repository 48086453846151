import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import Button from '@/components/UI/Button';
import { IRkoItem } from '@/types/rko/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const RkoItem: React.FC<IRkoItem> = observer(
    ({ price, title, listFree, listDesc, listBottom, itemUrl, gtag, tariff, activeTariff }) => (
        <AnimatedComponent classNameActive={cn.animationInit} className={cn.rkoItem}>
            <div className={clsx(cn.rkoItemWrap, activeTariff === tariff && cn.active)}>
                <div className={clsx(cn.rkoContent)}>
                    <div className={cn.rkoTitle} dangerouslySetInnerHTML={{ __html: title }} />
                    <div className={cn.rkoPrice}>{`${price} ₽/мес.`}</div>
                    {listFree && (
                        <div className={cn.rkoListWrapper}>
                            <div className={cn.rkoListTitle}>Бесплатно</div>
                            <div className={cn.rkoList} dangerouslySetInnerHTML={{ __html: listFree }} />
                        </div>
                    )}
                    {listDesc && (
                        <div className={cn.rkoListWrapper}>
                            <div className={cn.rkoList} dangerouslySetInnerHTML={{ __html: listDesc }} />
                        </div>
                    )}
                    {listBottom && (
                        <div className={cn.rkoListWrapper}>
                            <div className={cn.rkoListBottom} dangerouslySetInnerHTML={{ __html: listBottom }} />
                        </div>
                    )}
                </div>

                <div className={cn.rkoBottom}>
                    <Button
                        variant="btnBlue"
                        type="link"
                        href={itemUrl}
                        label="Открыть счёт"
                        customClass="full"
                        onClick={() => {
                            if (gtag?.action) event(gtag);
                        }}
                    />
                </div>
            </div>
        </AnimatedComponent>
    )
);

export default RkoItem;
