import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';

import FormedRangeSecondary from '@/components/blocks/calculators/range/deprecated/FormedRangeSecondaryDeprecated';
import Input from '@/components/UI/Input';
import SecondaryCalculatorStoreContext from '@/context/SecondaryCalculatorStoreContext';
import { getQueryParam } from '@/utils/getQueryParam';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
    className?: string;
}

interface IQueryParam {
    value: string;
    term: string;
}

const RangeInputSecondary: React.FC<IProps> = observer(({ gtag, className }) => {
    const { onInputEnterPress, onInputBlur, onInputFocus, inputValue, onInputChange, setInitialValueFromQueryParams } =
        useContext(SecondaryCalculatorStoreContext);

    let asPath = '';
    try {
        const { location } = document;
        asPath = location.href;
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Tabs error: `, error.message);
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            try {
                let queryParam = getQueryParam(asPath, 'cashCalculatorParams');

                if (!queryParam) return;

                const hashPosition = queryParam.indexOf('#');
                if (hashPosition !== -1) {
                    queryParam = queryParam.slice(0, hashPosition);
                }

                const decodedQueryParam = decodeURIComponent(queryParam);
                const { value, term }: IQueryParam = JSON.parse(decodedQueryParam) as IQueryParam;
                setInitialValueFromQueryParams(value, term);
            } catch (e) {
                console.error('Ошибка парсинга значений из гет параметров для калькулятора наличными.', e);
            }
        }
    }, []);

    return (
        <div className={clsx(cn.wrapper, className)}>
            <Input
                value={inputValue}
                label="Сумма кредита"
                onChangeHandler={onInputChange}
                onBlur={onInputBlur}
                onFocus={onInputFocus}
                onKeyPress={onInputEnterPress}
            />
            <FormedRangeSecondary gtag={gtag} />
        </div>
    );
});

export default RangeInputSecondary;
