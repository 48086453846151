import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useLayoutEffect, useState } from 'react';
import Collapsible from 'react-collapsible';

import CustomImage from '@/components/CustomImage';
import { BasicLink } from '@/components/UI/BasicLink';

import cn from './style.module.sass';

type TPboFaqItemLink = {
    href: string;
    label: string;
};

export type TPboFaqItem = {
    icon: string;
    title: string;
    subSectionLinks: TPboFaqItemLink[];
};

const Trigger = ({ title, icon, toggle }: { title: string; icon: string; toggle: () => void }) => (
    <div className={cn.trigger} onClick={toggle} role="presentation">
        <div className={cn.left}>
            <div className={cn.icon}>
                <CustomImage src={icon} alt={title} width={42} height={42} />
            </div>
            <div className={cn.title}>{title}</div>
        </div>
        <div className={cn.right}>
            <CustomImage
                className={cn.arrow}
                src="/img-next/svg/pbo-faq/arrow.svg"
                alt={title}
                width={14}
                height={14}
            />
        </div>
    </div>
);

const PboFaqDetailSidebarLink = ({ icon, title, subSectionLinks }: TPboFaqItem) => {
    const pathname = usePathname();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(prev => !prev);
    };

    useLayoutEffect(() => {
        setIsOpen(subSectionLinks.map(item => item.href).includes(pathname));
    }, []);

    return (
        <div className={cn.wrapper}>
            <Collapsible
                open={isOpen}
                trigger={<Trigger title={title} icon={icon} toggle={toggle} />}
                triggerOpenedClassName={cn.open}
                triggerTagName="div"
                transitionTime={300}
                easing="ease-in-out"
            >
                <div className={cn.links}>
                    {subSectionLinks.map(item => (
                        <BasicLink
                            href={item.href}
                            customClass={clsx(cn.link, pathname === item.href && cn.active)}
                            key={item.label}
                        >
                            {item.label}
                        </BasicLink>
                    ))}
                </div>
            </Collapsible>
        </div>
    );
};

export default PboFaqDetailSidebarLink;
