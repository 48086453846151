import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import RkoItem from '@/components/blocks/calculators/layouts/RkoCalculator/Content/item';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import CustomSwiper from '@/components/CustomSwiper';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import Radio from '@/components/UI/RadioBtn';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import { IRkoCalculator, ITariffConfig } from '@/domain/calculator/RkoCalculatorStore';
import { useResize } from '@/hooks/useResize';
import { IRkoItem } from '@/types/rko/item';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface ICalculator {
    title?: string;
    tabTitle?: string;
    items: IRkoItem[];
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
}
const RkoCalculatorContent: FC<ICalculator> = observer(({ title, tabTitle, gtag, items }) => {
    const {
        resultConfig,
        rangeFormatValue,
        setValueBalanceMonth,
        setCheckedIsPurposePayment,
        computingTariffValue,
        tariff,
    } = useContext<IRkoCalculator>(CalculatorStoreContextNew);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [swiper, setSwiper] = useState(null);

    const slideTo = (index: number) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (swiper) swiper.slideTo(index);
    };
    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;

    const slidesPerView = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.2;
        if (width < MOBILE_WIDTH) return 1.5;
        if (width < TABLET_SMALL_WIDTH) return 2.5;
        return 3;
    }, [width]);

    const centered = width < 610;

    const settings = {
        slidesPerView,
        watchOverflow: true,
        centeredSlides: centered,
    };

    const rkoTariffConfig: ITariffConfig[] = [
        {
            role: 'Стартап',
            coords: [
                [18, 14999],
                [15, 19999],
                [13, 29999],
                [11, 39999],
            ],
            balance: 1,
        },
        {
            role: 'Гуру',
            coords: [
                [19, 14999],
                [19, 19999],
                [19, 29999],
                [19, 39999],
                [18, 49999],
                [18, 59999],
            ],
            balance: 1,
        },
        {
            role: 'Бизнес',
            coords: [
                [19, 49999],
                [19, 59999],
            ],
            balance: 1,
        },
        {
            role: 'Гуру',
            coords: [
                [19, 14999],
                [19, 19999],
                [19, 29999],
                [19, 39999],
                [18, 49999],
                [18, 59999],
            ],
            balance: 2,
        },
        {
            role: 'Бизнес',
            coords: [
                [19, 49999],
                [19, 59999],
            ],
            balance: 2,
        },
    ];

    const handleRadio = (e: ChangeEvent<HTMLInputElement>) => {
        setValueBalanceMonth(e.target.value);
    };
    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckedIsPurposePayment(e.target.checked);
    };
    useEffect(() => {
        const indexTariff = items.findIndex(el => el.tariff === tariff);
        // eslint-disable-next-line no-unused-expressions
        isMobile && slideTo(indexTariff);
    }, [tariff]);
    return (
        <div className="wrapper">
            <div className={cn.fullWrapper}>
                <div className={cn.rko}>
                    <div className={clsx(cn.wrapper)}>
                        <TextField
                            text={title}
                            customTag="h2"
                            name="title"
                            className={cn.title}
                            isHTML
                            htmlValue={title}
                        />
                        <div className={cn.content}>
                            <div className={cn.left}>
                                <RangeInput config={resultConfig.range1} />
                            </div>
                            <div className={cn.right}>
                                <div className={cn.rightWrapper}>
                                    <div className={cn.rkoPaymentRange}>
                                        <RangeInput config={resultConfig.range2} callback={rangeFormatValue} />
                                    </div>
                                </div>
                            </div>
                            <div className={cn.left}>
                                {tabTitle && <div className={cn.subtitle}>{tabTitle}</div>}
                                <div className={cn.contentRow}>
                                    <div className={cn.radio}>
                                        <Radio
                                            name="balance"
                                            checked
                                            value="1"
                                            onChangeHandler={handleRadio}
                                            label="От 1 000 до 49 999,99 ₽"
                                        />
                                    </div>
                                    <div className={cn.radio}>
                                        <Radio
                                            name="balance"
                                            value="2"
                                            onChangeHandler={handleRadio}
                                            label="От 50 000 ₽ (3% на остаток)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={cn.right}>
                                <div className={cn.rightWrapper}>
                                    <div className={cn.rightWrapperMarginTop}>
                                        <div className={cn.contentRow}>
                                            <div className={cn.radio}>
                                                <Checkbox
                                                    value="payment-purpose"
                                                    size="small"
                                                    onChangeHandler={handleChecked}
                                                    label="20 и более платежей"
                                                    customClass={cn.checkboxPayment}
                                                />
                                            </div>

                                            <div className={cn.radio}>
                                                <div className={cn.button}>
                                                    <Button
                                                        variant="btnBlueBorder"
                                                        type="button"
                                                        label="Рассчитать"
                                                        size="small"
                                                        onClick={() => {
                                                            computingTariffValue(rkoTariffConfig);
                                                            if (gtag?.gtagButton) event(gtag.gtagButton);
                                                        }}
                                                        customClass={cn.rkoBtnBottom}
                                                        animation={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isDesktop && (
                        <div className={cn.rkoGrid}>
                            {items?.map((item, index) => (
                                <RkoItem
                                    key={index}
                                    title={item.title}
                                    itemUrl={item.itemUrl}
                                    price={item.price}
                                    activeTariff={tariff}
                                    tariff={item.tariff}
                                    listFree={item.listFree}
                                    listDesc={item.listDesc}
                                    listBottom={item.listBottom}
                                />
                            ))}
                        </div>
                    )}
                    {isMobile && (
                        <div className={cn.rkoSlider}>
                            <CustomSwiper {...settings} onSwiper={setSwiper}>
                                {items?.map((item, index) => (
                                    <SwiperSlide key={`${index}-${item.title}`}>
                                        <RkoItem
                                            key={index}
                                            title={item.title}
                                            itemUrl={item.itemUrl}
                                            price={item.price}
                                            activeTariff={tariff}
                                            tariff={item.tariff}
                                            listFree={item.listFree}
                                            listDesc={item.listDesc}
                                            listBottom={item.listBottom}
                                        />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default RkoCalculatorContent;
