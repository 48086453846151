import React from 'react';

import { TinaLinkingList } from '@/components/blocks/LinkingList/index.tina';
import { mockLinkingListBlockDataTina } from '@/data/blocks/linkingList';

export const linkingListBlock = {
    Component: props => <TinaLinkingList {...props} />,
    template: {
        label: 'SEO: Ссылки',
        defaultItem: mockLinkingListBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { text: string }, index: number) => ({
                    key: index,
                    label: item.text,
                }),
                defaultItem: () => mockLinkingListBlockDataTina.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'text',
                        label: 'Заголовок',
                    },
                    {
                        component: 'text',
                        name: 'link',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
