export const mockArticlesDetailBlockData = {
    element: {
        title: 'Статья',
        text: 'описание: основное тело статьи',
        previewText: 'Аннотация: для разводящей',
        image: '/img-next/png/onlinecredits/adv1.png',
        isImageDisabled: false,
        time: '8 мин',
        date: '06.07.2022',
        background: '#F6F7FF',
    },
};

export const mockArticlesDetailBlockTinaData = {
    section: null,
    element: {
        name: 'ArticlesDetail',
        title: 'Заголовок',
        text: 'Описание: основное тело статьи',
        previewText: 'Аннотация: для разводящей',
        image: '/img-next/png/onlinecredits/adv1.png',
        isImageDisabled: false,
        time: '8 мин',
        date: '06.07.2022',
        background: '#F6F7FF',
    },
    isMulti: false,
    tagList: [
        {
            value: 'Все',
            xmlId: 'all',
        },
        {
            value: 'Кредит',
            xmlId: 'kredit',
        },
        {
            value: 'Кредитные карты',
            xmlId: 'kredit_card',
        },
        {
            value: 'Финансовая безопасность',
            xmlId: 'finanse_bezop',
        },
        {
            value: 'Финансовая грамотность',
            xmlId: 'finanse_gram',
        },
    ],
};
