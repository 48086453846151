import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    customClass?: string;
}

const IconFixButton: React.FC<IProps> = ({ customClass }) => (
    <svg
        className={clsx(cn.fix, customClass)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4158 2.97439C14.03 2.36014 15.0259 2.36014 15.6402 2.97439L21.0255 8.35976C21.6398 8.974 21.6398 9.9699 21.0255 10.5841C20.4309 11.1788 19.4787 11.1978 18.8612 10.6412L14.4694 15.0329L14.2531 14.8166C15.5679 16.1334 15.5673 18.2668 14.2512 19.5828C13.7575 20.0766 12.957 20.0766 12.4632 19.5828L9.20114 16.3207L4.54042 19.8584C4.42707 19.9444 4.26761 19.9335 4.16699 19.8329C4.06637 19.7323 4.0555 19.5728 4.14153 19.4595L7.67919 14.7988L4.4171 11.5367C3.92335 11.0429 3.92335 10.2424 4.4171 9.74867C5.73316 8.43261 7.86655 8.432 9.18336 9.74686L8.96699 9.53049L13.3587 5.13873C12.8022 4.52125 12.8212 3.569 13.4158 2.97439ZM14.7914 6.45732C14.5489 6.21485 14.1558 6.21485 13.9133 6.45732L10.3426 10.028C10.1001 10.2705 10.1001 10.6636 10.3426 10.9061C10.5851 11.1486 10.9782 11.1486 11.2207 10.9061L14.7914 7.33536C15.0338 7.0929 15.0338 6.69978 14.7914 6.45732Z"
        />
    </svg>
);

export default IconFixButton;
