import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import AnimatedComponent from '@/components/Animation';
import StudyCalculatorContent from '@/components/blocks/calculators/layouts/StudyCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import { IElement } from '@/domain/calculator/blocksStore/Range';
import StudyCalculatorStore from '@/domain/calculator/StudyCalculatorStore';
import { ITermItem } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IStudyCalculator {
    _template?: 'studyCalculator';
    title?: string;
    tabTitle?: string;
    filterElements: IElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
    gtag: GTagEvent;
    button?: {
        label: string;
        link: string;
    };
    valueRate: number;
}

const StudyCalculator: React.FC<IStudyCalculator> = observer(
    ({ title, filterElements, gtag, terms, minValue, maxValue, button, valueRate }) => {
        useEffect(() => {
            StudyCalculatorStore.init({ filterElements, terms, minValue, maxValue, valueRate });
        }, []);

        return (
            <CalculatorStoreContextNew.Provider value={StudyCalculatorStore}>
                {StudyCalculatorStore.initialized && (
                    <AnimatedComponent>
                        <StudyCalculatorContent title={title} gtag={gtag} button={button} />
                    </AnimatedComponent>
                )}
            </CalculatorStoreContextNew.Provider>
        );
    }
);

export default withBlockLinks(StudyCalculator);
