import React, { useMemo, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import HRVacancyItem from '@/components/blocks/PushHRLanding/HRVacancy/HRVacancyItem';
import VacancyButton from '@/components/blocks/PushHRLanding/HRVacancy/VacancyButton';

import cn from './style.module.sass';

interface IPushHRVacancyList {
    title?: string;
    hrVacancyList: IPushHRVacancyListItem[];
    handler?: (theme: string) => void;
    vacancyToShow?: number;
}

export interface IPushHRVacancyListItem {
    title: string;
    subtitle?: string;
    link?: string;
    linkShare?: string;
    description?: string;
}

function HRVacancy({ title, hrVacancyList, handler, vacancyToShow = 10 }: IPushHRVacancyList) {
    const [showChunk, setShowChunk] = useState<number>(+vacancyToShow);

    const showedVacancies: IPushHRVacancyListItem[] = useMemo(
        () => hrVacancyList?.slice(0, showChunk),
        [hrVacancyList, showChunk]
    );

    const isShowMore = useMemo(() => showedVacancies?.length < hrVacancyList?.length, [showedVacancies, vacancyToShow]);

    const onShowMoreClick = () => {
        setShowChunk(showChunk + +vacancyToShow);
    };
    return (
        <div className={cn.vacancy} id="vacancy">
            <div className={cn.wrap}>
                <div className={cn.vacancyBlock}>
                    {title && <div className={cn.vacancyHeader} dangerouslySetInnerHTML={{ __html: title }} />}

                    <div className={cn.vacancyList}>
                        {showedVacancies?.map((item, index) => (
                            <HRVacancyItem
                                linkShare={item.linkShare}
                                link={item.link}
                                title={item.title}
                                subtitle={item.subtitle}
                                description={item?.description}
                                key={index}
                                handler={handler}
                            />
                        ))}
                    </div>
                    {isShowMore && (
                        <VacancyButton
                            isDark
                            customClass={cn.showMoreBtn}
                            onClick={onShowMoreClick}
                            type="more"
                            label="Показать еще"
                        />
                    )}

                    <div className={cn.vacancyBottom}>
                        <div className={cn.vacancyBottomQuestion}>Не нашли подходящую вакансию?</div>
                        <div className={cn.vacancyBottomEmail}>
                            Напишите нам на <a href="mailto:jobit@pochtabank.ru">jobit@pochtabank.ru</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRVacancy;
