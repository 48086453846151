import clsx from 'clsx';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { IQuestionsItem } from '@/types/questions/item';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import Arrows from './arrows';
import cn from './style.module.sass';

export interface IQuestions {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template?: 'questions';
    /**
     * Список категорий
     */
    list: IQuestionsItem[];
    title: string;
    text?: string;
    button?: IBtn;
    withoutBorderMobile?: boolean;
    questionsLimit?: number;
    tinaPrefix?: string;
}

interface IBtn {
    label: string;
    href: string;
    gtag?: GTagEvent;
}

const Questions: React.FC<IQuestions> = ({
    title,
    text,
    list,
    button,
    withoutBorderMobile,
    questionsLimit,
    tinaPrefix = '',
}) => {
    const [hidden, setHidden] = useState(true);

    const getTrigger = (trigger: string) => (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger} dangerouslySetInnerHTML={{ __html: trigger }} />
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </div>
    );
    const showHead = title || text;

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.questions, 'section')}>
                <div className={cn.questionsWrap}>
                    {showHead && (
                        <div className={cn.top}>
                            <div className={cn.topItem}>
                                {title && (
                                    <TextField
                                        text={title}
                                        name={`${tinaPrefix}title`}
                                        isHTML
                                        htmlValue={title}
                                        className={cn.topMainTitle}
                                        customTag="h3"
                                    />
                                )}
                                {text && (
                                    <TextField
                                        text={text}
                                        name={`${tinaPrefix}text`}
                                        isHTML
                                        htmlValue={text}
                                        className={cn.topText}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <div className={cn.questionsGrid}>
                        <div className={cn.questionsRow}>
                            {list
                                ?.filter((current, index) => !questionsLimit || !hidden || index + 1 <= questionsLimit)
                                ?.map(({ name, desc, gtag }, index) => (
                                    <AnimatedComponent
                                        className={clsx(
                                            cn.questionsCollapsibleWrap,
                                            withoutBorderMobile && cn.withoutBorderMobile
                                        )}
                                        classNameActive={cn.animationInit}
                                        key={index}
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                    >
                                        <Collapsible
                                            className={cn.questionsCollapsible}
                                            openedClassName={clsx(cn.questionsCollapsible, cn.open)}
                                            trigger={getTrigger(name)}
                                            triggerOpenedClassName={cn.open}
                                            triggerTagName="div"
                                            transitionTime={300}
                                            easing="ease-in-out"
                                        >
                                            <div
                                                className={cn.questionsDesc}
                                                dangerouslySetInnerHTML={{ __html: desc }}
                                            />
                                        </Collapsible>
                                    </AnimatedComponent>
                                ))}
                            {hidden && list?.length > questionsLimit && (
                                <Button
                                    variant="btnBlueBorder"
                                    type="button"
                                    label="Показать ещё"
                                    customClass={clsx('full', cn.btnAll)}
                                    onClick={() => setHidden(false)}
                                    darkWhite
                                />
                            )}
                        </div>
                    </div>

                    {button?.label && (
                        <div className={cn.questionsBot}>
                            <Button
                                variant="btnBlue"
                                type="link"
                                label={button.label}
                                href={button.href}
                                customClass={cn.questionsBotButton}
                                onClick={() => {
                                    if (button?.gtag?.action) event(button?.gtag);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Questions);
