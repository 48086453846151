export const mockPreferentialCalculatorData = {
    title: 'Рассчитайте размер платежа',
    tabTitle: 'Рефинансирование',
    valueRate: [
        {
            id: 1,
            percentMin: 9.9,
            percentMax: 11.9,
            minSum: 500000,
            maxSum: 100000000000,
        },
        {
            id: 2,
            percentMin: 10.9,
            percentMax: 12.9,
            minSum: 300000,
            maxSum: 500000,
        },
        {
            id: 3,
            percentMin: 12.9,
            percentMax: 14.9,
            minSum: 0,
            maxSum: 300000,
        },
    ],
    guaranteedRateElements: {
        percentDefault: 9.9,
        percentMin: 5.9,
        percentMax: 6.9,
        maxSum: 500000,
    },
    filterElements: [
        {
            value: 20000,
            step: 20000,
            percent: 0,
            label: '20 тыс.',
            id: 1,
        },
        {
            value: 300000,
            step: 10000,
            percent: 20,
            label: '300 тыс.',
            id: 2,
        },
        {
            value: 500000,
            step: 50000,
            percent: 40,
            label: '500 тыс.',
            id: 3,
        },
        {
            value: 1000000,
            step: 100000,
            percent: 60,
            label: '1 млн.',
            id: 4,
        },
        {
            value: 2000000,
            step: 100000,
            percent: 80,
            label: '2 млн.',
            id: 5,
        },
        {
            value: 4000000,
            step: 100000,
            percent: 100,
            label: '4 млн.',
            id: 6,
        },
    ],
    terms: [
        {
            id: 1,
            label: '3 года',
            value: 3,
        },
        {
            id: 2,
            label: '4 года',
            value: 4,
        },
        {
            id: 3,
            label: '5 лет',
            value: 5,
        },
        {
            id: 4,
            label: '6 лет',
            value: 6,
        },
        {
            id: 5,
            label: '7 лет',
            value: 7,
        },
    ],
    minValue: 20000,
    maxvalue: 4000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
