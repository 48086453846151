export const mockArticlesListForDetail = {
    _template: 'articlesListForDetail',
    title: 'Вам может быть интересно',
    button: {
        title: 'Все статьи',
        link: '/articles',
    },
    list: [
        {
            id: 9034,
            name: '5 интересных фактов о кредитах, которые вы наверняка не знаете. Проверьте себя!',
            code: '706862',
            previewPictureSrc: '/img-next/png/main-slider/1.png',
            detailListUrl: '/news/706862',
            time: '5 min',
            date: '06.07.2022',
        },
        {
            id: 9036,
            name: '«Сыр как залог» и еще 4 необычных факта о кредитах, которые вас удивят',
            code: '706823',
            previewPictureSrc: '/img-next/png/main-slider/1.png',
            detailListUrl: '/news/706823',
            time: '5 min',
            date: '06.07.2022',
        },
        {
            id: 9038,
            name: 'Спорим, этого о кредитах вы не знали? 5 интересных фактов, которые вас удивят и рассмешат',
            code: '706799',
            previewPictureSrc: '/img-next/png/main-slider/1.png',
            detailListUrl: '/news/706799',
            time: '5 min',
            date: '06.07.2022',
        },
        {
            id: 9042,
            name: 'ТОП-5 интересных фактов о кредитах. Этого не знают даже сотрудники банков!',
            code: '705157',
            previewPictureSrc: '/img-next/png/main-slider/1.png',
            detailListUrl: '/news/705157',
            time: '5 min',
            date: '06.07.2022',
        },
        {
            id: 9040,
            name: '«Пушкинскую карту» для молодежи можно будет получить в Почта Банке',
            code: '705159',
            previewPictureSrc: '/img-next/png/main-slider/1.png',
            detailListUrl: '/news/705159',
            time: '5 min',
            date: '06.07.2022',
        },
    ],
};

export const mockArticlesListForDetailTinaData = {
    title: 'Заголовок',
    button: {
        title: 'Все статьи',
        link: '/articles',
    },
};
