import React from 'react';

import { TinaUnsubscribeNews } from '@/components/blocks/frames/UnsubscribeNews/index.tina';

export const unsubscribeNewsBlock = {
    Component: props => <TinaUnsubscribeNews {...props} />,
    template: {
        label: 'Форма отписки от рассылки',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
