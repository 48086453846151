import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { ITariffRow } from '@/types/tariff/item';

import Arrows from './arrows';
import TariffItem from './item';
import cn from './style.module.sass';

export interface ITariff {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'groupedTariffs';
    /**
     * Список категорий
     */
    list: ITariffRow[];
}

const GroupedTariffs: React.FC<ITariff> = ({ list }) => {
    if (!list || list?.length <= 0) return null;
    const getTrigger = (trigger: string) => (
        <AnimatedComponent className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger}>{trigger}</div>
            <div className={cn.arrow}>
                <Arrows />
            </div>
        </AnimatedComponent>
    );

    return (
        <div className={clsx(cn.tariff, 'section')}>
            <div className={cn.tariffWrap}>
                {list.map(({ name, items }, index) => (
                    <AnimatedComponent key={index} className={cn.tariffRow} classNameActive={cn.animationInit}>
                        <div className={clsx(cn.tariffRowWrap)}>
                            <div className={clsx(cn.tariffItem, cn.tariffItemTitle)}>
                                <TextField
                                    text={name}
                                    className={cn.tariffTitle}
                                    name={`list.[${index}].name`}
                                    isHTML
                                    htmlValue={name}
                                />
                            </div>
                            <div className={clsx(cn.tariffItem, cn.tariffItemInfo)}>
                                <AnimatedComponent
                                    className={clsx(cn.tariffCollapsibleWrap)}
                                    classNameActive={cn.animationInit}
                                >
                                    <Collapsible
                                        open
                                        trigger={getTrigger('Действующие')}
                                        triggerOpenedClassName={cn.open}
                                        triggerTagName="div"
                                        transitionTime={300}
                                        easing="ease-in-out"
                                        openedClassName={clsx(cn.tariffCollapsible, cn.tariffCollapsibleOpen)}
                                        className={clsx(cn.tariffCollapsible, cn.tariffCollapsibleClose)}
                                    >
                                        {items
                                            ?.filter(current => !current.archive)
                                            ?.map((tariffItem, itemIndex) => (
                                                <TariffItem key={itemIndex} {...tariffItem} />
                                            ))}
                                    </Collapsible>
                                </AnimatedComponent>
                                {items?.filter(current => current.archive).length > 0 && (
                                    <AnimatedComponent
                                        className={clsx(cn.tariffCollapsibleWrap)}
                                        classNameActive={cn.animationInit}
                                    >
                                        <Collapsible
                                            openedClassName={clsx(cn.tariffCollapsible, cn.tariffCollapsibleOpen)}
                                            className={clsx(cn.tariffCollapsible, cn.tariffCollapsibleClose)}
                                            trigger={getTrigger('Архивные')}
                                            triggerOpenedClassName={cn.open}
                                            triggerTagName="div"
                                            transitionTime={300}
                                            easing="ease-in-out"
                                        >
                                            {items
                                                ?.filter(current => current.archive)
                                                ?.map((tariffItem, itemIndex) => (
                                                    <TariffItem key={itemIndex} {...tariffItem} />
                                                ))}
                                        </Collapsible>
                                    </AnimatedComponent>
                                )}
                            </div>
                        </div>
                    </AnimatedComponent>
                ))}
            </div>
        </div>
    );
};

export default withBlockLinks(GroupedTariffs);
