export const mockInstructionBlockData = {
    name: 'Как получить кредит',
    tagList: [
        {
            value: 'Я ёщё не клиент',
            xmlId: 'no',
        },
        {
            value: 'Я клиент Почта Банк',
            xmlId: 'yes',
        },
    ],
    list: [
        {
            title: 'Оставьте заявку',
            subtitle: 'Онлайн на сайте, в клиентском центре или на стойке продаж.',
        },
        {
            title: 'Дождитесь одобрения',
            subtitle: 'Предварительное решение онлайн в течение 1 минуты.',
        },
        {
            title: 'Получите кредит',
            subtitle: 'Получите кредит наличными или на карту.',
        },
    ],
};

export const mockInstructionBlockCreditGoods = {
    name: 'Как получить кредит',
    tagList: [
        {
            value: 'В магазине-партнере банка',
            xmlId: 'no',
        },
        {
            value: 'На сайте торговой организации',
            xmlId: 'yes',
        },
    ],
    list: [
        {
            tags: ['no', 'yes'],
            title: 'Обратитесь с паспортом',
            subtitle: 'и выпиской на товар в отделение Почта Банка или к продавцу в магазине.',
        },
        {
            tags: ['no', 'yes'],
            title: 'Подпишите документы',
            subtitle:
                'в торговой организации или в одном из отделений Почта Банка, а если Вы уже клиент банка, подпишите договор с помощью простой электронной подписи.',
        },
        {
            tags: ['no'],
            title: 'Получите товар в магазине',
            subtitle: '',
            btn: {
                link: '#',
                title: 'Магазины',
            },
        },
    ],
};

export const mockInstructionBlockDataOnlineCredit = {
    name: 'Как пользоваться онлайн кредитованием',
    list: [
        {
            title: 'Подать заявку',
            subtitle: 'на подключение в качесте партнера',
        },
        {
            title: 'Подписать соглашение',
            subtitle: 'о сотрудничестве удаленно',
        },
        {
            title: 'Интегрировать',
            subtitle: 'на сайт интернет-магазина виджет WEB-анкеты на кредит',
        },
    ],
};

export const mockInstructionBlockTinaData = {
    section: null,
    name: 'Заголовок',
    tagList: [],
    list: [
        {
            name: 'elem1',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        {
            name: 'elem2',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        {
            name: 'elem3',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
    ],
    button: {
        name: 'Заголовок',
        link: '#',
    },
};
