import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsHotLine } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormHotLine {
    _template: 'feedbackFormHotLine';
    title?: string;
    description?: string;
    successTitle?: string;
}

const FeedbackFormHotLine: React.FC<IFeedbackFormHotLine> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormHotLine',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsHotLine}
        title={title}
        description={description}
        successTitle={successTitle}
    />
);

export default FeedbackFormHotLine;
