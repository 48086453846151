import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import { apiRequest } from '@/utils/request';

const useAxiosFetch = (url, flag) => {
    const [data, setData] = useState<void | AxiosResponse>(null);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const source = axios.CancelToken.source();
    useEffect(() => {
        try {
            if (flag) {
                setLoading(true);
                const promise = apiRequest(url, {
                    cancelToken: source.token,
                })
                    .catch(thrown => {
                        if (axios.isCancel(thrown)) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
                            console.log(`request cancelled:${thrown.message}`);
                        } else {
                            // console.log('another error happened');
                        }
                    })
                    .then(a => {
                        setData(a);
                        setLoading(false);
                    });
            }
        } catch (e) {
            setData(null);
            setError(e);
        }

        return function () {
            source.cancel('Cancelling in cleanup');
        };
    }, [url, flag]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { data, loading, error };
};

export default useAxiosFetch;
