import clsx from 'clsx';
import React, { useState } from 'react';

import { disclaimer } from '@/components/blocks/HRLanding/data/disclaimer';
import { socialHR } from '@/components/blocks/HRLanding/data/social';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import Container from '@/components/blocks/SalesHRLanding/core/Container';
import Menu, { TMenu } from '@/components/blocks/SalesHRLanding/core/Menu';
import Socials from '@/components/blocks/SalesHRLanding/core/Socials';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/SalesHRLanding/helpers/constants/menu';
import SubscriptionModal from '@/components/SubscriptionModal';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useResize } from '@/hooks/useResize';
import { TABLET_MOBILE } from '@/utils/constants';

import Logo from './logo';
import cn from './style.module.sass';

type Props = {
    menu: TMenu['list'];
};

function Footer({ menu }: Props) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const width = useResize();

    const isTablet = width <= TABLET_MOBILE;

    return (
        <div className={cn.footer}>
            <Container>
                <div className={cn.footer__grid}>
                    {!isTablet && <Logo />}
                    <div className={clsx(cn.footer__item, cn.footer__item_menu)}>
                        <Menu align="center" list={menu} color="gray" />
                    </div>
                    <div className={clsx(cn.footer__item, cn.footer__item_full)}>
                        <div className={clsx(cn.footer__item, cn.footer__item_socials)}>
                            <Socials socials={socialHR} />
                        </div>
                        {isTablet && <Logo />}
                        <div className={clsx(cn.footer__item, cn.footer__item_button)}>
                            <ScrollLink
                                to={APPLY_FOR_JOB_KEY}
                                offset={-90}
                                activeClass="menuLinkActive"
                                className="productPanelMenuLink"
                            >
                                <HRButton customClass={cn.footer__button} type="button" label="Хочу в команду" />
                            </ScrollLink>
                        </div>
                    </div>

                    <div className={clsx(cn.footer__item, cn.footer__item_info)}>
                        <button
                            className={cn.footer__button_info}
                            type="button"
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            Информация об изображениях, использованных на сайте.
                        </button>
                    </div>
                </div>
            </Container>
            <SubscriptionModal
                isOpen={isModalOpen}
                isFail
                isInfoText
                handleClose={() => {
                    setIsModalOpen(false);
                }}
                title={disclaimer}
            />
        </div>
    );
}

export default Footer;
