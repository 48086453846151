import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IVezdedohodCard {
    _template: 'vezdedohodCard';
    title?: string;
    gtagClick?: GTagEvent;
}

const VezdedohodCardContent = dynamic(async () => import('./VezdedohodCardContent'), {
    ssr: false,
});

const VezdedohodCard: React.FC<IVezdedohodCard & { index: number }> = ({ title, gtagClick }) => (
    <div className="section">
        <VezdedohodCardContent title={title} gtagClick={gtagClick} />
    </div>
);

export default withBlockLinks(VezdedohodCard);
