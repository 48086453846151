import React from 'react';

import { TinaPurchases } from '@/components/blocks/purchases/Purchases/index.tina';
import { mockPurchasesBlockDataTina } from '@/data/blocks/purchases';

export const purchasesBlock = {
    Component: props => <TinaPurchases {...props} />,
    template: {
        label: 'Покупки',
        defaultItem: () => mockPurchasesBlockDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockPurchasesBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'date',
                        component: 'text',
                        label: 'Дата',
                    },
                    {
                        name: 'endDate',
                        component: 'text',
                        label: 'Дата окончания',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        component: 'select',
                        name: 'status',
                        label: 'Тип',
                        options: [
                            {
                                value: 'active',
                                label: 'Активный',
                            },
                            {
                                value: 'running',
                                label: 'Заключение договора',
                            },
                            {
                                value: 'end',
                                label: 'Завершена',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
