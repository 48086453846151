import { ITinyGradientCategory } from '@/components/blocks/TinyGradientCategory/types';

export const tinaTinyGradientCategoryData: ITinyGradientCategory = {
    _template: 'tinyGradientCategory',
    title: 'Кэшбек на выбор',
    subTitle: 'Меняйте категории каждый месяц как вам выгодно',
    list: [
        {
            id: 1,
            previewTitle: 'Настройте категории кэшбека 1',
            storyTitle: 'Категории кэшбека каждый месяц 1',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 2,
            previewTitle: 'Настройте категории кэшбека 2',
            storyTitle: 'Категории кэшбека каждый месяц 2',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 3,
            previewTitle: 'Настройте категории кэшбека 3',
            storyTitle: 'Категории кэшбека каждый месяц 3',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 4,
            previewTitle: 'Настройте категории кэшбека 4',
            storyTitle: 'Категории кэшбека каждый месяц 4',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 5,
            previewTitle: 'Настройте категории кэшбека 5',
            storyTitle: 'Категории кэшбека каждый месяц 5',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 6,
            previewTitle: 'Настройте категории кэшбека 6',
            storyTitle: 'Категории кэшбека каждый месяц 6',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 7,
            previewTitle: 'Настройте категории кэшбека 7',
            storyTitle: 'Категории кэшбека каждый месяц 7',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
        {
            id: 8,
            previewTitle: 'Настройте категории кэшбека 8',
            storyTitle: 'Категории кэшбека каждый месяц 8',
            storyDescription:
                'Обычно категории кэшбэка включают такие сферы, как покупки в супермаркетах, ресторанах, заправках, путешествиях, развлечениях',
            img: '',
            backgroundImage: '',
            openStoryImg: '',
            button: {
                href: '#',
                label: '',
            },
        },
    ],
};
