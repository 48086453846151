import React from 'react';

import { hrVacanciesList, hrVacanciesListItemTina } from '@/components/blocks/PushHRLanding/data/hrFaq';
import { TinaPushHRLanding } from '@/components/blocks/PushHRLanding/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const pushHrLandingBlock = {
    Component: props => <TinaPushHRLanding {...props} />,
    template: {
        label: 'Push HR лендинг',
        defaultItem: () => ({ titleVacancy: 'Вакансии', listVacancy: hrVacanciesList }),
        fields: [
            {
                name: 'titleVacancy',
                component: HtmlEditorFeatured,
                label: 'Заголовок для блока Вакансии',
            },
            {
                label: 'Список элементов для блока Вакансии',
                name: 'listVacancy',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => hrVacanciesListItemTina,
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Подзаголовок',
                    },
                    {
                        name: 'description',
                        component: HtmlEditorFeatured,
                        label: 'Описание вакансии',
                    },
                ],
            },
        ],
    },
};
