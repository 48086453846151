import React from 'react';

import { TinaBankAdvantages } from '@/components/blocks/BankAdvantages/index.tina';
import { mockBankAdvantagesBlockDataTina } from '@/data/blocks/bankAdvantages';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const bankAdvantagesBlock = {
    Component: props => <TinaBankAdvantages {...props} />,
    template: {
        label: 'Преимущества банка',
        defaultItem: () => mockBankAdvantagesBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'bgImg',
                component: 'image',
                label: 'Фоновое Изображение.',
                parse: (media: { src: string }) => media.src,
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name || 'Элемент списка без названия',
                }),
                defaultItem: () => mockBankAdvantagesBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        label: 'Логотипы',
                        description: 'Отображаются при отсутствии заголовка',
                        name: 'logo',
                        component: 'group-list',
                        itemProps: (item: { alt: string }, index: number) => ({
                            key: index,
                            label: item.alt,
                        }),
                        defaultItem: () => mockBankAdvantagesBlockDataTina.list[0].logo[0],
                        fields: [
                            {
                                name: 'alt',
                                component: 'text',
                                label: 'Альтернативный текст',
                            },
                            {
                                name: 'img',
                                component: 'image',
                                label: 'Изображение.',
                                parse: (media: { src: string }) => media.src,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
