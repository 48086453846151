import React, { FC, useEffect, useMemo, useState } from 'react';

import QuotePersonItem from '@/components/blocks/aboutPB/QuotePerson/item';
import LazyRender from '@/components/LazyRender';
import { useResize } from '@/hooks/useResize';
import { IQuotePersonItem } from '@/types/quotePerson/item';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IQuotePerson {
    list: IQuotePersonItem[];
}

const QuotePerson: FC<IQuotePerson> = ({ list }) => {
    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [SwiperSlide, setSwiperSlide] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [Swiper, setSwiper] = useState(null);

    const slidesPerView = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.15;
        if (width < MOBILE_WIDTH) return 2.15;
        if (width < TABLET_SMALL_WIDTH) return 2.15;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView,
        watchOverflow: true,
    };

    const getSwiperSlide = async () => {
        const swiperComponents = await import('swiper/react');
        setSwiper(swiperComponents.Swiper);
        setSwiperSlide(swiperComponents.SwiperSlide);
    };

    useEffect(() => {
        getSwiperSlide().catch(e => console.log(e));
    }, []);

    return (
        <div className={cn.grid}>
            {isDesktop && (
                <div className={cn.list}>
                    {list?.map(({ img, personName, personPosition, text, pos }, index) => (
                        <QuotePersonItem
                            img={img}
                            personName={personName}
                            personPosition={personPosition}
                            text={text}
                            pos={pos || index}
                            key={index}
                        />
                    ))}
                </div>
            )}
            {isMobile && Swiper && SwiperSlide && (
                <LazyRender rootMargin="90px" className={cn.slider}>
                    <Swiper {...settings}>
                        {list?.map(({ img, personName, personPosition, text }, index) => (
                            <SwiperSlide key={`${index}-${personName}`}>
                                <QuotePersonItem
                                    img={img}
                                    personName={personName}
                                    personPosition={personPosition}
                                    text={text}
                                    pos={index}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </LazyRender>
            )}
        </div>
    );
};

export default QuotePerson;
