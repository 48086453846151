import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

export interface IGuaranteeCalculatorStore {
    range: IRangeInput;
    contributionValue: number; // взнос
    initialized: boolean;
    isChecked?: boolean;
    toggleChecked?: () => void;
    fixedIncome: number; // фиксированный доход
    taxDeduction: number; // налоговый вычет
    contributionSum: number; // сумма взносов
    contributionIncomeSum: number; // сумма взносов + доход
    cashback: number; // кешбек
}

class GuaranteeCalculatorStore implements IGuaranteeCalculatorStore {
    public range: IRangeInput = null;

    public initialized = false;

    public cashback = 12;

    public isChecked = false;

    public terms = 5;

    public taxDeductionPercentage = 13;

    constructor() {
        makeAutoObservable(this);
    }

    get contributionValue(): number {
        return this.range.value;
    }

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    get contributionSum() {
        if (this.contributionValue > this.range.maxValue) return 0;

        return Math.round(this.contributionValue * this.terms);
    }

    get contributionIncomeSum() {
        return Math.round(this.contributionSum + this.fixedIncome);
    }

    get fixedIncome() {
        return Math.round(this.contributionValue * (this.cashback / 100) * this.terms);
    }

    get taxDeduction() {
        if (this.contributionValue * this.taxDeductionPercentage * this.terms > 97500) {
            return 97500;
        }
        return Math.round(this.contributionValue * this.taxDeductionPercentage);
    }

    public init = (filterElements: ICalculatorFilterElement[], cashback: number, template: string) => {
        const rangeInitValue = filterElements[0].value;

        this.range = new RangeInput({
            name: 'contributionValue',
            label: 'Ежегодный взнос',
            elements: filterElements,
            initValue: rangeInitValue,
        });

        this.cashback = cashback;

        this.initialized = true;
    };
}

export default new GuaranteeCalculatorStore();
