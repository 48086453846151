/* eslint-disable no-param-reassign */
import clsx from 'clsx';
import Image from 'next/image';
import React, { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ISwiperRef } from '@/types/animatedPromo/item';

import { IShowcaseCard, ShowcaseCard } from '../ShowcaseCard';
import cn from './style.module.sass';

export interface ISliderList {
    list: IShowcaseCard[];
}

const SliderList: FC<ISliderList> = ({ list }) => {
    const swiperRef = useRef<ISwiperRef>(null);

    const nextSlide = () => {
        swiperRef?.current?.swiper?.slideNext();
    };

    const prevSlide = () => {
        swiperRef?.current?.swiper?.slidePrev();
    };

    const isOnlyOne = list?.length === 1;
    const isSixOrSmaller = list?.length <= 6;
    const smallerThenSix = list?.length < 6;

    return (
        <div className={cn.sliderList}>
            <button
                type="button"
                className={clsx(isSixOrSmaller && cn.notVisible, cn.sliderButton, cn.left)}
                onClick={prevSlide}
            >
                <Image src="/img-next/svg/circled-arrow.svg" height={48} width={48} alt="prev" />
            </button>
            <Swiper
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line react/jsx-no-bind
                ref={swiperRef}
                className={clsx(cn.tiledShowcase, smallerThenSix && cn.spaceBetween, isOnlyOne && cn.onlyOne)}
                spaceBetween={16}
                slidesPerView={6}
                loop={!isSixOrSmaller}
            >
                {list?.map((showcaseCard, idx) => (
                    <SwiperSlide key={idx}>
                        <ShowcaseCard
                            image={showcaseCard.image}
                            listLink={showcaseCard.listLink}
                            listName={showcaseCard.listName}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <button
                type="button"
                className={clsx(isSixOrSmaller && cn.notVisible, cn.sliderButton, cn.right)}
                onClick={nextSlide}
            >
                <Image src="/img-next/svg/circled-arrow.svg" height={48} width={48} alt="next" />
            </button>
        </div>
    );
};

export default SliderList;
