import React from 'react';

import { TinaInfo } from '@/components/blocks/Info/index.tina';
import { mockInfoBlockTinaData } from '@/data/blocks/info';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoBlock = {
    Component: props => <TinaInfo {...props} />,
    template: {
        label: 'Информационый блок',
        defaultItem: () => mockInfoBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },

            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список информационных блоков',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockInfoBlockTinaData.list[0],
                fields: [
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер',
                        options: [
                            {
                                value: 'small',
                                label: 'Маленький',
                            },
                            {
                                value: 'middle',
                                label: 'Средний',
                            },
                            {
                                value: 'big',
                                label: 'Большой',
                            },
                            {
                                value: 'full',
                                label: 'Огромный',
                            },
                        ],
                    },
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'bg',
                        component: 'text',
                        label: 'Цвет фона',
                    },

                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'linkHref',
                        component: 'text',
                        label: 'Кнопка. Ссылка',
                    },
                    {
                        name: 'linkName',
                        component: 'text',
                        label: 'Кнопка. Название',
                    },
                    {
                        name: 'markContent',
                        component: HtmlEditorFeatured,
                        label: 'Кнопка с подсказкой. Контент',
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                name: 'bottomText',
                component: HtmlEditorFeatured,
                label: 'Нижний текст',
            },
            {
                name: 'textInfo',
                component: 'text',
                label: 'Текст кнопки тултипа',
            },
            {
                name: 'tooltipText',
                component: HtmlEditorFeatured,
                label: 'Текст тултипа',
            },
            {
                label: 'Кнопка',
                name: 'btn',
                component: 'group',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
