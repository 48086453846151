import React from 'react';

import { TinaLightBanner } from '@/components/blocks/banners/LightBanner/index.tina';
import { mockLightBannerBlockDataTina } from '@/data/blocks/lightBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const lightBannerBlock = {
    Component: props => <TinaLightBanner {...props} />,
    template: {
        label: 'Светлый баннер',
        defaultItem: () => mockLightBannerBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'leftImage',
                component: 'image',
                label: 'Левая картинка',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'rightImage',
                component: 'image',
                label: 'Правая картинка (будет отображаться в моб версии)',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
