import React from 'react';

import AnimatedComponent from '@/components/Animation';
import TextField from '@/components/TextField';
import { IExampleItem } from '@/types/example/item';

import cn from './style.module.sass';

const ExampleItem: React.FC<IExampleItem & { pos: number }> = ({ title, info, pos }) => (
    <AnimatedComponent className={cn.exampleItem} classNameActive={cn.animationInit}>
        <div className={cn.exampleItemWrap}>
            <div className={cn.exampleItemTop}>
                <TextField text={title} name={`list.[${pos}].title`} isTextArea className={cn.exampleItemTitle} />
            </div>
            <div className={cn.exampleItemBot}>
                <div className={cn.exampleInfo}>
                    {info?.map(({ subtitle, desc }, infoPos) => (
                        <div className={cn.exampleInfoRow} key={infoPos}>
                            <TextField
                                text={subtitle}
                                name={`list.[${pos}].info.[${infoPos}].subtitle`}
                                className={cn.exampleInfoTitle}
                                isTextArea
                            />
                            <TextField
                                text={desc}
                                name={`list.[${pos}].info.[${infoPos}].desc`}
                                className={cn.exampleInfoText}
                                isTextArea
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default ExampleItem;
