import React from 'react';

import { TinaInformationCenter } from '@/components/blocks/InformationCenter/index.tina';
import { mockInformationCenterBlockDataTina } from '@/data/blocks/informationCenter';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const informationCenterBlock = {
    Component: props => <TinaInformationCenter {...props} />,
    template: {
        label: 'Информационный центр',
        defaultItem: () => mockInformationCenterBlockDataTina,
        fields: [
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'bottomText',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Список',
                name: 'phone',
                component: 'group-list',
                itemProps: (item: { tel: string }, index: number) => ({
                    key: index,
                    label: item.tel,
                }),
                defaultItem: () => mockInformationCenterBlockDataTina.phone[0],
                fields: [
                    {
                        name: 'tel',
                        component: HtmlEditorFeatured,
                        label: 'Телефон',
                    },
                    {
                        name: 'telHref',
                        component: 'text',
                        label: 'Телефонная ссылка',
                    },
                    {
                        name: 'city',
                        component: HtmlEditorFeatured,
                        label: 'Город',
                    },
                ],
            },
        ],
    },
};
