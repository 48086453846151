import { observer } from 'mobx-react-lite';
// eslint-disable-next-line import/no-named-default
import { default as NoUISlider } from 'nouislider-react';
import React, { useContext } from 'react';

import PipSecondary from '@/components/blocks/calculators/range/deprecated/PipSecondaryDeprecated';
import SecondaryCalculatorStoreContext from '@/context/SecondaryCalculatorStoreContext';
import { ICalculatorFilterElement, ICalculatorRange } from '@/domain/calculator/AbstractCalculatorStore';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag: GTagEvent;
}
const FormedRangeSecondary: React.FC<IProps> = observer(({ gtag }) => {
    const { minValue, maxValue, value, onTrackSlide, onTrackChange, filterElements } = useContext(
        SecondaryCalculatorStoreContext
    );

    const getCorrectValue = () => {
        if (+value > maxValue) return maxValue;
        if (+value < minValue) return minValue;
        return +value;
    };

    const getFormattedOptionName = (percent: number) => {
        if (percent === 0) return 'min';
        if (percent === 100) return 'max';
        return `${percent}%`;
    };

    const getRange = () =>
        filterElements?.reduce((acc: ICalculatorRange[], filterElement: ICalculatorFilterElement) => {
            const optionName = getFormattedOptionName(filterElement.percent);

            return {
                ...acc,
                [optionName]: [filterElement.value, filterElement.step],
            };
        }, []);

    return (
        <div
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
            role="presentation"
        >
            <NoUISlider
                start={[getCorrectValue()]}
                behaviour="tap"
                onSlide={onTrackSlide}
                onChange={onTrackChange}
                connect={[true, false]}
                className={cn.slider}
                range={getRange()}
                keyboardSupport={false}
                animate={false}
            />

            <div className={cn.pips}>
                <div className={cn.pipsContainer}>
                    {filterElements?.map((filterElement: ICalculatorFilterElement) => (
                        <PipSecondary key={filterElement.id} filterElement={filterElement} />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default FormedRangeSecondary;
