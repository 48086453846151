import clsx from 'clsx';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import SlideMobile from '@/components/blocks/Persons/SliderMobile/SlideMobile';
import { IPersonsItem } from '@/components/blocks/Persons/types';
import CustomSwiper from '@/components/CustomSwiper';
import { useTabletSmallWidth } from '@/hooks/useMedia';

import cn from './style.module.sass';

interface ISliderMobile {
    list: IPersonsItem[];
}
function SliderMobile({ list }: ISliderMobile) {
    const isMobile = useTabletSmallWidth();

    const settingsMobile = {
        className: clsx(cn.sliderMobile, cn.start),
        slidesPerView: 'auto',
        spaceBetween: 10,
        allowTouchMove: isMobile,
        loop: true,
    };

    return (
        <div className={cn.wrapperMobile}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <CustomSwiper {...settingsMobile}>
                {list?.map((mobileSlide, index) => (
                    <SwiperSlide key={index} className={clsx(cn.slideMobile)}>
                        <SlideMobile key={mobileSlide.name} mobileSlide={mobileSlide} />
                    </SwiperSlide>
                ))}
            </CustomSwiper>
        </div>
    );
}

export default SliderMobile;
