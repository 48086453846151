import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { withComponentView } from '@/components/HOC/withComponentView';
import { withExternalFrameView } from '@/components/HOC/withExternalFrameView';

const SavingsContent = dynamic(async () => import('./SavingsContent'), { ssr: false });

export interface ISavings {
    _template: 'savings';
    title: string;
}

const Savings: React.FC<ISavings & { index: number }> = ({ title }) => (
    <div className="section">
        <SavingsContent title={title} />
    </div>
);

export default withBlockLinks(withComponentView(withExternalFrameView(Savings)));
