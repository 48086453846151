import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import { ICalculatorDisclaimer } from '@/components/blocks/calculators/layouts/CashCalculatorV2/Info';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useAppStore } from '@/context/AppStoreContext';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import CashCalculatorStore from '@/domain/calculator/CashCalculatorStore';
import { ITermItem } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import CashCalculatorContent from './Content';

interface ICalculatorParams {
    filterElements: ICalculatorFilterElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
}

export interface ICashCalculator {
    _template?: 'cashCalculatorV2';
    title?: string;
    tabTitle?: string;
    params: ICalculatorParams;
    infoTitle?: ICalculatorDisclaimer;
    percentTooltip?: string;
    button?: {
        label: string;
        link: string;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
    };
}

const modifyTermsPercent = (params: ICalculatorParams) => {
    if (params.terms && Array.isArray(params.terms)) {
        return {
            ...params,
            terms: params.terms.map(term => ({
                ...term,
                ranges: term.ranges.map(range => ({ ...range, percent: 4 })),
            })),
        };
    }

    return params;
};

const CashCalculatorV2: React.FC<ICashCalculator> = observer(
    ({ title, params, index, gtag, button, infoTitle, percentTooltip }) => {
        const [path] = useAppStore(store => store.path);

        if (!params) return null;

        const modifiedParams = useMemo(() => modifyTermsPercent(params), [params]);

        const CashCalculatorInstance = useMemo(() => new CashCalculatorStore(modifiedParams), [path]);

        return (
            <AnimatedComponent>
                <CalculatorStoreContext.Provider value={CashCalculatorInstance}>
                    <CashCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        infoTitle={infoTitle}
                        button={button}
                        percentTooltip={percentTooltip}
                    />
                </CalculatorStoreContext.Provider>
            </AnimatedComponent>
        );
    }
);

export default withBlockLinks(CashCalculatorV2);
