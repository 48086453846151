import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { optionsV3 } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormVThree {
    _template: 'feedbackFormVThree';
    title?: string;
    description?: string;
    successTitle?: string;
    themeDescList?: { desc: string }[];
}

const FeedbackFormVThree: React.FC<IFeedbackFormVThree> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormVThree',
    themeDescList,
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsV3}
        title={title}
        description={description}
        descFlag
        successTitle={successTitle}
        themeDescList={themeDescList}
    />
);

export default withBlockLinks(FeedbackFormVThree);
