import React from 'react';

export function CrossWithoutCircleIcon() {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L14.34 15" stroke="black" strokeOpacity="0.3" />
            <path d="M14.34 0.5L1 15" stroke="black" strokeOpacity="0.3" />
        </svg>
    );
}
