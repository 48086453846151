import React from 'react';

import { TinaFeedbackUlIpSurvey } from '@/components/blocks/frames/FeedbackUlIpSurvey/index.tina';

export const feedbackUlIpSurveyBlock = {
    Component: props => <TinaFeedbackUlIpSurvey {...props} />,
    template: {
        label: 'Опрос удовлетворенности каналами обслуживания ЮЛ-ИП',
        fields: [],
    },
};
