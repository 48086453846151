import React from 'react';

import { TinaHRLanding } from '@/components/blocks/HRLanding/index.tina';

export const hrLandingBlock = {
    Component: props => <TinaHRLanding {...props} />,
    template: {
        label: 'HR лендинг',
        fields: [],
    },
};
