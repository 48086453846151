import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import PaymentGroupLayout from '@/components/blocks/frames/_deprecated/PaymentGroupLayout';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Tabs from '@/components/tabs/Tabs';
import TabContext from '@/context/TabContext';
import { ITab } from '@/types/tabsBlock';
import { IDefaultBlock } from '@/types/tina/block';

import cn from './style.module.sass';

interface IPaymentGroup {
    blocks?: IDefaultBlock[];
}

const PaymentGroup: React.FC<IPaymentGroup> = observer(({ blocks }) => {
    const pathmane = usePathname();

    const [activeTab, setActiveTab] = useState(0);

    const setActiveTabHandler = (tab: number) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        setActiveTab(0);
    }, [blocks?.length]);

    const isShowTabs = blocks?.length > 1;
    const mappedTabs = blocks?.map(({ gtag, title }) => ({ gtag, title })) as ITab[];
    return (
        <div className={clsx(cn.paymentGroup, 'section')}>
            <div className={cn.fullWrapper}>
                {pathmane?.includes('service/cashloan') && <div className="ssocpr" />}

                <div className={clsx(cn.wrapper, 'tabs')}>
                    <div className={cn.grid}>
                        <TabContext.Provider value={{ activeTab, setActiveTabHandler }}>
                            {isShowTabs && <Tabs items={mappedTabs} type="secondary" />}
                            <PaymentGroupLayout blocks={blocks} />
                        </TabContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default withBlockLinks(PaymentGroup);
