import clsx from 'clsx';
import React from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';

import cn from './style.module.sass';

interface IProps {
    title: string;
    mark?: string;
    description: string;
    handleClose: () => void;
    isOpen: boolean;
}

function ActionPensionsModal({ title, mark, description, handleClose, isOpen }: IProps) {
    return (
        <ModalWrapper handleClose={handleClose} isOpen={isOpen} isStandartModalWidth={false}>
            <div className={cn.modalWrapper}>
                {title && <div className={clsx(cn.modalTitle)} dangerouslySetInnerHTML={{ __html: title }} />}

                {mark && <div className={clsx(cn.modalMark)} dangerouslySetInnerHTML={{ __html: mark }} />}

                {description && (
                    <div className={clsx(cn.modalDesc)} dangerouslySetInnerHTML={{ __html: description }} />
                )}
            </div>
        </ModalWrapper>
    );
}

export default ActionPensionsModal;
