import React from 'react';

import sidebarAnchorLinksComponentMapper from './ComponentVariants';
import { ISidebarAnchorLinksProps } from './ComponentVariants/iSidebarAnchorLinks';

const SidebarAnchor: React.FC<ISidebarAnchorLinksProps> = ({
    list,
    rollableBlockName,
    isRollable = false,
    checked = false,
}) => {
    const SidebarAnchorLinksComponent = sidebarAnchorLinksComponentMapper(isRollable);

    const scrollOffset = 90;

    if (!list || list?.length <= 0) return null;

    return (
        <SidebarAnchorLinksComponent
            rollableBlockName={rollableBlockName}
            list={list}
            scrollOffset={scrollOffset}
            checked={checked}
        />
    );
};

export default SidebarAnchor;
