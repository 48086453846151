import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    type: 'col-3' | 'col-4' | 'col-5';
}

const CashbackPercentageItemLoader: React.FC<IProps> = ({ type }) => (
    <div className={clsx(cn.itemWrapper, type && cn[type])}>
        <div className={cn.item} />
    </div>
);

export default CashbackPercentageItemLoader;
