import React, { createRef, ReactNode, useEffect, useMemo, useState } from 'react';

interface ILazy {
    threshold?: number;
    rootMargin?: string;
    onVisible?: () => void;
    children: ReactNode;
    className?: string;
    visible?: boolean;
}
function LazyRender({ children, threshold, rootMargin, onVisible, className, visible = false }: ILazy) {
    const ref = useMemo(() => createRef<HTMLDivElement>(), []);
    const [isVisible, setIsVisible] = useState(() => visible);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const options = {
            rootMargin: rootMargin ?? '0px',
            threshold: threshold ?? 0,
        };

        const isIntersecting = (entry: IntersectionObserverEntry) =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        const observer = new IntersectionObserver((entries, observerEntry) => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    setIsVisible(true);
                    observerEntry.disconnect();

                    if (onVisible) {
                        onVisible();
                    }
                }
            });
        }, options);

        observer.observe(ref.current);

        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
        };
    }, [threshold, rootMargin, ref, onVisible]);

    return (
        <div className={className} ref={ref}>
            {isVisible ? children : null}
        </div>
    );
}

export default LazyRender;
