export const mockStretchBlockData = {
    name: 'Актуально',
    title: 'Карта «Элемент 120»',
    subtitle: '',
    image: '/img-next/png/stretch.png',
    linkText: 'Заполнить заявку',
    advantages: [
        {
            title: 'До 120 дней',
            subtitle: 'Без процентов',
        },
        {
            title: 'От 10,9 %',
            subtitle: 'ставка',
        },
        {
            title: 'До 20% кешбэк',
            subtitle: 'бонусами за покупки',
        },
    ],
    link: '#',
    rel: '',
    margin: 'big',
    gtag: {
        action: 'click',
        category: 'test',
        label: 'test',
    },
    smPadding: false,
    bgColor: '',
    inTwo: false,
};

export const mockStretchBlockTinaData = {
    section: null,
    name: 'Заголовок',
    title: 'Заголовок',
    subtitle: 'Описание',
    image: '',
    advantages: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
        },
    ],
    linkText: 'Название',
    link: '#',
    rel: '',
    margin: 'big',
    gtag: {
        action: 'click',
        category: 'test',
        label: 'test',
    },
    smPadding: false,
    bgColor: '',
    inTwo: false,
};
