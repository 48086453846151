import React from 'react';

import { TinaCreditGiftBlock } from '@/components/blocks/CreditGiftBlock/index.tina';
import { mockCreditGiftBlockBlockData } from '@/data/blocks/creditGiftBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const creditGiftBlockBlock = {
    Component: props => <TinaCreditGiftBlock {...props} />,
    template: {
        label: 'Кредит в подарок',
        defaultItem: () => mockCreditGiftBlockBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Детали акции',
                name: 'promoData',
                component: 'group',
                fields: [
                    {
                        name: 'promoPeriodTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок 1-го столбца',
                    },
                    {
                        name: 'promoPeriod',
                        component: HtmlEditorFeatured,
                        label: 'Контент 1-го столбца',
                    },
                    {
                        name: 'promoDateTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок 2-го столбца',
                    },
                    {
                        name: 'promoDate',
                        component: HtmlEditorFeatured,
                        label: 'Контент 2-го столбца',
                    },
                    {
                        name: 'promoQuantityTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок 3-го столбца',
                    },
                    {
                        name: 'promoQuantity',
                        component: HtmlEditorFeatured,
                        label: 'Контент 3-го столбца',
                    },
                ],
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'button',
                component: 'group',
                label: 'Основная кнопка',
                fields: [
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'linkName',
                        component: 'text',
                        label: 'Название',
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Кнопка-ссылка',
                name: 'link',
                component: 'group',
                fields: [
                    {
                        name: 'linkName',
                        component: 'text',
                        label: 'Текст для ссылки',
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Модальное окно',
                name: 'modal',
                component: 'group',
                fields: [
                    {
                        name: 'modalTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок в модальном окне',
                    },
                    {
                        name: 'modalDescription',
                        component: HtmlEditorFeatured,
                        label: 'Описание в модальном окне',
                    },
                ],
            },
        ],
    },
};
