import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { ISocialItem } from '@/types/footer';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface ISocialColor {
    list: ISocialItem[];
}

const SocialColor: React.FC<ISocialColor> = ({ list }) => {
    if (!list || list?.length <= 0) return null;
    return (
        <AnimatedComponent className={cn.social} classNameActive={cn.animationInit} role="presentation">
            <div className={cn.socialGrid}>
                {list?.map(({ link, name, image, gtag }) => (
                    <div className={cn.socialItem} key={link}>
                        <a
                            href={link}
                            target="_blank"
                            className={cn.socialLink}
                            rel="noopener noreferrer"
                            onClick={() => event(gtag)}
                        >
                            {image && <CustomImage src={image} alt={name} width={40} height={40} />}
                        </a>
                    </div>
                ))}
            </div>
        </AnimatedComponent>
    );
};

export default SocialColor;
