import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IAwardsItem {
    image: string;
    year: string;
    desc: string;
}

export interface IAwards {
    awardsList: IAwardsItem[];
}

const AwardsList: React.FC<IAwards> = ({ awardsList }) => {
    if (!awardsList || awardsList?.length <= 0) return null;

    return (
        <AnimatedComponent className={cn.awards} classNameActive={cn.animationInit} role="presentation">
            <div className={cn.awardsGrid}>
                {awardsList?.map(({ image, year, desc }, index) => (
                    <div className={cn.awardsItemWrapper} key={index}>
                        <div className={cn.awardsItem}>
                            <div className={cn.awardsItemContent}>
                                <div className={cn.awardsItemInfo}>
                                    <TextField
                                        className={cn.awardsYear}
                                        text={year}
                                        name={`awardsList.${index}.year`}
                                        isHTML
                                        htmlValue={year}
                                    />
                                    <TextField
                                        className={cn.awardsDesc}
                                        text={desc}
                                        name={`awardsList.${index}.desc`}
                                        isHTML
                                        htmlValue={desc}
                                    />
                                </div>
                                <div className={cn.awardsItemImage}>
                                    <div className={cn.awardsImage}>
                                        <CustomImage src={image} alt={desc} mockWidth="40" mockHeight="40" fill />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </AnimatedComponent>
    );
};

export default AwardsList;
