import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { EffectFade, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import ArticlesListItem from '@/components/blocks/ArticlesListForDetail/item';
import LazyRender from '@/components/LazyRender';
import TextField from '@/components/TextField';
import { useAppStore } from '@/context/AppStoreContext';
import { useMobileWidth } from '@/hooks/useMedia';
import { IArtilceListResponse } from '@/types/articleList';
import { IArticlesListForDetail, IArticlesListForDetailItem } from '@/types/ArticlesListForDetail';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

const CustomSwiper = dynamic(() => import('../../CustomSwiper'), {
    ssr: false,
});

SwiperCore.use([EffectFade, Pagination]);

const ArticlesListForDetail: React.FC<IArticlesListForDetail> = observer(
    ({ _template, section, apiCode, title, list, button }) => {
        const isMobile = useMobileWidth(false);
        const [path] = useAppStore(store => store.path);
        const [isTinaView] = useAppStore(store => store.isTinaView);

        const [elementsList, setElementsList] = useState<IArticlesListForDetailItem[]>(list);

        const swiperRef = useRef(null);

        const settings = {
            className: clsx(cn.carousel, 'carousel', 'articlesCarousel'),
            slidesPerView: 1,
            loop: true,
            pagination: { clickable: true },
            watchOverflow: true,
        };

        // GET params
        const sectionPart = `&section=${section || 0}`;
        const infoBlockPart = `&apiCode=${apiCode}`;

        const requestDataWithParams = async (...args) => {
            try {
                const response: IArtilceListResponse = await apiRequest(
                    `/page/block?path=${path}&block=articlesListForDetail${args.join('')}`
                );

                const {
                    data: { data: fetchedData },
                } = response;

                return fetchedData;
            } catch (e) {
                console.error(e);
                return null;
            }
        };

        const setData = async params => {
            try {
                const data = await requestDataWithParams(...params);

                setElementsList(data?.list);
            } catch (e) {
                console.error(e);
            }
        };

        useEffect(() => {
            if (!apiCode || !isTinaView) return;
            const requestParams = [sectionPart, infoBlockPart];
            setData(requestParams).catch(console.error);
        }, [section, apiCode]);

        if (elementsList?.length <= 0) {
            return (
                <div className={clsx(cn.articles, 'section')}>
                    <div className={cn.articlesWrap}>
                        <div className={cn.articlesBottom}>
                            <div className={cn.buttonWrapper}>
                                <div className={cn.button}>
                                    <a href={button?.link || '/articles'} className={cn.buttonInner}>
                                        {button?.title ? button?.title : 'Все статьи'}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (isMobile) {
            return (
                <div className={clsx(cn.articles, 'section')}>
                    <div className={cn.articlesWrap}>
                        <div className={cn.articlesInnerWrap}>
                            <TextField
                                text={title}
                                name="title"
                                className={cn.articlesTitle}
                                isHTML
                                htmlValue={title}
                            />

                            <div className={cn.articlesGrid}>
                                <div className={clsx(cn.articlesItem, cn.articlesItemList)}>
                                    <LazyRender rootMargin="100px" className={cn.currentSlider}>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <CustomSwiper {...settings} ref={swiperRef}>
                                            {elementsList?.map((item, index) => (
                                                <SwiperSlide key={`${index} - ${item.id}`}>
                                                    <ArticlesListItem key={`${index} - ${item.id}`} {...item} />
                                                </SwiperSlide>
                                            ))}
                                        </CustomSwiper>
                                    </LazyRender>
                                </div>
                            </div>

                            <div className={cn.articlesBottom}>
                                {button?.title && (
                                    <div className={cn.buttonWrapper}>
                                        <div className={cn.button}>
                                            <a href={button?.link} className={cn.buttonInner}>
                                                {button?.title}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={clsx(cn.articles, 'section')}>
                <div className={cn.articlesWrap}>
                    <div className={cn.articlesInnerWrap}>
                        <TextField text={title} name="title" className={cn.articlesTitle} isHTML htmlValue={title} />

                        <div className={cn.articlesGrid}>
                            <div className={clsx(cn.articlesItem, cn.articlesItemList)}>
                                <AnimatedComponent className={cn.list} classNameActive={cn.animationInit}>
                                    {elementsList?.map((item, index) => (
                                        <ArticlesListItem key={`${index} - ${item.id}`} {...item} />
                                    ))}
                                </AnimatedComponent>
                            </div>
                        </div>
                        <div className={cn.articlesBottom}>
                            {button?.title && (
                                <div className={cn.buttonWrapper}>
                                    <div className={cn.button}>
                                        <a href={button?.link} className={cn.buttonInner}>
                                            {button?.title}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
export default ArticlesListForDetail;
