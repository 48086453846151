import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

export interface IDocument {
    documentName: string;
    document: string;
    permanentDocument: string;
    documentType: string;
    documentSize: string;
    isSecondary?: boolean;
}

const Document: React.FC<IDocument> = observer(
    ({ documentSize, document, permanentDocument, documentName, documentType, isSecondary }) => (
        <a href={document || permanentDocument} className={clsx(cn.item, isSecondary && cn.itemSecondary)} download>
            <div className={cn.itemIconWrap}>
                <div className={cn.itemIcon}>
                    <CustomImage
                        src={`/img-next/svg/${documentType}.svg`}
                        alt={documentName}
                        width={25}
                        height={25}
                        layout="fixed"
                        quality={90}
                    />
                </div>
                <div className={cn.itemSize}>{documentSize}</div>
            </div>
            <div className={cn.itemName}>{documentName}</div>
        </a>
    )
);

export default Document;
