import React from 'react';

import { TinaFlocktoryIntegration } from '@/components/blocks/FlocktoryIntegration/index.tina';

export const flocktoryIntegrationBlock = {
    Component: props => <TinaFlocktoryIntegration {...props} />,
    template: {
        label: 'Блок для Flocktory',
        fields: [
            {
                name: 'className',
                component: 'text',
                label: 'Класс',
                required: true,
            },
        ],
    },
};
