import React from 'react';

import { TinaInfoTransitionBlock } from '@/components/blocks/InfoTransitionBlock/index.tina';
import { mockInfoTransitionBlockTinaData } from '@/data/blocks/infoTransitionBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoTransitionBlockBlock = {
    Component: props => <TinaInfoTransitionBlock {...props} />,
    template: {
        label: 'Информационный переходной блок',
        defaultItem: () => mockInfoTransitionBlockTinaData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
