import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsQuestions } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormQuestions {
    _template: 'feedbackFormQuestions';
    title?: string;
    description?: string;
    successTitle?: string;
}

const FeedbackFormQuestions: React.FC<IFeedbackFormQuestions> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormQuestions',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsQuestions}
        title={title}
        description={description}
        successTitle={successTitle}
    />
);

export default FeedbackFormQuestions;
