import clsx from 'clsx';
import Image from 'next/image';
import React, { useMemo, useRef, useState } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

export interface IYouTubeVideosElement {
    title: string;
    link: string;
    previewImage?: string;
    fullBlock?: boolean;
    buttonColor?: string;
}

interface IProps extends IYouTubeVideosElement {
    pos?: number;
}

const YouTubeVideosElement: React.FC<IProps> = ({
    pos,
    link,
    title,
    previewImage,
    fullBlock = false,
    buttonColor = 'dark',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    const ref = useRef(null);
    const handleOpen = () => {
        if (window.innerWidth > 767) {
            setIsOpen(true);
            return;
        }
        setIsMobileView(true);
    };

    const handleClose = () => {
        if (window.innerWidth > 767) {
            setIsOpen(false);
            return;
        }
        setIsMobileView(false);
    };

    useOnClickOutside(ref, handleClose);

    const imageLink = useMemo(() => {
        const id = link?.slice(-11);

        return `http://img.youtube.com/vi/${id}/maxresdefault.jpg`;
    }, [link]);

    return (
        <>
            <div
                className={clsx(cn.item, isMobileView && cn.itemMobileView, fullBlock && cn.itemFullBlock)}
                ref={ref}
                onClick={handleOpen}
                role="presentation"
            >
                <div
                    className={clsx(cn.wrapper, isMobileView && cn.wrapperMobileView)}
                    style={{ background: `url("${previewImage || imageLink}")` }}
                >
                    <div className={cn.inner}>
                        <TextField text={title} name={`list.${pos}.title`} className={cn.title} />
                        <div className={clsx(cn.button, buttonColor === 'light' && cn.buttonLight)}>
                            <Image src="/img-next/svg/youTubePlay.svg" width={14} height={17} alt="youTube" />
                        </div>
                    </div>
                    {isMobileView && (
                        <div className={cn.mobileView}>
                            <div className={cn.videoWrapper}>
                                <iframe
                                    title={title}
                                    width="560"
                                    height="349"
                                    src={link}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ModalWrapper
                isOpen={isOpen}
                handleClose={handleClose}
                handleOpen={handleOpen}
                wrapperClassName={cn.modalWrapper}
                bgClassName={cn.modalOverlay}
                className={cn.modalInner}
            >
                <div className={cn.videoWrapper}>
                    <iframe title={title} width="560" height="349" src={link} frameBorder="0" allowFullScreen />
                </div>
            </ModalWrapper>
        </>
    );
};

export default YouTubeVideosElement;
