import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import MobileAppSource from '@/components/blocks/MobileApp/source';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { IServicesMobileApp } from '@/types/services';

import cn from './style.module.sass';

export interface IServicesMobile {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'mobileApp';
    /**
     * Мобильное приложение.
     */
    mobile: IServicesMobileApp;
}

const MobileApp: React.FC<IServicesMobile> = ({ mobile }) => {
    if (!mobile) return null;

    return (
        <div className={clsx(cn.services, 'section')}>
            <div className={cn.servicesWrap}>
                <div className={cn.servicesGrid}>
                    <AnimatedComponent className={cn.servicesItem} classNameActive={cn.animationInit}>
                        <div className={clsx(cn.servicesItemWrap)} role="presentation">
                            <div className={cn.servicesContent}>
                                <div className={cn.servicesTop}>
                                    <TextField
                                        className={cn.servicesTitle}
                                        text={mobile?.title}
                                        name="mobile.title"
                                        isHTML
                                        htmlValue={mobile.title}
                                    />
                                    <TextField
                                        className={cn.servicesSubtitle}
                                        isHTML
                                        htmlValue={mobile?.previewText}
                                        text={mobile?.previewText}
                                        name="mobile.previewText"
                                    />
                                    {mobile?.link?.title && (
                                        <a
                                            href={mobile?.link?.address}
                                            target="_blank"
                                            className={cn.servicesLink}
                                            rel="noreferrer"
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: mobile.link.title,
                                                }}
                                            />
                                        </a>
                                    )}
                                </div>
                                <div className={cn.servicesBot}>
                                    <div className={cn.servicesStore}>
                                        {mobile?.store?.length > 0 &&
                                            mobile?.store?.map(({ link, name, image }, index) => (
                                                <a
                                                    href={link}
                                                    target="_blank"
                                                    className={cn.servicesStoreItem}
                                                    key={index}
                                                    rel="noreferrer"
                                                >
                                                    <CustomImage src={image} alt={name} width={131} height={38} />
                                                </a>
                                            ))}
                                    </div>
                                    <div className={cn.servicesImageMobile}>
                                        <div className={clsx(cn.servicesImage)}>
                                            <div className={cn.servicesImageWrap}>
                                                <MobileAppSource
                                                    image={mobile?.image}
                                                    isVideo={mobile?.isVideo}
                                                    video={mobile?.video}
                                                    title={mobile?.title}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx(cn.servicesImage)}>
                                <div className={cn.servicesImageWrap}>
                                    <MobileAppSource
                                        image={mobile?.image}
                                        isVideo={mobile?.isVideo}
                                        video={mobile?.video}
                                        title={mobile?.title}
                                    />
                                </div>
                            </div>
                        </div>
                    </AnimatedComponent>
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(MobileApp);
