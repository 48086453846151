import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IFaqItem } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    item: IFaqItem;
    activeItem: number;
    setActiveItemHandler?: (pos: number) => void;
    pos: number;
    handleCloseModal?: () => void;
    isThemes?: boolean;
}

const SidebarItem: React.FC<IProps> = observer(
    ({ item, setActiveItemHandler, pos, activeItem, handleCloseModal, isThemes }) => {
        const router = useRouter();

        const onClick = async () => {
            if (setActiveItemHandler) {
                setActiveItemHandler(pos);
            }

            if (handleCloseModal) handleCloseModal();

            if (item?.link) {
                await Promise.resolve(router.push(item.link));
            }
        };

        const isCurrentItemActive = pos === activeItem;

        const titleNameTina = `list.[${pos}].title`;

        return (
            <AnimatedComponent
                role="presentation"
                className={clsx(
                    cn.item,
                    isCurrentItemActive && cn.active,
                    !item?.link && !setActiveItemHandler && cn.itemDisable,
                    isThemes && cn.itemThemes
                )}
                classNameActive={cn.animationInit}
                onClick={onClick}
            >
                <div className={cn.inner}>
                    <div className={cn.image}>
                        <CustomImage src={item?.image} alt="" width="42" height="42" />
                    </div>
                    <TextField
                        text={item?.title}
                        name={titleNameTina}
                        isHTML
                        htmlValue={item.title}
                        className={cn.title}
                    />
                </div>
            </AnimatedComponent>
        );
    }
);

export default SidebarItem;
