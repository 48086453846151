import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    customClass?: string;
}

const IconPdf: React.FC<IProps> = ({ customClass }) => (
    <svg
        className={clsx(cn.pdf, customClass)}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.125 1.875C6.125 1.11989 6.74489 0.5 7.5 0.5H20.4179L27.625 7.70711V28.125C27.625 28.8801 27.0051 29.5 26.25 29.5H7.5C6.74489 29.5 6.125 28.8801 6.125 28.125V1.875Z"
            fill="white"
            stroke="#F15642"
        />
        <path d="M22.5 7.5H28.125L20.625 0V5.625C20.625 6.65625 21.4688 7.5 22.5 7.5Z" fill="#F15642" />
        <path d="M28.125 13.125L22.5 7.5H28.125V13.125Z" fill="#E3E7EB" />
        <path
            d="M24.375 24.375C24.375 24.8906 23.9531 25.3125 23.4375 25.3125H2.8125C2.29688 25.3125 1.875 24.8906 1.875 24.375V15C1.875 14.4844 2.29688 14.0625 2.8125 14.0625H23.4375C23.9531 14.0625 24.375 14.4844 24.375 15V24.375Z"
            fill="#F15642"
        />
        <path
            d="M5.96094 17.7626C5.96094 17.5151 6.15594 17.2451 6.47 17.2451H8.20156C9.17656 17.2451 10.0541 17.8976 10.0541 19.1482C10.0541 20.3332 9.17656 20.9932 8.20156 20.9932H6.95V21.9832C6.95 22.3132 6.74 22.4998 6.47 22.4998C6.2225 22.4998 5.96094 22.3132 5.96094 21.9832V17.7626ZM6.95 18.1892V20.0567H8.20156C8.70406 20.0567 9.10156 19.6132 9.10156 19.1482C9.10156 18.6242 8.70406 18.1892 8.20156 18.1892H6.95Z"
            fill="white"
        />
        <path
            d="M11.5214 22.4997C11.2739 22.4997 11.0039 22.3647 11.0039 22.0356V17.7775C11.0039 17.5084 11.2739 17.3125 11.5214 17.3125H13.238C16.6636 17.3125 16.5886 22.4997 13.3055 22.4997H11.5214ZM11.9939 18.2275V21.5856H13.238C15.262 21.5856 15.352 18.2275 13.238 18.2275H11.9939Z"
            fill="white"
        />
        <path
            d="M17.8048 18.2875V19.479H19.7164C19.9864 19.479 20.2564 19.749 20.2564 20.0106C20.2564 20.2581 19.9864 20.4606 19.7164 20.4606H17.8048V22.0346C17.8048 22.2971 17.6183 22.4987 17.3558 22.4987C17.0258 22.4987 16.8242 22.2971 16.8242 22.0346V17.7765C16.8242 17.5075 17.0267 17.3115 17.3558 17.3115H19.9873C20.3173 17.3115 20.5123 17.5075 20.5123 17.7765C20.5123 18.0165 20.3173 18.2865 19.9873 18.2865H17.8048V18.2875Z"
            fill="white"
        />
        <path
            d="M23.4375 25.3125H5.625V26.25H23.4375C23.9531 26.25 24.375 25.8281 24.375 25.3125V24.375C24.375 24.8906 23.9531 25.3125 23.4375 25.3125Z"
            fill="#CAD1D8"
        />
    </svg>
);

export default IconPdf;
