import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import PartnersItem from '@/components/blocks/partners/PartnersItem';
import PartnersItemLoader from '@/components/blocks/partners/PartnersItemLoader';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { IPartnersItem } from '@/types/partners/item';
import { IDefaultBlock } from '@/types/tina/block';

import cn from './style.module.sass';

export interface IPartners extends IDefaultBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'partners';
    title?: string;
    /**
     * Список блоков.
     */
    list: IPartnersItem[];
    // Количество видимых элементов
    limit: number;
    // Количество элементов в строке
    type: 'col-3' | 'col-4' | 'col-5';
    // Размер элементов
    size?: 'big' | 'small';
    showAll: boolean;
}

const Partners: React.FC<IPartners> = ({
    list,
    limit,
    type = 'col-3',
    title,
    isDataFetching,
    size = 'big',
    showAll = false,
}) => {
    const [hidden, setHidden] = useState(true);

    const mockItems = useMemo(() => {
        if (type === 'col-5') return new Array<string>(5).fill('');
        if (type === 'col-4') return new Array<string>(4).fill('');
        if (type === 'col-3') return new Array<string>(3).fill('');
        return new Array<string>(3).fill('');
    }, [type]);

    const partners = useMemo(() => {
        if (showAll || !hidden) {
            return list;
        }
        return list?.filter((current, index) => index + 1 <= limit);
    }, [list, hidden]);

    const showButton = useMemo(() => !showAll, [showAll]);

    const toggleItems = () => {
        setHidden(prevState => !prevState);
    };

    if (isDataFetching)
        return (
            <div className={clsx(cn.partners, 'section')}>
                <div className={cn.partnersWrap}>
                    {title && <div className={cn.titleLoader} />}
                    <div className={clsx(cn.partnersGrid)}>
                        {mockItems?.map((_, index) => (
                            <PartnersItemLoader key={index} type={type} />
                        ))}
                    </div>
                </div>
            </div>
        );

    return (
        <AnimatedComponent>
            <div className={clsx(cn.partners, 'section')}>
                <div className={cn.partnersWrap}>
                    {title && (
                        <TextField
                            text={title}
                            className={cn.mainTitle}
                            name="name"
                            customTag="h3"
                            isHTML
                            htmlValue={title}
                        />
                    )}
                    <div className={clsx(cn.partnersGrid)}>
                        {partners?.map((item, index) => (
                            <PartnersItem
                                key={index}
                                pos={index}
                                title={item.title}
                                image={item.image}
                                imageDarkTheme={item.imageDarkTheme}
                                link={item.link}
                                type={type}
                                size={size}
                            />
                        ))}
                    </div>
                    {showButton && (
                        <AnimatedComponent className={cn.partnersBtn} classNameActive={cn.animationInit}>
                            <Button
                                variant="btnBlueBorder"
                                type="button"
                                label={hidden ? 'Показать ещё' : 'Свернуть'}
                                customClass={clsx(cn.btnAll)}
                                darkWhite
                                onClick={toggleItems}
                            />
                        </AnimatedComponent>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Partners);
