import React from 'react';

import PboFaqDetailSidebarLink, { TPboFaqItem } from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebarLink';

import cn from './style.module.sass';

export interface IPboFaqDetailSidebar {
    list: TPboFaqItem[];
}

const PboFaqDetailSidebarNavigation = ({ list }: IPboFaqDetailSidebar) => (
    <div className={cn.navigation}>
        {list.map(item => (
            <PboFaqDetailSidebarLink
                title={item.title}
                icon={item.icon}
                subSectionLinks={item.subSectionLinks}
                key={item.title}
            />
        ))}
    </div>
);

export default PboFaqDetailSidebarNavigation;
