import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import Decor from '@/components/blocks/News/decor';
import TextField from '@/components/TextField';
import { INewsItem } from '@/types/news/item';
import { getDateObjectFromISO } from '@/utils/date/getDateObjectFromISO';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    item: INewsItem;
    pos: number;
}

const Item: React.FC<IProps> = observer(({ item, pos }) => {
    const { month, day, year } = getDateObjectFromISO(item?.date);

    return (
        <AnimatedComponent className={cn.newsItem} classNameActive={cn.animationInit}>
            <a
                href={item?.link}
                className={clsx(cn.newsItemWrap, cn.newsItemWrapLink)}
                onClick={() => {
                    if (item?.gtag?.action) event(item?.gtag);
                }}
            >
                <div className={cn.newsTop}>
                    <TextField
                        text={item?.title}
                        className={cn.newsTitle}
                        name={`list.[${pos}].title`}
                        isHTML
                        htmlValue={item?.title}
                    />
                </div>
                <div className={cn.newsBot}>
                    <div className={cn.newsData}>
                        <div className={cn.newsDataNumber}>{day}</div>
                        <div className={cn.newsDataFull}>
                            <span>{month}</span>
                            <span>{year}</span>
                        </div>
                    </div>
                </div>
                <div className={cn.newsBotDecor}>
                    <Decor />
                </div>
            </a>
        </AnimatedComponent>
    );
});

export default Item;
