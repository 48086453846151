import React from 'react';

import { TinaMobileApp } from '@/components/blocks/MobileApp/index.tina';
import { mockMobileAppBlockTinaData } from '@/data/blocks/mobileApp';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const mobileAppBlock = {
    Component: props => <TinaMobileApp {...props} />,
    template: {
        label: 'Мобильное приложение',
        defaultItem: () => mockMobileAppBlockTinaData,
        fields: [
            {
                name: 'mobile',
                component: 'group',
                label: 'Мобильное приложение',
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'previewText',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'link',
                        component: 'group',
                        label: 'Дополнительная ссылка',
                        fields: [
                            {
                                name: 'address',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'title',
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'store',
                        component: 'group-list',
                        label: 'Список приложений',
                        description: 'Выводятся на мобильной версии',
                        itemProps: (item: { name: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => ({
                            image: '/img-next/svg/services/AppStore.svg',
                            name: 'App Store',
                            link: '#',
                        }),
                        fields: [
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Картинка',
                                parse: (media: { src: string }) => media.src,
                            },
                        ],
                    },
                    {
                        name: 'isVideo',
                        component: 'toggle',
                        label: 'Картинка/Видео',
                    },
                    {
                        name: 'video',
                        component: 'webm',
                        label: 'Видео',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
