import clsx from 'clsx';
import React, { FC, useMemo, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';

import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { ICreditSliderItem } from '@/types/creditSlider';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import CreditSliderItem from './item';
import cn from './style.module.sass';

export interface ICreditSlider {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'creditSlider';
    title: string;
    text?: string;
    list: ICreditSliderItem[];
    disableSlider?: boolean;
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef.current.swiper.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef.current.swiper.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const CreditSlider: React.FC<ICreditSlider> = ({ gtag, title, text, list, disableSlider }) => {
    const width = useResize();

    const swiperRef = useRef(null);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return list.length < 3 ? list.length : 3;
        if (width < MOBILE_SM_WIDTH) return 1.16;
        if (width < MOBILE_WIDTH) return 1.32;
        if (width < TABLET_SMALL_WIDTH) return 3;
        return list.length < 3 ? list.length : 3;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
    };

    const showHead = title || text;

    if (disableSlider) {
        return (
            <div className={clsx(cn.credit, 'section')}>
                <div className={cn.creditWrap}>
                    {showHead && (
                        <div className={cn.creditTop}>
                            {title && (
                                <TextField
                                    text={title}
                                    name="title"
                                    className={cn.creditTitle}
                                    customTag="h3"
                                    isHTML
                                    htmlValue={title}
                                />
                            )}
                            {text && (
                                <TextField text={text} name="text" className={cn.creditText} isHTML htmlValue={text} />
                            )}
                        </div>
                    )}

                    {list?.length > 0 && (
                        <div className={cn.creditGrid}>
                            <div className={clsx(cn.creditSlider, cn.creditNoSlider)}>
                                {list?.map((item, index) => (
                                    <CreditSliderItem key={index} pos={index} {...item} />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.credit, 'section')}>
            <div className={cn.creditWrap}>
                <div className={cn.creditTop}>
                    {title && (
                        <TextField
                            text={title}
                            name="title"
                            className={cn.creditTitle}
                            customTag="h3"
                            isHTML
                            htmlValue={title}
                        />
                    )}
                    {text && <TextField text={text} name="text" className={cn.creditText} isHTML htmlValue={text} />}
                </div>
                {list?.length > 0 && (
                    <div className={cn.creditGrid}>
                        <div className={cn.creditSlider}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <CustomSwiper {...settings} ref={swiperRef}>
                                {list?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <CreditSliderItem pos={index} {...item} />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                            <PrevArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                            <NextArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(CreditSlider);
