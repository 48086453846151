import React from 'react';

// eslint-disable-next-line import/no-cycle
import { TinaTariffTabs } from '@/components/blocks/tariffTabs/_deprecated/TariffTabs/tina.index';
import { mockTariffTabsTinaData } from '@/data/blocks/tariffTabs';
import { tariffTabsTinaConfig } from '@/tina/config/tariffTabs.tina.config';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';
import { getTemplates } from '@/tina/utils/getTemplates';

export const tariffTabsBlock = {
    Component: props => <TinaTariffTabs {...props} />,
    template: {
        label: 'SEO: Табы',
        defaultItem: () => mockTariffTabsTinaData,
        fields: [
            {
                label: 'Список табов',
                component: 'blocks',
                name: 'blocks',
                templates: getTemplates(tariffTabsTinaConfig),
            },
            {
                label: 'Архив',
                component: 'group',
                name: 'archive',
                fields: [
                    {
                        label: 'Заголовок',
                        component: 'text',
                        name: 'title',
                    },
                    {
                        label: 'Ссылка',
                        component: 'text',
                        name: 'link',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
