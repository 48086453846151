import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import Sidebar from '@/components/blocks/faq/Sidebar';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IFaq } from '@/types/faq';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';

import CollapsibleList from '../CollapsibleList';
import cn from './style.module.sass';

const FAQ: React.FC<IFaq> = observer(({ title, subtitle, list }) => {
    const [activeItem, setActiveItem] = useState<number>(0);
    const tagsEnabled = list[activeItem]?.tabsList?.length > 0;

    const width = useResize();

    const [selectedTag, setSelectedTag] = useState(list[activeItem]?.tabsList?.[0]?.tabTitle);

    const isTablet = width < TABLET_SMALL_WIDTH;

    const setActiveItemHandler = (pos: number) => {
        setActiveItem(pos);

        if (list[pos]?.tabsList?.length > 0) {
            setSelectedTag(list[pos]?.tabsList?.[0]?.tabTitle);
        }
    };

    useEffect(() => {
        setActiveItem(isTablet ? null : 0);
    }, [isTablet]);

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx('section', cn.wrapper)}>
                <div className={cn.fullWrapper}>
                    <TextField text={title} name="title" className={cn.title} customTag="h3" isHTML htmlValue={title} />
                    <TextField text={subtitle} name="subtitle" className={cn.subtitle} isHTML htmlValue={subtitle} />
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <Sidebar list={list} setActiveItemHandler={setActiveItemHandler} activeItem={activeItem} />
                        </div>
                        <div className={cn.right}>
                            {tagsEnabled && (
                                <div className={cn.tags}>
                                    <TagList
                                        tagList={list[activeItem]?.tabsList.map((el, idx) => ({
                                            value: el.tabTitle,
                                            xmlId: el.tabTitle,
                                            id: idx,
                                        }))}
                                        onClick={setSelectedTag}
                                        activeTag={selectedTag}
                                        withoutPaddings
                                    />
                                </div>
                            )}

                            {list?.map((item, index) => (
                                <CollapsibleList
                                    key={index}
                                    activeItem={index}
                                    info={item.info}
                                    isHidden={index !== activeItem}
                                    tagsEnabled={tagsEnabled}
                                    selectedTag={selectedTag}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
});

export default FAQ;
