import clsx from 'clsx';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Feature from '@/domain/feature/Feature';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IHolidayBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'holidayBanner';
    title?: string;
    text?: string;
    desktopImage?: string;
    tabletImage?: string;
    mobileImage?: string;
    typeBanner?: string;
    button: {
        title: string;
        link: string;
        gtag?: GTagEvent;
    };
}

function HolidayBanner({
    title,
    text,
    desktopImage,
    mobileImage,
    tabletImage,
    typeBanner = 'love',
    button,
}: IHolidayBanner) {
    const width = useResize();

    const getCurrentImage = useMemo(() => {
        if (width < MOBILE_WIDTH) return mobileImage ?? desktopImage;
        if (width < TABLET_SMALL_WIDTH) return tabletImage ?? desktopImage;
        return desktopImage;
    }, [width, desktopImage, mobileImage, tabletImage]);

    const classes = clsx(
        cn.wrapper,
        typeBanner === 'may' && cn.may,
        typeBanner === 'woman' && cn.woman,
        typeBanner === 'knowledge' && cn.knowledge,
        typeBanner === 'february' && cn.february,
        typeBanner === 'love' && cn.love
    );

    if (!Feature.isFeatureEnabled('enableHolidayBanner')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={classes} classNameActive={cn.animationInit}>
                    <div className={cn.textWrapper}>
                        {title && <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />}
                        {text && <TextField className={cn.text} text={text} name="text" isHTML htmlValue={text} />}

                        {button?.title && (
                            <div className={cn.buttonWrapper}>
                                <div className={cn.button}>
                                    <Button
                                        variant="btnColor"
                                        type="link"
                                        href={button.link}
                                        label={button.title}
                                        colorBtn="white"
                                        size="long"
                                        onClick={() => {
                                            if (button?.gtag?.action) event(button?.gtag);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {getCurrentImage && (
                        <div className={clsx(cn.imageWrapper)}>
                            <CustomImage src={getCurrentImage} alt="Почта Банк" fill />
                        </div>
                    )}
                </AnimatedComponent>
            </div>
        </div>
    );
}

export default withBlockLinks(HolidayBanner);
