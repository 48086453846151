import { IActionPensions } from '@/types/actionPensions/item';

export const mockActionPensionsBlockData: IActionPensions = {
    _template: 'actionPensions',
    section: null,
    title: '',
    list: [
        {
            name: 'actionPensions1',
            title: 'Получите +2000₽',
            modalTitle: 'Как получить 2 000 ₽',
            subtitle: 'При оформлении перевода пенсии в&nbsp;Социальном фонде или в&nbsp;отделении Почта Банка',
            description:
                'Шаг 1. Обратитесь в отделение Социального фонда (ранее Пенсионный фонд), флагманский клиентский центр банка или отделение банка на Почте России. ' +
                '<p>Шаг 2. После того как пенсия или пособие поступит на Сберегательный счёт — начислим 2 000 ₽ в следующем месяце</p>',
            btn: {
                type: 'button',
                link: '#',
                label: 'Узнать больше',
                gtag: {
                    eventName: 'pbSiteEvent',
                    action: 'click',
                    category: 'Interactions',
                    label: '',
                },
            },
            isGradient: true,
            isArchive: false,
            id: null,
        },
        {
            name: 'actionPensions2',
            title: 'Получите +1 000 ₽',
            modalTitle: 'Как получить 2 000 ₽',
            subtitle: 'Рекомендуйте Почта Банк и получайте денежное вознаграждение',
            description:
                'Шаг 1. Обратитесь в отделение Социального фонда (ранее Пенсионный фонд), флагманский клиентский центр банка или отделение банка на Почте России. ' +
                '<p>Шаг 2. После того как пенсия или пособие поступит на Сберегательный счёт — начислим 2 000 ₽ в следующем месяце</p>',
            btn: {
                type: 'link',
                link: '#',
                label: 'Узнать больше',
                gtag: {
                    eventName: 'pbSiteEvent',
                    action: 'click',
                    category: 'Interactions',
                    label: '',
                },
            },
            isGradient: false,
            isArchive: false,
            id: null,
        },
        {
            name: 'actionPensions3',
            title: 'Получите +1 000 ₽',
            modalTitle: 'Как получить 2 000 ₽',
            subtitle: 'Рекомендуйте Почта Банк и получайте денежное вознаграждение',
            description:
                'Шаг 1. Обратитесь в отделение Социального фонда (ранее Пенсионный фонд), флагманский клиентский центр банка или отделение банка на Почте России. ' +
                '<p>Шаг 2. После того как пенсия или пособие поступит на Сберегательный счёт — начислим 2 000 ₽ в следующем месяце</p>',
            btn: {
                type: 'link',
                link: '#',
                label: 'Узнать больше',
                gtag: {
                    eventName: 'pbSiteEvent',
                    action: 'click',
                    category: 'Interactions',
                    label: '',
                },
            },
            isGradient: false,
            isArchive: true,
            mark: 'Акция завершена 20.01.2024',
            id: null,
        },
    ],
    btn: {
        link: '#',
        label: 'Архив акций',
        gtag: {
            eventName: 'pbSiteEvent',
            action: 'click',
            category: 'Interactions',
            label: '',
        },
    },
};

export const mockActionPensionsBlockDataTina: IActionPensions = {
    _template: 'actionPensions',
    section: null,
    title: 'Заголовок',
    list: [
        {
            name: 'actionPensions1',
            title: 'Заголовок',
            subtitle: 'Описание',
            description: 'Длинное описание',
            modalTitle: 'Заголовок',
            mark: 'Пометка',
            btn: {
                type: 'button',
                link: '#',
                label: 'Название',
                gtag: {
                    eventName: 'pbSiteEvent',
                    action: 'click',
                    category: 'Interactions',
                    label: '',
                },
            },
            isGradient: false,
            isArchive: false,
            id: null,
        },
    ],
    btn: {
        link: '#',
        label: 'Название',
        gtag: {
            eventName: 'pbSiteEvent',
            action: 'click',
            category: 'Interactions',
            label: '',
        },
    },
};
