import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface ICalculatorBanner {
    title: string;
    subtitle: string;
    image?: string;
    min?: number;
    max?: number;
}

interface IProps {
    banner: ICalculatorBanner;
    shouldHide?: boolean;
    isActive?: boolean;
}

const Banner: React.FC<IProps> = ({ banner, shouldHide }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(!shouldHide);
    }, [shouldHide]);

    return (
        <div className={clsx(cn.banner, !isActive && cn.hide)}>
            <div className={cn.content}>
                <div dangerouslySetInnerHTML={{ __html: banner?.title }} className={cn.title} />
                <div dangerouslySetInnerHTML={{ __html: banner?.subtitle }} className={cn.subtitle} />
            </div>
            {banner?.image && (
                <div className={cn.image}>
                    <CustomImage src={banner?.image} fill alt="Баннер калькулятора" />
                </div>
            )}
        </div>
    );
};

export default Banner;
