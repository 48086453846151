const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement('textarea');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    textArea.style.width = '0';
    textArea.style.height = '0';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const range = document.createRange();
    range.selectNodeContents(textArea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textArea.setSelectionRange(0, textArea.value.length);

    try {
        const result = document.execCommand('copy');
        if (!result) throw new Error();
    } catch (err) {
        console.error('Ошибка при копировании через callback', err);
    }

    document.body.removeChild(textArea);
};

export const copyTextToClipboard = text => {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text);
};
