import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IFeedbackUlIpSurvey {
    _template: 'feedbackUlIpSurvey';
    title: string;
}

const FeedbackUlIpSurveyContent = dynamic(async () => import('./FeedbackUlIpSurveyContent'), {
    ssr: false,
});

const FeedbackUlIpSurvey: React.FC<IFeedbackUlIpSurvey & { index: number }> = ({ title }) => (
    <div className="section">
        <FeedbackUlIpSurveyContent title={title} />
    </div>
);

export default withBlockLinks(FeedbackUlIpSurvey);
