import React from 'react';

import { TinaMoneyBoxCalculator } from '@/components/blocks/calculators/layouts/MoneyBoxCalculator/index.tina';
import { moneyBoxCalculatorData } from '@/data/blocks/calculators/moneyBox';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const moneyBoxCalculatorBlock = {
    Component: props => <TinaMoneyBoxCalculator {...props} />,
    template: {
        label: 'Калькулятор "Копилка"',
        defaultItem: moneyBoxCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
