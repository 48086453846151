import clsx from 'clsx';
import React from 'react';

import { IDictionaryItem } from '@/types/dictionary';

import cn from './style.module.sass';

interface IProps {
    item: IDictionaryItem;
    activeItem: IDictionaryItem;
    changeActiveItemHandler: (item: IDictionaryItem) => void;
}

const SidebarItem: React.FC<IProps> = ({ activeItem, item, changeActiveItemHandler }) => {
    const isActive = item?.name === activeItem?.name;

    const onClick = () => {
        changeActiveItemHandler(item);
    };

    return (
        <div
            onClick={onClick}
            role="presentation"
            className={clsx(cn.item, isActive && cn.itemActive)}
            dangerouslySetInnerHTML={{ __html: item.name }}
        />
    );
};

export default SidebarItem;
