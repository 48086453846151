import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import ArticlesTagList from '@/components/blocks/ArticlesDetail/ArticlesTagList';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import SidebarAnchor from '@/components/SidebarAnchor';
import TextField from '@/components/TextField';
import IconCalendar from '@/components/UI/IconCalendar';
import IconTime from '@/components/UI/IconTime';
import LikeButton from '@/components/UI/LikeButton';
import ShareBlock from '@/components/UI/ShareBlock';
import ViewCount from '@/components/UI/ViewCount';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

interface IArticlesDetailElement {
    title: string; // заголовок
    text: string; // описание: основное тело статьи
    previewText: string; // аннотация (200)
    image?: string; // превью изображение для разводящей
    time?: string; // время прочтения статьи
    date?: string;
    linkTo?: string; // ссылка
    tags?: string[];
}

export interface IArticlesDetail {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'articlesDetail';
    /**
     * Карточки.
     */
    section?: number;
    tagList?: ITag[];
    element?: IArticlesDetailElement;
    list?: [
        {
            linkHref: string;
            linkName: string;
        }
    ];
}

const ArticlesDetail: React.FC<IArticlesDetail> = ({ element, list, tagList }) => {
    const actualDate = element?.date?.split(' ')[0];
    const router = useRouter();
    const handleSelectedTag = (tag: string) => {
        const pathName = tag === 'all' ? '/articles/' : `/articles/${tag}`;
        router.push(pathName);
    };
    return (
        <AnimatedComponent className={clsx(cn.detail, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.detailWrap}>
                <div className={cn.detailGrid}>
                    <div className={clsx(cn.detailItem, cn.detailItemList)}>
                        <div className={cn.filter}>
                            <ArticlesTagList
                                onClick={handleSelectedTag}
                                tagList={tagList}
                                elementTags={element?.tags}
                            />
                        </div>
                        {element?.title && (
                            <TextField
                                customTag="h1"
                                text={element?.title}
                                name="element.title"
                                className={cn.itemTitle}
                                isHTML
                                htmlValue={element?.title}
                            />
                        )}
                        <div className={clsx(cn.detailWrapper)}>
                            {element?.date && (
                                <div className={clsx(cn.detailInnerWrapper)}>
                                    <IconCalendar />
                                    <TextField text={actualDate} name="element.date" className={cn.itemMark} />
                                </div>
                            )}

                            {element?.time && (
                                <div className={clsx(cn.detailInnerWrapper)}>
                                    <IconTime />
                                    <span className={cn.itemMark}>Время чтения</span>
                                    <TextField text={element?.time} name="element.time" className={cn.itemMark} />
                                </div>
                            )}
                        </div>
                        {list?.length > 0 && (
                            <SidebarAnchor rollableBlockName="В этой статье:" list={list} checked isRollable />
                        )}
                        {element?.text && (
                            <TextField
                                className={cn.itemContent}
                                text={element?.text}
                                name="element.text"
                                isHTML
                                htmlValue={element?.text}
                            />
                        )}

                        <div className={cn.detailBottom}>
                            <div className={cn.detailBottomLeft}>
                                <div className={cn.detailLike}>
                                    <LikeButton />
                                </div>
                                <div className={cn.detailView}>
                                    <ViewCount />
                                </div>
                            </div>
                            <div className={cn.detailBottomRight}>
                                <ShareBlock link={element?.linkTo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(ArticlesDetail);
