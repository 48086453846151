import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Arrow from '@/components/svg/Arrow';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IBankAdvantages {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'bankAdvantages';
    title?: string;
    list: IBankAdvantageItem[];
    bgImg?: string;
}

interface IBankAdvantageItem {
    name?: string;
    desc: string;
    logo?: ILogoItem[];
}

interface ITriggerProps {
    title?: string;
    logo?: ILogoItem[];
    pos?: number;
}

interface ILogoItem {
    img: string;
    alt: string;
}

const CollapsibleItemTrigger: React.FC<ITriggerProps> = observer(({ title, logo, pos }) => (
    <div className={cn.triggerWrap}>
        {title ? (
            <TextField text={title} name={`list.${pos}.title`} className={cn.trigger} isHTML htmlValue={title} />
        ) : (
            <div className={cn.logo}>
                {logo && (
                    <div className={cn.logoGrid}>
                        {logo?.map(
                            // eslint-disable-next-line no-shadow
                            ({ alt, img }, index) => (
                                <div className={cn.logoItem} key={index}>
                                    <CustomImage width="85" height="58" src={img} alt={alt} />
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        )}
        <div className={cn.arrow}>
            <Arrow />
        </div>
    </div>
));

const BankAdvantages: React.FC<IBankAdvantages> = ({ title, list, bgImg }) => {
    const width = useResize();
    const isDesktop = width > TABLET_SMALL_WIDTH;

    if (list?.length <= 0) return null;

    return (
        <div className={clsx(cn.bank, 'section')}>
            <div className={cn.bankWrap}>
                <div className={cn.bankGrid}>
                    <div className={clsx(cn.bankItem, cn.bankItemLeft)}>
                        {title && (
                            <TextField
                                text={title}
                                name="title"
                                customTag="h3"
                                className={cn.bankTitle}
                                isHTML
                                htmlValue={title}
                            />
                        )}
                        {isDesktop ? (
                            <div className={cn.bankList}>
                                {list.map(({ name, desc, logo }, index) => (
                                    <AnimatedComponent
                                        className={cn.rows}
                                        key={index}
                                        classNameActive={cn.animationInit}
                                    >
                                        <div className={cn.grid}>
                                            <div className={clsx(cn.item, cn.itemLeft)}>
                                                {name ? (
                                                    <TextField
                                                        text={name}
                                                        name={`list.${index}.name`}
                                                        className={cn.name}
                                                        isHTML
                                                        htmlValue={name}
                                                    />
                                                ) : (
                                                    <div className={cn.logo}>
                                                        {logo && (
                                                            <div className={cn.logoGrid}>
                                                                {logo?.map(
                                                                    // eslint-disable-next-line no-shadow
                                                                    ({ alt, img }, index) => (
                                                                        <div className={cn.logoItem} key={index}>
                                                                            <CustomImage
                                                                                width="85"
                                                                                height="58"
                                                                                src={img}
                                                                                alt={alt}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className={clsx(cn.item, cn.itemRight)}>
                                                <TextField
                                                    text={desc}
                                                    name={`list.${index}.desc`}
                                                    className={cn.desc}
                                                    isHTML
                                                    htmlValue={desc}
                                                />
                                            </div>
                                        </div>
                                    </AnimatedComponent>
                                ))}
                            </div>
                        ) : (
                            <div className={cn.bankList}>
                                {list.map(({ name, desc, logo }, index) => (
                                    <div className={cn.rows} key={index}>
                                        <Collapsible
                                            className={cn.descCollapsible}
                                            openedClassName={clsx(cn.descCollapsible, cn.open)}
                                            trigger={
                                                // eslint-disable-next-line
                                                <CollapsibleItemTrigger title={name} logo={logo} pos={index} />
                                            }
                                            triggerOpenedClassName={cn.open}
                                            triggerTagName="div"
                                            transitionTime={300}
                                            easing="ease-in-out"
                                        >
                                            <TextField
                                                text={desc}
                                                name={`list.${index}.desc`}
                                                className={cn.desc}
                                                isHTML
                                                htmlValue={desc}
                                            />
                                        </Collapsible>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {isDesktop && (
                        <AnimatedComponent
                            className={clsx(cn.bankItem, cn.bankItemRight)}
                            classNameActive={cn.animationInit}
                        >
                            <div className={cn.bg}>
                                <CustomImage width="260" height="660" src={bgImg} alt={title} />
                            </div>
                        </AnimatedComponent>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(BankAdvantages);
