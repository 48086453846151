export const menu = {
    list: [
        { title: 'Работа у нас', id: 'conditions' },
        { title: 'Наши достижения', id: 'our-achievements' },
        { title: 'Вакансии', id: 'vacancy' },
    ],
};

export const hrGtag = {
    gtagHeader: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_push_lan',
    },
    gtagFooter: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_push_lan',
    },
    gtagForm: {
        eventName: 'universalEvent',
        action: 'form_send',
        category: 'conversions',
        label: 'hr_push_lan',
    },
    gtagSlider: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_push_lan',
    },
};
