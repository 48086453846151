import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import SwiperCore, { EffectFade, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';
import TextField from '@/components/TextField';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IQuotesCarouselItem {
    photo: string;
    name: string;
    position: string;
    quote: string;
}

export interface IQuotesCarousel {
    _template: 'quotesCarousel';
    items: IQuotesCarouselItem[];
    disableSlider?: boolean;
    bottomText?: string;
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

SwiperCore.use([EffectFade, Pagination]);

const QuotesCarousel: FC<IQuotesCarousel> = ({ items, disableSlider, gtag, bottomText }) => {
    const swiperRef = useRef(null);
    const settings = {
        className: clsx(cn.carousel, 'carousel', 'quotesCarousel'),
        fadeEffect: { crossFade: true },
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: { clickable: true },
        watchOverflow: true,
    };

    if (!items || items?.length <= 0) return null;

    if (disableSlider) {
        return (
            <div className="section">
                <div className={cn.wrapper}>
                    <div className={cn.grid}>
                        {items?.map(({ photo, name, position, quote }: IQuotesCarouselItem) => (
                            <div className={cn.item} key={quote}>
                                <div className={cn.info}>
                                    <div className={cn.img}>
                                        <CustomImage src={photo} alt={name} width="96" height="96" />
                                    </div>
                                    <div className={cn.leader}>
                                        <div className={cn.name}>{name}</div>
                                        {position && <div className={cn.position}>{position}</div>}
                                    </div>
                                </div>
                                <div className={cn.quote} dangerouslySetInnerHTML={{ __html: quote }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="section">
            <div className={cn.wrapper}>
                <div className={cn.grid}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <CustomSwiper {...settings} ref={swiperRef}>
                        {items?.map(({ photo, name, position, quote }: IQuotesCarouselItem, index) => (
                            <SwiperSlide key={`${name}-${index}`}>
                                <div className={cn.item}>
                                    <div className={cn.info}>
                                        <div className={cn.img}>
                                            <CustomImage src={photo} alt={name} width="96" height="96" />
                                        </div>
                                        <div className={cn.leader}>
                                            <div className={cn.name}>{name}</div>
                                            {position && <div className={cn.position}>{position}</div>}
                                        </div>
                                    </div>
                                    <div className={cn.quote} dangerouslySetInnerHTML={{ __html: quote }} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    <PrevArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                    <NextArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                </div>
                <TextField
                    text={bottomText}
                    name="bottomText"
                    className={cn.bottomText}
                    isHTML
                    htmlValue={bottomText}
                />
            </div>
        </div>
    );
};

export default QuotesCarousel;
