import React from 'react';

import PboFaqDetailSidebar, { IPromotion } from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebar';
import { TPboFaqItem } from '@/components/blocks/PboFaqDetail/PboFaqDetailSidebarLink';

import PboFaqDetailContent from './PboFaqDetailContent';
import cn from './style.module.sass';

export interface IPboFaqDetail {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'pboFaqDetail';
    list: TPboFaqItem[];
    title: string;
    content: string;
    button?: {
        href: string;
        label: string;
    };
    promotion: IPromotion;
}

const PboFaqDetail = ({ title, content, list = [], promotion, button }: IPboFaqDetail) => (
    <div className={cn.wrapper}>
        <div className={cn.sidebar}>
            <PboFaqDetailSidebar promotion={{ ...promotion, isMobileInitial: true }} list={list} />
        </div>
        <div className={cn.main}>
            <div className={cn.content}>
                <PboFaqDetailContent title={title} content={content} button={button} />
            </div>
        </div>
    </div>
);

export default PboFaqDetail;
