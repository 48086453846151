export const mockErrorsBlockData = {
    title: 'Страница не найдена',
    desc: 'Возможно, неправильно набран адрес страницы или такой страницы на сайте не существует',
    list: [
        {
            link: '/',
            name: 'Главная страница',
        },
        {
            link: '#',
            name: 'Продукты и услуги',
        },
        {
            link: '#',
            name: 'Банкоматы и отделения',
        },
        {
            link: '#',
            name: '«Помощь»',
        },
    ],
    img: '/img-next/png/404.png',
};

export const mockErrorsBlockTinaData = {
    section: null,
    _template: 'errors',
    title: 'Заголовок',
    desc: 'Описание',
    list: [
        {
            link: '#',
            name: 'Название',
        },
        {
            link: '#',
            name: 'Название',
        },
        {
            link: '#',
            name: 'Название',
        },
        {
            link: '#',
            name: 'Название',
        },
    ],
    img: '',
};
