import Image from 'next/image';
import React from 'react';

import type { MainPrizesProps } from '@/components/blocks/draw/main-prizes';

import cn from './style.module.sass';

type PrizeItemProps = MainPrizesProps['items'][0];

export function PrizeItem({ title, label, backgroundSrc, partnerLogoSrc }: PrizeItemProps) {
    return (
        <div className={cn.item}>
            <div className={cn.label} dangerouslySetInnerHTML={{ __html: label }} />

            <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />

            <div className={cn.background}>
                <Image src={backgroundSrc} unoptimized width={165} height={165} alt="" />
            </div>

            <div className={cn.partnerLogo}>
                <Image src={partnerLogoSrc} width={64} height={64} alt="" />
            </div>
        </div>
    );
}
