import 'react-datepicker/dist/react-datepicker.css';

import clsx from 'clsx';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { useTheme } from '@/context/ThemeProvider';

import cn from './style.module.sass';

registerLocale('ru', ru);

export interface IDatePicker {
    onChangeHandler?: (date: Date) => unknown;
    isLoading?: boolean;
    [name: string]: unknown;
}

const CustomDatePicker: React.FC<IDatePicker> = observer(({ onChangeHandler, isLoading, ...rest }) => {
    const { theme } = useTheme();

    const [startDate, setStartDate] = useState(new Date());

    const onChange = date => {
        setStartDate(date);

        onChangeHandler(date);
    };

    const handleDateChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
    };

    return (
        <div className={clsx(cn.wrapper, theme === 'dark' && cn.dark, isLoading && cn.loading)}>
            <div className={cn.label}>Дата</div>
            <DatePicker
                selected={startDate}
                onChange={(date: Date) => onChange(date)}
                locale="ru"
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy"
                disabled={isLoading}
                onChangeRaw={handleDateChangeRaw}
                {...rest}
            />
        </div>
    );
});

export default CustomDatePicker;
