import clsx from 'clsx';
import React from 'react';

import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import Down from './down';
import Right from './right';
import cn from './style.module.sass';

export interface LinkIconProps {
    /**
     * Ссылка
     */
    href?: string;
    /**
     * Target атрибут
     */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Стрелка низ/верх?
     */
    typeIcon?: 'down' | 'right';
    /**
     * Есть ли Padding?
     */
    noPadding?: boolean;
    /**
     * Есть ли рамка на мобайле?
     */
    border?: boolean;
    /**
     * Размеры кнопки
     */
    size?: 'large' | 'small';
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    /**
     * Цвет (Предполагается, что используется только в купе с Тиной)
     */
    color?: string;
    gtag?: GTagEvent;
}

export const LinkIcon: React.FC<LinkIconProps> = ({
    label,
    disable = false,
    border = false,
    customClass,
    target,
    href,
    size = 'large',
    noPadding,
    typeIcon,
    color,
    gtag,
    onClick,
}) => (
    <a
        href={href}
        style={{ color }}
        target={target}
        className={clsx(
            cn.linkIcon,
            disable === true && cn.disable,
            border === true && cn.border,
            typeIcon,
            size === 'small' && cn.small,
            noPadding === true && cn.noPadding,
            customClass
        )}
        onClick={() => {
            if (gtag?.action) event(gtag);
        }}
    >
        <span>{label}</span>
        {typeIcon === 'down' && (
            <i className={cn.linkIconDown}>
                <Down />
            </i>
        )}
        {typeIcon === 'right' && (
            <i className={cn.linkIconRight}>
                <Right />
            </i>
        )}
    </a>
);
