import React, { FC } from 'react';

import FullScreenTooltip from '@/components/blocks/FullScreenTolltip';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useTooltip } from '@/hooks/useTooltip';

import cn from './style.module.sass';

export interface ICalculatorDisclaimer {
    text: string;
    tooltipText?: string;
}

const CalculatorInfo: FC<ICalculatorDisclaimer> = ({ text, tooltipText }) => {
    const { show, hide, isVisible } = useTooltip();

    if (tooltipText?.length > 0) {
        return (
            <>
                <div
                    className={cn.infoTitle}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />

                <div role="presentation" className={cn.tooltip} onClick={show}>
                    <TooltipIcon />
                </div>

                <FullScreenTooltip
                    tooltipText={tooltipText}
                    handleClose={hide}
                    isOpen={isVisible}
                    customClass={cn.tooltipText}
                />
            </>
        );
    }

    return (
        <div
            className={cn.infoTitle}
            dangerouslySetInnerHTML={{
                __html: text,
            }}
        />
    );
};

export default CalculatorInfo;
