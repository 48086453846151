export const mockSafelyBlockData = {
    image: '/img-next/png/conditions.png',
    imgMinHigh: '/img-next/png/vtb.png',
    imgMinLow: '/img-next/png/pochta.png',
    imageAlt: 'Вклады застрахованы',
    subhead: 'Надёжно:',
    text: '<ul><li>Почта Банк — банк с государственным подходом</li><li>Более 19 000 точек обслуживания в 83 регионах страны</li><li>Банк является участником государственной системы страхования вкладов</li></ul>',
};

export const mockSafelyBlockDataDeposits = {
    image: '/img-next/png/conditions.png',
    imgMinHigh: '/img-next/png/vtb.png',
    imgMinLow: '/img-next/png/pochta.png',
    imageAlt: 'Вклады застрахованы',
    subhead: 'Все вклады застрахованы:',
    text: 'Порядок, размер и условия страхования установлены Федеральным законом «О страховании вкладов физических лиц в банках Российской Федерации» № 177-ФЗ от 23 декабря 2003 года.',
};

export const mockSafelyBlockTinaData = {
    section: null,
    image: '',
    imgMinHigh: '',
    imgMinLow: '',
    imageAlt: 'Описание картинок',
    subhead: 'Заголовок',
    text: 'Описание',
};
