import React from 'react';

import { TinaATMList } from '@/components/blocks/ATMList/index.tina';

export const atmListBlock = {
    Component: props => <TinaATMList {...props} />,
    template: {
        label: 'Список банкоматов',
        fields: [],
    },
};
