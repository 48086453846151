import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import SwiperCore, { EffectFade, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';
import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IInstructionSliderItem {
    image: string;
    text: string;
}

export interface IInstructionSlider {
    _template: 'instructionSlider';
    items: IInstructionSliderItem[];
    button?: {
        label: string;
        link: string;
        gtag?: GTagEvent;
    };
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength <= 1) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength <= 1) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

SwiperCore.use([EffectFade, Pagination]);

const InstructionSlider: FC<IInstructionSlider> = ({ gtag, items, button }) => {
    const swiperRef = useRef(null);
    const settings = {
        className: clsx(cn.carousel, 'carousel', 'instructionSlider'),
        slidesPerView: 1,
        loop: true,
        watchOverflow: true,
        pagination: { clickable: true },
    };

    if (!items || items?.length <= 0) return null;

    return (
        <div className="section">
            <div className={cn.wrapper}>
                <div className={cn.grid}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <CustomSwiper {...settings} ref={swiperRef}>
                        {items?.map(({ image, text }: IInstructionSliderItem, index) => (
                            <SwiperSlide key={index}>
                                <div className={cn.item}>
                                    <div className={cn.img}>
                                        <CustomImage src={image} alt={text} width="650" height="340" />
                                    </div>
                                    <div className={cn.text} dangerouslySetInnerHTML={{ __html: text }} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    <PrevArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                    <NextArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                </div>
                {button?.label && (
                    <div className={cn.btn}>
                        <Button
                            variant="btnBlue"
                            type="link"
                            href={button.link}
                            label={button.label}
                            size="small"
                            customClass={cn.button}
                            onClick={() => {
                                if (button?.gtag?.action) event(button?.gtag);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstructionSlider;
