import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useMemo } from 'react';

import CalculatorConclusions from '@/components/blocks/calculators/CalculatorConclusions';
import Conditions from '@/components/blocks/calculators/Conditions';
import InfoTitle from '@/components/blocks/calculators/InfoTitle';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import {
    AdditionSumTakenStatus,
    ISecondaryPreferentialStore,
} from '@/domain/calculator/SecondaryPreferentialCalculatorStore';
import { SecondaryPreferentialContentProps } from '@/types/calculator/secondaryPreferentialCalculator';
import { event } from '@/components/shared/utilities/analytics/metrics';

import FormedRangeNew from '../../../range/FormedRange';
import cn from './style.module.sass';

const SecondaryPreferentialCalculatorContent: FC<SecondaryPreferentialContentProps> = observer(
    ({ title, index, gtag, button, infoTitle }) => {
        const {
            range,
            termField,
            paymentValue,
            creditValue,
            rate,
            additionSumTakenStatus,
            setAdditionSumTakenStatus,
            subRange,
        } = useContext<ISecondaryPreferentialStore>(CalculatorStoreContext);
        const currentInfoTitle = useMemo(
            () => infoTitle?.ranges?.find(item => item.min <= +creditValue && item.max >= +creditValue),
            [infoTitle, creditValue]
        );

        const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        const formattedRate = +range.value > 99999 ? `${rate}%` : `от ${rate}%`;
        const isNotMaximumValueOfRange = range.value < range.elements.at(-1).value;

        return (
            <div className="section">
                <div className={cn.fullWrapper}>
                    <div className={cn.wrapper}>
                        <TextField
                            text={title}
                            customTag="h2"
                            name="title"
                            className={cn.title}
                            isHTML
                            htmlValue={title}
                        />
                        <div className={cn.content}>
                            <div className={cn.left}>
                                <div className={cn.leftInner}>
                                    <RangeInput config={range} noMaxWidth />
                                    <Terms title="Срок рефинансирования" field={termField} gtag={gtag?.gtagTerm} />
                                    <div className={clsx(cn.info, cn.hideOnMobile)}>
                                        {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                    </div>
                                    <div className={cn.additionallyCreditSum}>
                                        {additionSumTakenStatus !== AdditionSumTakenStatus.REJECTED && (
                                            <div className={cn.additionallyCreditSum}>
                                                <div className={cn.additionallyCreditSumText}>
                                                    Вы можете взять дополнительную сумму наличных
                                                </div>
                                                {isNotMaximumValueOfRange &&
                                                    additionSumTakenStatus === AdditionSumTakenStatus.ACCEPTED && (
                                                        <div className={cn.additionallyCreditSumRangeContainer}>
                                                            <div className={cn.additionallyCreditSumRangeText}>
                                                                {subRange.formatValue}
                                                            </div>
                                                            <div className={cn.additionallyCreditSumRange}>
                                                                <FormedRangeNew config={subRange} withPips={false} />
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className={cn.additionallyCreditSumButtonsToAccept}>
                                                    <div className={cn.additionallyCreditSumAddToBalance}>
                                                        Добавить к расчёту?
                                                    </div>
                                                    <Button
                                                        variant="btnWhite"
                                                        type="button"
                                                        label="Нет"
                                                        size="small"
                                                        customClass={clsx(cn.button, cn.additionallyCreditButton)}
                                                        onClick={() => {
                                                            setAdditionSumTakenStatus(AdditionSumTakenStatus.REJECTED);
                                                        }}
                                                        animation={false}
                                                    />
                                                    <Button
                                                        variant="btnBlue"
                                                        type="button"
                                                        label="Да"
                                                        size="small"
                                                        customClass={clsx(
                                                            cn.button,
                                                            cn.additionallyCreditButton,
                                                            cn.additionallyCreditButtonBlue
                                                        )}
                                                        onClick={() => {
                                                            setAdditionSumTakenStatus(AdditionSumTakenStatus.ACCEPTED);
                                                        }}
                                                        animation={false}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={cn.right}>
                                <div className={cn.rightWrapper}>
                                    <CalculatorConclusions
                                        title="Наше предложение"
                                        sum={`${formatValue(paymentValue)} ₽`}
                                        percent={formattedRate}
                                    />
                                    <Conditions isEarningShow={creditValue > 300000} />
                                    <div className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={button?.link || '#'}
                                            label={button?.label || 'Заполнить анкету'}
                                            size="small"
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                </div>
                                <div className={clsx(cn.info, cn.hideOnDesktop)}>
                                    {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default SecondaryPreferentialCalculatorContent;
