'use client';

import Image from 'next/image';
import React from 'react';

import type { DrawParticipationListProps } from '@/components/blocks/draw/participation-list';
import { useMobileWidth } from '@/hooks/useMedia';

import cn from './style.module.sass';

type ParticipationItemProps = DrawParticipationListProps['items'][0];

function Chances({ text }: { text: string }) {
    return (
        <div className={cn.chances}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
            <Image src="/img-next/svg/draw/paw.svg" width={18} height={18} alt="" />
        </div>
    );
}

function ParticipationItem({ title, desc, chances }: ParticipationItemProps) {
    const isMobile = useMobileWidth(true);

    return (
        <div className={cn.card}>
            {isMobile ? (
                <>
                    <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />

                    <div className={cn.mobileWrapper}>
                        <div className={cn.desc} dangerouslySetInnerHTML={{ __html: desc }} />
                        <Chances text={chances} />
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={cn.desc} dangerouslySetInnerHTML={{ __html: desc }} />
                    </div>
                    <Chances text={chances} />
                </>
            )}
        </div>
    );
}

export default ParticipationItem;
