export const mockPeriodInfoBlockData = {
    name: 'Беспроцентный период до 120 дней',
    descTop:
        'Например, Вы оформили карту 10 марта и совершили покупку на 10 000 рублей.<br>Чтобы не платить проценты, необходимо вносить ежемесячные платежи:',
    image: '/img-next/png/periodInfo/periodInfo.png',
    list: [
        { data: 'До 10 мая', sum: '300 ₽', desc: 'Минимальный платеж' },
        { data: 'До 10 июня', sum: '300 ₽', desc: 'Минимальный платеж' },
        { data: 'До 10 июля', sum: '9400 ₽', desc: 'Ваш платеж' },
    ],
    descBottom:
        '<ul><li>Для того, чтобы начался новый беспроцентный период, погасите полностью сумму задолженности.</li><li>Уже на следующий день вы можете совершать новые покупки и не платить за них проценты до 120 дней.</li></ul>',
};

export const mockPeriodInfoBlockTinaData = {
    section: null,
    name: 'Заголовок',
    descTop: 'Описание',
    image: '',
    list: [{ data: 'Описание', sum: 'Описание', desc: 'Описание' }],
    descBottom: 'Описание',
};
