import clsx from 'clsx';
import React, { useState } from 'react';

import { BurgerIcon } from '@/components/blocks/HRLanding/BurgerIcon';
import HRMobileMenu from '@/components/blocks/HRLanding/HRMobileMenu';
import HRMenu, { IHRMenu } from '@/components/blocks/HRLanding/Menu';
import CustomImage from '@/components/CustomImage';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    handler?: () => void;
    menu: IHRMenu;
    hrGtag?: {
        [key: string]: GTagEvent;
    };
}

function HRHeader({ handler, menu, hrGtag }: IProps) {
    const [isMenuShown, showMenu] = useState(false);

    const show = () => showMenu(true);
    const hide = () => showMenu(false);

    return (
        <div className={cn.header}>
            <div className={cn.wrap}>
                <div className={cn.grid}>
                    <div className={clsx(cn.item, cn.itemLogo)}>
                        <div className={cn.logo}>
                            <a href="/">
                                <CustomImage
                                    src="/img-next/svg/hr-landing/logo-white.svg"
                                    alt="HR Landing"
                                    width="111"
                                    height="52"
                                />
                            </a>
                        </div>
                    </div>

                    <div className={clsx(cn.item, cn.itemMenu)}>
                        <HRMenu list={menu.list} />
                    </div>

                    <div className={clsx(cn.item, cn.itemButton)}>
                        <div className={cn.burger}>
                            <BurgerIcon handleClick={show} />
                        </div>
                    </div>
                </div>
            </div>
            <HRMobileMenu
                btnLabel="Хочу в команду"
                active={isMenuShown}
                closeMenu={hide}
                handler={handler}
                menu={menu}
            />
        </div>
    );
}

export default HRHeader;
