import React from 'react';

import { TinaDepositApplication } from '@/components/blocks/frames/DepositApplication/index.tina';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

export const depositApplicationBlock = {
    Component: (props: IBlockProps) => <TinaDepositApplication {...props} />,
    template: {
        label: 'Онлайн заявка на оформление вклада',
        defaultItem: () => ({
            title: 'Заголовок',
            withoutRedirect: false,
            productType: 'Deposit',
            productKind: 'Nakopitelny',
            gtagClick: {
                action: 'click',
                category: 'conversions',
                label: 'vklad_hot',
                event: 'universalEvent',
                orderid2: 'weerrgt45437',
            },
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'leadDescription',
                component: 'text',
                label: 'Параметр leadDescription',
            },
            {
                name: 'withoutRedirect',
                component: 'select',
                label: 'Редирект',
                options: [
                    {
                        value: 'false',
                        label: 'С редиректом',
                    },
                    {
                        value: 'true',
                        label: 'Без редиректа',
                    },
                ],
            },
            {
                name: 'typeProduct',
                component: 'select',
                label: 'Тип продукта ( productType )',
                description: "По умолчанию вклад будет типа 'Deposit'",
                options: [
                    {
                        value: 'Deposit',
                        label: 'Deposit',
                    },
                    {
                        value: 'Card',
                        label: 'Card',
                    },
                ],
            },
            {
                name: 'type',
                component: 'select',
                label: 'Тип ( productKind )',
                description: "По умолчанию вклад будет типа 'Накопитальный'",
                options: [
                    {
                        value: 'Nakopitelny',
                        label: 'Накопительный',
                    },
                    {
                        value: 'Hot_season',
                        label: 'Горячий сезон',
                    },
                    {
                        value: 'Pension',
                        label: 'Пенсионный +',
                    },
                    {
                        value: 'Capital',
                        label: 'Капитальный',
                    },
                    {
                        value: 'Dohodny',
                        label: 'Доходный',
                    },
                    {
                        value: 'Perspective',
                        label: 'Перспективный',
                    },
                    {
                        value: '',
                        label: 'Тип не определен',
                    },
                ],
            },
            { ...gtagSingleItemCustom('gtagClick', 'Цель на успешную отправку') },
        ],
    },
};
