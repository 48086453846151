import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import CustomSelect from '@/components/UI/CustomSelect';
import Input from '@/components/UI/Input';
import { useCity } from '@/context/CityProvider';
import { ISelectElement } from '@/types/select';
import { IVacancyFilterElement } from '@/types/vacancy/item';
import { getCorrectEndingAfterNumber } from '@/utils/getCorrectEndingAfterNumber';

import cn from './style.module.sass';

interface IVacancyFilter {
    onVacancySelect: (item: { label: string; value: string }) => void;
    onCitySelect: (item: { label: string; value: string }) => void;
    onSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
    citiesList: IVacancyFilterElement[];
    vacanciesList: IVacancyFilterElement[];
    vacanciesLength: number;
    citiesListLength: number;
}

const VacancyFilter: React.FC<IVacancyFilter> = observer(
    ({
        onVacancySelect,
        onCitySelect,
        searchValue,
        onSearchValueChange,
        citiesList,
        citiesListLength,
        vacanciesList,
        vacanciesLength,
    }) => {
        const city = useCity();
        const [currentCity, setCurrentCity] = useState<{ label: string; value: string }>(null);

        const getOptionsFromFilterElements = (
            filterElements: IVacancyFilterElement[]
        ): ISelectElement<string | number>[] => filterElements.map(item => ({ value: item.xmlId, label: item.value }));

        const correctVacancyEnding = getCorrectEndingAfterNumber(vacanciesLength, ['вакансия', 'вакансии', 'вакансий']);

        const correctCityEnding = getCorrectEndingAfterNumber(citiesListLength, ['городе', 'городах', 'городах']);

        const finalText = `Всего ${vacanciesLength} ${correctVacancyEnding} в ${citiesList?.length} ${correctCityEnding}`;

        const selectOptions = getOptionsFromFilterElements([{ value: 'Все города', xmlId: 'all' }, ...citiesList]);

        useEffect(() => {
            const currentItem = selectOptions.find(item => item.label === city?.currentCity?.city);

            onCitySelect({ label: currentItem?.label, value: currentItem?.value.toString() });

            setCurrentCity({ label: currentItem?.label, value: currentItem?.value.toString() });
        }, [city]);

        return (
            <div className={cn.filter}>
                <div className={cn.grid}>
                    <div className={clsx(cn.item, cn.itemBig)}>
                        <Input
                            type="text"
                            value={searchValue}
                            onChangeHandler={onSearchValueChange}
                            label="Найти вакансию"
                            shouldColored
                            customClass={cn.isFull}
                        />
                    </div>
                    <div className={clsx(cn.item, cn.itemMin)}>
                        <CustomSelect
                            options={getOptionsFromFilterElements([
                                { value: 'Все вакансии', xmlId: 'all' },
                                ...vacanciesList,
                            ])}
                            onChangeHandler={onVacancySelect}
                            placeholder="Все вакансии"
                        />
                    </div>
                    <div className={clsx(cn.item, cn.itemMin)}>
                        <CustomSelect
                            options={selectOptions}
                            onChangeHandler={onCitySelect}
                            placeholder="Выбрать город"
                            defaultValue={currentCity}
                        />
                    </div>
                    <div className={clsx(cn.item, cn.itemMin, cn.itemCount)}>
                        <div className={cn.text}>{finalText}</div>
                    </div>
                </div>
            </div>
        );
    }
);

export default VacancyFilter;
