import React from 'react';

import { TinaSingleDocuments } from '@/components/blocks/singleDocuments/SingleDocuments/index.tina';
import { mockSingleDocumentsBlockData } from '@/data/blocks/singleDocuments';
import { IBlockProps } from '@/types/tina/block';

export const singleDocumentsBlock = {
    Component: (props: IBlockProps) => <TinaSingleDocuments {...props} />,

    template: {
        label: 'Единичный документ',
        defaultItem: () => mockSingleDocumentsBlockData,
        fields: [
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                label: 'Список документов',
                name: 'documents',
                component: 'group-list',
                itemProps: (item: { documentName: string }, index: number) => ({
                    key: index,
                    label: item.documentName,
                    title: item.documentName,
                }),
                defaultItem: () => mockSingleDocumentsBlockData.documents[0],
                fields: [
                    {
                        component: 'text',
                        name: 'documentName',
                        label: 'Заголовок',
                    },
                    {
                        component: 'text',
                        name: 'document',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
