import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import { DecSvg } from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/svg/DecSvg';
import { IncSvg } from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/svg/IncSvg';
import { ITermField } from '@/domain/calculator/blocksStore/Term';

import cn from './style.module.sass';

const Counter: FC<{ paymentValue: string; field: ITermField }> = ({ paymentValue, field }) => {
    const { allTerms, setActiveTerm, value } = field;

    const initialState = () => value / 12 - 1;
    const [count, setCount] = useState(() => initialState());
    const countBtn = allTerms.length;

    useEffect(() => {
        setCount(initialState());
    }, [value]);

    const inc = () => {
        setActiveTerm(allTerms[count < countBtn - 2 ? count + 1 : countBtn - 1]);
    };

    const dec = () => {
        setActiveTerm(allTerms[count <= 0 ? 0 : count - 1]);
    };

    return (
        <div className={cn.counterWrap}>
            <button type="button" className={cn.counterBtn} disabled={count === countBtn - 1} onClick={inc}>
                <DecSvg color="#001E45" customClass={cn.counterBtnImg} />
            </button>
            <div className={clsx(cn.resultNumber)}>{`${paymentValue} ₽`}</div>
            <button type="button" className={cn.counterBtn} disabled={count === 0} onClick={dec}>
                <IncSvg color="#001E45" customClass={cn.counterBtnImg} />
            </button>
        </div>
    );
};

export default Counter;
