import React from 'react';

import { TinaTariffInfo } from '@/components/blocks/TariffInfo/index.tina';
import { mockTariffInfoBlockDataTina } from '@/data/blocks/tariffInfo';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const tariffInfoBlock = {
    Component: props => <TinaTariffInfo {...props} />,
    template: {
        label: 'Тарифы и информация',
        defaultItem: () => mockTariffInfoBlockDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item?.name || 'Элемент списка',
                }),
                defaultItem: () => mockTariffInfoBlockDataTina.list[0],
                fields: [
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: 'text',
                        label: 'Подзаголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
