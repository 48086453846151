import React from 'react';

import { TinaCorgis } from '@/components/blocks/Corgis/index.tina';
import { mockCorgisBlockData } from '@/data/blocks/corgis';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const corgisBlock = {
    Component: props => <TinaCorgis {...props} />,
    template: {
        label: 'Корги',
        defaultItem: () => mockCorgisBlockData,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imageMobile',
                component: 'image',
                label: 'Картинка для моб. версии',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
