export const mockManagementDefaultData = {
    heading: 'Руководство',
    managers: [
        {
            name: 'Руденко Дмитрий Васильевич',
            position: 'Президент-Председатель Правления АО «Почта Банк»',
            photo: '/img-next/jpg/management/item1.jpg',
        },
        {
            name: 'Бородай Юлия Анатольевна',
            position: 'Вице-президент, директор по персоналу',
            photo: '/img-next/jpg/management/item2.jpg',
        },
    ],
};

export const mockManagementMockData = {
    heading: 'Заголовок',
    managers: [
        {
            name: 'Имя руководителя',
            position: 'Должность руководителя',
            photo: '',
        },
    ],
};
