import React from 'react';

import { TinaInfoBlockPensiyaBlock } from '@/components/blocks/InfoBlockPensiya/index.tina';
import { mockInfoBlockPensiyaBlockDataTina } from '@/data/blocks/infoBlockPensiya';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const infoBlockPensiyaBlock = {
    Component: props => <TinaInfoBlockPensiyaBlock {...props} />,
    template: {
        label: 'Информационный блок пенсия',
        defaultItem: () => mockInfoBlockPensiyaBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'alignment',
                component: 'select',
                label: 'Выравнивание',
                options: [
                    {
                        value: 'top',
                        label: 'По верхней линии (по умолчанию)',
                    },
                    {
                        value: 'middle',
                        label: 'По центру',
                    },
                    {
                        value: 'bottom',
                        label: 'По нижней линии',
                    },
                ],
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'linkName',
                        component: 'text',
                        label: 'Текст на кнопке',
                    },
                    {
                        name: 'type',
                        component: 'select',
                        label: 'Тип элемента',
                        options: [
                            { value: 'anchor', label: 'Кнопка-якорь' },
                            { value: 'link', label: 'Кнопка-ссылка' },
                        ],
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
