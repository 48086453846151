import React from 'react';

import cn from '@/components/UI/LikeButton/style.module.sass';

function LikeIcon() {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.11604 8.8157H5.86697L8.38708 4.66395L8.57032 0.570804C8.58559 0.239832 8.8656 -0.01462 9.19656 0.00065308C13.7658 0.204317 12.6915 4.61304 11.9815 7.78494L18.6812 8.03957C20.0507 8.01418 21.2597 10.7659 19.1801 11.7052C19.4168 11.9342 19.6357 12.2194 19.7911 12.5706C20.2061 13.4998 19.4552 14.0216 18.6405 14.3091C18.923 14.5916 19.1624 14.9607 19.2641 15.4316C19.4779 16.4396 18.4928 16.9233 17.5459 17.1523C17.8412 17.4451 18.1135 17.8015 18.2714 18.219C18.9866 20.123 16.2147 19.9983 16.2071 19.9983C13.2008 19.8634 10.1896 19.759 7.18092 19.6395C6.94157 19.6294 6.74305 19.4816 6.65659 19.2755L6.2901 18.4711L5.14962 18.5118C4.8213 18.5219 4.54378 18.2623 4.53365 17.934C4.43954 15.1543 4.52601 12.2089 4.52086 9.41152C4.52086 9.08055 4.78809 8.81583 5.11656 8.81583L5.11604 8.8157Z"
                fill="#635BF8"
                className={cn.svgFill}
            />
            <path
                d="M0.598197 8.90759H3.33972C3.67069 8.90759 3.93792 9.17749 3.93792 9.50579V17.7992C3.93792 18.1302 3.67069 18.3974 3.33972 18.3974H0.598197C0.267225 18.3974 0 18.1302 0 17.7992V9.50579C0 9.17748 0.267229 8.90759 0.598197 8.90759Z"
                fill="#635BF8"
                className={cn.svgFill}
            />
        </svg>
    );
}

export default LikeIcon;
