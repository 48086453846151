import React from 'react';

import { TinaCashCalculator } from '@/components/blocks/calculators/layouts/CashCalculatorV2/index.tina';
import { mockCashCreditCalculatorV2Data } from '@/data/blocks/calculators/cashcredit_v2';
import { mockSecondaryCashCreditCalculatorData } from '@/data/blocks/calculators/secondaryCashcreditV2';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const cashCalculatorBlockV2 = {
    Component: (props: IBlockProps) => <TinaCashCalculator {...props} />,
    template: {
        label: 'Калькулятор наличными КН 1',
        defaultItem: mockCashCreditCalculatorV2Data,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tabTitle',
                component: 'text',
                label: 'Заголовок вкладки',
            },
            {
                name: 'percentTooltip',
                component: HtmlEditorFeatured,
                label: 'Текст тултипа возле процентов',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'infoTitle',
                component: 'group',
                label: 'Дисклеймер',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Выключить/включить дисклеймер',
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        defaultItem: () => mockSecondaryCashCreditCalculatorData.infoTitle.text,
                        label: 'Текст',
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        defaultItem: () => mockSecondaryCashCreditCalculatorData.infoTitle.tooltipText,
                        label: 'Текст тултипа',
                    },
                ],
            },
            {
                name: 'gtag',
                component: 'group',
                label: 'Цель GTAG',
                fields: [
                    {
                        name: 'gtagSlider',
                        component: 'group',
                        label: 'Цель ползунок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagButton',
                        component: 'group',
                        label: 'Цель кнопка',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagTerm',
                        component: 'group',
                        label: 'Цель срок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
