import React from 'react';

import ParticipationItem from './item';
import cn from './style.module.sass';

export type DrawParticipationListProps = {
    /**
     * Необходимый параметр для обозначения блока
     */
    _template: 'drawParticipationList';
    title: string;
    text: string;
    items: { title: string; desc: string; chances: string }[];
    link: string;
};

export default function DrawParticipationList({ title, text, items, link }: DrawParticipationListProps) {
    return (
        <div className="section">
            <div className={cn.wrapper}>
                <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={cn.text} dangerouslySetInnerHTML={{ __html: text }} />
                <div className={cn.cards}>
                    {items?.map((item, index) => (
                        <ParticipationItem
                            key={`${index}-${item.title}`}
                            title={item.title}
                            desc={item.desc}
                            chances={item.chances}
                        />
                    ))}
                </div>
                <div className={cn.link} dangerouslySetInnerHTML={{ __html: link }} />
            </div>
        </div>
    );
}
