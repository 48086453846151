import clsx from 'clsx';
import React, { useEffect, useMemo, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import ISwiperOptions from 'swiper/types/swiper-class';

import MobileTap from '@/components/blocks/TinyGradientCategory/MobileTap';
import { ITinyGradientCategoryItem } from '@/components/blocks/TinyGradientCategory/types';
import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';
import ModalWrapper from '@/components/modal/ModalWrapper';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

interface ITinyGradientStory {
    list: ITinyGradientCategoryItem[];
    activeSlideIndex: number;
    isOpen: boolean;
    handleClose: () => void;
    addToLocalStorage: (index: number) => void;
    updateWatchedItems: () => void;
    setActiveSlideIndex: (index: number) => void;
}

const TinyGradientStory = ({
    list,
    activeSlideIndex,
    isOpen,
    handleClose,
    addToLocalStorage,
    updateWatchedItems,
    setActiveSlideIndex,
}: ITinyGradientStory) => {
    const getClassName = () => (activeSlideIndex % 2 === 0 ? cn.odd : cn.even);
    const swiperRef = useRef(null);
    useEffect(() => {
        updateWatchedItems();
    }, [activeSlideIndex]);

    const settings = useMemo(
        () => ({
            className: cn.slider,
            slidesPerView: 1,
            autoHeight: true,
            centeredSlides: true,
            effect: 'fade' as 'fade' | 'slide' | 'cube' | 'coverflow' | 'flip',
            fadeEffect: { crossFade: true },
            onActiveIndexChange: (swiper: { activeIndex: number; realIndex: number }) => {
                setActiveSlideIndex(swiper.activeIndex);
            },
        }),
        []
    );
    const goToSlide = (index: number) => {
        setActiveSlideIndex(index);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        swiperRef?.current?.swiper?.slideTo(index, 300);
    };

    const onSlideChangeHandler = (swiper: ISwiperOptions) => {
        addToLocalStorage(swiper.activeIndex);
    };

    const onClickHandler = (index: number) => {
        goToSlide(index);
        addToLocalStorage(index);
    };
    useEffect(() => {
        goToSlide(activeSlideIndex);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    }, [swiperRef?.current?.swiper?.activeIndex]);

    return (
        <ModalWrapper
            className={clsx(cn.inner, getClassName(), !!list[activeSlideIndex]?.backgroundImage)}
            wrapperClassName={clsx(cn.wrapper)}
            isOpen={isOpen}
            isModalContentCenterNeeded
            handleClose={handleClose}
            whiteCross
            swiperRef={swiperRef}
            clickOutsideExceptionsId={['prevArrow', 'nextArrow']}
            activeSlideIndex={activeSlideIndex}
            list={list}
            crossIconClassName={cn.crossIconClassName}
        >
            {!!list[activeSlideIndex]?.backgroundImage && (
                <CustomImage
                    src={list[activeSlideIndex]?.backgroundImage}
                    fill
                    alt="фоновый градиент"
                    defaultValue={null}
                    className={cn.bgGradient}
                />
            )}
            <div className={cn.content}>
                <div className={cn.thumbs}>
                    {list?.map((item, index) => (
                        <div
                            onClick={() => onClickHandler(index)}
                            className={clsx(cn.thumb, activeSlideIndex === index && cn.active)}
                            key={item?.id}
                            role="presentation"
                        />
                    ))}
                </div>

                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CustomSwiper {...settings} ref={swiperRef} onSlideChange={onSlideChangeHandler}>
                    {list?.map(item => (
                        <SwiperSlide key={item?.id} className={clsx(cn.slide, !item?.storyTitle && cn.hidden)}>
                            <MobileTap swiperRef={swiperRef} />
                            <div className={cn.textWrapper}>
                                <div className={cn.title}>{item.storyTitle}</div>
                                <div className={cn.desc} dangerouslySetInnerHTML={{ __html: item.storyDescription }} />
                            </div>
                            {isOpen && item?.button.label && (
                                <Button
                                    onClick={() => handleClose()}
                                    customClass={cn.button}
                                    variant="btnBlue"
                                    type="link"
                                    href={item?.button?.href}
                                    label={item?.button?.label}
                                />
                            )}
                            {isOpen && item?.openStoryImg && (
                                <CustomImage
                                    src={item?.openStoryImg || ''}
                                    alt="изображение сторис"
                                    className={cn.bgImage}
                                    width={360}
                                    height={360}
                                />
                            )}
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
            </div>
        </ModalWrapper>
    );
};

export default TinyGradientStory;
