import React, { useState } from 'react';

import Decor from '@/components/blocks/QuoteHr/decor';
import QuoteHRModal from '@/components/blocks/QuoteHr/QuoteHRModal';
import CustomImage from '@/components/CustomImage';
import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import { IQuoteHrItem } from '@/types/quoteHr/item';

import cn from './style.module.sass';

const QuoteHrItem: React.FC<IQuoteHrItem> = ({ quote, position, desc, pos, name, photo, tinaPrefix }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className={cn.item}>
            <div className={cn.info}>
                <div className={cn.img}>
                    <CustomImage src={photo} alt={name} width="96" height="96" />
                </div>
                <div className={cn.leader}>
                    <TextField
                        text={name}
                        name={`${tinaPrefix}items.[${pos}].name`}
                        className={cn.name}
                        isHTML
                        htmlValue={name}
                    />
                    {position && (
                        <TextField
                            text={position}
                            name={`${tinaPrefix}items.[${pos}].position`}
                            className={cn.position}
                            isHTML
                            htmlValue={position}
                        />
                    )}
                </div>
                <div className={cn.decor}>
                    <Decor />
                </div>
            </div>
            <div className={cn.quote}>
                <TextField text={quote} name={`${tinaPrefix}items.[${pos}].quote`} isHTML htmlValue={quote} />
                {desc && (
                    <div className={cn.btnWrap}>
                        <ModalWrapper
                            isOpen={isOpen}
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                            className={cn.otherView}
                            bgClassName={cn.otherViewBg}
                            trigger={<div className={cn.btn}>Читать полностью</div>}
                        >
                            {isOpen && (
                                <QuoteHRModal
                                    pos={pos}
                                    name={name}
                                    position={position}
                                    quote={quote}
                                    photo={photo}
                                    desc={desc}
                                    tinaPrefix={tinaPrefix}
                                />
                            )}
                        </ModalWrapper>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuoteHrItem;
