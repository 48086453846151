import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';

import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';
import MapFilterTrigger from './trigger';

interface IFilterMap {
    stateCheckbox: { [key: string]: boolean };
    handleCheckbox: (key) => void;
    selectOptions: string[];
    handleSelect: (val) => void;
}

const FilterMap: React.FC<IFilterMap> = ({ stateCheckbox, handleCheckbox, handleSelect, selectOptions }) => {
    const collapsibleRef = useRef(null);
    const [open, setOpen] = useState(false);
    const options = useMemo(
        () =>
            ['Все', ...selectOptions].map(item => ({
                value: item,
                label: item,
            })),
        [selectOptions]
    );

    useOnClickOutside(collapsibleRef, () => setOpen(false));

    return (
        <div className={cn.filter} ref={collapsibleRef}>
            <Collapsible
                trigger={<MapFilterTrigger isOpen={open} trigger="Фильтры" />}
                onTriggerOpening={() => setOpen(true)}
                onTriggerClosing={() => setOpen(false)}
                triggerOpenedClassName={clsx(cn.filterBox, cn.filterTrigger, cn.open)}
                triggerTagName="div"
                transitionTime={300}
                easing="ease-in-out"
                className={clsx(cn.filterBox, cn.filterTrigger)}
                open={open}
                checked={false}
            >
                <div className={clsx(cn.filterBox, cn.filterBoxOpened)}>
                    <CustomSelect
                        size="small"
                        customClass={cn.filterSelect}
                        placeholder="Ваше местоположение"
                        options={options}
                        onChangeHandler={handleSelect}
                    />
                    <div className={clsx(cn.filterList)}>
                        {stateCheckbox &&
                            Object.keys(stateCheckbox).map((item, idx) => (
                                <Checkbox
                                    key={idx.toString()}
                                    classCheckmark={cn.filterCheckboxCheckmark}
                                    customClass={cn.filterCheckbox}
                                    label={item}
                                    onChangeHandler={() => handleCheckbox(item)}
                                    checked={stateCheckbox[item]}
                                />
                            ))}
                    </div>
                </div>
            </Collapsible>
        </div>
    );
};

export default FilterMap;
