import React from 'react';

import { TinaConfirmEmailForm } from '@/components/blocks/forms/ConfirmEmailForm/index.tina';
import { mockConfirmEmailFormBlockDataTina } from '@/data/blocks/confirmEmailForm';

export const confirmEmailFormBlock = {
    Component: props => <TinaConfirmEmailForm {...props} />,
    template: {
        label: 'Форма для подтверждения электронной почты',
        defaultItem: () => mockConfirmEmailFormBlockDataTina,
        fields: [],
    },
};
