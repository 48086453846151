export const sberschetCalculatorData = {
    title: 'Ваш доход',
    tabTitle: 'Ваш доход',
    valueRate: 5.9,
    fields: {
        rangeSlider: [
            {
                name: 'range1',
                elements: [
                    {
                        value: 0,
                        step: 1000,
                        percent: 0,
                        label: '0',
                        id: 1,
                    },
                    {
                        value: 1000000,
                        step: 1000,
                        percent: 25,
                        label: '1 млн',
                        id: 2,
                    },
                    {
                        value: 2000000,
                        step: 1000,
                        percent: 50,
                        label: '2 млн.',
                        id: 3,
                    },
                    {
                        value: 3000000,
                        step: 1000,
                        percent: 75,
                        label: '3 млн.',
                        id: 5,
                    },
                    {
                        value: 4000000,
                        step: 1000,
                        percent: 100,
                        label: '4 млн.',
                        id: 6,
                    },
                ],
                initValue: 2000000,
                label: 'Сумма на счёте',
            },
        ],
    },
};
