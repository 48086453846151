import React from 'react';

import CustomTooltip from '@/components/Tooltip';
import TooltipIcon from '@/components/UI/TooltipIcon';

import cn from './style.module.sass';

interface IPropsText {
    value: string;
}

const Text: React.FC<IPropsText> = ({ value }) => (
    <div
        className={cn.textTooltip}
        dangerouslySetInnerHTML={{
            __html: value,
        }}
    />
);

export interface ICalculatorDisclaimerRanges {
    text: string;
    tooltipText?: string;
    min: number;
    max: number;
}

export interface ICalculatorDisclaimer {
    ranges?: ICalculatorDisclaimerRanges[];
    isActive?: boolean;
}

const InfoTitle: React.FC<ICalculatorDisclaimerRanges> = ({ text, tooltipText, max, min }) => {
    if (tooltipText && tooltipText.length > 0) {
        return (
            <>
                <div
                    className={cn.infoTitle}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />

                <div className={cn.tooltipWrapper}>
                    <CustomTooltip html={<Text value={tooltipText} />}>
                        <span className={cn.tooltip}>
                            <TooltipIcon />
                        </span>
                    </CustomTooltip>
                </div>
            </>
        );
    }

    return (
        <div
            className={cn.infoTitle}
            dangerouslySetInnerHTML={{
                __html: text,
            }}
        />
    );
};

export default InfoTitle;
