export const mockFormBlockData = {
    form: {
        _template: '',
    },
};

export const mockFormBlockDataTina = {
    form: {
        _template: '',
    },
};
