export const hrDetailConditionsList = [
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/career.svg',
        title: '',
        subtitle: 'Стабильность и уверенность в завтрашнем дне',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/teacher.svg',
        title: '',
        subtitle: 'Возможность работать в команде профессионалов',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/projects.svg',
        title: '',
        subtitle: 'Современный стек технологий',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/star.svg',
        title: '',
        subtitle: 'Амбициозные задачи и уникальные проекты',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/diamond.svg',
        title: '',
        subtitle: 'Высокий уровень качества реализуемых продуктов',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/format.svg',
        title: '',
        subtitle: 'Гибкий формат: очно, гибрид или 100% удаленная работа',
    },
];
