import React from 'react';

import { TinaBiography } from '@/components/blocks/biography/Biography/index.tina';
import { mockBiographyBlockDataTina } from '@/data/blocks/biogpraphy';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const biographyBlock = {
    Component: props => <TinaBiography {...props} />,
    template: {
        defaultItem: () => mockBiographyBlockDataTina,
        label: 'Биография',
        fields: [
            {
                component: 'group-list',
                name: 'list',
                label: 'Блоки',
                itemProps: (item: { year: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.year || 'Год',
                }),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                defaultItem: () => mockBiographyBlockDataTina.list[0],
                fields: [
                    {
                        name: 'year',
                        component: 'text',
                        label: 'Год',
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
