import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import FaqQuestionsItem, { IFaqQuestionsItem } from '@/components/blocks/FaqQuestions/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IFaqQuestions {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'faqQuestions';
    name?: string;
    title?: string;
    description?: string;
    list: Array<IFaqQuestionsItem>;
}

function FaqQuestions({ title, description, list }: IFaqQuestions) {
    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.wrapper, 'section')}>
                <div className={cn.wrap}>
                    <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                    <TextField
                        className={cn.description}
                        text={description}
                        name="description"
                        isHTML
                        htmlValue={description}
                    />
                    <div className={cn.grid}>
                        {list?.map((item, index) => (
                            <div className={cn.item} key={`key-${index}`}>
                                <FaqQuestionsItem {...item} index={index} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(FaqQuestions);
