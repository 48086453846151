// Конфиг для блоков форм. Ключ = template вашей формы

import dynamic from 'next/dynamic';
import React from 'react';

export type TFormTemplates = keyof typeof formsConfig;

export type IFormsConfig = {
    [name in TFormTemplates]: {
        title: string;
        Component: React.FC;
    };
};

export const formsConfig = {
    unlockingPhoneForm: {
        title: 'Форма восстановления доступа без изменения номера телефона',
        Component: dynamic(() => import('@/components/blocks/forms/UnlockingPhoneForm')) as React.FC,
    },
    unlockingCardForm: {
        title: 'Форма восстановления доступа - разблокировка карты',
        Component: dynamic(() => import('@/components/blocks/forms/UnlockingCardForm')) as React.FC,
    },
    salaryProjectForm: {
        title: 'Форма заявки на зарплатный проект',
        Component: dynamic(() => import('@/components/blocks/forms/SalaryProjectForm')) as React.FC,
    },
    salaryCardForm: {
        title: 'Форма заявки на зарплатную карту ПБ(Индивидуальный зарплатный проект)',
        Component: dynamic(() => import('@/components/blocks/forms/SalaryCardForm')) as React.FC,
    },
    internetAcquiringRequestForm: {
        title: 'Форма заявки на подключение интернет эквайринга',
        Component: dynamic(() => import('@/components/blocks/forms/InternetAcquiringRequestForm')) as React.FC,
    },
    feedbackForm: {
        title: 'Форма обратной связи',
        Component: dynamic(() => import('@/components/blocks/FeedbackForm')) as React.FC,
    },
    hrFeedbackForm: {
        title: 'Форма обратной связи HR',
        Component: dynamic(() => import('@/components/blocks/forms/HRFeedbackForm')) as React.FC,
    },
    connectionRequestForm: {
        title: 'Заявка на подключение (доработать чего именно)',
        Component: dynamic(() => import('@/components/blocks/forms/ConnectionRequestForm')) as React.FC,
    },
    bankGuaranteeForm: {
        title: 'Форма заявки на банковскую гарантию: Подать заявку',
        Component: dynamic(() => import('@/components/blocks/forms/BankGuaranteeForm')) as React.FC,
    },
    bankGuaranteeFormAgent: {
        title: 'Форма заявки на банковскую гарантию: Стать агентом банка',
        Component: dynamic(() => import('@/components/blocks/forms/BankGuaranteeFormAgent')) as React.FC,
    },
    worldEliteForm: {
        title: 'Форма заявки на получение карты WORLD ELITE',
        Component: dynamic(() => import('@/components/blocks/forms/WorldEliteForm')) as React.FC,
    },
    mirSupremeForm: {
        title: 'Форма заявки на получение карты МИР SUPREME',
        Component: dynamic(() => import('@/components/blocks/forms/MirSupremeForm')) as React.FC,
    },
    cooperationForm: {
        title: 'Форма заявки на сотрудничество',
        Component: dynamic(() => import('@/components/blocks/forms/CooperationForm')) as React.FC,
    },
    ticketForm: {
        title: 'Заявка на подключение к Почта Банк.Билеты',
        Component: dynamic(() => import('@/components/blocks/forms/TicketForm')) as React.FC,
    },
    insuranceCaseForm: {
        title: 'Форма для страхового случая',
        Component: dynamic(() => import('@/components/blocks/forms/InsuranceCaseForm')) as React.FC,
    },
    feedbackIshopper: {
        title: 'Форма обратной связи Ishopper',
        Component: dynamic(() => import('@/components/blocks/forms/IshopperFeedbackForm')) as React.FC,
    },
    bvdForm: {
        title: 'Форма для БВД',
        Component: dynamic(() => import('@/components/blocks/forms/BistrDomaForm')) as React.FC,
    },
    digitalCardApplicationForm: {
        title: 'Заявление для цифровой карты',
        Component: dynamic(() => import('@/components/blocks/forms/DigitalCardApplicationForm')) as React.FC,
    },
    connectOnlineForm: {
        title: 'Форма подключить онлайн',
        Component: dynamic(() => import('@/components/blocks/forms/ConnectOnlineForm')) as React.FC,
    },
    confirmEmailForm: {
        title: 'Форма для подтверждения электронной почты',
        Component: dynamic(() => import('@/components/blocks/forms/ConfirmEmailForm')) as React.FC,
    },
    questionnaireForPushkisCardForm: {
        title: 'Анкета для пушкинской карты',
        Component: dynamic(() => import('@/components/blocks/forms/QuestionnaireForPushkisCardForm')) as React.FC,
    },
    cultureForm: {
        title: 'Форма обратной связи для учреждений культуры',
        Component: dynamic(() => import('@/components/blocks/forms/CultureForm')) as React.FC,
    },
    feedbackFormQuestions: {
        title: 'Форма обратной связи - Вопросы по банкоматам',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormQuestions')) as React.FC,
    },
    feedbackFormBank: {
        title: 'Форма обратной связи - Сотрудничество с Банком',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormBank')) as React.FC,
    },
    feedbackFormHotLine: {
        title: 'Форма обратной связи - Горячая линия комплаенс',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormHotLine')) as React.FC,
    },
    feedbackFormOffRequests: {
        title: 'Форма обратной связи - Официальные запросы',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormOffRequests')) as React.FC,
    },
    feedbackFormPretension: {
        title: 'Форма обратной связи - Подать претензию, жалобу',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormPretension')) as React.FC,
    },
    feedbackFormPretensionSecond: {
        title: 'Форма обратной связи - Подать претензию, жалобу v2',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormPretensionSecond')) as React.FC,
    },
    feedbackFormMortgage: {
        title: 'Форма обратной связи - Ипотека',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormMortgage')) as React.FC,
    },
    feedbackFormAppeal: {
        title: 'Форма обратной связи - Подать обращение',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormAppeal')) as React.FC,
    },
    feedbackFormVTwo: {
        title: 'Форма обратной связи v.2',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormV2')) as React.FC,
    },
    feedbackFormVThree: {
        title: 'Форма обратной связи по договорам и счетам',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormVThree')) as React.FC,
    },
    feedbackFormSalaryCard: {
        title: 'Форма обратной связи - Зарплатная карта',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormSalaryCard')) as React.FC,
    },
    feedbackFormSalaryProject: {
        title: 'Форма обратной связи - Зарплатный проект',
        Component: dynamic(() => import('@/components/blocks/FeedbackFormSalaryProject')) as React.FC,
    },
};
