import Image from 'next/image';
import React from 'react';

import { Advantage } from '@/components/blocks/SalesHRLanding/types/advantages';

import cn from './style.module.sass';

type SalesHRAdvantagesCardProps = Advantage;

function SalesHRAdvantagesCard({ icon, title }: SalesHRAdvantagesCardProps) {
    return (
        <div className={cn.advantagesCard}>
            <Image className={cn.advantagesCard__icon} src={icon} alt="" width={48} height={48} aria-hidden="true" />
            <div className={cn.advantagesCard__title} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );
}

export default SalesHRAdvantagesCard;
