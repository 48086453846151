import clsx from 'clsx';
import React, { useRef } from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface IHRVacancyButtonProps {
    /**
     * Тип: кнопка или ссылка
     */
    type: 'button' | 'link' | 'more';
    /**
     * Ссылка
     */
    href?: string;
    /**
     * Target атрибут
     */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /**
     * Размеры кнопки
     */
    size?: 'large' | 'small';
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    /**
     * Темный цвет
     */
    isDark?: boolean;
    /**
     * Заливка
     */
    isBg?: boolean;
}

function VacancyButton({
    size = 'large',
    label,
    disable = false,
    customClass,
    type = 'button',
    href,
    target,
    onClick,
    isDark = false,
    isBg = false,
}: IHRVacancyButtonProps) {
    const ref = useRef(null);

    const className = clsx(
        cn.btnHR,
        disable === true && cn.disable,
        isDark === true && cn.darkTheme,
        isBg === true && cn.bgTheme,
        size === 'small' && cn.small,
        customClass
    );

    if (type !== 'button' && type === 'more')
        return (
            <button ref={ref} type="button" className={className} onClick={onClick}>
                <span>
                    <CustomImage width={16} height={16} src="/img-next/svg/hr-landing/more.svg" alt="Показать еще" />
                </span>
                {label}
            </button>
        );

    if (type !== 'button')
        return (
            <a href={href} ref={ref} target={target} className={className} onClick={onClick}>
                {label}
            </a>
        );

    return (
        <button ref={ref} type="button" className={className} onClick={onClick}>
            {label}
        </button>
    );
}

export default VacancyButton;
