export const mockInformationCenterBlockData = {
    _template: 'informationCenter',
    image: '/img-next/png/informationCenter.png',
    title: 'Информационный центр управляющей компании «ВТБ Капитал Управление активами»',
    bottomText:
        'Звонки принимаются ежедневно с 09:00 до 19:00 по московскому времени, кроме выходных и праздничных дней.',
    phone: [
        {
            tel: '+7 (495) 589-10-00',
            telHref: '84955891000',
            city: 'Москва',
        },
        {
            tel: '8 (800) 700-44-04',
            telHref: '88007004404',
            city: 'Другие регионы',
        },
    ],
};

export const mockInformationCenterBlockDataTina = {
    _template: 'informationCenter',
    image: '',
    title: 'Заголовок',
    bottomText: 'Описание.',
    phone: [
        {
            tel: '+7 (999) 999-99-99',
            telHref: '899999999',
            city: 'Заголовок',
        },
    ],
};
