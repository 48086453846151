export const partnersMap = {
    title: 'Карта партнеров',
    partnerList: [
        {
            id: 0,
            coords: '59.722119, 30.604937',
            logoPartner: '/img-next/test/1.png',
            namePartner: 'ООО Белый Дом - Про',
            partnerLink: 'https://beli-dom.ru/',
            city: 'Нижний Новгород',
            mp: 'Северное Поволжье',
            regionalCenter: 'Нижний Новгород',
            address: '607684, РОССИЯ, НИЖЕГОРОДСКАЯ ОБЛ, Р-Н КСТОВСКИЙ, Д РЖАВКА',
            openingHours: '10:00 - 18:00',
            phones: '+7(999) 99 99 999',
            specialization: 'Универсальные застройщики (компании использующие все возможные типы застройки).',
            statementLink: 'https://test.tu',
            webCode: '0101001005817',
        },
    ],
};

export const mockPartnersMapDataTina = {
    title: 'Карта партнеров',
    partnerList: [
        {
            id: 0,
            coords: '59.722119, 30.604937',
            logoPartner: '/img-next/test/1.png',
            namePartner: 'ООО Белый Дом - Про',
            partnerLink: 'https://beli-dom.ru/',
            city: 'Нижний Новгород',
            mp: 'Северное Поволжье',
            regionalCenter: 'Нижний Новгород',
            address: '607684, РОССИЯ, НИЖЕГОРОДСКАЯ ОБЛ, Р-Н КСТОВСКИЙ, Д РЖАВКА',
            openingHours: '10:00 - 18:00',
            phones: '+7(999) 99 99 999',
            specialization: 'Универсальные застройщики (компании использующие все возможные типы застройки).',
            statementLink: 'https://test.tu',
            webCode: '0101001005817',
        },
        {
            id: 1,
            coords: '59.722119, 31.604937',
            logoPartner: '/img-next/test/1.png',
            namePartner: 'ООО Белый Дом - Про',
            partnerLink: 'https://beli-dom.ru/',
            city: 'Нижний Новгород',
            mp: 'Северное',
            regionalCenter: 'Новгород',
            address: '607684, РОССИЯ, НИЖЕГОРОДСКАЯ ОБЛ, Р-Н КСТОВСКИЙ, Д РЖАВКА',
            openingHours: '10:00 - 18:00',
            phones: '+7(999) 99 99 999',
            specialization: 'Универсальные з (компании использующие все возможные типы застройки).',
            statementLink: 'https://test.tu',
            webCode: '0101001005817',
        },
        {
            id: 1,
            coords: '59.722119, 32.604937',
            logoPartner: '/img-next/test/1.png',
            namePartner: 'ООО Белый Дом - Про',
            partnerLink: 'https://beli-dom.ru/',
            city: 'Нижний Новгород',
            mp: 'Северное',
            regionalCenter: 'Нижний',
            address: '607684, РОССИЯ, НИЖЕГОРОДСКАЯ ОБЛ, Р-Н КСТОВСКИЙ, Д РЖАВКА',
            openingHours: '10:00 - 18:00',
            phones: '+7(999) 99 99 999',
            specialization: ' Универсальные з(компании использующие все возможные типы застройки).',
            statementLink: 'https://test.tu',
            webCode: '0101001005817',
        },
    ],
};
