import React from 'react';

import { TinaRefinanceAdvantages } from '@/components/blocks/RefinanceAdvantages/index.tina';
import { mockRefinanceAdvantagesBlockData } from '@/data/blocks/refinanceAdvantages';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const tinaRefinanceAdvantages = {
    Component: props => <TinaRefinanceAdvantages {...props} />,
    template: {
        label: 'Выгода при рефинансировании',
        defaultItem: () => mockRefinanceAdvantagesBlockData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'name',
                component: 'text',
                label: 'Название',
                required: true,
            },
            {
                name: 'list',
                label: 'Список карточек',
                component: 'group-list',
                defaultItem: () => mockRefinanceAdvantagesBlockData.list,
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'firstText',
                        component: HtmlEditorFeatured,
                        label: 'Первый блок текста',
                    },
                    {
                        name: 'secondText',
                        component: HtmlEditorFeatured,
                        label: 'Второй блок текста',
                    },
                    {
                        name: 'thirdText',
                        component: HtmlEditorFeatured,
                        label: 'Третий блок текста',
                    },
                    {
                        name: 'fourthText',
                        component: HtmlEditorFeatured,
                        label: 'Четвертый блок текста',
                    },
                ],
            },
        ],
    },
};
