export const mockParametersBondsBlockData = {
    title: 'Параметры ОФЗ-Н седьмого выпуска &#8470;&nbsp;53007RMFS',
    list: [
        {
            itemTitle: 'Эмитент',
            itemValue: 'Министерство финансов Российской Федерации',
        },
        {
            itemTitle: 'Государственный регистрационный номер',
            itemValue: '53007RMFS',
        },
        {
            itemTitle: 'Объем выпуска',
            itemValue: '15 000 000 000 (пятнадцать миллиардов) рублей.',
        },
        {
            itemTitle: 'Количество облигаций',
            itemValue: '15 000 000 (пятнадцать миллионов) штук.',
        },
        {
            itemTitle: 'Номинальная стоимость одной облигации',
            itemValue: '1000 рублей.',
        },
        {
            itemTitle: 'Дата начала размещения облигаций',
            itemValue: '25 января 2021 г.',
        },
        {
            itemTitle: 'Дата окончания размещения облигаций',
            itemValue: '30 июля 2021 г.',
        },
        {
            itemTitle: 'Процентные ставки купонного дохода (годовые проценты)',
            itemValue:
                '<ul><li>первый купон — 4% годовых;' +
                '</li>' +
                '<li>второй купон — 4,4% годовых;</li>' +
                '<li>третий купон — 4,9% годовых;</li>' +
                '<li>четвертый купон — 5,45% годовых;</li>' +
                '<li>пятый купон — 6,05% годовых;</li>' +
                '<li>шестой купон — 6,75% годовых;</li>' +
                '</ul>',
        },
        {
            itemTitle: 'Дата погашения облигаций',
            itemValue: '7 февраля 2024 г.',
        },
        {
            itemTitle: 'Круг потенциальных владельцев',
            itemValue:
                'совершеннолетние граждане Российской Федерации и&nbsp;агенты по&nbsp;размещению и&nbsp;выкупу облигаций в&nbsp;случаях, установленных условиями эмиссии.',
        },
        {
            itemTitle: 'Минимальное количество облигаций',
            itemValue:
                'доступных для приобретения одним физическим лицом по&nbsp;каждому поручению, поданному Агенту &mdash;10 (десять) штук.',
        },
        {
            itemTitle: 'Максимальное количество облигаций',
            itemValue: 'которым может владеть одно физическое лицо&nbsp;&mdash; 15&nbsp;000 (пятнадцать тысяч) штук.',
        },
    ],
};

export const mockParametersBondsBlockTinaData = {
    section: null,
    _template: 'parametersBonds',
    title: 'Заголовок',
    list: [
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
        {
            itemTitle: 'Название',
            itemValue: 'Описание',
        },
    ],
};
