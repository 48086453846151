import React from 'react';

import { TinaHelp } from '@/components/blocks/tariffTabs/Help/index.tina';
import { mockHelpBlockDataTina } from '@/data/blocks/tariffTabs/help';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const helpBlock = {
    Component: (props: IBlockProps) => <TinaHelp {...props} />,
    template: {
        label: 'Помощь',
        defaultItem: () => mockHelpBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                component: HtmlEditorFeatured,
                name: 'title',
                label: 'Заголовок',
            },
            {
                name: 'isIndexHidden',
                component: 'toggle',
                label: 'Скрыть от индексации',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { subtitle: string }, index: number) => ({
                    key: index,
                    label: item.subtitle,
                }),
                defaultItem: () => mockHelpBlockDataTina.list[0],
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        name: 'subtitle',
                        label: 'Заголовок',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'desc',
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
