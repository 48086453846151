import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import Title from '@/components/UI/Title';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

export interface ITariffInfo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'tariffInfo';
    title?: string;
    list: ITariffInfoElement[];
    tagList?: ITag[];
}

export interface ITariffInfoElement {
    name: string;
    subtitle?: string;
    pos?: number;
    tags: string[];
    desc?: string;
}

const TariffInfo: React.FC<ITariffInfo> = ({ title, list, tagList }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const items = useMemo(() => {
        if (!tagList?.length) return list;
        return list?.reduce((acc: ITariffInfoElement[], current, index) => {
            if (current.tags?.includes(selectedTag)) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);
    }, [tagList, list, selectedTag]);

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.tariffInfo, 'section', 'tariffInfo')}>
                <div className={cn.tariffInfoWrap}>
                    <Title level={3} title={title} name="title" />
                    <TagList className={cn.filter} tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                    <div className={cn.panelWrap}>
                        {items?.map(({ name, subtitle, desc, pos }, index) => (
                            <AnimatedComponent
                                className={cn.panel}
                                classNameActive={cn.activePanel}
                                key={`${selectedTag}-${index}`}
                            >
                                <TextField
                                    text={name}
                                    name={`list.${pos || index}.name`}
                                    className={cn.tariffInfoName}
                                />
                                <TextField
                                    text={subtitle}
                                    name={`list.${pos || index}.subtitle`}
                                    className={cn.tariffInfoSubtitle}
                                />
                                <TextField
                                    text={desc}
                                    name={`list.${pos || index}.desc`}
                                    isHTML
                                    htmlValue={desc}
                                    className={cn.tariffInfoDesc}
                                />
                            </AnimatedComponent>
                        ))}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(TariffInfo);
