import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';

import MonthlyPayment from '@/components/blocks/calculators/layouts/PreferentialCalculator/MonthlyPayment';
import cn from '@/components/blocks/calculators/layouts/PreferentialCalculator/style.module.sass';
import { PreferentialContentProps } from '@/components/blocks/calculators/layouts/PreferentialCalculator/types';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IPreferentialStore } from '@/domain/calculator/PreferentialCalculatorStore';
import { event } from '@/components/shared/utilities/analytics/metrics';

const PreferentialCalculatorContent: FC<PreferentialContentProps> = observer(({ title, index, gtag, button }) => {
    const { range, termField } = useContext<IPreferentialStore>(CalculatorStoreContext);

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.wrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <div className={cn.leftInner}>
                                {title && (
                                    <TextField
                                        text={title}
                                        customTag="h2"
                                        name="title"
                                        className={cn.title}
                                        isHTML
                                        htmlValue={title}
                                    />
                                )}
                                <RangeInput config={range} noMaxWidth />
                                <Terms title="Срок кредита" field={termField} gtag={gtag?.gtagTerm} />
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <MonthlyPayment />
                                <div className={cn.button}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={button?.link || '#'}
                                        label={button?.label || 'Заполнить анкету'}
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => {
                                            if (gtag?.gtagButton) event(gtag.gtagButton);
                                        }}
                                        animation={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PreferentialCalculatorContent;
