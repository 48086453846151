import React from 'react';

import ResumeForm from '@/components/blocks/forms/ResumeForm';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IVacancyContent {
    content: string;
    vacancyName: string;
    cityName?: string;
}

const Modal: React.FC<IVacancyContent> = ({ content, vacancyName, cityName }) => (
    <div className={cn.wrap}>
        <div className={cn.content}>
            <TextField text={content} name={content} isTextArea />
        </div>
        <div className={cn.form}>
            <ResumeForm
                title="Отправить резюме"
                successTitle="Ваша заявка принята!"
                noBlock
                summaryName={vacancyName}
                cityName={cityName}
            />
        </div>
    </div>
);

export default Modal;
