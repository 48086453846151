export const mockApplicationAppsBlockData = {
    title: 'Возможности приложения',
    bgImg: '/img-next/png/applicationApps.png',
    list: [
        {
            img: '/img-next/png/applicationApps/1.png',
            name: 'Детская карта лучше карманных денег',
            desc: 'Если утром забыли оставить деньги на обед ребенку — не переживайте! Просто переведите деньги ему на карту, и проблема решена!',
        },
        {
            img: '/img-next/png/applicationApps/2.png',
            name: 'Приучите ребенка к финансовой самостоятельности',
            desc: 'С личной картой ребенок сможет самостоятельно оплачивать в приложении — мобильную связь, интернет, игры, услуги ЖКУ и много другое. Для оплаты доступны все услуги, как в Почта Банк Онлайн.',
        },
        {
            img: '/img-next/png/applicationApps/3.png',
            name: 'Контроль всех операций по Детской карте',
            desc: 'Теперь вы точно сможете знать, на что уходят карманные деньги ребенка. С банковской картой все расходы вашего чада под контролем. Устанавливайте ограничения на снятие наличных, покупки в интернете, переводы на другие карты и многое другое.',
        },
        {
            img: '/img-next/png/applicationApps/4.png',
            name: 'Развивайте финансовую грамотность',
            desc: 'Превратите оплату регулярных платежей в познавательную игру. Научите ребенка пользоваться фотоплатежом, передавать показания электросчетчика, снимать наличные по QR—коду в банкоматах. Мы уверены, что вам тоже будет интересно разобраться в современных инструментах оплаты.',
        },
        {
            img: '/img-next/png/applicationApps/5.png',
            name: 'Научите ребенка копить и разумно тратить',
            desc: 'Не секрет, что расставаться с наличным деньгами гораздо сложнее, чем расплачиваться банковской картой. Научите ребенка грамотно обращаться с «виртуальным деньгам» с детства, чтобы он мог избежать импульсивных трат и необдуманных покупок в дальнейшем.',
        },
    ],
};

export const mockApplicationAppsBlockDataTina = {
    title: 'Заголовок',
    bgImg: '',
    list: [
        {
            img: '',
            name: 'Заголовок',
            desc: 'Описание',
        },
    ],
};
