import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useTheme } from '@/context/ThemeProvider';
import { useResize } from '@/hooks/useResize';
import { IServicesBank, IServicesMobile } from '@/types/services';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IServices {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'services';
    /**
     * Мобильное приложение.
     */
    mobile: { mobile: IServicesMobile };
    /**
     * Интернет банк.
     */
    bank: { bank: IServicesBank };
}

const Services: React.FC<IServices> = ({ mobile, bank }) => {
    // theme нужен для опредения цвета OR кода
    const { theme } = useTheme();
    const width = useResize();
    const isDesktop = width > MOBILE_WIDTH;
    const isMobile = width <= MOBILE_WIDTH;

    if (!mobile?.mobile && !bank?.bank) return null;

    return (
        <div className={clsx(cn.services, 'section')}>
            <div className={cn.servicesWrap}>
                <div className={cn.servicesGrid}>
                    {mobile?.mobile && (
                        <AnimatedComponent className={cn.servicesItem} classNameActive={cn.animationInit}>
                            <div
                                className={clsx(cn.servicesItemWrap, cn.servicesItemMobile)}
                                onClick={() => {
                                    if (mobile?.mobile?.gtag?.action) event(mobile?.mobile?.gtag);
                                }}
                                role="presentation"
                            >
                                <div className={cn.servicesContent}>
                                    <div className={cn.servicesTop}>
                                        <TextField
                                            className={cn.servicesTitle}
                                            text={mobile?.mobile?.title}
                                            name="mobile.mobile.title"
                                        />
                                        <TextField
                                            className={cn.servicesSubtitle}
                                            isHTML
                                            htmlValue={mobile?.mobile?.subtitle}
                                            text={mobile?.mobile?.subtitle}
                                            name="mobile.mobile.subtitle"
                                        />
                                        {isMobile && (
                                            <TextField
                                                className={cn.servicesMobileSubtitle}
                                                isHTML
                                                htmlValue={mobile?.mobile?.mobileSubtitle}
                                                text={mobile?.mobile?.mobileSubtitle}
                                                name="mobile.mobile.mobileSubtitle"
                                            />
                                        )}
                                    </div>
                                    <div className={cn.servicesBot}>
                                        {isDesktop && mobile?.mobile?.qrCode?.text && (
                                            <div className={cn.servicesQr}>
                                                <div className={clsx(cn.servicesQrItem, cn.servicesQrItemImg)}>
                                                    {theme === 'dark' ? (
                                                        <CustomImage
                                                            src={mobile?.mobile?.qrCode?.imageDarkTheme}
                                                            alt={mobile?.mobile?.qrCode?.text}
                                                            width={86}
                                                            height={86}
                                                        />
                                                    ) : (
                                                        <CustomImage
                                                            src={mobile?.mobile?.qrCode?.image}
                                                            alt={mobile?.mobile?.qrCode?.text}
                                                            width={86}
                                                            height={86}
                                                        />
                                                    )}
                                                </div>
                                                <div className={clsx(cn.servicesQrItem, cn.servicesQrItemText)}>
                                                    <TextField
                                                        className={cn.servicesQrText}
                                                        isTextArea
                                                        text={mobile?.mobile?.qrCode?.text}
                                                        name="mobile.mobile.qrCode.text"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {isMobile && (
                                            <div className={cn.servicesStore}>
                                                {mobile?.mobile?.store?.map(({ link, name, image }, index) => (
                                                    <a
                                                        href={link}
                                                        target="_blank"
                                                        className={cn.servicesStoreItem}
                                                        key={index}
                                                        rel="noreferrer"
                                                    >
                                                        <CustomImage src={image} alt={name} width={131} height={38} />
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                        {mobile?.mobile?.isLink ? (
                                            <a
                                                className={clsx(cn.servicesBtn, cn.servicesBtnLink)}
                                                href={mobile?.mobile?.link}
                                            >
                                                {mobile?.mobile?.textLink}
                                            </a>
                                        ) : (
                                            <Button
                                                variant="btnBlue"
                                                type="link"
                                                href={mobile?.mobile?.link}
                                                label={mobile?.mobile?.textLink}
                                                size="small"
                                                onClick={() => {
                                                    if (mobile.mobile.gtag?.action) event(mobile.mobile.gtag);
                                                }}
                                                customClass={cn.servicesBtn}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={clsx(cn.servicesImage, cn.servicesImageMobail)}>
                                    <div className={cn.servicesImageWrap}>
                                        <CustomImage
                                            src={mobile?.mobile?.image}
                                            alt={mobile?.mobile?.title}
                                            width={150}
                                            height={207}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AnimatedComponent>
                    )}
                    {bank?.bank && (
                        <AnimatedComponent
                            className={clsx(
                                cn.servicesItem,
                                bank?.bank?.mobailDisplay === false && cn.servicesBlockHidden,
                                bank?.bank?.mobailDisplay === true && cn.servicesBlockVisible
                            )}
                            classNameActive={cn.animationInit}
                        >
                            <div className={clsx(cn.servicesItemWrap, bank?.bank && cn.servicesItemBank)}>
                                <div className={cn.servicesContent}>
                                    <div className={cn.servicesTop}>
                                        <TextField
                                            className={cn.servicesTitle}
                                            text={bank?.bank?.title}
                                            name="bank.bank.title"
                                        />
                                        <TextField
                                            className={cn.servicesSubtitle}
                                            isTextArea
                                            text={bank?.bank?.subtitle}
                                            name="bank.bank.subtitle"
                                        />
                                        {isMobile && (
                                            <TextField
                                                className={cn.servicesMobileSubtitle}
                                                isHTML
                                                htmlValue={bank?.bank?.mobileSubtitle}
                                                text={bank?.bank?.mobileSubtitle}
                                                name="bank.bank.mobileSubtitle"
                                            />
                                        )}
                                    </div>
                                    <div className={cn.servicesBot}>
                                        {bank?.bank?.isLink ? (
                                            <a
                                                className={clsx(cn.servicesBtn, cn.servicesBtnLink)}
                                                href={bank?.bank?.link}
                                            >
                                                {bank?.bank?.textLink}
                                            </a>
                                        ) : (
                                            <Button
                                                variant="btnBlue"
                                                type="link"
                                                href={bank?.bank?.link}
                                                label={bank?.bank?.textLink}
                                                size="small"
                                                onClick={() => {
                                                    if (mobile.mobile.gtag?.action) event(mobile.mobile.gtag);
                                                }}
                                                customClass={cn.servicesBtn}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={clsx(cn.servicesImage, cn.servicesImageBank)}>
                                    <div className={clsx(cn.servicesImageWrap, cn.servicesImageWrapBank)}>
                                        <CustomImage
                                            src={bank?.bank?.image}
                                            alt={bank?.bank?.title}
                                            fill
                                            mockWidth="260"
                                            mockHeight="323"
                                        />
                                    </div>
                                </div>
                            </div>
                        </AnimatedComponent>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(Services);
