import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { ICalculatorDisclaimer } from '@/components/blocks/calculators/InfoTitle';
import CreditRealEstateCalculatorContent from '@/components/blocks/calculators/layouts/CreditRealEstateCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useAppStore } from '@/context/AppStoreContext';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import CreditRealEstateCalculatorStore from '@/domain/calculator/CreditRealEstateCalculatorStore';
import { getQueryParam } from '@/utils/getQueryParam';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

interface IQueryParam {
    value: string;
    term: string;
}

interface ICalculatorParams {
    sumElements: ICalculatorFilterElement[];
    terms: ICalculatorFilterElement[];
    rate: number;
    balancePercent: number;
}

interface ISecondaryCalculatorBannerRanges {
    title: string;
    subtitle: string;
    min: number;
    max: number;
    image?: string;
    shouldHide?: boolean;
}

interface ICalculatorBanner {
    ranges?: ISecondaryCalculatorBannerRanges[];
    isActive: boolean;
}

export interface ICreditRealEstateCalculator {
    _template?: 'creditRealEstateCalculator';
    title?: string;
    tabTitle?: string;
    params: ICalculatorParams;
    banner?: ICalculatorBanner;
    infoTitle?: ICalculatorDisclaimer;
    button?: {
        label?: string;
        link: string;
    };
    buttonShared?: {
        isActive: boolean;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
        gtagButtonShare: GTagEvent;
    };
}

const CreditRealEstateCalculator: React.FC<ICreditRealEstateCalculator> = observer(
    ({ title, params, index, gtag, banner, button, buttonShared, infoTitle }) => {
        const [path] = useAppStore(store => store.path);

        if (!params) return null;

        let asPath = '';
        try {
            const { location } = document;
            asPath = location.href;
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Tabs error: `, error.message);
            }
        }

        const [currentQueryValue, setCurrentQueryValue] = useState<string>(null);
        const [currentQueryTerm, setCurrentQueryTerm] = useState<string>(null);

        useEffect(() => {
            if (typeof window !== 'undefined') {
                try {
                    let queryParam = getQueryParam(asPath, 'cashCalculatorParams');

                    if (!queryParam) return;

                    const hashPosition = queryParam.indexOf('#');
                    if (hashPosition !== -1) {
                        queryParam = queryParam.slice(0, hashPosition);
                    }

                    const decodedQueryParam = decodeURIComponent(queryParam);
                    const { value, term }: IQueryParam = JSON.parse(decodedQueryParam) as IQueryParam;

                    setCurrentQueryTerm(term);
                    setCurrentQueryValue(value);
                } catch (e) {
                    console.error('Ошибка парсинга значений из гет параметров для калькулятора наличными.', e);
                }
            }
        }, []);

        useEffect(() => {
            CreditRealEstateCalculatorStore.init(
                params.sumElements,
                params.terms,
                params.rate,
                params.balancePercent,
                +currentQueryValue,
                +currentQueryTerm
            );
        }, [path, currentQueryTerm, currentQueryValue]);

        return (
            <CalculatorStoreContext.Provider value={CreditRealEstateCalculatorStore}>
                {CreditRealEstateCalculatorStore.initialized && (
                    <AnimatedComponent>
                        <CreditRealEstateCalculatorContent
                            title={title}
                            index={index}
                            gtag={gtag}
                            banner={banner}
                            infoTitle={infoTitle}
                            button={button}
                            buttonShared={buttonShared}
                        />
                    </AnimatedComponent>
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default withBlockLinks(CreditRealEstateCalculator);
