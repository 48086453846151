export const mockPromoV2Data = {
    name: 'PromoV2',
    heading: 'почта банк приветствует тебя!',
    subheading: 'поздравляем с подключением епэп',
    desc: 'Недавно ты подключил ЕПЭП (Единую простую электронную подпись) на Почте России. Теперь у тебя появилась возможность оформить продукты банка онлайн без посещения офиса.',
    image: '/img-next/png/promov2.png',
};

export const mockPromoV2DataBlue = {
    name: 'PromoV2',
    heading: 'почта банк приветствует тебя!',
    subheading: 'поздравляем с подключением епэп',
    desc: 'Недавно ты подключил ЕПЭП (Единую простую электронную подпись) на Почте России. Теперь у тебя появилась возможность оформить продукты банка онлайн без посещения офиса.',
    image: '/img-next/png/promov2.png',
    bgColor: 'blue',
    imageBottomMv: true,
};

export const mockPromoV2DataTina = {
    name: 'PromoV2',
    heading: 'Заголовок',
    subheading: 'Подзаголовок',
    desc: 'Описание, какой-то пояснительный текст',
    image: '',
};
