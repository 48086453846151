import React from 'react';

import { TinaPromoV2 } from '@/components/blocks/PromoV2/index.tina';
import { mockPromoV2Data } from '@/data/blocks/promov2';

export const promoV2Block = {
    Component: props => <TinaPromoV2 {...props} />,
    template: {
        label: 'Главный промоблок внутренних страниц версия 2',
        defaultItem: () => mockPromoV2Data,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'heading',
                component: 'text',
                label: 'Заголовок (красная линия)',
            },
            {
                name: 'subheading',
                component: 'text',
                label: 'Подзаголовок (синяя линия)',
            },
            {
                name: 'desc',
                component: 'textarea',
                label: 'Текст',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'imageBottomMv',
                component: 'toggle',
                label: 'Прижать изображение к нижнему краю',
            },
            {
                name: 'imageBig',
                component: 'toggle',
                label: 'Увеличить контейнер для изображения',
            },
            {
                component: 'select',
                name: 'bgColor',
                label: 'Цвет градиента',
                options: [
                    {
                        value: 'default',
                        label: 'Дефолтный (сиреневый)',
                    },
                    {
                        value: 'blue',
                        label: 'Синий',
                    },
                ],
            },
        ],
    },
};
