import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import { ICalculatorDisclaimer } from '@/components/blocks/calculators/InfoTitle';
import SecondaryCashCalculatorContent from '@/components/blocks/calculators/layouts/SecondaryCashCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useAppStore } from '@/context/AppStoreContext';
import SecondaryCalculatorStoreContext from '@/context/SecondaryCalculatorStoreContext';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import SecondaryCashCalculatorStore from '@/domain/calculator/SecondaryCashCalculatorStore';
import { ITermItem } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

interface ISecondaryCalculatorParams {
    filterElements: ICalculatorFilterElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
}

interface ISecondaryCalculatorBanner {
    isActive: boolean;
}

export interface ISecondaryCashCalculator {
    _template?: 'secondaryCashCalculator';
    title?: string;
    tabTitle?: string;
    params: ISecondaryCalculatorParams;
    banner?: ISecondaryCalculatorBanner;
    infoTitle?: ICalculatorDisclaimer;
    button?: {
        label: string;
        link: string;
    };
    buttonShared?: {
        isActive: boolean;
    };
    index: number;
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
    valueRate: { lowRate: number; highRate: number };
}

const SecondaryCashCalculator: React.FC<ISecondaryCashCalculator> = observer(
    ({ title, params, index, gtag, banner, button, buttonShared, infoTitle, valueRate }) => {
        const [path] = useAppStore(store => store.path);

        if (!params || !valueRate) return null;

        const SecondaryCashCalculatorInstance = useMemo(
            () => new SecondaryCashCalculatorStore(params, valueRate),
            [path]
        );

        return (
            <AnimatedComponent>
                <SecondaryCalculatorStoreContext.Provider value={SecondaryCashCalculatorInstance}>
                    <SecondaryCashCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        banner={banner}
                        button={button}
                        buttonShared={buttonShared}
                        infoTitle={infoTitle}
                    />
                </SecondaryCalculatorStoreContext.Provider>
            </AnimatedComponent>
        );
    }
);

export default withBlockLinks(SecondaryCashCalculator);
