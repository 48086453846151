import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IBiographyItem {
    image: string;
    title?: string;
    year?: string;
    desc?: string;
    pos?: number;
}

const BiographyItem: React.FC<IBiographyItem> = ({ image, year, desc, title, pos }) => (
    <AnimatedComponent className={cn.biographyItem} classNameActive={cn.animationInit} role="presentation">
        <div className={cn.biographyItemInnerWrapper}>
            <div className={cn.biographyItemContent}>
                {image && (
                    <div className={cn.biographyItemImage}>
                        <div className={cn.biographyImage}>
                            <CustomImage src={image} alt={desc} mockWidth="352" mockHeight="204" fill />
                        </div>
                    </div>
                )}
                <div className={cn.biographyItemInfo}>
                    {year && (
                        <TextField
                            className={cn.biographyItemYear}
                            text={year}
                            name={`list.${pos}.year`}
                            isHTML
                            htmlValue={year}
                        />
                    )}
                    {title && (
                        <TextField
                            className={cn.biographyItemTitle}
                            text={title}
                            name={`list.${pos}.title`}
                            isHTML
                            htmlValue={title}
                        />
                    )}
                    {desc && (
                        <TextField
                            className={cn.biographyItemDesc}
                            text={desc}
                            name={`list.${pos}.desc`}
                            isHTML
                            htmlValue={desc}
                        />
                    )}
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default BiographyItem;
