import React from 'react';

import AnimatedComponent from '@/components/Animation';
import TextField from '@/components/TextField';
import { ICreditSliderItem } from '@/types/creditSlider';

import cn from './style.module.sass';

const CreditSliderItem: React.FC<ICreditSliderItem> = ({ subtitle, title, pos, infoTitle, infoDesc }) => (
    <AnimatedComponent className={cn.creditItem} classNameActive={cn.animationInit}>
        <div className={cn.creditItemWrap}>
            <div className={cn.creditItemTop}>
                <TextField
                    text={title}
                    name={`list.[${pos}].title`}
                    isHTML
                    htmlValue={title}
                    className={cn.creditItemSubtitle}
                />
                <TextField
                    text={subtitle}
                    name={`list.[${pos}].subtitle`}
                    isHTML
                    htmlValue={subtitle}
                    className={cn.creditItemName}
                />
            </div>
            <div className={cn.creditItemBot}>
                <TextField
                    text={infoTitle}
                    name={`list.[${pos}].infoTitle`}
                    isHTML
                    htmlValue={infoTitle}
                    className={cn.creditInfoTitle}
                />
                <TextField
                    text={infoDesc}
                    name={`list.[${pos}].infoDesc`}
                    isHTML
                    htmlValue={infoDesc}
                    className={cn.creditInfoDesc}
                />
            </div>
        </div>
    </AnimatedComponent>
);

export default CreditSliderItem;
