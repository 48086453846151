import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomDatePicker from '@/components/UI/CustomDatePicker';
import Title from '@/components/UI/Title';
import { IAllExchangeRatesItem } from '@/types/allExchangeRates/item';
import { IError } from '@/types/data';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IAllExchangeRates {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'allExchangeRates';
    /**
     * Заголовок
     */
    name: string;
    /**
     * Список курсов валют
     */
    list: IAllExchangeRatesItem[];
}

export interface IAllExchangeRatesResponse {
    data: {
        data: IAllExchangeRatesItem[];
        errors: IError[];
    };
}

const AllExchangeRates: React.FC<IAllExchangeRates> = ({ name, list }) => {
    const [exchangeRates, setExchangeRates] = useState(list);
    const [isRequestGoing, setIsRequestGoing] = useState(false);
    const [currentDate, setCurrentDate] = useState<Date | string>(new Date());

    const fetchData = async (date: string | Date = new Date()) => {
        setIsRequestGoing(true);

        const correctDate = format(new Date(date), 'yyyy-MM-dd');

        try {
            const response: IAllExchangeRatesResponse = await apiRequest(`/currencies?date=${correctDate}`);

            const {
                data: { data: fetchedList, errors },
            } = response;

            if (errors?.length > 0) throw new Error(JSON.stringify(errors));

            setCurrentDate(date);
            setExchangeRates(fetchedList);
        } catch (e) {
            console.error(e);
        }
        setIsRequestGoing(false);
    };

    const onChangeDatePicker = async value => {
        await fetchData(value);
    };

    useEffect(() => {
        if (!list?.length) fetchData().catch(console.error);
    }, [list]);

    if (!exchangeRates?.length) return null;

    return (
        <div className={clsx(cn.allExchangeRates, 'section')}>
            <div className={cn.wrap}>
                <Title
                    level={3}
                    title={name}
                    customClass={cn.mainTitle}
                    name="name"
                    isHTML
                    htmlValue={name || 'Все курсы валют'}
                />
                <div className={cn.datepicker}>
                    <CustomDatePicker onChangeHandler={onChangeDatePicker} isLoading={isRequestGoing} />
                </div>
                <AnimatedComponent className={cn.table} classNameActive={cn.animationInit} key={currentDate.toString()}>
                    <div className={cn.tableHead}>
                        <div className={cn.row}>
                            <div className={clsx(cn.col, cn.colFirst)}>Код валюты</div>
                            <div className={clsx(cn.col, cn.colSecond)}>Наименование валюты</div>
                            <div className={cn.col}>Покупка ₽</div>
                            <div className={cn.col}>Продажа ₽</div>
                        </div>
                    </div>
                    <div className={cn.tableBody}>
                        {exchangeRates.map((item, index) => (
                            <div className={cn.row} key={`key-${index}`}>
                                <div className={clsx(cn.col, cn.colFirst)}>{item.title}</div>
                                <div className={clsx(cn.col, cn.colSecond)}>{item.desc}</div>
                                <div className={cn.col}>
                                    <div className={clsx(cn.innerWrapper, item.buy.flag && cn.flag)}>
                                        {item.buy.value}
                                    </div>
                                </div>
                                <div className={cn.col}>
                                    <div className={clsx(cn.innerWrapper, item.sale.flag && cn.flag)}>
                                        {item.sale.value}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default AllExchangeRates;
