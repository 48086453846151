import clsx from 'clsx';
import React, { FC } from 'react';

import QuoteHrItem from '@/components/blocks/QuoteHr/item';
import { IQuoteHrItem } from '@/types/quoteHr/item';

import cn from './style.module.sass';

export interface IQuoteHr {
    _template?: 'quoteHr';
    items: IQuoteHrItem[];
    tinaPrefix?: string;
}

const QuoteHr: FC<IQuoteHr> = ({ items, tinaPrefix = '' }) => {
    if (!items || items?.length <= 0) return null;

    return (
        <div className={clsx(cn.quoteHr, 'section')}>
            <div className={cn.wrap}>
                <div className={cn.grid}>
                    {items?.map((item, index) => (
                        <QuoteHrItem {...item} pos={index} tinaPrefix={tinaPrefix} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuoteHr;
