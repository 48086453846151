import dynamic from 'next/dynamic';
import React from 'react';

import { IDefaultBlocksConfig } from '@/types/tina/config';

export const defaultFrameConfig: IDefaultBlocksConfig = {
    cardToCard: dynamic(() => import('../../components/blocks/frames/CardToCard')) as React.FC,
    loanRepayment: dynamic(() => import('../../components/blocks/frames/LoanRepayment')) as React.FC,
    cashLoan: dynamic(() => import('../../components/blocks/frames/CashLoan')) as React.FC,
    vddohod: dynamic(() => import('../../components/blocks/frames/Vddohod')) as React.FC,
    depositApplication: dynamic(() => import('../../components/blocks/frames/DepositApplication')) as React.FC,
    sberschetOpen: dynamic(() => import('../../components/blocks/frames/SberschetOpen')) as React.FC,
    pushkinskayaCard: dynamic(() => import('../../components/blocks/frames/PushkinskayaCard')) as React.FC,
    creditCard: dynamic(() => import('../../components/blocks/frames/CreditCard')) as React.FC,
    creditForm: dynamic(() => import('../../components/blocks/frames/CreditForm')) as React.FC,
    creditCardTypecredit: dynamic(() => import('../../components/blocks/frames/CreditCardTypeCredit')) as React.FC,
    universalExternalIframe: dynamic(
        () => import('../../components/blocks/frames/UniversalExternalIframe')
    ) as React.FC,
    universalExternalFormScript: dynamic(
        () => import('../../components/blocks/frames/UniversalExternalFormScript')
    ) as React.FC,
    crmOffers: dynamic(() => import('../../components/blocks/frames/CrmOffers')) as React.FC,
    pensionTransferV2: dynamic(() => import('@/components/blocks/frames/PensionTransferV2')) as React.FC,
    iosAppLink: dynamic(() => import('@/components/blocks/frames/IosAppLink')) as React.FC,
};
