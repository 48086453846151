import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';

import CustomSlider from '@/components/CustomSlider';
import { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import TermItem from '../TermItem';
import cn from './style.module.sass';

interface IProps extends ITermField {
    gtag?: GTagEvent;
    className?: string;
    noFixedWidth?: boolean;
}

const TermsList: React.FC<IProps> = observer(
    ({ activeTerm, allTerms, setActiveTerm, gtag, className, noFixedWidth }) => {
        const handleClick = useCallback(
            (term: ITermItem) => {
                if (term.disabled) return;
                if (gtag?.action) event(gtag);
                setActiveTerm(term);
            },
            [gtag]
        );

        return (
            <div className={cn.sliderWrapper}>
                <CustomSlider className={clsx(cn.wrapper, className)}>
                    {allTerms?.map(term => (
                        <TermItem
                            key={term.id}
                            term={term}
                            isActive={activeTerm.id === term.id}
                            handleClick={handleClick}
                            noFixedWidth={noFixedWidth}
                        />
                    ))}
                </CustomSlider>
            </div>
        );
    }
);

export default TermsList;
