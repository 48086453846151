import React from 'react';

import { TinaSearchResultsPage } from '@/components/blocks/SearchResultsPage/index.tina';

export const searchResultsPageBlock = {
    Component: props => <TinaSearchResultsPage {...props} />,
    template: {
        label: 'Страница с результатами поиска',
        fields: [],
    },
};
