import clsx from 'clsx';
import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IPromoV2 {
    _template: 'promov2';
    heading?: string;
    subheading?: string;
    desc?: string;
    image?: string;
    imageBottomMv?: boolean;
    imageBig?: boolean;
    bgColor?: string;
}

const PromoV2: FC<IPromoV2> = ({
    heading,
    subheading,
    desc,
    image,
    imageBottomMv = false,
    imageBig = false,
    bgColor = 'default',
}) => (
    <div className={clsx(cn.wrapper, bgColor === 'blue' && cn.wrapperBlue)}>
        <div className={clsx(cn.grid, imageBottomMv && cn.imgBottom)}>
            <div className={clsx(cn.cellInfo, cn.cell)}>
                {heading && (
                    <div className={clsx(cn.headingWrap)}>
                        <TextField text={heading} name="heading" customTag="h1" className={cn.heading} isTextArea />
                    </div>
                )}
                {subheading && (
                    <div>
                        <div className={cn.subheadingWrap}>
                            <TextField text={subheading} name="subheading" className={cn.subheading} isTextArea />
                        </div>
                    </div>
                )}
                {desc && <TextField className={clsx(cn.desc)} name="desc" text={desc} isHTML htmlValue={desc} />}
            </div>
            {image && (
                <div
                    className={clsx(cn.cell, cn.cellImg, imageBottomMv && cn.cellImgBottom, imageBig && cn.cellImgBig)}
                >
                    <div className={cn.imgWrap}>
                        <CustomImage src={image} alt={heading} fill />
                    </div>
                </div>
            )}
        </div>
    </div>
);

export default PromoV2;
