import { makeAutoObservable } from 'mobx';

import RangeInput from '@/domain/calculator/blocksStore/Range';
import { IFieldsConfig } from '@/types/calculator/config';
import { IConfigCalculator, ICreditCalculatorStore, IRate } from '@/types/calculator/creditCalculator';

class CreditCalculatorStore implements ICreditCalculatorStore {
    public resultConfig: IConfigCalculator = {};

    public initialized = false;

    public rate: IRate;

    constructor() {
        makeAutoObservable(this);
    }

    public get percent(): number {
        const currentRate = this.rate?.ranges?.find(
            range => range.min <= +this.resultConfig.range1.value && range.max >= +this.resultConfig.range1.value
        );

        return currentRate?.value;
    }

    public get paymentSum() {
        const sum = Math.ceil((this.resultConfig.range1.value * (this.percent / 100)) / 100) * 100;

        return this.formatValueHandle(`${sum}`);
    }

    private formatValueHandle = (value: string) => `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}₽`;

    public init = (fields: IFieldsConfig, rate: IRate) => {
        fields?.rangeSlider.forEach(rangeConfig => {
            this.resultConfig[rangeConfig.name] = new RangeInput(rangeConfig, false, true);
        });

        this.rate = rate;

        this.initialized = true;
    };
}

export default CreditCalculatorStore;
