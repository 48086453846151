export const mockOffersCreditsBlockData = {
    list: [
        {
            title: 'Другие предложения по кредиту наличными',
            categories: [
                {
                    categoriesList: [
                        {
                            text: 'Кредит наличными по паспорту',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными без истории',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными без отказа',
                            link: '#',
                        },
                        {
                            text: 'Без поручителей',
                            link: '#',
                        },
                        {
                            text: 'Без справки о доходах',
                            link: '#',
                        },
                        {
                            text: 'Без визита в банк',
                            link: '#',
                        },
                        {
                            text: 'Без справки о доходах',
                            link: '#',
                        },
                        {
                            text: 'По низкой ставке',
                            link: '#',
                        },
                        {
                            text: 'Срочный кредит наличными',
                            link: '#',
                        },
                        {
                            text: 'Кредит на карту',
                            link: '#',
                        },
                        {
                            text: 'На карту без отказа',
                            link: '#',
                        },
                    ],
                },
                {
                    name: 'По сроку',
                    categoriesList: [
                        {
                            text: 'Кредит наличными на 3 года',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными на 5 лет',
                            link: '#',
                        },
                    ],
                },
                {
                    name: 'По возрасту',
                    categoriesList: [
                        {
                            text: 'Кредит наличными с 18 лет',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными с 19 лет',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными с 20 лет',
                            link: '#',
                        },
                        {
                            text: 'Кредит наличными с 21 лет',
                            link: '#',
                        },
                    ],
                },
                {
                    name: 'По цели',
                    categoriesList: [
                        {
                            text: 'На лечение',
                            link: '#',
                        },
                        {
                            text: 'На отпуск',
                            link: '#',
                        },
                        {
                            text: 'На ремонт',
                            link: '#',
                        },
                        {
                            text: 'На свадьбу',
                            link: '#',
                        },
                    ],
                },
                {
                    name: 'По сумме',
                    categoriesList: [
                        {
                            text: 'Кредит на 1000000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 100000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1050000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1100000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1150000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1200000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1250000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1300000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1350000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1400000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1500000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 150000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1600000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1700000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1800000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1900000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2000000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 200000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2100000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2200000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2300000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 1800000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2400000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2500000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 250000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2600000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2700000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2800000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 2900000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 3000000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 300000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 350000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 400000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 450000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 500000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 50000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 550000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 55000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 600000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 60000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 650000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 700000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 750000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 800000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 850000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 900000 рублей',
                            link: '#',
                        },
                        {
                            text: 'Кредит на 950000 рублей',
                            link: '#',
                        },
                    ],
                },
            ],
        },
    ],
};

export const mockOffersCreditsBlockDataTina = {
    list: [
        {
            title: 'Заголовок',
            categories: [
                {
                    name: 'Заголовок',
                    categoriesList: [
                        {
                            text: 'Заголовок',
                            link: '#',
                        },
                    ],
                },
            ],
        },
    ],
};
