import React from 'react';

import { TinaPaymentGroup } from '@/components/blocks/frames/_deprecated/PaymentGroup/index.tina';
import { tinaFrameConfig } from '@/tina/config/frame.tina.config';
import { getTemplates } from '@/tina/utils/getTemplates';

export const paymentGroupBlock = {
    Component: props => <TinaPaymentGroup {...props} />,
    template: {
        label: 'Iframe блоки',
        defaultItem: () => ({
            name: 'Онлайн переводы/погашения кредитов',
            blocks: [
                {
                    _template: 'cardToCard',
                    title: 'Онлайн переводы',
                },
                {
                    _template: 'loanRepayment',
                    title: 'Погашение кредита',
                },
            ],
        }),
        fields: [
            {
                label: 'Список iframe блоков',
                component: 'blocks',
                name: 'blocks',
                templates: getTemplates(tinaFrameConfig),
            },
        ],
    },
};
