import clsx from 'clsx';
import React, { useState } from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useAppStore } from '@/context/AppStoreContext';
import { useFixHtml } from '@/hooks/useFixHtml';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IStore {
    list: IStoreItem[];
}

interface IStoreItem {
    image: string;
    alt: string;
    link: string;
}

export interface IInfoBgBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'infoBgBlock';
    /**
     * Список категорий
     */
    titleBlock?: string;
    image: string;
    imageLink?: string;
    title: string;
    accentText: string;
    botLink?: string;
    botLinkHref?: string;
    desc?: string;
    lineToPopup?: string;
    modal?: { modalTitle: string; modalDescription: string };
    smallDesc?: string;
    link: string;
    linkName: string;
    imageTop?: boolean;
    gtag?: GTagEvent;
    size?: string;
    /**
     * Позиции изображений
     */
    sideImg: 'left' | 'right';
    positionImg: 'top' | 'center' | 'bottom';
    positionMobileImg?: 'left' | 'center' | 'right';
    color?: 'default' | 'white';
    bg?: 'default' | string;
    store?: IStore;
}

const InfoBgBlock: React.FC<IInfoBgBlock> = ({
    image,
    imageLink,
    title,
    desc,
    smallDesc,
    lineToPopup,
    modal,
    link,
    linkName,
    gtag,
    sideImg = 'left',
    positionImg = 'center',
    positionMobileImg = 'left',
    size,
    titleBlock,
    accentText,
    botLink,
    botLinkHref,
    color = 'default',
    bg,
    store,
    imageTop = false,
}) => {
    // eslint-disable-next-line no-shadow
    const [isTinaView] = useAppStore(store => store.isTinaView);

    const [showModal, setShowModal] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();

    const handleOpen = () => {
        fix();
        setShowModal(true);
    };

    const handleClose = () => {
        unfix();
        setShowModal(false);
    };

    return (
        <AnimatedComponent className={clsx(cn.infoBlock, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.infoBlockWrap}>
                {titleBlock && (
                    <TextField
                        text={titleBlock}
                        className={cn.mainTitle}
                        name="titleBlock"
                        customTag="h3"
                        isHTML
                        htmlValue={titleBlock}
                    />
                )}

                <div
                    className={clsx(
                        cn.infoBlockGrid,
                        sideImg === 'right' && cn.right,
                        positionImg === 'bottom' && cn.bottom,
                        positionImg === 'top' && cn.top,
                        size === 'small' && cn.small,
                        size === 'smallHeight' && cn.smallHeight,
                        color === 'white' && cn.colorWhite
                    )}
                    style={{
                        background: bg || null,
                    }}
                >
                    <div className={clsx(cn.infoBlockItem, cn.infoBlockItemImg)}>
                        <div className={clsx(cn.infoBlockImage)}>
                            <div
                                className={clsx(
                                    cn.infoBlockImageContainer,
                                    positionMobileImg === 'center' && cn.positionMobileImg
                                )}
                            >
                                {image && !imageLink && size === 'small' && (
                                    <CustomImage src={image} alt={title} width={247} height={226} />
                                )}
                                {image && !imageLink && size !== 'small' && (
                                    <CustomImage src={image} alt={title} fill />
                                )}
                                {imageLink && !isTinaView && size === 'small' && (
                                    <CustomImage src={imageLink} alt={title} width={247} height={226} />
                                )}
                                {imageLink && !isTinaView && size !== 'small' && (
                                    <CustomImage src={imageLink} alt={title} fill />
                                )}
                                {imageLink && isTinaView && (
                                    <div className="promoServiceDesc">
                                        предпросмотр картинки с продуктовым параметром невозможен
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={clsx(cn.infoBlockItem, cn.infoBlockItemContent, imageTop && cn.mobileOrder)}>
                        <div className={clsx(cn.infoBlockContent, link && cn.infoBlockContentSpaceBetween)}>
                            <div className={cn.infoBlockTop}>
                                {title && (
                                    <TextField
                                        text={title}
                                        className={cn.infoBlockTitle}
                                        name="title"
                                        htmlValue={title}
                                        isHTML
                                    />
                                )}
                                {accentText && (
                                    <TextField
                                        text={accentText}
                                        className={cn.infoBlockAccent}
                                        name="accentText"
                                        htmlValue={accentText}
                                        isHTML
                                    />
                                )}
                                {desc && (
                                    <TextField
                                        text={desc}
                                        isHTML
                                        htmlValue={desc}
                                        name="desc"
                                        className={cn.infoBlockDesc}
                                    />
                                )}

                                {lineToPopup && (
                                    <TextField
                                        text={lineToPopup}
                                        isHTML
                                        htmlValue={lineToPopup}
                                        name="lineToPopup"
                                        className={clsx(cn.infoBlockDesc, cn.popupLink)}
                                        onClick={handleOpen}
                                    />
                                )}

                                {botLink && (
                                    <TextField
                                        text={botLink}
                                        className={cn.infoBlockLink}
                                        customTag="a"
                                        href={botLinkHref}
                                        name="botLink"
                                        htmlValue={botLink}
                                        isHTML
                                    />
                                )}
                            </div>
                            {store?.list?.length > 0 && (
                                <div className={cn.infoBlockTop}>
                                    <div className={cn.store}>
                                        <div className={cn.storeWrap}>
                                            <div className={cn.storeGrid}>
                                                {/* eslint-disable-next-line no-shadow */}
                                                {store?.list?.map(({ link, alt, image }, index) => (
                                                    <div className={cn.storeItem} key={index}>
                                                        {link ? (
                                                            <a
                                                                href={link}
                                                                target="_blank"
                                                                className={cn.storeLink}
                                                                rel="noreferrer"
                                                            >
                                                                <CustomImage
                                                                    src={image}
                                                                    alt={alt}
                                                                    width={131}
                                                                    height={38}
                                                                />
                                                            </a>
                                                        ) : (
                                                            <div className={cn.storeLink}>
                                                                <CustomImage
                                                                    src={image}
                                                                    alt={alt}
                                                                    width={131}
                                                                    height={38}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {link && (
                                <div className={clsx(cn.infoBlockBot, cn.hideOnMobile)}>
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={link}
                                        label={linkName}
                                        size="full"
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                    />
                                </div>
                            )}
                            {smallDesc && (
                                <TextField
                                    className={cn.smallDesc}
                                    text={smallDesc}
                                    name="smallDesc"
                                    isHTML
                                    htmlValue={smallDesc}
                                />
                            )}
                        </div>
                    </div>
                    {link && (
                        <div
                            className={clsx(
                                cn.infoBlockItem,
                                cn.infoBlockItemBtn,
                                cn.showOnMobile,
                                imageTop && cn.mobileOrder
                            )}
                        >
                            <Button
                                variant="btnBlue"
                                type="link"
                                href={link}
                                label={linkName}
                                size="full"
                                onClick={() => {
                                    if (gtag?.action) event(gtag);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            <ModalWrapper handleClose={handleClose} isOpen={showModal} isStandartModalWidth={false}>
                <div className={cn.modalWrapper}>
                    {modal?.modalTitle && (
                        <div className={clsx(cn.modalTitle)} dangerouslySetInnerHTML={{ __html: modal.modalTitle }} />
                    )}

                    {modal?.modalDescription && (
                        <div
                            className={clsx(cn.modalDesc)}
                            dangerouslySetInnerHTML={{ __html: modal.modalDescription }}
                        />
                    )}
                </div>
            </ModalWrapper>
        </AnimatedComponent>
    );
};

export default withBlockLinks(InfoBgBlock);
