import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, useMemo } from 'react';

import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import IconFire from '@/components/UI/IconFire';
import { useResize } from '@/hooks/useResize';
import { TABLET_WIDTH } from '@/utils/constants';

import ProductsAndServicesArrow from './arrowSvg';
import cn from './style.module.sass';

export interface IProductsAndServicesCardProps {
    isActual?: boolean;
    actualColor?: 'red' | 'white';
    width?: 'large' | 'medium' | 'small';
    color?: 'green' | 'orange' | 'peach' | 'bright-purple' | 'blue' | 'gray';
    cardTitle?: string;
    cardDescription?: string;
    linkStyle?: 'button' | 'link' | 'none';
    linkHref?: string;
    linkText?: string;
    isLineNeeded?: boolean;
    desktopImage?: string;
    mobileImage?: string;
    imagePosition?: 'left' | 'right' | 'middle';
    pos?: number;
    size?: 'large' | 'small' | 'extra-small' | 'full';
    buttonStyle?: 'lightBlueWithWhite' | 'whiteWithBlack';
}

const ProductsAndServicesCard: FC<IProductsAndServicesCardProps> = ({
    isActual,
    actualColor,
    width = 'small',
    color = 'gray',
    cardTitle,
    cardDescription,
    linkStyle,
    linkHref = '#',
    isLineNeeded = false,
    linkText,
    desktopImage,
    mobileImage,
    imagePosition = 'right',
    pos,
    size,
    buttonStyle,
}) => {
    const windowWidth = useResize();

    const isDesktop = windowWidth > TABLET_WIDTH;
    const isMobile = !isDesktop;
    const image = isDesktop ? desktopImage : mobileImage;

    const imagePositionClass = useMemo(() => {
        const positions = {
            left: cn.cardImageLeft,
            right: cn.cardImageRight,
            middle: cn.cardImageCenter,
        };
        return positions[imagePosition];
    }, [imagePosition]);

    const cardSizeClass = useMemo(() => {
        const sizes = {
            large: cn.cardLarge,
            medium: cn.cardMedium,
            small: cn.cardSmall,
        };
        return sizes[width];
    }, [width]);

    const imageSizeClass = useMemo(() => {
        const sizes = {
            large: cn.imageLarge,
            medium: cn.imageMedium,
            small: cn.imageSmall,
        };
        return sizes[width];
    }, [width]);

    const isLink = (linkStyle === 'link' && linkText) || (isMobile && linkText);
    const isButton = linkStyle === 'button' && linkText && !isMobile;

    return (
        <div className={clsx(cn.card, cardSizeClass, cn[`cardColor-${color}`], isLineNeeded && cn.cardLine)}>
            <div className={clsx(cn.fire, isActual && cn.fireVisible)}>
                <IconFire
                    customClass={
                        {
                            red: cn.cardActualRed,
                            white: cn.cardActualWhite,
                        }[actualColor]
                    }
                />
            </div>
            <div className={cn.cardTextContent}>
                <TextField text={cardTitle} name={`list.[${pos}].cardTitle`} className={cn.cardTitle} isTextArea />
                <TextField
                    text={cardDescription}
                    name={`list.[${pos}].cardDescription`}
                    className={cn.cardDescription}
                    isTextArea
                />
                {isLink && (
                    <Link className={cn.cardLink} href={linkHref}>
                        {linkText} <ProductsAndServicesArrow customClass={cn.cardLinkIcon} />
                    </Link>
                )}
            </div>
            {isButton && (
                <Button
                    variant="btnBlue"
                    size={size === 'extra-small' ? 'xsmall' : size}
                    type="link"
                    href={linkHref}
                    customClass={clsx(
                        cn.lightBlueWithWhite,
                        cn.cardButton,
                        buttonStyle === 'whiteWithBlack' && cn.whiteWithBlack
                    )}
                    label={linkText}
                />
            )}
            {image && (
                <Image
                    className={clsx(cn.cardImage, imagePositionClass, imageSizeClass)}
                    alt=""
                    src={image}
                    width={300}
                    height={300}
                />
            )}
        </div>
    );
};

export default ProductsAndServicesCard;
