import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { optionsV2 } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormV2 {
    _template: 'feedbackFormVTwo';
    title?: string;
    description?: string;
    successTitle?: string;
}

const FeedbackFormVTwo: React.FC<IFeedbackFormV2> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormVTwo',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsV2}
        title={title}
        description={description}
        descFlag={false}
        successTitle={successTitle}
    />
);

export default withBlockLinks(FeedbackFormVTwo);
