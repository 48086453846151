import clsx from 'clsx';
import React, { FC, useMemo, useRef } from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import PhotoSliderItem, { IPhotoSliderItem } from '@/components/blocks/PhotoSlider/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useResize } from '@/hooks/useResize';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IPhotoSlider {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template?: 'photoSlider';
    /**
     * Список элементов слайдера.
     */
    list: IPhotoSliderItem[];
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 7) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 7) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

SwiperCore.use([Pagination]);

const PhotoSlider: React.FC<IPhotoSlider> = ({ gtag, list }) => {
    const width = useResize();

    const swiperRef = useRef(null);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return list.length < 6 ? list.length : 6;
        if (width < MOBILE_SM_WIDTH) return 1.5;
        if (width < MOBILE_WIDTH) return 2.5;
        if (width < TABLET_SMALL_WIDTH) return 3.2;
        return list.length < 6 ? list.length : 6;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel', 'photoSlider'),
        on: {
            init() {
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 200);
            },
        },
        lazy: true,
        watchOverflow: true,
        watchSlidesProgress: true,
        freeMode: true,
        pagination: { clickable: true },
    };

    const options = {
        settings: {
            overlayColor: 'rgba(48, 52, 64, 0.9)',
        },
        caption: {
            captionColor: '#ffffff',
            captionFontFamily: 'Roboto, sans-serif',
            captionFontWeight: '400',
        },
    };

    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.current, 'section')}>
            <div className={cn.currentWrap}>
                <AnimatedComponent className={cn.currentGrid} classNameActive={cn.animationInit}>
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <Swiper {...settings} ref={swiperRef}>
                                {list?.map(({ image, desc }, index) => (
                                    <SwiperSlide key={index}>
                                        <PhotoSliderItem image={image} desc={desc} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <PrevArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                            <NextArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                        </SRLWrapper>
                    </SimpleReactLightbox>
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default withBlockLinks(PhotoSlider);
