import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import PromoButton from '@/components/blocks/Promo/PromoButton';
import PromoButtons from '@/components/blocks/Promo/PromoButtons';
import PromoDots from '@/components/blocks/Promo/PromoDots';
import PromoTopWithoutLines from '@/components/blocks/Promo/PromoTopWithoutLines';
import PromoVideo from '@/components/blocks/Promo/PromoVideo';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';
import { useAppStore } from '@/context/AppStoreContext';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useMobileWidth } from '@/hooks/useMedia';
import { IBanner, IPromoEntry, IPromoItem } from '@/types/promo/item';

// Стили -> apps/front/src/assets/styles/base/promo.sass

export interface IPromoSuper {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'promoSuper';
    /**
     * Список кнопок с анонсом. Пока есть непонятки с синей плашкой, так как она располагаться может в любом месте.
     * Сделать markdown?
     */
    banner: IBanner;
    promo?: IPromoItem[];
    entry?: IPromoEntry;
    hideBreadcrumbs?: boolean;
}

const PromoSuper: FC<IPromoSuper> = ({ banner, promo, entry, hideBreadcrumbs }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);
    const {
        image,
        mobileImage,
        imageLink,
        imageLinkMobile,
        imageTopMv,
        imageBottomMv = false,
        imageBig = false,
        imageContainerFull = false,
        mobileTextTop = false,
    } = banner;

    const promoRef = useRef<HTMLDivElement>(null);
    const { setPromoHeight } = useLayoutStore();

    useEffect(() => {
        if (!promoRef.current?.offsetHeight) return;

        setPromoHeight(promoRef.current?.offsetHeight);
    }, [promoRef, setPromoHeight]);

    const isMobile = useMobileWidth();

    const [currentImage, setCurrentImage] = useState<string>();
    const [isLinkedImage, setIsLinkedImage] = useState<boolean>(false);

    useEffect(() => {
        if (isMobile) {
            const actualImage = imageLinkMobile || mobileImage || imageLink || image;
            setIsLinkedImage(actualImage === imageLinkMobile || actualImage === imageLink);
            setCurrentImage(actualImage);
        } else {
            const actualImage = imageLink?.length > 0 ? imageLink : image;
            setIsLinkedImage(actualImage?.length > 0 && actualImage === imageLink);
            setCurrentImage(actualImage);
        }
    }, [isMobile, imageLinkMobile, mobileImage, imageLink, image]);

    return (
        <AnimatedComponent>
            <div className={clsx('promo promo--secondary promo--super', banner?.color === 'white' && 'promoWhite')}>
                <div className="promoWrap">
                    <div className="promoGrid" ref={promoRef}>
                        <div
                            className={clsx('promoItem', banner?.backgroundImage && 'promoItemBg')}
                            style={{
                                background: banner?.activeGradient ? '#ebf1fb' : banner?.background,
                            }}
                        >
                            {banner?.backgroundImage && !!banner?.activeImage && !banner?.activeGradient && (
                                <CustomImage src={banner?.backgroundImage} fill priority loading="eager" alt="" />
                            )}
                            <div className={clsx('promoItemGrid', promo?.length <= 0 && 'promoItemGridWithOutPromo')}>
                                <div
                                    className={clsx(
                                        'promoItemContent',
                                        ' promoSuperContent',
                                        mobileTextTop && 'promoItemContentMobileTop'
                                    )}
                                >
                                    <PromoTopWithoutLines
                                        line={banner?.line}
                                        hideBreadcrumbs={hideBreadcrumbs}
                                        entry={entry}
                                        description={banner?.description}
                                    />
                                    <div className={clsx('promoItemBot', !mobileTextTop && ' promoItemBotMobileFirst')}>
                                        <div className="promoBtn">
                                            {banner?.buttons?.length > 0 && (
                                                <div className="promoBtnInnerWrapper promoSuperBtnInnerWrapper">
                                                    {banner.buttons.map((button, index) => (
                                                        <PromoButton
                                                            key={index}
                                                            {...button}
                                                            pos={index}
                                                            animation={false}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={clsx(
                                        'promoItemImage',
                                        'promoSuperItemImage',
                                        imageContainerFull && 'promoSuperItemImageFull',
                                        entry?.text && 'promoItemImageEntry'
                                    )}
                                >
                                    <div
                                        className={clsx('promoSuperImage', imageContainerFull && 'promoSuperImageFull')}
                                    >
                                        {isLinkedImage && isTinaView && (
                                            <div className="promoServiceDesc">
                                                предпросмотр картинки с продуктовым параметром невозможен
                                            </div>
                                        )}
                                        {currentImage && !isLinkedImage && (
                                            <CustomImage
                                                src={currentImage}
                                                alt={banner?.line?.title}
                                                mockWidth={480}
                                                mockHeight={420}
                                                width={imageContainerFull ? 560 : 332}
                                                height={imageContainerFull ? 500 : 330}
                                                priority
                                                loading="eager"
                                            />
                                        )}
                                        {isLinkedImage && !isTinaView && (
                                            <CustomImage
                                                src={currentImage}
                                                alt={banner?.line?.title}
                                                fill
                                                mockWidth={480}
                                                mockHeight={420}
                                                priority
                                                loading="eager"
                                            />
                                        )}

                                        {banner.withAnimationImage && (
                                            <div className="promoImageAnimation">
                                                <PromoVideo
                                                    animationImageWebm={banner?.animationImageWebm}
                                                    animationImage={banner?.animationImage}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {entry?.text &&
                                        (entry.color === 'white' ? (
                                            <div className={clsx('promoEntry', 'promoEntryNoMobail')}>
                                                <Button
                                                    variant="btnWhiteBorder"
                                                    type="link"
                                                    href={entry?.link}
                                                    label={entry?.text}
                                                    size="small"
                                                    customClass="full"
                                                    animation={false}
                                                />
                                            </div>
                                        ) : (
                                            <div className={clsx('promoEntry', 'promoEntryNoMobail')}>
                                                <Button
                                                    variant="btnBlueBorder"
                                                    type="link"
                                                    href={entry?.link}
                                                    label={entry?.text}
                                                    size="small"
                                                    customClass="full"
                                                    animation={false}
                                                />
                                            </div>
                                        ))}
                                </div>
                                {banner?.buttons?.length > 0 && mobileTextTop && (
                                    <div className="promoSuperBtnMobail">
                                        <PromoButtons buttons={banner?.buttons} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {promo && promo?.length > 0 && <PromoDots promo={promo} sizeIcon={50} />}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(observer(PromoSuper));
