import React from 'react';

import { TinaMobileAppBanner } from '@/components/blocks/MobileAppBanner/index.tina';
import { mockMobileAppBannerBlockData } from '@/data/blocks/mobileAppBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const mobileAppBannerBlock = {
    Component: props => <TinaMobileAppBanner {...props} />,
    template: {
        label: 'Баннер мобильного приложения',
        defaultItem: () => mockMobileAppBannerBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                required: true,
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'img',
                component: 'image',
                label: 'Иконка',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'buttons',
                component: 'group-list',
                label: 'Кнопки',
                defaultItem: () => mockMobileAppBannerBlockData.buttons[0],
                itemProps: (item: { label: string }, index: number) => ({
                    key: index,
                    label: item.label,
                }),
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
