import React from 'react';

import DrawParticipationList from '@/components/blocks/draw/participation-list/index';
import { mockParticipationList } from '@/components/blocks/draw/participation-list/mock';
import withBlocksControl from '@/components/HOC/withBlocksControl';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const TinaParticipationList = withBlocksControl(DrawParticipationList);

export const drawParticipationListBlock = {
    Component: props => <TinaParticipationList {...props} />,
    template: {
        label: 'Блок участия в розыгрыше',
        defaultItem: () => mockParticipationList,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Текст',
            },
            {
                label: 'Список элементов',
                name: 'items',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockParticipationList.items[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'chances',
                        component: HtmlEditorFeatured,
                        label: 'Текст количества шансов',
                    },
                ],
            },
            {
                name: 'link',
                component: HtmlEditorFeatured,
                label: 'Ссылка',
            },
        ],
    },
};
