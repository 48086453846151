export const filterElements = {
    label: 'Список элементов',
    name: 'filterElements',
    component: 'group-list',
    itemProps: (item: { label: string; id: number }, index: number) => ({
        key: index,
        label: item.label ? `${item.id}) ${item.label}` : 'Элемент',
    }),
    defaultItem: () => ({
        value: 0,
        step: 5000,
        percent: 0,
        label: '0',
        id: 100,
    }),

    fields: [
        {
            name: 'label',
            component: 'text',
            label: 'Название риски',
        },
        {
            name: 'value',
            component: 'number',
            label: 'Значение',
        },
        {
            name: 'id',
            component: 'number',
            label: 'Номер - ID - обязательно уникальное цифровое значение для элемента в массиве (элементы с совпадающими ID автоматически не выводятся)',
        },
        {
            name: 'percent',
            component: 'number',
            label: 'Процент расположения риски на шкале (0 - 100)',
        },
        {
            name: 'step',
            component: 'number',
            label: 'Размер шага',
        },
    ],
};
