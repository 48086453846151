import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import ModalItem from '@/components/blocks/aboutPB/QuotePerson/QuotePersonModal';
import CustomImage from '@/components/CustomImage';
import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import { IQuotePersonItem } from '@/types/quotePerson/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const QuotePersonItem: React.FC<IQuotePersonItem & { pos: number }> = ({
    img,
    personName,
    personPosition,
    text,
    pos,
    gtag,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [enableOpening, setEnableOpening] = useState(true);

    const handleOpen = () => {
        if (enableOpening) setIsOpen(true);
    };

    const handleClose = () => {
        setEnableOpening(false);
        setIsOpen(false);
        setTimeout(() => {
            setEnableOpening(true);
        }, 1000);
    };
    return (
        <AnimatedComponent
            className={cn.person}
            classNameActive={cn.animationInit}
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
        >
            <div className={cn.wrapper} onClick={handleOpen} role="presentation">
                <div className={cn.top}>
                    {img && (
                        <div className={cn.imageWrapper}>
                            <div className={cn.image}>
                                <CustomImage src={img} alt={personName} mockWidth="96" mockHeight="96" fill />
                            </div>
                        </div>
                    )}
                    <div className={cn.info}>
                        <TextField text={personName} name={`list.[${pos}].personName`} className={cn.name} isTextArea />
                        {personPosition && (
                            <TextField
                                text={personPosition}
                                name={`list.[${pos}].personPosition`}
                                className={cn.position}
                                isTextArea
                            />
                        )}
                    </div>
                </div>
                <div className={cn.bottom}>
                    <TextField text={text} name={`list.[${pos}].text`} className={cn.text} isHTML htmlValue={text} />
                </div>
            </div>
            <ModalWrapper
                isOpen={isOpen}
                handleClose={handleClose}
                handleOpen={handleOpen}
                className={cn.otherView}
                bgClassName={cn.otherViewBg}
            >
                {isOpen && (
                    <ModalItem
                        img={img}
                        personName={personName}
                        personPosition={personPosition}
                        text={text}
                        pos={pos}
                    />
                )}
            </ModalWrapper>
        </AnimatedComponent>
    );
};

export default QuotePersonItem;
