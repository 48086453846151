import React, { FC, useEffect, useMemo, useState } from 'react';

import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { TABLET_WIDTH } from '@/utils/constants';

import ProductsAndServicesCard, { IProductsAndServicesCardProps } from './ProductsAndServicesCard';
import cn from './style.module.sass';

export interface IProductsAndServicesProps {
    _template: 'productsAndServices';
    title: string;
    list: IProductsAndServicesCardProps[];
    amountOfElements: number;
}

const ProductsAndServices: FC<IProductsAndServicesProps> = ({ list, title, amountOfElements = 10 }) => {
    const windowWidth = useResize();
    const isDesktop = windowWidth > TABLET_WIDTH;
    const [buttonShow, setButtonShow] = useState(amountOfElements < list.length);

    const currentList = useMemo(() => {
        if (isDesktop) return list;

        if (buttonShow) return list?.slice(0, amountOfElements);

        return list;
    }, [list, buttonShow, isDesktop, amountOfElements]);

    const handleClick = () => {
        setButtonShow(false);
    };

    useEffect(() => {
        setButtonShow(amountOfElements < list.length);
    }, [amountOfElements]);

    return (
        <div className="section">
            <div className={cn.productsAndServicesWrapper}>
                {title && (
                    <TextField
                        text={title}
                        name="title"
                        isTextArea
                        className={cn.productsAndServicesTitle}
                        customTag="div"
                    />
                )}
                <div className={cn.productsAndServices}>
                    {currentList?.map((item, idx) => (
                        <ProductsAndServicesCard
                            isActual={item?.isActual || false}
                            actualColor={item?.actualColor}
                            width={item?.width || 'small'}
                            color={item?.color || 'gray'}
                            cardTitle={item?.cardTitle || ''}
                            cardDescription={item?.cardDescription || ''}
                            linkStyle={item?.linkStyle || 'none'}
                            linkText={item?.linkText || ''}
                            linkHref={item?.linkHref || ''}
                            desktopImage={item?.desktopImage || ''}
                            mobileImage={item?.mobileImage || ''}
                            isLineNeeded={item?.isLineNeeded || false}
                            imagePosition={item?.imagePosition}
                            key={idx}
                            pos={idx}
                            buttonStyle={item?.buttonStyle}
                            size={item?.size}
                        />
                    ))}
                </div>
                {!isDesktop && buttonShow && (
                    <button className={cn.showAll} type="button" onClick={handleClick}>
                        Показать все
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProductsAndServices;
