import { IPromo } from '@/components/blocks/Promo';

export const mockPromoBlockData: IPromo = {
    _template: 'promo',
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background1.png',
        color: 'white',
        line: {
            title: 'Кредит наличными',
            subtitle: 'от 5,9% по паспорту',
        },
        description: 'Максимально низкая ставка за максимально короткое время',
        buttons: [
            {
                text: 'Заполнить заявку',
                link: '#id__credit-issue',
                type: 'button',
                isSetMobileAnimate: true,
            },
            { text: 'Подробнее', link: '#id__credit-issue', type: 'link' },
        ],
        image: '/img-next/png/animatedPromo/1.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            imageLink: '',
            title: 'Ставка 0%',
            subtitle: 'На первые 6 месяцев',
        },
        {
            image: '/img-next/png/promo/2.png',
            imageLink: '',
            title: 'До 4 млн ₽',
            subtitle: 'Сумма кредита',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'До 5 лет',
            subtitle: 'Срок кредита',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'За 1 минуту',
            subtitle: 'Решение по кредиту',
        },
    ],
};

export const mockPromoBlockDataCreditGoods = {
    entry: {
        text: 'Стать партнером банка',
        link: '#',
    },
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        line: {
            title: 'Покупки в кредит',
            subtitle: 'За полчаса нужные вещи станут вашими',
        },
        buttons: [{ text: '', link: '#', type: 'button' }],
        image: '/img-next/Group1.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'До 300 000 ₽',
            subtitle: 'желаемая сумма кредита',
        },
        {
            image: '/img-next/png/promo/2refinancing.png',
            title: 'От 3 до 36 месяцев',
            subtitle: 'сроки погашения кредита',
        },
        {
            image: '/img-next/png/promo/3refinancing.png',
            title: 'От 0% до 90%',
            subtitle: 'первоначальный взнос',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: '30 минут',
            subtitle: 'от выбора до покупки',
        },
    ],
};

export const mockPromoBlockDataOnlineCredits = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        line: {
            title: 'Онлайн кредитование',
        },
        buttons: [{ text: 'Заявка на подключение', link: '#', type: 'button' }],
        image: '/img-next/png/onlinecredits/promo1.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/onlinecredits/promo2.png',
            title: 'до 300 000 ₽',
            subtitle: 'желаемая сумма кредита',
        },
        {
            image: '/img-next/png/onlinecredits/promo3.png',
            title: 'От 0 до 90%',
            subtitle: 'первоначальный взнос',
        },
        {
            image: '/img-next/png/onlinecredits/promo4.png',
            title: 'ОТ 3 до 36 месяцев',
            subtitle: 'сроки погашения кредита',
        },
        {
            image: '/img-next/png/onlinecredits/promo5.png',
            title: 'От 18 лет',
            subtitle: 'возраст заемщика',
        },
    ],
};

export const mockPromoBlockDataTina = {
    section: null,
    entry: {
        text: 'Заголовок',
        link: '#',
    },
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '',
        color: 'white',
        line: {
            title: 'Заголовок',
            subtitle: 'Описание',
            headersList: [
                { title: 'Вклад для пенсионеров с дополнительным % годовых', utm: 'v-brand-dliapens' },
                { title: 'Пенсионный вклад с дополнительным % годовых', utm: 'v-brand-pensionni' },
                { title: 'Дополнительный процент по вкладу для пенсионеров', utm: 'v-brand-procenti' },
            ],
            subheadersList: [
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-dliapens' },
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-pensionni' },
                { title: 'Переведите пенсию и оформите вклад с повышенной ставкой', utm: 'v-brand-procenti' },
            ],
        },
        description: 'Описание',
        buttons: [{ text: 'Заполнить заявку', link: '#id__credit-issue', type: 'button' }],
        image: '',
        imageLink: '',
        activeGradient: false,
    },
    promo: [
        {
            image: '',
            imageLink: '',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        {
            image: '',
            imageLink: '',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        {
            image: '',
            imageLink: '',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        {
            image: '',
            imageLink: '',
            title: 'Заголовок',
            subtitle: 'Описание',
        },
    ],
    enableSpecial: false,
    special: {
        imageSpecial: '',
        text: 'Заголовок',
        desc: 'Описание',
    },
};

export const mockPromoBlockDataConfidenceIndex = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background1.png',
        color: 'white',
        line: {
            title: 'Инвестиционное страхование жизни',
            subtitle: 'по программе «Индекс доверия»',
        },
        description:
            'Высокий потенциал роста дохода на&nbsp;основе инвестиционной стратегии &laquo;ЭПОХА ТЕХНОЛОГИЙ 2.0&raquo;',
        image: '/img-next/png/animatedPromo/1.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: '100% защита',
            subtitle: 'вложенного капитала',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'Страховая защита',
            subtitle: 'жизни',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'Срок программы',
            subtitle: '3 года или 4 года',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'Возможность пополнения',
            subtitle: 'от 100 000 рублей',
        },
    ],
};

export const mockPromoBlockDataLeadersChina = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background1.png',
        color: 'white',
        line: {
            title: 'Инвестиционное страхование жизни',
            subtitle: 'по&nbsp;программе &laquo;Лидеры Китая&raquo;',
        },
        description:
            'Высокий потенциал роста дохода на&nbsp;основе инвестиционной стратегии &laquo;Лидеры Китая&raquo;',
        image: '/img-next/png/animatedPromo/1.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: '100% защита',
            subtitle: 'вложенного капитала',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'Страховая защита',
            subtitle: 'жизни',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'Срок программы',
            subtitle: '5 лет',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'Возможность пополнения',
            subtitle: 'от 100 000 рублей',
        },
    ],
};

export const mockPromoBlockDataFitonomika = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background2.png',
        color: 'white',
        line: {
            title: 'Программа «Фитономика»',
            subtitle: 'здоровый образ жизни с правильным питанием',
        },
        buttons: [{ text: 'Оформить услугу', link: 'https://www.pochtabank.ru/map', type: 'button' }],
        image: '/img-next/png/promo/fitonomika.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'Консультация с диетологом',
            subtitle: 'и разработка программы питания',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'Индивидуальный подход',
            subtitle: 'с учетом особенностей организма',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'Регулярная мотивация',
            subtitle: 'для достижения цели',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: '3 000 ₽',
            subtitle: 'стоимость сертификата на год',
        },
    ],
};

export const mockPromoBlockDataBooking = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background2.png',
        color: 'white',
        line: {
            title: 'Авиа и ж/д билеты',
            subtitle: 'Бронирование рядом с домом',
        },
        description: 'Теперь в любом клиентском центре банка быстро и без комиссии',
        buttons: [{ text: 'Узнайте где купить', link: 'https://www.pochtabank.ru/map', type: 'button' }],
        image: '/img-next/png/promo/travels.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'купить авиа',
            subtitle: 'и ж/д билеты',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'оформить',
            subtitle: 'страхование',
        },
    ],
};

export const mockPromoBlockDataLawyer = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        backgroundImage: '/img-next/png/animatedPromo/background2.png',
        color: 'white',
        line: {
            title: 'Юридические',
            subtitle: 'консультации',
        },
        description: '',
        buttons: [{ text: 'Оформить услугу', link: 'https://www.pochtabank.ru/map', type: 'button' }],
        image: '/img-next/png/promo/lawyer.png',
        imageLink: '{cashcredit_image}',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: '24 часа в сутки, 365 дней в году',
            subtitle: 'По телефону, Skype или email',
        },
        {
            image: '/img-next/png/promo/1.png',
            title: 'По широкому кругу вопросов',
            subtitle:
                'Трудовое, семейное, жилищное законодательство; российское право; сделки с недвижимостью; юридическая поддержка на дорогах и многое другое',
        },
        {
            image: '/img-next/png/promo/2.png',
            title: 'От 3 000 рублей',
            subtitle: 'Стоимость сертификата на год',
        },
    ],
};
