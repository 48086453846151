import clsx from 'clsx';
import React from 'react';

import ThreeInARowItem, { IThreeInARowItem } from '@/components/blocks/ThreeInARow/ThreeInARowItem';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IThreeInARow {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'threeInARow';
    /**
     * Список блоков.
     */
    list: IThreeInARowItem[];
    name?: string;
}

function ThreeInARow({ list, name }: IThreeInARow) {
    return (
        <div className={clsx(cn.blockWrap, 'section')}>
            <div className={cn.threeInARowWrap}>
                {name && (
                    <TextField
                        text={name}
                        name="name"
                        customTag="h3"
                        className={cn.mainTitle}
                        isHTML
                        htmlValue={name}
                    />
                )}

                {list?.length > 0 && (
                    <div className={cn.itemsWrapper}>
                        {list?.map((item, index) => (
                            <ThreeInARowItem {...item} key={index} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default withBlockLinks(ThreeInARow);
