import React from 'react';

import { TinaDictionary } from '@/components/blocks/dictionary/Dictionary/index.tina';
import { mockDictionaryBlockDataTina } from '@/data/blocks/dictionary';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const dictionaryBlock = {
    Component: props => <TinaDictionary {...props} />,
    template: {
        label: 'Словарь банковских терминов',
        defaultItem: () => mockDictionaryBlockDataTina,
        fields: [
            {
                label: 'Список секций',
                name: 'sections',
                component: 'group-list',
                itemProps: (item: { label: string }, index: number) => ({
                    key: index,
                    label: item.label,
                }),
                defaultItem: () => mockDictionaryBlockDataTina.sections[0],
                fields: [
                    {
                        name: 'label',
                        label: 'Заголовок',
                        component: 'text',
                    },
                    {
                        label: 'Список элементов в секции',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { name: string }, index: number) => ({
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => mockDictionaryBlockDataTina.sections[0].list[0],
                        fields: [
                            {
                                name: 'name',
                                label: 'Заголовок',
                                component: HtmlEditorFeatured,
                            },
                            {
                                name: 'description',
                                label: 'Описание',
                                component: HtmlEditorFeatured,
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
