export const mockExampleBlockData = {
    name: 'Пример экономии на покупках',
    text: 'Вы в течение 1 года ежемесячно совершали покупки по карте «Пятёрочка» от «Почта Банка» по 7500 руб. в магазинах «Пятёрочка» и других магазинах или интернете:',
    list: [
        {
            title: 'За покупки в «Пятерочке»',
            info: [
                {
                    subtitle: '1 500 баллов в месяц',
                },
                {
                    subtitle: '18 000 баллов в год',
                    desc: 'если каждая покупка была > 555 ₽',
                },
                {
                    subtitle: '1 800 ₽ в год',
                },
            ],
        },
        {
            title: 'За покупки в других магазинах или интернете',
            info: [
                {
                    subtitle: '750 баллов в месяц',
                },
                {
                    subtitle: '9 000 баллов в год',
                },
                {
                    subtitle: '900 ₽ в год',
                },
            ],
        },
        {
            title: 'Приветственные баллы за покупки в «Пятёрочке»',
            info: [
                {
                    subtitle: '5 550 приветственных баллов',
                    desc: '555 баллов за каждую покупку от 555 ₽ в течение 6 месяцев пользования картой',
                },
                {
                    subtitle: '555 ₽ в год',
                },
            ],
        },
    ],
    bottomText:
        'Итого за год вы накопили: 32 550 баллов «Пятёрочки» = 3 255 ₽ скидка на товары, это может быть 1 корзина с продуктами на сумму <span>3 200 ₽</span>',
    bottomInfo: {
        infoTitle: 'Итого за год вы накопили ',
        list: [
            {
                infoItemTitle: '32 550 <span>баллов</span>',
                infoItemText: 'баллов «Пятёрочки»',
            },
            {
                infoItemTitle: '= 3 255 ₽',
                infoItemText: 'скидка на товары',
            },
        ],
    },
    banner: {
        text: 'Это может быть 1 корзина с продуктами на сумму',
        bigText: '3 200 ₽',
        logo: '/img-next/png/pitera.png',
        image: '/img-next/png/piteraBg.png',
    },
};

export const mockExampleBlockDataTina = {
    name: 'Заголовок',
    text: 'Описание',
    list: [
        {
            title: 'Заголовок',
            info: [
                {
                    subtitle: 'Заголовок',
                    desc: 'Описание',
                },
            ],
        },
    ],
    bottomText: 'Заголовок',
    bottomInfo: {
        infoTitle: 'Заголовок',
        list: [
            {
                infoItemTitle: 'Заголовок',
                infoItemText: 'Описание',
            },
        ],
    },
    banner: {
        text: 'Заголовок',
        bigText: 'Подзаголовок',
        logo: '',
        image: '',
    },
};
