import React from 'react';

import { TinaFAQ } from '@/components/blocks/faq/FAQ/index.tina';
import { mockFaqBlockDataTina } from '@/data/blocks/faq';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const faqBlock = {
    Component: props => <TinaFAQ {...props} />,
    template: {
        label: 'FAQ - помощь',
        defaultItem: () => mockFaqBlockDataTina,
        fields: [
            {
                component: HtmlEditorFeatured,
                label: 'Заголовок',
                name: 'title',
            },
            {
                component: HtmlEditorFeatured,
                label: 'Описание',
                name: 'subtitle',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockFaqBlockDataTina.list[0],
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                        name: 'title',
                    },
                    {
                        label: 'Список табов',
                        component: 'group-list',
                        name: 'tabsList',
                        itemProps: (item: { tabTitle: string }, index: number) => ({
                            key: index,
                            label: item.tabTitle,
                        }),
                        fields: [
                            {
                                label: 'Заголовок в табе',
                                component: 'text',
                                name: 'tabTitle',
                            },
                        ],
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'info',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockFaqBlockDataTina.list[0].info[0],
                        fields: [
                            {
                                label: 'Список табов',
                                component: 'group-list',
                                name: 'elementTabsList',
                                itemProps: (item: { elementTabTitle: string }, index: number) => ({
                                    key: index,
                                    label: item.elementTabTitle,
                                }),
                                fields: [
                                    {
                                        label: 'Заголовок в табе',
                                        component: 'text',
                                        name: 'elementTabTitle',
                                    },
                                ],
                            },
                            {
                                component: HtmlEditorFeatured,
                                label: 'Заголовок',
                                name: 'title',
                            },
                            {
                                component: HtmlEditorFeatured,
                                label: 'Описание',
                                name: 'subtitle',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
