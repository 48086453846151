import React from 'react';

import { TinaGroupedTariffs } from '@/components/blocks/GroupedTariffs/index.tina';
import { mockTariffBlockDataTina } from '@/data/blocks/groupedTariffs';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const groupedTariffsBlock = {
    Component: props => <TinaGroupedTariffs {...props} />,
    template: {
        label: 'Тарифы',
        defaultItem: () => mockTariffBlockDataTina,
        fields: [
            {
                name: 'list',
                component: 'group-list',
                label: 'Список',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                    title: item.name,
                }),
                defaultItem: mockTariffBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'items',
                        component: 'group-list',
                        label: 'Список субэлементов',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                            title: item.title,
                        }),
                        defaultItem: mockTariffBlockDataTina.list[0].items[0],
                        fields: [
                            {
                                name: 'archive',
                                component: 'toggle',
                                label: 'Действующий/Архивный',
                            },
                            {
                                name: 'title',
                                component: HtmlEditorFeatured,
                                label: 'Название',
                            },
                            {
                                name: 'document',
                                component: 'text',
                                label: 'Ссылка на документ',
                            },
                            {
                                component: 'text',
                                name: 'documentType',
                                label: 'Расширение документа',
                            },
                            {
                                component: 'text',
                                name: 'permanentDocument',
                                label: 'Ссылка для редиректа',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
