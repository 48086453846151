import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import IconCalendar from '@/components/UI/IconCalendar';
import IconTime from '@/components/UI/IconTime';
import { LinkIcon } from '@/components/UI/LinkIcon';
import { IArticlesListForDetailItem } from '@/types/ArticlesListForDetail';

import cn from './style.module.sass';

const ArticlesListItem: React.FC<IArticlesListForDetailItem> = ({
    id,
    code,
    name,
    previewPictureSrc,
    detailListUrl,
    time,
    date,
    theme,
}) => {
    const actualDate = date?.split(' ')[0];

    return (
        <AnimatedComponent className={clsx(cn.currentItem, 'currentItem')} classNameActive={cn.animationInit}>
            <div className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}>
                <a href={detailListUrl}>
                    <div className={cn.currentImage}>
                        <CustomImage src={previewPictureSrc} alt={name} mockWidth="350" mockHeight="200" fill />
                    </div>
                </a>
                <div className={cn.currentContent}>
                    <div className={cn.currentTop}>
                        {date && (
                            <div className={clsx(cn.currentWrapper)}>
                                <IconCalendar />
                                <div className={cn.currentTime} dangerouslySetInnerHTML={{ __html: actualDate }} />
                            </div>
                        )}

                        {time && (
                            <div className={cn.currentWrapper}>
                                <IconTime />
                                <span className={cn.currentTime}>Время чтения</span>
                                <div className={cn.currentTime} dangerouslySetInnerHTML={{ __html: time }} />
                            </div>
                        )}
                        <a href={detailListUrl} className={cn.currentTitle}>
                            <span dangerouslySetInnerHTML={{ __html: name }} />
                        </a>
                    </div>

                    <div className={cn.currentBot}>
                        {detailListUrl && (
                            <LinkIcon href={detailListUrl} noPadding typeIcon="right" label="Узнать больше" />
                        )}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default ArticlesListItem;
