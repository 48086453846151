import clsx from 'clsx';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import MainArticlesSliderItem from '@/components/blocks/MainArticlesSlider/item';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useAppStore } from '@/context/AppStoreContext';
import { useResize } from '@/hooks/useResize';
import {
    IArrowMainArticlesSlider,
    IMainArticlesSlider,
    IMainArticlesSliderItem,
    IMainArticlesSliderResponse,
    SwiperRefMainArticlesSlider,
} from '@/types/mainArticlesSlider';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

const PrevArrow: FC<IArrowMainArticlesSlider> = ({ gtag, listLength, swiperRefLink }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRefLink?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrowMainArticlesSlider> = ({ gtag, listLength, swiperRefLink }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRefLink?.current?.swiper?.slideNext();

        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const MainArticlesSlider: React.FC<IMainArticlesSlider> = ({
    apiCode,
    section,
    list,
    title,
    gtag,
    disableSlider,
    button,
}) => {
    const width = useResize();
    const [path] = useAppStore(store => store.path);
    const [isTinaView] = useAppStore(store => store.isTinaView);
    const isMobile = width <= TABLET_WIDTH;

    const [isEnd, setIsEnd] = useState<boolean>(false);
    const [isBeginning, setIsBeginning] = useState<boolean>(true);

    const [elementsList, setElementsList] = useState<IMainArticlesSliderItem[]>(list);

    const swiperRef: SwiperRefMainArticlesSlider = useRef(null);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.16;
        if (width < MOBILE_WIDTH) return 1.32;
        if (width < TABLET_SMALL_WIDTH) return 2.2;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
        onSlideChange: (swp: { isEnd: boolean; isBeginning: boolean }) => {
            if (swp?.isEnd) {
                setIsEnd(true);
            } else {
                setIsEnd(false);
            }

            if (swp?.isBeginning) {
                setIsBeginning(true);
            } else {
                setIsBeginning(false);
            }
        },
    };

    // GET params
    const sectionPart = `&section=${section || 0}`;
    const infoBlockPart = `&apiCode=${apiCode}`;

    const requestDataWithParams = async (...args) => {
        try {
            const response: IMainArticlesSliderResponse = await apiRequest(
                `/page/block?path=${path}&block=mainArticlesSlider${args.join('')}`
            );

            const {
                data: { data: fetchedData },
            } = response;

            return fetchedData;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    const setData = async params => {
        try {
            const data = await requestDataWithParams(...params);

            setElementsList(data?.list);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (!apiCode || !isTinaView) return;
        const requestParams = [sectionPart, infoBlockPart];
        setData(requestParams).catch(console.error);
    }, [section, apiCode]);

    if (disableSlider) {
        return (
            <div className={clsx(cn.current, 'section')}>
                <div className={cn.currentWrap}>
                    <div className={cn.topWrap}>
                        {title && (
                            <TextField
                                text={title}
                                name="title"
                                isHTML
                                htmlValue={title}
                                className={cn.title}
                                customTag="h2"
                            />
                        )}
                        {button?.title && (
                            <div className={cn.buttonWrapper}>
                                <div className={cn.button}>
                                    <a href={button?.link} className={cn.buttonInner}>
                                        {button?.title}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={cn.currentGrid}>
                        <AnimatedComponent
                            className={clsx(cn.currentSlider, cn.currentNoSlider)}
                            classNameActive={cn.animationInit}
                        >
                            {elementsList?.length > 0 &&
                                elementsList?.map((item, index) => (
                                    <MainArticlesSliderItem key={item?.pos || index} {...item} />
                                ))}
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.current, 'section')}>
            <div className={cn.currentWrap}>
                <div className={cn.topWrap}>
                    {title && (
                        <TextField
                            text={title}
                            name="title"
                            isHTML
                            htmlValue={title}
                            className={cn.title}
                            customTag="h2"
                        />
                    )}
                    {!isMobile && button?.title && (
                        <div className={cn.buttonWrapper}>
                            <div className={cn.button}>
                                <a href={button?.link} className={cn.buttonInner}>
                                    {button?.title}
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                {elementsList?.length > 0 && (
                    <div className={cn.currentGrid}>
                        <AnimatedComponent className={cn.currentSlider} classNameActive={cn.animationInit}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <CustomSwiper {...settings} ref={swiperRef}>
                                {elementsList?.map((item, index) => (
                                    <SwiperSlide key={item?.pos || index}>
                                        <MainArticlesSliderItem {...item} />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>

                            {!isBeginning && (
                                <PrevArrow listLength={elementsList?.length} swiperRefLink={swiperRef} gtag={gtag} />
                            )}

                            {!isEnd && (
                                <NextArrow listLength={elementsList?.length} swiperRefLink={swiperRef} gtag={gtag} />
                            )}
                        </AnimatedComponent>
                    </div>
                )}
            </div>

            <div className={cn.bottomWrap}>
                {isMobile && button?.title && (
                    <div className={cn.buttonWrapper}>
                        <div className={cn.button}>
                            <a href={button?.link} className={cn.buttonInner}>
                                {button?.title}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(MainArticlesSlider);
