import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface ICartToCard {
    _template: 'cardToCard';
    title: string;
}

const CardToCardContent = dynamic(async () => import('./CardToCardContent'), { ssr: false });

const CardToCard: React.FC<ICartToCard & { index: number }> = ({ index, title }) => (
    <div className="section">
        <CardToCardContent title={title} />
    </div>
);

export default withBlockLinks(CardToCard);
