import React from 'react';

import { TinaTariffCompare } from '@/components/blocks/TariffCompare/index.tina';
import { mockTariffCompareBlockData } from '@/data/blocks/tariffCompare';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const tariffCompareBlock = {
    Component: props => <TinaTariffCompare {...props} />,
    template: {
        label: 'Сравнение тарифов',
        defaultItem: () => mockTariffCompareBlockData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                label: 'Список тарифов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string; id: number }) => ({
                    key: item.id,
                    label: item.title,
                }),
                defaultItem: () => mockTariffCompareBlockData.list[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Название (максимум 255 символов)',
                    },
                    {
                        label: 'Характеристики',
                        name: 'characteristics',
                        component: 'group-list',
                        itemProps: (elem: { title: string }, index: number) => ({
                            key: index,
                            label: elem.title,
                        }),
                        defaultItem: () => mockTariffCompareBlockData.list[0].characteristics[0],
                        fields: [
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Название',
                            },
                            {
                                name: 'value',
                                component: HtmlEditorFeatured,
                                label: 'Описание',
                            },
                        ],
                    },
                ],
            },

            {
                label: 'Кнопка',
                name: 'button',
                component: 'group',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
