import React from 'react';

import { TinaRightButtonText } from '@/components/blocks/RightButtonText/index.tina';
import { mockRightButtonTextBlockDataTina } from '@/data/blocks/rightButtonText';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const rightButtonTextBlock = {
    Component: props => <TinaRightButtonText {...props} />,
    template: {
        label: 'Текст с кнопкой справа',
        defaultItem: () => mockRightButtonTextBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'titleBlock',
                component: HtmlEditorFeatured,
                label: 'Главный заголовок',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },

            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Кнопка',
                name: 'btn',
                component: 'group',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Текст на кнопке',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
