import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import Button from '@/components/UI/Button';
import { useResize } from '@/hooks/useResize';
import { TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IPromotionGradientBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'promotionGradientBanner';
    promotion: {
        text: string;
        mobileText?: string;
        button?: string;
        link?: string;
        image: string;
        isMobileInitial?: boolean;
    };
}

const PromotionGradientBanner = ({ promotion }: IPromotionGradientBanner) => {
    const width = useResize();
    const router = useRouter();
    const isMobile = promotion?.isMobileInitial || width < TABLET_WIDTH;

    const goTo = () => {
        if (isMobile && promotion?.link) {
            router.push(promotion.link);
        }
    };

    const showButton = !isMobile && promotion?.button && promotion?.link;

    return (
        <div className={clsx(cn.wrapper, isMobile && cn.mobile)} onClick={goTo} role="presentation">
            <div className={clsx(cn.left, isMobile && cn.mobile)}>
                {promotion?.text && <div className={clsx(cn.text, isMobile && cn.mobile)}>{promotion?.text}</div>}
            </div>
            <div className={clsx(cn.right, isMobile && cn.mobile)}>
                <div className={clsx(cn.icon, isMobile && cn.mobile)}>
                    <CustomImage
                        src={promotion?.image || '/img-next/svg/pbo-faq/promotion.svg'}
                        alt={promotion?.text}
                        width={isMobile ? 81 : 152}
                        height={isMobile ? 65 : 122}
                        quality={100}
                    />
                </div>
                {showButton && (
                    <Button
                        variant="btnWhite"
                        type="link"
                        href={promotion.link}
                        customClass={cn.button}
                        label={promotion.button}
                        animation={false}
                        whiteBg
                    />
                )}
            </div>
        </div>
    );
};

export default PromotionGradientBanner;
