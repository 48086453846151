export const mockSummaryNumbersDefaultData = {
    columns: 3,
    items: [
        {
            title: 'Клиентская база',
            number: '21 млн',
            desc: 'клиентов «Почта Банка» к 2023 году',
        },
        {
            title: 'Региональная экспансия',
            number: '26 000',
            desc: 'точек предоставления банковских услуг',
        },
        {
            title: 'Банковская сеть',
            number: '8 000',
            desc: 'устройств банковского обслуживания',
        },
    ],
};

export const mockSummaryNumbersMockData = {
    heading: 'Заголовок',
    columns: 2,
    items: [
        {
            title: 'Заголовок',
            number: 'Число',
            desc: 'Описание',
        },
        {
            title: 'Заголовок',
            number: 'Число',
            desc: 'Описание',
        },
        {
            title: 'Заголовок',
            number: 'Число',
            desc: 'Описание',
        },
        {
            title: 'Заголовок',
            number: 'Число',
            desc: 'Описание',
        },
    ],
};
