import clsx from 'clsx';
import Image from 'next/image';
import React, { FC } from 'react';

import TextField from '@/components/TextField';

import { IRefinanceAdvantagesCard } from '../../iRefinanceAdvantagesCard';
import cn from './style.module.sass';

const RefinanceInPochtaBankCard: FC<IRefinanceAdvantagesCard> = ({
    image,
    title,
    firstText,
    secondText,
    thirdText,
    fourthText,
}) => (
    <div className={cn.refinanceInPochtaBank}>
        <TextField text={title} className={cn.title} name="list[0].title" htmlValue={title} isHTML />
        <div className={cn.topRefinanceBlock}>
            <TextField
                text={firstText}
                className={cn.refinanceText}
                name="list[1].firstText"
                htmlValue={firstText}
                isHTML
            />
            <TextField
                text={secondText}
                className={cn.refinanceText}
                name="list[1].secondText"
                htmlValue={secondText}
                isHTML
            />
        </div>
        <TextField
            text={thirdText}
            className={clsx(cn.refinanceText, cn.bottomRefinanceBlock)}
            name="list[1].thirdText"
            htmlValue={thirdText}
            isHTML
        />
        <TextField text={fourthText} className={cn.smallText} name="list[1].fourthText" htmlValue={fourthText} isHTML />
        {image && <Image className={cn.image} src={image} alt={image} width={217} height={190} />}
    </div>
);

export default RefinanceInPochtaBankCard;
