import React from 'react';

import { TinaTinyGradientCategory } from '@/components/blocks/TinyGradientCategory/index.tina';
import { tinaTinyGradientCategoryData } from '@/data/blocks/tinyGradientCategory';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const tinyGradientCategoryBlock = {
    Component: props => <TinaTinyGradientCategory {...props} />,
    template: {
        label: 'Маленький блок категории с градиентом',
        defaultItem: () => tinaTinyGradientCategoryData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'subTitle',
                component: 'text',
                label: 'Подзаголовок',
            },

            {
                name: 'list',
                component: 'group-list',
                label: 'Список элементов',
                defaultItem: () => tinaTinyGradientCategoryData.list[0],
                itemProps: (item: { previewTitle: string }, index: number) => ({
                    key: index,
                    label: item.previewTitle,
                }),
                fields: [
                    {
                        name: 'previewTitle',
                        component: 'text',
                        label: 'Текст (слайдер)',
                    },
                    {
                        name: 'storyTitle',
                        component: 'text',
                        label: 'Текст (модальное окно)',
                    },
                    {
                        name: 'storyDescription',
                        component: HtmlEditorFeatured,
                        label: 'Описание (модальное окно)',
                    },
                    {
                        name: 'img',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'backgroundImage',
                        component: 'image',
                        label: 'Фоновое Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'openStoryImg',
                        component: 'image',
                        label: 'Картинка в открытой истории ',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Кнопка',
                        fields: [
                            {
                                name: 'href',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Название',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
