import React from 'react';

import { TinaPeriodInfo } from '@/components/blocks/PeriodInfo/index.tina';
import { mockPeriodInfoBlockTinaData } from '@/data/blocks/periodInfo';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const periodInfoBlock = {
    Component: props => <TinaPeriodInfo {...props} />,
    template: {
        label: 'Беспроцентный период',
        defaultItem: () => mockPeriodInfoBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },

            {
                name: 'descTop',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'list',
                component: 'group-list',
                label: 'Нижние значения таблицы',
                itemProps: (item: { data: string; sum: string; desc: string }, index: number) => ({
                    key: index,
                    label: item.sum,
                }),
                defaultItem: () => mockPeriodInfoBlockTinaData.list[0],
                fields: [
                    {
                        label: 'Дата',
                        name: 'data',
                        component: 'text',
                    },
                    {
                        label: 'Сумма',
                        name: 'sum',
                        component: 'text',
                    },
                    {
                        label: 'Описание',
                        name: 'desc',
                        component: HtmlEditorFeatured,
                    },
                ],
            },
            {
                name: 'descBottom',
                label: 'Текст снизу',
                component: HtmlEditorFeatured,
            },
        ],
    },
};
