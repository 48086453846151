export const mockInfoCardBlockData = {
    list: [
        {
            name: 'Мультибонус для пенсионеров',
            image: '/img-next/png/infoCard/1Multibonus.png',
            imageLink: '',
            desc:
                'Получаете пенсию в Почта Банк?\n' +
                'Мы начислим 3% за покупки в аптеках, на автозаправках, за ж/д билеты.\n' +
                '\n' +
                'Подключите Мультибонус самостоятельно в Почта Банк Онлайн или в отделении.',
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
        },
        {
            name: 'Пакет «Почётный клиент»',
            image: '/img-next/png/infoCard/2Multibonus.png',
            imageLink: '',
            desc:
                'Подключите пакет «Почётный клиент» и получайте дополнительные бонусы:\n' +
                '\n' +
                '3% в категории «Товары для дома, ремонта»\n' +
                '1% за все покупки (суммируется с текущими предложениями)',
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
        },
        {
            name: 'Инвестиции в золото',
            image: '/img-next/png/infoCard/3Multibonus.png',
            imageLink: '',
            desc:
                'Совершайте покупки по карте и копите бонусы.\n' +
                '\n' +
                'Обменивайте бонусы на ПИФ Золото в Почта Банк Онлайн.\n' +
                '\n' +
                'Золото – защитный актив во времена нестабильности и кризисов, цена на него растет даже в период колебаний на рынке. Следить за изменениями стоимости портфеля можно в Почта Банк Онлайн.',
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
        },
    ],
};
export const mockInfoCardBlockDataRight = {
    list: [
        {
            name: 'Инвестиции в золото',
            image: '/img-next/png/infoCard/3Multibonus.png',
            desc: 'Вкладывайте в паевой инвестиционный фонд в золоте, который создан Управляющей Компанией – ВТБ Капитал Управление активами. ПИФ в золоте - лучший защитный актив во времена нестабильности и кризисов.',
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        {
            name: 'Инвестиционное страхование жизни',
            image: '/img-next/png/infoCard/investStrLife.png',
            desc: 'Два в одном: страхование жизни и получение дополнительного дохода от размещения денежных средств в финансовые активы, на 100% защищенные от любых падений финансового рынка. Страховые выплаты до 300% от суммы Ваших вложений.',
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        {
            name: 'ПИФЫ',
            image: '/img-next/png/infoCard/pif.png',
            desc: 'Вкладывайте средства в паевые инвестиционные фонды, становитесь владельцами инвестиционных паев под управлением профессионалов фондового рынка.',
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        {
            name: 'ОФЗ-Н',
            image: '/img-next/png/infoCard/ofzn.png',
            desc: 'Облигации федерального займа для населения с доходностью до 6,02%. Надежный инвестиционный инструмент с высоким уровнем дохода и государственной гарантией по возврату денежных средств.',
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        {
            name: 'Индивидуальный инвестиционный счет',
            image: '/img-next/png/infoCard/individual.png',
            desc: 'Индивидуальный инвестиционный счет - это привлекательная альтернатива вкладу. Сочетание потенциального дохода от инвестиций с управляющей компанией «Альфа-Капитал» и налогового вычета до 52 000 ₽ в год.',
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        {
            name: 'Умный доход',
            image: '/img-next/png/infoCard/dohod.png',
            desc: `<ul>
<li>до 6,75% годовых</li>
<li>срок размещения – 181 , 367 дней</li>
<li>сумма вклада – от 50 000 ₽</li>
<li>повышенная ставка при оформлении ИСЖ</li>
</ul>`,
            buttonBg: {
                name: 'Подробнее',
                link: '#',
                rel: '',
            },
        },
        // {
        //     name: 'Мультибонус для пенсионеров',
        //     image: '/img-next/png/infoCard/1Multibonus.png',
        //     desc:
        //         'Получаете пенсию в Почта Банк?\n' +
        //         'Мы начислим 3% за покупки в аптеках, на автозаправках, за ж/д билеты.\n' +
        //         '\n' +
        //         'Подключите Мультибонус самостоятельно в Почта Банк Онлайн или в отделении.',
        //     buttonBg: {
        //         name: 'Подробнее',
        //         link: '#',
        //     },
        // },
        // {
        //     name: 'Пакет «Почётный клиент»',
        //     image: '/img-next/png/infoCard/2Multibonus.png',
        //     desc:
        //         'Подключите пакет «Почётный клиент» и получайте дополнительные бонусы:\n' +
        //         '\n' +
        //         '3% в категории «Товары для дома, ремонта»\n' +
        //         '1% за все покупки (суммируется с текущими предложениями)',
        //     buttonBg: {
        //         name: 'Подробнее',
        //         link: '#',
        //     },
        // },
    ],
};
export const mockInfoCardBlockTina = {
    section: null,
    list: [
        {
            name: 'Заголовок',
            image: '/img-next/mock/320x320.png',
            imageLink: '',
            desc: 'Описание',
            info: [
                {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
            ],
            buttonDetail: {
                name: 'Название',
                link: '#',
            },
        },
        {
            name: 'Заголовок',
            image: '/img-next/mock/320x320.png',
            imageLink: '',
            desc: 'Описание',
            info: [
                {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
                {
                    title: 'Заголовок',
                    subtitle: 'Описание',
                },
            ],
            buttonDetail: {
                name: 'Название',
                link: '#',
            },
        },
    ],
};
