export const mockCashCreditBlockData = {
    image: '/img-next/png/cashCreditCard.png',
    imageAlt: 'Кредит наличными',
    subhead: 'Оформите кредит наличными на карту на выгодных условиях.',
    text: 'Для того чтобы получить кредит на карту, необходимо заполнить онлайн-заявку ниже.',
    backgroundColor: 'blue',
};

export const mockCashCreditBlockTinaData = {
    section: null,
    image: '',
    imageAlt: 'Описание картинок',
    subhead: 'Заголовок',
    text: 'Описание',
    backgroundColor: 'white',
};
