import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useParams, useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import AnimatedComponent from '@/components/Animation';
import TagList from '@/components/TagList';
import ErrorFallback from '@/components/UI/ErrorFallback';
import Pagination, { ISelected } from '@/components/UI/Pagination';
import Preloader from '@/components/UI/Preloader';
import { useAppStore } from '@/context/AppStoreContext';
import useAxiosFetch from '@/hooks/useAxiosFetch';
import { IArtilceListData, IArtilceListPagination } from '@/types/articleList';
import { ITag } from '@/types/tags';

import ArticleItem from '../ArticleItem';
import cn from './style.module.sass';

export interface IArticlesList {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'dzenPage';
    /**
     * Карточки новостей
     */
    apiCode: string;
    section: number;
    links?: IArtilceListData['links'];
    tagList?: ITag[];
    list: IArtilceListData['list'];
    navigation: IArtilceListPagination;
}

const ArticlesList: React.FC<IArticlesList> = observer(
    ({ _template, apiCode, section, list, tagList, navigation: fetchedNavigation }) => {
        const router = useRouter();
        const params = useParams<{ slug: string }>();
        const currentUrl = params?.slug;

        const defaultUrl = currentUrl && currentUrl.length ? currentUrl[currentUrl.length - 1] : 'articles';
        const activeTag = defaultUrl === 'articles' ? 'all' : defaultUrl;

        const [path] = useAppStore(store => store.path);
        const [isTinaView] = useAppStore(store => store.isTinaView);

        const blockArticles = useRef<HTMLDivElement | null>(null);

        const [isDataFetching, setIsDataFetching] = useState(true);

        const [selectedTag, setSelectedTag] = useState<string>('all');

        const [activePage, setActivePage] = useState(0);
        const [currentTagList, setCurrentTagList] = useState(tagList);

        const [elementsList, setElementsList] = useState<IArtilceListData['list']>(list);

        const [navigation, setNavigation] = useState<IArtilceListPagination>(
            fetchedNavigation?.currentPage
                ? fetchedNavigation
                : {
                      currentPage: 1,
                      pageCount: 1,
                      perPage: 1,
                  }
        );

        // GET params
        const requestParams = [`&section=${section || 0}`, `&apiCode=${apiCode}`];

        const { data, loading } = useAxiosFetch(
            `/page/block?path=${path}&block=dzenPage${requestParams.join('')}&tag=${activeTag}${`&nav-${apiCode}=page-${
                activePage + 1
            }`}`,
            activeTag
        );

        useEffect(() => {
            if (data && typeof data !== 'undefined' && selectedTag === activeTag) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setElementsList(data.data.data?.list);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setNavigation(data.data.data?.navigation);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                setCurrentTagList(data.data.data?.tagList);
                setIsDataFetching(false);
            }
        }, [data]);

        const handlePaginate = (currentPage: ISelected) => {
            setIsDataFetching(true);
            setActivePage(currentPage.selected);
        };

        const handleSelectedTag = async (tag: string) => {
            const pathName = tag === 'all' ? '/articles/' : `/articles/${tag}`;
            setIsDataFetching(true);

            await Promise.resolve(() => router.push(pathName));
        };
        useEffect(() => {
            if (activeTag) {
                setIsDataFetching(true);
                setSelectedTag(activeTag);
            }
        }, []);

        useEffect(() => {
            // отображение данных в тине при смене секции
            if (isTinaView && apiCode) {
                setSelectedTag(activeTag);
                setActivePage(0);
            }
        }, [section, apiCode, isTinaView]);

        return (
            <div className={clsx(cn.news, 'section', 'news')} ref={blockArticles}>
                <div className={cn.newsWrap}>
                    <div className={cn.newsGrid}>
                        <div className={clsx(cn.newsItem, cn.newsItemList)}>
                            {currentTagList?.length > 0 && (
                                <div className={cn.filter}>
                                    <TagList
                                        tagList={currentTagList}
                                        onClick={handleSelectedTag}
                                        activeTag={selectedTag}
                                    />
                                </div>
                            )}

                            <AnimatedComponent
                                className={clsx(
                                    cn.list,
                                    (isDataFetching || elementsList?.length <= 0) && cn.listPreload
                                )}
                                classNameActive={cn.animationInit}
                            >
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    {(isDataFetching || elementsList?.length <= 0) && (
                                        <div className={cn.preloaderWrapper}>
                                            <Preloader type="articles" />
                                        </div>
                                    )}
                                    {elementsList &&
                                        elementsList?.length > 0 &&
                                        elementsList?.map(artilceElement => (
                                            <ArticleItem key={artilceElement.id} {...artilceElement} />
                                        ))}
                                </ErrorBoundary>
                            </AnimatedComponent>

                            <Pagination
                                pageCount={navigation?.pageCount}
                                handleClick={handlePaginate}
                                initialPage={navigation ? navigation.currentPage - 1 : 0}
                                tag={selectedTag}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ArticlesList;
