import React from 'react';

import { TinaAdvantagesV2 } from '@/components/blocks/advantages/Advantages-v2/index.tina';
import { mockAdvantagesBlockCreditCash } from '@/data/blocks/advantages';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const advantagesBlockV2 = {
    Component: props => <TinaAdvantagesV2 {...props} />,
    template: {
        label: 'Преимущества КН 1',
        defaultItem: () => mockAdvantagesBlockCreditCash,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockAdvantagesBlockCreditCash.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                        required: true,
                    },
                    {
                        name: 'subtitleMobile',
                        component: HtmlEditorFeatured,
                        label: 'Описание. Мобильная версия',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'mobileBgColor',
                        component: 'text',
                        label: 'Цвет фона блока на телефоне в формате hex/rgba',
                    },
                    {
                        name: 'isHoverNeeded',
                        component: 'toggle',
                        label: 'Ховер Эффект',
                    },
                    {
                        name: 'isClickable',
                        component: 'toggle',
                        label: 'Кликабелен ли блок',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        label: 'Текст подсказки',
                    },
                    {
                        name: 'size',
                        component: 'select',
                        label: 'Размер',
                        options: [
                            {
                                value: 'small',
                                label: 'Маленький',
                            },
                            {
                                value: 'middle',
                                label: 'Средний',
                            },
                            {
                                value: 'big',
                                label: 'Большой',
                            },
                            {
                                value: 'full',
                                label: 'Огромный',
                            },
                        ],
                    },
                    {
                        name: 'button',
                        component: 'group',
                        label: 'Кнопка',
                        fields: [
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
