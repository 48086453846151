import React from 'react';

import { TinaActionPensions } from '@/components/blocks/ActionPensions/index.tina';
import { mockActionPensionsBlockData, mockActionPensionsBlockDataTina } from '@/data/blocks/actionPensions';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';

export const actionPensionsBlock = {
    Component: props => <TinaActionPensions {...props} />,
    template: {
        label: 'Акции для пенсионеров',
        defaultItem: () => mockActionPensionsBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список акций',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                    title: item.title,
                }),
                defaultItem: () => mockActionPensionsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                        validate: (value: string) => {
                            if (!value) {
                                return 'Вы не заполнили наименование элемента';
                            }
                            return null;
                        },
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Короткое описание',
                    },
                    {
                        label: 'Кнопка',
                        name: 'btn',
                        component: 'group',
                        fields: [
                            {
                                name: 'type',
                                component: 'select',
                                label: 'Тип элемента',
                                options: [
                                    { value: 'button', label: 'Кнопка' },
                                    { value: 'link', label: 'Ссылка' },
                                    { value: 'buttonLink', label: 'Кнопка-ссылка' },
                                ],
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItemCustom('gtag', 'Цель на клик по кнопке') },
                        ],
                    },
                    {
                        name: 'isGradient',
                        component: 'toggle',
                        label: 'Включить градиент',
                    },
                    {
                        name: 'isArchive',
                        component: 'toggle',
                        label: 'Сделать архивной',
                    },
                    {
                        name: 'mark',
                        component: HtmlEditorFeatured,
                        label: 'Пометка о завершении акции',
                    },
                    {
                        name: 'modalTitle',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок в модальном окне',
                    },
                    {
                        name: 'modalDescription',
                        component: HtmlEditorFeatured,
                        label: 'Описание в модальном окне',
                    },
                ],
            },
            {
                label: 'Ссылка',
                name: 'btn',
                component: 'group',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItemCustom('gtag', 'Цель на клик по кнопке') },
                ],
            },
        ],
    },
};
