export const mockRequisitesBlockData = {
    name: 'Реквизиты',
    list: [
        {
            name: 'Наименование',
            items: [
                {
                    name: 'Полное наименование на русском языке',
                    desc: 'Акционерное общество «Почта Банк»',
                },
                {
                    name: 'Полное наименование на английском языке',
                    desc: 'Joint-stock company «Post Bank»',
                },
                {
                    name: 'Сокращенное наименование на русском языке',
                    desc: 'АО «Почта Банк»',
                },
                {
                    name: 'Сокращенное наименование на английском языке',
                    desc: 'JSC «Post Bank»',
                },
            ],
        },
        {
            name: 'Юридический и почтовый адрес',
            items: [
                {
                    name: 'Юридический и почтовый адрес',
                    desc: '107061, г. Москва,<br/>Преображенская площадь, д. 8',
                },
            ],
        },
        {
            name: 'Реквизиты банка',
            items: [
                {
                    name: 'Корреспондентский счет',
                    desc: '№30101810245250000214 в ГУ Банка России по Центральному федеральному округу',
                },
                {
                    name: 'ИНН',
                    desc: '3232005484',
                },
                {
                    name: 'БИК',
                    desc: '044525214',
                },
                {
                    name: 'Код ОКПО',
                    desc: '09106059',
                },
                {
                    name: 'ОКВЭД',
                    desc: '64.19',
                },
                {
                    name: 'ОГРН Банка',
                    desc: '1023200000010',
                },
                {
                    name: 'КПП крупнейшего налогоплательщика',
                    desc: '997950001',
                },
                {
                    name: 'КПП по месту нахождения',
                    desc: '771801001',
                },
            ],
        },
    ],
};

export const mockRequisitesBlockDataTina = {
    name: 'Заголовок',
    list: [
        {
            name: 'Заголовок',
            items: [
                {
                    name: 'Заголовок',
                    desc: 'Описание',
                },
            ],
        },
    ],
};
