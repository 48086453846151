export const mockBankAdvantagesBlockData = {
    _template: 'bankAdvantages',
    title: '«Почта Банк» — больше, чем банк',
    bgImg: '/img-next/png/bankAdvantages.png',
    list: [
        {
            name: 'Опытный.',
            desc: 'За нашими плечами — успешный опыт «Лето Банка»',
        },
        {
            name: 'Простой.',
            desc: 'Понятные условия кредитования и отсутствие скрытых комиссий',
        },
        {
            name: 'Удобный.',
            desc: 'Интернет- и мобильный банк помогут вам управлять своим счетом',
        },
        {
            name: 'Доступный.',
            desc: 'Реализуйте свои планы без лишних переплат по кредиту',
        },
        {
            name: 'Социально ориентированный.',
            desc: 'Выдача пенсий и особые возможности для пенсионеров',
        },
        {
            name: 'Близкий.',
            desc: '«Почта Банк» находится в шаговой доступности от вашего дома',
        },
        {
            name: 'На каждый случай.',
            desc: 'Большой выбор продуктов: кредитов, кредитных карт, вкладов и т.д.',
        },
        {
            name: 'Надежный.',
            desc: 'Защиту средств вкладчиков гарантирует поддержка Группы ВТБ и «Почты России»',
        },
        {
            desc: 'Почта Банк работает с такими платежными системами как: Visa, MasterCard и МИР',
            logo: [
                {
                    img: '/img-next/svg/bankAdvantages/Visa.svg',
                    alt: 'Visa',
                },
                {
                    img: '/img-next/svg/bankAdvantages/Master.svg',
                    alt: 'Master',
                },
                {
                    img: '/img-next/svg/bankAdvantages/Mir.svg',
                    alt: 'Mir',
                },
            ],
        },
    ],
};

export const mockBankAdvantagesBlockDataTina = {
    _template: 'bankAdvantages',
    title: 'Заголовок',
    bgImg: '',
    list: [
        {
            desc: 'Текст',
            logo: [
                {
                    img: '',
                    alt: 'Альтернативный текст',
                },
            ],
        },
    ],
};
