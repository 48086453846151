import React from 'react';

import { TinaBuildingCalculator } from '@/components/blocks/calculators/layouts/BuildingCalculator/index.tina';
import { buildingCalculator } from '@/data/blocks/calculators/building';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const buildingCalculatorBlock = {
    Component: props => <TinaBuildingCalculator {...props} />,
    template: {
        label: 'Калькулятор кредита на строительство',
        defaultItem: buildingCalculator,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
