import { IDistributionAskQuestion } from '@/components/blocks/DistributionAskQuestion';

export const mockDistributionAskQuestionBlockData: IDistributionAskQuestion = {
    _template: 'distributionAskQuestion',
    name: 'DistributionAskQuestion',
    title: 'Задать вопрос',
    description:
        'Получить консультацию по&nbsp;договору, продуктам и&nbsp;услугам вы&nbsp;можете в&nbsp;официальных каналах Клиентской службы Банка или найти ответ в&nbsp;<a href="#">Часто задаваемые вопросы</a>.',
    list: [
        {
            title: '8 800 550 0770',
            subtitle: 'Обратный звонок',
            tooltipText: 'Текст в тултипе',
            link: '88005500770',
            isTel: true,
            size: 'big',
            image: '/img-next/png/links/2.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Чат',
            subtitle: 'Перейти',
            link: '#',
            size: 'big',
            image: '/img-next/png/links/1.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Информация',
            subtitle: '',
            link: '#',
            size: 'big',
            image: '/img-next/png/links/3.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Вопросы по банкоматам',
            subtitle: '',
            tooltipText: 'Текст в тултипе',
            link: '#',
            size: 'small',
            isTel: false,
            image: '/img-next/png/links/2.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Сотрудничество с Банком',
            subtitle: '',
            link: '#',
            isTel: false,
            size: 'small',
            image: '/img-next/png/links/2.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Горячая линия комплаенс',
            subtitle: 'Обращения, связанные с нарушением законодательства РФ',
            link: '#',
            isTel: false,
            size: 'small',
            image: '/img-next/png/links/2.png',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};

export const mockDistributionAskQuestionBlockTinaData: IDistributionAskQuestion = {
    _template: 'distributionAskQuestion',
    name: 'DistributionAskQuestionBlock',
    title: 'Заголовок',
    description: 'Описание',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            tooltipText: 'Текст в тултипе',
            link: '#',
            isTel: true,
            size: 'big',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            size: 'big',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            size: 'big',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            tooltipText: 'Текст в тултипе',
            link: '#',
            isTel: true,
            size: 'small',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            size: 'small',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            link: '#',
            size: 'small',
            image: '',
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
        },
    ],
};
