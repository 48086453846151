import clsx from 'clsx';
import React from 'react';

import Item from '@/components/blocks/singleDocuments/Item';
import Title from '@/components/UI/Title';
import { ISingleDocuments } from '@/types/singleDocuments';

import cn from './style.module.sass';

const SingleDocuments: React.FC<ISingleDocuments> = ({ title, documents }) => (
    <div className={clsx(cn.wrapper, 'section')}>
        <div className={cn.fullWrapper}>
            <div className={clsx(cn.documents, cn.documentsInner)}>
                <Title level={6} title={title} name="title" />
                {documents?.map((item, index) => (
                    <Item key={index} pos={index} {...item} />
                ))}
            </div>
        </div>
    </div>
);

export default SingleDocuments;
