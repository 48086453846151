export const mockQuestionsBlockData = {
    title: 'Популярные вопросы',
    list: [
        {
            name: 'Как работают кредитные карты',
            desc: 'Почта Банк выдает кредиты крупными суммами для больших покупок, а для более мелких повседневных трат предлагает клиентам оформить кредитную карту для любых потребностей. Наличные часто требуются людям до зарплаты на некоторые покупки, но с кредитных карточек деньги можно тратить и безналичным путем. Клиенту банка запросто высылают кредитные карты даже почтой по всей России, стоит просто выбрать удобный продукт Почта Банка.',
        },
        {
            name: 'Что такое льготный период',
            desc: 'Льготный беспроцентный период кредитования (также его называют грейс-период) — это время, в течение которого вы можете пользоваться деньгами со своей кредитной карты и не платить проценты.',
        },
        {
            name: 'Что такое процентная ставка',
            desc: 'Процентная ставка — это процент от суммы кредита, который начисляется за использование денег с кредитной карты в расчете на год. Банк назначает процентную ставку индивидуально для каждого клиента и указывает ее в тарифе кредитной карты.',
        },
        {
            name: 'Что такое кредитный лимит',
            desc: 'Лимит — это предельное количество средств, которые вы можете потратить на свои нужды при помощи карточки. Причем независимо от того, как вы это делаете: оплачиваете ли картой покупки и услуги или снимаете деньги наличными. В случае с кредитной картой: на ней изначально лежат средства банка, предоставленные вам в использование, — они и составляют лимит.',
        },
        {
            name: 'Как оформить кредитную карту',
            desc: 'На официальном сайте банка можно подать заявление на любую кредитную карту, кроме «Почтового экспресса». Для этого необходимо: на сайте выбрать раздел «Кредитные карты»; познакомиться с информацией о предлагаемых банком продуктах и выбрать наиболее подходящий; в открывшейся форме заполнить все поля, в т. ч. указать желаемую сумму кредитного лимита; после нажатия кнопки «Далее» откроется форма, в которую надо внести паспортные данные; на последнем этапе заполнения следует указать данные о работодателе, стаж работы и сумму дохода.',
        },
        {
            name: 'Другие предложения по кредитным картам',
            desc: 'Кредитные карты с быстрым решением от Почта Банка. Узнайте решение онлайн в течение 10-ти минут. Подробные условия кредитования и тарифы. Оформите онлайн заявку на карту прямо сейчас!',
        },
    ],
};

export const mockQuestionsBlockDataHr = {
    questionsLimit: 6,
    list: [
        {
            name: 'Как работают кредитные карты',
            desc: 'Почта Банк выдает кредиты крупными суммами для больших покупок, а для более мелких повседневных трат предлагает клиентам оформить кредитную карту для любых потребностей. Наличные часто требуются людям до зарплаты на некоторые покупки, но с кредитных карточек деньги можно тратить и безналичным путем. Клиенту банка запросто высылают кредитные карты даже почтой по всей России, стоит просто выбрать удобный продукт Почта Банка.',
        },
        {
            name: 'Что такое льготный период',
            desc: 'Льготный беспроцентный период кредитования (также его называют грейс-период) — это время, в течение которого вы можете пользоваться деньгами со своей кредитной карты и не платить проценты.',
        },
        {
            name: 'Что такое процентная ставка',
            desc: 'Процентная ставка — это процент от суммы кредита, который начисляется за использование денег с кредитной карты в расчете на год. Банк назначает процентную ставку индивидуально для каждого клиента и указывает ее в тарифе кредитной карты.',
        },
        {
            name: 'Что такое кредитный лимит',
            desc: 'Лимит — это предельное количество средств, которые вы можете потратить на свои нужды при помощи карточки. Причем независимо от того, как вы это делаете: оплачиваете ли картой покупки и услуги или снимаете деньги наличными. В случае с кредитной картой: на ней изначально лежат средства банка, предоставленные вам в использование, — они и составляют лимит.',
        },
        {
            name: 'Как оформить кредитную карту',
            desc: 'На официальном сайте банка можно подать заявление на любую кредитную карту, кроме «Почтового экспресса». Для этого необходимо: на сайте выбрать раздел «Кредитные карты»; познакомиться с информацией о предлагаемых банком продуктах и выбрать наиболее подходящий; в открывшейся форме заполнить все поля, в т. ч. указать желаемую сумму кредитного лимита; после нажатия кнопки «Далее» откроется форма, в которую надо внести паспортные данные; на последнем этапе заполнения следует указать данные о работодателе, стаж работы и сумму дохода.',
        },
        {
            name: 'Другие предложения по кредитным картам',
            desc: 'Кредитные карты с быстрым решением от Почта Банка. Узнайте решение онлайн в течение 10-ти минут. Подробные условия кредитования и тарифы. Оформите онлайн заявку на карту прямо сейчас!',
        },
        {
            name: 'Другие предложения по кредитным картам',
            desc: 'Кредитные карты с быстрым решением от Почта Банка. Узнайте решение онлайн в течение 10-ти минут. Подробные условия кредитования и тарифы. Оформите онлайн заявку на карту прямо сейчас!',
        },
    ],
};

export const mockQuestionsBlockDataHrTags = {
    title: 'Популярные вопросы',
    list: [
        {
            name: 'Как работают кредитные карты',
            tags: ['1', '2', '3'],
            desc: 'Почта Банк выдает кредиты крупными суммами для больших покупок, а для более мелких повседневных трат предлагает клиентам оформить кредитную карту для любых потребностей. Наличные часто требуются людям до зарплаты на некоторые покупки, но с кредитных карточек деньги можно тратить и безналичным путем. Клиенту банка запросто высылают кредитные карты даже почтой по всей России, стоит просто выбрать удобный продукт Почта Банка.',
        },
        {
            name: 'Что такое льготный период',
            tags: ['1', '3'],
            desc: 'Льготный беспроцентный период кредитования (также его называют грейс-период) — это время, в течение которого вы можете пользоваться деньгами со своей кредитной карты и не платить проценты.',
        },
        {
            name: 'Что такое процентная ставка',
            tags: ['1', '2'],
            desc: 'Процентная ставка — это процент от суммы кредита, который начисляется за использование денег с кредитной карты в расчете на год. Банк назначает процентную ставку индивидуально для каждого клиента и указывает ее в тарифе кредитной карты.',
        },
        {
            name: 'Что такое кредитный лимит',
            tags: ['2', '3'],
            desc: 'Лимит — это предельное количество средств, которые вы можете потратить на свои нужды при помощи карточки. Причем независимо от того, как вы это делаете: оплачиваете ли картой покупки и услуги или снимаете деньги наличными. В случае с кредитной картой: на ней изначально лежат средства банка, предоставленные вам в использование, — они и составляют лимит.',
        },
        {
            name: 'Как оформить кредитную карту',
            tags: ['1', '2'],
            desc: 'На официальном сайте банка можно подать заявление на любую кредитную карту, кроме «Почтового экспресса». Для этого необходимо: на сайте выбрать раздел «Кредитные карты»; познакомиться с информацией о предлагаемых банком продуктах и выбрать наиболее подходящий; в открывшейся форме заполнить все поля, в т. ч. указать желаемую сумму кредитного лимита; после нажатия кнопки «Далее» откроется форма, в которую надо внести паспортные данные; на последнем этапе заполнения следует указать данные о работодателе, стаж работы и сумму дохода.',
        },
        {
            name: 'Другие предложения по кредитным картам',
            tags: ['2', '3'],
            desc: 'Кредитные карты с быстрым решением от Почта Банка. Узнайте решение онлайн в течение 10-ти минут. Подробные условия кредитования и тарифы. Оформите онлайн заявку на карту прямо сейчас!',
        },
    ],
    tagList: [
        {
            xmlId: '1',
            value: 'Первый',
        },
        {
            xmlId: '2',
            value: 'Второй',
        },
        {
            xmlId: '3',
            value: 'Третий',
        },
    ],
};

export const mockQuestionsBlockDataDebet = {
    title: 'Популярные вопросы',
    list: [
        {
            name: 'Как работают дебетовые карты',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus sem imperdiet nunc porttitor, eu tincidunt magna dictum. Etiam purus magna, laoreet ut pretium eget, rutrum nec lacus. Ut porta metus nulla, et eleifend eros posuere id. Curabitur vel ligula vitae justo faucibus euismod. Mauris semper consequat magna, sit amet eleifend lacus facilisis sollicitudin. Duis sagittis iaculis maximus. ',
        },
        {
            name: 'Как оформить дебетовую карту',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus sem imperdiet nunc porttitor, eu tincidunt magna dictum. Etiam purus magna, laoreet ut pretium eget, rutrum nec lacus. Ut porta metus nulla, et eleifend eros posuere id. Curabitur vel ligula vitae justo faucibus euismod. Mauris semper consequat magna, sit amet eleifend lacus facilisis sollicitudin. Duis sagittis iaculis maximus. ',
        },
        {
            name: 'Другие предложения по дебетовым картам',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus sem imperdiet nunc porttitor, eu tincidunt magna dictum. Etiam purus magna, laoreet ut pretium eget, rutrum nec lacus. Ut porta metus nulla, et eleifend eros posuere id. Curabitur vel ligula vitae justo faucibus euismod. Mauris semper consequat magna, sit amet eleifend lacus facilisis sollicitudin. Duis sagittis iaculis maximus.',
        },
    ],
};

export const mockQuestionsBlockDataTina = {
    title: 'Заголовок',
    list: [
        {
            name: 'Заголовок',
            desc: 'Описание',
        },
    ],
};
