import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import AdvantageItemV2 from '@/components/blocks/advantages/Advantages-v2/item';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IAdvantagesFilteredItems } from '@/types/advantages/item';
import { TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    list: IAdvantagesFilteredItems;
    name: string;
    text: string;
}

const AdvantagesList: React.FC<IProps> = observer(({ list, name, text }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const width = useResize();
    const isDesktop = width > TABLET_WIDTH && typeof window !== 'undefined';

    return (
        <div className={clsx(cn.advantagesGrid)}>
            <div className={cn.top}>
                <div className={clsx(cn.topItem, cn.topItemTitle)}>
                    {name && <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />}
                    {text && (
                        <TextField text={text} name="text" isHTML htmlValue={text} className={cn.advantagesText} />
                    )}
                </div>
            </div>

            {isDesktop ? (
                <div className={cn.advantagesList}>
                    {list.desktop?.map(item => (
                        <AdvantageItemV2 {...item} key={`main-${item.id || item.pos}`} pos={item.pos} />
                    ))}
                </div>
            ) : (
                <>
                    {list?.mobile?.main?.length > 0 && (
                        <div className={cn.advantagesList}>
                            {list?.mobile?.main?.map(item => (
                                <AdvantageItemV2 {...item} key={`main-${item.id || item.pos}`} pos={item.pos} />
                            ))}
                        </div>
                    )}

                    <div
                        ref={containerRef}
                        className={clsx(
                            cn.advantagesList,
                            cn.advantagesSlider,
                            list.mobile.main?.length <= 0 && cn.advantagesSliderNoMargin
                        )}
                    >
                        {list?.mobile?.slider.map((item, index) => (
                            <div
                                key={`mobile-${item.id || item.pos}`}
                                className={cn.advantagesItemContainer}
                                style={{
                                    top: index * 25 + 60,
                                }}
                            >
                                <AdvantageItemV2 {...item} pos={item.pos} />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
});

export default AdvantagesList;
