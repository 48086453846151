import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import CustomSelect from '@/components/UI/CustomSelect';
import { useCity } from '@/context/CityProvider';

import cn from './style.module.sass';

export interface ISelectableWithValue {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'selectableWithValue';
    firstFieldName?: string;
    firstFieldDescription?: string;
    firstFieldTitle?: string;
    secondFieldName?: string;
    options?: {
        label: string;
        value: unknown;
    }[];
}

interface IFormType {
    selectField: { value: string; label: string };
    inputValue: string;
}

const SelectableWithValue: FC<ISelectableWithValue> = observer(
    ({ _template, firstFieldTitle, firstFieldName, firstFieldDescription, secondFieldName, options }) => {
        const { control, watch } = useForm<IFormType>();
        const selectFieldWatcher = watch('selectField', {
            value: null,
            label: null,
        });

        const { currentCity } = useCity();

        const defaultOption = useMemo(
            () => options?.find(item => item.label.toLowerCase() === currentCity.city.toLowerCase()),
            [options, currentCity]
        );

        return (
            <div className="section">
                <form className={clsx(cn.fullWrapper, 'section')}>
                    <div className={cn.wrapper}>
                        <div className={cn.selectLabel}>
                            <TextField text={firstFieldTitle} name="firstFieldTitle" className={cn.firstFieldTitle} />
                            <TextField
                                text={firstFieldDescription}
                                name="firstFieldDescription"
                                className={cn.firstFieldDescription}
                            />
                            <Controller
                                name="selectField"
                                control={control}
                                render={({ field }) => (
                                    <CustomSelect
                                        {...field}
                                        onChangeHandler={e => {
                                            field.onChange(e);
                                        }}
                                        placeholder={firstFieldName}
                                        options={options}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        defaultValue={defaultOption}
                                    />
                                )}
                            />
                        </div>
                        <div className={cn.renderValue}>
                            {(selectFieldWatcher?.label || defaultOption?.value) && (
                                <>
                                    <TextField
                                        className={cn.secondFieldName}
                                        customTag="p"
                                        name="secondFieldName"
                                        text={secondFieldName}
                                    />
                                    <TextField
                                        name="text"
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        text={selectFieldWatcher?.value ?? defaultOption?.value}
                                        className={cn.resultText}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
);

export default withBlockLinks(SelectableWithValue);
