export const mockTariffBlockData = {
    list: [
        {
            name: 'Потребительские кредиты',
            items: [
                {
                    archive: false,
                    title: 'Тариф «Суперпочтовый»',
                    document: '#',
                    size: '64KB',
                    documentType: 'pdf',
                },
                {
                    archive: false,
                    title: 'Тариф «Почтовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный 12,9»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный 1 500 000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Первый Почтовый 12,9»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Первый Почтовый 1 000 000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Льготный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Льготный Особый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Просто Кредит»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Адресный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Адресный Специальный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Адресный Первый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Суперпочтовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Почтовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный 12,9»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный 1 500 000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Первый Почтовый 12,9»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Первый Почтовый 1 000 000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Льготный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Льготный Особый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Просто Кредит»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Адресный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Адресный Специальный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Адресный Первый»',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
        {
            name: 'Кредитные карты',
            items: [
                {
                    archive: false,
                    title: 'Тариф по кредитной карте «Марки»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф по кредитной карте «Вездедоход»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф по кредитной карте «Элемент 120»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф по кредитной карте «Элемент 120» (для зарплатных клиентов)',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Пятёрочка» по программе «Кредитная карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф по кредитной карте «Зеленый мир»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Лето Карта 5000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Лето Карта 10000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Лето-карта Элемент»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тарифы по «Лето-картам» оформленным до 1 января 2015 года',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф по кредитной карте «Марки»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф по кредитной карте «Вездедоход»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф по кредитной карте «Элемент 120»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф по кредитной карте «Элемент 120» (для зарплатных клиентов)',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Пятёрочка» по программе «Кредитная карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф по кредитной карте «Зеленый мир»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Лето Карта 5000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Лето Карта 10000»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Лето-карта Элемент»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тарифы по «Лето-картам» оформленным до 1 января 2015 года',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
        {
            name: 'Дебетовые карты',
            items: [
                {
                    archive: false,
                    title: 'Тариф «Вездедоход» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Марки» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Карта для покупок»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Карта для покупок для зарплатных клиентов»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Пятерочка» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Киберлаб» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Онлайн Карта 2.0» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Вездедоход» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Марки» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Карта для покупок»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Карта для покупок для зарплатных клиентов»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Пятерочка» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Киберлаб» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Онлайн Карта 2.0» по программе «Дебетовая карта»',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
        {
            name: 'Вклады',
            items: [
                {
                    archive: false,
                    title: 'Вклад «Новый сезон»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Вклад «Капитальный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Вклад «Доходный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Вклад «Накопительный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Вклад «Почтовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Вклад «Новый сезон»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Вклад «Капитальный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Вклад «Доходный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Вклад «Накопительный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Вклад «Почтовый»',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
        {
            name: 'Сберегательный счет',
            items: [
                {
                    archive: false,
                    title: 'Тариф «Базовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Пенсионный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный Пенсионер»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Зарплатный Корпорация»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тариф «Активный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Базовый»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Пенсионный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный Пенсионер»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Зарплатный Корпорация»',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тариф «Активный»',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
        {
            name: 'Дистанционное банковское обслуживание',
            items: [
                {
                    archive: false,
                    title: 'Тарифы на оказание услуг по переводу денежных средств с использованием банковских карт',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тарифы на совершение переводов денежных средств через Почта Банк Онлайн',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тарифы на совершение платежей и переводов в банкоматах/терминалах банка',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Договор р2р',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: false,
                    title: 'Тарифы р2р',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тарифы на оказание услуг по переводу денежных средств с использованием банковских карт',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тарифы на совершение переводов денежных средств через Почта Банк Онлайн',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тарифы на совершение платежей и переводов в банкоматах/терминалах банка',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Договор р2р',
                    document: '#',
                    size: '64KB',
                },
                {
                    archive: true,
                    title: 'Тарифы р2р',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
    ],
};

export const mockTariffBlockDataTina = {
    list: [
        {
            name: 'Заголовок',
            items: [
                {
                    archive: false,
                    title: 'Заголовок',
                    document: '#',
                    size: '64KB',
                },
            ],
        },
    ],
};
