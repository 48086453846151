import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { IExchangeRatesItem } from '@/types/exchangeRates/item';

import cn from './style.module.sass';

const PopularExchangeRatesItem: React.FC<IExchangeRatesItem> = ({ title, desc, buy, sale }) => (
    <AnimatedComponent className={cn.item} classNameActive={cn.animationInit}>
        <div className={cn.itemTop}>
            <div className={cn.row}>
                <div className={clsx(cn.col, cn.colFirst)}>Валюта</div>
                <div className={cn.col}>Покупка</div>
                <div className={cn.col}>Продажа</div>
            </div>
        </div>
        <div className={cn.itemBottom}>
            <div className={cn.row}>
                <div className={clsx(cn.col, cn.colFirst)}>
                    <div className={cn.innerWrapperFirst}>
                        <div className={cn.title}>{title}</div>
                        <div className={cn.desc}>{desc}</div>
                    </div>
                </div>
                <div className={cn.col}>
                    <div className={clsx(cn.innerWrapper, buy.flag && cn.flag)}>
                        <div className={cn.value}>{buy?.value}</div>
                        <div className={cn.difference}>{buy?.difference}</div>
                    </div>
                </div>
                <div className={cn.col}>
                    <div className={clsx(cn.innerWrapper, sale.flag && cn.flag)}>
                        <div className={cn.value}>{sale?.value}</div>
                        <div className={cn.difference}>{sale?.difference}</div>
                    </div>
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default PopularExchangeRatesItem;
