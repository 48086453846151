import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

interface IButton {
    label: string;
    link: string;
}

export interface IMobileAppBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'mobileAppBanner';
    title: string;
    text: string;
    img: string;
    buttons: IButton[];
}

function MobileAppBanner({ title, text, img, buttons }: IMobileAppBanner) {
    return (
        <div className={clsx(cn.blockWrap, 'section')}>
            <div className={cn.banner}>
                <div className={cn.bannerContent}>
                    <div className={cn.left}>
                        <div className={cn.bannerTitle}>{title}</div>
                        <div className={cn.bannerText} dangerouslySetInnerHTML={{ __html: text }} />
                        {!!buttons?.length && (
                            <div className={cn.buttons}>
                                {buttons.map((item, index) => (
                                    <div className={cn.btn} key={`${item.link}-${item.label}-${index}`}>
                                        <Button
                                            customClass={cn.full}
                                            variant="btnWhite"
                                            type="link"
                                            href={item.link}
                                            label={item.label}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {img && (
                        <div className={cn.right}>
                            <CustomImage className={cn.bannerImage} src={img} fill alt={title} quality={100} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default withBlockLinks(MobileAppBanner);
