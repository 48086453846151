import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import PreloaderSnowFlake from '@/components/UI/PreloaderSnowFlake';
import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';
import { IError } from '@/types/data';
import { Cookie } from '@/utils/cookie';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface ITextBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'textBanner';
    startText?: string;
    text?: string;
    victoryText?: string;
    step?: string;
}

interface IResponse {
    data: { data: { number: number } & { notFound?: boolean }; errors: IError[]; status: string };
}

function TextBanner({ startText, text, victoryText, step }: ITextBanner) {
    let cookieCountViewsValue = '';
    let cookieSessionValue = '';

    if (typeof window !== 'undefined') {
        cookieCountViewsValue = Cookie.getCookie('lotteryNumber');
        cookieSessionValue = Cookie.getCookie('_ym_uid');
    }

    const [countViews, setCountViews] = useState<number>(+cookieCountViewsValue || null);

    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response: IResponse = await apiRequest(`/counter?session=${cookieSessionValue}`);

            if (response.data.errors?.length > 0) {
                throw new Error(JSON.stringify(response.data.errors[0]));
            }

            setCountViews(response.data.data.number);
            document.cookie = `lotteryNumber=${response.data.data.number}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT;`;
            setIsLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useIsomorphicEffect(() => {
        if (!cookieCountViewsValue) {
            fetchData().catch(console.error);
        }
    }, []);

    const isVictory = countViews > 0 && Number.isInteger(countViews / (+step || 10));

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    <div className={clsx(cn.innerWrap)}>
                        <div className={cn.textWrapper}>
                            <div className={clsx(cn.title, isLoading && cn.titleWrap)}>
                                <span dangerouslySetInnerHTML={{ __html: startText }} />
                                {isLoading ? <PreloaderSnowFlake /> : countViews}
                            </div>

                            <div className={cn.desc}>
                                {isVictory && victoryText && (
                                    <TextField
                                        className={cn.text}
                                        text={victoryText}
                                        name="victoryText"
                                        isHTML
                                        htmlValue={victoryText}
                                    />
                                )}
                                {!isVictory && text && (
                                    <TextField className={cn.text} text={text} name="text" isHTML htmlValue={text} />
                                )}
                            </div>
                        </div>
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
}

export default withBlockLinks(TextBanner);
