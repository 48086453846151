import clsx from 'clsx';
import React from 'react';

import { IDictionarySection } from '@/types/dictionary';

import cn from './style.module.sass';

interface IProps {
    item: IDictionarySection;
    activeSection: IDictionarySection;
    sectionChangeHandler: (section: IDictionarySection) => void;
}

const FilterItem: React.FC<IProps> = ({ activeSection, item, sectionChangeHandler }) => {
    const isActive = activeSection?.label === item?.label;
    const isDisabled = !item?.list?.length;

    const onClick = () => {
        if (!isDisabled && !isActive) {
            sectionChangeHandler(item);
        }
    };

    return (
        <div
            className={clsx(
                cn.filterElement,
                isActive && cn.filterElementActive,
                isDisabled && cn.filterElementDisabled
            )}
            onClick={onClick}
            role="presentation"
        >
            {item?.label}
        </div>
    );
};

export default FilterItem;
