import React from 'react';

import { TinaPboFaqDetail } from '@/components/blocks/PboFaqDetail/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const pboFaqDetailBlock = {
    Component: props => <TinaPboFaqDetail {...props} />,
    template: {
        _template: 'pboFaqDetail',
        label: 'Детальная страница FAQ для ПБО',
        defaultItem: () => ({
            title: 'Заголовок',
            content: 'Текст',
            button: {
                href: '#',
                label: '',
            },
            promotion: {
                link: '#',
                button: 'Подробнее',
                text: 'Скачать Почта Банк Онлайн',
                image: undefined,
            },
        }),
        fields: [
            {
                name: 'img',
                component: 'pboCategory',
                label: 'Категория ПБО',
            },
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                component: HtmlEditorFeatured,
                name: 'content',
                label: 'Контент',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'href',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Название',
                    },
                ],
            },
            {
                name: 'promotion',
                component: 'group',
                label: 'Предложение',
                fields: [
                    {
                        name: 'text',
                        component: 'text',
                        label: 'Заголовок',
                    },

                    {
                        name: 'button',
                        component: 'text',
                        label: 'Название кнопки',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
