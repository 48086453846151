import React from 'react';

import { TinaVezdedohodCard } from '@/components/blocks/frames/VezdedohodCard/index.tina';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

export const vezdedohodCardBlock = {
    Component: (props: IBlockProps) => <TinaVezdedohodCard {...props} />,
    template: {
        label: 'Заявка на дебетовую карту Вездедоход',
        defaultItem: () => ({
            title: 'Заголовок',
            gtagClick: {
                action: 'click',
                category: 'interactions',
                label: 'debet_voddohod_step1_dalee',
            },
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            { ...gtagSingleItemCustom('gtagClick', 'Цель на успешную отправку') },
        ],
    },
};
