import clsx from 'clsx';
import React, { FC } from 'react';

import cn from './style.module.sass';

type ResultProgressProps = {
    text: string;
    progress: number;
    sum: string | number;
    symbol?: string;
    textClassName?: string;
    isTextStarred?: boolean;
};

const ResultProgress: FC<ResultProgressProps> = ({
    text,
    progress,
    sum,
    symbol,
    textClassName,
    isTextStarred = false,
}) => (
    <div className={cn.wrap}>
        <div className={cn.row}>
            <div className={clsx(cn.text, textClassName, isTextStarred && cn.textRequired)}>{text}</div>
            <span className={cn.result}>{`${sum} ${symbol || '₽'}`}</span>
        </div>
        <span className={cn.progress}>
            <span className={cn.progressSilver} />
            <span className={cn.progressBlue} style={{ width: `${progress}%` }} />
        </span>
    </div>
);

export default ResultProgress;
