import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import RkoCalculatorContent from '@/components/blocks/calculators/layouts/RkoCalculator/Content';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import RkoCalculatorStore, { IFieldsConfig } from '@/domain/calculator/RkoCalculatorStore';
import { IRkoItem } from '@/types/rko/item';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IRkoCalculator {
    _template?: 'rkoCalculator';
    title?: string;
    tabTitle?: string;
    fields: IFieldsConfig;
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
    maxCache: number;
    items: IRkoItem[];
}

const RkoCalculator: React.FC<IRkoCalculator> = observer(({ title, tabTitle, fields, gtag, items }) => {
    if (!fields) return null;

    useEffect(() => {
        RkoCalculatorStore.init(fields);
    }, []);

    return (
        <CalculatorStoreContextNew.Provider value={RkoCalculatorStore}>
            {RkoCalculatorStore.initialized && (
                <RkoCalculatorContent title={title} gtag={gtag} tabTitle={tabTitle} items={items} />
            )}
        </CalculatorStoreContextNew.Provider>
    );
});

export default RkoCalculator;
