import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IFaqQuestionsItem {
    title: string;
    image?: string;
    link: string;
    gtag?: GTagEvent;
}

function FaqQuestionsItem({ title, image, link, gtag, index }: IFaqQuestionsItem & { index: number }) {
    return (
        <AnimatedComponent
            className={cn.linksItem}
            classNameActive={cn.animationInit}
            key={`key-${index}`}
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
            role="presentation"
        >
            <div className={cn.linksItemWrap}>
                <a className={cn.linksItemLink} href={link}>
                    <div className={cn.linksImage}>
                        <CustomImage mockWidth="42" mockHeight="42" src={image} alt={title} fill />
                    </div>
                    <TextField
                        className={cn.linksTitle}
                        text={title}
                        name={`list.[${index}].title`}
                        isHTML
                        htmlValue={title}
                    />
                </a>
            </div>
        </AnimatedComponent>
    );
}

export default FaqQuestionsItem;
