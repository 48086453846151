import clsx from 'clsx';
import React from 'react';

import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ITariffsRestrictions {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'tariffsRestrictions';
    title?: string;
    list: ITariffsRestrictionsItem[];
}

interface ITariffsRestrictionsItem {
    itemValue: string;
}

const TariffsRestrictions: React.FC<ITariffsRestrictions> = ({ title, list }) => {
    if (list?.length <= 0) return null;

    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={cn.innerWrapper}>
                {title && <TextField text={title} customTag="h3" name="title" isTextArea className={cn.title} />}
                <div className={cn.blockWrapper}>
                    <div className={cn.content}>
                        <ul>
                            {list?.map((item, index) => (
                                <li className={cn.item} key={`index-${index}`}>
                                    <TextField
                                        className={cn.itemValue}
                                        text={item.itemValue}
                                        name={`list[${index}].itemValue`}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TariffsRestrictions;
