import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useTheme } from '@/context/ThemeProvider';
import { IInstructionItem, ITagItem } from '@/types/instruction/item';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IInstruction {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'instruction';
    /**
     * Список элементов. макс 3.
     */
    list: IInstructionItem[];
    tagList?: ITagItem[];
    name: string;
    text?: string;
    button?: {
        name: string;
        link: string;
        gtag?: GTagEvent;
    };
    descText: string;
}

const Instruction: React.FC<IInstruction> = ({ name, text, list, tagList, button, descText }) => {
    // theme нужен для опредения цвета OR кода
    const { theme } = useTheme();

    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const items = useMemo(() => {
        if (!tagList?.length) return list?.slice(0, 3);
        return list
            ?.reduce((acc: IInstructionItem[], current, index) => {
                if (current.tags?.includes(selectedTag)) {
                    return [...acc, { ...current, pos: index }];
                }
                return acc;
            }, [])
            ?.slice(0, 3);
    }, [tagList, list, selectedTag]);

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.instruction, 'section')}>
                <div className={cn.instructionWrap}>
                    <div className={cn.top}>
                        {name && (
                            <div className={clsx(cn.topItem, cn.topItemTitle)}>
                                <TextField
                                    text={name}
                                    name="name"
                                    isHTML
                                    htmlValue={name}
                                    className={cn.instructionMainTitle}
                                />
                                {text && (
                                    <TextField
                                        text={text}
                                        name="text"
                                        isHTML
                                        htmlValue={text}
                                        className={cn.instructionText}
                                    />
                                )}
                            </div>
                        )}
                        {tagList && tagList?.length > 0 && (
                            <div className={clsx(cn.topItem, cn.topItemFilter)}>
                                <AnimatedComponent className={clsx(cn.filter)} classNameActive={cn.animationInit}>
                                    <TagList
                                        tagList={tagList}
                                        limit={2}
                                        onClick={setSelectedTag}
                                        activeTag={selectedTag}
                                    />
                                </AnimatedComponent>
                            </div>
                        )}
                    </div>
                    <div className={cn.instructionGrid}>
                        {items?.map(({ title, subtitle, btn, pos, qr }, index) => (
                            <AnimatedComponent
                                classNameActive={cn.animationInit}
                                className={cn.instructionItem}
                                key={pos || index}
                                data-length={items.length}
                            >
                                <div className={cn.instructionItemWrap}>
                                    <div className={cn.instructionContent}>
                                        {title && (
                                            <TextField
                                                text={title}
                                                name={`list.[${pos || index}].title`}
                                                className={cn.instructionTitle}
                                                isHTML
                                                htmlValue={title}
                                            />
                                        )}
                                        {subtitle && (
                                            <TextField
                                                text={subtitle}
                                                name={`list.[${pos || index}].subtitle`}
                                                className={cn.instructionSubtitle}
                                                isHTML
                                                htmlValue={subtitle}
                                            />
                                        )}
                                        {qr?.image && qr?.imageDarkTheme && (
                                            <div className={cn.centeredQr}>
                                                <div className={cn.qr}>
                                                    {theme === 'dark' ? (
                                                        <CustomImage
                                                            src={qr.imageDarkTheme}
                                                            alt={qr.alt}
                                                            width={130}
                                                            height={130}
                                                        />
                                                    ) : (
                                                        <CustomImage
                                                            src={qr.image}
                                                            alt={qr.alt}
                                                            width={130}
                                                            height={130}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {btn?.title && (
                                            <div className={cn.instructionBtn}>
                                                <Button
                                                    variant="btnBlue"
                                                    type="link"
                                                    label={btn.title}
                                                    href={btn.link}
                                                    size="small"
                                                    animation={false}
                                                    onClick={() => {
                                                        if (btn?.gtag?.action) event(btn?.gtag);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AnimatedComponent>
                        ))}
                    </div>
                    {descText && (
                        <div className={cn.instructionBot}>
                            <TextField
                                text={descText}
                                name="descText"
                                isHTML
                                htmlValue={descText}
                                className={cn.instructionDescText}
                            />
                        </div>
                    )}

                    {button?.name && (
                        <div className={cn.buttonWrapper}>
                            <Button
                                variant="btnBlue"
                                type="link"
                                href={button.link}
                                label={button.name}
                                size="small"
                                customClass={cn.button}
                                onClick={() => {
                                    if (button?.gtag?.action) event(button?.gtag);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Instruction);
