import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import Banner from '@/components/blocks/calculators/Banner';
import CalculatorConclusions from '@/components/blocks/calculators/CalculatorConclusions';
import Conditions from '@/components/blocks/calculators/Conditions';
import InfoTitle, { ICalculatorDisclaimer } from '@/components/blocks/calculators/InfoTitle';
import SharingButton from '@/components/blocks/calculators/layouts/CreditRealEstateCalculator/Content/sharingButton';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICreditRealEstateCalculatorStore } from '@/domain/calculator/CreditRealEstateCalculatorStore';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface ICalculatorBannerRanges {
    title: string;
    subtitle: string;
    min: number;
    max: number;
    image?: string;
    shouldHide?: boolean;
}

interface ICalculatorBanner {
    ranges?: ICalculatorBannerRanges[];
    isActive: boolean;
}

export interface ICreditRealEstateCalculatorContent {
    title?: string;
    tabTitle?: string;
    banner?: ICalculatorBanner;
    infoTitle?: ICalculatorDisclaimer;
    button?: {
        label?: string;
        link: string;
    };
    buttonShared?: {
        isActive: boolean;
    };
    index: number;
    gtag: {
        gtagButton: GTagEvent;
        gtagSlider: GTagEvent;
        gtagTerm: GTagEvent;
        gtagButtonShare: GTagEvent;
    };
}

const CreditRealEstateCalculatorContent: React.FC<ICreditRealEstateCalculatorContent> = observer(
    ({ title, index, gtag, banner, button, buttonShared, infoTitle }) => {
        const { getMonthlyPayment, rate, creditValue, sumElements, termsElements } =
            useContext<ICreditRealEstateCalculatorStore>(CalculatorStoreContext);

        const [currentBanner, setCurrentBanner] = useState<ICalculatorBannerRanges>(null);

        const width = useResize();

        const formattedMonthlyPayment = `${getMonthlyPayment()
            .toString()
            // eslint-disable-next-line no-irregular-whitespace
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽`;

        const isMobile = width <= MOBILE_WIDTH;

        const formattedRate = `${rate}%`;

        useEffect(() => {
            getMonthlyPayment();
        }, []);

        useEffect(() => {
            setCurrentBanner(banner?.ranges?.find(range => range.min <= +creditValue && range.max >= +creditValue));
        }, [banner, creditValue]);

        const currentInfoTitle = useMemo(
            () => infoTitle?.ranges?.find(range => range.min <= +creditValue && range.max >= +creditValue),
            [infoTitle, creditValue]
        );

        return (
            <div className="section">
                <div className={cn.container}>
                    <div className={cn.wrapper}>
                        {title && !banner?.isActive && (
                            <TextField
                                text={title}
                                customTag="h2"
                                name="title"
                                className={cn.title}
                                isHTML
                                htmlValue={title}
                            />
                        )}
                        <div className={cn.content}>
                            <div className={cn.left}>
                                {title && banner?.isActive && (
                                    <TextField
                                        text={title}
                                        customTag="h2"
                                        name="title"
                                        isTextArea
                                        className={cn.title}
                                    />
                                )}

                                <RangeInput config={sumElements} noMaxWidth />
                                <RangeInput config={termsElements} noMaxWidth />

                                {!isMobile && (
                                    <div className={cn.info}>
                                        {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                    </div>
                                )}
                            </div>
                            <div className={cn.right}>
                                {banner?.isActive && (
                                    <Banner banner={currentBanner} shouldHide={currentBanner?.shouldHide} />
                                )}
                                <div className={cn.rightWrapper}>
                                    <CalculatorConclusions
                                        title="Наше предложение"
                                        sum={formattedMonthlyPayment}
                                        percent={formattedRate}
                                    />
                                    <Conditions isEarningShow={+creditValue > 300000} />
                                    <div className={cn.buttonWrapper}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={button?.link || '/service/cashcredit#credit-issue-form-container'}
                                            label={button?.label || 'Заполнить заявку'}
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                    {buttonShared?.isActive && <SharingButton gtag={gtag?.gtagButtonShare} />}
                                </div>
                                {isMobile && (
                                    <div className={cn.info}>
                                        <div className={cn.info}>
                                            {infoTitle?.isActive && <InfoTitle {...currentInfoTitle} />}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default CreditRealEstateCalculatorContent;
