import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import CustomTooltip from '@/components/Tooltip';
import { useTheme } from '@/context/ThemeProvider';
import { protocol } from '@/utils/constants';

import cn from './style.module.sass';

export type ConditionsProps = {
    isEarningShow?: boolean;
    isRedesign?: boolean;
};

const Conditions: FC<ConditionsProps> = observer(({ isEarningShow, isRedesign }) => {
    const { theme } = useTheme();

    /* eslint-disable */
    return (
        <div className={clsx(cn.wrapper, isRedesign && cn.redesignWrapper)}>
            <div className={cn.title}>Для получения запрашиваемой суммы необходимо:</div>
            <div className={cn.list}>
                <div className={clsx(cn.item, isRedesign && cn.hidden)}>
                    <div className={cn.itemInner}>
                        <div className={cn.img}>
                            <CustomImage
                                src={`${protocol}${process.env.FRONT_ROOT_URL}/img-next/svg/calculators/passport${
                                    theme === 'dark' ? '-dark' : ''
                                }.svg`}
                                alt="Паспорт"
                                title="Паспорт"
                                width={36}
                                height={36}
                            />
                        </div>
                        <div className={cn.text}>Паспорт</div>
                    </div>
                </div>
                <div className={clsx(cn.item, isRedesign && cn.hidden)}>
                    <div className={cn.itemInner}>
                        <div className={cn.img}>
                            <CustomImage
                                src={`${protocol}${process.env.FRONT_ROOT_URL}/img-next/svg/calculators/snils${
                                    theme === 'dark' ? '-dark' : ''
                                }.svg`}
                                alt="СНИЛС"
                                title="СНИЛС"
                                width={36}
                                height={36}
                            />
                        </div>
                        <div className={cn.text}>СНИЛС</div>
                    </div>
                </div>
                {isRedesign && (
                    <>
                        <div className={clsx(cn.item, cn.passport)}>
                            <div className={cn.itemInner}>
                                <div className={cn.img}>
                                    <CustomImage
                                        src={`/img-next/svg/calculators/passport${theme === 'dark' ? '-dark' : ''}.svg`}
                                        alt="Паспорт и СНИЛС"
                                        title="Паспорт и СНИЛС"
                                        width={36}
                                        height={36}
                                    />
                                </div>
                                <div className={cn.text}>Паспорт и&nbsp;СНИЛС</div>
                            </div>
                        </div>
                        {isEarningShow && (
                            <div className={clsx(cn.item, cn.snils)}>
                                <div className={cn.itemInner}>
                                    <div className={cn.img}>
                                        <CustomImage
                                            src={`/img-next/svg/calculators/uslugi.svg`}
                                            alt="Гос. услуги"
                                            title="Гос. услуги"
                                            width={36}
                                            height={36}
                                        />
                                    </div>
                                    <div className={cn.text}>
                                        Подтвердить доход
                                        <CustomTooltip
                                            html={
                                                <div className={cn.tooltip}>
                                                    Подтвердить доход онлайн можно не выходя из дома, с помощью СМС или
                                                    учетной записи на сайте Госуслуги
                                                </div>
                                            }
                                        >
                                            <span className={cn.tooltipText}>онлайн</span>
                                        </CustomTooltip>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {isEarningShow && !isRedesign && (
                    <div className={cn.item}>
                        <div className={cn.itemInner}>
                            <div className={cn.img}>
                                <CustomImage
                                    src={`${protocol}${process.env.FRONT_ROOT_URL}/img-next/svg/calculators/uslugi.svg`}
                                    alt="Гос. услуги"
                                    title="Гос. услуги"
                                    width={36}
                                    height={36}
                                />
                            </div>
                            <div className={cn.text}>
                                Подтвердить доход
                                <CustomTooltip
                                    html={
                                        <div className={cn.tooltip}>
                                            Подтвердить доход онлайн можно не выходя из дома, с помощью СМС или учетной
                                            записи на сайте Госуслуги
                                        </div>
                                    }
                                >
                                    <span className={cn.tooltipText}>онлайн</span>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default Conditions;
