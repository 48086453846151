import React from 'react';

import { TinaQuestions } from '@/components/blocks/Questions/index.tina';
import { mockQuestionsBlockDataTina } from '@/data/blocks/questions';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const questionsBlock = {
    Component: props => <TinaQuestions {...props} />,
    template: {
        label: 'Популярные вопросы',
        defaultItem: () => mockQuestionsBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'withoutBorderMobile',
                component: 'toggle',
                label: 'Выключить рамки на моб. версии',
            },
            {
                name: 'questionsLimit',
                component: 'number',
                label: 'Лимит для показа',
            },
            {
                name: 'text',
                component: 'textarea',
                label: 'Описание',
            },
            {
                label: 'Список вопросов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockQuestionsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Кнопка',
                name: 'button',
                component: 'group',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'href',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
