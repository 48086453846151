import React from 'react';

import cn from './style.module.sass';

function Down() {
    return (
        <svg
            className={cn.svg}
            width="11"
            height="15"
            viewBox="0 0 11 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.782422 10.3893L4.81917 14.7179C4.9939 14.906 5.23189 15 5.49397 15H5.50603C5.76811 15 6.0061 14.906 6.18083 14.7179L10.2176 10.3893C10.5941 10.0131 10.5941 9.40818 10.2176 9.03518C9.84101 8.66219 9.23249 8.66219 8.85593 9.03518L6.5 11.6368V0.959135C6.5 0.429417 6.03321 0 5.5 0C4.95775 0 4.5 0.429417 4.5 0.959135V11.6368L2.14407 9.03518C1.76751 8.66219 1.15898 8.66219 0.782422 9.03518C0.405859 9.40818 0.405859 10.0131 0.782422 10.3893Z"
                className={cn.svgFill}
            />
        </svg>
    );
}

export default Down;
