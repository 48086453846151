import clsx from 'clsx';
import React from 'react';

import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

interface IProps {
    href?: { link: string; type: string };
    className?: string;
    activeClass?: string;
    offset?: number;
    children?: React.ReactNode;
    onClick?: () => void;
}
const CommonLink = ({ href, className, children, activeClass, offset, onClick }: IProps) => {
    if (href?.type === 'link') {
        return (
            <a
                href={href?.link}
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(href.link === '' && cn.link, className)}
            >
                {children}
            </a>
        );
    }

    return (
        <ScrollLink to={href?.link} className={className} activeClass={activeClass} offset={offset} onClick={onClick}>
            {children}
        </ScrollLink>
    );
};

export default CommonLink;
