import clsx from 'clsx';
import React from 'react';

import Slide from '@/components/blocks/Persons/SliderDesktop/Slide';
import cn from '@/components/blocks/Persons/SliderDesktop/style.module.sass';
import { IPersonsItem } from '@/components/blocks/Persons/types';

interface ISlide {
    setActiveCardIndex: (index: number) => void;
    activeCardIndex: number;
    currentSlideContent: IPersonsItem[];
}

function SliderDesktop({ setActiveCardIndex, activeCardIndex, currentSlideContent }: ISlide) {
    return (
        <div className={clsx(cn.container)}>
            {currentSlideContent?.map((slideContent, index) => (
                <Slide
                    key={index + Math.random()}
                    setActiveCardIndex={setActiveCardIndex}
                    activeCardIndex={activeCardIndex}
                    index={index}
                    slideContent={slideContent}
                />
            ))}
        </div>
    );
}

export default SliderDesktop;
