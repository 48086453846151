import React from 'react';

import { TinaCitiesLoans } from '@/components/blocks/CitiesLoans/index.tina';
import { mockCitiesLoansBlockDataTina } from '@/data/blocks/citiesLoans';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const citiesLoansBlock = {
    Component: props => <TinaCitiesLoans {...props} />,
    template: {
        label: 'Кредиты в городах',
        defaultItem: mockCitiesLoansBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                label: 'Заголовок',
                component: HtmlEditorFeatured,
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { text: string }, index: number) => ({
                    key: index,
                    label: item.text,
                }),
                defaultItem: () => mockCitiesLoansBlockDataTina.list[0],
                fields: [
                    {
                        component: HtmlEditorFeatured,
                        name: 'text',
                        label: 'Заголовок (максимум 255 символов)',
                        required: true,
                    },
                    {
                        component: 'text',
                        name: 'link',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
