import React from 'react';

import { TinaPhotoSlider } from '@/components/blocks/PhotoSlider/index.tina';
import { mockPhotoSliderDataTina } from '@/data/blocks/photoSlider';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const photoSliderBlock = {
    Component: props => <TinaPhotoSlider {...props} />,
    template: {
        label: 'Карусель с увеличением фото и описанием',
        defaultItem: () => mockPhotoSliderDataTina,
        fields: [
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { desc: string }, index: number) => ({
                    key: index,
                    label: item.desc,
                }),
                defaultItem: () => mockPhotoSliderDataTina.list[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
