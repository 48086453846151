import React from 'react';

import CustomImage from '@/components/CustomImage';
import { BasicLink } from '@/components/UI/BasicLink';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

type TPboFaqItemLink = {
    href: string;
    label: string;
};

export type TPboFaqItem = {
    icon: string;
    title: string;
    subSectionLinks: TPboFaqItemLink[];
    hideItemsButton?: boolean;
};

const PboFaqItem = ({ icon, subSectionLinks, title, hideItemsButton }: TPboFaqItem) => {
    const sectionLink = subSectionLinks?.[0]?.href;
    return (
        <div className={cn.wrapper}>
            <div className={cn.top}>
                <div className={cn.icon}>
                    <CustomImage src={icon} alt={title} width={42} height={42} />
                </div>
                <div className={cn.title}>{title}</div>
            </div>
            <div className={cn.list}>
                <div className={cn.links}>
                    {subSectionLinks.map(item => (
                        <BasicLink href={item.href} customClass={cn.subLink} key={item.label}>
                            {item.label}
                        </BasicLink>
                    ))}
                </div>
            </div>
            {sectionLink && !hideItemsButton && (
                <Button
                    variant="btnBlueBorder"
                    customClass={cn.button}
                    type="link"
                    href={sectionLink}
                    label="Все вопросы"
                    size="small"
                />
            )}
        </div>
    );
};

export default PboFaqItem;
