export const mockDiagramBlockData = {
    title: 'Как работает программа',
    firstDiagram: {
        title: 'Взнос по программе',
        redColumn: {
            value: 20,
            text: 'Обеспечивает инвестиционный доход',
            legendText: 'Базовый актив: Инвестиционная стратегия',
        },
        blueColumn: {
            value: 50,
            text: 'Обеспечивает 100% возврат взноса',
            legendText: 'Инструменты с фиксированной доходностью',
        },
    },
    secondDiagram: {
        title: 'Выплата по программе',
        redColumn: {
            value: 20,
            text: 'Размер инвестиционного дохода зависит от динамики Базового актива и величины коэффициента участия',
            legendText: 'Инвестиционного доход',
        },
        blueColumn: {
            value: 70,
            text:
                '<ul><li>100% возврат взноса гарантирован договором с СОГАЗ-ЖИЗНЬ и законодательством РФ(стю 934 ГК РФ)</li>' +
                '<li>СОГАЗ-ЖИЗНЬ имеет лицензии (СЖ 3825, СЛ 3825) на оказание услуг страхования жизни и имеет высший рейтинг надежности RuAAA Эксперт РА</li></ul>',
            legendText: 'Взнос по программе',
        },
    },
    button: {
        title: 'Срок программы - 2/3 года',
    },
};

export const mockDiagramBlockTinaData = {
    section: null,
    name: 'Diagram',
    title: 'Заголовок',
    firstDiagram: {
        title: 'Заголовок',
        redColumn: {
            value: 10,
            text: 'Описание',
            legendText: 'Текст легенды',
        },
        blueColumn: {
            value: 40,
            text: 'Описание',
            legendText: 'Текст легенды',
        },
    },
    secondDiagram: {
        title: 'Заголовок',
        redColumn: {
            value: 20,
            text: 'Описание',
            legendText: 'Текст легенды',
        },
        blueColumn: {
            value: 60,
            text: 'Описание',
            legendText: 'Текст легенды',
        },
    },
    button: {
        title: 'Заголовок',
    },
};
