import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IText {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'text';
    text?: string;
}

const Text: React.FC<IText> = ({ text }) => (
    <div className={clsx(cn.textBlock, 'section')}>
        <div className={cn.textBlockWrap}>
            <AnimatedComponent className={cn.textBlockContent} classNameActive={cn.animationInit}>
                <TextField className={cn.textBlockDesc} text={text} name="text" isHTML htmlValue={text} />
            </AnimatedComponent>
        </div>
    </div>
);

export default withBlockLinks(Text);
