import React from 'react';

import cn from './style.module.sass';

function VkIcon() {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="31" height="31" rx="8" className={cn.svgFill} />
            <path
                d="M23.5374 10.6781C23.6536 10.2875 23.5374 10 22.9782 10H21.1278C20.6565 10 20.4398 10.2469 20.3235 10.5219C20.3235 10.5219 19.3811 12.8062 18.049 14.2875C17.6186 14.7156 17.4207 14.8531 17.1851 14.8531C17.0688 14.8531 16.8898 14.7156 16.8898 14.325V10.6781C16.8898 10.2094 16.7578 10 16.3683 10H13.4591C13.1638 10 12.9879 10.2188 12.9879 10.4219C12.9879 10.8656 13.6539 10.9688 13.723 12.2188V14.9313C13.723 15.525 13.6162 15.6344 13.3806 15.6344C12.7523 15.6344 11.2255 13.3406 10.3207 10.7156C10.1385 10.2063 9.95939 10 9.48501 10H7.6346C7.10681 10 7 10.2469 7 10.5219C7 11.0094 7.62832 13.4313 9.92483 16.6313C11.4548 18.8156 13.6099 20 15.5703 20C16.7484 20 16.8929 19.7375 16.8929 19.2844C16.8929 17.1969 16.7861 17 17.3767 17C17.65 17 18.1213 17.1375 19.2208 18.1906C20.4775 19.4406 20.6848 20 21.3885 20H23.2389C23.7667 20 24.0338 19.7375 23.8798 19.2188C23.528 18.1281 21.1498 15.8844 21.043 15.7344C20.7696 15.3844 20.8482 15.2281 21.043 14.9156C21.0461 14.9125 23.3049 11.75 23.5374 10.6781Z"
                className={cn.svgFillInner}
            />
        </svg>
    );
}

export default VkIcon;
