export const mockWidgetPollBlockDataTina = {
    _template: 'blockWidgetPoll',
    title: 'Заголовок',
    list: [
        {
            questionName: 'Название вопроса',
            typeAnswer: 'comment',
            isAddTextArea: false,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \n' +
                'Если вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
            emojiList: { emojiYes: 'Да', emojiNo: 'Нет' },
            answerList: [{ title: 'Вариант 1' }, { title: 'Вариант 2' }, { title: 'Вариант 3' }],
        },
    ],
};

export const mockWidgetPollBlockData = {
    _template: 'blockWidgetPoll',
    title: '<p>Большой опрос</p>',
    list: [
        {
            questionName: '<p>Можно выбрать несколько вариантов ответа</p>',
            typeAnswer: 'many',
            isAddTextArea: false,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
            answerList: [{ title: 'Целый торт' }, { title: 'Пироженка' }, { title: '2 кофе' }, { title: '1 кофе' }],
        },
        {
            questionName: '<p>Выберите один вариант ответа</p>',
            typeAnswer: 'one',
            isAddTextArea: true,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
            answerList: [{ title: '<p>хочу кофе</p>' }, { title: '<p>хорошо</p>' }, { title: '<p>отлично</p>' }],
        },
        {
            questionName: '<p>Еще одна оценка</p>',
            typeAnswer: 'rating',
            isAddTextArea: false,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
        },
        {
            questionName: '<p>Получилось найти ответ?</p>',
            typeAnswer: 'rating',
            isAddTextArea: false,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
        },
        {
            questionName: '<p>Ответьте <span style="color: rgb(148, 0, 211);">Да</span> или Нет?</p>',
            typeAnswer: 'emoji',
            isAddTextArea: true,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
            emojiList: { emojiYes: '<p>Да</p>', emojiNo: '<p>Нет</p>' },
        },
        {
            questionName: '<p>Все ли вам понравилось?</p>',
            typeAnswer: 'comment',
            isAddTextArea: false,
            description:
                'Это форма только для обратной связи. Не&nbsp;оставляйте здесь свои персональные данные или информацию о&nbsp;картах и&nbsp;учетных записях. \nЕсли вам нужна помощь, обратитесь в <a href="https://www.pochtabank.ru/feedback">Службу поддержки</a>.',
        },
    ],
};
