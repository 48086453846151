export interface IOptionsItem {
    value: string;
    label: string;
}

export const options: IOptionsItem[] = [
    {
        value: 'https://hh.ru/vacancy/82680339',
        label: 'Android разработчик: Kotlin/Java',
    },
    {
        value: 'https://hh.ru/vacancy/82933609',
        label: 'Java разработчик',
    },
    {
        value: 'https://hh.ru/vacancy/82931652?hhtmFrom=employer_vacancies',
        label: 'Frontend-разработчик (React)',
    },
    {
        value: 'https://hh.ru/vacancy/82312636?hhtmFrom=employer_vacancies',
        label: 'Разработчик интеграционных сервисов (IBM Integration Bus)',
    },
    {
        value: 'https://hh.ru/vacancy/82687095',
        label: 'Разработчик 1С',
    },
    {
        value: 'https://hh.ru/vacancy/82904326?hhtmFrom=employer_vacancies',
        label: 'Ведущий разработчик DWH (Oracle PL/SQL)',
    },
    {
        value: 'https://hh.ru/vacancy/81884888?hhtmFrom=employer_vacancies',
        label: 'Аналитик-разработчик (DWH)',
    },
    {
        value: 'https://hh.ru/vacancy/82522241?hhtmFrom=employer_vacancies',
        label: 'Ведущий аналитик (DWH)',
    },
    {
        value: 'https://hh.ru/vacancy/81652107?hhtmFrom=employer_vacancies',
        label: 'Системный аналитик (IBM Integration Bus)',
    },
    {
        value: 'https://hh.ru/vacancy/78788683',
        label: 'Старший системный аналитик',
    },
    {
        value: 'https://hh.ru/vacancy/82857423?hhtmFrom=employer_vacancies',
        label: 'Веб-аналитик',
    },
    {
        value: 'https://hh.ru/vacancy/80046520',
        label: 'Руководитель продукта',
    },
    {
        value: 'https://hh.ru/vacancy/82355374?hhtmFrom=employer_vacancies',
        label: 'Эксперт по тестированию бэк-офисных систем',
    },
    {
        value: 'https://hh.ru/vacancy/82245467',
        label: 'Главный специалист поддержки бэк-офисных систем',
    },
    {
        value: 'https://hh.ru/vacancy/82248071',
        label: 'Специалист технической поддержки топ-менеджеров',
    },
    {
        value: 'https://hh.ru/vacancy/82912773?hhtmFrom=employer_vacancies',
        label: 'Специалист технической поддержки',
    },
    {
        value: 'https://hh.ru/vacancy/83051200?hhtmFrom=employer_vacancies',
        label: 'Ведущий системный аналитик',
    },
    {
        value: 'https://hh.ru/vacancy/83185492?hhtmFrom=employer_vacancies',
        label: 'Руководитель проектов (автоматизация процессов в 1С:ЗУП)',
    },
];
