import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IApplicationApps {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'applicationApps';
    title?: string;
    list: IApplicationAppsItem[];
    bgImg?: string;
}

interface IApplicationAppsItem {
    name?: string;
    desc: string;
    img?: string;
}

const ApplicationApps: React.FC<IApplicationApps> = ({ title, list, bgImg }) => {
    if (list?.length <= 0) return null;

    return (
        <div className={clsx(cn.application, 'section')}>
            <div className={cn.applicationWrap}>
                {title && (
                    <TextField
                        text={title}
                        name="title"
                        customTag="h3"
                        className={cn.applicationTitle}
                        isHTML
                        htmlValue={title}
                    />
                )}
                <div className={cn.applicationGrid}>
                    <div className={clsx(cn.applicationItem, cn.applicationItemLeft)}>
                        <div className={cn.applicationList}>
                            {list?.map(({ name, desc, img }, index) => (
                                <AnimatedComponent className={cn.rows} classNameActive={cn.animationInit} key={index}>
                                    <div className={cn.grid}>
                                        <div className={clsx(cn.item, cn.itemLeft)}>
                                            {img && (
                                                <div className={cn.img}>
                                                    <CustomImage
                                                        width="60"
                                                        height="60"
                                                        src={img}
                                                        alt={name}
                                                        objectFit="contain"
                                                        objectPosition="center"
                                                    />
                                                </div>
                                            )}
                                            {name && (
                                                <TextField
                                                    text={name}
                                                    name={`list.${index}.name`}
                                                    className={cn.name}
                                                    isHTML
                                                    htmlValue={name}
                                                />
                                            )}
                                        </div>
                                        {desc && (
                                            <div className={clsx(cn.item, cn.itemRight, cn.hideOnTablet)}>
                                                <TextField
                                                    text={desc}
                                                    name={`list.${index}.desc`}
                                                    className={cn.desc}
                                                    isHTML
                                                    htmlValue={desc}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </AnimatedComponent>
                            ))}
                        </div>
                    </div>
                    <AnimatedComponent
                        className={clsx(cn.applicationItem, cn.applicationItemRight, cn.hideOnTablet)}
                        classNameActive={cn.animationInit}
                    >
                        <div className={cn.bg}>
                            <CustomImage
                                width="230"
                                height="500"
                                src={bgImg}
                                alt={title}
                                objectFit="contain"
                                objectPosition="center top"
                            />
                        </div>
                    </AnimatedComponent>
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(ApplicationApps);
