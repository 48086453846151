import React from 'react';

import { TinaSecondaryPreferentialCalculator } from '@/components/blocks/calculators/layouts/SecondaryPreferentialCalculator/index.tina';
import { mockSecondaryCashCreditCalculatorData } from '@/data/blocks/calculators/secondaryCashcredit';
import { secondaryPreferentialCalculatorData } from '@/data/blocks/calculators/secondaryPreferential';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const secondaryPreferentialCalculatorBlock = {
    Component: props => <TinaSecondaryPreferentialCalculator {...props} />,
    template: {
        label: 'Калькулятор рефинансирования с возможностью выбрать версию',
        defaultItem: () => secondaryPreferentialCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'version',
                component: 'select',
                label: 'Версия калькулятора',
                options: [
                    {
                        value: 'v2',
                        label: 'v2',
                    },
                    {
                        value: 'v3',
                        label: 'v3',
                    },
                ],
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'infoTitle',
                component: 'group',
                label: 'Дисклеймер',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Выключить/включить дисклеймер',
                    },
                    {
                        label: 'Список элементов',
                        name: 'ranges',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title || 'Элемент',
                        }),
                        defaultItem: () => mockSecondaryCashCreditCalculatorData.infoTitle.ranges[0],
                        fields: [
                            {
                                name: 'text',
                                component: 'text',
                                label: 'Описание',
                            },
                            {
                                name: 'tooltipText',
                                component: 'text',
                                label: 'Описание для тултипа',
                            },
                            {
                                name: 'min',
                                component: 'number',
                                label: 'Минимальное значение для показа',
                            },
                            {
                                name: 'max',
                                component: 'number',
                                label: 'Максимальное значение для показа',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
