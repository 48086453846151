import React, { FC } from 'react';

import { CrossWithoutCircleIcon } from '@/components/UI/CrossWithoutCircleIcon';
import FilterIcon from '@/components/UI/FilterIcon';

import Arrows from '../../Questions/arrows';
import cn from './style.module.sass';

interface IProps {
    trigger: string;
    isOpen: boolean;
}

const MapFilterTrigger: FC<IProps> = ({ trigger, isOpen }) => (
    <div className={cn.triggerWrap}>
        <div className={cn.triggerFilterWithText}>
            <FilterIcon className={cn.triggerIcon} />
            <div className={cn.trigger}>{trigger}</div>
        </div>
        <div className={cn.triggerWrapArrow}>{isOpen ? <CrossWithoutCircleIcon /> : <Arrows />}</div>
    </div>
);

export default MapFilterTrigger;
