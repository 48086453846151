import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IGuaranteedPrizesItem {
    icon: string;
    title: string;
}

function GuaranteedPrizesItem({ title, icon }: IGuaranteedPrizesItem) {
    return (
        <AnimatedComponent className={cn.itemWrap} classNameActive={cn.animationInit} role="presentation">
            <div className={cn.item}>
                <div className={cn.textWrap}>
                    <TextField text={title} name="name" className={cn.itemTitle} isHTML htmlValue={title} />
                </div>
                <div className={cn.iconWrap}>
                    <CustomImage src={icon} width={64} height={64} alt={title} />
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default GuaranteedPrizesItem;
