import React from 'react';

import { TinaSummaryNumbers } from '@/components/blocks/SummaryNumbers/index.tina';
import { mockSummaryNumbersMockData } from '@/data/blocks/summaryNumbers';

export const summaryNumbersBlock = {
    Component: props => <TinaSummaryNumbers {...props} />,
    template: {
        label: 'Сводка',
        defaultItem: () => mockSummaryNumbersMockData,
        fields: [
            {
                name: 'heading',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'columns',
                component: 'select',
                label: 'Кол-во колонок',
                options: [
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                ],
            },
            {
                name: 'items',
                label: 'Элементы',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockSummaryNumbersMockData.items[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'number',
                        component: 'text',
                        label: 'Кол-во',
                    },
                    {
                        name: 'desc',
                        component: 'text',
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
