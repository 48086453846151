import React from 'react';

import { TinaVddohod } from '@/components/blocks/frames/Vddohod/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const vddohodBlock = {
    Component: (props: IBlockProps) => <TinaVddohod {...props} />,
    template: {
        label: 'Заявка на кредитную карту Вездедоход',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
