import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import InfoCardItem from '@/components/blocks/infoCard/InfoCardItem';
import TextField from '@/components/TextField';
import { IInfoCardItem } from '@/types/infoCard/item';

import cn from './style.module.sass';

export interface IInfoCard {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'infoCard';
    /**
     * Карточки
     */
    title?: string;
    list: IInfoCardItem[];
}

const InfoCard: React.FC<IInfoCard> = ({ title, list }) => (
    <AnimatedComponent className={clsx(cn.list, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.listWrap}>
            {title && (
                <div className={cn.titleWrapper}>
                    <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                </div>
            )}
            {list?.length > 0 && (
                <div className={cn.listGrid}>
                    {list?.map((item, index) => (
                        <InfoCardItem pos={index} key={index} {...item} />
                    ))}
                </div>
            )}
        </div>
    </AnimatedComponent>
);

export default InfoCard;
