import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 } from 'uuid';

import Counter from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/Counter';
import ImageBlock from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/ImageBlock';
import Partners from '@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture/Partners';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { useCity } from '@/context/CityProvider';
import { IBuildingCalculatorStore } from '@/domain/calculator/BuildingCalculatorStore';
import { TBuildingCalculatorContent } from '@/types/calculator/building';
import { getTTCodeforMacro } from '@/utils/getTTCodeforMacro';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const noPartners = false;

const BuildingCalculatorWithPictureContent: FC<TBuildingCalculatorContent> = observer(({ title, index, gtag }) => {
    const { range, percentRange, termField, paymentValue, rate, toggleChecked, isChecked, creditSum } =
        useContext<IBuildingCalculatorStore>(CalculatorStoreContext);

    const {
        currentCity: { region },
    } = useCity();
    const ttCode = useMemo(() => getTTCodeforMacro(region), [region]);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const formattedRate = `${rate}%`;

    const uuidUser = useMemo(() => v4(), []);

    const firstPayment = (+range.value * +percentRange.value) / 100;

    const specialLink = `https://my.pochtabank.ru/pos-credit-v2?operId=${uuidUser}&productCode=EXP_DOM_PP&toCode=9999999998&ttCode=${ttCode}&ttName=&amountCredit=${range.value}&termCredit=${termField.activeTerm.value}&firstPayment=${firstPayment}&fullName=&phone=&brokerAgentId=NON_BROKER&returnUrl=&order%5B0%5D%5Bcategory%5D=274&order%5B0%5D%5Bmark%5D=%D0%94%D0%BE%D0%BC&order%5B0%5D%5Bmodel%5D=%D0%9A%D0%B8%D1%80%D0%BF%D0%B8%D1%87%D0%BD%D1%8B%D0%B9&order%5B0%5D%5Bquantity%5D=1&order%5B0%5D%5Bprice%5D=${range.value}&_ga=2.48246177.646290470.1639584009-169232854.1638538784`;

    const getTypeImg = sum => {
        if (sum > 500000 && sum < 999999) return 'type1';
        if (sum > 1000000 && sum < 2999999) return 'type2';
        if (sum > 3000000) return 'type3';
        return 'type1';
    };

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.wrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <TextField
                                text={title}
                                customTag="h2"
                                name="title"
                                className={cn.title}
                                isHTML
                                htmlValue={title}
                            />
                            <RangeInput config={range} noMaxWidth />
                            <RangeInput config={percentRange} noMaxWidth />
                            <Terms
                                title="Срок"
                                field={termField}
                                gtag={gtag?.gtagTerm}
                                className={cn.termsNoMargin}
                                noFixedWidth
                            />
                            {noPartners && <Partners />}
                        </div>
                        <div className={cn.right}>
                            <ImageBlock type={getTypeImg(creditSum)} />
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.textTop, cn.textTop)}>Сумма ежемесячного платежа</div>
                                    <Counter paymentValue={formatValue(paymentValue)} field={termField} />
                                    <div className={cn.row}>
                                        <div className={cn.rowItem}>
                                            <div className={cn.resultText}>Ставка</div>
                                            <div className={cn.resultNumber}>{formattedRate}</div>
                                        </div>
                                        <div className={cn.rowItem}>
                                            <div className={cn.resultText}>Сумма кредита</div>
                                            <div className={cn.resultNumber}>{`${formatValue(creditSum)} ₽`}</div>
                                        </div>
                                    </div>
                                    {'enableCheckbox' && (
                                        <Checkbox
                                            label="Страховка"
                                            checked={isChecked}
                                            onChangeHandler={toggleChecked}
                                            customClass={cn.checkbox}
                                        />
                                    )}
                                    <div className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={specialLink}
                                            label="Заполнить анкету"
                                            size="small"
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BuildingCalculatorWithPictureContent;
