import React from 'react';

import { TinaMicroTiles } from '@/components/blocks/MicroTiles/index.tina';
import { microTilesMockData } from '@/data/blocks/microTiles';

export const tinaMicroTilesBlock = {
    Component: props => <TinaMicroTiles {...props} />,
    template: {
        label: 'Микро иконки и ссылки',
        defaultItem: () => microTilesMockData,
        fields: [
            {
                name: 'titleBlock',
                component: 'text',
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { listName: string }, index: number) => ({
                    key: index,
                    label: item.listName,
                }),
                defaultItem: () => microTilesMockData.list[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        required: true,
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'listName',
                        component: 'text',
                        required: true,
                        label: 'Название',
                    },
                    {
                        name: 'listLink',
                        required: true,
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'width',
                        component: 'select',
                        label: 'Размер',
                        required: true,
                        options: [
                            {
                                value: 'medium',
                                label: 'Средний',
                            },
                            {
                                value: 'big',
                                label: 'Большой',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
