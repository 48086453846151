import React from 'react';

import { TinaCarCalculator } from '@/components/blocks/calculators/layouts/CarCalculator/index.tina';
import { mockCarCalculatorData } from '@/data/blocks/calculators/carCredit';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const carCalculatorBlock = {
    Component: (props: IBlockProps) => <TinaCarCalculator {...props} />,
    template: {
        label: 'Калькулятор автокредитования',
        defaultItem: mockCarCalculatorData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tabTitle',
                component: 'text',
                label: 'Заголовок вкладки',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'banner',
                component: 'group',
                label: 'Баннер',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Выключить/включить баннер',
                    },
                    {
                        label: 'Список элементов',
                        name: 'ranges',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title || 'Элемент',
                        }),
                        defaultItem: () => mockCarCalculatorData.banner.ranges[0],
                        fields: [
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Изображение',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'subtitle',
                                component: 'text',
                                label: 'Описание',
                            },
                            {
                                name: 'min',
                                component: 'number',
                                label: 'Минимальное значение для показа',
                            },
                            {
                                name: 'max',
                                component: 'number',
                                label: 'Максимальное значение для показа',
                            },
                            {
                                name: 'shouldHide',
                                component: 'toggle',
                                label: 'Скрыть текущий баннер',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'infoTitle',
                component: 'group',
                label: 'Дисклеймер',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Выключить/включить дисклеймер',
                    },
                    {
                        label: 'Список элементов',
                        name: 'ranges',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title || 'Элемент',
                        }),
                        defaultItem: () => mockCarCalculatorData.infoTitle.ranges[0],
                        fields: [
                            {
                                name: 'text',
                                component: HtmlEditorFeatured,
                                label: 'Описание',
                            },
                            {
                                name: 'tooltipText',
                                component: 'text',
                                label: 'Описание для тултипа',
                            },
                            {
                                name: 'min',
                                component: 'number',
                                label: 'Минимальное значение для показа',
                            },
                            {
                                name: 'max',
                                component: 'number',
                                label: 'Максимальное значение для показа',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'buttonShared',
                component: 'group',
                label: 'Кнопка Поделиться',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Скрыть/показать кнопку',
                    },
                ],
            },
            {
                name: 'gtag',
                component: 'group',
                label: 'Цель GTAG',
                fields: [
                    {
                        name: 'gtagSlider',
                        component: 'group',
                        label: 'Цель ползунок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagButton',
                        component: 'group',
                        label: 'Цель кнопка',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagTerm',
                        component: 'group',
                        label: 'Цель срок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagButtonShare',
                        component: 'group',
                        label: 'Цель кнопка Поделиться',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
