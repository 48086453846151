import clsx from 'clsx';
import React from 'react';

import Arrow from '@/components/svg/Arrow';
import TextField from '@/components/TextField';
import CustomAccordion from '@/components/UI/CustomAccordion';
import { IFaqInfo } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    item: IFaqInfo;
    pos: number;
    activeItem: number;
    isThemeFlag?: boolean;
    tagsEnabled?: boolean;
    selectedTag?: string;
}

interface ITriggerProps {
    title: string;
    titleTinaName: string;
}

const CollapsibleItemTrigger: React.FC<ITriggerProps> = ({ title, titleTinaName }) => (
    <div className={cn.triggerWrap}>
        <TextField text={title} name={titleTinaName} className={cn.title} isHTML htmlValue={title} />
        <div className={cn.arrow}>
            <Arrow />
        </div>
    </div>
);

const CollapsibleItem: React.FC<IProps> = ({
    item,
    activeItem,
    pos,
    isThemeFlag = false,
    tagsEnabled,
    selectedTag,
}) => {
    const titleTinaName = `list.[${activeItem}].info.[${pos}].title`;
    const subtitleTinaName = `list.[${activeItem}].info.[${pos}].subtitle`;

    const isHidden = tagsEnabled && !item?.elementTabsList?.map(el => el?.elementTabTitle).includes(selectedTag);

    return (
        <div className={clsx(cn.item, isThemeFlag && cn.itemThemes, isHidden && cn.hidden)}>
            <CustomAccordion trigger={<CollapsibleItemTrigger title={item?.title} titleTinaName={titleTinaName} />}>
                <TextField
                    text={item?.subtitle}
                    name={subtitleTinaName}
                    className={cn.subtitle}
                    isHTML
                    htmlValue={item?.subtitle}
                />
            </CustomAccordion>
        </div>
    );
};

export default CollapsibleItem;
