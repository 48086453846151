import React from 'react';

import { TinaText } from '@/components/blocks/Text/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const textBlock = {
    Component: props => <TinaText {...props} />,
    template: {
        label: 'Текстовый блок',
        defaultItem: () => ({ text: 'Текст ', titleBlock: 'Текстовый блок' }),
        fields: [
            {
                name: 'titleBlock',
                component: 'text',
                label: 'Название элемента/Заголовок',
                required: true,
            },
            {
                label: 'Текст',
                name: 'text',
                component: HtmlEditorFeatured,
                required: true,
            },
        ],
    },
};
