import React from 'react';

import { TinaForestMap } from '@/components/blocks/frames/ForestMap/index.tina';

export const forestMapBlock = {
    Component: props => <TinaForestMap {...props} />,
    template: {
        label: 'Карта мест посадки деревьев',
        defaultItem: () => ({}),
        fields: [],
    },
};
