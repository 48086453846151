import React from 'react';

import { TinaHolidayBanner } from '@/components/blocks/banners/HolidayBanner/index.tina';
import { mockHolidayBannerBlockData } from '@/data/blocks/holidayBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const holidayBannerBlock = {
    Component: props => <TinaHolidayBanner {...props} />,
    template: {
        label: 'Праздничный баннер',
        defaultItem: () => mockHolidayBannerBlockData,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'typeBanner',
                component: 'select',
                label: 'Тип баннера',
                options: [
                    { value: 'may', label: 'Майские праздники' },
                    { value: 'woman', label: '8 марта' },
                    { value: 'knowledge', label: 'День знаний' },
                    { value: 'february', label: 'День защитника Отечества' },
                    { value: 'love', label: 'День святого Валентина' },
                ],
            },
            {
                name: 'desktopImage',
                component: 'image',
                label: 'Картинка для десктопа',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'tabletImage',
                component: 'image',
                label: 'Картинка для планшета',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'mobileImage',
                component: 'image',
                label: 'Картинка для мобильных',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
