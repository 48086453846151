import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import MoneyBoxCalculatorContent from '@/components/blocks/calculators/layouts/MoneyBoxCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import MoneyBoxCalculatorStore from '@/domain/calculator/MoneyBoxCalculatorStore';
import { TMoneyBoxCalculator } from '@/types/calculator/moneyBox';

const MoneyBoxCalculator: React.FC<TMoneyBoxCalculator> = observer(
    ({ title, rate, filterElements, termsElements, index, gtag, button, enableCheckbox }) => {
        if (!filterElements || !termsElements || !rate) return null;

        useEffect(() => {
            MoneyBoxCalculatorStore.init(filterElements, termsElements, enableCheckbox, rate);
        }, []);

        return (
            <CalculatorStoreContext.Provider value={MoneyBoxCalculatorStore}>
                {MoneyBoxCalculatorStore.initialized && (
                    <MoneyBoxCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        button={button}
                        enableCheckbox={enableCheckbox}
                        rate={rate}
                    />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default MoneyBoxCalculator;
