import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';

import { useAppStore } from '@/context/AppStoreContext';
import TabContext from '@/context/TabContext';
import { defaultFrameConfig } from '@/tina/config/frame.config';
import { IDefaultBlock } from '@/types/tina/block';

interface IProps {
    blocks: IDefaultBlock[];
}

const PaymentGroupTinaLayout = dynamic(
    () => import('@/components/blocks/frames/_deprecated/PaymentGroupLayout/tinaLayout')
);

const PaymentGroupDefaultLayout: React.FC<IProps> = ({ blocks }) => {
    const { activeTab } = useContext(TabContext);

    const Component: React.FC<{ index: number }> = defaultFrameConfig[blocks[activeTab]._template];

    return <Component {...blocks[activeTab]} index={activeTab} />;
};

const PaymentGroupLayout: React.FC<IProps> = observer(({ blocks }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    if (isTinaView) return <PaymentGroupTinaLayout />;

    return <PaymentGroupDefaultLayout blocks={blocks} />;
});

export default PaymentGroupLayout;
