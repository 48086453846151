import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';

import TextField from '../../TextField';
import Arrows from './arrows';
import cn from './style.module.sass';

export interface ILinkingList {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'linkingList';
    title: string;
    /**
     * Список категорий
     */
    list: ILinkingListItem[];
    limit: number;
}

interface ILinkingListItem {
    text: string;
    link: string;
}

const getTrigger = (trigger: string) => (
    <div className={clsx(cn.triggerWrap)}>
        <div className={cn.trigger}>{trigger}</div>
        <div className={cn.arrow}>
            <Arrows />
        </div>
    </div>
);

const LinkingList: React.FC<ILinkingList> = ({ list, title, limit }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.linkingList, 'section')}>
            <div className={cn.linkingListWrap}>
                <div className={cn.linkingListGrid}>
                    <div className={cn.linkingListRow}>
                        <AnimatedComponent className={cn.linkingListCollapsibleWrap} classNameActive={cn.animationInit}>
                            <Collapsible
                                className={clsx(cn.linkingListCollapsible, cn.linkingListCollapsibleClose)}
                                openedClassName={clsx(
                                    cn.linkingListCollapsible,
                                    cn.open,
                                    cn.linkingListCollapsibleOpen
                                )}
                                trigger={getTrigger(title)}
                                triggerOpenedClassName={cn.open}
                                triggerTagName="div"
                                transitionTime={300}
                                easing="ease-in-out"
                                open
                            >
                                <div className={cn.linkingRow}>
                                    {/* скрыли блок стилями, поэтому вывожу в списке сразу все города - list */}
                                    {list?.map(({ text, link }, index) => (
                                        <AnimatedComponent
                                            className={cn.linkingItem}
                                            classNameActive={clsx(cn.animationInit)}
                                            key={index}
                                        >
                                            <TextField
                                                customTag="a"
                                                text={text}
                                                name={`list[${index}].text`}
                                                href={link}
                                                isTextArea
                                                className={cn.linkingLink}
                                            />
                                        </AnimatedComponent>
                                    ))}
                                </div>
                            </Collapsible>
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(LinkingList);
