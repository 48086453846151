import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import cn from '@/components/blocks/mediaPublications/mediaPublicationsSlider/style.module.sass';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IMediaPublicationsSliderItem {
    logo?: string;
    title?: string;
    desc: string;
    date?: string;
    gtag?: GTagEvent;
    link?: string;
    pos?: number;
}

const MediaPublicationsSliderItem: React.FC<IMediaPublicationsSliderItem> = observer(
    ({ title, logo, gtag, desc, date, link, pos }) => {
        const onClick = () => {
            if (gtag?.action) event(gtag);
        };

        return (
            <div className={clsx(cn.currentItem, 'currentItem')}>
                <div className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}>
                    <div className={cn.currentContent}>
                        <div className={cn.currentContentTop}>
                            <TextField
                                className={cn.currentDate}
                                htmlValue={date}
                                text={date}
                                name={`list.[${pos}].date`}
                                customTag="div"
                                isHTML
                            />
                            <TextField
                                className={cn.currentTitle}
                                text={title}
                                name={`list.[${pos}].title`}
                                customTag="div"
                                isHTML
                                htmlValue={title}
                            />
                            <TextField
                                className={cn.currentDesc}
                                text={desc}
                                customTag="div"
                                name={`list.[${pos}].desc`}
                                isHTML
                                htmlValue={desc}
                            />
                        </div>
                        <div className={cn.currentContentBottom}>
                            <a href={link} className={cn.currentImage} onClick={onClick}>
                                <CustomImage src={logo} alt={title} mockWidth="124" mockHeight="40" fill />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default MediaPublicationsSliderItem;
