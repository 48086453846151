import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import ProfitabilityMonthCalculatorContent from '@/components/blocks/calculators/layouts/ProfitabilityMonthCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import ProfitabilityMonthCalculatorStore from '@/domain/calculator/ProfitabilityMonthCalculatorStore';
import { ITermItem } from '@/types/calculator/config';
import { TProfitabilityMonthCalculator } from '@/types/calculator/profitabilityMonth';

const ProfitabilityMonthCalculator: React.FC<TProfitabilityMonthCalculator> = observer(
    ({ title, filterElements, terms, index, gtag, button, enableCheckbox, termsActiveIndex }) => {
        if (!filterElements || !terms) return null;

        useEffect(() => {
            const tempFilterElements = filterElements?.filter(
                item =>
                    item.value >= 0 &&
                    item.value.toString().length > 0 &&
                    item.percent >= 0 &&
                    item.percent.toString().length > 0
            );
            const filterElementsNew = tempFilterElements.reduce((acc: ICalculatorFilterElement[], curr) => {
                if (!acc.find(item => item.id === curr.id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);

            const termsNew = terms.reduce((acc: ITermItem[], curr) => {
                if (!acc.find(item => item.id === curr.id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);

            ProfitabilityMonthCalculatorStore.init(filterElementsNew, termsNew, enableCheckbox, termsActiveIndex);
        }, [filterElements, terms, enableCheckbox, termsActiveIndex]);

        return (
            <CalculatorStoreContext.Provider value={ProfitabilityMonthCalculatorStore}>
                {ProfitabilityMonthCalculatorStore.initialized && (
                    <ProfitabilityMonthCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        button={button}
                        enableCheckbox={enableCheckbox}
                    />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default ProfitabilityMonthCalculator;
