export const mockCreditSliderBlockData = {
    title: 'На что потратить',
    list: [
        {
            title: 'Обустройство',
            subtitle: '196 ₽/день.',
            infoTitle: 'Сумма кредита 283 070 ₽',
            infoDesc:
                '<ul><li>Септик 60 000 ₽</li><li>Генераторная дизельная установка 49 070 ₽</li><li>Скважина 40 м 92 000 ₽</li><li>Гараж 82 000 ₽</li></ul>',
        },
        {
            title: 'Электрооборудование',
            subtitle: '97 ₽/день.',
            infoTitle: 'Сумма кредита 140 170 ₽',
            infoDesc:
                '<ul><li>Компрессор SKAT КПП 9 100 ₽</li><li>Генераторная дизельная установка 49 070 ₽</li><li>Гараж 82 000 ₽</li></ul>',
        },
        {
            title: 'Фундамент',
            subtitle: '309 ₽/день.',
            infoTitle: 'Сумма кредита 445 500 ₽',
            infoDesc: '<ul><li>Бетон на 30 м + Доставка 148 500 ₽</li><li>Работа по возведению 297 000 ₽</li></ul>',
        },
        {
            title: 'Строительство',
            subtitle: '416 ₽/день.',
            infoTitle: 'Сумма кредита 600 000 ₽',
            infoDesc: '<ul><li>Забор 90 000 ₽</li><li>Дом 510 000 ₽</li></ul>',
        },
    ],
};

export const mockCreditSliderBlockDataTina = {
    title: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            infoTitle: 'Подзаголовок',
            infoDesc: 'Доп. описание',
        },
    ],
};
