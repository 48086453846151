import clsx from 'clsx';
import React, { FC } from 'react';

import TextField from '@/components/TextField';

import { IRefinanceAdvantagesCard } from './iRefinanceAdvantagesCard';
import cn from './style.module.sass';
import { RefinanceInPochtaBankCard } from './Сards';
import ExternalCreditsCard from './Сards/externalCreditsCard';

export interface IRefinanceAdvantagesProps {
    _template?: 'advantagesInRefinance';
    list: IRefinanceAdvantagesCard[];
    title?: string;
    tinaPrefix?: string;
}

const RefinanceAdvantages: FC<IRefinanceAdvantagesProps> = ({ _template, list, title, tinaPrefix }) => (
    <div className={clsx(cn.refinanceAdvantages, 'section')}>
        <div className={clsx(cn.refinanceAdvantagesWrapper)}>
            <TextField text={title} className={cn.title} name="title" htmlValue={title} isHTML />
            <div className={cn.refinanceAdvantagesCards}>
                <ExternalCreditsCard {...list?.[0]} />
                <RefinanceInPochtaBankCard {...list?.[1]} />
            </div>
        </div>
    </div>
);

export default RefinanceAdvantages;
