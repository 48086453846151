import React from 'react';

import { TinaActionsSlider } from '@/components/blocks/ActionsSlider/index.tina';

export const actionsSliderBlock = {
    Component: props => <TinaActionsSlider {...props} />,
    template: {
        label: 'Список для внешних акций',
        defaultItem: () => ({ title: 'Другие акции', apiCode: 'PromoActions' }),
        fields: [
            {
                name: 'apiCode',
                component: 'infoBlock',
                label: 'Привязка к инфоблоку',
            },
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
