import React from 'react';

import { TinaYouTube } from '@/components/blocks/YouTube/index.tina';
import { mockYoutubeBlockDataTina } from '@/data/blocks/youtube';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const youtubeBlock = {
    Component: props => <TinaYouTube {...props} />,
    template: {
        label: 'Текстовый блок с YouTube фреймом',
        defaultItem: () => mockYoutubeBlockDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'description',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'buttonColor',
                component: 'select',
                label: 'Выбор цвета кнопки',
                options: [
                    {
                        value: 'dark',
                        label: 'Черный',
                    },
                    {
                        value: 'light',
                        label: 'Белый',
                    },
                ],
            },
            {
                name: 'src',
                component: 'text',
                label: 'Ссылка на видео с YouTube',
            },
        ],
    },
};
