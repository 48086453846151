import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import FullScreenTolltip from '@/components/blocks/FullScreenTolltip';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useFixHtml } from '@/hooks/useFixHtml';
import { ISpecialAdvantagesItem } from '@/types/specialAdvantages';

import cn from './style.module.sass';

function SpecialAdvantageItem({ title, subtitle, image, pos, tooltipText }: ISpecialAdvantagesItem & { pos: number }) {
    const [showToolTip, setShowTooltip] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();

    const show = () => {
        fix();
        setShowTooltip(true);
    };

    const hide = () => {
        unfix();
        setShowTooltip(false);
    };
    return (
        <div className={cn.advantagesItem}>
            <div className={clsx(cn.advantagesItemWrap)} role="presentation">
                {tooltipText?.length > 0 && (
                    <div
                        className={cn.tooltip}
                        onClick={show}
                        role="button"
                        tabIndex={0}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                show();
                            }
                        }}
                    >
                        <TooltipIcon />
                    </div>
                )}
                <div className={cn.advantagesItemGrid}>
                    <div className={cn.advantagesContent}>
                        {title && (
                            <TextField
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                                className={cn.advantagesTitle}
                            />
                        )}
                        {subtitle && (
                            <TextField
                                text={subtitle}
                                name={`list.[${pos}].subtitle`}
                                isHTML
                                htmlValue={subtitle}
                                className={cn.advantagesSubtitle}
                            />
                        )}
                    </div>
                    {image && (
                        <div className={cn.advantagesImageWrap}>
                            <div className={cn.advantagesImage}>
                                <CustomImage src={image} alt={title} mockWidth="200" mockHeight="200" fill />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <FullScreenTolltip tooltipText={tooltipText} handleClose={hide} isOpen={showToolTip} />
        </div>
    );
}

export default observer(SpecialAdvantageItem);
