import { ISpecialAdvantages } from '@/types/specialAdvantages';

export const mockAdvantagesBlockData = {
    name: 'Преимущества кредита в Почта Банке',
    list: [
        {
            link: 'https://www.pochtabank.ru/service/payments',
            title: 'Платежи без комиссии',
            subtitle:
                'Мы отменили комиссию за оплату ЖКУ и иных услуг в Почта Банк Онлайн и банкоматах для всех клиентов',
            image: '/img-next/png/advantages/1.png',
            tooltipText: '',
            size: 'small',
        },
        {
            link: 'https://www.pochtabank.ru/service/cashcredit#benefits',
            title: '-2% годовых ставка',
            subtitle:
                'Получайте зарплату или пенсию на счет в Почта Банке, либо совершайте покупки и платежи от 10 000 Р в месяц с помощью карт банка',
            image: '/img-next/png/advantages/2.png',
            tooltipText: '',
            size: 'big',
            bgColor: 'violet',
        },
        {
            link: 'https://www.pochtabank.ru/service/cashcredit#benefits',
            title: 'Кредит с доставкой',
            subtitle: 'Получите кредит, не выходя из дома!',
            image: '/img-next/png/advantages/3.png',
            tooltipText:
                'Мы сами приедем к Вам по удобному адресу для оформления кредита. Нужен только паспорт и снилс',
            size: 'small',
        },
        {
            link: '',
            title: 'Быстро и удобно',
            subtitle: 'Выдача кредита по паспорту в день обращения в любом удобном отделении в шаге от дома',
            image: '/img-next/png/advantages/4.png',
            tooltipText: '',
            size: 'small',
        },
        {
            link: '',
            title: 'Гарантированная ставка ',
            subtitle: 'Вы отдаете – мы возвращаем',
            image: '/img-next/png/advantages/5.png',
            tooltipText:
                '• Погасите не менее 12 платежей по кредиту без просрочек <br/>\n' +
                '• Мы пересчитаем кредит по ставке 5,9%,7,9% или 9,9% в зависимости от тарифного плана Сберегательного счета<br/> \n' +
                '• И вернем разницу на ваш Сберегательный счет в течение 3-х рабочих дней с даты погашения кредита <br/>',
            size: 'small',
        },
    ],
};

export const mockAdvantagesBlockCreditGoods = {
    name: 'Преимущества',
    list: [
        {
            title: 'Быстрое оформление кредита',
            subtitle: '30 минут от выбора до покупки понравившегося товара',
            image: '/img-next/png/InternetAcquiring.png',
            imageLink: '',
            size: 'full',
        },
        {
            title: 'Без справок о доходах',
            subtitle: 'для оформления кредита нужен только паспорт гражданина РФ и номер СНИЛС',
            image: '/img-next/png/advantages/corzina.png',
            imageLink: '',
            size: 'small',
        },
        {
            title: 'Пакет услуг «Все под контролем»',
            subtitle:
                'возможность перенести дату платежа, уменьшить или пропустить платеж в Почта Банк Онлайн, не выходя <a href="#">из дома</a>',
            image: '/img-next/png/advantages/saving/2.png',
            imageLink: '',
            size: 'small',
        },
        {
            title: 'Бесплатно к карте',
            subtitle:
                'открытие и обслуживание «<a href="#">Сберегательного счёта</a>» с ежемесячным начислением до 5% годовых на минимальный остаток средств на счёте',
            image: '/img-next/png/advantages/saving/3.png',
            imageLink: '',
            size: 'small',
        },
    ],
};

export const mockAdvantagesBlockCreditCash = {
    _template: 'advantagesV2',
    name: 'Преимущества кредита наличными',
    list: [
        {
            title: 'Быстрое оформление кредита',
            subtitle: '30 минут от выбора до покупки понравившегося товара',
            subtitleMobile: '15 минут от выбора до покупки понравившегося товара',
            image: '/img-next/png/InternetAcquiring.png',
            size: 'big',
            mobileBgColor: '#ebf1fb',
        },
        {
            title: 'Без справок о доходах',
            subtitle: 'для оформления кредита нужен только паспорт гражданина РФ и номер СНИЛС',
            image: '/img-next/png/advantages/corzina.png',
            size: 'small',
            mobileBgColor: '#feeded',
        },
        {
            title: 'Пакет услуг «Все под контролем»',
            subtitle:
                'возможность перенести дату платежа, уменьшить или пропустить платеж в Почта Банк Онлайн, не выходя',
            image: '/img-next/png/advantages/saving/2.png',
            size: 'small',
            mobileBgColor: '#fce9d9',
        },
        {
            title: 'Бесплатно к карте',
            subtitle:
                'открытие и обслуживание  с ежемесячным начислением до 5% годовых на минимальный остаток средств на счёте',
            image: '/img-next/png/advantages/saving/3.png',
            size: 'small',
            mobileBgColor: '#fce9d9',
        },
        {
            title: 'Бесплатно к карте',
            subtitle:
                'открытие и обслуживание  с ежемесячным начислением до 5% годовых на минимальный остаток средств на счёте',
            image: '/img-next/png/advantages/saving/3.png',
            size: 'small',
            mobileBgColor: '#f8efff',
        },
    ],
    button: {
        name: 'Получить кредит',
        link: '#',
    },
};

export const mockAdvantagesBlockOnlineCreditData = {
    name: 'Преимущества онлайн-кредитования',
    tagList: [
        {
            value: 'Для клиентов',
            xmlId: 'no',
        },
        {
            value: 'Для бизнеса',
            xmlId: 'yes',
        },
    ],
    list: [
        {
            tags: ['no'],
            title: 'Удобство подачи и онлайн решение по заявке',
            image: '/img-next/png/onlinecredits/adv1.png',
            size: 'small',
        },
        {
            tags: ['no'],
            title: 'Простые и понятные условия кредитования, досрочное погашение в любой день без комиссий',
            image: '/img-next/png/onlinecredits/adv2.png',
            size: 'big',
            bgColor: 'violet',
        },
        {
            tags: ['no'],
            title: 'Более 4500 отделений Почта Банка и Почты России, где можно подписать договор на кредит',
            image: '/img-next/png/onlinecredits/adv3.png',
            size: 'small',
        },
        {
            tags: ['no'],
            title: 'Выдача кредита по паспорту',
            image: '/img-next/png/onlinecredits/adv4.png',
            size: 'small',
        },
        {
            tags: ['no'],
            title: 'Решение банка действует 7 дней',
            image: '/img-next/png/onlinecredits/adv5.png',
            size: 'small',
        },
        {
            tags: ['yes'],
            title: 'Более 4500 отделений Почта Банка и Почты России, где можно подписать договор на кредит',
            image: '/img-next/png/onlinecredits/adv1.png',
            size: 'small',
        },
        {
            tags: ['yes'],
            title: 'Выдача кредита по паспорту',
            image: '/img-next/png/onlinecredits/adv2.png',
            size: 'small',
        },
        {
            tags: ['yes'],
            title: 'Решение банка действует 7 дней',
            image: '/img-next/png/onlinecredits/adv3.png',
            size: 'small',
        },
    ],
};

export const mockAdvantagesBlockTinaData = {
    section: null,
    name: 'Заголовок',
    list: [
        {
            link: '#',
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            imageLink: '',
            tooltipText: '',
            size: 'small',
        },
        {
            link: '#',
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            imageLink: '',
            tooltipText: '',
            size: 'big',
            bgColor: 'violet',
        },
        {
            link: '#',
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            imageLink: '',
            tooltipText: 'Всплывающая подсказка',
            size: 'small',
        },
        {
            link: '#',
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            imageLink: '',
            tooltipText: '',
            size: 'small',
        },
        {
            link: '#',
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            imageLink: '',
            tooltipText: 'Всплывающая подсказка',
            size: 'small',
        },
    ],
};

export const mockSpecialAdvantagesBlockData: ISpecialAdvantages = {
    _template: 'specialAdvantages',
    section: null,
    name: 'Особые преимущества для пенсионеров',
    list: [
        {
            title: 'Бесплатная &laquo;Линия заботы&raquo;',
            subtitle:
                'Бесплатная круглосуточная поддержка по&nbsp;телефону: юридические, социальные и&nbsp;медицинские вопросы',
            image: '/img-next/png/InternetAcquiring.png',
            id: null,
        },
        {
            title: '0 ₽ выпуск и обслуживание',
            subtitle: 'карты платёжной системы «Мир»',
            image: '/img-next/png/advantages/corzina.png',
            id: null,
        },
        {
            title: '0 ₽ снятие наличных',
            subtitle:
                '<ul><li>в банкоматах Почта Банка</li><li>в банкоматах любого банка до 10 000 ₽ в месяц</li></ul>',
            image: '/img-next/png/advantages/saving/2.png',
            id: null,
        },
        {
            title: '0 ₽ переводы в другие банки',
            subtitle: 'через СБП до 100 000 ₽ в месяц',
            image: '/img-next/png/advantages/saving/3.png',
            id: null,
        },
        {
            title: 'До 5% годовых на остаток',
            subtitle: 'от 50 000 ₽ при обороте по карте <br/> от 10 000 ₽ в месяц',
            image: '/img-next/png/advantages/saving/2.png',
            id: null,
        },
        {
            title: 'Без комиссии',
            subtitle: 'Оплата услуг ЖКУ, связи, телевидения, интернета, охраны и многого другого',
            image: '/img-next/png/advantages/saving/3.png',
            id: null,
        },
    ],
};

export const mockSpecialAdvantagesBlockDataTina: ISpecialAdvantages = {
    section: null,
    _template: 'specialAdvantages',
    name: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            image: '',
            id: null,
        },
    ],
};
