import clsx from 'clsx';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { IAllExchangeRatesResponse } from '@/components/blocks/AllExchangeRates/AllExchangeRates';
import PopularExchangeRatesItem from '@/components/blocks/PopularExchangeRates/PopularExchangeRatesItem';
import { IExchangeRatesItem } from '@/types/exchangeRates/item';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IPopularExchangeRates {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'popularExchangeRates';
    /**
     * Заголовок
     */
    name: string;
    /**
     * Описание
     */
    desc: string;
    /**
     * Список курсов валют
     */
    list: IExchangeRatesItem[];
}

const PopularExchangeRates: React.FC<IPopularExchangeRates> = ({ name, desc, list }) => {
    const [exchangeRatesList, setExchangeRatesList] = useState(list);

    const getPopularExchanges = (allList: IExchangeRatesItem[]): IExchangeRatesItem[] => {
        const popularExchanges = ['GBP', 'USD', 'EUR', 'CHF'];

        return allList.filter(item => popularExchanges.includes(item.title));
    };

    const fetchList = async () => {
        const correctDate = format(new Date(), 'yyyy-MM-dd');

        try {
            const response: IAllExchangeRatesResponse = await apiRequest(`/currencies?date=${correctDate}`);

            const {
                data: { data: fetchedList, errors },
            } = response;

            if (errors?.length > 0) throw new Error(JSON.stringify(errors));

            const popularList = getPopularExchanges(fetchedList as IExchangeRatesItem[]);

            setExchangeRatesList(popularList);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (!exchangeRatesList?.length) fetchList().catch(console.error);
    }, [list]);

    const currentDate = format(new Date(), 'dd.MM.yy');
    const defaultDesc = `Курсы валют на ${currentDate}`;

    if (!exchangeRatesList?.length) return null;

    return (
        <div className={clsx(cn.popularExchangeRates, 'section')}>
            <div className={cn.wrap}>
                <h3 className={cn.mainTitle}>{name || 'Популярные курсы валют'}</h3>
                <div className={cn.mainDesc} dangerouslySetInnerHTML={{ __html: desc || defaultDesc }} />
                <div className={cn.grid}>
                    {exchangeRatesList?.map((item, index) => (
                        <div className={cn.item} key={`key-${index}`}>
                            <PopularExchangeRatesItem {...item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PopularExchangeRates;
