import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IMediaPublicationsPerson {
    name: string;
    desc: string;
    image: string;
}

const MediaPublicationsPerson: React.FC<IMediaPublicationsPerson> = ({ image, desc, name }) => (
    <AnimatedComponent className={cn.mediaPublicationsPerson} classNameActive={cn.animationInit} role="presentation">
        <div className={cn.mediaPublicationsPersonContent}>
            <div className={cn.mediaPublicationsImageWrapper}>
                <CustomImage src={image} alt={desc} mockWidth="141" mockHeight="141" fill />
            </div>
            <div className={cn.mediaPublicationsPersonInner}>
                {name && (
                    <TextField
                        className={cn.mediaPublicationsPersonName}
                        text={name}
                        name="person.name"
                        isHTML
                        htmlValue={name}
                    />
                )}
                {desc && (
                    <TextField
                        className={cn.mediaPublicationsPersonDesc}
                        text={desc}
                        name="person.desc"
                        isHTML
                        htmlValue={desc}
                    />
                )}
            </div>
        </div>
    </AnimatedComponent>
);

export default MediaPublicationsPerson;
