import { IInfoBlockPensiya } from '@/components/blocks/InfoBlockPensiya';

export const mockInfoBlockPensiyaBlockData: IInfoBlockPensiya = {
    _template: 'infoBlockPensiya',
    image: '/img-next/png/infoBlockPensiya.png',
    title: 'Получайте пенсию в надежном Почта Банке',
    desc:
        'Почта Банк &mdash; розничный банк, созданный группой ВТБ и Почтой России\n' +
        '<ul><li>Более 25 000 точек обслуживания\n</li><li>Нас уже выбрали более 30 млн человек\n</li><li>Рейтинг на уровне &laquo;A-|ru|&raquo;, прогноз &laquo;Стабильный&raquo;, Национальное рейтинговое агентство\n</li><li>Участник государственной системы страхования АСВ</li></ul>',
    button: { link: '#', linkName: 'Стать пенсионным клиентом', type: 'anchor' },
};

export const mockInfoBlockPensiyaBlockDataTina = {
    _template: 'infoBlockPensiya',
    image: '',
    title: 'Заголовок',
    desc: 'Описание',
    button: { link: 'Название якоря без #', linkName: 'Название', type: 'anchor' },
};
