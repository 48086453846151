import React, { FC } from 'react';

interface IFIlterIconProps {
    className?: string;
}

const FilterIcon: FC<IFIlterIconProps> = ({ className }) => (
    <svg
        className={className}
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.00401 5.20506H3.92201C4.25701 7.15106 5.93001 8.61106 7.93901 8.61106C9.94801 8.61106 11.574 7.15106 11.957 5.20506H21.283C21.665 5.20506 22 4.86506 22 4.47506C22 4.08606 21.665 3.74506 21.283 3.74506H11.957C11.622 1.80006 9.94701 0.341064 7.93901 0.341064C5.93001 0.341064 4.30401 1.80006 3.92201 3.74606H1.00401C0.622011 3.74606 0.287011 4.08606 0.287011 4.47606C0.287011 4.86506 0.622011 5.20606 1.00401 5.20606V5.20506ZM7.89101 1.80006C9.32601 1.80006 10.521 3.01606 10.521 4.47606C10.521 5.93606 9.32601 7.15106 7.89101 7.15106C6.45701 7.15106 5.26101 5.93506 5.26101 4.47606C5.26101 3.01606 6.45701 1.80006 7.89101 1.80006ZM21.235 12.7461H18.509C18.174 10.8001 16.5 9.34007 14.49 9.34007C12.482 9.34007 10.856 10.7991 10.473 12.7451H1.00301C0.621011 12.7451 0.286011 13.0851 0.286011 13.4751C0.286011 13.8641 0.621011 14.2051 1.00301 14.2051H10.473C10.808 16.1501 12.482 17.6101 14.49 17.6101C16.499 17.6101 18.125 16.1501 18.508 14.2041H21.234C21.616 14.2041 21.951 13.8641 21.951 13.4741C21.951 13.0851 21.616 12.7441 21.234 12.7441L21.235 12.7461ZM14.49 16.1501C13.056 16.1501 11.86 14.9341 11.86 13.4751C11.86 12.0151 13.056 10.7991 14.49 10.7991C15.925 10.7991 17.12 12.0151 17.12 13.4751C17.12 14.9351 15.925 16.1501 14.49 16.1501Z"
            fill="#1A68BC"
        />
    </svg>
);

export default FilterIcon;
