import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IInformationCenter {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'informationCenter';
    /**
     * Список категорий
     */
    image: string;
    title: string;
    phone?: IPhone[];
    bottomText?: string;
}

interface IPhone {
    tel: string;
    telHref: string;
    city?: string;
}

const InformationCenter: React.FC<IInformationCenter> = ({ image, title, bottomText, phone }) => (
    <AnimatedComponent className={clsx(cn.information, 'section')} classNameActive={cn.animationInit}>
        <div className={cn.informationWrap}>
            <div className={cn.informationGrid}>
                <div className={clsx(cn.informationItem, cn.informationItemImg)}>
                    <div className={clsx(cn.informationImage)}>
                        <div className={cn.informationImageContainer}>
                            {image && (
                                <CustomImage
                                    src={image}
                                    alt={title}
                                    layout="intrinsic"
                                    width={257}
                                    height={245}
                                    objectFit="contain"
                                    objectPosition="center"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={clsx(cn.informationItem, cn.informationItemContent)}>
                    <div className={cn.informationContent}>
                        <div className={cn.informationTop}>
                            {title && (
                                <TextField
                                    text={title}
                                    className={cn.informationTitle}
                                    name="title"
                                    isHTML
                                    htmlValue={title}
                                />
                            )}
                            <div className={cn.phone}>
                                <div className={cn.phoneGrid}>
                                    {phone?.length > 0 &&
                                        phone?.map(({ tel, telHref, city }, index) => (
                                            <div className={cn.phoneItem} key={index}>
                                                <TextField
                                                    text={tel}
                                                    className={cn.phoneTel}
                                                    name={`phone.[${index}].tel`}
                                                    isHTML
                                                    htmlValue={tel}
                                                    customTag="a"
                                                    href={`tel:${telHref}`}
                                                />
                                                <TextField
                                                    text={city}
                                                    className={cn.phoneCity}
                                                    name={`phone.[${index}].city`}
                                                    isHTML
                                                    htmlValue={city}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {bottomText && (
                            <div className={cn.informationBot}>
                                <TextField
                                    text={bottomText}
                                    isHTML
                                    htmlValue={bottomText}
                                    name="bottomText"
                                    className={cn.informationDesc}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </AnimatedComponent>
);

export default withBlockLinks(InformationCenter);
