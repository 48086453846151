import clsx from 'clsx';
import React, { useState } from 'react';

import HRConditions from '@/components/blocks/HRLanding/HRConditions';
import HRDetailConditions from '@/components/blocks/HRLanding/HRDetailConditions';
import HRFooter from '@/components/blocks/HRLanding/HRFooter';
import { hrLandingFormOptions } from '@/components/blocks/PushHRLanding/data/form';
import { hrGtag, menu } from '@/components/blocks/PushHRLanding/data/header';
import { hrAchievementsList } from '@/components/blocks/PushHRLanding/data/hrAchievements';
import { hrConditionsList } from '@/components/blocks/PushHRLanding/data/hrConditions';
import { hrDetailConditionsList } from '@/components/blocks/PushHRLanding/data/hrDetailConditions';
import { hrMain } from '@/components/blocks/PushHRLanding/data/main';
import HRHeader from '@/components/blocks/PushHRLanding/Header';
import HRAchievements from '@/components/blocks/PushHRLanding/HRAchievements';
import PushHRLandingForm from '@/components/blocks/PushHRLanding/HRLandingForm';
import HRMain from '@/components/blocks/PushHRLanding/HRMain';
import HRVacancy, { IPushHRVacancyListItem } from '@/components/blocks/PushHRLanding/HRVacancy';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import ModalWrapper from '@/components/modal/ModalWrapper';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export interface IHRLanding {
    _template?: 'pushHrLandingForm';
    titleVacancy?: string;
    listVacancy?: IPushHRVacancyListItem[];
}

function PushHRLanding({ _template, titleVacancy, listVacancy }: IHRLanding) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTheme, setCurrentTheme] = useState<string>(hrLandingFormOptions[0].value);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const handleOpenWithoutTheme = () => {
        setCurrentTheme(hrLandingFormOptions[0].value);
        setIsModalOpen(true);
    };

    const handleOpenWithTheme = (theme: string) => {
        setCurrentTheme(theme);
        setIsModalOpen(true);
    };

    if (!Feature.isFeatureEnabled('enablePushHRLanding')) {
        return null;
    }

    return (
        <div className={clsx(cn.layout)}>
            <HRHeader handler={handleOpenWithoutTheme} menu={menu} hrGtag={hrGtag} />

            <div className={cn.content}>
                <HRMain btnLabel="Хочу в команду" btnHandler={handleOpenWithoutTheme} {...hrMain} />
                <HRConditions hrConditionsList={hrConditionsList} />
                <HRDetailConditions
                    title="Работа у нас это"
                    typeCard="small"
                    hrDetailConditionsList={hrDetailConditionsList}
                />
                <HRAchievements hrAchievementsList={hrAchievementsList} title="Наши достижения" />

                <HRVacancy title={titleVacancy} hrVacancyList={listVacancy} handler={handleOpenWithTheme} />
            </div>
            <HRFooter handler={handleOpenWithoutTheme} menu={menu} />

            <ModalWrapper
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                handleOpen={handleOpen}
                wrapperClassName={cn.modalWrapper}
                bgClassName={cn.modalOverlay}
                className={cn.modalInner}
                isClosedOverlay={false}
            >
                <PushHRLandingForm title="Откликнуться на вакансию" defaultValue={currentTheme} hrGtag={hrGtag} />
            </ModalWrapper>
        </div>
    );
}

export default withBlockLinks(PushHRLanding);
