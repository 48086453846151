import React from 'react';

import { TinaTextImageRow } from '@/components/blocks/TextImageRow/index.tina';
import { mockTextImageRowData } from '@/data/blocks/textImageRow';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const textImageRowBlock = {
    Component: props => <TinaTextImageRow {...props} />,
    template: {
        label: 'Текстовый блок с картинкой (Строка)',
        defaultItem: () => mockTextImageRowData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
