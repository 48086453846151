import React, { FC } from 'react';

import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';

import cn from './style.module.sass';

const YandexSearchResults: FC = () => {
    useIsomorphicEffect(() => {
        (function (w, d, c) {
            const s = d.createElement('script');
            const h = d.getElementsByTagName('script')[0];
            s.type = 'text/javascript';
            s.async = true;
            s.charset = 'utf-8';
            s.src = `${d.location.protocol === 'https:' ? 'https:' : 'http:'}//site.yandex.net/v2.0/js/all.js`;
            h.parentNode.insertBefore(s, h);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,no-param-reassign
            (w[c] || (w[c] = [])).push(() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                window.Ya.Site.Results.init();
            });
        })(window, document, 'yandex_site_callbacks');
    }, []);

    return (
        <div
            id="search-results"
            className={cn.searchResults}
            dangerouslySetInnerHTML={{
                __html: `<div id="ya-site-results"
                        data-bem="{&quot;tld&quot;: &quot;ru&quot;,&quot;language&quot;: &quot;ru&quot;,&quot;encoding&quot;: &quot;&quot;,&quot;htmlcss&quot;: &quot;1.x&quot;,&quot;updatehash&quot;: true}"></div>`,
            }}
        />
    );
};

export default YandexSearchResults;
