export const mockIndicationBlockData = {
    title: 'Превращение «Лето Банка» в «Почта Банк»',
    rows: [
        {
            name: 'Количество клиентов',
            list: [
                {
                    year: '2016',
                    numbers: '2 млн.',
                },
                {
                    year: '2023',
                    numbers: '15 млн.',
                },
            ],
        },
        {
            name: 'Рост региональной сети (клиентские центры)',
            list: [
                {
                    year: '2015',
                    numbers: '340',
                },
                {
                    year: '2016',
                    numbers: '6 000',
                },
                {
                    year: '2018',
                    numbers: '15 000',
                },
            ],
        },
    ],
};

export const mockIndicationBlockDataTina = {
    title: 'Заголовок',
    rows: [
        {
            name: 'Заголовок',
            list: [
                {
                    year: 'Заголовок',
                    numbers: 'Подзаголовок',
                },
                {
                    year: 'Год',
                    numbers: '2000',
                },
            ],
        },
    ],
};
