import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

const VddohodContent = dynamic(async () => import('./VddohodContent'), { ssr: false });

export interface IVddohod {
    _template: 'vddohod';
    title: string;
}

const Vddohod: React.FC<IVddohod & { index: number }> = ({ index, title }) => (
    <div className="section">
        <VddohodContent title={title} index={index} />
    </div>
);

export default withBlockLinks(Vddohod);
