import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Rating from 'react-rating';

import CustomImage from '@/components/CustomImage';
import { StarEmptyIcon } from '@/components/UI/icons/StarEmptyIcon';
import { StarIcon } from '@/components/UI/icons/StarIcon';
import { configReview } from '@/data/blocks/reviews';
import { IReviewsItem } from '@/types/reviews';
import { getDateObjectFromISO } from '@/utils/date/getDateObjectFromISO';

import cn from './style.module.sass';

const ReviewsContent: React.FC<IReviewsItem> = observer(({ name, text, date, type = 'debet-card', value, flag }) => {
    const { month, day, year } = getDateObjectFromISO(date);

    return (
        <div className={cn.currentContent}>
            <div className={cn.currentTop}>
                <div className={cn.currentName} dangerouslySetInnerHTML={{ __html: name }} />
                <div className={cn.currentRating}>
                    {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*  @ts-ignore */}
                    <Rating
                        initialRating={value}
                        readonly
                        emptySymbol={<StarEmptyIcon />}
                        fullSymbol={<StarIcon />}
                        fractions={2}
                    />
                </div>
                <div
                    className={clsx(cn.currentText, flag && cn.currentTextSmall)}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
            <div className={cn.currentDate}>
                {day} {month} {year}
            </div>
            <div className={cn.currentBot}>
                <div className={cn.currentProduct}>
                    <div className={cn.currentProductName}>{configReview[type].title}</div>
                    <div className={cn.currentProductImage}>
                        <CustomImage
                            src={configReview[type].image}
                            alt={configReview[type].title}
                            mockWidth="45"
                            mockHeight="45"
                            width={45}
                            height={45}
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ReviewsContent;
