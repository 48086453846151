import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import IconTime from '@/components/UI/IconTime';
import { PhantomLinkIcon } from '@/components/UI/PhantomLinkIcon';
import { IArticleItem } from '@/types/articleList';

import cn from './style.module.sass';

const ArticleItem: React.FC<IArticleItem> = ({
    id,
    code,
    name,
    previewPictureSrc,
    previewText,
    detailListUrl,
    time,
    theme,
    properties,
}) => (
    <AnimatedComponent className={clsx(cn.currentItem, 'currentItem')} classNameActive={cn.animationInit}>
        <a href={detailListUrl} className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}>
            {previewPictureSrc && (
                <div className={cn.currentImage}>
                    <CustomImage src={previewPictureSrc} alt={name} mockWidth="350" mockHeight="200" fill />
                </div>
            )}
            <div className={cn.currentContent}>
                <div className={cn.currentTop}>
                    {name && <div className={cn.currentTitle} dangerouslySetInnerHTML={{ __html: name }} />}

                    {previewText && (
                        <div className={cn.currentSubtitle} dangerouslySetInnerHTML={{ __html: previewText }} />
                    )}
                </div>

                <div className={cn.currentBot}>
                    {detailListUrl && <PhantomLinkIcon noPadding typeIcon="right" label="Узнать больше" />}

                    {time && (
                        <div className={cn.currentWrapper}>
                            <IconTime />
                            <div className={cn.currentTime} dangerouslySetInnerHTML={{ __html: time }} />
                        </div>
                    )}
                </div>
            </div>
        </a>
    </AnimatedComponent>
);

export default ArticleItem;
