import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import MediaPublicationsSliderItem, {
    IMediaPublicationsSliderItem,
} from '@/components/blocks/mediaPublications/mediaPublicationsSlider/item';
import CustomSwiper from '@/components/CustomSwiper';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IMediaPublicationsSlider {
    list: IMediaPublicationsSliderItem[];
    disableSlider?: boolean;
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

SwiperCore.use([Pagination]);

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef.current.swiper.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef.current.swiper.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const MediaPublicationsSlider: React.FC<IMediaPublicationsSlider> = ({ list, gtag, disableSlider }) => {
    const swiperRef = useRef(null);

    const settings = {
        slidesPerView: 1,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
        pagination: true,
    };

    const pagination = {
        clickable: true,
    };

    if (disableSlider) {
        return (
            <div className={clsx(cn.current, 'section')}>
                <div className={cn.currentWrap}>
                    <div className={cn.currentGrid}>
                        <AnimatedComponent
                            className={clsx(cn.currentSlider, cn.currentNoSlider)}
                            classNameActive={cn.animationInit}
                        >
                            {list?.map((item, index) => (
                                <MediaPublicationsSliderItem key={index} {...item} pos={index} />
                            ))}
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.current)}>
            <div className={cn.currentWrap}>
                <div className={cn.currentGrid}>
                    <AnimatedComponent className={cn.currentSlider} classNameActive={cn.animationInit}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <CustomSwiper {...settings} pagination={pagination} ref={swiperRef}>
                            {list?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <MediaPublicationsSliderItem {...item} key={index} pos={index} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                        <PrevArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                        <NextArrow listLength={list?.length} swiperRef={swiperRef} gtag={gtag} />
                    </AnimatedComponent>
                </div>
            </div>
        </div>
    );
};

export default MediaPublicationsSlider;
