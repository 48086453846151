import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ICashCredit {
    _template: 'cashCredit';
    subhead: string;
    text: string;
    image?: string;
    imageAlt?: string;
    backgroundColor?: string;
}
const CashCredit: React.FC<ICashCredit> = ({ subhead, text, image, imageAlt, backgroundColor = 'white' }) => (
    <div className={clsx(cn.cashCredit, 'section')}>
        <div className={cn.cashCreditWrap}>
            <div
                className={
                    backgroundColor === 'blue' ? clsx(cn.cashCreditBlock, cn.blueBackground) : cn.cashCreditBlock
                }
            >
                {image && (
                    <div className={cn.imgWrapper}>
                        <div className={cn.img}>
                            <CustomImage src={image} alt={imageAlt} fill />
                        </div>
                    </div>
                )}
                <div className={cn.cashCreditContent}>
                    <TextField
                        className={cn.cashCreditTitle}
                        text={subhead}
                        name="subhead"
                        htmlValue={subhead}
                        isHTML
                    />
                    <TextField text={text} name="text" className={cn.cashCreditDesc} htmlValue={text} isHTML />
                </div>
            </div>
        </div>
    </div>
);
export default CashCredit;
