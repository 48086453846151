import React from 'react';

import { TinaPreferentialCalculator } from '@/components/blocks/calculators/layouts/PreferentialCalculator/index.tina';
import { mockPreferentialCalculatorData } from '@/data/blocks/calculators/preferential';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const preferentialCalculatorBlock = {
    Component: props => <TinaPreferentialCalculator {...props} />,
    template: {
        label: 'Льготный калькулятор',
        defaultItem: mockPreferentialCalculatorData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'gtagSlider',
                component: 'group',
                label: 'Цель GTAG ползунок',
                fields: [
                    {
                        name: 'action',
                        component: 'text',
                        label: 'Действие',
                    },
                    {
                        name: 'category',
                        component: 'text',
                        label: 'Категория',
                    },
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                ],
            },
            {
                name: 'gtagButton',
                component: 'group',
                label: 'Цель GTAG кнопка',
                fields: [
                    {
                        name: 'action',
                        component: 'text',
                        label: 'Действие',
                    },
                    {
                        name: 'category',
                        component: 'text',
                        label: 'Категория',
                    },
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                ],
            },
            {
                name: 'gtagTerm',
                component: 'group',
                label: 'Цель срок',
                fields: [
                    {
                        name: 'action',
                        component: 'text',
                        label: 'Действие',
                    },
                    {
                        name: 'category',
                        component: 'text',
                        label: 'Категория',
                    },
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                ],
            },
        ],
    },
};
