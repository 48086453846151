import React from 'react';

import { TinaSalesHRLanding } from '@/components/blocks/SalesHRLanding/index.tina';

export const salesHrLandingBlock = {
    Component: props => <TinaSalesHRLanding {...props} />,
    template: {
        label: 'Sales HR лендинг',
        fields: [],
    },
};
