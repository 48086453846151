export const mockMainSliderBlockData = {
    name: 'Карусель',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    title: 'Заголовок',
    list: [
        {
            image: '/img-next/png/main-slider/1.png',
            title: 'Платежи без комиссии',
            subtitle: 'Мы отменили комиссию за ЖКУ и иных услуг в Почта Банк Онлайн и банкоматах для всех клиентов',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/2.png',
            title: 'Текущие акции',
            subtitle: 'Все актуальные акции Почта Банка собранные в одном месте',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/3.png',
            title: 'Подари лес другу',
            subtitle: 'Первый в мире проект по посадке деревьев через интернет',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/1.png',
            title: 'Платежи без комиссии',
            subtitle: 'Мы отменили комиссию за ЖКУ и иных услуг в Почта Банк Онлайн и банкоматах для всех клиентов',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/2.png',
            title: 'Текущие акции',
            subtitle: 'Все актуальные акции Почта Банка собранные в одном месте',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/main-slider/3.png',
            title: 'Подари лес другу',
            subtitle: 'Первый в мире проект по посадке деревьев через интернет',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};

export const mockMainSliderBlockActionData = {
    disableSlider: true,
    tagList: [
        {
            value: 'Все акции',
            xmlId: '1',
        },
        {
            value: 'Акции Почта Банка',
            xmlId: '2',
        },
        {
            value: 'МИР',
            xmlId: '3',
        },
        {
            value: 'VISA',
            xmlId: '4',
        },
        {
            value: 'Mastercard',
            xmlId: '5',
        },
    ],
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    title: 'Заголовок',
    list: [
        {
            image: '/img-next/png/action/1.png',
            title: 'Платежи без комиссии',
            subtitle: '',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            tags: ['1', '2', '3', '4', '5'],
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/action/2.png',
            title: 'Переводы на Балканы и в Словакию',
            subtitle: '',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            tags: ['1', '2', '3', '4', '5'],
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '/img-next/png/action/3.png',
            title: 'Подари лес другу',
            subtitle: '',
            textBtn: 'Узнать больше',
            link: '#',
            isLink: true,
            tags: ['1', '2', '3', '4', '5'],
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};

export const mockMainSliderBlockTinaData = {
    section: null,
    name: 'Карусель',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    title: 'Заголовок',
    list: [
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            textBtn: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            textBtn: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            textBtn: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            textBtn: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
        {
            image: '',
            title: 'Заголовок',
            subtitle: 'Описание',
            textBtn: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'test',
                category: 'test',
                label: 'test',
            },
        },
    ],
};
