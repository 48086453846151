import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import ActionPensionsItem from '@/components/blocks/ActionPensions/item';
import { IActionPensions } from '@/types/actionPensions/item';

import cn from './style.module.sass';

interface IProps {
    list: IActionPensions['list'];
}

function ActionPensionsList({ list }: IProps) {
    return (
        <div className={clsx(cn.grid)}>
            <div className={cn.list}>
                {list.map((item, index) => (
                    <ActionPensionsItem {...item} key={`main-${index}`} pos={index} />
                ))}
            </div>
        </div>
    );
}

export default observer(ActionPensionsList);
