import React from 'react';

import { TinaCreditForm } from '@/components/blocks/frames/CreditForm/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const creditFormBlock = {
    Component: (props: IBlockProps) => <TinaCreditForm {...props} />,
    template: {
        label: 'Форма на выбор: Кредитные каникулы/карты',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'type',
                component: 'select',
                label: 'Тип элемента',
                options: [
                    { value: 'cardsCardType', label: 'Карты (тип карты)' },
                    { value: 'cardsCreditType', label: 'Карты (тип кредит)' },
                    { value: 'vacationCardType', label: 'Кредитные каникулы (тип карты)' },
                    { value: 'vacationCreditType', label: 'Кредитные каникулы (тип кредит)' },
                ],
            },
        ],
    },
};
