import React from 'react';

import SalesHROffersCard from '@/components/blocks/SalesHRLanding/core/SalesHROffers/Card';
import { Offers } from '@/components/blocks/SalesHRLanding/types/offers';

import cn from './style.module.sass';

type SalesHROffersListProps = {
    offers: Offers;
};

function SalesHROffersList({ offers }: SalesHROffersListProps) {
    if (offers.length === 0) return null;

    return (
        <div className={cn.offersList}>
            {offers.map(({ id, icon, title, list, buttonValue }) => (
                <SalesHROffersCard
                    icon={icon}
                    title={title}
                    list={list}
                    buttonValue={buttonValue}
                    id={id}
                    key={title}
                />
            ))}
        </div>
    );
}

export default SalesHROffersList;
