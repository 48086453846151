import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IHRAchievementsList {
    hrAchievementsList: IHRAchievementsItem[];
}
interface IHRAchievements extends IHRAchievementsList {
    title: string;
}

export interface IHRAchievementsItem {
    img: string;
    title: string;
    description: string;
}

function HRAchievements({ hrAchievementsList, title }: IHRAchievements) {
    return (
        <div className={clsx(cn.achievements)} id="our-achievements">
            <div className={cn.wrap}>
                <div className={cn.achievementsBox}>
                    <div className={cn.achievementsHeader}>{title}</div>
                    <div className={cn.achievementsGrid}>
                        {hrAchievementsList.map((item, index) => (
                            <div className={cn.achievementsInner} key={index}>
                                <div
                                    className={clsx(cn.achievementsItem)}
                                    style={{ backgroundImage: `url(${item.img})` }}
                                >
                                    <div className={cn.achievementsImgBox} />

                                    <div className={cn.achievementsTitle}>{item.title}</div>
                                    <div
                                        className={cn.achievementsDesc}
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRAchievements;
