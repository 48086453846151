import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IQuoteHrItem } from '@/types/quoteHr/item';

import cn from './style.module.sass';

const ModalItem: React.FC<IQuoteHrItem> = ({ photo, name, position, desc, pos, tinaPrefix }) => (
    <div className={cn.item}>
        <div className={cn.itemGrid}>
            <div className={cn.itemTop}>
                {photo && (
                    <div className={cn.imageWrapper}>
                        <div className={cn.image}>
                            <CustomImage src={photo} alt={name} mockWidth="96" mockHeight="96" fill />
                        </div>
                    </div>
                )}
                <div className={cn.content}>
                    <TextField text={name} name={`${tinaPrefix}items.[${pos}].name`} className={cn.name} isTextArea />
                    {position && (
                        <TextField
                            text={position}
                            name={`${tinaPrefix}items.[${pos}].position`}
                            className={cn.position}
                            isTextArea
                        />
                    )}
                </div>
            </div>
            <div className={cn.itemBottom}>
                <TextField
                    text={desc}
                    name={`${tinaPrefix}items.[${pos}].desc`}
                    className={cn.text}
                    isHTML
                    htmlValue={desc}
                />
            </div>
        </div>
    </div>
);

export default ModalItem;
