import { THypothecReadyHousingCalculator } from '@/types/calculator/hypothecReadyHousingCalc';

export const hypothecReadyHousingCalculatorData: THypothecReadyHousingCalculator = {
    _template: 'hypothecReadyHousingCalculator',
    title: 'Ипотечный калькулятор на готовое жилье',
    config: [
        {
            target: 'Ипотечный калькулятор на готовое жилье',
            regionsMax: 30000000,
            regionsMin: 300000,
            minCreditValue: 300000,
            rates: [
                {
                    title: 'Покупка готового дома с земельным участком',
                    code: 'HOUSE',
                    desc: '',
                    regionsMax: 30000000,
                    regionsMin: 300000,
                    rateValue: 17.5,
                    firstPayment: 20,
                    fullCreditCoastMin: 18.512,
                    fullCreditCoastMax: 27.747,
                },
                {
                    title: 'Покупка квартиры',
                    code: 'FLAT',
                    desc: '',
                    regionsMax: 30000000,
                    regionsMin: 300000,
                    rateValue: 17.0,
                    firstPayment: 20,
                    fullCreditCoastMin: 18.208,
                    fullCreditCoastMax: 24.606,
                },
            ],
            depositElements: [
                { value: 0, step: 100000, percent: 0, label: '0', id: 1 },

                { value: 100000000, step: 500000, percent: 100, label: '100 млн', id: 8 },
            ],
            sumElements: [
                { value: 0, step: 500000, percent: 0, label: '0', id: 110 },

                { value: 100000000, step: 500000, percent: 100, label: '100 млн', id: 100 },
            ],
            termsElements: [
                { value: 1, step: 1, percent: 0, label: '1 год', id: 1 },
                { value: 5, step: 1, percent: 16, label: '5 лет', id: 2 },
                { value: 10, step: 1, percent: 33, label: '10 лет', id: 3 },
                { value: 15, step: 1, percent: 50, label: '15 лет', id: 4 },
                { value: 20, step: 1, percent: 66, label: '20 лет', id: 5 },
                { value: 25, step: 1, percent: 83, label: '25 лет', id: 6 },
                { value: 30, step: 1, percent: 100, label: '30 лет', id: 7 },
            ],
            matCapitalDesc: '',
            button: { label: 'Онлайн заявка', link: '#' },
            tooltipText: 'Если Вы хотите взять кредит на большую сумму, обратитесь к менеджеру.',
        },
    ],
};
