import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

export interface IFlocktoryIntegration {
    _template: 'flocktoryIntegration';
    className?: string;
}

const FlocktoryIntegration: React.FC<IFlocktoryIntegration> = ({ _template = 'flocktoryIntegration', className }) => (
    <div className="section">
        <div className={clsx(cn.centred, className)} />
    </div>
);

export default FlocktoryIntegration;
