import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import FAQThemeContent from '@/components/blocks/faq/FAQThemeContent';
import FAQThemeTopContent from '@/components/blocks/faq/FAQThemeTopContent';
import Sidebar from '@/components/blocks/faq/Sidebar';
import { useTabletSmallWidth } from '@/hooks/useMedia';
import { IFAQTheme } from '@/types/faq';

import cn from './style.module.sass';

const FAQTheme: React.FC<IFAQTheme> = ({ list }) => {
    const isMobile = useTabletSmallWidth();

    let activeItem = 0;
    if (typeof window !== 'undefined') {
        const index = list.findIndex(item => item?.link === window.location.pathname);

        activeItem = index > 0 ? index : 0;
    }

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx('section', cn.wrapper)}>
                <div className={cn.fullWrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            {isMobile && <FAQThemeTopContent list={list} activeItem={activeItem} />}

                            <Sidebar list={list} activeItem={activeItem} isThemes />
                        </div>
                        <div className={cn.right}>
                            <FAQThemeContent list={list} activeItem={activeItem} />
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default FAQTheme;
