import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { ITermItem } from '@/types/calculator/config';

import cn from './style.module.sass';

interface TermItemProps {
    term: ITermItem;
    isActive: boolean;
    handleClick: (term: ITermItem) => void;
    noFixedWidth?: boolean;
}

const TermItem: FC<TermItemProps> = observer(({ term, isActive, handleClick, noFixedWidth }) => (
    <div className={cn.wrapper}>
        {term?.topLabel && <div className={cn.topLabel}>{term.topLabel}</div>}
        <div
            role="presentation"
            onClick={() => handleClick(term)}
            className={clsx(
                cn.item,
                isActive && cn.active,
                term.disabled && cn.disable,
                noFixedWidth && cn.noFixedWidth
            )}
            dangerouslySetInnerHTML={{ __html: term.label }}
        />
    </div>
));

export default TermItem;
