export const mockSelectableDataTina = {
    firstFieldName: 'Заголовок',
    firstFieldDescription: 'Описание',
    firstFieldTitle: 'Название Поля № 1',
    secondFieldName: 'Название Поля № 2',
    options: [
        {
            label: 'Название',
            value: 'Значение',
        },
    ],
};
