import { IOptionsItem } from '@/data/blocks/feedbackFormData';

export const optionsSalaryProject: IOptionsItem[] = [
    {
        value: 'Зарплатный проект',
        label: 'Зарплатный проект',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
            {
                type: 'input',
                inputType: 'text',
                label: 'ИНН компании',
                name: 'inn',
                validate: 'inn',
                required: false,
            },
        ],
    },
];
