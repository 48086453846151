import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { ICashbackItem } from '@/types/cashback/item';

import cn from './style.module.sass';

const CashbackItem: React.FC<ICashbackItem & { pos: number }> = ({ desc, title, subtitle, image, pos }) => (
    <AnimatedComponent classNameActive={cn.animationInit} className={cn.cashbackItem}>
        <div className={cn.cashbackItemWrap}>
            <div className={clsx(cn.cashbackContent, !image && cn.cashbackContentNoImg)}>
                <TextField
                    text={desc}
                    name={`list.[${pos}].desc`}
                    className={cn.cashbackDesc}
                    isHTML
                    htmlValue={desc}
                />
                <TextField
                    text={title}
                    name={`list.[${pos}].title`}
                    className={cn.cashbackTitle}
                    isHTML
                    htmlValue={title}
                />
                <TextField
                    text={subtitle}
                    name={`list.[${pos}].subtitle`}
                    className={cn.cashbackSubtitle}
                    isHTML
                    htmlValue={subtitle}
                />
            </div>
            {image && (
                <div className={cn.cashbackImageWrap}>
                    <div className={cn.cashbackImage}>
                        <CustomImage src={image} alt={title} fill />
                    </div>
                </div>
            )}
        </div>
    </AnimatedComponent>
);

export default CashbackItem;
