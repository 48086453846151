export const mockCampaignBlockBlockData = {
    image: '/img-next/png/campaignCard.png',
    imageAlt: 'Акция пенсионерам',
    subhead: '<b>Акция!</b> Начните получать пенсию на карту Почта Банка и получите 2 000 ₽',
};

export const mockCampaignBlockBlockTinaData = {
    section: null,
    image: '',
    imageAlt: 'Описание картинок',
    subhead: 'Заголовок',
};
