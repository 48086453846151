import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

interface ITopChartItem {
    image?: string;
    verticalImagePosition?: string;
    topText: string;
    desc: string;
    source: string;
    id: number;
}

export interface ITopChart {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'topChart';
    list: Array<ITopChartItem>;
}

function TopChart({ list }: ITopChart) {
    if (!list) return null;

    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={cn.innerWrapper}>
                <div className={cn.grid}>
                    {list.map((item, index) => (
                        <AnimatedComponent className={cn.item} classNameActive={cn.animationInit} key={index}>
                            <div className={cn.content}>
                                <div className={cn.left}>
                                    <div className={cn.top}>
                                        <div className={cn.topText}>
                                            <TextField
                                                name={`list[${index}].topText`}
                                                text={item.topText}
                                                isHTML
                                                htmlValue={item.topText}
                                            />
                                        </div>
                                        <div className={cn.desc}>
                                            <TextField
                                                name={`list[${index}].desc`}
                                                text={item.desc}
                                                isHTML
                                                htmlValue={item.desc}
                                            />
                                        </div>
                                    </div>
                                    <div className={cn.bottom}>
                                        По версии
                                        <br />
                                        <TextField
                                            name={`list[${index}].source`}
                                            text={item.source}
                                            isHTML
                                            htmlValue={item.source}
                                        />
                                    </div>
                                </div>
                                <div className={cn.right}>
                                    <div
                                        className={clsx(
                                            cn.imgWrapper,
                                            item?.verticalImagePosition === 'top' && cn.imgWrapperTop,
                                            item?.verticalImagePosition === 'bottom' && cn.imgWrapperBottom
                                        )}
                                    >
                                        <CustomImage src={item.image} alt="Почтабанк" fill />
                                    </div>
                                </div>
                            </div>
                        </AnimatedComponent>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default withBlockLinks(TopChart);
