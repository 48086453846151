import React from 'react';

import { TinaHeadManagement } from '@/components/blocks/headManagement/HeadManagement/index.tina';
import { mockHeadManagementBlockDataTina } from '@/data/blocks/headManagement';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const headManagementBlock = {
    Component: props => <TinaHeadManagement {...props} />,
    template: {
        label: 'Основной блок для страницы лидера',
        defaultItem: () => mockHeadManagementBlockDataTina,
        component: 'group',
        fields: [
            {
                name: 'banner.description',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'banner.image',
                component: 'image',
                label: 'Фото',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'banner.line',
                component: 'group',
                label: 'Должность',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => ({
                    title: 'Дмитрий Руденко',
                    subtitle: 'Президент-Председатель Правления Почта Банка',
                }),
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Подзаголовок',
                    },
                ],
            },
            {
                name: 'banner.socialList',
                component: 'group-list',
                label: 'Соц. сети.',
                itemProps: (item: { name: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => ({
                    image: '/img-next/svg/socialColor/fb.svg',
                    link: '#',
                    name: 'Facebook',
                }),
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Текст',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    { ...gtagSingleItem },
                ],
            },
            {
                label: 'Награды',
                name: 'awardsList',
                component: 'group-list',
                itemProps: (item: { year: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.year,
                }),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                defaultItem: () => mockHeadManagementBlockDataTina.awardsList[0],
                fields: [
                    {
                        name: 'year',
                        component: 'text',
                        label: 'Год',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
