export const mockPromotionGradientBannerTinaData = {
    _template: 'promotionGradientBanner',
    promotion: {
        link: '#',
        button: 'Подробнее',
        mobileText: 'Скачать Почта Банк Онлайн',
        text: 'Установите приложение Почта Банк Онлайн',
        image: undefined,
    },
};
