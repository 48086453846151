import React from 'react';

import { TinaServices } from '@/components/blocks/Services/index.tina';
import { mockServicesBlockTinaData } from '@/data/blocks/services';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';

export const servicesBlock = {
    Component: props => <TinaServices {...props} />,
    template: {
        label: 'Сервисы: Мобильное приложение и Интернет-банк',
        defaultItem: () => mockServicesBlockTinaData,
        fields: [
            {
                name: 'mobile',
                component: 'group',
                label: 'Мобильное приложение.',
                fields: [
                    {
                        name: 'section',
                        component: 'section',
                        label: 'Привязка к разделу',
                    },
                    {
                        name: 'mobile.title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'mobile.subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'mobile.mobileSubtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание для мобильной версии',
                    },
                    {
                        name: 'mobile.qrCode',
                        component: 'group',
                        label: 'QRCode',
                        fields: [
                            {
                                name: 'text',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Картинка: Темная тема',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'imageDarkTheme',
                                component: 'image',
                                label: 'Картинка',
                                parse: (media: { src: string }) => media.src,
                            },
                        ],
                    },
                    {
                        name: 'mobile.textLink',
                        component: 'text',
                        label: 'Текст на ссылке',
                    },
                    {
                        name: 'mobile.link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'mobile.isLink',
                        component: 'toggle',
                        label: 'Кнопка/Ссылка',
                    },
                    {
                        name: 'mobile.store',
                        component: 'group-list',
                        label: 'Список приложений для вывода на мобильной версии',
                        itemProps: (item: { name: string }, index) => ({
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => ({
                            image: '/img-next/svg/services/AppStore.svg',
                            name: 'App Store',
                            link: '#',
                        }),
                        fields: [
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Картинка',
                                parse: (media: { src: string }) => media.src,
                            },
                        ],
                    },
                    {
                        name: 'mobile.image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    { ...gtagSingleItemCustom('mobile.gtag', 'Цели GTM') },
                ],
            },
            {
                name: 'bank',
                component: 'group',
                label: 'Интернет-банк.',
                fields: [
                    {
                        name: 'section',
                        component: 'section',
                        label: 'Привязка к разделу',
                    },
                    {
                        name: 'bank.title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'bank.subtitle',
                        component: 'textarea',
                        label: 'Описание',
                    },
                    {
                        name: 'bank.mobileSubtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание для мобильной версии',
                    },
                    {
                        name: 'bank.image',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'bank.textLink',
                        component: 'text',
                        label: 'Текст на ссылке',
                    },
                    {
                        name: 'bank.link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'bank.isLink',
                        component: 'toggle',
                        label: 'Кнопка/Ссылка',
                    },
                    {
                        name: 'bank.mobailDisplay',
                        component: 'toggle',
                        label: 'Отображать на мобильных устройствах',
                    },
                ],
            },
        ],
    },
};
