export const mockSidebarAnchorLinksBlockData = {
    list: [
        {
            linkHref: '#',
            linkName: 'На что влияет длительность займа?',
        },
        {
            linkHref: '#',
            linkName: 'Почему не стоит брать краткосрочный кредит',
        },
        {
            linkHref: '#',
            linkName: 'Почему не надо рассчитывать на долгосрочный кредит',
        },
        {
            linkHref: '#',
            linkName: 'Как определить оптимальный период кредита',
        },
        {
            linkHref: '#',
            linkName: 'Примеры кредита на разный промежуток и его стоимость',
        },
        {
            linkHref: '#',
            linkName: 'Может ли банк обозначить свои правила',
        },
        {
            linkHref: '#',
            linkName: 'Сделаем вывод',
        },
    ],
};

export const mockSidebarAnchorLinksBlockDataWithRollable = {
    list: [
        {
            linkHref: '#',
            linkName: 'На что влияет длительность займа?',
        },
        {
            linkHref: '#',
            linkName: 'Почему не стоит брать краткосрочный кредит',
        },
        {
            linkHref: '#',
            linkName: 'Почему не надо рассчитывать на долгосрочный кредит',
        },
        {
            linkHref: '#',
            linkName: 'Как определить оптимальный период кредита',
        },
        {
            linkHref: '#',
            linkName: 'Примеры кредита на разный промежуток и его стоимость',
        },
        {
            linkHref: '#',
            linkName: 'Может ли банк обозначить свои правила',
        },
        {
            linkHref: '#',
            linkName: 'Сделаем вывод',
        },
    ],
    isRollable: true,
    rollableBlockName: 'В этом тексте:',
};

export const mockSidebarAnchorLinksBlockDataTina = {
    list: [
        {
            linkHref: '#',
            linkName: 'Название ссылки',
        },
        {
            linkHref: '#',
            linkName: 'Название ссылки',
        },
    ],
};
