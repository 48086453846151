import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import TermField, { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';

export interface IBuildingCalculatorStore {
    range: IRangeInput;
    percentRange: IRangeInput;
    termField: ITermField;
    paymentValue: number;
    creditValue: number;
    rate: number;
    isChecked?: boolean;
    creditSum?: number;
    toggleChecked?: () => void;
    valueRate?: { lowRate: number; highRate: number };
}

class BuildingCalculatorStore implements IBuildingCalculatorStore {
    public range: IRangeInput;

    public percentRange: IRangeInput;

    public termField: ITermField;

    public isChecked = false;

    public valueRate: { lowRate: number; highRate: number };

    constructor(
        filterElements: ICalculatorFilterElement[],
        secondFilterElements: ICalculatorFilterElement[],
        terms: ITermItem[],
        valueRate = { lowRate: 6.9, highRate: 14.9 }
    ) {
        const rangeInitValue = 1000000;

        this.range = new RangeInput(
            {
                name: 'creditValue',
                label: 'Стоимость дома',
                elements: filterElements,
                initValue: rangeInitValue,
            },
            true
        );

        this.percentRange = new RangeInput(
            {
                name: 'initialContributionValue',
                label: 'Первоначальный взнос',
                elements: secondFilterElements,
                initValue: 10,
                customFormatValueHandle: (value, _) => `${value}%`,
            },
            true
        );

        this.termField = new TermField(terms);

        this.valueRate = valueRate;

        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    private setFrameValue = () => {
        if (typeof window === 'undefined') return;

        if (window?.PBSDK && window?.PBSDK?.creditIssue)
            window.PBSDK.creditIssue.setCreditAmount(this.creditSum.toString());
    };

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    get rate() {
        return this.isChecked ? this.valueRate.lowRate : this.valueRate.highRate;
    }

    get creditSum() {
        if (this.isChecked) {
            const costOfHouse = this.range.value - this.range.value * (this.percentRange.value / 100);

            const permanentValue = 1 - (1.1 * 6) / 100 - (1.2 * 12) / 100;

            return Math.round(costOfHouse / permanentValue);
        }

        return Math.round(this.range.value - this.range.value * (this.percentRange.value / 100));
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        const correctRate = this.rate / 100;

        // 1+ ставка/12 ** срок
        const P = (1 + correctRate / 12) ** this.termField.value;
        // Верхняя часть
        const T = this.creditSum * (correctRate / 12) * P;
        // Нижняя часть
        const B = P - 1;

        this.setFrameValue();

        return Math.trunc(T / B);
    }
}

export default BuildingCalculatorStore;
