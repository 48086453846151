// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';

interface IDateObject {
    month: string;
    day: string;
    year: string;
}

export const getDateObjectFromISO = (isoDate): IDateObject => {
    if (!isoDate) return { month: '', day: '', year: '' };

    try {
        const date = format(parseISO(isoDate), 'MMMM-dd-yyyy', { locale: ru });
        const splittedDate = date.split('-');
        const [month, day, year] = splittedDate;
        return { month, day, year };
    } catch (e) {
        return { month: '', day: '', year: '' };
    }
};
