import clsx from 'clsx';
import React from 'react';

import CreditCard from '@/components/SpecialOffer/CreditCard';
import YandexSearchForm from '@/components/UI/YandexSearchForm';
import YandexSearchResults from '@/components/UI/YandexSearchResults';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

const staticDataCard = {
    title: 'Кредит наличными',
    desc: 'На выгодных условиях',
    href: 'https://www.pochtabank.ru/service/cashloan',
    button: 'Подробнее',
    isPWA: true,
    img: 'https://pochtabank-upload-prod.storage.yandexcloud.net/medialibrary/fde/fdeb55b72389f0774cc184aa70b788e1/e2516f93cad23c9b36fdf4780681ad12.png?id=5075',
    gtag: {
        action: 'click',
        category: 'Interactions',
        label: 'main__header_pwa_input_credit',
    },
};

export interface ISearchResultsPage {
    _template?: 'searchResultsPage';
}

const SearchResultsPage: React.FC<ISearchResultsPage> = ({ _template = 'searchResultsPage' }) => {
    if (!Feature.isFeatureEnabled('enableSearchYandex')) {
        return null;
    }

    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={clsx(cn.fullWrapper)}>
                <div className={clsx(cn.grid)} id="search-page">
                    <div className={clsx(cn.search)}>
                        <YandexSearchForm flag />
                    </div>
                    <div className={clsx(cn.content)}>
                        <div className={clsx(cn.results)}>
                            <YandexSearchResults />
                        </div>
                        <div className={clsx(cn.info)}>
                            <CreditCard {...staticDataCard} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchResultsPage;
