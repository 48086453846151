/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import React, { useState } from 'react';

import ItemTagMapper from '@/components/blocks/advantages/Advantages/item.tag.mapper';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useAppStore } from '@/context/AppStoreContext';
import { useFixHtml } from '@/hooks/useFixHtml';
import { IAdvantagesItem } from '@/types/advantages/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import FullScreenTolltip from '../../FullScreenTolltip';
import cn from './style.module.sass';

const AdvantageItem: React.FC<IAdvantagesItem & { pos: number }> = ({
    link,
    title,
    subtitle,
    image,
    imageLink,
    size = 'small',
    tooltipText,
    pos,
    button,
    isHoverNeeded = false,
    bgColor = '',
    isClickable = false,
}) => {
    const [showToolTip, setShowTooltip] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();
    const [isTinaView] = useAppStore(store => store.isTinaView);

    const show = () => {
        fix();
        setShowTooltip(true);
    };

    const hide = () => {
        unfix();
        setShowTooltip(false);
    };

    const sizeToNameMapping = {
        small: 4,
        middle: 6,
        big: 8,
        full: 12,
    };

    return (
        <ItemTagMapper
            className={clsx(
                cn.advantagesItem,
                cn[`advantagesItem${sizeToNameMapping[size]}`],
                bgColor && cn[`bgColor--${bgColor}`],
                isClickable && cn.clickable
            )}
            isClickable={isClickable}
            key={pos}
            link={link}
        >
            <div className={clsx(cn.advantagesItemWrap, isHoverNeeded && cn.advantagesItemHover)} role="presentation">
                <div className={cn.advantagesItemGrid}>
                    {tooltipText?.length > 0 && tooltipText !== '<p></p>' && (
                        <div className={cn.tooltip}>
                            <div onClick={show}>
                                <TooltipIcon />
                            </div>
                        </div>
                    )}
                    <div className={cn.advantagesContent}>
                        {link && !isClickable && (
                            <TextField
                                customTag="a"
                                href={link}
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                                className={cn.advantagesTitle}
                            />
                        )}

                        {isClickable && title && (
                            <TextField
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                                className={cn.advantagesTitle}
                            />
                        )}

                        {!link && title && (
                            <TextField
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                                className={cn.advantagesTitle}
                            />
                        )}

                        {subtitle && (
                            <TextField
                                text={subtitle}
                                name={`list.[${pos}].subtitle`}
                                isHTML
                                htmlValue={subtitle}
                                className={cn.advantagesSubtitle}
                            />
                        )}
                        {button?.label && (
                            <div className={cn.advantagesBtn}>
                                <Button
                                    variant="btnBlue"
                                    type="link"
                                    href={button.link}
                                    label={button.label}
                                    animation={false}
                                    onClick={() => {
                                        if (button?.gtag?.action) event(button?.gtag);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {(image || imageLink) && (
                        <div className={cn.advantagesImageWrap}>
                            <div className={cn.advantagesImage}>
                                {imageLink && isTinaView && (
                                    <div className="promoServiceDesc" style={{ padding: '32px' }}>
                                        предпросмотр картинки с продуктовым параметром невозможен
                                    </div>
                                )}
                                {imageLink && !isTinaView && (
                                    <CustomImage src={imageLink} alt={title} mockWidth="200" mockHeight="200" fill />
                                )}
                                {image && !imageLink && (
                                    <CustomImage src={image} alt={title} mockWidth="200" mockHeight="200" fill />
                                )}
                                <div className={cn.advantagesBotDecor} />
                            </div>
                        </div>
                    )}
                    <div className={cn.advantagesBotDecor} />
                </div>
            </div>
            <FullScreenTolltip tooltipText={tooltipText} handleClose={hide} isOpen={showToolTip} />
        </ItemTagMapper>
    );
};

export default AdvantageItem;
