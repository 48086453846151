export const creditCalculatorData = {
    title: 'Рассчитайте кредит наличными',
    tabTitle: 'Рассчитайте кредит наличными',
    rate: {
        ranges: [
            {
                value: 4,
                min: 0,
                max: 29999,
            },
            {
                value: 3,
                min: 30000,
                max: 300000,
            },
        ],
    },
    fields: {
        rangeSlider: [
            {
                name: 'range1',
                elements: [
                    {
                        value: 10000,
                        step: 1000,
                        percent: 0,
                        label: '10 тыс',
                        id: 1,
                    },
                    {
                        value: 30000,
                        step: 1000,
                        percent: 20,
                        label: '30 тыс',
                        id: 2,
                    },
                    {
                        value: 50000,
                        step: 1000,
                        percent: 40,
                        label: '50 тыс.',
                        id: 3,
                    },
                    {
                        value: 100000,
                        step: 1000,
                        percent: 60,
                        label: '100 тыс.',
                        id: 4,
                    },
                    {
                        value: 200000,
                        step: 1000,
                        percent: 80,
                        label: '200 тыс.',
                        id: 5,
                    },
                    {
                        value: 300000,
                        step: 1000,
                        percent: 100,
                        label: '300 тыс.',
                        id: 6,
                    },
                ],
                initValue: 10000,
                label: 'Сумма кредита',
            },
        ],
    },
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
