import React from 'react';

import CollapsibleItem from '@/components/blocks/faq/CollapsibleItem';
import FAQThemeTopContent from '@/components/blocks/faq/FAQThemeTopContent';
import { useTabletSmallWidth } from '@/hooks/useMedia';
import { IFaqItem } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    list: IFaqItem[];
    activeItem: number;
}

const FAQThemeContent: React.FC<IProps> = ({ list, activeItem }) => {
    const isMobile = useTabletSmallWidth();

    if (!list || list?.length <= 0) return null;

    return (
        <div>
            {!isMobile && <FAQThemeTopContent list={list} activeItem={activeItem} />}

            {list[activeItem]?.info && (
                <div className={cn.list}>
                    {list[activeItem]?.info?.map((item, index) => (
                        <CollapsibleItem key={index} pos={index} activeItem={activeItem} item={item} isThemeFlag />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FAQThemeContent;
