export const mockCashCreditCalculatorData = {
    title: 'Рассчитайте условия по кредиту',
    tabTitle: 'Наличными',
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagButtonShare: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
    params: {
        terms: [
            {
                id: 1,
                label: '3 года',
                value: 3,
                ranges: [
                    {
                        id: 1,
                        percent: 17.9,
                        min: 10000,
                        max: 30000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 14.9,
                        min: 30001,
                        max: 190000,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 13.9,
                        min: 190001,
                        max: 500000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 4,
                        percent: 10.9,
                        min: 500001,
                        max: 1000000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 5,
                        percent: 9.9,
                        min: 1000001,
                        max: 5000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 2,
                label: '4 года',
                value: 4,
                ranges: [
                    {
                        id: 1,
                        percent: 17.9,
                        min: 10000,
                        max: 30000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 14.9,
                        min: 30001,
                        max: 190000,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 13.9,
                        min: 190001,
                        max: 500000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 4,
                        percent: 10.9,
                        min: 500001,
                        max: 1000000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 5,
                        percent: 9.9,
                        min: 1000001,
                        max: 5000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 3,
                label: '5 лет',
                value: 5,
                ranges: [
                    {
                        id: 1,
                        percent: 17.9,
                        min: 10000,
                        max: 30000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 14.9,
                        min: 30001,
                        max: 190000,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 13.9,
                        min: 190001,
                        max: 500000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 4,
                        percent: 10.9,
                        min: 500001,
                        max: 1000000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 5,
                        percent: 9.9,
                        min: 1000001,
                        max: 5000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 4,
                label: '6 лет',
                value: 6,
                ranges: [
                    {
                        id: 1,
                        percent: 17.9,
                        min: 10000,
                        max: 30000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 14.9,
                        min: 30001,
                        max: 190000,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 13.9,
                        min: 190001,
                        max: 500000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 4,
                        percent: 10.9,
                        min: 500001,
                        max: 1000000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 5,
                        percent: 9.9,
                        min: 1000001,
                        max: 5000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 5,
                label: '7 лет',
                value: 7,
                ranges: [
                    {
                        id: 1,
                        percent: 17.9,
                        min: 10000,
                        max: 30000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 14.9,
                        min: 30001,
                        max: 190000,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 13.9,
                        min: 190001,
                        max: 500000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 4,
                        percent: 10.9,
                        min: 500001,
                        max: 1000000,
                        balancePercent: 37.5048604181,
                    },
                    {
                        id: 5,
                        percent: 9.9,
                        min: 1000001,
                        max: 5000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
        ],
        filterElements: [
            {
                value: 10000,
                step: 5000,
                percent: 0,
                label: '10 тыс.',
                id: 1,
            },
            {
                value: 30000,
                step: 20000,
                percent: 20,
                label: '30 тыс.',
                id: 2,
            },
            {
                value: 200000,
                step: 50000,
                percent: 40,
                label: '200 тыс.',
                id: 3,
            },
            {
                value: 500000,
                step: 100000,
                percent: 60,
                label: '500 тыс.',
                id: 4,
            },
            {
                value: 1000000,
                step: 100000,
                percent: 80,
                label: '1 млн.',
                id: 5,
            },
            {
                value: 5000000,
                step: 200000,
                percent: 100,
                label: '5 млн.',
                id: 6,
            },
        ],
        minValue: 10000,
        maxValue: 5000000,
    },
    popup: {
        ranges: [
            {
                title: 'Диапазон 1',
                min: 20000,
                max: 30000,
                subtraction: 40000,
                rate: 3,
            },
            {
                title: 'Диапазон 2',
                min: 70000,
                max: 190000,
                subtraction: 200000,
                rate: 1,
            },
            {
                title: 'Диапазон 3',
                min: 350000,
                max: 500000,
                subtraction: 550000,
                rate: 3,
            },
            {
                title: 'Диапазон 4',
                min: 700000,
                max: 1000000,
                subtraction: 1100000,
                rate: 1,
            },
        ],
    },
    banner: {
        ranges: [
            {
                title: '',
                subtitle: 'Без обязательных страховок',
                min: 0,
                max: 270000,
            },
            {
                title: '',
                subtitle: '',
                min: 270001,
                max: 499999,
                shouldHide: true,
            },
            {
                title: '3%',
                subtitle: 'ежемесячный платеж',
                min: 500000,
                max: 6000000,
            },
        ],
        isActive: true,
        image: '/img-next/png/buildingCalculator/imgType1.png',
    },
    infoTitle: {
        ranges: [
            {
                text: 'Предварительный расчёт по кредитной карте «Деньги и Всё!». Не является публичной офертой.',
                min: 0,
                max: 99999,
            },
            {
                text: 'Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке &nbsp;5.9% годовых с&nbsp;услугой «Гарантированная ставка». Не&nbsp;является публичной офертой.',
                tooltipText:
                    'Погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке 5.9% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита',
                min: 100000,
                max: 6000000,
            },
        ],
        isActive: true,
    },
    buttonShared: {
        isActive: true,
    },
};
