export const mockYouTubeVideosBlockData = {
    title: 'Наши счастливые клиенты',
    list: [
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
            buttonColor: 'light',
        },
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
        },
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
        },
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
        },
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
        },
        {
            title: 'Дом мечты за месяц',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
        },
    ],
};

export const mockYouTubeVideosBlockDataTina = {
    title: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            link: 'https://www.youtube.com/embed/K58Ug5-QbP0',
            buttonColor: 'dark',
        },
    ],
};
