import clsx from 'clsx';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Tabs from '@/components/blocks/PhotoDateBlock/Tabs';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useMobileWidth } from '@/hooks/useMedia';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IWinner {
    img?: string;
    desc?: string;
    date: string;
}

export interface IPhotoDateBlock {
    _template: 'photoDateBlock';
    title: string;
    list: IWinner[];
    button?: {
        href: string;
        label: string;
        gtag?: GTagEvent;
    };
}

function PhotoDateBlock({ title, button, list }: IPhotoDateBlock) {
    const isMobile = useMobileWidth();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    return (
        <AnimatedComponent className={clsx(cn.photoDateBlock, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.photoDateBlockWrap}>
                <div className={clsx(cn.photoDateBlockContainer)}>
                    <div className={cn.photoDateBlockItem}>
                        <div className={clsx(cn.photoDateBlockContent)}>
                            {title && (
                                <TextField
                                    text={title}
                                    className={cn.photoDateBlockTitle}
                                    name="title"
                                    htmlValue={title}
                                    isHTML
                                />
                            )}
                            {isMobile ? (
                                <Swiper
                                    spaceBetween={8}
                                    slidesPerView="auto"
                                    className={clsx(cn.slider, cn.start)}
                                    allowTouchMove
                                >
                                    {list?.map((item, index) => (
                                        <SwiperSlide key={index} className={cn.slide}>
                                            <Tabs
                                                className={clsx(cn.tab, !item.img && cn.disabled)}
                                                onClick={handleTabClick}
                                                index={index}
                                            >
                                                {item.date}
                                            </Tabs>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            ) : (
                                <div className={cn.tabsWrapper}>
                                    {list?.map((item, index) => (
                                        <Tabs
                                            key={index}
                                            className={clsx(cn.tab, !item.img && cn.disabled)}
                                            onClick={handleTabClick}
                                            index={index}
                                        >
                                            {item.date}
                                        </Tabs>
                                    ))}
                                </div>
                            )}
                            {list?.map((item, index) => (
                                <TextField
                                    key={index}
                                    text={item.desc}
                                    isHTML
                                    htmlValue={item.desc}
                                    name="desc"
                                    className={clsx(cn.photoDateBlockDesc, index !== activeTab && cn.hidden)}
                                />
                            ))}
                        </div>
                        {button && (
                            <Button
                                customClass={clsx(cn.button, isMobile && cn.hideOnMobile)}
                                variant="btnBlue"
                                type="link"
                                href={button.href}
                                label={button.label}
                                onClick={() => {
                                    if (button?.gtag?.action) event(button?.gtag);
                                }}
                            />
                        )}
                    </div>
                    <div className={clsx(cn.photoDateBlockItemImageContainer)}>
                        <div className={clsx(cn.photoDateBlockItemImage)}>
                            {list && list[activeTab] && (
                                <CustomImage
                                    src={list[activeTab].img}
                                    alt={list[activeTab].date}
                                    width={582}
                                    height={360}
                                />
                            )}
                        </div>
                    </div>
                    {button && isMobile && (
                        <Button
                            customClass={clsx(cn.button, cn.buttonMobile)}
                            variant="btnBlue"
                            type="link"
                            href={button.href}
                            label={button.label}
                            onClick={() => {
                                if (button?.gtag?.action) event(button?.gtag);
                            }}
                        />
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(PhotoDateBlock);
