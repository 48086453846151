import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsMortgage } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormMortgage {
    _template: 'feedbackFormMortgage';
    title?: string;
    description?: string;
    successTitle?: string;
}

const FeedbackFormMortgage: React.FC<IFeedbackFormMortgage> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormMortgage',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsMortgage}
        title={title}
        descFlag={false}
        description={description}
        successTitle={successTitle}
    />
);

export default FeedbackFormMortgage;
