import * as Sentry from '@sentry/nextjs';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import SubscriptionModal from '@/components/SubscriptionModal';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import { FileUpload } from '@/components/UI/FileUpload';
import Input from '@/components/UI/Input';
import TextArea from '@/components/UI/TextArea';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IResumeForm {
    _template?: 'resumeForm';
    title: string;
    description?: string;
    successTitle: string;
    noBlock?: boolean;
    summaryName?: string;
    cityName?: string;
}

const ResumeForm: React.FC<IResumeForm> = ({
    title,
    description,
    successTitle,
    noBlock,
    summaryName,
    _template = 'resumeForm',
    cityName,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState(successTitle);

    const formResolver = useValidationSchema([
        { name: 'accept', type: 'checkbox', required: true },
        { name: 'phone', type: 'phone', required: true },
        { name: 'email', type: 'email', required: true },
        { name: 'fio', type: 'fio', required: true },
        { name: 'file', type: 'arrayOfStringsOptional', required: false },
    ]);

    const methods = useForm<InnerFormValues>({
        resolver: formResolver,
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful, isSubmitting },
        setValue,
    } = useForm<InnerFormValues>({ resolver: formResolver });

    const onSubmit = async finalData => {
        const filteredFinalData = removePropertyFromObject(finalData, 'accept');
        const formData = getFormDataFromHookForm(filteredFinalData);
        formData.append('summaryName', summaryName);

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${_template}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setModalText('Произошла ошибка при отправке формы.');

                Sentry.captureMessage(
                    `Произошла ошибка при отправке формы ${_template} -- ${response?.data?.errors[0].message}`,
                    'error'
                );
            }
        } catch (e) {
            console.error(e);
        }

        setIsModalOpen(true);
    };

    const [isErrorEmail, errorEmailText] = getFormErrors(errors, 'email');
    const [isErrorPhone, errorPhoneText] = getFormErrors(errors, 'phone');
    const [isErrorAccept, errorAcceptText] = getFormErrors(errors, 'accept');
    const [isErrorFio, errorFioText] = getFormErrors(errors, 'fio');

    // Регистрация поля для файла, обязательно, так как ref занят useDropzone
    useEffect(() => {
        register('file');
        setValue('city', cityName);
    }, [register]);

    const fileTypes = ['.doc', '.docx', '.txt', '.pdf'];

    return (
        <FormProvider {...methods}>
            <div className={clsx(cn.wrapper, 'section')}>
                <div className={clsx(cn.fullWrapper, noBlock && cn.fullWrapperNoBlock)}>
                    <div className={cn.innerWrapper}>
                        <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                            <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                            <TextField
                                text={description}
                                name="description"
                                isHTML
                                htmlValue={description}
                                className={cn.description}
                            />
                            <div className={cn.input}>
                                <Input
                                    type="text"
                                    label="Фамилия, имя и отчество"
                                    shouldColored
                                    customClass={cn.isFull}
                                    isError={isErrorFio}
                                    errorText={errorFioText}
                                    {...register('fio')}
                                />
                            </div>
                            <div className={cn.row}>
                                <div className={clsx(cn.input, cn.inputMask)}>
                                    <Input
                                        type="tel"
                                        label="Телефон"
                                        mask="+7 (999) 999-99-99"
                                        shouldColored
                                        isError={isErrorPhone}
                                        errorText={errorPhoneText}
                                        {...register('phone')}
                                    />
                                </div>
                                <div className={cn.input}>
                                    <Input
                                        isError={isErrorEmail}
                                        errorText={errorEmailText}
                                        shouldColored
                                        type="text"
                                        label="E-mail"
                                        {...register('email')}
                                    />
                                </div>
                            </div>
                            <div className={cn.input}>
                                <TextArea placeholder="Текст сообщения" {...register('message')} />
                            </div>

                            <Input type="hidden" value={cityName} {...register('city')} />

                            <div className={cn.input}>
                                <FileUpload
                                    name="file"
                                    onFileChange={(files: File[]) => setValue('file', files)}
                                    fileTypes={fileTypes}
                                />
                            </div>

                            <div className={cn.input}>
                                <Checkbox
                                    size="small"
                                    isError={isErrorAccept}
                                    errorText={errorAcceptText}
                                    label='Даю согласие на <a href="https://www.pochtabank.ru/personal-data">обработку персональных данных</a>'
                                    {...register('accept')}
                                />
                            </div>

                            <Button
                                variant="btnBlue"
                                buttonType="submit"
                                type="button"
                                label="Отправить"
                                disable={isSubmitting}
                                customClass={cn.button}
                                animation={false}
                            />
                        </form>
                    </div>
                    <SubscriptionModal
                        isOpen={isModalOpen}
                        isFail={!isSubmitSuccessful}
                        handleClose={() => setIsModalOpen(false)}
                        title={modalText}
                    />
                </div>
            </div>
        </FormProvider>
    );
};

export default ResumeForm;
