import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import BuildingCalculatorContent from '@/components/blocks/calculators/layouts/BuildingCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import BuildingCalculatorStore from '@/domain/calculator/BuildingCalculatorStore';
import { TBuildingCalculator } from '@/types/calculator/building';

const BuildingCalculator: React.FC<TBuildingCalculator> = observer(
    ({ title, filterElements, secondFilterElements, terms, index, gtag, valueRate }) => {
        if (!filterElements || !secondFilterElements || !terms || !valueRate) return null;

        const BuildingCalculatorInstance = useMemo(
            () => new BuildingCalculatorStore(filterElements, secondFilterElements, terms, valueRate),
            []
        );

        return (
            <CalculatorStoreContext.Provider value={BuildingCalculatorInstance}>
                <BuildingCalculatorContent title={title} index={index} gtag={gtag} />
            </CalculatorStoreContext.Provider>
        );
    }
);

export default withBlockLinks(BuildingCalculator);
