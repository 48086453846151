import clsx from 'clsx';
import React from 'react';

import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import Down from './down';
import Right from './right';
import cn from './style.module.sass';

export interface IPhantomLinkIconProps {
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Стрелка низ/верх?
     */
    typeIcon?: 'down' | 'right';
    /**
     * Есть ли Padding?
     */
    noPadding?: boolean;
    /**
     * Есть ли рамка на мобайле?
     */
    border?: boolean;
    /**
     * Размеры кнопки
     */
    size?: 'large' | 'small';
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    /**
     * Цвет (Предполагается, что используется только в купе с Тиной)
     */
    color?: string;
    gtag?: GTagEvent;
}

export function PhantomLinkIcon({
    label,
    disable = false,
    border = false,
    customClass,
    size = 'large',
    noPadding,
    typeIcon,
    color,
    gtag,
    onClick,
}: IPhantomLinkIconProps) {
    return (
        <div
            role="presentation"
            style={{ color }}
            className={clsx(
                cn.linkIcon,
                disable === true && cn.disable,
                border === true && cn.border,
                typeIcon,
                size === 'small' && cn.small,
                noPadding === true && cn.noPadding,
                customClass
            )}
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
        >
            <span>{label}</span>
            {typeIcon === 'down' && (
                <i className={cn.linkIconDown}>
                    <Down />
                </i>
            )}
            {typeIcon === 'right' && (
                <i className={cn.linkIconRight}>
                    <Right />
                </i>
            )}
        </div>
    );
}
