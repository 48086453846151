import clsx from 'clsx';
import React, { FC, useMemo, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import MainSliderItem, { IMainSliderItem } from '@/components/blocks/MainSlider/item';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { ITag } from '@/types/tags';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IMainSlider {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'mainSlider';
    /**
     * Список элементов слайдера.
     */
    list: IMainSliderItem[];
    title?: string;
    tagList?: ITag[];
    disableSlider?: boolean;
    gtag?: GTagEvent;
}

interface IArrow {
    className?: string;
    style?: string;
    onClick?: () => void;
    gtag?: GTagEvent;
    listLength: number;
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
}

const PrevArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowLeft)}
        />
    );
};
const NextArrow: FC<IArrow> = ({ gtag, listLength, swiperRef }) => {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
};

const MainSlider: React.FC<IMainSlider> = ({ list, title, gtag, tagList, disableSlider }) => {
    const width = useResize();

    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const swiperRef = useRef(null);

    const items = useMemo(() => {
        if (!tagList?.length) return list?.map((item, index) => ({ ...item, pos: index }));
        return list?.reduce((acc: IMainSliderItem[], current, index) => {
            if (current.tags?.includes(selectedTag)) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);
    }, [tagList, list, selectedTag]);

    const getSlidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return list.length < 3 ? list.length : 3;
        if (width < MOBILE_SM_WIDTH) return 1.16;
        if (width < MOBILE_WIDTH) return 1.32;
        if (width < TABLET_SMALL_WIDTH) return 2.2;
        return list.length < 3 ? list.length : 3;
    }, [width]);

    const settings = {
        slidesPerView: getSlidesToShow,
        className: clsx(cn.carousel, 'carousel'),
        watchOverflow: true,
    };

    if (!list || list?.length <= 0) return null;

    if (disableSlider) {
        return (
            <div className={clsx(cn.current, 'section')}>
                <div className={cn.currentWrap}>
                    {title && (
                        <TextField
                            text={title}
                            name="title"
                            isHTML
                            htmlValue={title}
                            className={cn.title}
                            customTag="h2"
                        />
                    )}

                    {tagList?.length > 0 && (
                        <div className={cn.filter}>
                            <TagList tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                        </div>
                    )}
                    <div className={cn.currentGrid}>
                        <AnimatedComponent
                            className={clsx(cn.currentSlider, cn.currentNoSlider)}
                            classNameActive={cn.animationInit}
                        >
                            {items?.length > 0 &&
                                items?.map((item, index) => <MainSliderItem key={item?.pos || index} {...item} />)}
                        </AnimatedComponent>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={clsx(cn.current, 'section')}>
            <div className={cn.currentWrap}>
                {title && (
                    <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} customTag="h2" />
                )}

                {tagList?.length > 0 && (
                    <div className={cn.filter}>
                        <TagList tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                    </div>
                )}
                {items?.length > 0 && (
                    <div className={cn.currentGrid}>
                        <AnimatedComponent className={cn.currentSlider} classNameActive={cn.animationInit}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <CustomSwiper {...settings} ref={swiperRef}>
                                {items?.map(item => (
                                    <SwiperSlide key={item.pos}>
                                        <MainSliderItem {...item} />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                            <PrevArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                            <NextArrow listLength={items?.length} swiperRef={swiperRef} gtag={gtag} />
                        </AnimatedComponent>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(MainSlider);
