import React from 'react';

import { TinaReview } from '@/components/blocks/Reviews/index.tina';
import { mockReviewsBlockData } from '@/data/blocks/reviews';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const reviewsBlock = {
    Component: props => <TinaReview {...props} />,
    template: {
        label: 'Отзывы клиентов',
        defaultItem: () => mockReviewsBlockData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'disableSlider',
                component: 'toggle',
                label: 'Отключить слайдер',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                description: 'Список элементов слайдера',
                itemProps: (item: { name: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockReviewsBlockData.list[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'ФИО',
                    },
                    {
                        name: 'value',
                        component: 'select',
                        label: 'Оценка',
                        options: [
                            { value: 0, label: '0' },
                            { value: 0.5, label: '0.5' },
                            { value: 1, label: '1' },
                            { value: 1.5, label: '1.5' },
                            { value: 2, label: '2' },
                            { value: 2.5, label: '2.5' },
                            { value: 3, label: '3' },
                            { value: 3.5, label: '3.5' },
                            { value: 4, label: '4' },
                            { value: 4.5, label: '4.5' },
                            { value: 5, label: '5' },
                        ],
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'date',
                        label: 'Дата',
                        component: 'date',
                        dateFormat: 'DD.MM.YYYY',
                        timeFormat: false,
                    },
                    {
                        name: 'type',
                        component: 'select',
                        label: 'Тип продукта',
                        options: [
                            { value: 'debet-card', label: 'Дебетовая карта' },
                            { value: 'credit-card', label: 'Кредитная карта' },
                            { value: 'credit', label: 'Кредит наличными' },
                            { value: 'vklad', label: 'Вклады' },
                        ],
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
