import React from 'react';

import { TinaTiledShowcase } from '@/components/blocks/TiledShowcase/index.tina';
import { mockTiledShowcaseBlock } from '@/data/blocks/tiledShowcase';

export const tiledShowcaseBlock = {
    Component: props => <TinaTiledShowcase {...props} />,
    template: {
        label: 'Плиточная витрина',
        defaultItem: () => mockTiledShowcaseBlock,
        fields: [
            {
                name: 'titleBlock',
                component: 'text',
                label: 'Название элемента/Заголовок',
                required: true,
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { listName: string }, index: number) => ({
                    key: index,
                    label: item.listName,
                }),
                defaultItem: () => mockTiledShowcaseBlock.list[0],
                fields: [
                    {
                        name: 'listName',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'listLink',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
