import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';

import TermItem from '@/components/blocks/calculators/TermItem';
import CustomSlider from '@/components/CustomSlider';
import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { ITermItem } from '@/types/calculator/config';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    terms: ITermItem[];
    gtag?: GTagEvent;
    itemClassName?: string;
    activeClassName?: string;
    isRedesign?: boolean;
}

const TermsList: React.FC<IProps> = observer(({ terms, gtag, itemClassName, activeClassName, isRedesign }) => {
    const { activeTerm, setActiveTerm, value, isTermDisabled, setValueToTerm } = useContext(CalculatorStoreContext);

    const handleClick = () => {
        if (gtag?.action) event(gtag);
        setValueToTerm();
    };

    /* eslint-disable */
    useEffect(() => {
        if (isTermDisabled(activeTerm.value)) {
            const fiveYearTerm = terms.filter(filteredTerm => filteredTerm.value === 5)?.[0] || terms[0];
            setActiveTerm(fiveYearTerm);
        }
    }, [value]);
    /* eslint-enable */
    return (
        <div className={cn.sliderWrapper}>
            <CustomSlider className={cn.wrapper} isStartFromEnd>
                {terms?.map((term: ITermItem) => (
                    <TermItem
                        key={term.id}
                        term={term}
                        handleClick={handleClick}
                        itemClassName={itemClassName}
                        activeClassName={activeClassName}
                        isRedesign={isRedesign}
                    />
                ))}
            </CustomSlider>
        </div>
    );
});

export default TermsList;
