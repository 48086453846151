export const mockThreeInARowBlockData = {
    name: '',
    list: [
        {
            title: 'Пользователям Android',
            subtitle: 'Скачайте мобильное приложение Почта Банк Онлайн безопасно',
            icon: '/img-next/svg/threeInARow/android.svg',
            iconDesc: 'иконка android',
        },
        {
            title: 'Пользователям iOS',
            subtitle: 'Скачайте мобильное приложение Почта Банк Онлайн безопасно',
            icon: '/img-next/svg/threeInARow/apple.svg',
            iconDesc: 'иконка ios',
        },
        {
            title: 'Web-версия Почта Банк',
            subtitle: 'Добавьте иконку приложения на экран вашего телефона',
            icon: '/img-next/svg/threeInARow/web.svg',
        },
    ],
};

export const mockThreeInARowBlockDataTina = {
    section: null,
    name: '',
    list: [
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            icon: '',
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            icon: '',
        },
        {
            title: 'Заголовок',
            subtitle: 'Описание',
            icon: '',
        },
    ],
};
