import React from 'react';

import TextField from '@/components/TextField';
import { IFaqItem } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    list: IFaqItem[];
    activeItem: number;
}

const FAQThemeTopContent: React.FC<IProps> = ({ list, activeItem }) => (
    <div>
        <TextField
            text={list[activeItem]?.title}
            name={`list.[${activeItem}].title`}
            className={cn.title}
            isHTML
            htmlValue={list[activeItem]?.title}
        />
        {list[activeItem]?.subtitle && (
            <TextField
                text={list[activeItem]?.subtitle}
                name={`list.[${activeItem}].subtitle`}
                className={cn.subtitle}
                isHTML
                htmlValue={list[activeItem]?.subtitle}
            />
        )}
    </div>
);

export default FAQThemeTopContent;
