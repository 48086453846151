import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsSalaryCard } from '@/components/blocks/FeedbackFormSalaryCard/data';

export interface IFeedbackFormSalaryCard {
    _template: 'feedbackFormSalaryCard';
    title?: string;
    description?: string;
    successTitle?: string;
    buttonText?: string;
}

const FeedbackFormSalaryCard: React.FC<IFeedbackFormSalaryCard> = ({
    title,
    description,
    successTitle,
    _template = 'feedbackFormSalaryCard',
    buttonText,
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsSalaryCard}
        title={title}
        description={description}
        successTitle={successTitle}
        descFlag={false}
        buttonText={buttonText}
    />
);

export default FeedbackFormSalaryCard;
