export const mockUniversalDetailBlockDataTina = {
    element: {
        name: 'Заголовок',
        detailText: 'Текст новости',
        properties: [
            {
                code: 'NEWS_DATE',
                value: '2021-08-10 16:42:15',
            },
        ],
    },
    button: {
        text: 'Заголовок',
        link: '#',
    },
};
