import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import AdvantagesList from '@/components/blocks/advantages/Advantages-v2/list';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { IAdvantagesFilteredItems, IAdvantagesItem, IMainBtn } from '@/types/advantages/item';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

export interface IAdvantagesV2 {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'advantagesV2';
    /**
     * Карточки.
     */
    section?: number;
    list: IAdvantagesItem[];
    tagList?: ITag[];
    button?: IMainBtn;
    name?: string;
    text?: string;
}

const AdvantagesV2: React.FC<IAdvantagesV2> = ({ list, name, text, button }) => {
    const items = useMemo(
        () =>
            list?.reduce(
                (acc: IAdvantagesFilteredItems, item, index) => {
                    const mobileSizes = ['small', 'middle', 'big', 'full'];

                    if (mobileSizes.includes(item.size)) {
                        return {
                            desktop: [...acc.desktop, { ...item, pos: index }],
                            mobile: {
                                main: acc.mobile.main,
                                slider: [...acc.mobile.slider, { ...item, pos: index }],
                            },
                        };
                    }

                    return {
                        desktop: [...acc.desktop, { ...item, pos: index }],
                        mobile: {
                            main: [...acc.mobile.main, { ...item, pos: index }],
                            slider: acc.mobile.slider,
                        },
                    };
                },
                {
                    desktop: [],
                    mobile: {
                        main: [],
                        slider: [],
                    },
                }
            ),
        [list]
    );

    useEffect(() => {
        const element = document.querySelector('.layoutMiddle');
        if (element) {
            element.classList.add('disable-overflow');
        }
    }, []);

    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.advantages, 'section')}>
                <div className={cn.advantagesWrap}>
                    <AdvantagesList list={items} name={name} text={text} />

                    <div className={cn.advantagesWrapBtn}>
                        <ScrollLink
                            to={button?.link}
                            activeClass="menuLinkActive"
                            className={clsx('btnBlue', cn.buttonLinkWrap)}
                        >
                            <Button
                                variant="btnBlue"
                                type="button"
                                customClass={clsx(cn.button, 'productPanelButton')}
                                label={button?.name}
                                animation={false}
                            />
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(AdvantagesV2);
