import clsx from 'clsx';
import React from 'react';

import AnimatedCreditCard from '@/components/AnimatedCreditCard';
import AnimatedComponent from '@/components/Animation';
import TextField from '@/components/TextField';
import CustomTooltip from '@/components/Tooltip';
import Button from '@/components/UI/Button';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { ICardsItemWithActive } from '@/types/cards/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function CardsTooltip({ tooltipText }) {
    if (!tooltipText) return null;

    return (
        <CustomTooltip className={cn.tooltip} html={<div className={cn.tooltipText}>{tooltipText}</div>}>
            <TooltipIcon type="question" />
        </CustomTooltip>
    );
}

const CardsItem: React.FC<ICardsItemWithActive> = ({
    promo,
    name,
    desc,
    advantages,
    main = false,
    buttonDetail,
    buttonBg,
    cardLayers,
    archive,
    buttonArchive,
    pos,
}) => (
    <AnimatedComponent className={clsx(cn.item, main === true && cn.main)} classNameActive={cn.animationInit}>
        <div className={cn.itemGrid}>
            <div className={clsx(cn.itemCol, cn.itemColImg)}>
                {promo && (
                    <div className={cn.promo}>
                        <span dangerouslySetInnerHTML={{ __html: promo }} />
                    </div>
                )}
                <div className={cn.itemMobail}>
                    {name && (
                        <div className={cn.itemTitle}>
                            <a
                                href={buttonDetail?.link || buttonBg?.link || buttonArchive?.link}
                                className={cn.itemLink}
                                onClick={() => {
                                    if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                }}
                            >
                                <TextField
                                    text={name}
                                    name={`list.${pos || ''}.name`}
                                    customTag="span"
                                    isHTML
                                    htmlValue={name}
                                />
                            </a>
                        </div>
                    )}
                    {desc && (
                        <div className={cn.itemDesc}>
                            <TextField
                                text={desc}
                                name={`list.${pos || ''}.desc`}
                                customTag="span"
                                isHTML
                                htmlValue={desc}
                            />
                        </div>
                    )}
                </div>
                <div className={cn.itemImg}>
                    <a
                        href={buttonDetail?.link || buttonBg?.link || buttonArchive?.link}
                        className={cn.itemLink}
                        onClick={() => {
                            if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                        }}
                    >
                        <AnimatedCreditCard
                            alt={name}
                            bgLayer={cardLayers.bgLayer}
                            infoLayer={cardLayers.infoLayer}
                            imageLink={cardLayers.imageLink}
                        />
                    </a>
                </div>
            </div>
            <div className={clsx(cn.itemCol, cn.itemColContent)}>
                <div className={cn.itemContent}>
                    <div className={cn.itemContentTop}>
                        <div className={cn.itemDesktop}>
                            {name && (
                                <div className={cn.itemTitle}>
                                    <a
                                        href={buttonDetail?.link || buttonBg?.link || buttonArchive?.link}
                                        className={cn.itemLink}
                                        onClick={() => {
                                            if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                        }}
                                    >
                                        <TextField
                                            text={name}
                                            name={`list.${pos || ''}.name`}
                                            customTag="span"
                                            isHTML
                                            htmlValue={name}
                                        />
                                    </a>
                                </div>
                            )}
                            {desc && (
                                <div className={cn.itemDesc}>
                                    <TextField
                                        text={desc}
                                        name={`list.${pos || ''}.desc`}
                                        customTag="span"
                                        isHTML
                                        htmlValue={desc}
                                    />
                                </div>
                            )}
                        </div>
                        {advantages && advantages?.length > 0 && (
                            <div className={cn.info}>
                                {advantages?.slice(0, 3).map(({ title, subtitle, tooltipText }, index) => (
                                    <div className={cn.infoItem} key={index}>
                                        <TextField
                                            text={title}
                                            name={`list.${pos || ''}.advantages.${index}.title`}
                                            className={cn.infoName}
                                            isHTML
                                            htmlValue={title}
                                        />
                                        <div className={cn.infoDesc}>
                                            <TextField
                                                text={subtitle || ''}
                                                name={`list.${pos || ''}.advantages.${index}.subtitle`}
                                                customTag="span"
                                                isHTML
                                                htmlValue={subtitle !== undefined ? subtitle : ''}
                                            />
                                            <CardsTooltip tooltipText={tooltipText} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={cn.itemContentBot}>
                        {!archive && (
                            <div className={clsx(cn.itemBtn, cn.itemBtnActive)}>
                                {buttonDetail?.name && (
                                    <AnimatedComponent
                                        className={clsx(cn.itemBtnItem, cn.itemBtnItemLink)}
                                        classNameActive={cn.animationInit}
                                    >
                                        <a
                                            href={buttonDetail?.link}
                                            className={cn.itemLink}
                                            onClick={() => {
                                                if (buttonDetail?.gtag?.action) event(buttonDetail?.gtag);
                                            }}
                                        >
                                            {buttonDetail?.name}
                                        </a>
                                    </AnimatedComponent>
                                )}
                                {buttonBg?.name && (
                                    <div className={clsx(cn.itemBtnItem, cn.itemBtnItemBtn)}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={buttonBg?.link}
                                            label={buttonBg?.name}
                                            size="small"
                                            customClass="full"
                                            onClick={() => {
                                                if (buttonBg?.gtag?.action) event(buttonBg?.gtag);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {buttonArchive?.name && (
                            <div className={clsx(cn.itemBtn, cn.itemBtnArchive)}>
                                <div className={clsx(cn.itemBtnItem, cn.itemBtnItemBtn)}>
                                    <Button
                                        variant="btnBlueBorder"
                                        type="link"
                                        href={buttonArchive?.link}
                                        label={buttonArchive?.name}
                                        size="small"
                                        customClass="full"
                                        darkWhite
                                        onClick={() => {
                                            if (buttonArchive?.gtag?.action) event(buttonArchive?.gtag);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </AnimatedComponent>
);
export default CardsItem;
