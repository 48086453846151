import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IPeriod } from '@/types/periodInfo/item';
import { MOBILE_WIDTH } from '@/utils/constants';

import Decor from './decor';
import cn from './style.module.sass';

export interface IPeriodInfo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template?: 'periodInfo';
    /**
     * Заголовок
     */
    name?: string;
    descTop?: string;
    descBottom?: string;
    image?: string;
    list?: IPeriod[];
}

const PeriodInfo: React.FC<IPeriodInfo> = ({ name, descTop, descBottom, image, list }) => {
    const width = useResize();
    const isDesktop = width > MOBILE_WIDTH;
    const isMobile = width <= MOBILE_WIDTH;
    const isTablet = width > MOBILE_WIDTH;
    return (
        <div className={clsx(cn.period, 'section', !image && cn.noImage)}>
            <div className={cn.periodWrap}>
                <div className={cn.periodBg}>
                    <div className={cn.periodGrid}>
                        <div className={clsx(cn.periodItem, cn.periodItemContent)}>
                            <TextField text={name} name="name" className={cn.periodTitle} isHTML htmlValue={name} />
                            <TextField
                                text={descTop}
                                className={cn.periodDescTop}
                                name="descTop"
                                isHTML
                                htmlValue={descTop}
                            />
                            {list?.length > 0 && (
                                <div className={cn.info}>
                                    {isTablet && (
                                        <div className={clsx(cn.infoRow, cn.infoRowTop)}>
                                            {list?.slice(0, 3).map(({ data }, index) => (
                                                <div className={cn.infoItem} key={index}>
                                                    <TextField
                                                        className={cn.infoData}
                                                        text={data}
                                                        name={`list.[${index}].data`}
                                                    />
                                                    <Decor />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className={clsx(cn.infoRow, cn.infoRowBot)}>
                                        {list?.slice(0, 3).map(({ data, sum, desc }, index) => (
                                            <div className={cn.infoItem} key={index}>
                                                <div className={cn.infoItemWrap}>
                                                    <div className={cn.infoEl}>
                                                        <TextField
                                                            className={cn.infoSum}
                                                            text={sum}
                                                            name={`list.[${index}].sum`}
                                                        />
                                                        <TextField
                                                            className={cn.infoDesc}
                                                            text={desc}
                                                            name={`list.[${index}].desc`}
                                                            isHTML
                                                            htmlValue={desc}
                                                        />
                                                    </div>
                                                    {isMobile && (
                                                        <div className={cn.infoEl}>
                                                            <TextField
                                                                className={cn.infoData}
                                                                text={data}
                                                                name={`list.[${index}].data`}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <TextField
                                text={descBottom}
                                className={cn.periodDescBot}
                                name="descBottom"
                                isHTML
                                htmlValue={descBottom}
                            />
                        </div>
                        {isDesktop && (
                            <div className={clsx(cn.periodItem, cn.periodItemImage)}>
                                <div className={cn.periodImg}>
                                    {image && (
                                        <CustomImage src={image} alt={name} fill mockWidth={270} mockHeight={270} />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withBlockLinks(PeriodInfo);
