import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

import { NextArrow, PrevArrow } from '@/components/blocks/TinyGradientCategory/Arrows';
import Slide from '@/components/blocks/TinyGradientCategory/Slide';
import TinyGradientStory from '@/components/blocks/TinyGradientCategory/TinyGradientStory';
import { ITinyGradientCategory } from '@/components/blocks/TinyGradientCategory/types';
import CustomSwiper from '@/components/CustomSwiper';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

const TinyGradientCategory = ({ _template, title, subTitle, list = [] }: ITinyGradientCategory) => {
    const swiperRef = useRef(null);

    const [open, setOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(swiperRef?.current?.swiper?.activeIndex);
    const [watchedItems, setWatchedItems] = useState({});

    const updateWatchedItems = () => {
        const checkedItems = list.reduce((acc, item) => {
            const storedItem = localStorage.getItem(JSON.stringify(item.id));
            acc[item.id] = !!storedItem;
            return acc;
        }, {});
        setWatchedItems(checkedItems);
    };

    useEffect(() => {
        const handleStorageChange = () => {
            updateWatchedItems();
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const width = useResize();
    const isTablet = width <= TABLET_SMALL_WIDTH;

    const settings = {
        className: clsx(cn.slider, cn.start),
        spaceBetween: isTablet ? 4 : 12,
        slidesPerView: 'auto',
        loop: false,
        allowTouchMove: isTablet,
        onActiveIndexChange: (swiper: { activeIndex: number; realIndex: number }) => {
            setActiveSlideIndex(swiper.activeIndex);
        },
    };
    const addToLocalStorage = (index: number) => {
        const item = list[index];
        localStorage.setItem(JSON.stringify(item?.id), item?.previewTitle);
    };
    const handleOpen = (index: number) => {
        addToLocalStorage(index);
        setOpen(true);
        setActiveSlideIndex(index);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showArrows = !isTablet && list?.length >= 8;

    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>{title}</div>
            <div className={cn.subTitle}>{subTitle}</div>
            <div className={cn.list} key={isTablet ? 'tablet' : 'desc'}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CustomSwiper {...settings} ref={swiperRef}>
                    {list.map(({ previewTitle, backgroundImage, id, img }, index) => (
                        <SwiperSlide
                            key={id}
                            className={clsx(cn.slide, !previewTitle && cn.hidden, watchedItems[id] ? cn.isWatched : '')}
                            onClick={() => handleOpen(index)}
                        >
                            <Slide previewTitle={previewTitle} img={img} key={id} backgroundImage={backgroundImage} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
                {showArrows && <PrevArrow swiperRef={swiperRef} />}
                {showArrows && <NextArrow swiperRef={swiperRef} />}
            </div>
            <TinyGradientStory
                updateWatchedItems={updateWatchedItems}
                isOpen={open}
                list={list}
                activeSlideIndex={activeSlideIndex}
                setActiveSlideIndex={setActiveSlideIndex}
                handleClose={handleClose}
                addToLocalStorage={addToLocalStorage}
            />
        </div>
    );
};

export default TinyGradientCategory;
