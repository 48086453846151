import { IOptionsItem } from '@/data/blocks/feedbackFormData';

export const optionsSalaryCard: IOptionsItem[] = [
    {
        value: 'Зарплатная карта',
        label: 'Зарплатная карта',
        fields: [
            {
                type: 'input',
                inputType: 'text',
                label: 'ФИО',
                name: 'fio',
                validate: 'fio',
                required: true,
            },
            {
                type: 'input',
                inputType: 'tel',
                label: 'Телефон',
                mask: '+7 (999) 999-99-99',
                name: 'phone',
                validate: 'phone',
                required: true,
            },
        ],
    },
];
