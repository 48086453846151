import clsx from 'clsx';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Title from '@/components/UI/Title';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export interface IStepSelection {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'stepSelection';
    title?: string;
    desc?: string;
    image?: string;
    step?: string;
}

const StepSelection: React.FC<IStepSelection> = ({ title, desc, image, step }) => {
    const getCurrentStep = useMemo(() => {
        if (step === '1') return 'step-1';
        if (step === '2') return 'step-2';
        if (step === '3') return 'step-3';

        return 'step-1';
    }, [step]);

    if (!Feature.isFeatureEnabled('enableStepSelection')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    {title && <Title title={title} level={4} name="title" customClass="title" />}
                    <div className={cn.innerWrapper}>
                        {image && (
                            <div className={clsx(cn.imageWrap, cn.stepWrap)}>
                                <CustomImage src={image} alt="Почта Банк" mockWidth="48" mockHeight="48" fill />
                            </div>
                        )}
                        {!image && step && <div className={clsx(cn.step, `${getCurrentStep}`, cn.stepWrap)} />}
                        {desc && <TextField className={cn.desc} text={desc} name="desc" isHTML htmlValue={desc} />}
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default withBlockLinks(StepSelection);
