import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IProfitabilityCalculatorStore } from '@/domain/calculator/ProfitabilityCalculatorStore';
import { TProfitabilityCalculatorContent } from '@/types/calculator/profitability';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const ProfitabilityCalculatorContent: FC<TProfitabilityCalculatorContent> = observer(
    ({ title, isActiveRefill, index, gtag, button, enableCheckbox, termsTitleFlag }) => {
        const { range, termField, paymentValue, rate, toggleChecked, isChecked, initialized } =
            useContext<IProfitabilityCalculatorStore>(CalculatorStoreContext);

        if (!initialized) return null;

        const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        const formattedRate = `${rate}%`;

        return (
            <div className="section">
                <div className={cn.fullWrapper}>
                    <div className={cn.wrapper}>
                        <div className={cn.content}>
                            <div className={cn.left}>
                                <TextField
                                    text={title}
                                    customTag="h2"
                                    name="title"
                                    className={cn.title}
                                    isHTML
                                    htmlValue={title}
                                />
                                {!range.elementsSlider ? (
                                    <div className={cn.error}>Заполните списки элементов</div>
                                ) : (
                                    <RangeInput config={range} noMaxWidth />
                                )}
                                <Terms
                                    title="Срок"
                                    titleFlag={termsTitleFlag}
                                    field={termField}
                                    gtag={gtag?.gtagTerm}
                                    className={cn.termsNoMargin}
                                    noFixedWidth
                                />

                                {isActiveRefill && (
                                    <div className={cn.bottom}>
                                        <div className={cn.bottomItem}>
                                            <Checkbox
                                                label="С пополнением"
                                                checked
                                                isDisabled
                                                customClass={cn.checkbox}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={cn.right}>
                                <div className={cn.rightWrapper}>
                                    <div className={cn.wrapResult}>
                                        <div className={clsx(cn.wrapItem, cn['margin--md'])}>
                                            <div className={cn.wrapResultRow}>
                                                <span className={cn.resultText}>Процентная ставка</span>
                                                <span className={cn.resultNumber}>
                                                    {!range.elementsSlider ? 0 : formattedRate}
                                                </span>
                                            </div>
                                            <div className={cn.progress}>
                                                <span className={cn.progressSilver} />
                                                <span className={cn.progressRed} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn.wrapResult}>
                                        <div className={clsx(cn.wrapItem, cn['margin--sm'])}>
                                            <div className={cn.wrapResultRow}>
                                                <span className={cn.resultText}>Сумма вашего дохода</span>
                                                <span className={cn.resultNumber}>
                                                    {!range.elementsSlider ? 0 : `${formatValue(paymentValue)} ₽`}
                                                </span>
                                            </div>
                                            <div className={cn.progress}>
                                                <span className={cn.progressSilver} />
                                                <span className={cn.progressRed} />
                                            </div>
                                        </div>
                                    </div>
                                    {enableCheckbox && (
                                        <Checkbox
                                            label="Специальные условия"
                                            checked={isChecked}
                                            onChangeHandler={toggleChecked}
                                        />
                                    )}
                                    <div className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={button?.link || '#'}
                                            label={button?.label || 'Открыть счёт'}
                                            size="small"
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ProfitabilityCalculatorContent;
