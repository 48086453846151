import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import CustomImage from '@/components/CustomImage';
import { LinkIcon } from '@/components/UI/LinkIcon';
import { useResize } from '@/hooks/useResize';
import { IMainArticlesSliderItem } from '@/types/mainArticlesSlider';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const MainArticlesSliderItem: React.FC<IMainArticlesSliderItem> = observer(
    ({ linkTo, title, image, gtag, pos, text, textBtn, date, positionImg = 'left', isImageDisabled, background }) => {
        const width = useResize();
        const isMobile = width < MOBILE_WIDTH;

        const [subtitle, setSubtitle] = useState('');

        useEffect(() => {
            if (text.length > 200) {
                const desc = text.slice(0, 200).concat('...');
                setSubtitle(desc);
            } else {
                setSubtitle(text);
            }
        }, [text]);

        const onClick = () => {
            if (gtag?.action) event(gtag);
        };

        return (
            <div className={clsx(cn.currentItem, 'currentItem')}>
                <div
                    className={clsx(cn.currentItemWrap, cn.currentItemWrapLink)}
                    style={{
                        background: background || null,
                    }}
                >
                    {image && !isImageDisabled && (
                        <a
                            href={linkTo}
                            className={clsx(cn.currentImage, isMobile && cn.currentImageMobile)}
                            onClick={onClick}
                        >
                            <CustomImage src={image} alt={title} mockWidth="350" mockHeight="200" fill />
                        </a>
                    )}

                    <div className={cn.currentContent}>
                        <div className={cn.currentTop}>
                            <div className={cn.currentDate}>{date}</div>
                            <a href={linkTo} className={cn.currentTitle}>
                                <span dangerouslySetInnerHTML={{ __html: title }} />
                            </a>
                            <div className={cn.currentSubtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                        </div>
                        <div className={cn.currentBot}>
                            <LinkIcon
                                href={linkTo}
                                noPadding
                                typeIcon="right"
                                label={textBtn || 'Читать'}
                                gtag={gtag}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default MainArticlesSliderItem;
