import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import SecondaryPreferentialCalculatorContent from '@/components/blocks/calculators/layouts/SecondaryPreferentialCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import SecondaryPreferentialCalculatorStore from '@/domain/calculator/SecondaryPreferentialCalculatorStore';
import { SecondaryPreferentialCalculatorProps } from '@/types/calculator/secondaryPreferentialCalculator';

const SecondaryPreferentialCalculator: React.FC<SecondaryPreferentialCalculatorProps> = observer(
    ({ title, version = 'v2', filterElements, terms, index, gtag, button, infoTitle, boundTerms }) => {
        if (!filterElements || !terms || !boundTerms) return null;

        useEffect(() => {
            SecondaryPreferentialCalculatorStore.init(filterElements, terms, boundTerms, version);
        }, []);

        return (
            <CalculatorStoreContext.Provider value={SecondaryPreferentialCalculatorStore}>
                {SecondaryPreferentialCalculatorStore.initialized && (
                    <SecondaryPreferentialCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        button={button}
                        infoTitle={infoTitle}
                    />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default withBlockLinks(SecondaryPreferentialCalculator);
