import React from 'react';

import { TinaFeedbackFormVThree } from '@/components/blocks/FeedbackFormVThree/index.tina';
import { mockFeedbackFormBlockDataTina } from '@/data/blocks/feedbackForm';
import HtmlEditor from '@/tina/plugins/htmlEditor';

export const feedbackFormVThreeBlock = {
    Component: props => <TinaFeedbackFormVThree {...props} />,
    template: {
        label: 'Форма обратной связи по договорам и счетам',
        defaultItem: () => mockFeedbackFormBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditor,
                label: 'Заголовок',
            },
            {
                name: 'successTitle',
                component: 'text',
                label: 'Успешный заголовок',
            },
            {
                name: 'description',
                component: HtmlEditor,
                label: 'Описание',
            },
            {
                component: 'group-list',
                name: 'themeDescList',
                label: 'Описания тем',
                itemProps: (item: { desc: string }, index: number) => ({
                    key: index,
                    label: item.desc,
                    title: item.desc,
                }),
                defaultItem: () => mockFeedbackFormBlockDataTina.themeDescList[0],
                fields: [
                    {
                        name: 'desc',
                        component: HtmlEditor,
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
