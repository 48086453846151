import React from 'react';

import { TinaPopularExchangeRates } from '@/components/blocks/PopularExchangeRates/PopularExchangeRates/index.tina';

export const popularExchangeRatesBlock = {
    Component: props => <TinaPopularExchangeRates {...props} />,
    template: {
        label: 'Популярные курсы валют',
        defaultItem: () => ({}),
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
