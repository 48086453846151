import React from 'react';

import ProfitabilityCalculatorWrapper from '@/components/blocks/calculators/layouts/ProfitabilityCalculator/wrapper';
import cn from '@/components/UI/ErrorFallback/style.module.sass';
import { useAppStore } from '@/context/AppStoreContext';
import Feature from '@/domain/feature/Feature';
import { TProfitabilityCalculator } from '@/types/calculator/profitability';

function ProfitabilityCalculator({
    title,
    isActiveRefill = false,
    data,
    termsTitleFlag = true,
    index,
    gtag,
    button,
    enableCheckbox,
    _template,
    filterElements,
    terms,
    termsActiveIndex,
}: TProfitabilityCalculator) {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    if (Feature.isFeatureEnabled('enableProductParameterCalc')) {
        if (isTinaView)
            return (
                <div className="section">
                    <div className={cn.fullWrapper}>
                        <div className={cn.mainTitle}>
                            Предпросмотр калькулятора с продуктовым параметром невозможен
                        </div>
                    </div>
                </div>
            );

        if (!data)
            return (
                <div className="section">
                    <div className={cn.fullWrapper}>
                        <div className={cn.mainTitle}>Недостаточно данных для калькулятора</div>
                    </div>
                </div>
            );
    }

    return (
        <ProfitabilityCalculatorWrapper
            title={title}
            data={data}
            termsTitleFlag={termsTitleFlag}
            index={index}
            gtag={gtag}
            button={button}
            enableCheckbox={enableCheckbox}
            _template={_template}
            isActiveRefill={isActiveRefill}
            filterElements={filterElements}
            terms={terms}
            termsActiveIndex={termsActiveIndex}
        />
    );
}

export default ProfitabilityCalculator;
