import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsPretensionSecond } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormPretension {
    _template: 'feedbackFormPretensionSecond';
    title?: string;
    successTitle?: string;
    thanksSubTitle?: string;
}

const FeedbackFormPretensionSecond: React.FC<IFeedbackFormPretension> = ({
    title,
    successTitle,
    thanksSubTitle,
    _template = 'feedbackFormPretensionSecond',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsPretensionSecond}
        title={title}
        successTitle={successTitle}
        thanksSubTitle={thanksSubTitle}
        showLabel={false}
    />
);

export default FeedbackFormPretensionSecond;
