import React from 'react';

import { TinaInstruction } from '@/components/blocks/Instruction/index.tina';
import { mockInstructionBlockTinaData } from '@/data/blocks/instruction';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const instructionBlock = {
    Component: props => <TinaInstruction {...props} />,
    template: {
        label: 'Пошаговая инструкция',
        defaultItem: () => mockInstructionBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'name',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список блоков инструкции. (Будут видны первых 3 блока)',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockInstructionBlockTinaData.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                        required: true,
                    },
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'btn',
                        label: 'Кнопка',
                        component: 'group',
                        fields: [
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'link',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            { ...gtagSingleItem },
                        ],
                    },
                ],
            },
            {
                name: 'qr',
                label: 'QR код',
                component: 'group',
                description: 'Появляется при добавлении обеих картинок',
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageDarkTheme',
                        component: 'image',
                        label: 'Изображение. Темная тема.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'alt',
                        component: 'text',
                        label: 'Альтернативный текст',
                    },
                ],
            },
            {
                name: 'descText',
                component: HtmlEditorFeatured,
                label: 'Нижний текст',
            },
            {
                name: 'button',
                label: 'Кнопка',
                component: 'group',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
        ],
    },
};
