import clsx from 'clsx';
import React, { useState } from 'react';

import ActionPensionsModal from '@/components/blocks/ActionPensions/modal';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useFixHtml } from '@/hooks/useFixHtml';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface ICreditGiftBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'creditGiftBlock';
    /**
     * Список категорий
     */
    image: string;
    title: string;
    promoData: {
        promoPeriodTitle?: string;
        promoPeriod?: string;
        promoDateTitle?: string;
        promoDate?: string;
        promoQuantityTitle?: string;
        promoQuantity?: string;
    };
    desc?: string;
    button: {
        link: string;
        linkName: string;
        gtag?: GTagEvent;
    };
    link: {
        linkName: string;
        gtag?: GTagEvent;
    };
    modal: { modalTitle: string; modalDescription: string };
}

const CreditGiftBlock: React.FC<ICreditGiftBlock> = ({ title, desc, button, link, promoData, image, modal }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();
    const { modalTitle, modalDescription } = modal;
    const { promoPeriod, promoQuantity, promoDate, promoQuantityTitle, promoPeriodTitle, promoDateTitle } = promoData;
    const show = () => {
        fix();
        setShowModal(true);
    };

    const hide = () => {
        unfix();
        setShowModal(false);
    };
    return (
        <AnimatedComponent className={clsx(cn.creditGiftBlock, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.creditGiftBlockWrap}>
                <div className={cn.creditGiftBlockGrid}>
                    <div className={clsx(cn.creditGiftBlockItem, cn.creditGiftBlockItemContent)}>
                        <div className={cn.creditGiftBlockContent}>
                            <div>
                                {title && (
                                    <TextField
                                        text={title}
                                        className={cn.creditGiftBlockTitle}
                                        name="title"
                                        htmlValue={title}
                                        isHTML
                                    />
                                )}
                                {desc && (
                                    <TextField
                                        text={desc}
                                        isHTML
                                        htmlValue={desc}
                                        name="desc"
                                        className={cn.creditGiftBlockDesc}
                                    />
                                )}
                                <div className={cn.promoDetails}>
                                    {promoPeriod && (
                                        <div className={cn.promoDetail}>
                                            <TextField
                                                text={promoPeriodTitle}
                                                isHTML
                                                htmlValue={promoPeriodTitle}
                                                name="promoPeriodTitle"
                                                className={cn.promoDetailTitle}
                                            />
                                            <TextField
                                                text={promoPeriod}
                                                isHTML
                                                htmlValue={promoPeriod}
                                                name="promoPeriod"
                                                className={cn.promoDetailDesc}
                                            />
                                        </div>
                                    )}
                                    {promoDate && (
                                        <div className={cn.promoDetail}>
                                            <TextField
                                                text={promoDateTitle}
                                                isHTML
                                                htmlValue={promoDateTitle}
                                                name="promoDateTitle"
                                                className={cn.promoDetailTitle}
                                            />
                                            <TextField
                                                text={promoDate}
                                                isHTML
                                                htmlValue={promoDate}
                                                name="promoDate"
                                                className={cn.promoDetailDesc}
                                            />
                                        </div>
                                    )}
                                    {promoQuantity && (
                                        <div className={cn.promoDetail}>
                                            <TextField
                                                text={promoQuantityTitle}
                                                isHTML
                                                htmlValue={promoQuantityTitle}
                                                name="promoQuantityTitle"
                                                className={cn.promoDetailTitle}
                                            />
                                            <TextField
                                                text={promoQuantity}
                                                isHTML
                                                htmlValue={promoQuantity}
                                                name="promoQuantity"
                                                className={cn.promoDetailDesc}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {(button?.link || link) && (
                                <div className={clsx(cn.creditGiftBlockBot)}>
                                    {button?.link && (
                                        <div className={clsx(cn.button, cn.creditGiftBlockBotItem)}>
                                            <Button
                                                variant="btnBlue"
                                                type="link"
                                                href={button?.link}
                                                label={button?.linkName}
                                                size="full"
                                                onClick={() => {
                                                    if (button?.gtag?.action) event(button?.gtag);
                                                }}
                                                customClass={cn.buttonHeight}
                                            />
                                        </div>
                                    )}
                                    {link && (
                                        <button
                                            className={clsx(cn.popupLink, cn.creditGiftBlockBotItem)}
                                            onClick={show}
                                            type="button"
                                        >
                                            {link?.linkName}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {image && (
                        <div className={clsx(cn.creditGiftBlockItem, cn.creditGiftBlockItemImg)}>
                            <div className={clsx(cn.creditGiftBlockImage)}>
                                <div className={clsx(cn.creditGiftBlockImageContainer, cn.positionMobileImg)}>
                                    <CustomImage src={image} alt={title} width={340} height={288} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ActionPensionsModal
                title={modalTitle}
                description={modalDescription}
                handleClose={hide}
                isOpen={showModal}
            />
        </AnimatedComponent>
    );
};

export default withBlockLinks(CreditGiftBlock);
