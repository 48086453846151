import React from 'react';

import { TinaFormBlock } from '@/components/blocks/forms/FormBlock/index.tina';
import { mockFormBlockDataTina } from '@/data/blocks/formBlock';
import { formsConfig, TFormTemplates } from '@/tina/config/forms.config';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';

export const formBlockBlock = {
    Component: props => <TinaFormBlock {...props} />,
    template: {
        label: 'Блок с выбором формы',
        defaultItem: () => mockFormBlockDataTina,
        fields: [
            {
                name: 'form',
                label: 'Форма',
                component: 'group',
                fields: [
                    {
                        component: 'select',
                        name: '_template',
                        label: 'Тип формы',
                        options: [
                            {
                                value: '',
                                label: 'Без формы',
                            },
                            ...Object.keys(formsConfig).map((item: TFormTemplates) => ({
                                value: item,
                                label: formsConfig[item].title,
                            })),
                        ],
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'title',
                        label: 'Заголовок',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'description',
                        label: 'Описание',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'accentText',
                        label: 'Доп. Описание',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'successTitle',
                        label: 'Заголовок при успешной отправке',
                    },
                    {
                        ...gtagSingleItemCustom('gtagClick', 'Цель на успешную отправку (MirSupremeForm)'),
                    },
                ],
            },
        ],
    },
};
