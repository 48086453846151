import React from 'react';

import { TinaUniversalDetail } from '@/components/blocks/universalDetail/UniversalDetail/index.tina';
import { mockUniversalDetailBlockDataTina } from '@/data/blocks/universalDetail';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const universalDetailBlock = {
    Component: props => <TinaUniversalDetail {...props} />,
    template: {
        label: 'Универсальный блок: Детальная',
        defaultItem: () => mockUniversalDetailBlockDataTina,
        fields: [
            {
                label: 'Элемент',
                name: 'element',
                component: 'group',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'previewPictureSrc',
                        component: 'image',
                        label: 'Картинка для превью',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'pictureSrc',
                        component: 'image',
                        label: 'Картинка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'detailText',
                        component: HtmlEditorFeatured,
                        label: 'Контент',
                    },
                ],
            },
            {
                label: 'Кнопка',
                name: 'button',
                component: 'group',
                fields: [
                    {
                        name: 'text',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
