import clsx from 'clsx';
import $ from 'jquery';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import Script from 'next/script';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import Terms from '@/components/blocks/calculators/Terms';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IStudyCalculatorStore } from '@/domain/calculator/StudyCalculatorStore';
import { ITermItem } from '@/types/calculator/config';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import BottomResults from './bottom-results';
import cn from './style.module.sass';
import { createSVGPath } from './svg.path';
import TopResults from './top-results';

export interface ICalculator {
    title?: string;
    terms?: ITermItem[];
    gtag: GTagEvent;
    button?: {
        label: string;
        link: string;
    };
}

/* eslint-disable */

const StudyCalculatorContent: FC<ICalculator> = observer(({ title, gtag, button }) => {
    const [svgLoaded, setSvgLoaded] = useState(false);
    const [uiSliderLoaded, setUiSliderLoaded] = useState(false);
    const [chartLoaded, setChartLoaded] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const { range, term, chartHandler } = useContext<IStudyCalculatorStore>(CalculatorStoreContext);

    const elementRef = useRef(null);
    const calculatorRef = useRef(null);

    useEffect(() => {
        window.$ = $;

        window.addEventListener('chartChanged', chartHandler, false);

        return () => {
            window.removeEventListener('chartChanged', chartHandler, false);
        };
    }, []);

    useEffect(() => {
        if (elementRef.current && svgLoaded && scriptLoaded && uiSliderLoaded && chartLoaded) {
            createSVGPath();
            try {
                window.$(elementRef.current).educationLoanCalculator();
                calculatorRef.current = window.$(elementRef.current).data('educationLoanCalculator');
            } catch (e) {
                console.log(e);
            }
        }
    }, [svgLoaded, scriptLoaded, uiSliderLoaded, chartLoaded, elementRef]);

    useEffect(() => {
        if (calculatorRef.current) {
            calculatorRef.current.chart.setMaxValue(term.value * 12 + 51);
            calculatorRef.current.chart.setValue(6);
        }
    }, [term.value, calculatorRef]);

    // КОСТЫЛЬ С ПУТЯМИ
    //@todo СМЕНИТЬ ПУТИ НА ФАЙЛЫ ПОСЛЕ ТОГО КАК ОТКЛЮЧАТ ПРОКСИРОВАНИЕ. НА ДАННОМ ЭТАПЕ ТОЛЬКО ЭТА ПАПКА ПРОКСИРУЕТСЯ

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={clsx(cn.wrapper)} ref={elementRef}>
                    <Head>
                        <link rel="stylesheet" href="/img-next/education/style.css" />
                        <link rel="stylesheet" href="/img-next/education/nouislider.css" />
                        <link rel="stylesheet" href="/img-next/education/education-loan-calculator-chart.css" />
                    </Head>
                    <Script src="/img-next/education/nouislider.js" onLoad={() => setUiSliderLoaded(true)} />
                    <Script src="/img-next/education/svg.js" onLoad={() => setSvgLoaded(true)} />
                    <Script
                        src="/img-next/education/education-loan-calculator-chart.js"
                        onLoad={() => setChartLoaded(true)}
                    />
                    <Script
                        src="/img-next/education/education-loan-calculator.js"
                        onLoad={() => setScriptLoaded(true)}
                    />
                    <div className={cn.content}>
                        <div className={cn.left}>
                            {title && (
                                <TextField
                                    text={title}
                                    customTag="h2"
                                    name="title"
                                    className={cn.title}
                                    isHTML
                                    htmlValue={title}
                                />
                            )}
                            <div className={cn.rangeWrap}>
                                <div className={cn.range}>
                                    <RangeInput config={range} noMaxWidth />
                                </div>
                                <Terms field={term} title="Количество лет обучения" gtag={gtag} />
                            </div>
                            <div className={cn.info}>
                                Максимальная сумма кредита 2 000 000 рублей. Если ваше обучение стоит больше, вы можете
                                воспользоваться кредитом для частичной оплаты обучения.
                            </div>
                            <div className="knowledge-calculator">
                                <div className="content-calculator">
                                    <div className="chart-case">
                                        <div className="chart-content">
                                            <div className="datum-line">
                                                <div className="y-axis">₽</div>
                                                <div className="x-axis">Месяцы</div>
                                                <div className="chart js-education-loan-calculator-chart" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="legend">
                                        <span className="legend-label">
                                            <span className="legend-value color-1" />
                                            Период обучения
                                        </span>
                                        <span className="legend-label">
                                            <span className="legend-value color-2" />
                                            Отсрочка на поиск работы
                                        </span>
                                        <span className="legend-label">
                                            <span className="legend-value color-3" />
                                            Период выплаты основного долга
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <TopResults />
                                <div className={cn.button}>
                                    <Button
                                        variant={'btnBlue'}
                                        type="link"
                                        href={button?.link || '#'}
                                        label={button?.label || 'Оформить карту'}
                                        size="small"
                                        customClass={cn.button}
                                        onClick={() => event(gtag)}
                                        animation={false}
                                    />
                                </div>
                                <BottomResults />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default StudyCalculatorContent;
