export const mockCashBackPercentageBlockData = {
    title: 'Заголовок',
    limit: 8,
    type: 'col-4',
    list: [
        {
            title: 'AliExpress',
            name: 'наименование',
            link: '#',
            image: '/img-next/png/partners/1.png',
            imageDarkTheme: '/img-next/png/partners/dark/1.png',
            cashbackSize: '5',
        },
    ],
};
