import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import TextField from '@/components/TextField';
import Checkbox from '@/components/UI/Checkbox';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IGuaranteeCalculatorStore } from '@/domain/calculator/GuaranteeCalculatorStore';
import { useTabletMobileWidth } from '@/hooks/useMedia';
import { TGuaranteeCalculatorContent } from '@/types/calculator/guarantee';
import { formatCalcValue } from '@/utils/formatCalcValue';

import cn from './style.module.sass';

const GuaranteeCalculatorContent: FC<TGuaranteeCalculatorContent> = observer(({ title }) => {
    const {
        range,
        toggleChecked,
        isChecked,
        contributionSum,
        fixedIncome,
        taxDeduction,
        contributionIncomeSum,
        initialized,
    } = useContext<IGuaranteeCalculatorStore>(CalculatorStoreContext);
    if (!initialized) return null;

    const isMobile = useTabletMobileWidth();

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.wrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <div>
                                <TextField
                                    text={title}
                                    customTag="h2"
                                    name="title"
                                    className={cn.title}
                                    isHTML
                                    htmlValue={title}
                                />
                                <RangeInput config={range} noMaxWidth />
                                <Checkbox
                                    label="Имею право на&nbsp;получение налогового вычета"
                                    checked={isChecked}
                                    onChangeHandler={toggleChecked}
                                    subtext="Получение налогового вычета возможно при официальном трудоустройстве и&nbsp;при условии, если договор оформлен на&nbsp;вас или членов семьи (супруги, родители, дети)"
                                />
                            </div>
                            <div className={clsx(cn.disclaimer, isMobile && cn.hide)}>
                                Предварительный расчет с&nbsp;учетом подключения дополнительных услуг. Не&nbsp;является
                                офертой.
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem)}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Сумма взносов</span>
                                            <span className={cn.resultNumber}>{`${formatCalcValue(
                                                contributionSum
                                            )} ₽`}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem)}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Фиксированный доход</span>
                                            <span className={cn.resultNumber}>{`${formatCalcValue(
                                                fixedIncome
                                            )} ₽`}</span>
                                        </div>
                                        <div className={cn.progress}>
                                            <span className={cn.progressSilver} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cn.wrapResult}>
                                    <div className={clsx(cn.wrapItem)}>
                                        <div className={cn.wrapResultRow}>
                                            <span className={cn.resultText}>Ваш капитал через 5 лет</span>
                                            <span className={cn.resultNumber}>
                                                {`${formatCalcValue(contributionIncomeSum)} ₽`}
                                            </span>
                                        </div>
                                        {isChecked ? (
                                            <div className={cn.progress}>
                                                <span className={cn.progressSilver} />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {isChecked && (
                                    <div className={cn.wrapResult}>
                                        <div className={clsx(cn.wrapItem)}>
                                            <div className={cn.wrapResultRow}>
                                                <span className={cn.resultText}>Налоговый вычет</span>
                                                <span className={cn.resultNumber}>
                                                    {`до ${formatCalcValue(taxDeduction)} ₽`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={clsx(cn.disclaimer, cn.hide, isMobile && cn.show)}>
                                Предварительный расчет с&nbsp;учетом подключения дополнительных услуг. Не&nbsp;является
                                офертой.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default GuaranteeCalculatorContent;
