import React from 'react';

import { TinaMirSupremeCard } from '@/components/blocks/frames/MirSupremeCard/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const mirSupremeCardBlock = {
    Component: (props: IBlockProps) => <TinaMirSupremeCard {...props} />,
    template: {
        label: 'Заявка на дебетовую карту MirSupreme',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
