import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import Title from '@/components/UI/Title';

import cn from './style.module.sass';

export interface IErrors {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'errors';
    title: string;
    desc: string;
    customClass?: string;
    list: ILinkErrors[];
    img?: string;
}

interface ILinkErrors {
    link: string;
    name: string;
}

const Errors: React.FC<IErrors> = ({ title, desc, list, img, customClass }) => {
    if (list?.length <= 0) {
        return null;
    }

    return (
        <AnimatedComponent className={clsx(cn.errors, customClass)} classNameActive={cn.animationInit}>
            <div className={clsx(cn.errorsWrap)}>
                <div className={cn.errorsGrid}>
                    <div className={clsx(cn.errorsItem, cn.errorsItemContent)}>
                        <div className={cn.errorsContent}>
                            <Title level={1} title={title} customClass={cn.errorsTitle} />
                            <AnimatedComponent className={cn.errorsDesc} classNameActive={cn.animationInit}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: desc,
                                    }}
                                />
                            </AnimatedComponent>
                            <div className={cn.errorsList}>
                                {list.map(({ name, link }) => (
                                    <AnimatedComponent
                                        className={cn.errorsListItem}
                                        key={name}
                                        classNameActive={cn.animationInit}
                                    >
                                        <a href={link} className={cn.errorsListLink}>
                                            {name}
                                        </a>
                                    </AnimatedComponent>
                                ))}
                            </div>
                        </div>
                    </div>
                    {img && (
                        <div className={clsx(cn.errorsItem, cn.errorsItemImg)}>
                            <AnimatedComponent className={cn.errorsImage} classNameActive={cn.animationInit}>
                                <CustomImage src={img} alt={title} fill mockWidth={500} mockHeight={500} />
                            </AnimatedComponent>
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};
export default Errors;
