export const mockSecondaryCashCreditCalculatorData = {
    title: 'Рассчитайте свой платеж',
    tabTitle: 'Наличными',
    index: 8,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
    valueRate: {
        highRate: 3,
        lowRate: 2.5,
    },
    params: {
        terms: [
            {
                id: 1,
                label: '3 года',
                value: 3,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 20000,
                        max: 270000,
                        balancePercent: 38.1853177079737,
                    },
                    {
                        id: 2,
                        percent: 9.4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 38.185317708,
                    },
                    {
                        id: 3,
                        percent: 5.9,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 37.5048604181,
                    },
                ],
            },
            {
                id: 2,
                label: '4 года',
                value: 4,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 20000,
                        max: 270000,
                        balancePercent: 29.9469781389227,
                    },
                    {
                        id: 2,
                        percent: 9.4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 29.9469781389,
                    },
                    {
                        id: 3,
                        percent: 5.9,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 29.1608666805,
                    },
                ],
            },
            {
                id: 3,
                label: '5 лет',
                value: 5,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 9.4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 24.6773644879,
                    },
                    {
                        id: 3,
                        percent: 5.9,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 23.9416492058,
                    },
                ],
            },
            {
                id: 4,
                label: '6 лет',
                value: 6,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 9.4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 21.0729371986,
                    },
                    {
                        id: 3,
                        percent: 5.9,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 20.4700559128,
                    },
                ],
            },
            {
                id: 5,
                label: '7 лет',
                value: 7,
                ranges: [
                    {
                        id: 1,
                        percent: 10.9,
                        min: 20000,
                        max: 270000,
                        balancePercent: 24.6773644878684,
                    },
                    {
                        id: 2,
                        percent: 9.4,
                        min: 270001,
                        max: 499999,
                        balancePercent: 18.5045230588,
                    },
                    {
                        id: 3,
                        percent: 5.9,
                        min: 500000,
                        max: 6000000,
                        balancePercent: 17.9971158943,
                    },
                ],
            },
        ],
        filterElements: [
            {
                value: 20000,
                step: 5000,
                percent: 0,
                label: '20 тыс.',
                id: 1,
            },
            {
                value: 300000,
                step: 10000,
                percent: 20,
                label: '300 тыс.',
                id: 2,
            },
            {
                value: 500000,
                step: 50000,
                percent: 40,
                label: '500 тыс.',
                id: 3,
            },
            {
                value: 1000000,
                step: 100000,
                percent: 60,
                label: '1 млн.',
                id: 4,
            },
            {
                value: 3000000,
                step: 100000,
                percent: 80,
                label: '3 млн.',
                id: 5,
            },
            {
                value: 6000000,
                step: 100000,
                percent: 100,
                label: '6 млн.',
                id: 6,
            },
        ],
        minValue: 20000,
        maxValue: 6000000,
    },
    banner: {
        ranges: [
            {
                title: '3%',
                subtitle: 'ежемесячный платеж',
                min: 0,
                max: 300000,
            },
            {
                title: '2.5%',
                subtitle: 'ежемесячный платеж',
                min: 300001,
                max: 500000,
            },
            {
                title: '',
                subtitle: 'Хочу получить больше 500 000 ₽',
                min: 500001,
                max: 6000000,
            },
        ],
        isActive: true,
    },
    infoTitle: {
        ranges: [
            {
                text: 'Предварительный расчёт по кредитной карте «Деньги и Всё!». Не является публичной офертой.',
                min: 0,
                max: 499999,
            },
            {
                text: 'Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке &nbsp;5.9% годовых с&nbsp;услугой «Гарантированная ставка». Не&nbsp;является публичной офертой.',
                tooltipText:
                    'Погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке 5.9% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита',
                min: 500000,
                max: 6000000,
            },
        ],
        isActive: true,
    },
    buttonShared: {
        isActive: false,
    },
};
