import clsx from 'clsx';
import React, { useMemo } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import HelpWrapperLinkBlock from '@/components/UI/HelpWrapperLinkBlock';
import Feature from '@/domain/feature/Feature';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IGuitarBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'guitarBanner';
    type: string;
    title?: string;
    desktopImage?: string;
    mobileImage?: string;
    link?: string;
}

function GuitarBanner({ type = 'anchor', title, desktopImage, mobileImage, link }: IGuitarBanner) {
    const width = useResize();

    const getCurrentImage = useMemo(() => {
        if (width < MOBILE_WIDTH) return mobileImage ?? desktopImage;
        return desktopImage;
    }, [width, desktopImage, mobileImage]);

    const scrollOffset = width < TABLET_SMALL_WIDTH ? 60 : 90;

    if (!Feature.isFeatureEnabled('enableGuitarBanner')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    <HelpWrapperLinkBlock type={type} link={link} scrollOffset={scrollOffset}>
                        <div className={cn.innerWrapper}>
                            <div className={cn.textWrapper}>
                                {title && (
                                    <TextField
                                        className={cn.title}
                                        text={title}
                                        name="title"
                                        isHTML
                                        htmlValue={title}
                                    />
                                )}
                            </div>
                            {getCurrentImage && (
                                <div className={clsx(cn.imageWrapper)}>
                                    <CustomImage src={getCurrentImage} alt="Почта Банк" fill />
                                </div>
                            )}
                        </div>
                    </HelpWrapperLinkBlock>
                </AnimatedComponent>
            </div>
        </div>
    );
}

export default withBlockLinks(GuitarBanner);
