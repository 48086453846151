import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import CustomSlider from '@/components/CustomSlider';
import { ITag } from '@/types/tags';

import cn from './style.module.sass';

interface IProps {
    tagList: ITag[];
    onClick: (xmlId: string) => void;
    activeTag: string;
    limit?: number;
    className?: string;
    tagClassName?: string;
    disabled?: boolean;
    valueFlag?: boolean;
    withoutPaddings?: boolean;
}

const TagList: React.FC<IProps> = observer(
    ({ tagList, onClick, activeTag, limit, className, tagClassName, valueFlag, disabled, withoutPaddings = false }) => {
        const tags = useMemo(() => {
            if (limit) return tagList?.slice(0, limit);

            return tagList;
        }, [tagList, limit]);

        if (!tagList || tagList?.length <= 0) return null;

        return (
            <CustomSlider className={clsx(cn.tagsGrid, className, withoutPaddings && cn.withoutPaddings)}>
                {tags?.map(({ xmlId, value }, index) => (
                    <button
                        type="button"
                        onClick={() => onClick(valueFlag ? value : xmlId)}
                        className={clsx(
                            cn.tagsItem,
                            activeTag === (valueFlag ? value : xmlId) && cn.active,
                            tagClassName
                        )}
                        disabled={disabled}
                        key={index}
                    >
                        <div className={cn.tagsLink}>{value}</div>
                    </button>
                ))}
            </CustomSlider>
        );
    }
);

export default TagList;
