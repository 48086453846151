import { IDictionary } from '@/types/dictionary';

export const mockDictionaryBlockData: IDictionary = {
    sections: [
        {
            label: 'А',
            list: [
                {
                    name: 'Автокредит',
                    description:
                        'Автокредит вид потребительского кредита, который предоставляется специально на покупку автомобиля.',
                },
            ],
        },
        {
            label: 'Б',
            list: [
                {
                    name: 'Банк',
                    description:
                        'Банк — коммерческая организация, выдающая кредиты, принимающая вклады и проводящая другие финансовые операции. Его деятельность осуществляется на основании лицензии Центрального банка РФ в соответствии с федеральным законом «О банках и банковской деятельности». «Почта Банк» — новый банк группы ВТБ. Лицензия Банка России №650.',
                },
                {
                    name: 'Банкомат',
                    description:
                        '<a href="#">Банкомат</a> — автоматическое устройство, с помощью которого можно самостоятельно снять наличные с карты, внести платеж, получить выписку по счету, осуществить перевод и др. В банкоматах нашего банка легко получить наличные по кредиту «Лето-деньги», внести платеж, а также воспользоваться картой Visa любого банка.',
                },
            ],
        },
        {
            label: 'В',
            list: [],
        },
    ],
};

export const mockDictionaryBlockDataTina: IDictionary = {
    sections: [
        {
            label: 'A',
            list: [
                {
                    name: 'Заголовок',
                    description: 'Текст',
                },
            ],
        },
    ],
};
