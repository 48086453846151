export const promo = {
    title: 'Почта Банк.<br>Самый большой&nbsp;банк страны',
    description: 'Больше, чем новая работа. Новые возможности.',
    buttonValue: 'Хочу в команду',
    slides: [
        {
            src: '/img-next/png/hr-landing/promo/promo-6.png',
            rotate: 0.44,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-7.png',
            rotate: -1.46,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-8.png',
            rotate: 1,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-9.png',
            rotate: -3.11,
        },
    ],
};
