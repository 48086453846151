export const mockFeedbackFormBlockData = {
    title: 'Форма обратной связи',
    successTitle: 'Спасибо за обращение! Мы скоро свяжемся с Вами.',
    description:
        'Задайте интересующий вас вопрос <br> или найдите ответ в&nbsp;разделе <a href="https://www.pochtabank.ru/support/faq">часто задаваемые вопросы</a>',
};

export const mockFeedbackFormBlockDataHr = {
    title: 'Форма обратной связи',
    successTitle: 'Спасибо за обращение! Мы скоро свяжемся с Вами.',
    description: 'Если у вас остались вопросы, или вы не нашли подходящую вакансию, напишите нам.',
};

export const mockFeedbackFormBlockDataTina = {
    title: 'Заголовок',
    successTitle: 'Успешный заголовок.',
    description: 'Описание',
    themeDescList: [
        {
            desc: 'Описание',
        },
    ],
};
