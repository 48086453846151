import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import PreferentialCalculatorContent from '@/components/blocks/calculators/layouts/PreferentialCalculator/content';
import { PreferentialCalculatorProps } from '@/components/blocks/calculators/layouts/PreferentialCalculator/types';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import PreferentialCalculatorStore from '@/domain/calculator/PreferentialCalculatorStore';

const PreferentialCalculator: React.FC<PreferentialCalculatorProps> = observer(
    ({ title, filterElements, terms, index, gtag, button, valueRate, guaranteedRateElements }) => {
        if (!filterElements || !terms || !valueRate || !guaranteedRateElements) return null;

        useEffect(() => {
            PreferentialCalculatorStore.init(filterElements, terms, valueRate, guaranteedRateElements);
        }, []);

        return (
            <CalculatorStoreContext.Provider value={PreferentialCalculatorStore}>
                {PreferentialCalculatorStore.initialized && (
                    <PreferentialCalculatorContent title={title} index={index} gtag={gtag} button={button} />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default PreferentialCalculator;
