import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import InfoInnerItem from '@/components/blocks/Info/InfoInnerItem';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import CustomTooltip from '@/components/Tooltip';
import Button from '@/components/UI/Button';
import { useTheme } from '@/context/ThemeProvider';
import { IInfoItem } from '@/types/info/item';
import { ITag } from '@/types/tags';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';
import TooltipIcon from './tooltipIcon';

export interface IInfo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'info';
    /**
     * Список блоков.
     */
    list: IInfoItem[];
    tagList?: ITag[];
    /**
     * На какой странице находится компонент
     */
    section?: number;
    type?: 'primary' | 'secondary';
    name?: string;
    bottomText?: string;
    btn?: IBtn;
    textInfo?: string;
    tooltipText?: string;
}

interface IBtn {
    label: string;
    link: string;
    gtag?: GTagEvent;
}

const Info: React.FC<IInfo> = ({ list, name, tagList, bottomText, btn, textInfo, tooltipText }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);

    const { theme } = useTheme();

    const items = useMemo(() => {
        if (!tagList?.length) return list;
        return list.reduce((acc: IInfoItem[], current, index) => {
            if (current.tags?.includes(selectedTag)) {
                return [...acc, { ...current, pos: index }];
            }
            return acc;
        }, []);
    }, [tagList, list, selectedTag]);

    const tooltip = (
        <div
            className={clsx(cn.tooltipText, theme === 'dark' && cn.tooltipTextDark)}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
        />
    );

    return (
        <div className={clsx(cn.info, 'section')}>
            <div className={cn.infoWrap}>
                <div className={cn.top}>
                    {name && (
                        <div className={clsx(cn.topItem, cn.topItemTitle)}>
                            <TextField
                                text={name}
                                name="name"
                                customTag="h3"
                                className={cn.mainTitle}
                                isHTML
                                htmlValue={name}
                            />
                        </div>
                    )}
                    {tagList && (
                        <div className={clsx(cn.topItem, cn.topItemFilter)}>
                            <AnimatedComponent className={clsx(cn.filter)} classNameActive={cn.animationInit}>
                                <TagList tagList={tagList} onClick={setSelectedTag} activeTag={selectedTag} />
                            </AnimatedComponent>
                        </div>
                    )}
                </div>

                {items?.length > 0 && (
                    <div className={cn.infoGrid}>
                        {items?.map((item, index) => (
                            <div
                                className={clsx(
                                    cn.infoItem,
                                    (item.size === 'small' || !item.size) && cn.infoItem4,
                                    item.size === 'middle' && cn.infoItem6,
                                    item.size === 'big' && cn.infoItem8,
                                    item.size === 'full' && cn.infoItem12
                                )}
                                data-length={items.length}
                                key={item?.pos || index}
                            >
                                <InfoInnerItem {...item} pos={item?.pos || index} length={items?.length} />
                            </div>
                        ))}
                    </div>
                )}

                {/* @todo вынести в отдельный компонент InfoBot */}
                {(btn?.label || bottomText || textInfo) && (
                    <AnimatedComponent className={cn.infoBot} classNameActive={cn.animationInit}>
                        {btn?.label && (
                            <div className={clsx(cn.infoBotItem, cn.infoBotItemBtn)}>
                                <Button
                                    variant="btnBlue"
                                    href={btn.link}
                                    type="link"
                                    label={btn.label}
                                    animation={false}
                                    customClass="full"
                                    onClick={() => {
                                        if (btn?.gtag?.action) event(btn?.gtag);
                                    }}
                                />
                            </div>
                        )}

                        {textInfo && (
                            <div className={clsx(cn.infoBotItem, cn.infoBotItemInfo)}>
                                <div className={cn.infoBotTextInfoWrap}>
                                    <TextField text={textInfo} name="textInfo" className={cn.infoBotTextInfo} />
                                    {tooltipText && (
                                        <div className={cn.tooltip}>
                                            <CustomTooltip html={tooltip}>
                                                <TooltipIcon />
                                            </CustomTooltip>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {bottomText && (
                            <div className={clsx(cn.infoBotItem, cn.infoBotItemText)}>
                                <TextField
                                    text={bottomText}
                                    name="bottomText"
                                    isHTML
                                    htmlValue={bottomText}
                                    className={cn.infoBotText}
                                />
                            </div>
                        )}
                    </AnimatedComponent>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(Info);
