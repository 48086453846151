import { makeAutoObservable } from 'mobx';

import { ITermItem } from '@/types/calculator/config';

export interface ITermField {
    allTerms: ITermItem[];
    activeTerm: ITermItem;
    disabledTerms: ITermItem[];
    value: number;
    calculatedValue?: number;
    setTerms: (terms: ITermItem[]) => void;
    setActiveTerm: (term: ITermItem) => void;
    disableTerms: (terms: ITermItem[]) => void;
    unlockTerms: () => void;
}

class TermField implements ITermField {
    allTerms: ITermItem[];

    activeTerm: ITermItem;

    constructor(terms: ITermItem[], activeIndex?: number) {
        this.allTerms = terms;
        this.activeTerm = terms && terms.length > 0 ? terms[activeIndex] || terms[0] : null;
        makeAutoObservable(this);
    }

    get value() {
        return this.activeTerm?.value;
    }

    get calculatedValue() {
        return this.activeTerm?.calculatedValue;
    }

    get disabledTerms() {
        return this.allTerms.filter(term => term.disabled);
    }

    setTerms = (terms: ITermItem[]): void => {
        this.allTerms = terms;
    };

    setActiveTerm = (term: ITermItem): void => {
        this.activeTerm = term;
    };

    disableTerms = (terms: ITermItem[]) => {
        const ids = terms?.map(term => term.id);

        this.allTerms = this.allTerms?.map(term => ({ ...term, disabled: ids.includes(term.id) }));

        if (ids.includes(this.activeTerm.id)) {
            this.setActiveTerm(
                this.allTerms
                    .slice()
                    .reverse()
                    .find(term => !term.disabled)
            );
        }
    };

    unlockTerms = () => {
        this.allTerms = this.allTerms?.map(term => ({ ...term, disabled: false }));
    };
}

export default TermField;
