import clsx from 'clsx';
import React, { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import Item from '@/components/blocks/News/Item';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
// eslint-disable-next-line import/no-cycle
import Block from '@/components/layout/Block';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { INewsItem } from '@/types/news/item';
import { IDefaultBlock } from '@/types/tina/block';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH } from '@/utils/constants';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface ISocial {
    title: string;
    link?: string;
    gtag?: GTagEvent;
}

export interface INews {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'news';
    /**
     * Заголовок
     */
    name: string;
    /**
     * Карточки новостей. Добавляться через Тину скорей всего не будут. Если я не ошибаюсь, будут тянуться откуда-то
     */
    list: INewsItem[];
    socials: IDefaultBlock[];
    button: {
        title: string;
        link: string;
    };
}

const News: React.FC<INews> = ({ name, list, socials, button }) => {
    const width = useResize();

    const slidesToShow = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.15;
        if (width < MOBILE_WIDTH) return 2.3;
        if (width < TABLET_SMALL_WIDTH) return 2.5;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView: slidesToShow,
        watchOverflow: true,
    };

    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.news, 'section')}>
            <div className={cn.newsWrap}>
                <div className={cn.topWrap}>
                    <TextField
                        text={name}
                        className={cn.mainTitle}
                        name="name"
                        customTag="h3"
                        isHTML
                        htmlValue={name}
                    />
                    {button?.title && (
                        <div className={cn.buttonWrapper}>
                            <div className={cn.button}>
                                <a href={button?.link} className={cn.buttonInner}>
                                    {button?.title}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn.newsGrid}>
                    <div className={clsx(cn.newsCol, cn.newsColSlider)}>
                        <div className={cn.newsSlider}>
                            <CustomSwiper {...settings}>
                                {list?.map((item, index) => (
                                    <SwiperSlide key={`${index}-${item.link}`}>
                                        <Item item={item} key={`${index}-${item.link}`} pos={index} />
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                        </div>
                    </div>
                    {socials?.length > 0 && socials?.map((block, index) => <Block key={index} block={block} />)}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(News);
