export const mockRightButtonTextBlockData = {
    title: 'В отделении Почта Банка',
    desc:
        '<p>Наши сотрудники помогут установить приложение в любом отделении банка</p>' +
        '<p>Также вы можете позвонить на горячую линию +7 (495) 532-13-00</p>',
    btn: {
        link: '/',
        label: 'Отделения банка',
        gtag: {
            action: 'click',
            category: '',
            label: '',
        },
    },
};

export const mockRightButtonTextBlockDataTina = {
    titleBlock: 'Заголовок',
    title: 'Заголовок',
    desc: 'Описание',
    btn: {
        link: '/',
        label: 'Текст на кнопке',
        gtag: {
            action: '',
            category: '',
            label: '',
        },
    },
};
