import clsx from 'clsx';
import React from 'react';

import InstructionInfoGraphicItem, {
    IInstructionInfoGraphicItem,
} from '@/components/blocks/InstructionInfoGrapfic/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IInstructionInfoGraphic {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'instructionInfoGraphic';
    title?: string;
    list: IInstructionInfoGraphicItem[];
}

function InstructionInfoGraphic({ _template, title, list }: IInstructionInfoGraphic) {
    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={cn.wrap}>
                {title && <TextField text={title} name="title" isHTML htmlValue={title} className={cn.title} />}

                {list && (
                    <div className={cn.grid}>
                        {list?.map((item, index) => (
                            <InstructionInfoGraphicItem
                                name={item.name}
                                pos={index}
                                key={index}
                                image={item.image}
                                desc={item.desc}
                                positionImage={item.positionImage}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default withBlockLinks(InstructionInfoGraphic);
