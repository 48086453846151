export const mockActionDetailBlockData = {
    apiCode: 'PromoActions',
    element: {
        id: 141111,
        name: 'Заголовок',
        code: '',
        startedAt: '2023-06-30T00:00:00+03:00',
        endedAt: '2023-06-30T00:00:00+03:00',
        mechanicsDescription: 'Описание',
        mechanicsDescriptionSrc: '',
        conditions: 'Описание',
        conditionsSrc: '',
        tabletImage: '',
        mobileImage: '',
        linkTo: '#',
        rules: 'Описание',
    },
};
