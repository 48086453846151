import React from 'react';

import { TinaCardApplication } from '@/components/blocks/frames/CardApplication/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const cardApplicationBlock = {
    Component: (props: IBlockProps) => <TinaCardApplication {...props} />,
    template: {
        label: 'Онлайн заявка на оформление карты',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'type',
                component: 'select',
                label: 'Тип',
                description: 'Зявка по умолчанию: Карта "МИР"',
                options: [
                    {
                        value: 'MasterAccount',
                        label: 'Дебетовая карта "МИР"',
                    },
                    {
                        value: 'DebitVirtualCard',
                        label: 'Дебетовая виртуальная карта',
                    },
                    {
                        value: 'Vddohod',
                        label: 'Дебетовая карта "Вездедоход"',
                    },
                ],
            },
        ],
    },
};
