export const mockCardsBlockData = {
    list: [
        {
            archive: false,
            main: true,
            promo: 'Наша лучшая карта',
            name: 'Кредитная карта «ВездеДоход»',
            desc: 'Вернём бонусы за покупки.',
            advantages: [
                {
                    title: '120 дней',
                    subtitle: 'беспроцентный период',
                },
                {
                    title: 'до 20% кешбэк',
                    subtitle: 'бонусами за покупки',
                },
                {
                    title: 'Бесплатно',
                    subtitle: 'Выпуск и обслуживание',
                    tooltipText:
                        'Бесплатно при сумме покупок товаров и услуг по карте от 5 000 Р в месяц. Иначе — 99 Р/мес',
                },
            ],
            buttonBg: {
                name: 'Оформить карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/Rectangle1.png',
                imageLink: '',
                infoLayer: '/img-next/animated-cards/1/card-info-layer.png',
            },
        },
        /* {
            archive: false,
            main: true,
            name: 'Кредитная карта «Деньги и всё!»',
            advantages: [
                {
                    title: 'Возобновляемый',
                    subtitle: 'кредитный лимит',
                },
                {
                    title: 'Платеж 3%',
                    subtitle: 'в месяц от суммы кредита',
                },
                {
                    title: 'До 20% кешбэк',
                    subtitle: 'с программой лояльности «МИР»',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/tobuy/1.png',
                infoLayer: '/img-next/animated-cards/tobuy/3.png',
            },
        },
        {
            archive: false,
            main: true,
            name: 'Кредитная карта «Зеленый мир»',
            advantages: [
                {
                    title: 'Сажаем деревья',
                    subtitle: 'за покупки',
                },
                {
                    title: 'До 1 500 000 Р',
                    subtitle: 'кредитный лимит',
                },
                {
                    title: 'До 2-х месяцев',
                    subtitle: 'беспроцентный период',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/greenWorld/bg.png',
                infoLayer: '/img-next/animated-cards/greenWorld/info.png',
            },
        }, */
        {
            archive: true,
            name: 'Кредитная карта «Марки»',
            desc: 'Выпуск прекращен с 01.12.2020 г.',
            advantages: [
                {
                    title: 'До 1 500 000 ₽',
                    subtitle: 'кредитный лимит',
                },
                {
                    title: 'Кешбэк до 15% за все покупки',
                    subtitle: 'бонусами выбранной программы лояльности',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/2/card-bg-layer.png',
                imageLink: '',
                infoLayer: '/img-next/animated-cards/2/card-info-layer.png',
            },
        },
        {
            archive: true,
            name: 'Кредитная карта «Элемент 120»',
            desc: 'Выпуск прекращен с 15.04.2021 г.',
            advantages: [
                {
                    title: 'от 10,9% годовых',
                    subtitle: 'ставка',
                },
                {
                    title: 'Кешбэк до 12%!',
                    subtitle: 'с программой лояльности Шанс',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/element120/bg.png',
                infoLayer: '/img-next/animated-cards/element120/card-info-layer.png',
            },
        },
        {
            archive: true,
            name: 'Кредитная карта «ВездеДоход»',
            desc: 'Выпуск прекращен с 15.04.2021 г.',
            advantages: [
                {
                    title: 'до 6% бонусами',
                    subtitle: 'за покупки в магазинах одежды, обуви, в ресторанах и кафе',
                },
                {
                    title: 'До 1 500 000 ₽',
                    subtitle: 'кредитный лимит',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/vddohod/1.png',
                imageLink: '',
                infoLayer: '/img-next/animated-cards/vddohod/info.png',
            },
        },
        {
            archive: true,
            name: 'Кредитная карта «Пятёрочка»',
            desc: 'Выпуск прекращен с 15.04.2021 г.',
            advantages: [
                {
                    title: 'В 3 раза больше баллов',
                    subtitle: 'за покупки в «Пятёрочке»',
                },
                {
                    title: 'До 500 000 ₽',
                    subtitle: 'кредитный лимит',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/5-ka/1.png',
                imageLink: '',
                infoLayer: '/img-next/animated-cards/5-ka/2.png',
            },
        },
    ],
};

export const mockCardsBlockDataDebet = {
    cardLimit: 4,
    list: [
        {
            archive: false,
            name: 'Дебетовая карта «ВездеДоход»',
            desc: 'Карта к сберегательному счету.',
            advantages: [
                {
                    title: '3% бонусами',
                    subtitle: 'за покупки в магазинах одежды, обуви, в ресторанах и кафе',
                },
                {
                    title: '1% бонусами',
                    subtitle: 'за все покупки',
                },
                {
                    title: 'Обслуживание 0 Р ',
                    subtitle: 'при покупках от 5 000 Р в месяц',
                },
                {
                    title: 'Программа Мультибонус',
                    subtitle: 'товары или деньги за покупки по карте',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/vddohod/bg.png',
                infoLayer: '/img-next/animated-cards/vddohod/Rectangle.png',
            },
        },
        {
            archive: false,
            name: 'Дебетовая карта «Магнит»',
            desc: 'К Сберегательному счету дебетовая карта для экономных покупок в сети магазинов «Магнит».',
            advantages: [
                {
                    title: '4% бонусами ',
                    subtitle: 'на первый месяц за покупки в «Магните»',
                },
                {
                    title: '1% бонусами',
                    subtitle: 'на первый месяц за покупки в других магазинах',
                },
                {
                    title: 'Бесплатный выпуск',
                    subtitle: 'первой карты к Сберегательному счету',
                },
                {
                    title: 'Оплата покупок бонусами',
                    subtitle: '',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/magnit/1.png',
                infoLayer: '/img-next/animated-cards/magnit/2.png',
            },
        },
        {
            archive: false,
            name: 'Дебетовая карта «Пятёрочка»',
            desc: 'К Сберегательному счету дебетовая карта для экономных покупок в сети магазинов «Пятёрочка».',
            advantages: [
                {
                    title: 'Баллы х3',
                    subtitle: 'за покупки в «Пятёрочке», чем по «ВЫРУЧАЙ — КАРТЕ»',
                },
                {
                    title: 'До 5 550',
                    subtitle: 'приветственных баллов',
                },
                {
                    title: 'Бесплатный выпуск',
                    subtitle: 'первой карты к Сберегательному счету',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/5-ka/1.png',
                infoLayer: '/img-next/animated-cards/5-ka/2.png',
            },
        },
        {
            archive: false,
            name: 'Детская карта',
            desc: 'Первая карта для вашего ребенка с множеством преимуществ.',
            advantages: [
                {
                    title: 'Бесплатное открытие',
                    subtitle: 'за покупки в «Пятёрочке», чем по «ВЫРУЧАЙ — КАРТЕ»',
                },
                {
                    title: 'Детский мобильный банк',
                    subtitle: 'под контролем родителей',
                },
                {
                    title: 'Поддерживает',
                    subtitle: 'Apple Pay, Google Pay и Samsung Pay',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/child/1.png',
                infoLayer: '/img-next/animated-cards/child/2.png',
            },
        },
        {
            archive: false,
            name: 'Карта геймера',
            desc: 'Твой ключ в профессиональный игровой мир.',
            advantages: [
                {
                    title: 'Кешбэк до 12%',
                    subtitle: 'с программой лояльности «Шанс»',
                },
                {
                    title: 'Бесплатное',
                    subtitle: 'снятие наличных во всех банкоматах Почта Банка и банков группы ВТБ',
                },
                {
                    title: 'Бесплатное обслуживание',
                    subtitle: '',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/gamers/1.png',
                infoLayer: '/img-next/animated-cards/gamers/2.png',
            },
        },
        {
            archive: false,
            name: 'Карта для покупок',
            desc: 'Оплата товаров и услуг по всему миру.',
            advantages: [
                {
                    title: 'Мир привилегий Visa Premium',
                    subtitle: 'скидки и подарки от партнеров',
                },
                {
                    title: '«Пакет онлайн-покупателя»',
                    subtitle: 'для комфортных покупок в интернете',
                },
                {
                    title: 'Принимается во всем мире',
                    subtitle: '',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/tobuy/1.png',
                infoLayer: '/img-next/animated-cards/tobuy/2.png',
            },
        },
        {
            archive: false,
            name: 'Дебетовая карта «Мир»',
            desc: 'Карта к сберегательному счету.',
            advantages: [
                {
                    title: 'До 5% годовых',
                    subtitle: 'доход',
                },
                {
                    title: '1% бонусами ',
                    subtitle: 'за все покупки',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/mir/1.png',
                infoLayer: '/img-next/animated-cards/mir/Rectangle.png',
            },
        },
        {
            archive: true,
            name: 'Карта «Марки»',
            desc: 'Выпуск прекращен с 01.12.2020 г.',
            advantages: [
                {
                    title: 'Поддерживает',
                    subtitle: 'Apple Pay, Google Pay и Samsung Pay',
                },
                {
                    title: 'Кешбэк до 20% бонусами',
                    subtitle: 'с программой «Мультибонус»',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/marks/1.png',
                infoLayer: '/img-next/animated-cards/marks/2.png',
            },
        },
        {
            archive: true,
            name: 'Премиальная карта «Марки»',
            desc: 'Выпуск прекращен с 01.12.2020 г.',
            advantages: [
                {
                    title: 'Поддерживает',
                    subtitle: 'Apple Pay, Google Pay и Samsung Pay',
                },
                {
                    title: 'Кешбэк до 20% бонусами',
                    subtitle: 'с программой «Мультибонус»',
                },
                {
                    title: 'Мир привилегий Visa Premium',
                    subtitle: 'скидки и подарки от партнеров',
                },
            ],
            buttonArchive: {
                name: 'Узнать подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/marksPremium/1.png',
                infoLayer: '/img-next/animated-cards/marksPremium/2.png',
            },
        },
    ],
};

export const mockCardsBlockDataPensioneram = {
    title: 'Переведите пенсию на карту ПочтаБанк',
    tagList: [
        {
            value: 'Карта «Мир»',
            xmlId: '1',
        },
        {
            value: 'Карта Мир «Пятёрочка»',
            xmlId: '2',
        },
        {
            value: 'Карта «Магнит»',
            xmlId: '3',
        },
    ],
    list: [
        {
            tags: ['1'],
            archive: false,
            name: 'Дебетовая карта «Мир»',
            desc: 'Карта к сберегательному счету.',
            advantages: [
                {
                    title: 'До 5% годовых',
                    subtitle: 'доход',
                },
                {
                    title: '1% бонусами',
                    subtitle: 'за все покупки',
                },
                {
                    title: 'Мгновенный выпуск',
                    subtitle: 'Бесплатное оформление',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/mir/1.png',
                infoLayer: '/img-next/animated-cards/mir/Rectangle.png',
            },
        },
        {
            tags: ['2'],
            archive: false,
            name: 'Дебетовая карта Мир «Пятёрочка»',
            desc: 'Карта к сберегательному счету.',
            advantages: [
                {
                    title: 'До 5% годовых',
                    subtitle: 'доход',
                },
                {
                    title: '1% бонусами',
                    subtitle: 'за все покупки',
                },
                {
                    title: 'Мгновенный выпуск',
                    subtitle: 'Бесплатное оформление',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/5-ka/1.png',
                infoLayer: '/img-next/animated-cards/5-ka/2.png',
            },
        },
        {
            tags: ['3'],
            archive: false,
            name: 'Дебетовая карта «Магнит»',
            desc: 'Карта к сберегательному счету.',
            advantages: [
                {
                    title: 'До 5% годовых',
                    subtitle: 'доход',
                },
                {
                    title: '1% бонусами',
                    subtitle: 'за все покупки',
                },
                {
                    title: 'Мгновенный выпуск',
                    subtitle: 'Бесплатное оформление',
                },
            ],
            buttonBg: {
                name: 'Заказать карту',
                link: '#',
            },
            buttonDetail: {
                name: 'Подробнее',
                link: '#',
            },
            cardLayers: {
                bgLayer: '/img-next/animated-cards/magnit/1.png',
                infoLayer: '/img-next/animated-cards/magnit/2.png',
            },
        },
    ],
};

export const mockCardsBlockDataTina = {
    section: null,
    title: '',
    list: [
        {
            archive: false,
            name: '',
            desc: '',
            advantages: [
                {
                    title: '',
                    subtitle: '',
                },
            ],
            buttonArchive: {
                name: '',
                link: '#',
            },
            cardLayers: {},
        },
    ],
};
