export const mockStepSelectionBlockData = {
    title: 'Как подключиться к сервису',
    desc: 'Заполните форму обратной связи:',
    image: '',
    step: '1',
    name: 'stepSelection',
};

export const mockStepSelectionBlockWithImage = {
    title: '',
    desc:
        'Билетная система Почта Банк:' +
        '<ul>' +
        '<li>получает информацию о мероприятиях,</li>' +
        '<li>размещает на витрине билетов Почта Банк. Билеты</li>' +
        '<li>синхронизирует информацию о резерве и продажах во всех каналах</li></ul>',
    image: '/img-next/png/animatedPromo/dots4.png',
    step: '1',
    name: 'stepSelection',
};

export const mockStepSelectionBlockDataTina = {
    title: 'Заголовок',
    desc: 'Текст',
    image: '',
    step: '1',
    name: 'stepSelection',
};
