import React from 'react';

import { TinaDarkBanner } from '@/components/blocks/banners/DarkBanner/index.tina';
import { mockDarkBannerBlockDataTina } from '@/data/blocks/darkBanner';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const darkBannerBlock = {
    Component: props => <TinaDarkBanner {...props} />,
    template: {
        label: 'Темный баннер',
        defaultItem: () => mockDarkBannerBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Наименование элемента (максимум 255 символов)',
                required: true,
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'text',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'desktopImage',
                component: 'image',
                label: 'Картинка для десктопа',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'tabletImage',
                component: 'image',
                label: 'Картинка для планшета',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'mobileImage',
                component: 'image',
                label: 'Картинка для мобильных',
                parse: (media: { src: string }) => media.src,
            },
        ],
    },
};
