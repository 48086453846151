import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import SberCalculatorContent from '@/components/blocks/calculators/layouts/SberschetCalculator/Content';
import { useAppStore } from '@/context/AppStoreContext';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import SberschetCalculatorStore from '@/domain/calculator/SberschetCalculatorStore';
import { IFieldsConfig } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface ISberschetCalculator {
    _template?: 'sberschetCalculator';
    title?: string;
    tabTitle?: string;
    fields: IFieldsConfig;
    button?: {
        label: string;
        link: string;
    };
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
    valueRate: number;
}

const SberschetCalculator: React.FC<ISberschetCalculator> = observer(({ title, fields, gtag, button, valueRate }) => {
    const [path] = useAppStore(store => store.path);

    if (!fields || !valueRate) return null;

    const CashCalculatorInstance = useMemo(() => new SberschetCalculatorStore({ fields }, valueRate), [path]);

    return (
        <CalculatorStoreContextNew.Provider value={CashCalculatorInstance}>
            <SberCalculatorContent title={title} gtag={gtag} button={button} />
        </CalculatorStoreContextNew.Provider>
    );
});

export default SberschetCalculator;
