export const mockGuaranteedPrizesBlockData = {
    name: 'Ваши гарантированные призы',
    list: [
        {
            title: 'Скидка 1000&nbsp;₽ на&nbsp;чек от&nbsp;3000&nbsp;₽',
            icon: '/img-next/svg/threeInARow/android.svg',
        },
        {
            title: 'Скидка 1000&nbsp;₽ на&nbsp;чек от&nbsp;3000&nbsp;₽',
            icon: '/img-next/svg/threeInARow/android.svg',
        },
        {
            title: 'Скидка 1000&nbsp;₽ на&nbsp;чек от&nbsp;3000&nbsp;₽',
            icon: '/img-next/svg/threeInARow/android.svg',
        },
        {
            title: 'Скидка 1000&nbsp;₽ на&nbsp;чек от&nbsp;3000&nbsp;₽',
            icon: '/img-next/svg/threeInARow/android.svg',
        },
    ],
};

export const mockGuaranteedPrizesBlockDataTina = {
    name: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            icon: '',
        },
        {
            title: 'Заголовок',
            icon: '',
        },
    ],
};
