export const buildingCalculatorWithPicture = {
    title: 'Калькулятор кредита на строительство',
    valueRate: {
        lowRate: 3,
        highRate: 9,
    },
    filterElements: [
        {
            value: 1000000,
            step: 5000,
            percent: 0,
            label: '1 млн.',
            id: 2,
        },
        {
            value: 2000000,
            step: 5000,
            percent: 33,
            label: '2 млн.',
            id: 3,
        },
        {
            value: 3000000,
            step: 50000,
            percent: 66,
            label: '3 млн.',
            id: 4,
        },
        {
            value: 5000000,
            step: 50000,
            percent: 100,
            label: '5 млн.',
            id: 5,
        },
    ],
    secondFilterElements: [
        {
            value: 10,
            step: 1,
            percent: 0,
            label: '10%',
            id: 1,
        },
        {
            value: 25,
            step: 1,
            percent: 25,
            label: '25%',
            id: 2,
        },
        {
            value: 50,
            step: 1,
            percent: 50,
            label: '50%',
            id: 3,
        },
        {
            value: 75,
            step: 1,
            percent: 75,
            label: '75%',
            id: 4,
        },
        {
            value: 90,
            step: 1,
            percent: 100,
            label: '90%',
            id: 5,
        },
    ],
    terms: [
        {
            id: 1,
            label: '1 год',
            value: 12,
            coefficient: 8.48,
        },
        {
            id: 2,
            label: '2 года',
            value: 24,
            coefficient: 4.35,
        },
        {
            id: 4,
            label: '3 года',
            value: 36,
            coefficient: 2.9,
        },
        {
            id: 5,
            label: '4 года',
            value: 48,
            coefficient: 2.27,
        },
        {
            id: 6,
            label: '5 лет',
            value: 60,
            coefficient: 1.85,
        },
        {
            id: 7,
            label: '6 лет',
            value: 72,
            coefficient: 1.58,
        },
        {
            id: 8,
            label: '7 лет',
            value: 84,
            coefficient: 1.38,
        },
    ],
    minValue: 1000000,
    maxvalue: 5000000,
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
