import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import Feature from '@/domain/feature/Feature';
import { IWidgetPoll } from '@/types/widgetPoll';
import { Cookie } from '@/utils/cookie';

const WidgetPollContent = dynamic(async () => import('./content'));

function WidgetPoll({ title, list, _template }: IWidgetPoll) {
    const [shouldInitialize, setShouldInitialize] = useState(false);
    const [endPoll, setEndPoll] = useState(false);

    useEffect(() => {
        setEndPoll(!!Cookie.getCookie('endPoll'));
        setShouldInitialize(true);
    }, []);

    if (!Feature.isFeatureEnabled('enableWidgetPoll')) {
        return null;
    }

    if (shouldInitialize)
        return <WidgetPollContent _template={_template} title={title} list={list} endPoll={endPoll} />;

    return null;
}

export default WidgetPoll;
