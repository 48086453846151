import React from 'react';

import { TinaMainSlider } from '@/components/blocks/MainSlider/index.tina';
import { mockMainSliderBlockTinaData } from '@/data/blocks/mainSlider';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const mainSliderBlock = {
    Component: props => <TinaMainSlider {...props} />,
    template: {
        label: 'Карусель карточек предложений',
        defaultItem: () => mockMainSliderBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'disableSlider',
                component: 'toggle',
                label: 'Отключить слайдер',
            },
            {
                name: 'tagList',
                component: 'tagList',
                label: 'Привязка по тэгам',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                description: 'Список элементов слайдера',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockMainSliderBlockTinaData.list[0],
                fields: [
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Картинка слайдера',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'textBtn',
                        component: 'text',
                        label: 'Текст',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    { ...gtagSingleItem },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
