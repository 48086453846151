import React from 'react';

import FeedbackFormContent from '@/components/blocks/FeedbackForm/FeedbackFormContent';
import { optionsPretension } from '@/data/blocks/feedbackFormData';

export interface IFeedbackFormPretension {
    _template: 'feedbackFormPretension';
    title?: string;
    description?: string;
    successTitle?: string;
    thanksSubTitle?: string;
}

const FeedbackFormPretension: React.FC<IFeedbackFormPretension> = ({
    title,
    description,
    successTitle,
    thanksSubTitle,
    _template = 'feedbackFormPretension',
}) => (
    <FeedbackFormContent
        templateName={_template}
        options={optionsPretension}
        title={title}
        description={description}
        successTitle={successTitle}
        thanksSubTitle={thanksSubTitle}
    />
);

export default FeedbackFormPretension;
