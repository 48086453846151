import { IPhotoDateBlock } from '@/components/blocks/PhotoDateBlock';

export const mockPhotoDateBlockBlockData: IPhotoDateBlock = {
    _template: 'photoDateBlock',
    title: 'Поздравляем победителей!',
    list: [
        {
            date: '25 июля',
            desc: 'Победитель розыгрыша от 26 июля Галина В. получила в подарок от Почта Банка новый автомобиль. Поздравляем победителя розыгрыша!',
            img: '/img-next/png/mock.png',
        },
        {
            date: '26 июля',
            desc: '',
            img: '',
        },
        {
            date: '5 августа',
            desc: '',
            img: '',
        },
        {
            date: '23 августа',
            desc: '',
            img: '',
        },
        {
            date: '27 сентября',
            desc: '',
            img: '',
        },
        {
            date: '25 октября',
            desc: '',
            img: '',
        },
        {
            date: '22 ноября',
            desc: '',
            img: '',
        },
        {
            date: '20 декабря',
            desc: '',
            img: '',
        },
    ],
    button: {
        href: '#',
        label: 'Стать пенсионным клиентом',
    },
};

export const mockPhotoDateBlockBlockDataTina = {
    _template: 'photoDateBlock',
    title: 'Заголовок',
    list: [
        {
            date: 'Дата',
            desc: 'Описание',
            image: 'Изображение',
        },
    ],
    button: {
        href: '#',
        label: 'Название',
    },
};
