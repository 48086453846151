export const mockCashbackBlockData = {
    _template: 'cashback',
    name: 'Кешбэк бонусами до 20%',
    text: 'Получайте кешбэк бонусами с программой <a href="#">Мультибонус</a>',
    tagList: [
        {
            value: 'До 30 000 ₽ в мес',
            xmlId: 'min',
        },
        {
            value: 'После 30 000 ₽ в мес',
            xmlId: 'max',
        },
    ],
    list: [
        {
            tags: ['min'],
            title: '6%',
            subtitle: 'В магазинах одежды, обуви, в ресторанах и кафе',
            image: '/img-next/png/cashback/1.png',
        },
        {
            tags: ['min'],
            title: '2%',
            subtitle: 'Все остальные покупки',
            image: '/img-next/png/cashback/2.png',
        },
        {
            tags: ['min'],
            desc: 'До',
            title: '20%',
            subtitle: 'У партнёров программы',
            image: '/img-next/png/cashback/3.png',
        },
        {
            tags: ['max'],
            title: '20%',
            subtitle: 'Все покупки',
            image: '/img-next/png/cashback/2.png',
        },
    ],
};

export const mockCashbackBlockTinaData = {
    section: null,
    name: 'Заголовок',
    text: 'Текст',
    list: [
        {
            title: '0%',
            subtitle: 'Текст',
            image: '',
        },
        {
            title: '0%',
            subtitle: 'Текст',
            image: '',
        },
    ],
};
