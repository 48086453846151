import React from 'react';

import { TinaCashCreditCalculator } from '@/components/blocks/calculators/layouts/CashCreditCalculator/index.tina';
import { mockCashCreditCalculator } from '@/data/blocks/calculators/cashCreditCalculator';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const cashCreditCalculatorBlock = {
    Component: props => <TinaCashCreditCalculator {...props} />,
    template: {
        label: 'Кредит наличными на любые цены без процентов',
        defaultItem: mockCashCreditCalculator,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'tabTitle',
                component: 'text',
                label: 'Заголовок вкладки',
            },
            {
                name: 'percentTooltip',
                component: HtmlEditorFeatured,
                label: 'Текст тултипа возле процентов',
            },
            {
                name: 'button',
                component: 'group',
                label: 'Кнопка',
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                name: 'infoTitle',
                component: 'group',
                label: 'Дисклеймер',
                fields: [
                    {
                        name: 'isActive',
                        component: 'toggle',
                        label: 'Выключить/включить дисклеймер',
                    },
                    {
                        name: 'text',
                        component: HtmlEditorFeatured,
                        defaultItem: () => mockCashCreditCalculator.infoTitle.text,
                        label: 'Текст',
                    },
                    {
                        name: 'tooltipText',
                        component: HtmlEditorFeatured,
                        defaultItem: () => mockCashCreditCalculator.infoTitle.tooltipText,
                        label: 'Текст тултипа',
                    },
                ],
            },
            {
                name: 'gtag',
                component: 'group',
                label: 'Цель GTAG',
                fields: [
                    {
                        name: 'gtagSlider',
                        component: 'group',
                        label: 'Цель ползунок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagButton',
                        component: 'group',
                        label: 'Цель кнопка',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                    {
                        name: 'gtagTerm',
                        component: 'group',
                        label: 'Цель срок',
                        fields: [
                            {
                                name: 'action',
                                component: 'text',
                                label: 'Действие',
                            },
                            {
                                name: 'category',
                                component: 'text',
                                label: 'Категория',
                            },
                            {
                                name: 'label',
                                component: 'text',
                                label: 'Заголовок',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
