import React from 'react';

function TooltipColorIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15" stroke="url(#paint0_linear_3997_4724)" strokeWidth="2" />
            <path
                d="M17.6274 9.91406L17.3535 18.3062H15.3613L15.0791 9.91406H17.6274ZM15.0127 20.9043C15.0127 20.5501 15.1344 20.2568 15.3779 20.0244C15.627 19.7865 15.9535 19.6675 16.3574 19.6675C16.7669 19.6675 17.0934 19.7865 17.3369 20.0244C17.5804 20.2568 17.7021 20.5501 17.7021 20.9043C17.7021 21.2474 17.5804 21.5379 17.3369 21.7759C17.0934 22.0138 16.7669 22.1328 16.3574 22.1328C15.9535 22.1328 15.627 22.0138 15.3779 21.7759C15.1344 21.5379 15.0127 21.2474 15.0127 20.9043Z"
                fill="#373591"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3997_4724"
                    x1="16"
                    y1="-2.98023e-07"
                    x2="26"
                    y2="32"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#333492" />
                    <stop offset="1" stopColor="#C90D44" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TooltipColorIcon;
