import clsx from 'clsx';
import React, { FC } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IIntroPromo {
    _template: 'introPromo';
    heading?: string;
    subheading?: string;
    desc?: string;
    descBold?: boolean;
    descSize?: 'normal' | 'big';
    image?: string;
    isImageToBottomMobile?: boolean;
    button?: {
        name: string;
        link: string;
        enableIsNewDesign?: boolean;
        size?: 'default' | 'big';
        gtag?: GTagEvent;
    };
    logo?: ILogoItem[];
    enableLogo?: boolean;
    type?: 'big' | 'min';
    imagePosition?: 'bottom' | 'center';
    hideBreadcrumbs?: boolean;
    backgroundImage?: string;
    enableBackgroundImage?: boolean;
}

interface ILogoItem {
    img: string;
    alt: string;
}

const IntroPromo: FC<IIntroPromo> = ({
    heading,
    subheading,
    desc,
    descBold = false,
    descSize = 'normal',
    image,
    isImageToBottomMobile,
    button,
    logo,
    enableLogo,
    type = 'big',
    imagePosition = 'center',
    hideBreadcrumbs,
    backgroundImage,
    enableBackgroundImage = false,
}) => (
    <div
        className={cn.wrapper}
        style={{
            background:
                backgroundImage && enableBackgroundImage
                    ? `url(${backgroundImage}) center center/cover no-repeat`
                    : null,
        }}
    >
        <div className={clsx(cn.grid, type === 'min' && cn.gridMin, isImageToBottomMobile && cn.gridToBottom)}>
            <div className={clsx(cn.cellInfo, cn.cell, type === 'min' && cn.cellMin)}>
                <Breadcrumbs
                    hideDisable={!hideBreadcrumbs}
                    customClassName={cn.breadcrumbsPromo}
                    customClassItem={cn.breadcrumbsItemPromo}
                />
                {heading && (
                    <div className={clsx(cn.headingWrap, hideBreadcrumbs && cn.headingWrapNoBreadcrumbs)}>
                        <TextField
                            text={heading}
                            name="heading"
                            customTag="h1"
                            className={cn.heading}
                            isHTML
                            htmlValue={heading}
                        />
                    </div>
                )}
                {subheading && (
                    <div>
                        <div className={cn.subheadingWrap}>
                            <TextField
                                text={subheading}
                                name="subheading"
                                className={cn.subheading}
                                isHTML
                                htmlValue={subheading}
                            />
                        </div>
                    </div>
                )}
                {desc && (
                    <TextField
                        className={clsx(cn.desc, descBold && cn.bold, descSize === 'big' && cn.bigSize)}
                        name="desc"
                        text={desc}
                        isHTML
                        htmlValue={desc}
                    />
                )}
                <div className={clsx(cn.buttonWrap, cn.hideOnTablet)}>
                    {button?.name && (
                        <Button
                            variant="btnBlue"
                            type="link"
                            href={button.link}
                            label={button.name}
                            customClass={clsx(cn.button, button?.size === 'big' && cn.buttonBig)}
                            size={button?.size === 'big' ? 'large' : 'small'}
                            isRedesign={button?.enableIsNewDesign}
                            onClick={() => {
                                if (button?.gtag?.action) event(button?.gtag);
                            }}
                        />
                    )}
                </div>
            </div>
            {(image || enableLogo || button?.name) && (
                <div
                    className={clsx(
                        cn.cell,
                        cn.cellImg,
                        type === 'min' && cn.cellMin,
                        isImageToBottomMobile && cn.cellImgToBottom
                    )}
                >
                    {!enableLogo && image && (
                        <div className={clsx(cn.imgWrap, imagePosition === 'bottom' && cn.imgWrapBottom)}>
                            <CustomImage src={image} alt={heading} fill />
                        </div>
                    )}
                    {enableLogo && logo?.length > 0 && (
                        <div className={cn.logo}>
                            <div className={cn.logoGrid}>
                                {logo?.map(({ img, alt }, index) => (
                                    <div className={cn.logoItem} key={index}>
                                        <CustomImage src={img} alt={alt} width="190" height="125" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className={clsx(cn.buttonWrap, cn.showOnTablet)}>
                        {button?.name && (
                            <Button
                                variant="btnBlue"
                                type="link"
                                href={button.link}
                                label={button.name}
                                customClass={clsx(cn.button, 'full', button?.size === 'big' && cn.buttonBig)}
                                size={button?.size === 'big' ? 'large' : 'small'}
                                isRedesign={button?.enableIsNewDesign}
                                onClick={() => {
                                    if (button?.gtag?.action) event(button?.gtag);
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    </div>
);

export default IntroPromo;
