import React from 'react';

import cn from '@/components/UI/ViewCount/style.module.sass';

function ViewIcon() {
    return (
        <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.886 5.55983C20.8203 4.16979 17.0906 0 11.0212 0C4.95169 0 1.24514 4.16987 0.156342 5.55983C-0.0521141 5.81466 -0.0521141 6.18535 0.156342 6.44017C1.22206 7.83021 4.95169 12 11.0212 12C17.0906 12 20.7972 7.83013 21.886 6.44017C22.0713 6.18534 22.0713 5.81465 21.886 5.55983ZM11.0212 10.471C8.54233 10.471 6.55014 8.47873 6.55014 5.99998C6.55014 3.52114 8.54241 1.52895 11.0212 1.52895C13.5 1.52895 15.4922 3.52122 15.4922 5.99998C15.4922 8.47882 13.5001 10.471 11.0212 10.471Z"
                fill="#635BF8"
                className={cn.svgFill}
            />
            <path
                d="M11.0212 3.12747C10.7664 3.12747 10.5346 3.15058 10.3031 3.22006C10.6505 3.49801 10.8591 3.89179 10.8591 4.37836C10.8591 5.18925 10.2105 5.83786 9.39961 5.83786C8.93633 5.83786 8.51926 5.6294 8.24131 5.28183C8.17183 5.51356 8.14872 5.7684 8.14872 5.99994C8.14872 7.59843 9.44597 8.87255 11.0213 8.87255C12.5965 8.87255 13.894 7.5753 13.894 5.99994C13.8938 4.4249 12.5964 3.12744 11.0212 3.12744L11.0212 3.12747Z"
                fill="#635BF8"
                className={cn.svgFill}
            />
        </svg>
    );
}

export default ViewIcon;
