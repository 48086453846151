import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { formsConfig, TFormTemplates } from '@/tina/config/forms.config';

export interface IFormBlockParam {
    _template: TFormTemplates;
    [name: string]: unknown;
}

export interface IFormBlock {
    _template: 'formBlock';
    form: IFormBlockParam;
}

const FormBlock: React.FC<IFormBlock> = ({ form: { _template: _formTemplate, ...rest }, _template }) => {
    if (!_formTemplate) return null;

    const param = { _template: _formTemplate, ...rest };

    const Form = formsConfig?.[_formTemplate]?.Component;

    if (Form === undefined) return null;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Form {...param} />;
};

export default withBlockLinks(FormBlock);
