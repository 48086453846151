import React from 'react';

import { TinaManagement } from '@/components/blocks/Management/index.tina';
import { mockManagementMockData } from '@/data/blocks/management';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const managementBlock = {
    Component: props => <TinaManagement {...props} />,
    template: {
        label: 'Руководство. Карточки',
        defaultItem: () => mockManagementMockData,
        fields: [
            {
                name: 'heading',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'managers',
                label: 'Руководители',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockManagementMockData.managers[0],
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'ФИО',
                    },
                    {
                        name: 'position',
                        component: HtmlEditorFeatured,
                        label: 'Должность',
                    },
                    {
                        name: 'photo',
                        component: 'image',
                        label: 'Фото',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
