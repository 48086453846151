import React from 'react';

import { TinaPrivateBanking } from '@/components/blocks/PrivateBanking/index.tina';

export const privateBankingBlock = {
    Component: props => <TinaPrivateBanking {...props} />,
    template: {
        label: 'Приват Банк',
        defaultItem: () => ({ text: 'Текст ', titleBlock: 'Текстовый блок' }),
        fields: [
            {
                name: 'titleBlock',
                component: 'text',
                label: 'Название элемента/Заголовок (максимум 255 символов)',
                required: true,
            },
            {
                component: 'text',
                name: 'text',
                label: 'Текст',
                required: true,
            },
        ],
    },
};
