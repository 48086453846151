import clsx from 'clsx';
import React from 'react';

import TextField from '@/components/TextField';
import { IParametersBondsItem } from '@/types/bonds/item';

import cn from './style.module.sass';

export interface IParametersBonds {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'parametersBonds';
    title?: string;
    list: IParametersBondsItem[];
}

const ParametersBonds: React.FC<IParametersBonds> = ({ title, list }) => {
    if (list?.length <= 0) return null;

    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={cn.innerWrapper}>
                {title && (
                    <TextField text={title} customTag="h3" name="title" isHTML htmlValue={title} className={cn.title} />
                )}
                <div className={cn.blockWrapper}>
                    <div className={cn.content}>
                        {list?.map((item, index) => (
                            <div className={cn.item} key={`index-${index}`}>
                                <TextField
                                    className={cn.itemTitle}
                                    text={item.itemTitle}
                                    name={`list[${index}].itemTitle`}
                                    isHTML
                                    htmlValue={item.itemTitle}
                                />
                                <TextField
                                    className={cn.itemValue}
                                    text={item.itemValue}
                                    name={`list[${index}].itemValue`}
                                    isHTML
                                    htmlValue={item.itemValue}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParametersBonds;
