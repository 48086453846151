import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import FullScreenTolltip from '@/components/blocks/FullScreenTolltip';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { useFixHtml } from '@/hooks/useFixHtml';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IDistributionAskQuestionItem {
    title: string;
    subtitle: string;
    image?: string;
    link: string;
    isTel?: boolean;
    size: 'big' | 'small';
    gtag?: GTagEvent;
    tooltipText?: string;
}

function DistributionAskQuestionItem({
    title,
    subtitle,
    image,
    link,
    isTel,
    size,
    gtag,
    tooltipText,
    index,
}: IDistributionAskQuestionItem & { index: number }) {
    const [showToolTip, setShowTooltip] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();
    const show = () => {
        fix();
        setShowTooltip(true);
    };

    const hide = () => {
        unfix();
        setShowTooltip(false);
    };

    return (
        <AnimatedComponent
            className={cn.linksItem}
            classNameActive={cn.animationInit}
            key={`key-${index}`}
            onClick={() => {
                if (gtag?.action) event(gtag);
            }}
            role="presentation"
        >
            <div className={cn.linksItemWrap}>
                {tooltipText?.length > 0 && (
                    <div className={cn.linksIcon}>
                        <div role="presentation" onClick={show}>
                            <TooltipIcon />
                        </div>
                    </div>
                )}

                <a className={cn.linksItemLink} href={isTel ? `tel:${link}` : link}>
                    <div className={cn.linksTop}>
                        <TextField
                            className={cn.linksTitle}
                            text={title}
                            name={`list.[${index}].title`}
                            isHTML
                            htmlValue={title}
                        />
                        <TextField
                            className={cn.linksSubtitle}
                            text={subtitle}
                            name={`list.[${index}].subtitle`}
                            isHTML
                            htmlValue={subtitle}
                        />
                    </div>
                    <div className={cn.linksBot}>
                        <div className={cn.linksBotWrapper}>
                            <div className={cn.linksImage}>
                                <CustomImage mockWidth="200" mockHeight="185" src={image} alt={title} fill />
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <FullScreenTolltip tooltipText={tooltipText} handleClose={hide} isOpen={showToolTip} />
        </AnimatedComponent>
    );
}

export default DistributionAskQuestionItem;
