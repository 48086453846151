export const mockInfoBgBlockBlockData = {
    image: '/img-next/png/askQuestions.png',
    imageLink: '',
    sideImg: 'right',
    title: 'Остались вопросы?',
    desc: 'Подробнее о кредитных картах можно узнать в разделе «Часто задаваемые вопросы».',
    smallDesc: 'Описание мелким шрифтом',
    imageTop: true,
    store: {
        list: [
            {
                image: '/img-next/svg/services/AppStore.svg',
                alt: 'App Store',
                link: '#',
            },
            {
                image: '/img-next/svg/services/GooglePlay.svg',
                alt: 'Google Play',
                link: '#',
            },
            {
                image: '/img-next/svg/services/AppGallery.svg',
                alt: 'AppGallery',
                link: '#',
            },
        ],
    },
    link: '#',
    linkName: 'Узнать подробнее',
};

export const mockInfoBgBlockBlockDataTina = {
    titleBlock: 'Заголовок',
    image: 'https://files.pochtabank.ru/mockblock/mock.png',
    imageLink: '',
    sideImg: 'left',
    imageTop: false,
    title: 'Заголовок',
    desc: 'Описание',
    smallDesc: 'Описание мелким шрифтом',
    store: {
        list: [
            {
                image: '',
                alt: 'Описание картинки',
                link: '',
            },
        ],
    },
    link: '#',
    linkName: 'Название',
};
