import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { ButtonBlue } from '@/components/UI/button/ButtonBlue';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IInfoTransitionBlock {
    _template: 'infoTransitionBlock';
    image?: string;
    title: string;
    desc?: string;
    button: {
        title: string;
        link: string;
        gtag?: GTagEvent;
    };
}

function InfoTransitionBlock({ image, title, desc, button }: IInfoTransitionBlock) {
    return (
        <div className={clsx(cn.wrapper, 'section')}>
            <div className={cn.innerWrap}>
                <div className={cn.block}>
                    {image && (
                        <div className={cn.imgWrapper}>
                            <CustomImage src={image} alt={title} fill />
                        </div>
                    )}
                    <div className={cn.blockContent}>
                        <TextField className={cn.blockTitle} text={title} name="title" htmlValue={title} isHTML />
                        {desc && <TextField className={cn.blockDesc} text={desc} name="desc" htmlValue={desc} isHTML />}

                        {button?.link && (
                            <div className={cn.buttonWrapper}>
                                <ButtonBlue
                                    type="link"
                                    size="large"
                                    href={button.link}
                                    label={button.title}
                                    customClass={cn.button}
                                    onClick={() => {
                                        if (button?.gtag?.action) event(button?.gtag);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoTransitionBlock;
