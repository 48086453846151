/* eslint-disable no-shadow */
import { makeAutoObservable, observe } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import TermField, { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';

export enum AdditionSumTakenStatus {
    NOT_WATCHED,
    ACCEPTED,
    REJECTED,
}

export interface ISecondaryPreferentialStore {
    range: IRangeInput;
    termField: ITermField;
    creditValue: number;
    initialized: boolean;
    rate: number;
    boundTerms: number;
    paymentValue: number;
    subRange: IRangeInput;
    version: string;
    additionSumTakenStatus: AdditionSumTakenStatus;
    setAdditionSumTakenStatus: (value: AdditionSumTakenStatus) => void;
}

const MONTHS_IN_YEAR = 12;

class SecondaryPreferentialCalculatorStore implements ISecondaryPreferentialStore {
    public range: IRangeInput;

    public boundTerms: number;

    public termField: ITermField;

    public additionSumTakenStatus: AdditionSumTakenStatus = AdditionSumTakenStatus.NOT_WATCHED;

    public isPreferential = false;

    public version: string;

    public initialized = false;

    private _currSubRange: IRangeInput;

    private rangeDisposer;

    constructor() {
        makeAutoObservable(this);
    }

    get maxSubRangeValue(): number {
        return this.range.elements.at(-1).value - this.range.value;
    }

    get subRange(): IRangeInput {
        if (this.range.elements.at(-1).value === this.range.value) {
            return this._currSubRange;
        }

        return this.createNewSubRange();
    }

    get creditValue(): number {
        if (this.version === 'v3' && this.additionSumTakenStatus === AdditionSumTakenStatus.ACCEPTED) {
            const creditValue = this.range.value + this.subRange.value;
            return creditValue >= this.range.elements.at(-1).value ? this.range.elements.at(-1).value : creditValue;
        }

        return this.range.value;
    }

    public setAdditionSumTakenStatus = (value: AdditionSumTakenStatus) => {
        this.additionSumTakenStatus = value;
    };

    get rate() {
        const ranges = this.termField.activeTerm?.ranges;

        if (ranges?.length) {
            try {
                const activeItemRangeInArray = ranges.filter(
                    range => range.min <= this.creditValue && range.max >= this.creditValue
                );

                return activeItemRangeInArray[0].percent;
            } catch (e) {
                console.error(
                    'Ошибка при парсинге значений из range в калькуляторе рефинансирования',
                    this.creditValue
                );
            }
        }

        if (this.creditValue >= 100000) {
            return 5.9;
        }
        return 8.4;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        if (+this.creditValue <= 30000) {
            const percentRate = 2.8 / 100;

            const total = Math.ceil(((+this.creditValue * percentRate) / 100) * 100);
            // Размер платежа

            return total;
        }

        if (+this.creditValue < 270001 && +this.creditValue > 30000) {
            const { rate } = this;

            const percentRate = rate / 100;

            const total = Math.ceil((+this.creditValue * percentRate) / 100) * 100;
            // Размер платежа

            return total;
        }

        const { rate } = this;
        const percentRate = rate / 100;

        const year = this.termField.value;
        const formattedYear = year * MONTHS_IN_YEAR; // Год в месяцах

        const percentPart = (1 + percentRate / MONTHS_IN_YEAR) ** formattedYear; // 1 + ставка/12 в степени срока
        const creditSumPercent = (+this.creditValue * percentRate) / MONTHS_IN_YEAR; // Cумма кредита * ставку / 12

        const total = Math.round((creditSumPercent * percentPart) / (percentPart - 1));
        // Размер платежа

        return total;
    }

    public init = (
        filterElements: ICalculatorFilterElement[],
        terms: ITermItem[],
        boundTerms: number,
        version: string
    ) => {
        const rangeInitValue = 1500000;

        this.range = new RangeInput({
            name: 'creditValue',
            label: 'Сумма кредита',
            elements: filterElements,
            initValue: rangeInitValue,
        });

        this.version = version;
        if (this.version !== 'v3') {
            this.additionSumTakenStatus = AdditionSumTakenStatus.REJECTED;
        }

        this._currSubRange = this.createNewSubRange();

        this.boundTerms = boundTerms;

        this.termField = new TermField(terms);

        this.rangeDisposer = observe(this.range, change => {
            if (change.type !== 'update' || change.name !== 'value') return;
            this.updateTerms(change.oldValue, change.newValue);
        });

        this.initialized = true;
    };

    private updateTerms = (oldValue: number, newValue: number) => {
        const bound = this.boundTerms;

        if (oldValue < bound && newValue < bound) return;
        if (oldValue > bound && newValue > bound) return;

        if (oldValue > bound && newValue <= bound) {
            this.termField.disableTerms(this.termField.allTerms.filter(term => term.value > 5));
        }
        if (oldValue <= bound && newValue > bound) {
            this.termField.unlockTerms();
        }
    };

    private createNewSubRange = (): RangeInput =>
        new RangeInput({
            name: 'subRange',
            label: 'Дополнительная сумма наличных',
            elements: [
                {
                    value: 1000,
                    step: this.maxSubRangeValue / 100,
                    percent: 0,
                    label: '10 тыс.',
                    id: 1,
                },
                {
                    value: this.maxSubRangeValue,
                    step: this.maxSubRangeValue / 100,
                    percent: 0,
                    label: '10 тыс.',
                    id: 2,
                },
            ],
            initValue: 1000,
        });
}

export default new SecondaryPreferentialCalculatorStore();
