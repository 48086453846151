// ВАЖНО! КЛЮЧ ДОЛЖЕН СОВПАДАТЬ С "TEMPLATE" ЭЛЕМЕНТОВ

import { drawParticipationListBlock } from '@/components/blocks/draw/participation-list/index.tina';
import { mainPrizesBlock } from '@/components/blocks/draw/main-prizes/index.tina';
import { aboutPBBlock } from '@/tina/blocks/aboutPB';
import { actionDetailBlock } from '@/tina/blocks/actionDetail';
import { actionPensionsBlock } from '@/tina/blocks/actionPensions';
import { actionsSliderBlock } from '@/tina/blocks/actionsSlider';
import { advantagesBlock } from '@/tina/blocks/advantages';
import { advantagesBlockV2 } from '@/tina/blocks/advantages-v2';
import { allExchangeRatesBlock } from '@/tina/blocks/allExchangeRates';
import { animatedPromoBlock } from '@/tina/blocks/animatedPromo';
import { applicationAppsBlock } from '@/tina/blocks/applicationApps';
import { articleListBlock } from '@/tina/blocks/articleList';
import { articlesDetailBlock } from '@/tina/blocks/articlesDetail';
import { articlesListForDetailBlock } from '@/tina/blocks/articlesListForDetail';
import { atmListBlock } from '@/tina/blocks/atmList';
import { bankAdvantagesBlock } from '@/tina/blocks/bankAdvantages';
import { bankTextBlock } from '@/tina/blocks/bankText';
import { biographyBlock } from '@/tina/blocks/biography';
import { buildingCalculatorBlock } from '@/tina/blocks/calculators/buildingCalculator';
import { buildingCalculatorWithPictureBlock } from '@/tina/blocks/calculators/buildingCalculatorWithPicture';
import { cashCalculatorBlock } from '@/tina/blocks/calculators/cashCalculator';
import { cashCalculatorBlockV2 } from '@/tina/blocks/calculators/cashCalculatorV2';
import { cashCreditCalculatorBlock } from '@/tina/blocks/calculators/cashCreditCalculator';
import { creditCalculatorBlock } from '@/tina/blocks/calculators/creditCalculator';
import { creditRealEstateCalculatorBlock } from '@/tina/blocks/calculators/creditRealEstateCalculator';
import { guaranteeCalculatorBlock } from '@/tina/blocks/calculators/guaranteeCalculator';
import { hypothecCalculatorBlock } from '@/tina/blocks/calculators/hypothecCalculator';
import { hypothecReadyHousingCalculatorBlock } from '@/tina/blocks/calculators/hypothecreadyHousingCalculator';
import { moneyBoxAccountCalculatorBlock } from '@/tina/blocks/calculators/moneyBoxAccountCalculator';
import { moneyBoxCalculatorBlock } from '@/tina/blocks/calculators/moneyBoxCalculator';
import { preferentialCalculatorBlock } from '@/tina/blocks/calculators/preferentialCalculator';
import { profitabilityCapitalCalculatorBlock } from '@/tina/blocks/calculators/profitabilityCapitalCalculator';
import { profitabilityCumulativeCalculatorBlock } from '@/tina/blocks/calculators/profitabilityCumulativeCalculator';
import { profitabilityHotSeasonsCalculatorBlock } from '@/tina/blocks/calculators/profitabilityHotSeasonsCalculator';
import { profitabilityMonthCalculatorBlock } from '@/tina/blocks/calculators/profitabilityMonthCalculator';
import { profitabilityPensionCalculatorBlock } from '@/tina/blocks/calculators/profitabilityPensionCalculator';
import { profitabilityProfitCalculatorBlock } from '@/tina/blocks/calculators/profitabilityProfitCalculator';
import { rkoCalculatorBlock } from '@/tina/blocks/calculators/rkoCalculator';
import { safeAccountCalculatorBlock } from '@/tina/blocks/calculators/safeAccountCalculator';
import { sberschetCalculatorBlock } from '@/tina/blocks/calculators/sberschetCalculator';
import { secondaryCashCalculatorBlock } from '@/tina/blocks/calculators/secondaryCashCalculator';
import { secondaryPreferentialCalculatorBlock } from '@/tina/blocks/calculators/secondaryPreferentialCalculator';
import { studyCalculatorBlock } from '@/tina/blocks/calculators/studyCalculator';
import { vezdedohodCalculatorBlock } from '@/tina/blocks/calculators/vezdedohodCalculator';
import { campaignBlockBlock } from '@/tina/blocks/campaignBlock';
import { cardsBlock } from '@/tina/blocks/cards';
import { cashbackBlock } from '@/tina/blocks/cashback';
import { cashCreditBlock } from '@/tina/blocks/cashCredit';
import { citiesListBlock } from '@/tina/blocks/citiesList';
import { citiesLoansBlock } from '@/tina/blocks/citiesLoans';
import { coloredBannerBlock } from '@/tina/blocks/coloredBanner';
import { comHrLandingBlock } from '@/tina/blocks/comHrLanding';
import { confirmEmailFormBlock } from '@/tina/blocks/confirmEmailForm';
import { corgisBlock } from '@/tina/blocks/corgis';
import { creditGiftBlockBlock } from '@/tina/blocks/creditGift';
import { creditSliderBlock } from '@/tina/blocks/creditSlider';
import { crmOffersLinkBlockBlock } from '@/tina/blocks/crmOffersLinkBlock';
import { darkBannerBlock } from '@/tina/blocks/darkBanner';
import { diagramBlock } from '@/tina/blocks/diagram';
import { dictionaryBlock } from '@/tina/blocks/dictionary';
import { distributionAskQuestionBlock } from '@/tina/blocks/distributionAskQuestion';
import { errorsBlock } from '@/tina/blocks/Errors';
import { exampleBlock } from '@/tina/blocks/example';
import { faqBlock } from '@/tina/blocks/faq';
import { faqQuestionsBlock } from '@/tina/blocks/faqQuestions';
import { faqThemeBlock } from '@/tina/blocks/faqTheme';
import { feedbackFormBlock } from '@/tina/blocks/feedbackForm';
import { feedbackFormAppealBlock } from '@/tina/blocks/feedbackFormAppeal';
import { feedbackFormBankBlock } from '@/tina/blocks/feedbackFormBank';
import { feedbackFormHotLineBlock } from '@/tina/blocks/feedbackFormHotLine';
import { feedbackFormMortgageBlock } from '@/tina/blocks/feedbackFormMortgage';
import { feedbackFormOffRequestsBlock } from '@/tina/blocks/feedbackFormOffRequests';
import { feedbackFormPretensionBlock } from '@/tina/blocks/feedbackFormPretension';
import { feedbackFormPretensionBlockSecond } from '@/tina/blocks/feedbackFormPretensionSecond';
import { feedbackFormQuestionsBlock } from '@/tina/blocks/feedbackFormQuestions';
import { feedbackFormSalaryCardBlock } from '@/tina/blocks/feedbackFormSalaryCard';
import { feedbackFormSalaryProjectBlock } from '@/tina/blocks/feedbackFormSalaryProject';
import { feedbackFormV2Block } from '@/tina/blocks/feedbackFormV2';
import { feedbackFormVThreeBlock } from '@/tina/blocks/feedbackFormVThree';
import { feedbackUlIpSurveyBlock } from '@/tina/blocks/feedbackUlIpSurvey';
import { forestMapBlock } from '@/tina/blocks/forestMap';
import { formBlockBlock } from '@/tina/blocks/formBlock';
import { groupedTariffsBlock } from '@/tina/blocks/groupedTariff';
import { guaranteedPrizesBlock } from '@/tina/blocks/guaranteedPrizes';
import { guitarBannerBlock } from '@/tina/blocks/guitarBanner';
import { headManagementBlock } from '@/tina/blocks/headManagement';
import { holidayBannerBlock } from '@/tina/blocks/holidayBanner';
import { hrFaqBlock } from '@/tina/blocks/hrFaq';
import { hrLandingBlock } from '@/tina/blocks/hrLanding';
import { indicationBlock } from '@/tina/blocks/indication';
import { infoBlock } from '@/tina/blocks/info';
import { infoBgBlockBlock } from '@/tina/blocks/infoBgBlock';
import { infoBlockCreditBlock } from '@/tina/blocks/infoBlockCredit';
import { infoBlockGradientBlock } from '@/tina/blocks/infoBlockGradient';
import { infoBlockPensiyaBlock } from '@/tina/blocks/infoBlockPensiya';
import { infoCardBlock } from '@/tina/blocks/infoCard';
import { informationCenterBlock } from '@/tina/blocks/informationCenter';
import { infoTransitionBlockBlock } from '@/tina/blocks/infoTransitionBlock';
import { instructionBlock } from '@/tina/blocks/instruction';
import { instructionInfoGraphicBlock } from '@/tina/blocks/instructionInfoGraphic';
import { instructionSliderBlock } from '@/tina/blocks/instructionSlider';
import { insuranceBlock } from '@/tina/blocks/insurance';
import { introPromoBlock } from '@/tina/blocks/introPromo';
import { iosAppLinkBlock } from '@/tina/blocks/iosAppLink';
import { lightBannerBlock } from '@/tina/blocks/lightBanner';
import { linkingListBlock } from '@/tina/blocks/linkingList';
import { linksBlock } from '@/tina/blocks/links';
import { mainArticlesSliderBlock } from '@/tina/blocks/mainArticlesSlider';
import { mainSliderBlock } from '@/tina/blocks/mainSlider';
import { managementBlock } from '@/tina/blocks/management';
import { managementInfoBlock } from '@/tina/blocks/managementInfo';
import { mapContainerForStaticCityBlock } from '@/tina/blocks/mapContainerStaticCity';
import { mediaPublicationsBlock } from '@/tina/blocks/mediaPublications';
import { mobileAppBlock } from '@/tina/blocks/mobileApp';
import { mobileAppBannerBlock } from '@/tina/blocks/mobileAppBanner';
// eslint-disable-next-line import/no-cycle
import { newsBlock } from '@/tina/blocks/news';
import { newYearQuizBlock } from '@/tina/blocks/newYearQuiz';
import { offersCreditsBlock } from '@/tina/blocks/offersCredits';
import { onlineStorePartnersBlock } from '@/tina/blocks/onlineStorePartners';
import { ourTargetsBlock } from '@/tina/blocks/ourTargets';
import { paperWorkBlock } from '@/tina/blocks/paperWork';
import { parametersBondsBlock } from '@/tina/blocks/parametersBonds';
import { partnersBlock } from '@/tina/blocks/partners';
import { partnersMap } from '@/tina/blocks/partnersMap';
import { paymentGroupBlock } from '@/tina/blocks/paymentGroups/_deprecated/paymentGroup';
import { cardApplicationBlock } from '@/tina/blocks/paymentGroups/cardApplication';
import { cardToCardBlock } from '@/tina/blocks/paymentGroups/cardToCard';
import { cashLoanBlock } from '@/tina/blocks/paymentGroups/cashLoan';
import { creditCard } from '@/tina/blocks/paymentGroups/creditCard';
import { creditCardTypecreditBlock } from '@/tina/blocks/paymentGroups/creditCardTypeCredit';
import { creditFormBlock } from '@/tina/blocks/paymentGroups/creditForm';
import { crmOffersBlock } from '@/tina/blocks/paymentGroups/crmOffers';
import { depositApplicationBlock } from '@/tina/blocks/paymentGroups/depositApplication';
import { feedbackFormFrameBlock } from '@/tina/blocks/paymentGroups/feedbackFormFrame';
import { loanRepaymentBlock } from '@/tina/blocks/paymentGroups/loanRepayment';
import { mapBlock } from '@/tina/blocks/paymentGroups/map';
import { mirSupremeCardBlock } from '@/tina/blocks/paymentGroups/mirSupremeCard';
import { pensionTransferBlock } from '@/tina/blocks/paymentGroups/pensionTransfer';
import { pensionTransferV2Block } from '@/tina/blocks/paymentGroups/pensionTransferV2';
import { pushkinskayaCardBlock } from '@/tina/blocks/paymentGroups/pushkinskayaCard';
import { sberschetOpenBlock } from '@/tina/blocks/paymentGroups/sberschetOpen';
import { vddohodBlock } from '@/tina/blocks/paymentGroups/vddohod';
import { vezdedohodCardBlock } from '@/tina/blocks/paymentGroups/vezdedohodCard';
import { vkladDesyatochkaBlock } from '@/tina/blocks/paymentGroups/vkladDesyatochka';
import { payPlatformsBlock } from '@/tina/blocks/payPlatforms';
import { pboFaqBlock } from '@/tina/blocks/pboFaq';
import { pboFaqDetailBlock } from '@/tina/blocks/pboFaqDetail';
import { periodInfoBlock } from '@/tina/blocks/periodInfo';
import { personsBlock } from '@/tina/blocks/persons';
import { photoDateBlock } from '@/tina/blocks/photoDateBlock';
import { photoSliderBlock } from '@/tina/blocks/photoSlider';
import { popularExchangeRatesBlock } from '@/tina/blocks/popularExchangeRates';
import { privateBankingBlock } from '@/tina/blocks/privateBanking';
import { productsAndServicesBlock } from '@/tina/blocks/productsAndServices';
import { promoBlock } from '@/tina/blocks/promo';
import { promoPensionBlock } from '@/tina/blocks/promoPension';
import { promoSecondaryBlock } from '@/tina/blocks/promoSecondary';
import { promoSuperBlock } from '@/tina/blocks/promoSuper';
import { promotionGradientBannerBlock } from '@/tina/blocks/promotionGradientBanner';
import { promoV2Block } from '@/tina/blocks/promoV2';
import { purchasesBlock } from '@/tina/blocks/purchases';
import { pushHrLandingBlock } from '@/tina/blocks/pushHrLanding';
import { questionsBlock } from '@/tina/blocks/questions';
import { quoteHrBlock } from '@/tina/blocks/quoteHr';
import { quotesCarouselBlock } from '@/tina/blocks/quotesCarousel';
import { tinaRefinanceAdvantages } from '@/tina/blocks/refinanceAdvantages';
import { requisitesBlock } from '@/tina/blocks/requisites';
import { reviewsBlock } from '@/tina/blocks/reviews';
import { rightButtonTextBlock } from '@/tina/blocks/rightButtonText';
import { safelyBlock } from '@/tina/blocks/safely';
import { savingsBlock } from '@/tina/blocks/savings';
import { searchResultsPageBlock } from '@/tina/blocks/searchResults';
import { selectableWithValueBlock } from '@/tina/blocks/SelectableWithValue';
import { possibilitiesBlock } from '@/tina/blocks/service';
import { servicesBlock } from '@/tina/blocks/services';
import { singleDocumentsBlock } from '@/tina/blocks/singleDocuments';
import { specialAdvantagesBlock } from '@/tina/blocks/specialAdvantages';
import { stepSelectionBlock } from '@/tina/blocks/stepSelection';
import { stretchBlock } from '@/tina/blocks/stretch';
import { summaryNumbersBlock } from '@/tina/blocks/summaryNumbers';
import { tableBlock } from '@/tina/blocks/table';
import { tariffCompareBlock } from '@/tina/blocks/tariffCompare';
import { tariffInfoBlock } from '@/tina/blocks/tariffInfo';
import { tariffsDocumentsArchiveBlock } from '@/tina/blocks/tariffsDocumentsArchive';
import { tariffsRestrictionsBlock } from '@/tina/blocks/tariffsRestrictions';
import { tariffTabsBlock } from '@/tina/blocks/tariffTabs/_deprecated';
import { documentsBlock } from '@/tina/blocks/tariffTabs/documents';
import { helpBlock } from '@/tina/blocks/tariffTabs/help';
import { tariffBlocksBlock } from '@/tina/blocks/tariffTabs/tariffBlocks';
import { textBlock } from '@/tina/blocks/text';
import { textBannerBlock } from '@/tina/blocks/textBanner';
import { textBlockBlock } from '@/tina/blocks/textBlock';
import { textImageRowBlock } from '@/tina/blocks/textImageRow';
import { threeInARowBlock } from '@/tina/blocks/threeInARow';
import { tinyGradientCategoryBlock } from '@/tina/blocks/tinyGradientCategory';
import { tinyResponsiveCategoryBlock } from '@/tina/blocks/tinyResponsiveCategory';
import { titleBlock } from '@/tina/blocks/title';
import { topChartBlock } from '@/tina/blocks/topChart';
import { universalDetailBlock } from '@/tina/blocks/universalDetail';
import { universalExternalFormScriptBlock } from '@/tina/blocks/universalExternalFormScript';
import { universalExternalIframeBlock } from '@/tina/blocks/universalExternalIframe';
import { universalListBlock } from '@/tina/blocks/universalList';
import { unsubscribeNewsBlock } from '@/tina/blocks/unsubscribeNews';
import { vacancyBlock } from '@/tina/blocks/vacancy';
import { widgetBlock } from '@/tina/blocks/widget';
import { widgetPollBlock } from '@/tina/blocks/widgetPoll';
import { workRemotelyLandingBlock } from '@/tina/blocks/workRemotely';
import { youtubeBlock } from '@/tina/blocks/youtube';
import { youTubeVideosBlock } from '@/tina/blocks/youTubeVideos';
import { ITinaBlocksConfig } from '@/types/tina/config';

import { carCalculatorBlock } from '../blocks/calculators/carCalculator';
import { cashbackPercentage } from '../blocks/cashbackPercentage';
import { flocktoryIntegrationBlock } from '../blocks/flocktoryIntegration';
import { internship2024LandingBlock } from '../blocks/internship2024';
import { tinaMicroTilesBlock } from '../blocks/microTiles';
import { salesHrLandingBlock } from '../blocks/salesHrLanding';
import { tiledShowcaseBlock } from '../blocks/tiledShowcase';

export const tinaBlocksConfig: ITinaBlocksConfig = {
    animatedPromo: animatedPromoBlock,
    advantages: advantagesBlock,
    advantagesV2: advantagesBlockV2,
    info: infoBlock,
    threeInARow: threeInARowBlock,
    stretch: stretchBlock,
    mainSlider: mainSliderBlock,
    services: servicesBlock,
    news: newsBlock,
    links: linksBlock,
    promo: promoBlock,
    instruction: instructionBlock,
    requisites: requisitesBlock,
    title: titleBlock,
    groupedTariffs: groupedTariffsBlock,
    creditCardsList: cardsBlock,
    questions: questionsBlock,
    tariffInfo: tariffInfoBlock,
    offersCredits: offersCreditsBlock,
    linkingList: linkingListBlock,
    citiesList: citiesListBlock,
    textBlock: textBlockBlock,
    faq: faqBlock,
    universalDetail: universalDetailBlock,
    universalList: universalListBlock,
    partners: partnersBlock,
    infoBgBlock: infoBgBlockBlock,
    infoCard: infoCardBlock,
    periodInfo: periodInfoBlock,
    cashback: cashbackBlock,
    // DEPRECATED
    tariffTabs: tariffTabsBlock,
    // DEPRECATED
    paymentGroup: paymentGroupBlock,
    possibilities: possibilitiesBlock,
    safely: safelyBlock,
    managementInfo: managementInfoBlock,
    singleDocuments: singleDocumentsBlock,
    table: tableBlock,
    parametersBonds: parametersBondsBlock,
    tariffsRestrictions: tariffsRestrictionsBlock,
    mobileApp: mobileAppBlock,
    feedbackForm: feedbackFormBlock,
    preferentialCalculator: preferentialCalculatorBlock,
    cashCalculator: cashCalculatorBlock,
    cashCalculatorV2: cashCalculatorBlockV2,
    cashCreditCalculator: cashCreditCalculatorBlock,
    vezdedohodCalculator: vezdedohodCalculatorBlock,
    cardToCard: cardToCardBlock,
    loanRepayment: loanRepaymentBlock,
    cashLoan: cashLoanBlock,
    vddohod: vddohodBlock,
    example: exampleBlock,
    payPlatforms: payPlatformsBlock,
    allExchangeRates: allExchangeRatesBlock,
    popularExchangeRates: popularExchangeRatesBlock,
    depositApplication: depositApplicationBlock,
    cardApplication: cardApplicationBlock,
    sberschetOpen: sberschetOpenBlock,
    pushkinskayaCard: pushkinskayaCardBlock,
    help: helpBlock,
    documents: documentsBlock,
    tariffBlocks: tariffBlocksBlock,
    aboutPB: aboutPBBlock,
    introPromo: introPromoBlock,
    ourTargets: ourTargetsBlock,
    management: managementBlock,
    summaryNumbers: summaryNumbersBlock,
    informationCenter: informationCenterBlock,
    map: mapBlock,
    insurance: insuranceBlock,
    pensionTransfer: pensionTransferBlock,
    headManagement: headManagementBlock,
    biography: biographyBlock,
    mediaPublications: mediaPublicationsBlock,
    indication: indicationBlock,
    bankAdvantages: bankAdvantagesBlock,
    studyCalculator: studyCalculatorBlock,
    rkoCalculator: rkoCalculatorBlock,
    creditCalculator: creditCalculatorBlock,
    sberschetCalculator: sberschetCalculatorBlock,
    applicationApps: applicationAppsBlock,
    forestMap: forestMapBlock,
    youtube: youtubeBlock,
    photoSlider: photoSliderBlock,
    quotesCarousel: quotesCarouselBlock,
    quoteHr: quoteHrBlock,
    bankText: bankTextBlock,
    vacancy: vacancyBlock,
    creditSlider: creditSliderBlock,
    instructionSlider: instructionSliderBlock,
    hrFaq: hrFaqBlock,
    secondaryPreferentialCalculator: secondaryPreferentialCalculatorBlock,
    text: textBlock,
    tariffsDocumentsArchive: tariffsDocumentsArchiveBlock,
    formBlock: formBlockBlock,
    profitabilityCalculatorHotSeasons: profitabilityHotSeasonsCalculatorBlock,
    profitabilityCalculatorCapital: profitabilityCapitalCalculatorBlock,
    profitabilityCalculatorProfit: profitabilityProfitCalculatorBlock,
    profitabilityCalculatorPension: profitabilityPensionCalculatorBlock,
    profitabilityCalculatorCumulative: profitabilityCumulativeCalculatorBlock,
    buildingCalculator: buildingCalculatorBlock,
    buildingCalculatorWithPicture: buildingCalculatorWithPictureBlock,
    dictionary: dictionaryBlock,
    unsubscribeNews: unsubscribeNewsBlock,
    onlineStorePartners: onlineStorePartnersBlock,
    newYearQuiz: newYearQuizBlock,
    errors: errorsBlock,
    mirSupremeCard: mirSupremeCardBlock,
    vezdedohodCard: vezdedohodCardBlock,
    purchases: purchasesBlock,
    feedbackFormFrame: feedbackFormFrameBlock,
    youTubeVideos: youTubeVideosBlock,
    secondaryCashCalculator: secondaryCashCalculatorBlock,
    savings: savingsBlock,
    safeAccountCalculator: safeAccountCalculatorBlock,
    moneyBoxAccountCalculator: moneyBoxAccountCalculatorBlock,
    profitabilityMonthCalculator: profitabilityMonthCalculatorBlock,
    creditcard: creditCard,
    creditForm: creditFormBlock,
    creditCardTypeCredit: creditCardTypecreditBlock,
    dzenPage: articleListBlock,
    universalExternalIframe: universalExternalIframeBlock,
    universalExternalFormScript: universalExternalFormScriptBlock,
    feedbackUlIpSurvey: feedbackUlIpSurveyBlock,
    confirmEmailForm: confirmEmailFormBlock,
    partnersMap,
    articlesDetail: articlesDetailBlock,
    mainArticlesSlider: mainArticlesSliderBlock,
    crmOffers: crmOffersBlock,
    articlesListForDetail: articlesListForDetailBlock,
    crmOffersLinkBlock: crmOffersLinkBlockBlock,
    tariffCompare: tariffCompareBlock,
    selectableWithValue: selectableWithValueBlock,
    diagram: diagramBlock,
    darkBanner: darkBannerBlock,
    lightBanner: lightBannerBlock,
    stepSelection: stepSelectionBlock,
    searchResultsPage: searchResultsPageBlock,
    coloredBanner: coloredBannerBlock,
    promov2: promoV2Block,
    promoSecondary: promoSecondaryBlock,
    creditRealEstateCalculator: creditRealEstateCalculatorBlock,
    holidayBanner: holidayBannerBlock,
    guitarBanner: guitarBannerBlock,
    privateBankingForm: privateBankingBlock,
    mapContainerForStaticCity: mapContainerForStaticCityBlock,
    vkladDesyatochka: vkladDesyatochkaBlock,
    hrLandingForm: hrLandingBlock,
    citiesLoans: citiesLoansBlock,
    actionDetail: actionDetailBlock,
    actionsSlider: actionsSliderBlock,
    paperWork: paperWorkBlock,
    pushHrLandingForm: pushHrLandingBlock,
    comHrLandingForm: comHrLandingBlock,
    salesHrLandingForm: salesHrLandingBlock,
    workRemotelyLanding: workRemotelyLandingBlock,
    advantagesInRefinance: tinaRefinanceAdvantages,
    hypothecCalculator: hypothecCalculatorBlock,
    feedbackFormQuestions: feedbackFormQuestionsBlock,
    feedbackFormBank: feedbackFormBankBlock,
    feedbackFormHotLine: feedbackFormHotLineBlock,
    feedbackFormOffRequests: feedbackFormOffRequestsBlock,
    feedbackFormPretension: feedbackFormPretensionBlock,
    feedbackFormPretensionSecond: feedbackFormPretensionBlockSecond,
    feedbackFormMortgage: feedbackFormMortgageBlock,
    distributionAskQuestion: distributionAskQuestionBlock,
    faqQuestions: faqQuestionsBlock,
    feedbackFormAppeal: feedbackFormAppealBlock,
    infoBlockCredit: infoBlockCreditBlock,
    infoBlockGradient: infoBlockGradientBlock,
    moneyBoxCalculator: moneyBoxCalculatorBlock,
    flocktoryIntegration: flocktoryIntegrationBlock,
    microTiles: tinaMicroTilesBlock,
    reviews: reviewsBlock,
    tiledShowcase: tiledShowcaseBlock,
    topChart: topChartBlock,
    cashbackPercentage,
    carCalculator: carCalculatorBlock,
    blockWidget: widgetBlock,
    productsAndServices: productsAndServicesBlock,
    faqTheme: faqThemeBlock,
    cashCredit: cashCreditBlock,
    textBanner: textBannerBlock,
    promoPension: promoPensionBlock,
    specialAdvantages: specialAdvantagesBlock,
    actionPensions: actionPensionsBlock,
    infoBlockPensiya: infoBlockPensiyaBlock,
    campaignBlock: campaignBlockBlock,
    creditGiftBlock: creditGiftBlockBlock,
    feedbackFormVTwo: feedbackFormV2Block,
    blockWidgetPoll: widgetPollBlock,
    pensionTransferV2: pensionTransferV2Block,
    hypothecReadyHousingCalculator: hypothecReadyHousingCalculatorBlock,
    feedbackFormVThree: feedbackFormVThreeBlock,
    atmList: atmListBlock,
    internship2024: internship2024LandingBlock,
    infoTransitionBlock: infoTransitionBlockBlock,
    instructionInfoGraphic: instructionInfoGraphicBlock,
    rightButtonText: rightButtonTextBlock,
    feedbackFormSalaryCard: feedbackFormSalaryCardBlock,
    feedbackFormSalaryProject: feedbackFormSalaryProjectBlock,
    pboFaq: pboFaqBlock,
    pboFaqDetail: pboFaqDetailBlock,
    promotionGradientBanner: promotionGradientBannerBlock,
    tinyGradientCategory: tinyGradientCategoryBlock,
    tinyResponsiveCategory: tinyResponsiveCategoryBlock,
    mobileAppBanner: mobileAppBannerBlock,
    textImageRow: textImageRowBlock,
    persons: personsBlock,
    photoDateBlock,
    iosAppLink: iosAppLinkBlock,
    drawParticipationList: drawParticipationListBlock,
    guaranteedPrizes: guaranteedPrizesBlock,
    promoSuper: promoSuperBlock,
    guaranteeCalculator: guaranteeCalculatorBlock,
    corgis: corgisBlock,
    mainPrizes: mainPrizesBlock,
};
