export const hrConditionsList = [
    {
        title: '21 МЛН КЛИЕНТОВ',
        subtitle: 'прямо сейчас пользуются финтех-сервисами, которые мы создаем',
    },
    {
        title: 'DIGITAL BANKING',
        subtitle: 'держим курс на развитие диджитал каналов и мобильного приложения',
    },
    {
        title: 'УНИКАЛЬНЫЕ ПРОЕКТЫ',
        subtitle: 'та самая «Пушкинская карта» — наш совместный проект с Минкультуры и Минцифры',
    },
];
