import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IPushkinskayaCard {
    _template: 'pushkinskayaCard';
    title: string;
    gtagClick?: GTagEvent;
}

const PushkinskayaCardContent = dynamic(async () => import('./PushkinskayaCardContent'), {
    ssr: false,
});

const PushkinskayaCard: React.FC<IPushkinskayaCard & { index: number }> = ({ index, title, gtagClick }) => (
    <div className="section">
        <PushkinskayaCardContent title={title} index={index} gtagClick={gtagClick} />
    </div>
);

export default withBlockLinks(PushkinskayaCard);
