export const secondaryPreferentialCalculatorData = {
    title: 'Рассчитайте размер платежа',
    tabTitle: 'Рефинансирование',
    boundTerms: 699999,
    filterElements: [
        {
            value: 20000,
            step: 20000,
            percent: 0,
            label: '20 тыс.',
            id: 1,
        },
        {
            value: 300000,
            step: 10000,
            percent: 20,
            label: '300 тыс.',
            id: 2,
        },
        {
            value: 500000,
            step: 50000,
            percent: 40,
            label: '500 тыс.',
            id: 3,
        },
        {
            value: 1000000,
            step: 100000,
            percent: 60,
            label: '1 млн.',
            id: 4,
        },
        {
            value: 2000000,
            step: 100000,
            percent: 80,
            label: '2 млн.',
            id: 5,
        },
        {
            value: 6000000,
            step: 100000,
            percent: 100,
            label: '6 млн.',
            id: 6,
        },
    ],
    terms: [
        {
            id: 1,
            label: '3 года',
            value: 3,
            ranges: [
                {
                    id: 1,
                    percent: 8.4,
                    min: 20000,
                    max: 499999,
                },
                {
                    id: 2,
                    percent: 8.9,
                    min: 500000,
                    max: 6000000,
                },
            ],
        },
        {
            id: 2,
            label: '4 года',
            value: 4,
            ranges: [
                {
                    id: 1,
                    percent: 8.4,
                    min: 20000,
                    max: 499999,
                },
                {
                    id: 2,
                    percent: 8.9,
                    min: 500000,
                    max: 6000000,
                },
            ],
        },
        {
            id: 3,
            label: '5 лет',
            value: 5,
            ranges: [
                {
                    id: 1,
                    percent: 8.4,
                    min: 20000,
                    max: 499999,
                },
                {
                    id: 2,
                    percent: 8.9,
                    min: 500000,
                    max: 6000000,
                },
            ],
        },
        {
            id: 4,
            label: '6 лет',
            value: 6,
            ranges: [
                {
                    id: 1,
                    percent: 8.4,
                    min: 20000,
                    max: 499999,
                },
                {
                    id: 2,
                    percent: 8.9,
                    min: 500000,
                    max: 6000000,
                },
            ],
        },
        {
            id: 5,
            label: '7 лет',
            value: 7,
            ranges: [
                {
                    id: 1,
                    percent: 8.4,
                    min: 20000,
                    max: 499999,
                },
                {
                    id: 2,
                    percent: 8.9,
                    min: 500000,
                    max: 6000000,
                },
            ],
        },
    ],
    minValue: 20000,
    maxvalue: 6000000,
    infoTitle: {
        ranges: [
            {
                text: 'Предварительный расчёт по кредитной карте «Деньги и Всё!». Не является публичной офертой.',
                min: 0,
                max: 299999,
            },
            {
                text: 'Предварительный расчет с учетом проводимых акций. Не является публичной офертой.',
                min: 0,
                max: 499999,
            },
            {
                text: 'Платёж указан с&nbsp;учётом пересчёта процентов по&nbsp;ставке &nbsp;5.9% годовых с&nbsp;услугой «Гарантированная ставка». Не&nbsp;является публичной офертой.',
                tooltipText:
                    'Погасите не&nbsp;менее 12&nbsp;ежемесячных платежей без просрочек, мы&nbsp;пересчитаем кредит по&nbsp;ставке 5.9% годовых и&nbsp;вернем разницу на&nbsp;ваш Сберегательный счет в&nbsp;дату полного погашения кредита',
                min: 500000,
                max: 6000000,
            },
        ],
        isActive: true,
    },
    gtag: {
        gtagButton: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagSlider: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
        gtagTerm: {
            action: 'click',
            category: 'test',
            label: 'test',
        },
    },
};
