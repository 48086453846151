import { action, makeObservable } from 'mobx';

import AbstractCalculatorStore, { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import Feature from '@/domain/feature/Feature';
import { ITermItem } from '@/types/calculator/config';

interface ICalculatorParams {
    filterElements: ICalculatorFilterElement[];
    terms: ITermItem[];
    minValue: number;
    maxValue: number;
}

interface IValueRate {
    lowRate: number;
    highRate: number;
}

class SecondaryCashCalculatorStore extends AbstractCalculatorStore {
    getRate = () => {
        if (+this.value < 300000) {
            return this.valueRate.highRate;
        }
        if (+this.value >= 300000 && +this.value < 500000) {
            return this.valueRate.lowRate;
        }

        const activeItemRangeInArray = this.activeTerm.ranges.filter(
            range => range.min <= +this.value && range.max >= +this.value
        );

        return activeItemRangeInArray[0].percent;
    };

    isTermDisabled = (termValue: number) => {
        const IS_THREE_YEARS = termValue === 3;
        const IS_FOUR_YEARS = termValue === 4;
        const IS_FIVE_YEARS = termValue === 5;
        const IS_LESS_OTHER_VALUE = +this.value <= 270000;

        const IS_SIX_YEARS = termValue === 6;
        const IS_SEVEN_YEARS = termValue === 7;
        const IS_LESS_VALUE = +this.value <= 499999;
        return (
            ((IS_SIX_YEARS || IS_SEVEN_YEARS) && IS_LESS_VALUE) ||
            ((IS_THREE_YEARS || IS_FOUR_YEARS || IS_FIVE_YEARS) && IS_LESS_OTHER_VALUE)
        );
    };

    getMonthlyPayment = () => {
        const IS_LESS_THEN_REQUIRED = +this.value < this.minValue;
        if (IS_LESS_THEN_REQUIRED || (this.isTermDisabled(this.activeTerm.value) && +this.value > 270000)) return;

        const MONTHS_IN_YEAR = 12;

        const isFeatureEnabled = Feature.isFeatureEnabled('newCashCreditCalculator');

        // Дублирую код, для легкости дебага впоследствии, нужно сделать максимально быстро
        if (isFeatureEnabled) {
            if (+this.value < 500000) {
                const rate = this.getRate();
                const percentRate = rate / 100;

                const total = Math.ceil((+this.value * percentRate) / 100) * 100;
                // Размер платежа

                this.monthlyPayment = {
                    total,
                    rate,
                };
                return;
            }
            const rate = this.getRate();
            const percentRate = rate / 100;

            const year = this.activeTerm.value;
            const formattedYear = year * MONTHS_IN_YEAR; // Год в месяцах

            const percentPart = (1 + percentRate / MONTHS_IN_YEAR) ** formattedYear; // 1 + ставка/12 в степени срока
            const creditSumPercent = (+this.value * percentRate) / MONTHS_IN_YEAR; // Cумма кредита * ставку / 12

            const total = Math.round((creditSumPercent * percentPart) / (percentPart - 1));
            // Размер платежа

            const activeItemRangeInArray = this.activeTerm.ranges.filter(
                range => range.min <= +this.value && range.max >= +this.value
            );

            const { balancePercent } = activeItemRangeInArray[0];

            const balance = +this.value * (balancePercent / 100);

            const balanceSumPercent = (balance * percentRate) / MONTHS_IN_YEAR; // Остаток кредита * ставку / 12

            const balancePercentPart = (1 + percentRate / MONTHS_IN_YEAR) ** 12; // 1 + ставка/12 в степени 12

            const lastYearTotal = Math.round((balanceSumPercent * balancePercentPart) / (balancePercentPart - 1)); // Размер платежа в последний год

            this.monthlyPayment = {
                total: lastYearTotal,
                rate,
            };

            return;
        }

        if (+this.value < 499999) {
            const rate = this.getRate();
            const percentRate = rate / 100;

            const year = this.activeTerm.value;
            const formattedYear = year * MONTHS_IN_YEAR; // Год в месяцах

            const anuitPrecent = percentRate / MONTHS_IN_YEAR;
            const percentPart = (1 + anuitPrecent) ** formattedYear; // 1 + ставка/12 в степени срока

            const total = Math.round(+this.value * (anuitPrecent + anuitPrecent / (percentPart - 1)));
            // Размер платежа

            this.monthlyPayment = {
                total,
                rate,
            };
            return;
        }
        const rate = this.getRate();
        const percentRate = rate / 100;

        const year = this.activeTerm.value;
        const formattedYear = year * MONTHS_IN_YEAR; // Год в месяцах

        const percentPart = (1 + percentRate / MONTHS_IN_YEAR) ** formattedYear; // 1 + ставка/12 в степени срока
        const creditSumPercent = (+this.value * percentRate) / MONTHS_IN_YEAR; // Cумма кредита * ставку / 12

        const total = Math.round((creditSumPercent * percentPart) / (percentPart - 1));
        // Размер платежа

        const activeItemRangeInArray = this.activeTerm.ranges.filter(
            range => range.min <= +this.value && range.max >= +this.value
        );

        const { balancePercent } = activeItemRangeInArray[0];

        const balance = +this.value * (balancePercent / 100);

        const balanceSumPercent = (balance * percentRate) / MONTHS_IN_YEAR; // Остаток кредита * ставку / 12

        const balancePercentPart = (1 + percentRate / MONTHS_IN_YEAR) ** 12; // 1 + ставка/12 в степени 12

        const lastYearTotal = Math.round((balanceSumPercent * balancePercentPart) / (balancePercentPart - 1)); // Размер платежа в последний год

        this.monthlyPayment = {
            total: lastYearTotal,
            rate,
        };
    };

    valueRate: IValueRate;

    constructor(params: ICalculatorParams, valueRate = { lowRate: 2.5, highRate: 3 }) {
        super();

        this.hydrate(params?.filterElements, params?.terms, params?.minValue, params?.maxValue);

        this.valueRate = valueRate;

        makeObservable(this, {
            getMonthlyPayment: action,
            getRate: action,
        });
    }
}

export default SecondaryCashCalculatorStore;
