import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import MoneyBoxAccountCalculatorContent from '@/components/blocks/calculators/layouts/MoneyBoxAccountCalculator/Content';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import MoneyBoxAccountCalculatorStore from '@/domain/calculator/MoneyBoxAccountCalculatorStore';
import { TMoneyBoxAccountCalculator } from '@/types/calculator/moneyBoxAccount';

const MoneyBoxAccountCalculator: React.FC<TMoneyBoxAccountCalculator> = observer(
    ({ title, initRate, filterElements, termsElements, index, gtag, button, enableCheckbox }) => {
        if (!filterElements || !termsElements || !initRate) return null;

        useEffect(() => {
            MoneyBoxAccountCalculatorStore.init(filterElements, termsElements, enableCheckbox, initRate);
        }, []);

        return (
            <CalculatorStoreContext.Provider value={MoneyBoxAccountCalculatorStore}>
                {MoneyBoxAccountCalculatorStore.initialized && (
                    <MoneyBoxAccountCalculatorContent
                        title={title}
                        index={index}
                        gtag={gtag}
                        button={button}
                        enableCheckbox={enableCheckbox}
                        initRate={initRate}
                    />
                )}
            </CalculatorStoreContext.Provider>
        );
    }
);

export default MoneyBoxAccountCalculator;
