import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

export interface IPhotoSliderItem {
    image: string;
    desc: string;
}

const PhotoSliderItem: React.FC<IPhotoSliderItem> = observer(({ image, desc }) => (
    <div className={clsx(cn.currentItem, 'currentItem')}>
        <a href={image} className={cn.currentImage}>
            <CustomImage src={image} alt={desc} width="1000" height="667" />
        </a>
    </div>
));

export default PhotoSliderItem;
