import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

interface IPurchaseItem {
    date: string;
    endDate?: string;
    link: string;
    status: 'active' | 'running' | 'end';
    title: string;
    pos?: number;
}

export interface IPurchases {
    _template?: 'purchases';
    list: IPurchaseItem[];
}

const Purchases: React.FC<IPurchases> = ({ list }) => {
    const [activePagination, setActivePagination] = useState(0);

    const getStatusTitle = (status: IPurchaseItem['status']) => {
        if (!status) return null;
        if (status === 'end') return 'Завершена';
        if (status === 'running') return 'Заключение договора';
        return 'Активна';
    };

    const listChunks = useMemo(() => {
        const listWithPos = list?.map((item, index) => ({ ...item, pos: index }));

        return listWithPos?.reduce((acc: IPurchaseItem[][], item, index) => {
            if (!(index % 4)) {
                return [...acc, listWithPos?.slice(index, index + 4)];
            }
            return acc;
        }, []);
    }, [list]);

    const onPaginationClickFabric = (index: number) => () => {
        setActivePagination(index);
    };

    const onPaginationArrowClickFabric = (type: 'prev' | 'next') => () => {
        if (type === 'prev') {
            setActivePagination(activePagination - 1);
            return;
        }
        setActivePagination(activePagination + 1);
    };

    const hasPrevPagination = activePagination !== 0;
    const hasNextPagination = activePagination + 1 < listChunks.length;

    return (
        <div className="section">
            <AnimatedComponent className={cn.wrapper} classNameActive={cn.animation} key={activePagination}>
                <div className={cn.list}>
                    {listChunks?.[activePagination]?.map(item => (
                        <div className={cn.item} key={item?.pos}>
                            <div className={cn.inner}>
                                <div className={cn.left}>
                                    {item?.date && (
                                        <>
                                            <div className={cn.dateTitle}>Дата извещения</div>
                                            <TextField
                                                text={item?.date}
                                                name={`list.[${item?.pos}].date`}
                                                isTextArea
                                                className={cn.date}
                                            />
                                        </>
                                    )}
                                    {item?.endDate && (
                                        <div className={cn.end}>
                                            <div className={cn.dateTitle}>Дата подведения итогов</div>
                                            <TextField
                                                text={item?.endDate}
                                                name={`list.[${item?.pos}].endDate`}
                                                isTextArea
                                                className={cn.date}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={cn.right}>
                                    <div className={clsx(cn.active, item?.status && cn[`active--${item?.status}`])}>
                                        {getStatusTitle(item?.status)}
                                    </div>
                                    <TextField
                                        text={item?.title}
                                        name={`list.[${item?.pos}].title`}
                                        isTextArea
                                        className={cn.title}
                                    />
                                    <div className={cn.link}>
                                        Запросить:
                                        <a href={item?.link}> Общие сведения, документы</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {listChunks?.length > 1 && (
                    <div className={cn.pagination}>
                        <div
                            role="presentation"
                            onClick={onPaginationArrowClickFabric('prev')}
                            className={clsx(cn.paginationArrow, hasPrevPagination && cn.paginationArrowActive)}
                        />
                        <div className={cn.paginationInner}>
                            {listChunks?.map((_, index) => (
                                <div
                                    key={index}
                                    role="presentation"
                                    onClick={onPaginationClickFabric(index)}
                                    className={clsx(
                                        cn.paginationItem,
                                        activePagination === index && cn.paginationItemActive
                                    )}
                                >
                                    {index + 1}
                                </div>
                            ))}
                        </div>
                        <div
                            role="presentation"
                            onClick={onPaginationArrowClickFabric('next')}
                            className={clsx(
                                cn.paginationArrow,
                                cn.paginationArrowRight,
                                hasNextPagination && cn.paginationArrowActive
                            )}
                        />
                    </div>
                )}
            </AnimatedComponent>
        </div>
    );
};

export default Purchases;
