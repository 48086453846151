import React from 'react';

import { TinaRequisites } from '@/components/blocks/Requisites/index.tina';
import { mockRequisitesBlockDataTina } from '@/data/blocks/requisites';

export const requisitesBlock = {
    Component: props => <TinaRequisites {...props} />,
    template: {
        label: 'Реквизиты',
        defaultItem: () => mockRequisitesBlockDataTina,
        fields: [
            {
                name: 'name',
                component: 'text',
                label: 'Заголовок',
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список элементов',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockRequisitesBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                        required: true,
                    },
                    {
                        label: 'Список субэлементов',
                        name: 'items',
                        component: 'group-list',
                        itemProps: (item: { name: string }, index: number) => ({
                            key: index,
                            label: item.name,
                        }),
                        defaultItem: () => mockRequisitesBlockDataTina.list[0].items[0],
                        fields: [
                            {
                                name: 'name',
                                component: 'text',
                                label: 'Заголовок субэлемента',
                            },
                            {
                                name: 'desc',
                                component: 'textarea',
                                label: 'Описание субэлемента',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
