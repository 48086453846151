import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface ISberschetOpen {
    _template: 'sberschetOpen';
    title: string;
    gtagClick?: GTagEvent;
}

const SberschetOpenContent = dynamic(async () => import('./SberschetOpenContent'), { ssr: false });

const SberschetOpen: React.FC<ISberschetOpen & { index: number }> = ({ index, title, gtagClick }) => (
    <div className="section">
        <SberschetOpenContent title={title} index={index} gtagClick={gtagClick} />
    </div>
);

export default withBlockLinks(SberschetOpen);
