import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ICampaignBlock {
    _template: 'campaignBlock';
    subhead: string;
    image?: string;
    imageAlt?: string;
}

function CampaignBlock({ subhead, image, imageAlt }: ICampaignBlock) {
    return (
        <div className={clsx(cn.campaign, 'section')}>
            <div className={cn.campaignWrap}>
                <div className={cn.campaignBlock}>
                    {image && (
                        <div className={cn.imgWrapper}>
                            <CustomImage src={image} alt={imageAlt} fill />
                        </div>
                    )}
                    <div className={cn.campaignBlockContent}>
                        <TextField
                            className={cn.campaignBlockTitle}
                            text={subhead}
                            name="subhead"
                            htmlValue={subhead}
                            isHTML
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampaignBlock;
