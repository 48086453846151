import { mockSidebarInfoBlockData, mockSidebarInfoBlockDataTina } from '@/data/blocks/sidebar/sidebarInfo';

export const mockAboutPBBlockData = {
    name: 'Дорогие друзья!',
    tabletName: 'О&nbsp;создании Почта Банка',
    desc:
        '31&nbsp;марта 2016&nbsp;г. начал работу &laquo;Почта Банк&raquo;&nbsp;&mdash; новый розничный банк, созданный \n' +
        'Группой ВТБ и&nbsp;ФГУП &laquo;Почта России&raquo;.<br/><br/>' +
        '\n' +
        'Клиентские центры &laquo;Почта Банка&raquo; будут располагаться в&nbsp;отделениях почтовой связи. Филиальная сеть банка станет одной из&nbsp;самых больших в&nbsp;стране и&nbsp;охватит сотни \n' +
        'региональных центров, средних и&nbsp;малых городов.<br/><br/>' +
        '\n' +
        'Благодаря этому банковские услуги станут доступнее для россиян&nbsp;&mdash; чтобы получить \n' +
        'кредит, открыть вклад или сделать перевод, достаточно будет заглянуть в&nbsp;ближайшее отделение &laquo;Почты России&raquo;.<br/><br/>' +
        '\n' +
        'Специально для вас мы&nbsp;снизили ставки по&nbsp;кредитам и&nbsp;создали новые продукты, высокое качество и&nbsp;привлекательные условия которых вы&nbsp;обязательно оцените. Для удобного управления счетом работают интернет- и&nbsp;мобильный банк на&nbsp;платформе Android и&nbsp;iOS. \n' +
        '&laquo;Почта Банк&raquo; будет активно работать с&nbsp;пенсионерами: помимо выплаты пенсий, \n' +
        'они получат доступ к&nbsp;кредитам и&nbsp;вкладам на&nbsp;особых условиях.<br/><br/>' +
        '\n' +
        'Все ранее заключенные договоры по&nbsp;кредитам и&nbsp;вкладам &laquo;Лето Банка&raquo;, <br/>' +
        'а&nbsp;также приобретенные продукты наших партнеров продолжат действовать <br/>' +
        'без каких-либо изменений.<br/><br/>' +
        '\n' +
        'Приглашаем вас в&nbsp;новый банк на&nbsp;&laquo;Почту России&raquo;!',
    sidebarInfo: mockSidebarInfoBlockData,
    quote: {
        img: '/img-next/png/aboutPB/personQuote.png',
        personName: 'Дмитрий Руденко',
        personPosition: 'президент-председатель правления &laquo;Почта Банка&raquo;.',
        text:
            '&laquo;Мы&nbsp;постарались учесть все нюансы работы почты, проанализировали финансовые потребности ее&nbsp;посетителей, бизнес-модели иностранных почтовых банков, учли тенденции рынка. Пилотный проект, прошедший осенью в&nbsp;отделениях почтовой связи в&nbsp;Москве и&nbsp;Московской области, признан успешным. Мы&nbsp;приложим максимум усилий для того, чтобы обеспечить индивидуальный подход к&nbsp;своим клиентам \n' +
            'и&nbsp;оправдать доверие акционеров&raquo;.',
    },
    list: [
        {
            img: '/img-next/png/aboutPB/1.png',
            personName: 'Николай Никифоров',
            personPosition: 'Министр связи и массовых коммуникаций Российской Федерации.',
            text: 'Проект не предусматривает никаких бюджетных средств. Более того, в течение восьми лет мы должны заплатить почте 47 миллиардов рублей за пользование инфраструктурой. Эти деньги пойдут на развитие самой почты. Мы планируем, что за первые три года запустим 15 тысяч офисов, а в дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
        },
        {
            img: '/img-next/png/aboutPB/2.png',
            personName: 'Андрей Костин',
            personPosition: 'президент-председатель правления Банка ВТБ.',
            text: 'Проект не предусматривает никаких бюджетных средств. Более того, в течение восьми лет мы должны заплатить почте 47 миллиардов рублей за пользование инфраструктурой. Эти деньги пойдут на развитие самой почты. Мы планируем, что за первые три года запустим 15 тысяч офисов, а в дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
        },
        {
            img: '/img-next/png/aboutPB/3.png',
            personName: 'Михаил Задорнов',
            personPosition: 'президент-председатель правления ВТБ24.',
            text: 'Проект не предусматривает никаких бюджетных средств. Более того, в течение восьми лет мы должны заплатить почте 47 миллиардов рублей за пользование инфраструктурой. Эти деньги пойдут на развитие самой почты. Мы планируем, что за первые три года запустим 15 тысяч офисов, а в дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
        },
        {
            img: '/img-next/png/aboutPB/4.png',
            personName: 'Дмитрий Страшнов',
            personPosition: 'генеральный директор ФГУП «Почта России».',
            text: 'Проект не предусматривает никаких бюджетных средств. Более того, в течение восьми лет мы должны заплатить почте 47 миллиардов рублей за пользование инфраструктурой. Эти деньги пойдут на развитие самой почты. Мы планируем, что за первые три года запустим 15 тысяч офисов, а в дальнейшем будем стремиться, конечно, чтобы все офисы могли предоставлять базовые услуги.',
        },
    ],
};

export const mockAboutPBBlockTinaData = {
    section: null,
    name: 'Заголовок',
    tabletName: 'Заголовок',
    desc: 'Описание',
    sidebarInfo: mockSidebarInfoBlockDataTina,
    quote: {
        img: '',
        personName: 'Имя',
        personPosition: 'Описание',
        text: 'Описание',
    },
    list: [
        {
            img: '',
            personName: 'Имя',
            personPosition: 'Должность',
            text: 'Описание',
        },
    ],
};
