import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import VezdedohodCalculatorContent from '@/components/blocks/calculators/layouts/VezdedohodCalculator/Content';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import CashbackCalculatorStore from '@/domain/calculator/CashbackCalculatorStore';
import { IFieldsConfig } from '@/types/calculator/config';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IVezdedohodCalculator {
    _template?: 'vezdedohodCalculator';
    title?: string;
    tabTitle?: string;
    fields: IFieldsConfig;
    button?: {
        label: string;
        link: string;
    };
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
    maxCache: number;
    maxPercent: number;
    isPencilNeeded?: boolean;
}

const VezdedohodCalculator: React.FC<IVezdedohodCalculator> = observer(
    ({ title, fields, gtag, maxCache, button, maxPercent, isPencilNeeded = true }) => {
        if (!fields || !maxCache) return null;

        useEffect(() => {
            CashbackCalculatorStore.init(fields, maxCache, maxPercent);
        }, []);

        return (
            <CalculatorStoreContextNew.Provider value={CashbackCalculatorStore}>
                {CashbackCalculatorStore.initialized && (
                    <VezdedohodCalculatorContent
                        isPencilNeeded={isPencilNeeded}
                        title={title}
                        gtag={gtag}
                        button={button}
                    />
                )}
            </CalculatorStoreContextNew.Provider>
        );
    }
);

export default VezdedohodCalculator;
