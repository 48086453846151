import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import AdvantageItem from '@/components/blocks/advantages/Advantages/item';
import CustomSwiper from '@/components/CustomSwiper';
import { useResize } from '@/hooks/useResize';
import { IAdvantagesFilteredItems } from '@/types/advantages/item';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    list: IAdvantagesFilteredItems;
}

const AdvantagesList: React.FC<IProps> = observer(({ list }) => {
    const width = useResize();

    const isDesktop = width > TABLET_WIDTH && typeof window !== 'undefined';

    const slidesPerView = useMemo(() => {
        if (width < MOBILE_SM_WIDTH) return 1.15;
        if (width < MOBILE_WIDTH) return 1.32;
        if (!isDesktop) return 2.3;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView,
        watchOverflow: true,
    };

    return (
        <div className={clsx(cn.advantagesGrid)}>
            {isDesktop ? (
                <div className={cn.advantagesList}>
                    {list.desktop?.map(item => (
                        <AdvantageItem {...item} key={`main-${item.id || item.pos}`} pos={item.pos} />
                    ))}
                </div>
            ) : (
                <>
                    <div className={cn.advantagesList}>
                        {list?.mobile?.main?.map(item => (
                            <AdvantageItem {...item} key={`main-${item.id || item.pos}`} pos={item.pos} />
                        ))}
                    </div>
                    <div
                        className={clsx(
                            cn.advantagesList,
                            cn.advantagesSlider,
                            list.mobile.main?.length <= 0 && cn.advantagesSliderNoMargin
                        )}
                    >
                        <CustomSwiper {...settings}>
                            {list?.mobile?.slider.map(item => (
                                <SwiperSlide key={`mobile-${item.id || item.pos}`}>
                                    <AdvantageItem {...item} pos={item.pos} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                    </div>
                </>
            )}
        </div>
    );
});

export default AdvantagesList;
