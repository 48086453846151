import React from 'react';

import SalesHROffersList from '@/components/blocks/SalesHRLanding/core/SalesHROffers/List';
import { Offers } from '@/components/blocks/SalesHRLanding/types/offers';

type SalesHROffersProps = {
    offers: Offers;
};

function SalesHROffers({ offers }: SalesHROffersProps) {
    return <SalesHROffersList offers={offers} />;
}

export default SalesHROffers;
