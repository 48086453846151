import React from 'react';

import { TinaCreditSlider } from '@/components/blocks/CreditSlider/index.tina';
import { mockCreditSliderBlockDataTina } from '@/data/blocks/creditSlider';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const creditSliderBlock = {
    Component: props => <TinaCreditSlider {...props} />,
    template: {
        label: 'Кредитный слайдер',
        defaultItem: () => mockCreditSliderBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'disableSlider',
                component: 'toggle',
                label: 'Выключить слайдер',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockCreditSliderBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'subtitle',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'infoTitle',
                        component: HtmlEditorFeatured,
                        label: 'Подзаголовок',
                    },
                    {
                        name: 'infoDesc',
                        component: HtmlEditorFeatured,
                        label: 'Доп. описание',
                    },
                ],
            },
            { ...gtagSingleItem },
        ],
    },
};
