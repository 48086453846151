import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState, VFC } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { Clusterer, Map, Placemark, YMaps } from 'react-yandex-maps';

import AnimatedComponent from '@/components/Animation';
import BalloonCustom from '@/components/blocks/PartnersMap/balloon';
import FilterMap from '@/components/blocks/PartnersMap/FilterMap';
import TextField from '@/components/TextField';
import { useCity } from '@/context/CityProvider';

import cn from './style.module.sass';

export interface IPartnersMap {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'partnersMap';
    name?: string;
    title?: string;
    partnerList: PartnerItem[];
}

export type PartnerItem = {
    id: number;
    coords: string | [string, string];
    logoPartner: string;
    namePartner: string;
    partnerLink: string;
    city: string;
    mp?: string;
    regionalCenter: string;
    address: string;
    openingHours?: string;
    phones: string;
    specialization: string;
    statementLink?: string;
    webCode?: string;
};

const getPartnerCoords = (coords: string | [string, string] | number[]) => {
    let x = 55.75;
    let y = 37.57;

    if (coords && typeof coords === 'string') {
        const arr = coords.split(',');
        if (arr.length === 2) {
            const [xx, yy] = arr;
            x = +xx;
            y = +yy;
        }
    }

    if (coords && Array.isArray(coords)) {
        const [xx, yy] = coords;

        x = +xx;
        y = +yy;
    }

    return [x, y];
};

const processKeyToSameFormat = (key: string) => key.charAt(0).toUpperCase() + key.slice(1).toLocaleLowerCase();

const PartnersMap: VFC<IPartnersMap> = observer(({ partnerList = [], title }) => {
    const { currentCity } = useCity();

    const [state, setState] = useState({ balloonData: null, ymaps: null });
    const [stateCheckbox, setStateCheckbox] = useState<Record<string, boolean>>(() =>
        partnerList?.reduce((acc, checkboxItem) => {
            const rawKey = checkboxItem.specialization.split('(')[0].trim();
            if (rawKey.length) {
                const key = processKeyToSameFormat(rawKey);
                acc[key] = true;
            }
            return acc;
        }, {})
    );
    const [stateSelect, setStateSelect] = useState<string>(null);

    const mapRef = useRef(null);
    const boxRef = useRef<HTMLDivElement | null>(null);

    const selectRegionOptions = useMemo(() => {
        const regionalCenterList = partnerList?.map(item => item.regionalCenter);
        return [...Array.from(new Set(regionalCenterList))].sort();
    }, [partnerList]);

    const filteredPartners = useMemo(
        () =>
            partnerList
                ?.filter(item => {
                    const rawKey = item.specialization.split('(')[0].trim();
                    const key = processKeyToSameFormat(rawKey);
                    if (stateSelect === 'Все') return stateCheckbox[key];
                    return !stateSelect
                        ? stateCheckbox[key]
                        : item.regionalCenter === stateSelect && stateCheckbox[key];
                })
                ?.map(item => {
                    const [x, y] = getPartnerCoords(item.coords);

                    if (!x || !y) return null;

                    return { ...item, coords: [x, y] };
                })
                ?.filter(Boolean),
        [partnerList, stateCheckbox, stateSelect]
    );

    const handleSelect = ({ value }) => {
        setStateSelect(value);
    };

    const handleCheckbox = code => {
        setStateCheckbox(() => ({ ...stateCheckbox, [code]: !stateCheckbox[code as string] }));
    };

    const createTemplateLayoutFactory = (ymaps, Component) => {
        const html = ReactDOMServer.renderToString(<Component />);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (ymaps?.templateLayoutFactory) {
            setState({
                balloonData: null,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                ymaps: ymaps.templateLayoutFactory.createClass(`<div class="test-balloon" >${html}</div>`),
            });
        }
    };

    const onLoad = ymaps => {
        createTemplateLayoutFactory(ymaps, BalloonCustom);
    };

    const onBalloonOpen = item => () => {
        const balloonDOM = document?.querySelector('.test-balloon');
        if (balloonDOM && item) {
            ReactDOM.render(<BalloonCustom {...item} />, balloonDOM);
        }
    };

    const defaultCityCoords = [55.75, 37.57];
    const currentCoords = useMemo(() => {
        if (!stateSelect || stateSelect === 'Все') {
            return [currentCity.latitude, currentCity.longitude] ?? defaultCityCoords;
        }

        return getPartnerCoords(filteredPartners[0]?.coords);
    }, [filteredPartners, currentCity]);

    useEffect(() => {
        const handler = () => {
            const body = document.querySelector('.balloonBody');
            const arrow = document.querySelector('.balloonArrow');
            body.classList.toggle('balloonBodyOpen');
            if (arrow) {
                arrow?.classList.toggle('balloonArrowOpen');
            }
        };
        if (mapRef.current && boxRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            mapRef.current.events.add(['balloonopen'], () => {
                boxRef.current.querySelector('.globalArrow').addEventListener('click', handler);
            });
        }
    }, [mapRef.current, boxRef.current]);

    return (
        <AnimatedComponent
            className={clsx(cn.partnersMap, 'section')}
            classNameActive={cn.animationInit}
            role="presentation"
        >
            <div className={cn.partnersMapWrap}>
                {title && (
                    <TextField
                        text={title}
                        className={cn.mainTitle}
                        name="title"
                        customTag="h3"
                        isHTML
                        htmlValue={title}
                    />
                )}

                <div className={cn.partnersMapBox} ref={boxRef}>
                    <FilterMap
                        stateCheckbox={stateCheckbox}
                        handleCheckbox={handleCheckbox}
                        selectOptions={selectRegionOptions}
                        handleSelect={handleSelect}
                    />
                    <YMaps>
                        <Map
                            state={{
                                center: currentCoords,
                                zoom: 10,
                            }}
                            className={cn.map}
                            onLoad={onLoad}
                            modules={['templateLayoutFactory']}
                            instanceRef={ref => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                mapRef.current = ref;
                            }}
                        >
                            <Clusterer
                                options={{
                                    hasBalloon: false,
                                    hasHint: false,
                                    gridSize: 256,
                                    maxZoom: 15,
                                }}
                            >
                                {filteredPartners?.length > 0 &&
                                    filteredPartners?.map(item => (
                                        <Placemark
                                            key={item.id}
                                            geometry={item.coords}
                                            options={{
                                                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                                                balloonContentLayout: state.ymaps,
                                                iconOffset: [Math.random() * 80, Math.random() * 80],
                                                hideIconOnBalloonOpen: false,
                                            }}
                                            modules={['geoObject.addon.balloon']}
                                            onBalloonOpen={onBalloonOpen(item)}
                                        />
                                    ))}
                            </Clusterer>
                        </Map>
                    </YMaps>
                </div>
            </div>
        </AnimatedComponent>
    );
});

export default PartnersMap;
