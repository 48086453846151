import clsx from 'clsx';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Title from '@/components/UI/Title';
import { IRequisitesItem } from '@/types/requisites/item';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IRequisites {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'requisites';
    /**
     * Список категорий
     */
    name: string;
    list: IRequisitesItem[];
}

const Requisites: React.FC<IRequisites> = ({ name, list }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <div className={clsx(cn.requisites, 'section')}>
            <div className={cn.requisitesWrap}>
                <Title title={name} level={1} name="name" isTextArea />
                {list?.map(({ name: sectionName, items }, index) => (
                    <AnimatedComponent
                        className={clsx(cn.requisitesRow)}
                        classNameActive={cn.animationInit}
                        key={index}
                    >
                        <div className={clsx(cn.requisitesItem, cn.requisitesItemTitle)}>
                            <TextField
                                text={sectionName}
                                name={`list.${index}.name`}
                                isTextArea
                                className={cn.requisitesTitle}
                            />
                        </div>
                        <div className={clsx(cn.requisitesItem, cn.requisitesItemInfo)}>
                            {items?.map(({ name: itemName, desc }, itemIndex) => (
                                <AnimatedComponent
                                    className={clsx(cn.requisitesInfo)}
                                    classNameActive={cn.animationInit}
                                    key={itemIndex}
                                >
                                    <div className={cn.requisitesInfoWrap}>
                                        <div className={clsx(cn.requisitesInfoItem, cn.requisitesInfoItemSubtitle)}>
                                            <TextField
                                                text={itemName}
                                                name={`list.[${index}].items.[${itemIndex}].name`}
                                                isTextArea
                                                className={cn.requisitesInfoSubtitle}
                                            />
                                        </div>
                                        <div className={clsx(cn.requisitesInfoItem, cn.requisitesInfoItemDesc)}>
                                            <TextField
                                                text={desc}
                                                name={`list.[${index}].items.[${itemIndex}].desc`}
                                                isHTML
                                                htmlValue={desc}
                                                className={cn.requisitesInfoDesc}
                                            />
                                        </div>
                                    </div>
                                </AnimatedComponent>
                            ))}
                        </div>
                    </AnimatedComponent>
                ))}
            </div>
        </div>
    );
};

export default withBlockLinks(Requisites);
