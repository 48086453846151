import clsx from 'clsx';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Feature from '@/domain/feature/Feature';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IPaperwork {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'paperWork';
    image: string;
    title: string;
    buttonText?: string;
    content: string;
    gtag?: GTagEvent;
}

function PaperWork({ image, title, buttonText, content, gtag }: IPaperwork) {
    const [isOpen, setIsOpen] = useState(false);

    const getTrigger = (trigger: string) => <div className={clsx(cn.triggerWrap)}>trigger</div>;

    if (!Feature.isFeatureEnabled('enablePaperWork')) {
        return null;
    }

    return (
        <AnimatedComponent className={clsx(cn.paperWork, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.paperWorkWrap}>
                <div className={clsx(cn.paperWorkGrid)}>
                    <div className={clsx(cn.paperWorkItem, cn.paperWorkItemContent)}>
                        <div className={clsx(cn.paperWorkContent)}>
                            <div className={cn.paperWorkTop}>
                                {title && (
                                    <TextField
                                        text={title}
                                        className={cn.paperWorkTitle}
                                        name="title"
                                        htmlValue={title}
                                        isHTML
                                    />
                                )}
                            </div>
                            <div className={clsx(cn.paperWorkBot, cn.hideOnMobile)}>
                                <Button
                                    variant="btnWhite"
                                    type="button"
                                    label={buttonText || 'Смотрите здесь'}
                                    size="large"
                                    customClass="full"
                                    onClick={() => {
                                        setIsOpen(true);

                                        if (gtag?.action) event(gtag);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={clsx(cn.paperWorkItem, cn.paperWorkItemImg)}>
                        <div className={clsx(cn.paperWorkImage)}>
                            <div className={clsx(cn.paperWorkImageContainer)}>
                                <CustomImage src={image} alt={title} fill />
                            </div>
                        </div>
                    </div>
                    <div className={clsx(cn.paperWorkItem, cn.paperWorkItemBtn, cn.showOnMobile)}>
                        <Button
                            variant="btnWhite"
                            type="button"
                            label={buttonText || 'Смотрите здесь'}
                            size="large"
                            customClass="full"
                            onClick={() => {
                                setIsOpen(true);

                                if (gtag?.action) event(gtag);
                            }}
                        />
                    </div>
                </div>

                <Collapsible
                    className={cn.itemCollapsible}
                    openedClassName={clsx(cn.itemCollapsible, cn.open)}
                    trigger={getTrigger(title)}
                    triggerOpenedClassName={cn.open}
                    triggerTagName="div"
                    transitionTime={300}
                    easing="ease-in-out"
                    open={isOpen}
                >
                    <div className={cn.itemCollapsibleContent}>
                        <div className={cn.itemCollapsibleContentInner} dangerouslySetInnerHTML={{ __html: content }} />
                        <Button
                            variant="btnBlue"
                            type="button"
                            label="Свернуть"
                            animation={false}
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        />
                    </div>
                </Collapsible>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(PaperWork);
