import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export interface ILightBanner {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'lightBanner';
    title?: string;
    text?: string;
    leftImage?: string;
    rightImage?: string;
}

const LightBanner: React.FC<ILightBanner> = ({ title, text, leftImage, rightImage }) => {
    if (!Feature.isFeatureEnabled('enableLightBanner')) {
        return null;
    }

    return (
        <div className={clsx('section')}>
            <div className={cn.fullWrapper}>
                <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                    {leftImage && (
                        <div className={clsx(cn.leftImage, cn.imageWrap)}>
                            <CustomImage src={leftImage} alt="Почта Банк" mockWidth="300" mockHeight="156" fill />
                        </div>
                    )}
                    <div className={cn.textWrapper}>
                        {title && <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />}
                        <div className="flctry-time" />
                        {text && <TextField className={cn.text} text={text} name="text" isHTML htmlValue={text} />}
                    </div>
                    {rightImage && (
                        <div className={clsx(cn.rightImage, cn.imageWrap)}>
                            <CustomImage src={rightImage} alt="Почта Банк" mockWidth="300" mockHeight="300" fill />
                        </div>
                    )}
                </AnimatedComponent>
            </div>
        </div>
    );
};

export default withBlockLinks(LightBanner);
