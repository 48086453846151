import React, { FC } from 'react';

import TextField from '@/components/TextField';
import Feature from '@/domain/feature/Feature';

import MicroTileCard, { IMicroTileCard } from './MicroTileCard';
import cn from './style.module.sass';

export interface IMicroTiles {
    _template: 'microTiles';
    titleBlock: string;
    list: IMicroTileCard[];
}

const MicroTiles: FC<IMicroTiles> = ({ _template, titleBlock, list }) => {
    if (!Feature.isFeatureEnabled('enableMicroTiles')) {
        return null;
    }

    return (
        <div className="section">
            <div className={cn.wrapper}>
                <TextField text={titleBlock} className={cn.title} name="titleBlock" htmlValue={titleBlock} isHTML />
                <div className={cn.cards}>
                    {list?.map((item, idx) => (
                        <MicroTileCard
                            key={idx}
                            image={item.image}
                            listLink={item.listLink}
                            listName={item.listName}
                            width={item.width}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MicroTiles;
