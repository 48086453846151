import { motion, useTransform } from 'framer-motion';
import React, { useRef } from 'react';

import SpecialAdvantageItem from '@/components/blocks/advantages/SpecialAdvantages/item';
import { ISpecialAdvantagesItem } from '@/types/specialAdvantages';

import cn from './style.module.sass';

interface IProps extends ISpecialAdvantagesItem {
    pos: number;
    i: number;
    progress: any;
    range: any;
    targetScale: any;
    tooltipText?: string;
    offset?: number;
}

function SpecialAdvantageItemMobileWrapper({
    title,
    subtitle,
    image,
    pos,
    i,
    progress,
    range,
    targetScale,
    tooltipText,
    offset,
}: IProps) {
    const container = useRef(null);

    const scale = useTransform(progress, range, [1, targetScale]);

    return (
        <div ref={container} className={cn.cardContainer} style={{ top: `${offset}px` }}>
            <motion.div style={{ scale, top: `calc(${i * 10}px)` }} className={cn.card}>
                <SpecialAdvantageItem
                    title={title}
                    subtitle={subtitle}
                    image={image}
                    pos={pos}
                    tooltipText={tooltipText}
                />
            </motion.div>
        </div>
    );
}

export default SpecialAdvantageItemMobileWrapper;
