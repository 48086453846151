import React from 'react';

import { TinaPromotionGradientBanner } from '@/components/blocks/PromotionGradientBanner/index.tina';
import { mockPromotionGradientBannerTinaData } from '@/data/blocks/promotionGradientBanner';

export const tinaPromotionGradientBannerConfig = [
    {
        name: 'text',
        component: 'text',
        label: 'Заголовок на десктопе',
    },
    {
        name: 'mobileText',
        component: 'text',
        label: 'Заголовок на адаптиве',
    },
    {
        name: 'button',
        component: 'text',
        label: 'Название кнопки',
    },
    {
        name: 'link',
        component: 'text',
        label: 'Ссылка',
    },
    {
        name: 'image',
        component: 'image',
        label: 'Картинка',
        parse: (media: { src: string }) => media.src,
    },
];

export const promotionGradientBannerBlock = {
    Component: props => <TinaPromotionGradientBanner {...props} />,
    template: {
        label: 'Баннер-Предложение на градиентном фоне',
        defaultItem: () => ({
            promotion: mockPromotionGradientBannerTinaData.promotion,
        }),
        fields: [
            {
                name: 'promotion',
                component: 'group',
                label: 'Предложение',
                fields: tinaPromotionGradientBannerConfig,
            },
        ],
    },
};
