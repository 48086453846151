import React from 'react';

import { ITag } from '@/types/tags';

import cn from '../style.module.sass';

interface IProps {
    xmlId?: string;
    tagList: ITag[];
    elementTags: string[];
    onClick: (xmlId: string) => void;
}

const ArticlesTagList: React.FC<IProps> = ({ tagList, elementTags, onClick }) => {
    if (!elementTags || elementTags.length <= 0) return null;
    const currentValues = tagList.map(element => element.xmlId);
    const currentTags = elementTags.filter(value => currentValues.includes(value));

    return (
        <div className={cn.articleTags}>
            <div className={cn.articleTagTitle}>Теги:</div>
            <div className={cn.articleTagList}>
                {tagList.map(tag => {
                    if (currentTags.includes(tag.xmlId))
                        return (
                            <button
                                type="button"
                                key={tag.id}
                                onClick={() => onClick(tag.xmlId)}
                                className={cn.articleTag}
                            >
                                {tag.value}
                            </button>
                        );
                    return null;
                })}
            </div>
        </div>
    );
};
export default ArticlesTagList;
