import clsx from 'clsx';
import React, { FC } from 'react';

import TextField from '@/components/TextField';

import OneSpaceList from './OneSpaceList';
import { IShowcaseCard } from './ShowcaseCard';
import SliderList from './SliderList';
import cn from './style.module.sass';

export interface ITiledShowcase {
    _template: 'tiledShowcase';
    list?: IShowcaseCard[];
    titleBlock: string;
    button?: {
        title: string;
        link: string;
    };
}

const TiledShowcase: FC<ITiledShowcase> = ({ _template, list, titleBlock, button }) => (
    <div className={clsx('section')}>
        <div className={cn.wrapper}>
            <div className={cn.topWrap}>
                <TextField
                    className={cn.title}
                    text={titleBlock}
                    customTag="h2"
                    name="titleBlock"
                    isHTML
                    htmlValue={titleBlock}
                />
                {button?.title && (
                    <div className={cn.buttonWrapper}>
                        <div className={cn.button}>
                            <a href={button?.link} className={cn.buttonInner}>
                                {button?.title}
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <SliderList list={list} />
            <OneSpaceList list={list} />
        </div>
    </div>
);

export default TiledShowcase;
