import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import ViewIcon from '@/components/UI/ViewCount/viewIcon';
import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IResponse {
    data: {
        data: number;
        errors: string[];
        status: string;
    };
}

const ViewCount: React.FC = () => {
    const [countViews, setCountViews] = useState<number>(0);

    const pathname = usePathname();

    const sendData = async () => {
        try {
            const response: IResponse = await apiRequest(`articles/newviews`);

            if (response.data.errors?.length > 0) throw new Error(JSON.stringify(response.data.errors[0]));

            setCountViews(response.data.data);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const views = JSON.parse(window.localStorage?.getItem('views')) || [];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            views.push(pathname);
            window.localStorage?.setItem('views', JSON.stringify(views));
        } catch (e) {
            console.error(e);
        }
    };

    const fetchData = async () => {
        try {
            const response: IResponse = await apiRequest(`articles/views`);

            if (response.data.errors?.length > 0) throw new Error(JSON.stringify(response.data.errors[0]));

            setCountViews(response.data.data || 0);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData().catch(console.error);
    }, []);

    useIsomorphicEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const views = JSON.parse(window.localStorage?.getItem('views')) || [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (!views.includes(pathname)) {
            sendData().catch(console.error);
        }
    }, []);

    return (
        <div className={clsx(cn.button)} role="presentation">
            <ViewIcon />
            <div className={cn.text}>{countViews}</div>
        </div>
    );
};

export default ViewCount;
