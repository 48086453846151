import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import TextField from '@/components/TextField';
import { ISingleDocumentsItem } from '@/types/singleDocuments';

import cn from './style.module.sass';

const Item: React.FC<ISingleDocumentsItem & { pos: number }> = ({
    pos,
    documentType,
    document,
    permanentDocument,
    documentName,
}) => (
    <AnimatedComponent className={cn.documentsLink} classNameActive={cn.animationInit}>
        <div className={cn.documentsGrid}>
            <a
                href={permanentDocument || document}
                target="_blank"
                rel="noopener noreferrer"
                className={cn.documentsWrap}
            >
                <div className={clsx(cn.documentsItem, cn.documentsItemIcon, cn[`documentsItem-${documentType}`])} />
                <div className={cn.documentsItem}>
                    <TextField
                        text={documentName}
                        name={`documents[${pos}].documentName`}
                        className={cn.documentsText}
                        isTextArea
                    />
                </div>
            </a>
        </div>
    </AnimatedComponent>
);
export default Item;
