import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import AnimatedComponent from '../../Animation';
import cn from './style.module.sass';

export interface IPayPlatforms {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'payPlatforms';
    title: string;
    list: IPlatformsItem[];
}
interface IPlatformsItem {
    img: string;
    alt: string;
    link: string;
}

const PayPlatforms: React.FC<IPayPlatforms> = ({ title, list }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent className={clsx(cn.payPlatforms, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.payPlatformsWrap}>
                <div className={clsx(cn.payPlatformsGrid)}>
                    <div className={clsx(cn.payPlatformsItem, cn.payPlatformsItemContent)}>
                        <TextField
                            text={title}
                            name="title"
                            isHTML
                            htmlValue={title}
                            className={cn.payPlatformsTitle}
                        />
                    </div>
                    <div className={clsx(cn.payPlatformsItem, cn.payPlatformsItemImg)}>
                        <div className={cn.img}>
                            {list?.map(({ img, alt, link }, index) => (
                                <a className={cn.imgItem} href={link} key={index}>
                                    <div className={cn.imgItemWrap}>
                                        <CustomImage src={img} alt={alt} fill mockWidth="120" mockHeight="41" />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(PayPlatforms);
