import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import ReviewsContent from '@/components/blocks/Reviews/content';
import ModalWrapper from '@/components/modal/ModalWrapper';
import { IReviewsItem } from '@/types/reviews';

import cn from './style.module.sass';

const ReviewsItem: React.FC<IReviewsItem> = observer(({ name, text, date, type = 'debet-card', value }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <div className={clsx(cn.currentItem, 'currentItem')} onClick={handleOpen} role="presentation">
                <div className={clsx(cn.currentItemWrap)}>
                    <ReviewsContent name={name} text={text} value={value} date={date} type={type} flag />
                </div>
            </div>

            <ModalWrapper
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                handleOpen={handleOpen}
                wrapperClassName={clsx(cn.modalWrapper, 'modalMiddleWrapper')}
                bgClassName={cn.modalOverlay}
                className={cn.modalInner}
            >
                <div className={cn.modalContent}>
                    <ReviewsContent name={name} text={text} value={value} date={date} type={type} />
                </div>
            </ModalWrapper>
        </>
    );
});

export default ReviewsItem;
