import React from 'react';

import { TinaMediaPublications } from '@/components/blocks/mediaPublications/MediaPublications/index.tina';
import { mockMediaPublicationsDataTina } from '@/data/blocks/mediaPublicatons';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const mediaPublicationsBlock = {
    Component: props => <TinaMediaPublications {...props} />,
    template: {
        defaultItem: () => mockMediaPublicationsDataTina,
        label: 'Публикации в СМИ',
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'person',
                label: 'О ком публикации',
                component: 'group',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockMediaPublicationsDataTina.person,
                fields: [
                    {
                        name: 'name',
                        component: HtmlEditorFeatured,
                        label: 'Имя',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Фото',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },

            {
                component: 'group-list',
                name: 'list',
                label: 'Блоки',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockMediaPublicationsDataTina.list[0],
                fields: [
                    {
                        name: 'date',
                        component: 'text',
                        label: 'Год',
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок слайда',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'logo',
                        component: 'image',
                        label: 'Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
