import React from 'react';

import { TinaCashbackPercentage } from '@/components/blocks/CashbackPercentage/index.tina';
import { mockCashBackPercentageBlockData } from '@/data/blocks/cashbackPercentage';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const cashbackPercentage = {
    Component: props => <TinaCashbackPercentage {...props} />,
    template: {
        label: 'Проценты кешбека',
        defaultItem: () => mockCashBackPercentageBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                component: 'select',
                name: 'background',
                label: 'Фон карточек',
                required: true,
                options: [
                    {
                        value: 'white',
                        label: 'Белая',
                    },
                    {
                        value: 'blue',
                        label: 'Синий',
                    },
                    {
                        value: 'gradient',
                        label: 'Градиент',
                    },
                ],
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип (количество колонок)',
                options: [
                    {
                        value: 'col-3',
                        label: '3 колонки',
                    },
                    {
                        value: 'col-4',
                        label: '4 колонки',
                    },
                    {
                        value: 'col-5',
                        label: '5 колонок',
                    },
                ],
            },
            {
                name: 'limit',
                component: 'number',
                label: 'Лимит',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                }),
                defaultItem: () => mockCashBackPercentageBlockData.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'cashbackSize',
                        component: 'text',
                        label: 'Размер кешбека',
                        required: true,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Логотип.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'imageDarkTheme',
                        component: 'image',
                        label: 'Логотип. Темная тема',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
