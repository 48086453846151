import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import CalculatorStoreContext from '@/context/CalculatorStoreContext';
import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';

import cn from './style.module.sass';

// Дубляж, убрать при получении данных с сервака

interface IProps {
    filterElement: ICalculatorFilterElement;
    isRedesign?: boolean;
}

const Pip: React.FC<IProps> = observer(({ filterElement, isRedesign }) => {
    const { percent, label, value } = filterElement;
    const { setValue, getMonthlyPayment } = useContext(CalculatorStoreContext);

    const formatStyle = () => {
        if (percent === 0) return { left: '0%' };
        if (percent === 100) return { right: '0%' };
        return { left: `${percent}%` };
    };

    const onClick = () => {
        setValue(value.toString());
        getMonthlyPayment();
    };

    return (
        <div role="presentation" onClick={onClick} className={clsx(cn.pip, cn.visible)} style={formatStyle()}>
            <div className={clsx(cn.label, isRedesign && cn.visible)}>{label}</div>
        </div>
    );
});

export default Pip;
