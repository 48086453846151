export const mockServicesBlockData = {
    name: 'Сервисы',
    mobile: {
        _template: 'mobileService',
        mobile: {
            title: 'Мобильное приложение',
            subtitle: 'Перейдите по ссылке, чтобы использовать веб-версию',
            mobileSubtitle: 'Перейдите по ссылке, чтобы использовать веб-версию',
            qrCode: {
                image: '/img-next/png/services/qr.png',
                imageDarkTheme: '/img-next/png/services/qr-white.png',
                text: 'Отсканируйте QR-код чтобы скачать приложение',
            },
            textLink: 'my.pochtabank.ru',
            link: '#',
            isLink: true,
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
            store: [
                {
                    image: '/img-next/svg/services/AppStore.svg',
                    name: 'App Store',
                    link: '#',
                },
                {
                    image: '/img-next/svg/services/GooglePlay.svg',
                    name: 'Google Play',
                    link: '#',
                },
                {
                    image: '/img-next/svg/services/AppGallery.svg',
                    name: 'AppGallery',
                    link: '#',
                },
            ],
            image: '/img-next/png/services/mobail.png',
        },
    },
    bank: {
        _template: 'bankService',
        bank: {
            title: 'Интернет-банк',
            subtitle: 'Управляйте всеми услугами банка не выходя из дома',
            mobileSubtitle: 'Перейдите по ссылке, чтобы использовать веб-версию',
            image: '/img-next/png/services/bank.png',
            textLink: 'my.pochtabank.ru',
            link: 'my.pochtabank.ru',
            isLink: true,
            mobailDisplay: true,
        },
    },
};

export const mockServicesBlockTinaData = {
    section: null,
    name: 'Сервисы',
    mobile: {
        _template: 'mobileService',
        section: null,
        mobile: {
            title: 'Заголовок',
            subtitle: 'Описание',
            mobileSubtitle: 'Описание для мобильной версии',
            qrCode: {
                image: '',
                imageDarkTheme: '',
                text: 'Отсканируйте QR-код чтобы скачать приложение',
            },
            textLink: 'Название',
            link: '#',
            isLink: true,
            gtag: {
                action: 'click',
                category: '',
                label: '',
            },
            store: [
                {
                    image: '',
                    name: 'Название',
                    link: '#',
                },
                {
                    image: '',
                    name: 'Название',
                    link: '#',
                },
                {
                    image: '',
                    name: 'Название',
                    link: '#',
                },
            ],
            image: '',
        },
    },
    bank: {
        _template: 'bankService',
        section: null,
        bank: {
            title: 'Заголовок',
            subtitle: 'Описание',
            mobileSubtitle: 'Описание для мобильной версии',
            image: '',
            textLink: 'Название',
            link: '#',
            isLink: true,
            mobailDisplay: true,
        },
    },
};
