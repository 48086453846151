export const mockDarkBannerBlockData = {
    title: 'Билеты по Пушкинской <a href="#">карте</a>',
    text: '5 000 Пушкинских рублей для оплаты билетов в театры для молодых людей от 14 до 22 лет',
    desktopImage: '/img-next/png/banners/darkBannerDesktopImage.png',
    tabletImage: '/img-next/png/banners/darkBannerTabletImage.png',
    mobileImage: '/img-next/png/banners/darkBannerMobileImage.png',
    name: 'darkBanner',
};

export const mockDarkBannerBlockWithoutImageData = {
    title: '',
    text: 'Купить билеты на культурные события в твоем регионе можно в <a href="#">Почта Банк. Билеты</a>',
    desktopImage: '',
    tabletImage: '',
    mobileImage: '',
    name: 'darkBanner',
};

export const mockDarkBannerBlockDataTina = {
    title: 'Заголовок',
    text: 'Текст',
    desktopImage: '',
    tabletImage: '',
    mobileImage: '',
    name: 'darkBanner',
};
