import React from 'react';

import { TinaYouTubeVideos } from '@/components/blocks/youTubeVideos/YouTubeVideos/index.tina';
import { mockYouTubeVideosBlockDataTina } from '@/data/blocks/youTubeVideos';

export const youTubeVideosBlock = {
    Component: props => <TinaYouTubeVideos {...props} />,
    template: {
        label: 'YouTube ролики',
        defaultItem: () => mockYouTubeVideosBlockDataTina,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockYouTubeVideosBlockDataTina.list[0],
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'buttonColor',
                        component: 'select',
                        label: 'Выбор цвета кнопки',
                        options: [
                            {
                                value: 'dark',
                                label: 'Черный',
                            },
                            {
                                value: 'light',
                                label: 'Белый',
                            },
                        ],
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка',
                    },
                    {
                        name: 'previewImage',
                        component: 'image',
                        label: 'Изображение',
                        description: 'По умолчанию используется превью изображение с YouTube',
                        parse: (media: { src: string }) => media.src,
                    },
                ],
            },
        ],
    },
};
