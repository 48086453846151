import React from 'react';

import { TinaErrors } from '@/components/Errors/index.tina';
import { mockErrorsBlockTinaData } from '@/data/blocks/errors';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const errorsBlock = {
    Component: props => <TinaErrors {...props} />,
    template: {
        label: 'Страница не найдена',
        defaultItem: () => mockErrorsBlockTinaData,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: HtmlEditorFeatured,
                label: 'Описание',
            },
            {
                name: 'img',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index) => ({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockErrorsBlockTinaData.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Название',
                    },
                    {
                        name: 'link',
                        component: 'text',
                        label: 'Ссылка.',
                    },
                ],
            },
        ],
    },
};
