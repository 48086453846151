import React from 'react';

import cn from './style.module.sass';

function Arrows() {
    return (
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L6 6L11 1"
                className={cn.svgStroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Arrows;
