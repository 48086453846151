import clsx from 'clsx';
import React from 'react';

import GuaranteedPrizesItem from '@/components/blocks/GuaranteedPrizes/Item';
import { IThreeInARowItem } from '@/components/blocks/ThreeInARow/ThreeInARowItem';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IGuaranteedPrizes {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'guaranteedPrizes';
    name?: string;
    description?: string;
    /**
     * Список блоков.
     */
    list: IThreeInARowItem[];
}

function GuaranteedPrizes({ list, name, description }: IGuaranteedPrizes) {
    return (
        <div className={clsx(cn.blockWrap, 'section')}>
            <div className={cn.wrap}>
                {name && <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />}
                {description && (
                    <TextField
                        text={description}
                        name="description"
                        className={cn.description}
                        isHTML
                        htmlValue={description}
                    />
                )}

                {list?.length > 0 && (
                    <div className={cn.itemsWrapper}>
                        {list?.map((item, index) => (
                            <GuaranteedPrizesItem {...item} key={index} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
export default withBlockLinks(GuaranteedPrizes);
