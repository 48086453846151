import clsx from 'clsx';
import React, { FC } from 'react';

import { ISliderArrow } from '@/types/tariffCompare/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const NextSliderArrow: FC<ISliderArrow> = ({ gtag, listLength, swiperRef, isDisabled }) => {
    if (listLength < 2) return null;

    const onClick = () => {
        swiperRef.current.swiper.slideNext();

        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight, isDisabled && cn.customArrowDisabled)}
        />
    );
};

export default NextSliderArrow;
