import { IInfoTransitionBlock } from '@/components/blocks/InfoTransitionBlock';

export const mockInfoTransitionBlockData: IInfoTransitionBlock & { section: number } = {
    section: null,
    _template: 'infoTransitionBlock',
    image: '',
    desc: 'В привычном браузере на компьютере или смартфоне',
    title: 'Интернет-банк',
    button: {
        title: 'Войти',
        link: '/',
    },
};

export const mockInfoTransitionBlockTinaData: IInfoTransitionBlock & { section: number } = {
    section: null,
    _template: 'infoTransitionBlock',
    image: '',
    desc: 'Описание',
    title: 'Заголовок',
    button: {
        title: 'Название кнопки',
        link: '/',
    },
};
