import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import ManagementInfoItemModalContent from '@/components/blocks/managementInfo/ManagementInfoItemModalContent';
import CustomImage from '@/components/CustomImage';
import ModalWrapper from '@/components/modal/ModalWrapper';
import TextField from '@/components/TextField';
import { IManagementItem } from '@/types/managementInfo/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IAdditionalProps {
    pos: number;
}

const ManagementInfoItem: React.FC<IManagementItem & IAdditionalProps> = ({
    big,
    name,
    subtitle,
    tags,
    desc,
    image,
    pos,
    link,
    gtag,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <AnimatedComponent className={clsx(cn.item, big && cn.itemBig)} classNameActive={cn.animationInit}>
            <div className={cn.itemGrid}>
                {image && (
                    <div className={cn.imageWrapper}>
                        <div className={cn.image}>
                            <CustomImage src={image} alt={name} fill mockWidth="96" mockHeight="96" />
                        </div>
                    </div>
                )}
                <div className={cn.content}>
                    <div className={cn.contentTop}>
                        <TextField
                            text={name}
                            name={`list.[${pos}].name`}
                            isHTML
                            htmlValue={name}
                            className={cn.name}
                        />
                        <TextField
                            text={subtitle}
                            name={`list.[${pos}].subtitle`}
                            isHTML
                            htmlValue={subtitle}
                            className={cn.desc}
                        />
                    </div>
                    <div className={cn.contentBottom}>
                        {link ? (
                            <a
                                className={cn.itemLink}
                                onClick={() => {
                                    if (gtag?.action) event(gtag);
                                }}
                                href={link}
                            >
                                Биография
                            </a>
                        ) : (
                            <ModalWrapper
                                isOpen={isOpen}
                                handleClose={handleClose}
                                handleOpen={handleOpen}
                                className={cn.otherView}
                                bgClassName={cn.otherViewBg}
                                trigger={
                                    // eslint-disable-next-line
                                    <div
                                        className={cn.itemLink}
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                        role="presentation"
                                    >
                                        Биография
                                    </div>
                                }
                            >
                                {isOpen && (
                                    <ManagementInfoItemModalContent
                                        name={name}
                                        tags={tags}
                                        desc={desc}
                                        big={big}
                                        subtitle={subtitle}
                                        image={image}
                                        pos={pos}
                                    />
                                )}
                            </ModalWrapper>
                        )}
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};
export default ManagementInfoItem;
