import { makeAutoObservable, observe } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';
import TermField, { ITermField } from '@/domain/calculator/blocksStore/Term';
import { ITermItem } from '@/types/calculator/config';

export interface IPreferentialStore {
    range: IRangeInput;
    termField: ITermField;
    creditValue: number;
    isPreferential: boolean;
    initialized: boolean;
    rate: number;
    guaranteedRate: number;
    refundSum: number;
    paymentValue: number;
    overpayment: number;
    setPreferentialStatus: (value: boolean) => void;
}

interface IValueRate {
    id: number;
    percentMin: number;
    percentMax: number;
    minSum: number;
    maxSum: number;
}

interface IGuaranteedRateElements {
    percentDefault: number;
    percentMin: number;
    percentMax: number;
    maxSum: number;
}

const MONTHS_IN_YEAR = 12;

class PreferentialCalculatorStore implements IPreferentialStore {
    public range: IRangeInput;

    public termField: ITermField;

    public isPreferential = false;

    public initialized = false;

    private rangeDisposer;

    private valueRate: IValueRate[];

    private guaranteedRateElements: IGuaranteedRateElements;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    get rate() {
        const { percentMin, percentMax } = this.valueRate.find(
            rate => rate.minSum <= this.creditValue && rate.maxSum >= this.creditValue
        );
        return this.isPreferential ? percentMin : percentMax;
    }

    get guaranteedRate() {
        if (!this.isPreferential) return this.guaranteedRateElements.percentDefault;
        return this.creditValue > this.guaranteedRateElements.maxSum
            ? this.guaranteedRateElements.percentMin
            : this.guaranteedRateElements.percentMax;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;

        const percentRate = this.rate / 100 / MONTHS_IN_YEAR;
        const power = this.termField.value * 12 * -1;
        const d = 1 - (1 + percentRate) ** power;

        return Math.trunc(this.creditValue * (percentRate / d));
    }

    get overpayment() {
        if (this.creditValue > this.range.maxValue) return 0;

        const paymentsSum = this.paymentValue * this.termField.value * MONTHS_IN_YEAR;
        return paymentsSum - this.creditValue;
    }

    get refundSum() {
        return Math.round((this.overpayment / this.rate) * (this.rate - this.guaranteedRate));
    }

    public init = (
        filterElements: ICalculatorFilterElement[],
        terms: ITermItem[],
        valueRate: IValueRate[],
        guaranteedRateElements: IGuaranteedRateElements
    ) => {
        const rangeInitValue = 500000;

        this.range = new RangeInput({
            name: 'creditValue',
            title: 'Сумма кредита',
            elements: filterElements,
            initValue: rangeInitValue,
        });

        this.valueRate = valueRate;

        this.guaranteedRateElements = guaranteedRateElements;

        this.termField = new TermField(terms);

        this.termField.disableTerms(this.termField.allTerms.filter(term => term.value > 5));

        this.rangeDisposer = observe(this.range, change => {
            if (change.type !== 'update' || change.name !== 'value') return;
            this.updateTerms(change.oldValue, change.newValue);
        });

        this.initialized = true;
    };

    public setPreferentialStatus = (value: boolean) => {
        this.isPreferential = value;
    };

    private updateTerms = (oldValue: number, newValue: number) => {
        const bound = 500000;

        if (oldValue < bound && newValue < bound) return;
        if (oldValue > bound && newValue > bound) return;

        if (oldValue > bound && newValue <= bound) {
            this.termField.disableTerms(this.termField.allTerms.filter(term => term.value > 5));
        }
        if (oldValue <= bound && newValue > bound) {
            this.termField.unlockTerms();
        }
    };
}

export default new PreferentialCalculatorStore();
