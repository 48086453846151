export const mockTariffCompareBlockData = {
    title: 'Сравнение тарифов',
    list: [
        {
            id: 1,
            title: 'Тариф «Суперсмарт Промо»',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 5,5% годовых с услугой «Гарантированная ставка»',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
        {
            id: 2,
            title: 'Тариф &laquo;Практичный-Промо&raquo;',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 16,9% годовых — базовая ставка',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
        {
            id: 3,
            title: 'Тариф «Суперсмарт Промо» 222',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 5,5% годовых с услугой «Гарантированная ставка»',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
        {
            id: 4,
            title: 'Тариф &laquo;Практичный-Промо&raquo; 333',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 16,9% годовых — базовая ставка',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
        {
            id: 5,
            title: 'Тариф «Суперсмарт Промо» 444',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 5,5% годовых с услугой «Гарантированная ставка»',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
        {
            id: 6,
            title: 'Тариф &laquo;Практичный-Промо&raquo; 555',
            characteristics: [
                {
                    title: 'Процентная ставка',
                    value: 'От 16,9% годовых — базовая ставка',
                },
                {
                    title: 'Сумма кредита',
                    value: '1 000 001 — 6 000 000 ₽',
                },
                {
                    title: 'Ежемесячный платеж',
                    value: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    title: 'Срок кредита',
                    value: '36 — 84 месяца',
                },
                {
                    title: 'Финансовая защита',
                    value: 'Программы финансовой защиты с широким покрытием.',
                },
            ],
        },
    ],
    button: {
        label: 'Оставить заявку',
        link: '#',
    },
};

export const mockTariffCompareBlockDataTina = {
    title: 'Заголовок',
    list: [
        {
            id: 1,
            title: 'Название',
            characteristics: [
                {
                    title: 'Название',
                    value: 'Значение',
                },
                {
                    title: 'Название',
                    value: 'Значение',
                },
                {
                    title: 'Название',
                    value: 'Значение',
                },
            ],
        },
    ],
    button: {
        label: 'Заголовок',
        link: '#',
    },
};
