import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import Filter from '@/components/blocks/universalList/Filter';
import UniversalItem from '@/components/blocks/universalList/UniversalItem';
import Button from '@/components/UI/Button';
import Preloader from '@/components/UI/Preloader';
import { useAppStore } from '@/context/AppStoreContext';
import { IUniversalListData, IUniversalListPagination, IUniversalListResponse } from '@/types/universalList';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IUniversalList {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'newsPage';
    /**
     * Карточки новостей
     */
    apiCode: string;
    section: number;
    links: IUniversalListData['links'];
    list: IUniversalListData['list'];
    navigation: IUniversalListPagination;
}

const UniversalList: React.FC<IUniversalList> = observer(
    ({ _template, apiCode, section, list, navigation: fetchedNavigation, links }) => {
        const [path] = useAppStore(store => store.path);
        const [isTinaView] = useAppStore(store => store.isTinaView);

        const [elementsList, setElementsList] = useState<IUniversalListData['list']>(list);
        const [navigation, setNavigation] = useState<IUniversalListPagination>(fetchedNavigation);
        const [isDataFetching, setIsDataFetching] = useState(false);
        const [isAdditionalDataFetching, setIsAdditionalDataFetching] = useState(false);

        // GET params
        const sectionPart = `&section=${section || 0}`;
        const infoBlockPart = `&apiCode=${apiCode}`;

        const requestDataWithParams = async (...args) => {
            try {
                const response: IUniversalListResponse = await apiRequest(
                    `/page/block?path=${path}&block=universalList${args.join('')}`
                );

                const {
                    data: { data: fetchedData },
                } = response;

                return fetchedData;
            } catch (e) {
                console.error(e);
                return null;
            }
        };

        const setPreloader = (isAdditionalData, state) => {
            if (isAdditionalData) {
                setIsAdditionalDataFetching(state);
                return;
            }
            setIsDataFetching(state);
        };

        const setData = async (
            params,
            shouldSetActiveFilters,
            shouldSetFilters,
            shouldSetNavigation,
            isAdditionalInfo
        ) => {
            try {
                setPreloader(isAdditionalInfo, true);

                const data = await requestDataWithParams(...params);
                if (shouldSetNavigation) setNavigation(data?.navigation);
                if (isAdditionalInfo && data?.list?.length) {
                    setElementsList([...elementsList, ...data.list]);
                    setPreloader(true, false);
                    return;
                }
                setElementsList(data?.list);
                setPreloader(false, false);
            } catch (e) {
                console.error(e);
                setPreloader(isAdditionalInfo, false);
            }
        };

        const onMoreClick = async () => {
            const requestParams = [sectionPart, infoBlockPart, `&nav-${apiCode}=page-${navigation.currentPage + 1}`];
            await setData(requestParams, false, false, true, true);
        };

        const onFilterClick = () => {
            setIsDataFetching(true);
        };

        useEffect(() => {
            if (!apiCode || !isTinaView) return;
            const requestParams = [sectionPart, infoBlockPart];
            setData(requestParams, true, true, true, false).catch(console.error);
        }, [section, apiCode]);

        const showMoreButton = useMemo(() => {
            if (navigation) {
                return navigation.currentPage + 1 <= navigation?.pageCount;
            }
            return false;
        }, [navigation]);

        return (
            <div className={clsx(cn.news, 'section')}>
                <div className={cn.newsWrap}>
                    <div className={cn.newsGrid}>
                        <div className={clsx(cn.newsItem, cn.newsItemList)}>
                            <Filter filter={links} onFilterClick={onFilterClick} />
                            <AnimatedComponent className={cn.list} classNameActive={cn.animationInit}>
                                {isDataFetching && <Preloader />}
                                {!isDataFetching &&
                                    elementsList?.map((universalElement, index) => (
                                        <UniversalItem key={index} item={universalElement} />
                                    ))}
                                {isAdditionalDataFetching && <Preloader />}
                                {showMoreButton && (
                                    <div className={cn.listBtn}>
                                        <Button
                                            variant="btnBlueBorder"
                                            type="button"
                                            label="Показать ещё"
                                            customClass={clsx(cn.btnAll)}
                                            darkWhite
                                            onClick={onMoreClick}
                                        />
                                    </div>
                                )}
                            </AnimatedComponent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default UniversalList;
