import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
// eslint-disable-next-line import/no-cycle
import { IPushHRVacancyListItem } from '@/components/blocks/PushHRLanding/HRVacancy';
// eslint-disable-next-line import/no-cycle
import HRVacancyModal from '@/components/blocks/PushHRLanding/HRVacancy/HRVacancyModal';
import VacancyButton from '@/components/blocks/PushHRLanding/HRVacancy/VacancyButton';
import ModalWrapper from '@/components/modal/ModalWrapper';

import cn from './style.module.sass';

export interface IPushHRVacancyListItemInner extends IPushHRVacancyListItem {
    handler?: (theme: string) => void;
}

function HRVacancyItem({ title, subtitle, handler, description, linkShare, link }: IPushHRVacancyListItemInner) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className={cn.hrVacancyItem}>
            <AnimatedComponent className={clsx(cn.hrVacancyItemWrap)} classNameActive={cn.animationInit}>
                <div className={cn.hrVacancyItemTitle} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={cn.hrVacancyItemSubtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                <div className={cn.hrVacancyItemBottom}>
                    <VacancyButton type="button" label="Посмотреть" isDark onClick={handleOpen} />
                    <ModalWrapper
                        isOpen={isOpen}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        className={cn.otherView}
                        bgClassName={cn.otherViewBg}
                        wrapperClassName={cn.modalWrapper}
                    >
                        <HRVacancyModal
                            linkShare={linkShare}
                            title={title}
                            subtitle={subtitle}
                            link={link}
                            description={description}
                            handler={handler}
                            handleClose={handleClose}
                        />
                    </ModalWrapper>
                </div>
            </AnimatedComponent>
        </div>
    );
}

export default HRVacancyItem;
