import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { IWidgetContent } from '@/components/blocks/Widget/Content/innerContent';
import Feature from '@/domain/feature/Feature';
import { Cookie } from '@/utils/cookie';

interface IWidget extends IWidgetContent {
    _template: 'blockWidget';
}

const WidgetContent = dynamic(async () => import('./Content'));

function Widget({ top, image, isSetImage, mark, filterElements, termsInfo }: IWidget) {
    const [isTimeView, setIsTimeView] = useState(false);

    let shouldInitialize = true;

    if (typeof window !== 'undefined') {
        const cookieValue = Cookie.getCookie('closeWidget');
        shouldInitialize = cookieValue !== 'true';
    }

    useEffect(() => {
        setTimeout(() => {
            setIsTimeView(true);
        }, 3000);
    }, []);

    if (!Feature.isFeatureEnabled('enableWidget')) {
        return null;
    }

    if (shouldInitialize && isTimeView)
        return (
            <WidgetContent
                top={top}
                image={image}
                isSetImage={isSetImage}
                mark={mark}
                filterElements={filterElements}
                termsInfo={termsInfo}
            />
        );

    return null;
}

export default Widget;
