import { IReviewsConfig } from '@/types/reviews';

export const configReview: IReviewsConfig = {
    'debet-card': {
        title: 'Дебетовая карта',
        image: '/img-next/png/reviews/debet-card.png',
    },
    'credit-card': {
        title: 'Кредитная карта',
        image: '/img-next/png/reviews/credit-card.png',
    },
    credit: {
        title: 'Кредит наличными',
        image: '/img-next/png/reviews/credit.png',
    },
    vklad: {
        title: 'Вклады',
        image: '/img-next/png/reviews/vklad.png',
    },
};

export const mockReviewsBlockData = {
    title: 'Отзывы',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    disableSlider: false,
    list: [
        {
            name: 'Федоров Виталий Сергеевич',
            value: 3.5,
            text:
                'Добрый день!Я оформлял кредит в&nbsp;вашем банке, номер договора 57572141, который в&nbsp;январе 2023 был успешно полностью закрыт. Не&nbsp;так давно пытался сделать рефинансирование другого действующего кредита в&nbsp;другом банке и&nbsp;получил отказ по&nbsp;скоринг, решил заказать отчет в&nbsp;БКИ и&nbsp;на&nbsp;сколько&nbsp;же было мое удивление, когда в&nbsp;незавершенных кредитах я&nbsp;увидел кредит вашего банка, который я&nbsp;уже данным-давно закрыл! Так еще этот кредит тянет дополнительную нагрузку! Что за&nbsp;такие дела творятся в&nbsp;вашем банке, что вы&nbsp;не&nbsp;передаете информацию в&nbsp;БКИ о&nbsp;закрытых кредитах?!\n' +
                '\n' +
                'Требую скорректировать кредитную историю и&nbsp;как можно скорее!',
            date: '2023-02-08T00:00:00Z',
            type: 'debet-card',
        },
        {
            name: 'Левашенков Александр Александрович',
            value: 5,
            text:
                'Добрый день!Я оформлял кредит в&nbsp;вашем банке, номер договора 57572141, который в&nbsp;январе 2023 был успешно полностью закрыт. Не&nbsp;так давно пытался сделать рефинансирование другого действующего кредита в&nbsp;другом банке и&nbsp;получил отказ по&nbsp;скоринг, решил заказать отчет в&nbsp;БКИ и&nbsp;на&nbsp;сколько&nbsp;же было мое удивление, когда в&nbsp;незавершенных кредитах я&nbsp;увидел кредит вашего банка, который я&nbsp;уже данным-давно закрыл! Так еще этот кредит тянет дополнительную нагрузку! Что за&nbsp;такие дела творятся в&nbsp;вашем банке, что вы&nbsp;не&nbsp;передаете информацию в&nbsp;БКИ о&nbsp;закрытых кредитах?!\n' +
                '\n' +
                'Требую скорректировать кредитную историю и&nbsp;как можно скорее!',
            date: '2023-02-08T00:00:00Z',
            type: 'debet-card',
        },
        {
            name: 'Иванова Элеонора Сергеевна',
            value: 2.5,
            text:
                'Добрый день!Я оформлял кредит в&nbsp;вашем банке, номер договора 57572141, который в&nbsp;январе 2023 был успешно полностью закрыт. Не&nbsp;так давно пытался сделать рефинансирование другого действующего кредита в&nbsp;другом банке и&nbsp;получил отказ по&nbsp;скоринг, решил заказать отчет в&nbsp;БКИ и&nbsp;на&nbsp;сколько&nbsp;же было мое удивление, когда в&nbsp;незавершенных кредитах я&nbsp;увидел кредит вашего банка, который я&nbsp;уже данным-давно закрыл! Так еще этот кредит тянет дополнительную нагрузку! Что за&nbsp;такие дела творятся в&nbsp;вашем банке, что вы&nbsp;не&nbsp;передаете информацию в&nbsp;БКИ о&nbsp;закрытых кредитах?!\n' +
                '\n' +
                'Требую скорректировать кредитную историю и&nbsp;как можно скорее!',
            date: '2023-02-08T00:00:00Z',
            type: 'debet-card',
        },
        {
            name: 'Козлов Алексей',
            value: 5,
            text:
                'Добрый день!Я оформлял кредит в&nbsp;вашем банке, номер договора 57572141, который в&nbsp;январе 2023 был успешно полностью закрыт. Не&nbsp;так давно пытался сделать рефинансирование другого действующего кредита в&nbsp;другом банке и&nbsp;получил отказ по&nbsp;скоринг, решил заказать отчет в&nbsp;БКИ и&nbsp;на&nbsp;сколько&nbsp;же было мое удивление, когда в&nbsp;незавершенных кредитах я&nbsp;увидел кредит вашего банка, который я&nbsp;уже данным-давно закрыл! Так еще этот кредит тянет дополнительную нагрузку! Что за&nbsp;такие дела творятся в&nbsp;вашем банке, что вы&nbsp;не&nbsp;передаете информацию в&nbsp;БКИ о&nbsp;закрытых кредитах?!\n' +
                '\n' +
                'Требую скорректировать кредитную историю и&nbsp;как можно скорее!',
            date: '2023-02-08T00:00:00Z',
            type: 'debet-card',
        },
    ],
};

export const mockReviewsBlockTinaData = {
    title: 'Заголовок',
    gtag: {
        action: 'test',
        category: 'test',
        label: 'test',
    },
    disableSlider: false,
    list: [
        {
            name: 'ФИО',
            value: 3.5,
            text: 'Описание',
            date: '23.02.2023',
            type: 'debet-card',
        },
        {
            name: 'ФИО',
            value: 3.5,
            text: 'Описание',
            date: '23.02.2023',
            type: 'debet-card',
        },
        {
            name: 'ФИО',
            value: 3.5,
            text: 'Описание',
            date: '23.02.2023',
            type: 'debet-card',
        },
        {
            name: 'ФИО',
            value: 3.5,
            text: 'Описание',
            date: '23.02.2023',
            type: 'debet-card',
        },
    ],
};
