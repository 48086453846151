import clsx from 'clsx';
import React, { useRef } from 'react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import HRButton from '@/components/blocks/HRLanding/HRButton';
import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';

import cn from './style.module.sass';

SwiperCore.use([EffectFade, Autoplay]);

export interface IHRMain {
    title: string;
    images: string[];
    btnLabel: string;
    btnHandler?: () => void;
}

function HRMain({ title, btnLabel, images, btnHandler }: IHRMain) {
    const swiperRef = useRef(null);
    const settings = {
        className: clsx(cn.carousel, 'carousel'),
        slidesPerView: 1,
        loop: true,
        pagination: false,
        watchOverflow: true,
        effect: 'fade',
        fadeEffect: { crossFade: true },
        autoplay: true,
    };

    return (
        <div className={clsx(cn.main)}>
            <div className={clsx(cn.wrap)}>
                <div className={clsx(cn.grid)}>
                    <div className={clsx(cn.left)}>
                        <div className={clsx(cn.title)} dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={clsx(cn.desc)}>
                            <HRButton type="button" label={btnLabel} onClick={btnHandler} />
                        </div>
                    </div>
                    <div className={clsx(cn.right)}>
                        <div className={clsx(cn.currentSlider)}>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <CustomSwiper {...settings} ref={swiperRef}>
                                {images?.map((item, index) => (
                                    <SwiperSlide key={`${index} `}>
                                        <div className={cn.item} key={index}>
                                            <div className={cn.currentImage}>
                                                <CustomImage src={item} alt="Почтабанк" width="480" height="397" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </CustomSwiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRMain;
