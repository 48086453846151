import { makeAutoObservable } from 'mobx';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

export interface IMoneyBoxAccountCalculatorStore {
    range: IRangeInput;
    termsElements: IRangeInput;
    paymentValue: number;
    creditValue: number;
    initialized: boolean;
    turnoverBonusRate: number;
    addRate: number;
    enableCheckbox?: boolean;
    isChecked?: boolean;
    toggleChecked?: () => void;
    isCheckedCard?: boolean;
    toggleCheckedCard?: () => void;
    getRate?: () => number;
}

class MoneyBoxAccountCalculatorStore implements IMoneyBoxAccountCalculatorStore {
    public range: IRangeInput;

    public termsElements: IRangeInput;

    public initialized = false;

    public isChecked = true;

    public enableCheckbox = false;

    public initRate = 0;

    public turnoverBonusRate = 5;

    public addRate = 10;

    public isCheckedCard = true;

    constructor() {
        makeAutoObservable(this);
    }

    get creditValue(): number {
        return this.range.value;
    }

    get termValue(): number {
        return this.termsElements.value;
    }

    get paymentValue() {
        if (this.creditValue > this.range.maxValue) return 0;
        const monthPercent = this.getRate() / 100 / 12;
        const totalPercent = (1 + monthPercent) ** this.termValue;

        return Math.trunc(this.creditValue * totalPercent);
    }

    public toggleChecked = () => {
        this.isChecked = !this.isChecked;
    };

    public toggleCheckedCard = () => {
        this.isCheckedCard = !this.isCheckedCard;
    };

    getRate = () => {
        let currentRate = 0;

        if (this.termValue === 1) {
            currentRate = this.initRate + this.addRate;
        }

        if (this.termValue > 1 && this.isCheckedCard) {
            currentRate = this.initRate + this.turnoverBonusRate;
        }
        if (this.termValue > 1 && !this.isCheckedCard) {
            currentRate = this.initRate;
        }

        return currentRate;
    };

    public init = (
        filterElements: ICalculatorFilterElement[],
        termsElements: ICalculatorFilterElement[],
        enableCheckbox: boolean,
        initRate: number
    ) => {
        this.range = new RangeInput({
            name: 'creditValue',
            label: 'Сумма',
            elements: filterElements,
            initValue: 100000,
        });

        const termsInitValue = 1;

        this.termsElements = new RangeInput({
            name: 'termValue',
            label: 'Срок',
            elements: termsElements,
            initValue: termsInitValue,
            customFormatValueHandle: (value, _) => `${value} мес.`,
        });

        this.enableCheckbox = enableCheckbox;

        this.initRate = initRate;

        this.initialized = true;
    };
}

export default new MoneyBoxAccountCalculatorStore();
