import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import Reason from '@/components/blocks/faq/Reason';
import SidebarItem from '@/components/blocks/faq/SidebarItem';
import ModalWrapper from '@/components/modal/ModalWrapper';
import { useFixHtml } from '@/hooks/useFixHtml';
import { useTabletSmallWidth } from '@/hooks/useMedia';
import { IFaqItem } from '@/types/faq';

import cn from './style.module.sass';

interface IProps {
    list: IFaqItem[];
    setActiveItemHandler?: (pos: number) => void;
    activeItem: number;
    isThemes?: boolean;
}

const Sidebar: React.FC<IProps> = observer(({ list, setActiveItemHandler, activeItem, isThemes }) => {
    const { fix, unfix } = useFixHtml();

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
        fix();
    };

    const closeModal = () => {
        setIsOpen(false);
        unfix();
    };

    const isTablet = useTabletSmallWidth();

    if (!list || list?.length <= 0) return null;

    if (isTablet) {
        return (
            <>
                <Reason activeItem={list?.[activeItem]} handleOpen={openModal} />
                <ModalWrapper
                    noToggles
                    isOpen={isOpen}
                    handleOpen={openModal}
                    handleClose={closeModal}
                    className={cn.modal}
                >
                    <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
                        <div className={cn.list}>
                            {list?.map((item, index) => (
                                <SidebarItem
                                    activeItem={activeItem}
                                    pos={index}
                                    key={index}
                                    item={item}
                                    setActiveItemHandler={setActiveItemHandler}
                                    handleCloseModal={closeModal}
                                    isThemes={isThemes}
                                />
                            ))}
                        </div>
                    </AnimatedComponent>
                </ModalWrapper>
            </>
        );
    }

    return (
        <AnimatedComponent className={cn.wrapper} classNameActive={cn.animationInit}>
            <div className={cn.list}>
                {list?.map((item, index) => (
                    <SidebarItem
                        activeItem={activeItem}
                        pos={index}
                        key={index}
                        item={item}
                        setActiveItemHandler={setActiveItemHandler}
                        isThemes={isThemes}
                    />
                ))}
            </div>
        </AnimatedComponent>
    );
});

export default Sidebar;
