import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import Preloader from '@/components/UI/Preloader';
import { useAppStore } from '@/context/AppStoreContext';
import Feature from '@/domain/feature/Feature';
import { IActionsResponse, IActionsSlider, INavItems } from '@/types/actionsSlider';
import { ITag } from '@/types/tags';
import { apiRequest } from '@/utils/request';

import ActionsSliderItem from './item';
import cn from './style.module.sass';

const fetchActions = async ({
    page,
    path,
    section,
    apiCode,
    category,
}: {
    page: number;
    path: string;
    section: number;
    apiCode: string;
    category: string;
}) => {
    try {
        const basePath = '/page/block';

        const searchParams = `path=${path}&block=actionsSlider&apiCode=${apiCode}&section=${
            section || 0
        }&nav-${apiCode}=${page}&category=${category}`;

        const url = `${basePath}?${searchParams}`;

        const response: IActionsResponse<IActionsSlider> = await apiRequest(url);

        const {
            data: { data: fetchedData },
        } = response;

        return fetchedData;
    } catch (e) {
        throw Error('Не пришли данные акций');
    }
};

const ActionsSlider: React.FC<IActionsSlider> = observer(
    ({ list, title, apiCode, navigation: fetchedNavigation, section, allCategory }) => {
        const tagList: ITag[] = allCategory?.map(tag => ({
            xmlId: tag.UF_ID.toString(),
            value: tag.UF_NAME,
        }));

        const [isDataFetching, setIsDataFetching] = useState(false);
        const [isDataMoreFetching, setIsDataMoreFetching] = useState(false);
        const [path] = useAppStore(store => store.path);
        const [isTinaView] = useAppStore(store => store.isTinaView);
        const [elementsList, setElementsList] = useState<IActionsSlider['list']>(list);
        const [navigation, setNavigation] = useState<INavItems>(
            fetchedNavigation?.currentPage
                ? fetchedNavigation
                : {
                      currentPage: 1,
                      totalCount: 1,
                      pageCount: 1,
                  }
        );
        const [category, setCategory] = useState<string>(tagList[0]?.xmlId || '0');

        const setData = async ({
            page = 1,
            isShowMore,
            newCategory,
        }: {
            page: number;
            isShowMore: boolean;
            newCategory: string;
        }) => {
            const data = await fetchActions({ page, path, section, apiCode, category: newCategory });

            if (!data) return;

            setNavigation(data?.navigation);

            if (isShowMore) {
                setElementsList(elements => [...elements, ...data.list]);
            } else {
                setElementsList(data.list);
            }
        };

        const onMoreClick = async () => {
            setIsDataMoreFetching(true);

            await setData({
                newCategory: category,
                page: navigation.currentPage + 1,
                isShowMore: true,
            });

            setIsDataMoreFetching(false);
        };

        const handleSelectedTag = async (tag: string) => {
            setCategory(tag);

            setIsDataFetching(true);

            await setData({
                page: 1,
                isShowMore: false,
                newCategory: tag,
            });

            setIsDataFetching(false);
        };

        if (!Feature.isFeatureEnabled('enableActions')) {
            return null;
        }

        useEffect(() => {
            if (!apiCode || !isTinaView) return;

            setData({ page: 1, isShowMore: false, newCategory: category }).catch(console.error);
        }, [apiCode, section, isTinaView]);

        if (elementsList?.length === 0) return null;

        return (
            <div className={clsx(cn.current, 'section')}>
                {isDataFetching ? (
                    <div className={cn.currentWrap}>
                        <div className={cn.currentList}>
                            <Preloader type="dzen" />
                        </div>
                    </div>
                ) : (
                    <div className={cn.currentWrap}>
                        <div className={cn.topWrap}>
                            {title && (
                                <TextField text={title} name="title" isTextArea className={cn.title} customTag="h2" />
                            )}
                        </div>
                        <div className={cn.currentGrid}>
                            {allCategory?.length > 0 && (
                                <div className={cn.filter}>
                                    <TagList tagList={tagList} onClick={handleSelectedTag} activeTag={category} />
                                </div>
                            )}
                            <AnimatedComponent className={cn.currentList} classNameActive={cn.animationInit}>
                                {elementsList?.map(item => (
                                    <ActionsSliderItem {...item} key={item.id} />
                                ))}
                            </AnimatedComponent>
                        </div>

                        {isDataMoreFetching ? (
                            <div className={cn.currentWrap}>
                                <div className={cn.currentList}>
                                    <Preloader type="dzen" />
                                </div>
                            </div>
                        ) : (
                            elementsList?.length < navigation?.totalCount && (
                                <AnimatedComponent className={cn.actionsListBtn}>
                                    <Button
                                        variant="btnBlueBorder"
                                        type="button"
                                        label="Показать ещё"
                                        customClass={clsx(cn.btnAll)}
                                        darkWhite
                                        onClick={onMoreClick}
                                    />
                                </AnimatedComponent>
                            )
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default withBlockLinks(ActionsSlider);
