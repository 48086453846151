import clsx from 'clsx';
import React from 'react';

import cn from '@/components/blocks/GroupedTariffs/style.module.sass';
import CustomImage from '@/components/CustomImage';
import { ITariffItem } from '@/types/tariff/item';

import AnimatedComponent from '../../Animation';

const TariffItem: React.FC<ITariffItem> = ({ title, document, documentType, permanentDocument, size }) => (
    <AnimatedComponent className={clsx(cn.tariffInfo)} classNameActive={cn.animationInit}>
        <div className={cn.tariffInfoWrap}>
            <div className={clsx(cn.tariffInfoItem, cn.tariffInfoItemName)}>
                <div className={clsx(cn.tariffInfoName, 'headers')} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className={clsx(cn.tariffInfoItem, cn.tariffInfoItemLink)}>
                <a
                    href={permanentDocument || document}
                    target="_blank"
                    download={title}
                    rel="noreferrer"
                    className={cn.tariffLoad}
                >
                    <div className={clsx(cn.tariffLoadItem, cn.tariffLoadItemIcon)}>
                        {documentType && (
                            <div className={cn.tariffLoadItemIconImg}>
                                <CustomImage src={`/img-next/svg/${documentType}.svg`} alt={document} fill />
                            </div>
                        )}
                        <div
                            className={clsx(cn.tariffInfoName, cn.tariffInfoNameMobail, 'headers')}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    </div>
                    <div className={clsx(cn.tariffLoadItem, cn.tariffLoadItemSize)}>
                        <div className={cn.tariffLoadLink}>Скачать</div>
                        <div className={cn.tariffLoadSize}>{size}</div>
                    </div>
                </a>
            </div>
        </div>
    </AnimatedComponent>
);
export default TariffItem;
