type SimpleParamObject = Record<string, string>;

export const getSearchParams = (searchString: string): SimpleParamObject => {
    const result = {};

    new URLSearchParams(searchString).forEach((value, key) => {
        result[key] = value;
    });

    return result;
};
