import React from 'react';

import { TinaDocuments } from '@/components/blocks/tariffTabs/Documents/index.tina';
import { mockDocumentsBlockDataTina } from '@/data/blocks/tariffTabs/documents';
import { IBlockProps } from '@/types/tina/block';

export const documentsBlock = {
    Component: (props: IBlockProps) => <TinaDocuments {...props} />,
    template: {
        label: 'Документы',
        defaultItem: () => mockDocumentsBlockDataTina,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'isCollapsible',
                component: 'toggle',
                label: 'Сделать блок аккордеоном',
            },
            {
                name: 'name',
                component: 'text',
                label: 'Заголовок',
            },
            {
                component: 'text',
                name: 'subtitle',
                label: 'Подзаголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { documentName: string }, index: number) => ({
                    key: index,
                    label: item.documentName,
                    title: item.documentName,
                }),
                defaultItem: () => mockDocumentsBlockDataTina.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента',
                        required: true,
                        validate: (value: string) => {
                            if (!value) {
                                return 'Вы не заполнили наименование элемента';
                            }
                            return null;
                        },
                    },
                    {
                        component: 'text',
                        name: 'documentName',
                        label: 'Заголовок',
                    },
                    {
                        component: 'textarea',
                        name: 'documentDescription',
                        label: 'Описание',
                    },
                    {
                        component: 'date',
                        dateFormat: 'DD.MM.YYYY',
                        timeFormat: false,
                        name: 'expirationDate',
                        label: 'Окончание активности',
                    },

                    {
                        component: 'text',
                        name: 'documentType',
                        label: 'Расширение документа',
                    },
                    {
                        component: 'text',
                        name: 'document',
                        label: 'Ссылка',
                    },
                    {
                        component: 'text',
                        name: 'permanentDocument',
                        label: 'Ссылка для редиректа',
                    },
                ],
            },
        ],
    },
};
