import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import LazyRender from '@/components/LazyRender';
import TagList from '@/components/TagList';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { ICashbackItem } from '@/types/cashback/item';
import { ITag } from '@/types/tags';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';

import CashbackItem from './item';
import cn from './style.module.sass';

const CustomSwiper = dynamic(() => import('@/components/CustomSwiper'), {
    ssr: false,
});

export interface ICashback {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template?: 'cashback';
    /**
     * Список элементов. макс 3.
     */
    list: ICashbackItem[];
    tagList?: ITag[];
    name: string;
    text?: string;
}

const Cashback: React.FC<ICashback> = ({ name, text, list, tagList }) => {
    const [selectedTag, setSelectedTag] = useState(tagList?.[0]?.xmlId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [SwiperSlide, setSwiperSlide] = useState(null);

    const getSwiperSlide = async () => {
        const swiperComponents = await import('swiper/react');
        setSwiperSlide(swiperComponents.SwiperSlide);
    };

    useEffect(() => {
        getSwiperSlide().catch(e => console.log(e));
    }, []);

    const items = useMemo(() => {
        if (!tagList?.length) return list.slice(0, 3);
        return list
            .reduce((acc: ICashbackItem[], current, index) => {
                if (current.tags?.includes(selectedTag)) {
                    return [...acc, { ...current, pos: index }];
                }
                return acc;
            }, [])
            .slice(0, 3);
    }, [tagList, list, selectedTag]);

    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;

    const slidesPerView = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.23;
        if (width < MOBILE_WIDTH) return 2.3;
        if (width < TABLET_SMALL_WIDTH) return 2.5;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView,
        watchOverflow: true,
    };

    return (
        <AnimatedComponent>
            <div className={clsx(cn.cashback, 'section')}>
                <div className={cn.cashbackWrap}>
                    <div className={cn.top}>
                        <div className={clsx(cn.topItem, cn.topItemTitle)}>
                            <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />
                            {text && (
                                <AnimatedComponent className={cn.cashbackText} classNameActive={cn.animationInit}>
                                    <TextField text={text} name="text" isHTML htmlValue={text} />
                                </AnimatedComponent>
                            )}
                        </div>

                        {tagList?.length > 0 && (
                            <div className={clsx(cn.topItem, cn.topItemFilter)}>
                                <div className={cn.wrapper}>
                                    <div className={cn.topItemSmallTitle}>Покупки в месяц</div>
                                    <AnimatedComponent className={clsx(cn.filter)} classNameActive={cn.animationInit}>
                                        <TagList
                                            tagList={tagList}
                                            onClick={setSelectedTag}
                                            activeTag={selectedTag}
                                            limit={2}
                                        />
                                    </AnimatedComponent>
                                </div>
                            </div>
                        )}
                    </div>
                    {isDesktop && items?.length > 0 && (
                        <div className={cn.cashbackGrid}>
                            {items.map(({ desc, title, subtitle, image, pos }, index) => (
                                <CashbackItem
                                    desc={desc}
                                    title={title}
                                    subtitle={subtitle}
                                    image={image}
                                    pos={pos || index}
                                    key={index}
                                />
                            ))}
                        </div>
                    )}
                    {isMobile && SwiperSlide && items?.length > 0 && (
                        <div className={cn.cashbackSlider}>
                            <LazyRender rootMargin="100px" className={cn.currentSlider}>
                                <CustomSwiper {...settings}>
                                    {items.map(({ desc, title, subtitle, image }, index) => (
                                        <SwiperSlide key={`${index}-${title}`}>
                                            <CashbackItem
                                                desc={desc}
                                                title={title}
                                                subtitle={subtitle}
                                                image={image}
                                                pos={index}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </CustomSwiper>
                            </LazyRender>
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Cashback);
