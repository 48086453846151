import React from 'react';

import { TinaTariffsRestrictions } from '@/components/blocks/bonds/TariffsRestrictions/index.tina';
import { mockTariffsRestrictionsBlockTinaData } from '@/data/tariffsRestrictions';

export const tariffsRestrictionsBlock = {
    Component: props => <TinaTariffsRestrictions {...props} />,
    template: {
        label: 'Правила и ограничения для облигаций',
        defaultItem: () => mockTariffsRestrictionsBlockTinaData,
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { itemValue: string }, index: number) => ({
                    key: index,
                    label: item.itemValue,
                }),
                defaultItem: () => mockTariffsRestrictionsBlockTinaData.list[0],
                fields: [
                    {
                        name: 'itemValue',
                        component: 'text',
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
