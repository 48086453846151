import clsx from 'clsx';
import React, { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import AnimatedComponent from '@/components/Animation';
import ExampleItem from '@/components/blocks/Example/item';
import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { useResize } from '@/hooks/useResize';
import { IBanner, IBottomInfo, IExampleItem } from '@/types/example/item';
import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IExample {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'example';
    name?: string;
    text?: string;
    list: IExampleItem[];
    bottomText?: string;
    bottomInfo?: IBottomInfo;
    banner?: IBanner;
}

const Example: React.FC<IExample> = ({ name, text, list, bottomText, bottomInfo, banner }) => {
    const width = useResize();
    const isMobile = width <= TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;

    const slidesPerView = useMemo(() => {
        if (typeof window === 'undefined') return 3;
        if (width < MOBILE_SM_WIDTH) return 1.23;
        if (width < MOBILE_WIDTH) return 2.3;
        if (width < TABLET_SMALL_WIDTH) return 2.5;
        return 3;
    }, [width]);

    const settings = {
        slidesPerView,
        watchOverflow: true,
    };
    return (
        <div className={clsx(cn.example, 'section')}>
            <div className={cn.exampleWrap}>
                <AnimatedComponent className={cn.exampleTop} classNameActive={cn.animationInit}>
                    <TextField text={name} isTextArea name="name" customTag="h3" className={cn.exampleTitle} />
                    <TextField text={text} isTextArea name="text" className={cn.exampleText} />
                </AnimatedComponent>
                {isDesktop && (
                    <div className={cn.cashbackGrid}>
                        {list.map(({ title, info }, index) => (
                            <ExampleItem title={title} info={info} pos={index} key={index} />
                        ))}
                    </div>
                )}
                {isMobile && (
                    <div className={cn.cashbackSlider}>
                        <CustomSwiper {...settings}>
                            {list.map(({ title, info }, index) => (
                                <SwiperSlide key={index}>
                                    <ExampleItem title={title} info={info} pos={index} key={index} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                    </div>
                )}
                {isDesktop && (
                    <AnimatedComponent className={cn.exampleBot} classNameActive={cn.animationInit}>
                        <TextField
                            text={bottomText}
                            name="bottomText"
                            isHTML
                            htmlValue={bottomText}
                            className={cn.exampleBotText}
                        />
                    </AnimatedComponent>
                )}
                {isMobile && (
                    <AnimatedComponent className={cn.exampleBot} classNameActive={cn.animationInit}>
                        <div className={cn.info}>
                            <TextField
                                text={bottomInfo.infoTitle}
                                name="bottomInfo.infoTitle"
                                className={cn.infoTitle}
                            />
                            <div className={cn.infoGrid}>
                                {bottomInfo.list.map(({ infoItemTitle, infoItemText }, index) => (
                                    <div className={cn.infoItem} key={index}>
                                        <TextField
                                            text={infoItemTitle}
                                            isHTML
                                            htmlValue={infoItemTitle}
                                            name={`bottomInfo.list.[${index}].infoItemTitle`}
                                            className={cn.infoItemTitle}
                                        />
                                        <TextField
                                            text={infoItemText}
                                            name={`bottomInfo.list.[${index}].infoItemText`}
                                            className={cn.infoItemText}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className={cn.banner}>
                                <div className={cn.bannerContent}>
                                    <TextField
                                        text={banner.text}
                                        name="banner.text"
                                        className={cn.bannerText}
                                        isTextArea
                                    />
                                    <TextField
                                        text={banner.bigText}
                                        name="banner.bigText"
                                        className={cn.bannerBigText}
                                        isTextArea
                                    />
                                    <div className={cn.bannerBigLogo}>
                                        <CustomImage src={banner.logo} alt={banner.text} width={78} height={18} />
                                    </div>
                                </div>
                                <div className={cn.bannerImage}>
                                    <CustomImage src={banner.image} alt={banner.text} width={180} height={109} />
                                </div>
                            </div>
                        </div>
                    </AnimatedComponent>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(Example);
