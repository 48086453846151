import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname } from 'next/navigation';
import React, { useContext, useState } from 'react';

import Button from '@/components/UI/Button';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { ICreditRealEstateCalculatorStore } from '@/domain/calculator/CreditRealEstateCalculatorStore';
import { copyTextToClipboard } from '@/utils/copyToClipboard';
import { getSearchParams } from '@/utils/getSearchParams';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
}

type SimpleParamObject = Record<string, string>;

const SharingButton: React.FC<IProps> = observer(({ gtag }) => {
    const { creditValue, termValue } = useContext<ICreditRealEstateCalculatorStore>(CalculatorStoreContext);
    const [shareButtonText, setShareButtonText] = useState('Поделиться расчетами');

    const path = usePathname();

    let searchParams: SimpleParamObject = {};

    try {
        const { location } = document;
        searchParams = getSearchParams(location.search);
    } catch (error) {
        if (error instanceof Error) {
            console.error(`Calc error: `, error.message);
        }
    }

    const getQueryParams = () => {
        const keys = Object.keys(searchParams);

        return keys.reduce((acc, item) => {
            if (item === 'cashCalculatorParams' || item === 'slug') return acc;
            return `${acc}&${item}=${searchParams[item]}`;
        }, '');
    };

    const onShareClick = async () => {
        const stringJSON = `{"value":"${creditValue}","term":"${termValue}"}`;

        const linkQueryParamsString = `?creditRealEstateCalculatorParams=${encodeURIComponent(
            stringJSON
        )}${getQueryParams()}#calculation`;

        const link = `https://www.pochtabank.ru${path}${linkQueryParamsString}`;

        if (!navigator.share) {
            try {
                await copyTextToClipboard(link);
                setShareButtonText('Ссылка скопирована в буфер обмена');
                setTimeout(() => {
                    setShareButtonText('Поделиться расчетами');
                }, 3000);
            } catch (e) {
                console.error('Ошибка при копировании текста.');
            }
            return;
        }

        try {
            await navigator.share({
                title: 'Расчеты калькулятора наличными.',
                url: link,
            });
            setShareButtonText('Вы успешно поделились ссылкой');
            setTimeout(() => {
                setShareButtonText('Поделиться расчетами');
            }, 3000);
        } catch (_) {
            console.info('Пользователь отменил шаринг ссылки.');
        }

        if (gtag?.action) event(gtag);
    };
    return (
        <div className={clsx(cn.buttonWrapper, cn.buttonWrapperMobile)}>
            <Button
                variant="btnWhite"
                type="button"
                label={shareButtonText}
                customClass={cn.buttonWhite}
                onClick={onShareClick}
                animation={false}
                img="/img-next/svg/share.svg"
                imgOrder
            />
        </div>
    );
});

export default SharingButton;
