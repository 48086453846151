import React from 'react';

import { TinaPboFaq } from '@/components/blocks/PboFaq/index.tina';
import { mockPromotionGradientBannerTinaData } from '@/data/blocks/promotionGradientBanner';
import { tinaPromotionGradientBannerConfig } from '@/tina/blocks/promotionGradientBanner';

export const pboFaqBlock = {
    Component: props => <TinaPboFaq {...props} />,
    template: {
        _template: 'pboFaq',
        label: 'FAQ для ПБО',
        defaultItem: () => ({
            title: 'Инструкции для клиентов',
            promotion: mockPromotionGradientBannerTinaData.promotion,
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'promotion',
                component: 'group',
                label: 'Предложение',
                fields: tinaPromotionGradientBannerConfig,
            },
            {
                name: 'hideItemsButton',
                component: 'toggle',
                label: 'Отключить все кнопки в карточках снизу',
            },
        ],
    },
};
