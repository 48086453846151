import React from 'react';

import { TinaTable } from '@/components/blocks/Table/index.tina';
import { mockTableBlockTina } from '@/data/blocks/table';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const tableBlock = {
    Component: props => <TinaTable {...props} />,
    template: {
        label: 'Таблица',
        defaultItem: () => mockTableBlockTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                component: HtmlEditorFeatured,
                name: 'html',
                label: 'Таблица',
            },
            {
                component: 'toggle',
                name: 'isActive',
                label: 'Изменить стиль 1-го столбца',
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип',
                options: [
                    {
                        value: 'main',
                        label: 'Основной',
                    },
                    {
                        value: 'secondary',
                        label: 'Дополнительный',
                    },
                ],
            },
        ],
    },
};
