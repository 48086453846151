import { IPersons } from '@/components/blocks/Persons/types';

export const mockPersonsBlockData: IPersons = {
    _template: 'persons',
    title: 'Персоны',
    list: [
        {
            name: 'Ирина',
            status: 'Менеджер в крупной компании',
            description:
                'Активно использует карту &laquo;Моя карта&raquo; и&nbsp;получает кешбэк от&nbsp;банка и&nbsp;дополнительный кешбэк с&nbsp;программой лояльности по&nbsp;карте &laquo;Мир&raquo;',
            img: '',
            button: {
                href: '#',
                label: 'Подробнее',
            },
            backSideTitle: 'Условия акции',
            backSideDescription:
                'Кешбэк по&nbsp;карте &laquo;Моя карта&raquo; начисляется в&nbsp;выбранных категориях. Каждый месяц можно выбрать 3&nbsp;категории из&nbsp;5.\n' +
                'Чтобы получить дополнительный кешбэк нужно зарегистрировать карту &laquo;Моя карта&raquo; в&nbsp;программе лояльности на&nbsp;сайте vamprivet.ru или в&nbsp;приложении &laquo;Привет!&raquo;',
        },
        {
            name: 'Татьяна Викторовна',
            status: 'Пенсионерка',
            description:
                'Получает пенсию на карту «Моя карта» в Почта Банке и пользуется дополнительным кешбэком за покупки в аптеке, оплату ж/д билетов и на АЗС',
            img: '',
            button: {
                href: '#',
                label: 'Подробнее',
            },
            backSideTitle: 'Условия акции',
            backSideDescription: `Дополнительный кешбэк начисляется пенсионерам, которые получают пенсию в&nbsp;Почта Банк. Чтобы начать получать пенсию на&nbsp;карту &laquo;Моя карта&raquo; можно отправить заявку на&nbsp;сайте или обратиться к&nbsp;сотруднику в&nbsp;ближайшем отделении Почта Банка`,
        },
        {
            name: 'Мария',
            status: 'Бухгалтер',
            description:
                'Оформила кредит в&nbsp;Почта Банке с&nbsp;пакетом &laquo;Большие возможности&raquo; и&nbsp;получает выгоду за&nbsp;оплату коммунальных, телекоммуникационных услуг и&nbsp;транспорта',
            img: '',
            button: {
                href: '#',
                label: 'Подробнее',
            },
            backSideTitle: 'Условия акции',
            backSideDescription:
                'Кешбэк в категориях: коммунальные услуги, транспорт и телекоммуникационные услуги доступен для тех, кто оформил кредит и Почта Банке и подключил пакет «Большие возможности». Получить одобрение по кредиту можно онлайн за минуту, оставив заявку на сайте или у сотрудника ближайшем отделении Почта Банка. К кредиту доступны дополнительные услуги для комфортного погашения',
        },
        {
            name: 'Андрей',
            status: 'IT-специалист',
            description:
                'Ежедневно использует &laquo;Мою карту&raquo;, а&nbsp;чтобы экономить в&nbsp;ресторанах, кафе, и&nbsp;возвращать часть стоимости от&nbsp;покупок одежды и&nbsp;обувь&nbsp;&mdash; оформил кредитную карту &laquo;Вездедоход&raquo;',
            img: '',
            button: {
                href: '#',
                label: 'Подробнее',
            },
            backSideTitle: 'Условия акции',
            backSideDescription:
                'Для выгодных покупок в&nbsp;магазинах одежды, обуви, в&nbsp;ресторанах и&nbsp;кафе к&nbsp;оформлению доступна карта &laquo;Вездедоход&raquo; с&nbsp;беспроцентным периодом до&nbsp;120 дней и&nbsp;бесплатным выпуском и&nbsp;обслуживанием. Чтобы оформить карту &laquo;Вездедоход&raquo; можно оставить заявку на&nbsp;сайте и&nbsp;получить решение за&nbsp;минуту или обратиться к&nbsp;сотруднику в&nbsp;ближайшем отделении',
        },
    ],
};
export const mockPersonsBlockDataTina: IPersons = {
    _template: 'persons',
    title: 'Заголовок',

    list: [
        {
            name: 'Имя',
            status: 'Статус',
            description: 'Описание',
            img: '',
            button: {
                href: '#',
                label: 'Подробнее',
            },
            backSideTitle: 'Заголовок на обороте',
            backSideDescription: 'Описание на обороте',
        },
    ],
};
