import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';

import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import ResultProgress from '@/components/blocks/calculators/resultProgress';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import CalculatorStoreContextNew from '@/context/CalculatorStoreContextNew';
import { ICashbackCalculator } from '@/domain/calculator/CashbackCalculatorStore';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface ICalculator {
    title?: string;
    button?: {
        label: string;
        link: string;
    };
    gtag: { gtagButton: GTagEvent; gtagSlider: GTagEvent; gtagTerm: GTagEvent };
    isPencilNeeded?: boolean;
}
const VezdedohodCalculatorContent: FC<ICalculator> = observer(({ title, gtag, button, isPencilNeeded }) => {
    const {
        resultConfig,
        maxCashbackResult,
        currentCashback,
        createConfigRangeBuy,
        progressMaxCashback,
        progressCashback,
    } = useContext<ICashbackCalculator>(CalculatorStoreContextNew);

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={clsx(cn.wrapper)}>
                    <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <RangeInput
                                config={resultConfig.range1}
                                callback={createConfigRangeBuy}
                                isPencilNeeded={isPencilNeeded}
                            />
                            <RangeInput config={resultConfig.range2} isPencilNeeded={isPencilNeeded} />
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.rightWrapperMarginTop}>
                                    <ResultProgress
                                        text="Ваш максимальный кешбэк"
                                        progress={progressMaxCashback}
                                        sum={maxCashbackResult}
                                        isTextStarred
                                    />
                                    <ResultProgress
                                        text="Ваш кешбэк"
                                        progress={progressCashback}
                                        sum={currentCashback}
                                    />
                                    <div className={cn.helpText}>
                                        Расчет предварительный. Точная сумма кешбэка будет рассчитана по фактическим
                                        расходам по карте за месяц.
                                    </div>
                                    <div className={cn.button}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={button?.link || '#'}
                                            label={button?.label || 'Оформить карту'}
                                            size="small"
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (gtag?.gtagButton) event(gtag.gtagButton);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default VezdedohodCalculatorContent;
