import React from 'react';

import { TinaFeedbackFormPretensionSecond } from '@/components/blocks/FeedbackFormPretensionSecond/index.tina';
import { mockFeedbackFormBlockDataTina } from '@/data/blocks/feedbackForm';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const feedbackFormPretensionBlockSecond = {
    Component: props => <TinaFeedbackFormPretensionSecond {...props} />,
    template: {
        label: 'Форма обратной связи - Подать претензию, жалобу v.2 (с благодарностью)',
        defaultItem: () => mockFeedbackFormBlockDataTina,
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'successTitle',
                component: 'text',
                label: 'Успешный заголовок',
            },
            {
                name: 'thanksSubTitle',
                component: HtmlEditorFeatured,
                label: 'Оставить благодарность текст в модальном окне',
            },
        ],
    },
};
