import { DrawParticipationListProps } from '@/components/blocks/draw/participation-list/index';

export const mockParticipationList: DrawParticipationListProps = {
    _template: 'drawParticipationList',
    title: 'Действия для участия в розыгрыше до 31.12.2024 г.',
    text: `Список действий дополняется каждый месяц &nbsp; | &nbsp; Выполненные действия отображаются с задержкой в 1 день`,
    items: [
        {
            title: 'Совершите 10 покупок в октябре и 10 в ноябре',
            desc: 'Можно выполнять с&nbsp;01.11.2024',
            chances: '10 шансов',
        },
        {
            title: 'Совершите покупки на 5000 ₽ в ноябре и 5000 ₽ в декабре',
            desc: 'Можно выполнять с&nbsp;01.11.2024',
            chances: '10 шансов',
        },
        {
            title: 'Накопите 600 бонусов',
            desc: 'Можно выполнять с&nbsp;01.11.2024',
            chances: '3 шанса',
        },
    ],
    link: 'Другие категории в приложении <a href="#">Почта Банк Онлайн</a>',
};
