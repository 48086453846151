import { IPromoSuper } from '@/components/blocks/PromoSuper';

export const mockPromoSuperBlockData: IPromoSuper = {
    _template: 'promoSuper',
    hideBreadcrumbs: true,
    banner: {
        background: 'linear-gradient(88.18deg, #EFF5FF 1.64%, #F8F0FF 99.37%)',
        backgroundImage: '',
        color: 'black',
        line: {
            title: 'Кредит наличными',
            subtitle: 'на любые цели',
        },
        description: 'Вернём&nbsp;5% ставки по&nbsp;кредиту деньгами при погашении в&nbsp;срок',
        buttons: [
            {
                text: 'Получить кредит',
                link: '#id__credit-issue',
                type: 'button',
                color: 'blue',
                isSetMobileAnimate: true,
                isSetDesktopAnimate: true,
            },
        ],
        image: 'https://files.pochtabank.ru/medialibrary/5bb/5bb424b0f12865d47032002635d67924/cashcredit_main_3007_24_3.png',
        imageLink: '',
    },
    promo: [
        {
            image: '/img-next/png/promo/1.png',
            title: 'Онлайн',
            subtitle: 'Подтверждение по&nbsp;договору',
        },
        {
            image: '/img-next/png/promo/3.png',
            title: 'До 7 лет',
            subtitle: 'Срок кредита',
        },
        {
            image: '/img-next/png/promo/4.png',
            title: 'За 1 минуту',
            subtitle: 'Решение по кредиту',
        },
    ],
};
