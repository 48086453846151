import React from 'react';

import { TinaArticlesDetail } from '@/components/blocks/ArticlesDetail/index.tina';
import { mockArticlesDetailBlockTinaData } from '@/data/blocks/articlesDetail';
import { mockSidebarAnchorLinksBlockDataTina } from '@/data/blocks/sidebar/sidebarAnchorLinks';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const articlesDetailBlock = {
    Component: props => <TinaArticlesDetail {...props} />,
    template: {
        label: 'Детальная страница статьи',
        defaultItem: () => mockArticlesDetailBlockTinaData,
        fields: [
            {
                label: 'Элемент',
                name: 'element',
                component: 'group',
                fields: [
                    {
                        name: 'tags',
                        component: 'tagElement',
                        parentName: 'tagList',
                        label: 'Привязка по тэгам',
                    },
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                    },
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Заголовок',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'text',
                        label: 'Описание: основное тело статьи',
                    },
                    {
                        name: 'previewText',
                        component: HtmlEditorFeatured,
                        label: 'Аннотация: для разводящей (Поле должно содержать не более 200 символов)',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Превью изображение для разводящей',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'isImageDisabled',
                        component: 'toggle',
                        label: 'Выключить превью изображения для разводящей',
                    },
                    {
                        name: 'time',
                        component: 'text',
                        label: 'Время прочтения статьи',
                    },
                    {
                        name: 'date',
                        component: 'text',
                        label: 'Дата',
                    },
                    {
                        name: 'background',
                        component: 'text',
                        label: 'Цвет фона для элемента',
                    },
                    {
                        name: 'linkTo',
                        component: 'text',
                        label: 'Ссылка',
                    },
                ],
            },
            {
                label: 'Список элементов якорей',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { linkName: string }, index: number) => ({
                    key: index,
                    label: item.linkName,
                }),
                defaultItem: () => mockSidebarAnchorLinksBlockDataTina.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'linkName',
                        label: 'Название ссылки',
                    },
                    {
                        component: 'text',
                        name: 'linkHref',
                        label: 'Ссылка',
                    },
                ],
            },
        ],
    },
};
