export const mockHeadManagementBlockData = {
    banner: {
        background: 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)',
        line: {
            title: 'Дмитрий Руденко',
            subtitle: 'Президент-Председатель Правления Почта Банка',
        },
        image: '/img-next/management/rudenko/rudenko.png',
        description:
            'Эксперт отечественного финансового рынка\n' +
            'с&nbsp;многолетним опытом, один из&nbsp;создателей розничного банкинга страны',
        socialList: [
            {
                image: '/img-next/svg/socialColor/fb.svg',
                link: '#',
                name: 'Facebook',
            },
            {
                image: '/img-next/svg/socialColor/insta.svg',
                link: '#',
                name: 'Instagram',
            },
        ],
    },
    awardsList: [
        {
            image: '/img-next/management/rudenko/awards/1.png',
            year: '2017',
            desc: '&laquo;Новатор года&raquo; премии &laquo;Время инноваций-2017&raquo;.',
        },
        {
            image: '/img-next/management/rudenko/awards/2.png',
            year: '2016',
            desc: 'В&nbsp;2016 году награжден нагрудным знаком АРБ &laquo;За&nbsp;заслуги перед банковским сообществом 2016&raquo;.',
        },
        {
            image: '/img-next/management/rudenko/awards/3.png',
            year: '2017',
            desc: '&laquo;Лучший банковский управляющий 2016&nbsp;года&raquo; по&nbsp;версии Retail Finance Awards.',
        },
    ],
};

export const mockHeadManagementBlockDataTina = {
    banner: {
        background: '',
        line: {
            title: 'Заголовок',
            subtitle: 'Описание',
        },
        image: '',
        description: 'Описание',
        socialList: [
            {
                image: '',
                link: '#',
                name: 'Название',
            },
        ],
    },
    awardsList: [
        {
            image: '',
            year: '2017',
            desc: 'Описание',
        },
    ],
};
