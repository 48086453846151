import React from 'react';

import { TinaAnimatedPromo } from '@/components/blocks/animatedPromo/AnimatedPromo/index.tina';
import { mockAnimatedPromoBlockTinaData } from '@/data/blocks/animatedPromo';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';

export const animatedPromoBlock = {
    Component: props => <TinaAnimatedPromo {...props} />,
    template: {
        label: 'Слайдер',
        defaultItem: () => mockAnimatedPromoBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { title: string }, index: number) => ({
                    key: index,
                    label: item.title,
                }),
                defaultItem: () => mockAnimatedPromoBlockTinaData.list[0],
                fields: [
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Кнопка-анонс. Иконка',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        name: 'title',
                        component: 'text',
                        label: 'Кнопка-анонс. Заголовок',
                    },
                    {
                        name: 'titleMobile',
                        component: 'text',
                        label: 'Кнопка-анонс. Заголовок. Мобильная версия',
                    },
                    {
                        name: 'subtitle',
                        component: 'textarea',
                        label: 'Кнопка-анонс. Текстовое описание',
                    },
                    {
                        label: 'Баннер',
                        name: 'banner',
                        description: 'Изменить текст. Сделан для примера.',
                        component: 'group',
                        fields: [
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Изображение.',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'mobileImage',
                                component: 'image',
                                label: 'Изображение для мобильной версии',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'imageTopMv',
                                component: 'toggle',
                                label: 'Прижать изображение к верхнему краю',
                            },
                            {
                                name: 'imageBottomMv',
                                component: 'toggle',
                                label: 'Прижать изображение к нижнему краю',
                            },
                            {
                                name: 'imageBig',
                                component: 'toggle',
                                label: 'Сделать контейнер для изображения больше',
                            },
                            {
                                name: 'withAnimationImage',
                                component: 'toggle',
                                label: 'Выключить/включить анимированное изображение.',
                            },
                            {
                                name: 'animationImage',
                                component: 'image',
                                label: 'Анимированное изображение(gif).',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'animationImageWebm',
                                component: 'webm',
                                label: 'Видео.',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'backgroundImage',
                                component: 'image',
                                label: 'Фоновое Изображение.',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'withDecor',
                                component: 'toggle',
                                label: 'Выключить/включить фоновое изображение.',
                            },
                            {
                                name: 'withAnimate',
                                component: 'toggle',
                                label: 'Выключить/включить анимацию.',
                            },
                            {
                                name: 'animationType',
                                component: 'select',
                                label: 'Тип анимации',
                                options: [
                                    {
                                        value: '',
                                        label: 'Не выбрана',
                                    },
                                    {
                                        value: 'red',
                                        label: 'Анимация с красным фоном',
                                    },
                                    {
                                        value: 'blue',
                                        label: 'Анимация с голубым фоном',
                                    },
                                ],
                            },
                            {
                                label: 'Баннер. Красно-синяя полоса. Заголовок.',
                                name: 'line',
                                description: 'Изменить текст. Сделан для примера.',
                                component: 'group',
                                fields: [
                                    {
                                        name: 'title',
                                        component: HtmlEditorFeatured,
                                        label: 'Баннер. Красно-синяя полоса. Заголовок.',
                                    },
                                    {
                                        name: 'subtitle',
                                        component: HtmlEditorFeatured,
                                        label: 'Баннер. Красно-синяя полоса. Описание.',
                                    },
                                ],
                            },
                            {
                                name: 'description',
                                component: HtmlEditorFeatured,
                                label: 'Баннер. Текстовое описание.',
                            },
                            {
                                name: 'descriptionMobile',
                                component: HtmlEditorFeatured,
                                label: 'Баннер. Текстовое описание. Мобильная версия',
                            },
                            {
                                label: 'Преимущества',
                                name: 'advantages',
                                component: 'group-list',
                                itemProps: (item: { title: string }, index) => ({
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    key: index,
                                    label: item.title,
                                }),
                                defaultItem: () => mockAnimatedPromoBlockTinaData.list[0].banner.advantages[0],
                                fields: [
                                    {
                                        name: 'title',
                                        component: HtmlEditorFeatured,
                                        label: 'Заголовок',
                                    },
                                    {
                                        name: 'subtitle',
                                        component: HtmlEditorFeatured,
                                        label: 'Описание',
                                    },
                                ],
                            },
                            {
                                label: 'Баннер. Кнопки.',
                                name: 'buttons',
                                component: 'group-list',
                                description: 'Список кнопок на баннере',
                                itemProps: (item: { text: string }, index) => ({
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    key: index,
                                    label: item.text,
                                }),
                                defaultItem: () => ({
                                    text: 'Текст',
                                    link: '/',
                                    type: 'button',
                                }),
                                fields: [
                                    {
                                        name: 'text',
                                        component: 'text',
                                        label: 'Текст.',
                                    },
                                    {
                                        name: 'link',
                                        component: 'text',
                                        label: 'Ссылка.',
                                    },
                                    {
                                        name: 'type',
                                        component: 'select',
                                        label: 'Тип элемента',
                                        options: [
                                            {
                                                value: 'button',
                                                label: 'Кнопка',
                                            },
                                            { value: 'link', label: 'Ссылка' },
                                        ],
                                    },
                                    { ...gtagSingleItem },
                                ],
                            },
                            {
                                name: 'dotGtag',
                                component: 'group',
                                label: 'Цель GTM Пин',
                                fields: [
                                    {
                                        name: 'action',
                                        component: 'text',
                                        label: 'Действие',
                                    },
                                    {
                                        name: 'category',
                                        component: 'text',
                                        label: 'Категория',
                                    },
                                    {
                                        name: 'label',
                                        component: 'text',
                                        label: 'Заголовок',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
