import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC, useContext } from 'react';

import CalculatorConclusions from '@/components/blocks/calculators/CalculatorConclusions';
import Conditions from '@/components/blocks/calculators/Conditions';
import TextField from '@/components/TextField';
import Checkbox from '@/components/UI/Checkbox';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IPreferentialStore } from '@/domain/calculator/PreferentialCalculatorStore';

import cn from './style.module.sass';

const MonthlyPayment: FC = observer(() => {
    const { paymentValue, rate, guaranteedRate, overpayment, refundSum, setPreferentialStatus } =
        useContext<IPreferentialStore>(CalculatorStoreContext);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        setPreferentialStatus(e.target.checked);
    };

    return (
        <div className={cn.wrapper}>
            <div className={cn.content}>
                <div className={cn.group}>
                    <div className={cn.groupWrapper}>
                        <CalculatorConclusions
                            title="Наше предложение"
                            sum={`${formatValue(paymentValue)} ₽`}
                            percent={`${rate} %`}
                            withMaxWidth
                        />

                        <div className={cn.row}>
                            <div className={cn.rowInner}>
                                <div className={cn.rowColumn}>
                                    <span className={cn.result}>{`${guaranteedRate} %`}</span>
                                    <TextField
                                        name="guaranteedRate"
                                        text={`Ставка с услугой \n"Гарантированная ставка"`}
                                        className={clsx(cn.rowText)}
                                    />
                                </div>
                                <div className={cn.rowColumn}>
                                    <span
                                        className={cn.result}
                                        dangerouslySetInnerHTML={{
                                            __html: `${formatValue(refundSum)}&nbsp;₽`,
                                        }}
                                    />
                                    <TextField
                                        name="refundSum"
                                        text="Мы вернем в конце срока"
                                        className={clsx(cn.rowText)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={clsx(cn.row, cn.rowSecondary)}>
                            <div className={clsx(cn.rowInner, cn.rowInnerSecondary)}>
                                <div className={cn.rowColumn}>
                                    <TextField
                                        name="percent"
                                        text="Сумма переплаты"
                                        className={clsx(cn.rowText, cn.rowTextSecondary)}
                                    />
                                </div>
                                <div className={cn.rowColumn}>
                                    <span
                                        className={cn.result}
                                        dangerouslySetInnerHTML={{
                                            __html: `${formatValue(overpayment)}&nbsp;₽`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Conditions />
                        <Checkbox label="Я льготный клиент" onChangeHandler={handleChecked} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default MonthlyPayment;
