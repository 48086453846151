import clsx from 'clsx';
import React from 'react';

import { ScrollLink } from '@/components/UI/ScrollLink';

import { ISidebarAnchorLinksProps } from './iSidebarAnchorLinks';
import cn from './style.module.sass';

const DefaultSidebarAnchorLinks: React.FC<ISidebarAnchorLinksProps> = ({ list, scrollOffset }) => (
    <div className={clsx(cn.wrapper, 'sidebarSection')}>
        <div className={clsx(cn.innerWrapper)}>
            {list &&
                list?.length > 0 &&
                list?.map(item => (
                    <div className={cn.item} key={item.linkName}>
                        <ScrollLink
                            to={item.linkHref}
                            offset={-scrollOffset}
                            activeClass="menuLinkActive"
                            className={cn.link}
                        >
                            {item.linkName}
                        </ScrollLink>
                    </div>
                ))}
        </div>
    </div>
);

export default DefaultSidebarAnchorLinks;
