import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import ActionPensionsModal from '@/components/blocks/ActionPensions/modal';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { useFixHtml } from '@/hooks/useFixHtml';
import { IActionPensionsItem } from '@/types/actionPensions/item';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function ActionPensionsItem({
    title,
    subtitle,
    description,
    modalTitle,
    mark,
    btn,
    isArchive,
    isGradient,
    pos,
}: IActionPensionsItem & { pos: number }) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { fix, unfix } = useFixHtml();
    const show = () => {
        fix();
        setShowModal(true);
    };

    const hide = () => {
        unfix();
        setShowModal(false);
    };

    return (
        <div className={clsx(cn.item, isGradient && cn.itemGradient, isArchive && cn.itemArchive)}>
            <div className={clsx(cn.itemWrap)} role="presentation">
                <div className={cn.itemGrid}>
                    <div className={cn.content}>
                        {title && (
                            <TextField
                                text={title}
                                name={`list.[${pos}].title`}
                                isHTML
                                htmlValue={title}
                                className={cn.itemTitle}
                            />
                        )}
                        {subtitle && (
                            <TextField
                                text={subtitle}
                                name={`list.[${pos}].subtitle`}
                                isHTML
                                htmlValue={subtitle}
                                className={cn.itemSubtitle}
                            />
                        )}
                    </div>
                    <div className={cn.buttonWrap}>
                        <Button
                            variant="btnBlueBorder"
                            type={btn.type}
                            href={btn?.link}
                            label={btn?.label}
                            size="long"
                            darkWhite
                            animation={false}
                            isRedesign
                            isViewLink={btn?.type === 'link' || isArchive}
                            onClick={() => {
                                if (btn.type === 'button') show();
                                if (btn?.gtag?.action) universalEvent(btn?.gtag);
                            }}
                        />
                    </div>
                </div>
            </div>

            <ActionPensionsModal
                title={modalTitle}
                description={description}
                mark={mark}
                handleClose={hide}
                isOpen={showModal}
            />
        </div>
    );
}

export default observer(ActionPensionsItem);
