import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface ICrmOffersLinkBlock {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'crmOffersLinkBlock';
    /**
     */
    name?: string;
    buttonName?: string;
    buttonType?: string;
    link?: string;
    image?: string;
    gtag?: GTagEvent;
}

const CrmOffersLinkBlock: React.FC<ICrmOffersLinkBlock> = ({
    name = 'Ваши персональные предложения',
    buttonName = 'Показать предложения',
    buttonType = 'anchor',
    link,
    image,
    gtag,
}) => {
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const scrollOffset = isMobile ? 60 : 90;

    const children = (
        <>
            <div className={cn.image}>
                <CustomImage src={image} alt="Персональные предложения" mockWidth="42" mockHeight="66" fill />
            </div>
            <TextField text={name} name="name" className={cn.title} isHTML htmlValue={name} />
        </>
    );

    if (isMobile)
        return (
            <AnimatedComponent className={clsx(cn.wrap, 'section')} classNameActive={cn.animationInit}>
                <div className={cn.wrapper}>
                    {buttonType === 'link' && (
                        <a
                            href={link}
                            className="innerWrap crmButton"
                            onClick={() => {
                                if (gtag?.action) event(gtag);
                            }}
                        >
                            {children}
                        </a>
                    )}
                    {buttonType === 'anchor' && (
                        <ScrollLink to={link} offset={-scrollOffset} className="innerWrap crmButton">
                            {children}
                        </ScrollLink>
                    )}
                </div>
            </AnimatedComponent>
        );

    return (
        <AnimatedComponent className={clsx(cn.wrap, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.wrapper}>
                <div className={cn.innerWrap}>
                    <div className={cn.content}>
                        <div className={cn.left}>{children}</div>
                        <div className={cn.right}>
                            {buttonType === 'link' && (
                                <div className="crmButton">
                                    <Button
                                        variant="btnWhite"
                                        type="link"
                                        href={link}
                                        customClass="button"
                                        label={buttonName}
                                        animation={false}
                                        whiteBg
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                        withMinimalPadding
                                    />
                                </div>
                            )}

                            {buttonType === 'anchor' && (
                                <ScrollLink to={link} offset={-scrollOffset} className="btnBlue crmButton">
                                    <Button
                                        variant="btnWhite"
                                        type="button"
                                        customClass="button"
                                        label={buttonName}
                                        animation={false}
                                        whiteBg
                                        onClick={() => {
                                            if (gtag?.action) event(gtag);
                                        }}
                                        withMinimalPadding
                                    />
                                </ScrollLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(CrmOffersLinkBlock);
