import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import InsuranceItem from '@/components/blocks/insurance/InsuranceItem';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { IInsuranceItem } from '@/types/insurance/item';

import cn from './style.module.sass';

export interface IInsurance {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'insurance';
    /**
     * Карточки
     */
    title?: string;
    list: IInsuranceItem[];
}

const Insurance: React.FC<IInsurance> = ({ title, list }) => {
    if (!list || list?.length <= 0) return null;

    return (
        <AnimatedComponent className={clsx(cn.list, 'section')} classNameActive={cn.animationInit}>
            <div className={cn.listWrap}>
                {title && (
                    <div className={cn.titleWrapper}>
                        <TextField text={title} name="title" className={cn.title} isHTML htmlValue={title} />
                    </div>
                )}
                <div className={cn.listGrid}>
                    {list?.map((item, index) => (
                        <InsuranceItem pos={index} key={index} {...item} />
                    ))}
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Insurance);
