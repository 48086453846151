export const mockMediaPublicationsData = {
    title: 'Публикации в СМИ',
    person: {
        name: 'Дмитрий Руденко',
        desc: 'Президент-Председатель Правления Почта Банка',
        image: '/img-next/management/rudenko/rudenko-smi.png',
    },
    list: [
        {
            date: '16 декабря 2020 г.',
            title: 'Интервью RNS',
            desc: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo; Дмитрий Руденко в&nbsp;разговоре с&nbsp;RNS оценил влияние пандемии на&nbsp;российский банковский сектор, а&nbsp;также рассказал о&nbsp;подходах в&nbsp;работе с&nbsp;пострадавшими из-за коронавируса заёмщиками и&nbsp;принципах ответственного банкинга. Как пандемия коронавируса отразилась в&nbsp;целом на&nbsp;российском банковском секторе и&nbsp;на&nbsp;Почта Банке в&nbsp;частности?',
            logo: '/img-next/management/rudenko/smi/logo-1.png',
        },
        {
            date: '16 декабря 2020 г.',
            title: 'Интервью 2222',
            desc: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo; Дмитрий Руденко в&nbsp;разговоре с&nbsp;RNS оценил влияние пандемии на&nbsp;российский банковский сектор, а&nbsp;также рассказал о&nbsp;подходах в&nbsp;работе с&nbsp;пострадавшими из-за коронавируса заёмщиками и&nbsp;принципах ответственного банкинга. Как пандемия коронавируса отразилась в&nbsp;целом на&nbsp;российском банковском секторе и&nbsp;на&nbsp;Почта Банке в&nbsp;частности?',
            logo: '/img-next/management/rudenko/smi/logo-1.png',
        },
        {
            date: '16 декабря 2020 г.',
            title: 'Интервью 3333333',
            desc: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo; Дмитрий Руденко в&nbsp;разговоре с&nbsp;RNS оценил влияние пандемии на&nbsp;российский банковский сектор, а&nbsp;также рассказал о&nbsp;подходах в&nbsp;работе с&nbsp;пострадавшими из-за коронавируса заёмщиками и&nbsp;принципах ответственного банкинга. Как пандемия коронавируса отразилась в&nbsp;целом на&nbsp;российском банковском секторе и&nbsp;на&nbsp;Почта Банке в&nbsp;частности?',
            logo: '/img-next/management/rudenko/smi/logo-1.png',
        },
        {
            date: '16 декабря 2020 г.',
            title: 'Интервью RNS',
            desc: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo; Дмитрий Руденко в&nbsp;разговоре с&nbsp;RNS оценил влияние пандемии на&nbsp;российский банковский сектор, а&nbsp;также рассказал о&nbsp;подходах в&nbsp;работе с&nbsp;пострадавшими из-за коронавируса заёмщиками и&nbsp;принципах ответственного банкинга. Как пандемия коронавируса отразилась в&nbsp;целом на&nbsp;российском банковском секторе и&nbsp;на&nbsp;Почта Банке в&nbsp;частности?',
            logo: '/img-next/management/rudenko/smi/logo-1.png',
        },
        {
            date: '16 декабря 2020 г.',
            title: 'Интервью RNS',
            desc: 'Президент-Председатель Правления АО&nbsp;&laquo;Почта Банк&raquo; Дмитрий Руденко в&nbsp;разговоре с&nbsp;RNS оценил влияние пандемии на&nbsp;российский банковский сектор, а&nbsp;также рассказал о&nbsp;подходах в&nbsp;работе с&nbsp;пострадавшими из-за коронавируса заёмщиками и&nbsp;принципах ответственного банкинга. Как пандемия коронавируса отразилась в&nbsp;целом на&nbsp;российском банковском секторе и&nbsp;на&nbsp;Почта Банке в&nbsp;частности?',
            logo: '/img-next/management/rudenko/smi/logo-1.png',
        },
    ],
};

export const mockMediaPublicationsDataTina = {
    title: 'Заголовок',
    person: {
        name: 'Заголовок',
        desc: 'Описание',
        image: '',
    },
    list: [
        {
            date: 'Дата',
            title: 'Заголовок',
            desc: 'Описание',
            logo: '',
        },
    ],
};
