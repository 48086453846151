import React from 'react';

import { TinaWidget } from '@/components/blocks/Widget/index.tina';
import { mockWidgetBlockData } from '@/data/blocks/widget';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const widgetBlock = {
    Component: props => <TinaWidget {...props} />,
    template: {
        label: 'Блок Виджет',
        defaultItem: () => mockWidgetBlockData,
        fields: [
            {
                name: 'titleBlock',
                component: 'text',
                label: 'Название элемента/Заголовок',
                required: true,
            },
            {
                label: 'Плашка-раскрывашка',
                name: 'top',
                component: 'group',
                fields: [
                    {
                        name: 'title',
                        component: HtmlEditorFeatured,
                        label: 'Длинный заголовок',
                    },
                    {
                        name: 'shortTitle',
                        component: HtmlEditorFeatured,
                        label: 'Короткий заголовок',
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                ],
            },
            {
                name: 'isSetImage',
                component: 'toggle',
                label: 'Включить картинку',
            },
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },
            {
                name: 'mark',
                component: HtmlEditorFeatured,
                label: 'Примечание',
            },
            {
                label: 'Ползунок Сумма кредита',
                name: 'filterElements',
                component: 'group-list',
                itemProps: (item: { label: string; id: number }, index: number) => ({
                    key: index,
                    label: item.label ? `${item.id}) ${item.label}` : 'Элемент',
                }),
                defaultItem: () => ({
                    value: 0,
                    step: 5000,
                    percent: 0,
                    label: '0',
                    id: 100,
                }),
                fields: [
                    {
                        name: 'label',
                        component: 'text',
                        label: 'Название риски',
                    },
                    {
                        name: 'value',
                        component: 'number',
                        label: 'Значение',
                    },
                    {
                        name: 'id',
                        component: 'number',
                        label: 'Номер',
                    },
                    {
                        name: 'percent',
                        component: 'number',
                        label: 'Процент расположения риски на шкале (0 - 100)',
                    },
                    {
                        name: 'step',
                        component: 'number',
                        label: 'Размер шага',
                    },
                ],
            },
            {
                label: 'Ползунок Срок кредита',
                name: 'termsInfo',
                component: 'group',
                fields: [
                    {
                        name: 'firstValue',
                        component: 'text',
                        label: 'Начальное значение ползунка',
                    },
                    {
                        name: 'firstLabel',
                        component: 'text',
                        label: 'Подпись для начального значения ползунка',
                    },
                    {
                        name: 'lastValue',
                        component: 'text',
                        label: 'Конечное значение ползунка',
                    },
                    {
                        name: 'lastLabel',
                        component: 'text',
                        label: 'Подпись для конечного значения ползунка',
                    },
                ],
            },
        ],
    },
};
