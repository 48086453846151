import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IAdvantage {
    title: string;
    subtitle: string;
}

export interface IStretch {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'stretch';
    /**
     * Заголовок Блока
     */
    name: string;
    /**
     * Заголовок
     */
    title: string;
    /**
     * Описание. markdown
     */
    subtitle?: string;
    advantages?: IAdvantage[];
    /**
     * Картинка
     */
    image?: string;
    /**
     * Ссылка
     */
    link: string;
    /**
     * Текст кнопки
     */
    rel?: string;
    linkText: string;
    margin?: 'big' | 'min';
    gtag?: GTagEvent;
    section?: number;
    smPadding?: boolean;
    bgColor?: string;
    inTwo?: boolean;
}

function Stretch({
    title,
    subtitle,
    image,
    advantages,
    linkText,
    link,
    name,
    margin = 'big',
    gtag,
    smPadding = false,
    bgColor,
    inTwo = false,
    rel,
}: IStretch) {
    return (
        <AnimatedComponent
            className={clsx(cn.stretch, margin === 'min' && cn.marginMin, 'section')}
            classNameActive={cn.animationInit}
        >
            <div className={cn.stretchWrap}>
                <TextField text={name} name="name" customTag="h3" className={cn.topTitle} isTextArea />
                <div
                    className={clsx(cn.stretchGrid, smPadding && cn.stretchGridSmall)}
                    style={bgColor ? { background: bgColor } : {}}
                >
                    {!bgColor && (
                        <CustomImage
                            src="/img-next/jpg/light.jpg"
                            fill
                            mockWidth={1000}
                            mockHeight={1000}
                            alt="light"
                        />
                    )}
                    <div className={clsx(cn.stretchItem, cn.stretchItemContent)}>
                        <div className={cn.stretchTop}>
                            <TextField
                                className={clsx(cn.stretchTitle, advantages?.length && cn.stretchTitleAdvantages)}
                                isTextArea
                                text={title}
                                name="title"
                            />
                            {!advantages?.length ? (
                                <TextField
                                    className={cn.stretchSubtitle}
                                    text={subtitle}
                                    isHTML
                                    htmlValue={subtitle}
                                    name="subtitle"
                                />
                            ) : (
                                <div className={cn.advantages}>
                                    {advantages?.map((advantage, index) => (
                                        <div className={cn.advantagesItem} key={index}>
                                            <div className={cn.advantagesItemTitle}>{advantage?.title}</div>
                                            <div
                                                className={cn.advantagesItemSubtitle}
                                                dangerouslySetInnerHTML={{
                                                    __html: advantage?.subtitle,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {link && linkText && (
                            <div className={cn.stretchBot}>
                                <Button
                                    variant="btnWhite"
                                    rel={rel}
                                    type="link"
                                    href={link}
                                    label={linkText}
                                    customClass={cn.stretchBtn}
                                    onClick={() => {
                                        if (gtag?.action) event(gtag);
                                    }}
                                    animation={false}
                                />
                            </div>
                        )}
                    </div>
                    <div className={clsx(cn.stretchItem, cn.stretchItemImage, inTwo && cn.inTwo)}>
                        <div className={cn.stretchImageWrap}>
                            <div className={cn.stretchImage}>
                                <CustomImage src={image} alt={title} width={453} height={387} layout="intrinsic" />
                            </div>
                        </div>
                    </div>
                    {link && linkText && (
                        <div className={clsx(cn.stretchItem, cn.stretchItemMobail)}>
                            <div className={cn.stretchBtn}>
                                <Button
                                    rel={rel}
                                    variant="btnWhite"
                                    href={link}
                                    size="small"
                                    customClass="full"
                                    type="link"
                                    label={linkText}
                                    onClick={() => {
                                        if (gtag?.action) event(gtag);
                                    }}
                                    animation={false}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AnimatedComponent>
    );
}

export default withBlockLinks(Stretch);
