import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface IManager {
    name: string;
    position: string;
    photo: string;
}

export interface IManagement {
    _template: 'management';
    heading?: string;
    managers: IManager[];
}

const Management: FC<IManagement> = ({ heading, managers }) => {
    if (!managers || managers?.length <= 0) return null;

    return (
        <div className="section">
            <div className={cn.wrapper}>
                {heading && (
                    <TextField
                        text={heading}
                        name="heading"
                        isHTML
                        htmlValue={heading}
                        className={cn.heading}
                        customTag="h2"
                    />
                )}
                <div className={cn.grid}>
                    {managers?.map(({ name, position, photo }: IManager, index) => (
                        <div className={cn.item} key={index}>
                            <div className={cn.imgWrap}>
                                <CustomImage src={photo} alt={name} mockWidth="121" mockHeight="121" fill />
                            </div>
                            <div className={cn.info}>
                                <TextField
                                    text={name}
                                    name={`managers.[${index}].name`}
                                    isHTML
                                    htmlValue={name}
                                    className={cn.name}
                                />
                                <TextField
                                    text={position}
                                    name={`managers.[${index}].position`}
                                    isHTML
                                    htmlValue={position}
                                    className={cn.position}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Management;
