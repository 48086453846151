import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { IManagementItem } from '@/types/managementInfo/item';

import cn from './style.module.sass';

interface IAdditionalProps {
    pos: number;
}

const ManagementInfoItemModalContent: React.FC<IManagementItem & IAdditionalProps> = ({
    big,
    name,
    subtitle,
    desc,
    image,
    pos,
}) => (
    <div className={clsx(cn.item)}>
        <div className={cn.itemGrid}>
            <div className={cn.itemTop}>
                {image && (
                    <div className={cn.imageWrapper}>
                        <div className={cn.image}>
                            <CustomImage src={image} alt={name} fill mockWidth="96" mockHeight="96" />
                        </div>
                    </div>
                )}
                <div className={cn.content}>
                    <TextField text={name} name={`list.[${pos}].name`} isHTML htmlValue={name} className={cn.name} />
                    <TextField
                        text={subtitle}
                        name={`list.[${pos}].subtitle`}
                        isHTML
                        htmlValue={subtitle}
                        className={cn.subtitle}
                    />
                </div>
            </div>
            <div className={cn.itemBottom}>
                <TextField text={desc} name={`list.[${pos}].desc`} isHTML htmlValue={desc} className={cn.desc} />
            </div>
        </div>
    </div>
);
export default ManagementInfoItemModalContent;
