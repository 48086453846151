import React from 'react';

import { TinaCampaignBlockBlock } from '@/components/blocks/CampaignBlock/index.tina';
import { mockCampaignBlockBlockTinaData } from '@/data/blocks/campaignBlock';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const campaignBlockBlock = {
    Component: props => <TinaCampaignBlockBlock {...props} />,
    template: {
        label: 'Блок-акция',
        defaultItem: () => mockCampaignBlockBlockTinaData,
        fields: [
            {
                name: 'image',
                component: 'image',
                label: 'Изображение',
                parse: (media: { src: string }) => media.src,
            },

            {
                name: 'imageAlt',
                component: 'text',
                label: 'Описание к изображению',
            },
            {
                name: 'subhead',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
