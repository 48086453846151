import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import VacancyButton from '@/components/blocks/vacancy/VacancyButton';
import VacancyFilter from '@/components/blocks/vacancy/VacancyFilter';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import { ISelectElement } from '@/types/select';
import { IButton, IVacancyFilterElement, IVacancyItem } from '@/types/vacancy/item';

import VacancyItem from '../VacancyItem';
import cn from './style.module.sass';

export interface IVacancy {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template?: 'vacancy';
    title?: string;
    desc?: string;
    list: IVacancyItem[];
    citiesList: IVacancyFilterElement[];
    vacanciesList: IVacancyFilterElement[];
    button?: IButton;
    vacancyToShow?: number;
}

const Vacancy: React.FC<IVacancy> = ({ title, desc, list, button, vacancyToShow = 10, vacanciesList, citiesList }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedVacancy, setSelectedVacancy] = useState<IVacancyFilterElement>(null);
    const [selectedCity, setSelectedCity] = useState<IVacancyFilterElement>(null);
    const [showChunk, setShowChunk] = useState<number | 'all'>(+vacancyToShow || 'all');

    const filteredVacancies: IVacancyItem[] = useMemo(() => {
        setShowChunk(+vacancyToShow || 'all');

        return list?.reduce((acc: IVacancyItem[], item, index) => {
            const hasNameSpace = item?.title?.toLowerCase().includes(searchValue?.toLowerCase());

            const isSelectedVacancy =
                item?.vacancy?.includes(selectedVacancy?.xmlId) ||
                !selectedVacancy?.xmlId ||
                selectedVacancy?.xmlId === 'all';
            const isSelectedCity =
                item?.city?.includes(selectedCity?.xmlId) || !selectedCity?.xmlId || selectedCity?.xmlId === 'all';

            if (hasNameSpace && isSelectedVacancy && isSelectedCity) {
                return [...acc, { ...item, pos: index }];
            }
            return acc;
        }, []);
    }, [searchValue, selectedVacancy, selectedCity, list]);

    const showedVacancies: IVacancyItem[] = useMemo(() => {
        if (showChunk === 'all') return filteredVacancies;
        return filteredVacancies?.slice(0, showChunk);
    }, [filteredVacancies, showChunk]);

    const isShowMore = useMemo(
        () => showChunk !== 'all' && showedVacancies?.length < filteredVacancies?.length,
        [showedVacancies, vacancyToShow]
    );

    const onShowMoreClick = () => {
        setShowChunk((showChunk as number) + +vacancyToShow);
    };

    const convertOptionToFilterValue = ({ label, value }: { label: string; value: string }): IVacancyFilterElement => ({
        xmlId: value,
        value: label,
    });

    const onVacancySelect = (item: ISelectElement<string>) => {
        setSelectedVacancy(convertOptionToFilterValue(item));
    };

    const onCitySelect = (item: ISelectElement<string>) => {
        setSelectedCity(convertOptionToFilterValue(item));
    };

    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const showHead = title || desc;

    return (
        <AnimatedComponent>
            <div className={clsx(cn.vacancy, 'section')}>
                <div className={cn.wrap}>
                    {showHead && (
                        <div className={cn.top}>
                            <TextField text={title} name="title" isTextArea className={cn.title} customTag="h3" />
                            <TextField text={desc} name="desc" isTextArea className={cn.desc} />
                        </div>
                    )}
                    <div className={cn.filter}>
                        <VacancyFilter
                            onVacancySelect={onVacancySelect}
                            onCitySelect={onCitySelect}
                            searchValue={searchValue}
                            onSearchValueChange={onSearchValueChange}
                            vacanciesList={vacanciesList}
                            citiesList={citiesList}
                            vacanciesLength={list?.length}
                            citiesListLength={citiesList?.length}
                        />
                    </div>
                    <div className={cn.list}>
                        {showedVacancies?.map((item, index) => (
                            <VacancyItem {...item} key={index} citiesList={citiesList} />
                        ))}
                    </div>
                    <VacancyButton
                        link={button?.link}
                        type="button"
                        onClick={onShowMoreClick}
                        isShowMore={isShowMore}
                    />
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(Vacancy);
