import clsx from 'clsx';
import React from 'react';

import ShareIconItem from '@/components/UI/ShareIconBlock/item';

import cn from './style.module.sass';

type Item = 'vk' | 'ok' | 'tw';

interface IProps {
    link: string;
    list: Array<Item>;
}

const ShareIconBlock: React.FC<IProps> = ({ link, list }) => {
    if (list?.length <= 0) return null;

    return (
        <div className={clsx(cn.wrapper)} role="presentation">
            Поделиться
            <div className={clsx(cn.innerWrapper)}>
                {list.map((item, index) => (
                    <div className={cn.item} key={index}>
                        <ShareIconItem href={link} type={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShareIconBlock;
