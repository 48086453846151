import React from 'react';

import { TinaInternship2024 } from '@/components/blocks/Internship2024/index.tina';

export const internship2024LandingBlock = {
    Component: props => <TinaInternship2024 {...props} />,
    template: {
        label: 'Стажировка 2024 лендинг',
        fields: [],
    },
};
