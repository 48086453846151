import React from 'react';

import { TinaInstructionInfoGraphic } from '@/components/blocks/InstructionInfoGrapfic/index.tina';
import { mockInstructionInfoGraphicBlockTinaData } from '@/data/blocks/instructionInfoGraphic';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';

export const instructionInfoGraphicBlock = {
    Component: props => <TinaInstructionInfoGraphic {...props} />,
    template: {
        label: 'Пошаговая инструкция в картинках',
        defaultItem: () => mockInstructionInfoGraphicBlockTinaData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                label: 'Список',
                name: 'list',
                component: 'group-list',
                description: 'Список блоков инструкции',
                itemProps: (item: { name: string }, index: number) => ({
                    key: index,
                    label: item.name,
                    title: item.name,
                }),
                defaultItem: () => mockInstructionInfoGraphicBlockTinaData.list[0],
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Наименование элемента (максимум 255 символов)',
                        required: true,
                        validate: (value: string) => {
                            if (!value) {
                                return 'Это поле обязательно для заполнения';
                            }
                            return null;
                        },
                    },
                    {
                        name: 'desc',
                        component: HtmlEditorFeatured,
                        label: 'Описание',
                    },
                    {
                        name: 'image',
                        component: 'image',
                        label: 'Изображение.',
                        parse: (media: { src: string }) => media.src,
                    },
                    {
                        component: 'select',
                        name: 'positionImage',
                        label: 'Положение картинки',
                        options: [
                            {
                                value: 'bottom',
                                label: 'Прижать книзу',
                            },
                            {
                                value: 'top',
                                label: 'Прижать кверху',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
