interface IDictionary {
    region: string;
    code: string;
}

const dictionary: IDictionary[] = [
    {
        region: 'Москва',
        code: '999999999802',
    },
    {
        region: 'Северо-Запад',
        code: '999999999801',
    },
    {
        region: 'Северное Поволжье',
        code: '999999999807',
    },
    {
        region: 'Центр',
        code: '999999999803',
    },
    {
        region: 'Южное Поволжье',
        code: '999999999806',
    },
    {
        region: 'Юг',
        code: '999999999805',
    },
    {
        region: 'Сибирь',
        code: '999999999808',
    },
    {
        region: 'Северный Кавказ',
        code: '999999999802',
    },
    {
        region: 'Урал',
        code: '999999999804',
    },
    {
        region: 'Дальний Восток',
        code: '999999999809',
    },
];

export const getTTCodeforMacro = (region: string) => {
    if (!region) return 999999999802;

    const codeItem: IDictionary[] = dictionary.filter(item => item.region === region);
    return codeItem[0].code;
};
