import clsx from 'clsx';
import React, { FC } from 'react';

interface IProductsAndServicesArrowProps {
    customClass?: string;
}

const ProductsAndServicesArrow: FC<IProductsAndServicesArrowProps> = ({ customClass }) => (
    <svg
        className={clsx('svg', customClass)}
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
    >
        <path d="M15.2754 9.07461L8.63438 3.31074C8.5834 3.2668 8.51836 3.24219 8.4498 3.24219H6.89414C6.76406 3.24219 6.7043 3.40391 6.80273 3.48828L12.9586 8.83203H2.67188C2.59453 8.83203 2.53125 8.89531 2.53125 8.97266V10.0273C2.53125 10.1047 2.59453 10.168 2.67188 10.168H12.9568L6.80098 15.5117C6.70254 15.5979 6.7623 15.7578 6.89238 15.7578H8.50078C8.53418 15.7578 8.56758 15.7455 8.59219 15.7227L15.2754 9.92539C15.3362 9.87247 15.385 9.80712 15.4185 9.73373C15.4519 9.66035 15.4692 9.58064 15.4692 9.5C15.4692 9.41936 15.4519 9.33965 15.4185 9.26627C15.385 9.19288 15.3362 9.12753 15.2754 9.07461Z" />
    </svg>
);

export default ProductsAndServicesArrow;
